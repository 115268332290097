import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, DateBox } from "../commoncomponents/InputBox";
//import { FileUploader } from "react-drag-drop-files";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
import { format } from "date-fns";
//loder
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { dateFormate, number, numberwithDot } from '../Common/Common';

function QualificationDetails({ TokenData,activeTab, sessionExpired, CandidateCode, handleTabClick, qualificationDetails, PreviousEmploymentDetailsList, setPreviousEmploymentDetailsList, QualificationDetailsList, setQualificationDetailsList }) {
    const inputrefs = useRef([]);
    const { user} = useAuth();
   
    //initial state

    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [isUpdate, setIsUpdate] = useState(false)
    const [countryPre, setCountryPre] = useState('')
    const [countryAllData, setCountryAllData] = useState([])
    const [statePre, setStatePre] = useState('')
    const [stateAllData, setStateAllData] = useState([])
    const [FromDate, setFromDate] = useState()
    const [ToDate, setToDate] = useState();
    const [yearData, setYearData] = useState([]);
    const [qualificationData, setQualificationData] = useState([]);
   // const [QualificationDetailsList, setQualificationDetailsList] = useState(null);
    const [isEdit, setIsEdit] = useState(false)
    const [editIndex, setEditIndex] = useState('')
    const [errors, setErrors] = useState({});
    const [QualificationForm, setQualificationForm] = useState({
        "qualification": "",
        "college": "",
        "percentage": "",
        "year": ""
    })
  //  console.log(PreviousEmploymentDetailsList, "!!!!!!!!!!!!!!!!!!!!", QualificationDetailsList)

   // console.log(QualificationDetailsList,'QualificationDetailsList')
   // const [PreviousEmploymentDetailsList, setPreviousEmploymentDetailsList] = useState(null);
    const [isEditPreviousEmployment, setIsEditPreviousEmployment] = useState(false)
    const [editIndexPreviousEmployment, setEditIndexPreviousEmployment] = useState('')
    const [PreviousEmploymentForm, setPreviousEmploymentForm] = useState({
        "companyName": "",
        "fromDate": "",
        "toDate": "",

    })

    const YearData = () => {
        setYearData([])
        let temp = {
            Type: "GetYear",
            Prefix: '',

        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setYearData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setYearData(data)
                }
            }
        });
    }
    const QualificationData = () => {
        setQualificationData([])
        let temp = {
            Type: "GetQualification",
            Prefix: '',

        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setQualificationData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setQualificationData(data)
                }
            }
        });
    }




    //Change Handler
    const HandleChange = (prop, value) => {
        //if (prop === 'fromDate') {
        //    setFromDate(value)
        //    setPreviousEmploymentForm({ ...PreviousEmploymentForm, [prop]: value.toLocaleDateString() })
        //} else if (prop === 'toDate') {
        //    setToDate(value)
        //    setPreviousEmploymentForm({ ...PreviousEmploymentForm, [prop]: value.toLocaleDateString() })

        //} else {
        if (prop === 'qualification') {
            value === 'Others' ? setQualificationForm(QualificationForm => ({ ...QualificationForm, [prop]: value })) : setQualificationForm({ ...QualificationForm, otherQualification: '' })
            } 
            setQualificationForm(QualificationForm => ({ ...QualificationForm, [prop]: value }))

            
        //}
            setPreviousEmploymentForm(PreviousEmploymentForm => ({ ...PreviousEmploymentForm, [prop]: value }))

    }
   // console.log(QualificationForm,'AAAAAAAA')
    //Submit Handler
    const handleSubmit = () => {
        if (QualificationDetailsList.length > 0) {
            const PrevEmploymentDetailsList = [...PreviousEmploymentDetailsList];

            setLoading(true)
            post(`${API_SERVER}api/Candidate/UpdateCandidateQualificationDatail`, { ...TokenData, PrevEmploymentDetailsList, QualificationDetailsList, CandidateCode }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        setLoading(false)
                    } else if (res.status === "EXPIRED") {
                        toast.error(res.message)
                        sessionExpired()
                    } else if (res.status === "UNAUTHORIZED") {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        handleTabClick("FamilyDetails")
                    }
                }
            });
        } else {
            if (QualificationDetailsList.length === 0)  toast.error("Please Add Qualification Datails") 
        }
       
    }
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "qualification", msg: 'Please Enter Qualification.' },
            { field: "college", msg: 'Please Enter Allowance College Name.' },
            { field: "percentage", msg: 'Please Enter Percentage.' },
            { field: "year", msg: 'Select Passing Year.' },
        ]
        if (QualificationForm['qualification'] === 'Others') {
            validation.push(
                { field: "otherQualification", msg: ' Please Enter Other Qualification.' },
            )
        };
        validation.map(item => {
            if (!QualificationForm[item.field] || QualificationForm[item.field].length <= 0 || QualificationForm[item.field] === '') {

                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        return isValid;
    }

    const ValidatePreviousEmpyolement = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "companyName", msg: 'Please Enter Company Name.' },
            { field: "fromDate", msg: 'Please Enter From Date.' },
            { field: "toDate", msg: 'Please Enter To Date.' },
            
        ]
        validation.map(item => {
            if (!PreviousEmploymentForm[item.field] || PreviousEmploymentForm[item.field].length <= 0 || PreviousEmploymentForm[item.field] === '') {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        return isValid;
    }

    //handle Add Qualification
    const handleAddQualifications = () => {
        if (Validate()) {
            if (isEdit) {
                const list = [...QualificationDetailsList];
                list[editIndex] = { ...QualificationForm };
                setQualificationDetailsList(list);
                setIsEdit(false);
                setEditIndex('');

            } else {
                setQualificationDetailsList([...QualificationDetailsList, QualificationForm])
            }
            handleClearQualificationInputs();
        }
    }

    // Handle Reset Qualification Inputs
    const handleClearQualificationInputs = () => {
        setQualificationForm({
            ...QualificationForm,
            "qualification": "",
            "college": "",
            "percentage": "",
            "year": "",
            otherQualification:''
        })
    }

    //Handle Edit Qualificaion

    const HandleQualificationEdit = (item, index) => {
        setIsEdit(true);
        setEditIndex(index);
        const editQulification = QualificationDetailsList.find((ele, i) => i === index)
      //  console.log(editQulification, '34df')
        setQualificationForm({
            ...QualificationForm,
            "qualification": editQulification.qualification,
            "otherQualification": editQulification.otherQualification,
            "college": editQulification.college,
            "percentage": editQulification.percentage,
            "year": editQulification.year
        })
    }



    // Handle Delete Qualification

  const HandleQualificationDelete = (index) => {
        const filerQualification = QualificationDetailsList.filter((ele, i) => i !== index);
        setQualificationDetailsList(filerQualification);
    }

    //handle Add PreviousEmployment
    const handleAddPreviousEmployments = () => {
        if (ValidatePreviousEmpyolement()) {
            //const form = { ...PreviousEmploymentForm };
            //form.toDate = ToDate;
            //form.fromDate = FromDate;
            if (isEditPreviousEmployment) {
                const list = [...PreviousEmploymentDetailsList];
                list[editIndexPreviousEmployment] = { ...PreviousEmploymentForm };
               // list[editIndexPreviousEmployment].toDate = format(ToDate, 'dd-MMM-yyyy');
               // list[editIndexPreviousEmployment].fromDate = format(FromDate, 'dd-MMM-yyyy');
                setPreviousEmploymentDetailsList(list);
                setIsEditPreviousEmployment(false);
                setEditIndexPreviousEmployment('');
                handleClearPreviousEmploymentInputs();


            } else {
                    const fromData = { ...PreviousEmploymentForm };
                    // fromData.fromDate = FromDate.toLocaleDateString();
                   // fromData.fromDate = format(FromDate, 'dd-MMM-yyyy');
                    //fromData.toDate = format(ToDate, 'dd-MMM-yyyy');
                    // console.log(PreviousEmploymentDetailsList, 'PreviousEmploymentDetailsList', fromData)
                    setPreviousEmploymentDetailsList([...PreviousEmploymentDetailsList, fromData])
                    handleClearPreviousEmploymentInputs();
               
            }
        }
    }

    // Handle Reset PreviousEmployment Inputs
    const handleClearPreviousEmploymentInputs = () => {
        setPreviousEmploymentForm({
            ...PreviousEmploymentForm,
            "companyName": "",
            fromDate: '',
            toDate: ''
        })
       // setToDate(null)
       // setFromDate(null)
        setIsEditPreviousEmployment(false);
        setEditIndexPreviousEmployment('');

    }

    //Handle Edit PreviousEmployment

    const HandlePreviousEmploymentEdit = (item, index) => {
        setIsEditPreviousEmployment(true);
        setEditIndexPreviousEmployment(index);
        const editQulification = PreviousEmploymentDetailsList.find((ele, i) => i === index)
      // setFromDate(editQulification ? new Date(editQulification.fromDate) :'')
       //setToDate(editQulification ? new Date(editQulification.toDate) : "")

        setPreviousEmploymentForm({
            ...PreviousEmploymentForm,
            "companyName": editQulification.companyName,
            "fromDate": editQulification.fromDate,
            "toDate": editQulification.toDate
        })
    }

    // Handle Delete PreviousEmployment
    const HandlePreviousEmploymentDelete = (index) => {
        const filerPreviousEmployment = PreviousEmploymentDetailsList.filter((ele, i) => i !== index);
        setPreviousEmploymentDetailsList(filerPreviousEmployment);
    }

    //Reset Handler
    const handleClear = () => {
        handleTabClick('PersonalDetails')
    }

    let countryData = []
    for (let i = 0; i < countryAllData.length; i++) {
        let mydata = countryAllData[i]?.values
        countryData.push(mydata)
    };

    let StateData = []
    for (let i = 0; i < stateAllData.length; i++) {
        let mydata = stateAllData[i]?.values
        StateData.push(mydata)
    };
  //  console.log(qualificationDetails, 'qualificationDetails')
   // const QualificationDataByEditTime = [...qualificationListDetails];
   // const PrevEmployementDataByEditTime = [...preEmploymentListDetails];

    //console.log(QualificationDetailsList, 'qualificationListDetails', preEmploymentListDetails, 'asdlkjag')

    


    useEffect(() => {
       // console.log(qualificationListDetails)
      //  setQualificationDetailsList(qualificationListDetails);
      //  setPreviousEmploymentDetailsList(preEmploymentListDetails)
     //   YearData();
     //   QualificationData();
       
       
    }, [])

    useEffect(() => {
        if (activeTab === 'QualificationDetails') {
             inputrefs.current['txtQualification'].focus();
            document.title = 'Pay Plus : Qualification Details'
        }
    }, [activeTab])

    

    return (
        <>
            <div className='main-card card border-0'>

                <div className='card-body'>
                    <div className='row ps-3 pt-2'>

                        <div className='row'>
                            <div className='row'>
                                <fieldset className="myfieldset">
                                    <legend className="mylegend"> <h6 className="m-0"><span style={{ color: "red" }}>* </span>Qualification Details</h6></legend>
                                    <div className='row my-2'>
                                        <div className='d-none'>
                                        <div className={`col-md-3 mb-1 cntrl-width`} key="txtQualification">

                                            <select
                                                ref={ref => (inputrefs.current['txtQualification'] = ref)}
                                                className="form-control"
                                                id="txtQualification"
                                                value={QualificationForm.qualification === 'Other' ? QualificationForm?.otherQualification : QualificationForm.qualification}
                                                required
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { QualificationForm.qualification === 'Others' ? inputrefs.current['txtOtherQualification'].focus() : inputrefs.current['txtCollegeName'].focus() };
                                                }}
                                                onChange={(e) => HandleChange('qualification', e.target.value)}>
                                                <option value="">--Select Qualification--</option>
                                                {qualificationData.map((opt, index) => <option key={index} value={opt.name === ' Others' ? QualificationForm.otherQualification : opt.id} >{opt.name}</option>)}
                                            </select>
                                            <div className="error-msg">{errors.qualification}</div>
                                        </div>
                                        {QualificationForm.qualification === 'Others' &&
                                            <InputBox
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtCollegeName'].focus() };
                                                }}
                                                id='txtOtherQualification'
                                                key='txtOtherQualification'
                                                required
                                                maxLength='50' placeholder="Other Qualification"
                                            value={QualificationForm.otherQualification}
                                            error={errors.otherQualification}
                                            onChange={(e) => {
                                                QualificationForm.qualification === 'Others' ? HandleChange('otherQualification', e.target.value) : setQualificationForm(QualificationForm => ({ ...QualificationForm, otherQualification: '' }))
                                            }}
                                            />}

                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtPercentage'].focus() };
                                            }}
                                            id='txtCollegeName'
                                            key='txtCollegeName'
                                          //  divclassname={QualificationForm.qualification === 'Others' ? 'col-lg-3  col-md-3 mb-1' :'col-lg-5  col-md-5 mb-1'}
                                            required
                                            maxLength='50' placeholder="College/University Name"
                                            value={QualificationForm.college}
                                            error={errors.college} onChange={(e) => { HandleChange('college', e.target.value) }}
                                        />
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtYear'].focus() };
                                            }}
                                            id='txtPercentage'
                                            key='txtPercentage'
                                            required
                                            maxLength='6' placeholder="Percentage"
                                            value={QualificationForm.percentage}
                                            error={errors.percentage}
                                            onChange={(e) => { HandleChange('percentage', numberwithDot(e.target.value)) }}
                                            onKeyUp={(e) => {
                                                if (e.target.value > 100) { HandleChange('percentage', '') };
                                            }}
                                        />

                                        <div className={`col-md-3 col-lg-3 cntrl-width mb-1`} key="txtYear">

                                            <select
                                                className="form-control"
                                                ref={ref => (inputrefs.current['txtYear'] = ref)}
                                                id="txtYear"
                                                value={QualificationForm.year}
                                                required
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { handleAddQualifications() };
                                                }}
                                                onChange={(e) => HandleChange('year', e.target.value)}>
                                                <option value="">--Year--</option>
                                                {yearData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}
                                            </select>
                                            <div className="error-msg">{errors.year}</div>
                                        </div>

                                        <div  className='col-lg-3 cntrl-width ' >
                                            <button onClick={() => handleAddQualifications()} className="btn btn-rounded btn-success p-1 px-2" style={{ minWidth: "1.5rem" }} ><i className="fa-solid  fa fa-check"></i></button>
                                            <button onClick={() => handleClearQualificationInputs()} className="btn btn-rounded btn-danger p-1 px-2" style={{ minWidth: "1.5rem" }}><i className="fa-solid  fa-arrow-rotate-left"></i></button>
                                            </div>

                                        </div>
                                        {QualificationDetailsList?.length !== 0 ?

                                            <div className='row pt-3 '>
                                                <div className="table-responsive">
                                                    <table className="table table-bordered table-striped">
                                                        <thead>
                                                            <tr className='light-bg'>
                                                                <th className='d-none' >Action</th>
                                                                <th >Qualification</th>
                                                                <th >Other Qualification</th>
                                                                <th >College/University Name</th>
                                                                <th >Percentage</th>
                                                                <th >Year</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>


                                                            {QualificationDetailsList?.map((item, index) => {
                                                             //   console.log(item,'dgsdjfk')
                                                                return <tr key={index}>
                                                                    <td className='d-none'><i className="fa-regular fa-pen-to-square text-success me-2" onClick={() => HandleQualificationEdit(item, index)} />  <i className="fa-regular fa-trash-can text-danger me-2" onClick={() => HandleQualificationDelete(index)} /></td>
                                                                    <td>{item?.qualification}</td>
                                                                    <td>{item?.otherQualification}</td>
                                                                    <td>{item?.college}</td>
                                                                    <td>{ item?.percentage}</td>
                                                                    <td>{item?.year}</td>

                                                                </tr>
                                                            })}
                                                            {/*{QualificationDataByEditTime?.map((item, index) => {*/}
                                                            {/*    return <tr key={index}>*/}
                                                            {/*        <td><i className="fa-regular fa-pen-to-square text-success me-2" onClick={() => HandleQualificationEdit(item, index)} />  <i className="fa-regular fa-trash-can text-danger me-2" onClick={() => HandleQualificationDelete(index)} /></td>*/}
                                                            {/*        <td>{item?.qualification}</td>*/}
                                                            {/*        <td>{item?.college}</td>*/}
                                                            {/*        <td>{item?.percentage}</td>*/}
                                                            {/*        <td>{item?.year}</td>*/}

                                                            {/*    </tr>*/}
                                                            {/*}) }*/}

                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                            : <span className='text-danger '>No Quailfication Details Save</span>}
                                    </div>
                                </fieldset>
                            </div>
                            <div className='row'>
                                <fieldset className="myfieldset">
                                    <legend className="mylegend"> <h6 className="m-0">{/*<span style={{ color: "red" }}>* </span>*/}Previous Employment Details</h6></legend>
                                    <div className='row my-2'>
                                        <div className='d-none'>
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtFromDate'].focus() };
                                            }}
                                            id='txtCompanyName'
                                         //   divclassname="col-lg-5  col-md-5 mb-1"
                                            maxLength='50' placeholder="Company Name"
                                            value={PreviousEmploymentForm.companyName}
                                            error={errors.companyName} onChange={(e) => { HandleChange('companyName', e.target.value) }}
                                        />

                                        <DateBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtToDate'].focus() };
                                            }}
                                            // label='From Date'
                                            id='txtFromDate'
                                            maxLength='11'
                                           
                                            //isClearable
                                            selected={PreviousEmploymentForm.fromDate === '' ? '' : new Date(PreviousEmploymentForm.fromDate)}
                                            placeholder="From Date"
                                           // value={FromDate}
                                            error={errors.fromDate}
                                            onChange={(e) => HandleChange('fromDate',dateFormate(e))}
                                        // required
                                        />
                                        <DateBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { handleAddPreviousEmployments() };
                                            }}
                                            maxLength='11'
                                            // label='To Date'
                                            id='txtToDate'
                                            //isClearable
                                            selected={PreviousEmploymentForm.toDate === '' ? '' : new Date(PreviousEmploymentForm.toDate)}
                                            placeholder="To Date"
                                            //value={ToDate}
                                            error={errors.toDate}
                                            onChange={(e) => HandleChange('toDate', dateFormate(e))}
                                        //required
                                        />


                                        <div className='col-lg-3 cntrl-width'>
                                            <button onClick={() => handleAddPreviousEmployments()} className="btn btn-rounded btn-success p-1 px-2" style={{ minWidth: "1.5rem" }} ><i className="fa-solid  fa fa-check"></i></button>
                                            <button onClick={() => handleClearPreviousEmploymentInputs()} className="btn btn-rounded btn-danger p-1 px-2" style={{ minWidth: "1.5rem" }}><i className="fa-solid  fa-arrow-rotate-left"></i></button>
                                        </div>


                                        </div>
                                        {PreviousEmploymentDetailsList?.length !== 0 ?

                                        <div className='row pt-3 '>
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-striped">
                                                    <thead>
                                                        <tr className='light-bg'>
                                                            {/*<th >Action</th>*/}
                                                            <th >Company Name</th>
                                                            <th >From Date</th>
                                                            <th >To Date</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>


                                                        {PreviousEmploymentDetailsList?.map((item, index) => {
                                                            return <tr key={index}>
                                                                {/*<td><i className="fa-regular fa-pen-to-square text-success me-2" onClick={() => HandlePreviousEmploymentEdit(item, index)} />  <i className="fa-regular fa-trash-can text-danger me-2" onClick={() => HandlePreviousEmploymentDelete(index)} /></td>*/}
                                                                <td>{item?.companyName}</td>
                                                                <td>{item?.fromDate}</td>
                                                                <td>{item?.toDate}</td>
                                                            </tr>
                                                        })}

                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                            : <span className='text-danger '>No Previous Employment Details Save</span>}

                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div style={{ alignItems: 'center' }} className='d-flex align-items-center justify-content pt-3 d-none'>
                            <button onClick={handleSubmit} className="btn btn-rounded btn-success p-1 px-2"  ><i className="fa-solid  fa fa-check"></i> Submit</button>
                            <button onClick={handleClear} className="btn btn-rounded btn-danger p-1 px-2" ><i className="fa-solid  fa-arrow-rotate-left"></i> Reset</button>
                        </div>

                    </div>

                </div>

            </div >

        </>
    )
}

export default QualificationDetails
