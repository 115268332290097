///<reference path="LoanTypeMaster.jsx" />
import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, AutoCompleteBoxWithLabel, CheckBoxInput, SelectInputBox } from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
//loder
import BlurLoader from "../Loder/BlurLoader";
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { Token, number } from '../Common/Common';

function LoanRepay() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();
    //token Data
    //const TokenData = {
    //    AuthToken: process.env.REACT_APP_SECRET_KEY,
    //    LoginToken: user.loginsessionID,
    //    UserID: user.userID,
    //    CompanyID: user.companyID
    //}
    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({
        employee: '',
        loanRepayID: "0",
        loanId: '',
        repayAmount: "",
        payslipMonth: "",
        payslipYear: "",
        writeOff: true,


    })

    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData);
    const [isUpdate, setIsUpdate] = useState(false);
    const [errors, setErrors] = useState({});
    const [ShowEdit, setShowEdit] = useState(false);
    const [employeeAllData, setEmployeeAllData] = useState([])
    //  const [EmpCode, setEmpCode] = useState()
    const [yearData, setYearData] = useState([]);
    const [monthData, setMonthData] = useState([]);

    //Get Employee Data for AutoComplete
    const GetEmployeeData = (value) => {
        setEmployeeAllData([])
        let temp = {
            Type: "GetEmployee",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        getFormData(value)
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setEmployeeAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setEmployeeAllData(data)
                }
            }
        });
    }


    const YearData = () => {
        setYearData([])
        let temp = {
            Type: "GetYear",
            Prefix: '',

        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setYearData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setYearData(data)
                }
            }
        });
    }
    const MonthData = () => {
        setMonthData([])
        let temp = {
            Type: "GetMonth",
            Prefix: '',

        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setMonthData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setMonthData(data)
                }
            }
        });
    }


    const getFormData = (emp,CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s",
        }
        post(`${API_SERVER}api/loan/GetEMISuspension`, { ...TokenData, ...temp, ...sortConfig, ...filterData, EmpCode: emp ? emp : recipe.employee }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }

    const handleChange1 = (event, value) => {
        getFormData(value);
        //tableData(value)
    };
    useEffect(() => {
        inputrefs.current['txtEmployee'].focus();
        document.title = 'Pay Plus : Loan Repay';
        //getFormData()
        GetEmployeeData();
        YearData();
        MonthData();
    }, [sortConfig, isUpdate])

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "employee", msg: 'Please Enter Employee.' },
            { field: "repayAmount", msg: 'Please Enter Repay Amount.' },
            { field: "payslipMonth", msg: 'Please Enter Payslip Month.' },
            { field: "payslipYear", msg: 'Please Enter Payslip Year.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {

                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        return isValid;
    }

    //Submit Handler
    const handleSubmit = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/loan/InsertUpdateLoanRepay`, { ...TokenData, ...recipe }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        setLoading(false)
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "UNAUTHORIZED") {
                        toast.error(res.Message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        handleClear()
                        setShowEdit(false)
                        //getFormData()
                    }
                }
            });
        }
        else {
            //   toast.error(myString[0]);
            //   inputrefs.current[myString[1]].focus();
        }
    }

    //Handle Edit
    const handleEdit = (Id) => {
        setShowEdit(true)
        setRecipe({ ...recipe, loanId:Id })
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            loanRepayID: "0",
            loanId:'',
            employee: "",
            loanType: '',
            repayAmount: "",
            payslipMonth: "",
            payslipYear: "",
            writeOff: true,
        }))
        setErrors({})
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
       // getFormData()
        setShowEdit(false)
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    let EmployeeData = []
    for (let i = 0; i < employeeAllData.length; i++) {
        let mydata = employeeAllData[i]?.values
        EmployeeData.push(mydata)
    };

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Loan Repay</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>


                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            // onKeyDown={(e) => {
                            //       if (e.key === 'Enter') { inputrefs.current['txtRepayAmount'].focus() };
                            //   }}
                            label='Employee'
                            placeholder="Employee"
                            //divclassname='col-lg-6  '
                            maxLength='10'
                            options={EmployeeData}
                            id='txtEmployee'
                            key='txtEmployee'
                            disabled={ShowEdit}
                            required={true}
                            error={errors.employee}
                            inputValue={recipe.employee ? recipe.employee : ''}
                            onInputChange={(event, newInputValue) => {
                                GetEmployeeData(newInputValue)
                                HandleChange('employee', newInputValue)
                                //getFormData()

                            }}
                        />


                        {/*    <AutoCompleteBox*/}
                        {/*        inputrefs={inputrefs}*/}
                        {/*        // onKeyDown={(e) => {*/}
                        {/*        //       if (e.key === 'Enter') { inputrefs.current[''].focus() };*/}
                        {/*        //   }}*/}
                        {/*        label='Employee'*/}
                        {/*        placeholder="Employee"*/}
                        {/*      //  divclassname='col-lg-6  '*/}
                        {/*      disabled*/}
                        {/*        maxLength='10'*/}
                        {/*        options={EmployeeData}*/}
                        {/*        id='txtEmployee'*/}
                        {/*        //key='txtEmployee'*/}
                        {/*        required={true}*/}
                        {/*        error={errors.employee}*/}
                        {/*        inputValue={recipe.employee ? recipe.employee : ''}*/}
                        {/*        onInputChange={(event, newInputValue) => {*/}
                        {/*            HandleChange('employee', newInputValue)*/}

                        {/*            tableData('', newInputValue)*/}

                        {/*        }}*/}
                        {/*    />*/}

                        {ShowEdit && <>
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtPayslipMonth'].focus() };
                                }}
                                label='Repay Amount'
                                id='txtRepayAmount'
                                key='txtRepayAmount'
                                maxLength='10'
                                placeholder="Repay Amount"
                                required
                                value={recipe.repayAmount}
                                error={errors.repayAmount}
                                onChange={(e) => { HandleChange('repayAmount', number(e.target.value)) }}
                            />
                            {/*

                            <div className={`col-md-3 mb-1 cntrl-width`} key="txtPayslipMonth">
                                <label className="form-label">Payslip Month<span style={{ color: "red" }}> *</span>
                                </label>
                                <select
                                    className="form-control"
                                    ref={ref => (inputrefs.current['txtPayslipMonth'] = ref)}
                                    id="txtPayslipMonth"
                                    value={recipe.payslipMonth}
                                    required
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtPayslipYear'].focus() };
                                    }}

                                    onChange={(e) => HandleChange('payslipMonth', e.target.value)}>
                                    <option value="">--Month--</option>
                                    {monthData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}
                                </select>
                                <div className="error-msg">{errors.payslipMonth}</div>
                            </div>
                            */}
                            <SelectInputBox
                           //     divclassname='col-4 mt-4 p-0 ps-0'
                          //      inputStyle={{ marginTop: '0.1rem' }}
                                label='Payslip Month'
                                inputrefs={inputrefs}
                                required
                                error={errors.payslipMonth}
                                id="txtPayslipMonth"
                                value={recipe.payslipMonth}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtPayslipYear'].focus() };
                                }}

                                onChange={(e) => HandleChange('payslipMonth', e.target.value)}
                                children={<>
                                    <option value="">--Month--</option>
                                    {monthData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}
                                </>}
                            />

                            {/*   <div className={`col-md-3 mb-1 cntrl-width`} key="txtPayslipYear">
                                <label className="form-label">Payslip Year<span style={{ color: "red" }}> *</span>
                                </label>
                                <select
                                    className="form-control"
                                    ref={ref => (inputrefs.current['txtPayslipYear'] = ref)}
                                    id="txtPayslipYear"
                                    value={recipe.payslipYear}
                                    required
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtWriteOff'].focus() };
                                    }}

                                    onChange={(e) => HandleChange('payslipYear', e.target.value)}>
                                    <option value="">--Year--</option>
                                    {yearData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}
                                </select>
                                <div className="error-msg">{errors.payslipYear}</div>
                            </div>
                            */}
                            <SelectInputBox
                                //     divclassname='col-4 mt-4 p-0 ps-0'
                                //     inputStyle={{ marginTop: '0.1rem' }}
                                label='Payslip Year'
                                inputrefs={inputrefs}
                                required
                                error={errors.payslipYear}
                                id="txtPayslipYear"
                                value={recipe.payslipYear}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtWriteOff'].focus() };
                                }}

                                onChange={(e) => HandleChange('payslipYear', e.target.value)}
                                children={<>
                                    <option value="">--Year--</option>
                                    {yearData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}
                                </>}
                            />

                            <CheckBoxInput
                                customclass={'form-check form-switch p-0 m-0 mt-4'}
                                divclassname='col-md-4 col-lg-3 col-6 mb-1 cntrl-width'
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { handleSubmit() };
                                }}
                                checked={recipe.writeOff}
                                label='Write Off'
                                id='txtWriteOff'

                                value={recipe.writeOff}
                                onChange={(e) => HandleChange('writeOff', e.target.checked)}
                            />


                            <FormButton
                                Export={false}
                                handleSubmit={handleSubmit}
                                handleExport={() => handleDownloadExcel(API_SERVER + 'api/Loan/ShowLoanTypeMaster', ExportRequest, 'Loan Type Master')}
                                handleClear={handleClear}
                            />
                        </>}

                    </div>
                </div>
            </div>
            {recipe.employee &&
                <Table
                    Filter={false}
                    totalRecordText='Histroy'
                    Token={TokenData}
                    PageName='LoanRepay'
                    handleEdit={handleEdit}
                    //handleDelete={handleDelete}
                   // handleFilter={() => {
                   //     getFormData();
                   //     setFilterData({
                   //         ...filterData,
                   //     });
                   // }}
                    tableData={result}
                    loading={loading}
                   // filterData={filterData}
                   // setFilterData={setFilterData}
                    currentPageNo={currentPageNo}
                    handleChange1={handleChange1}
                   // handleSorting={handleSorting}
                   // sortConfig={sortConfig}
                />
            }
            {loading && < BlurLoader />}
        </>
    )
}

export default LoanRepay
