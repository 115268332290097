import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, handleFileUploderInput, handleFileUpload, FileUploadChild, handleFileUploader, SelectInputBox } from "../commoncomponents/InputBox";
//import { FileUploader } from "react-drag-drop-files";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
//loder
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { getCommonData } from '../../apiservices/CommonServices';
import { Token, number, numberwithDot } from '../Common/Common';
import BlurLoader from '../Loder/BlurLoader';
import { FileUploader } from "react-drag-drop-files";

function CompanyMaster() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();
    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({
        NewCompanyID: "0",
        CompanyCode: "", CompanyName: "", ContactPerson: "", Address1: "", Address2: "", Country: "", State: ""
        , City: "", PinCode: "", GSTIN: "", MobileNo: "", EmailID: "", PanNo: "", Website: "", EmpPrefix: ""
        , PerDayWorkingHour: "", OTDivisor: "", OTRate: "", OTDaysDivisor: "", ExtraDaysDivisor: ""
        , LeaveEncashDaysDivisor: "", PFApplicable: false, ESICApplicable: false, LWFApplicable: false, PTaxApplicable: false,
        UploadLogo: "", LogoExt: "", LogoName: "",
        UploadHeader: "", HeaderExt: "", HeaderName: "",
        UploadFooter: "", FooterExt: "", FooterName: "",
        UploadStamp: "", StampExt: "", StampName: "",
        PF: "", PFCutoffLimit: "", PFNo: "", EmplFPF: "", EmplrEPF: "", EmplrFPF: "", AddmCharge: "",
        ESICNo: "", ESICLocal: "", EmpLESICCont: "", EmpRESICCont: "", ESICCutoffLimit: "", ESICAgeLimit: "", EmployerName: ""
        , Designation: "", ResidenceAddress: "", Place: "", PFDeductionOnCrossLimit: "", PaySalaryWithExtraDay: true
        , LeaveApprovalType:""

    })

    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [isUpdate, setIsUpdate] = useState(false)
    const [countryPre, setCountryPre] = useState('')
    const [countryAllData, setCountryAllData] = useState([])
    const [statePre, setStatePre] = useState('')
    const [stateAllData, setStateAllData] = useState([])
    const [errors, setErrors] = useState({});

    const fileType = "jpg,png";
    const [imgRead, setImgRead] = useState({
        companyLogoImg: '',
        companyHeaderImg: '',
        companyFooterImg: '',
        companyStampImg: '',
    })

    const handleKeyUp = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    };
    const CountryData = () => {
        let temp = {
            Type: "GetCountry",
            Prefix: countryPre,
            ContextKey: "",
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setCountryAllData(data)
                }
            }
        });
    }

    const StateAllData = (countryCode) => {
        setStateAllData([])
        let temp = {
            Type: "GetCountrywiseState",
            Prefix: statePre,
            ContextKey: countryCode.split(':')[0].trim(),
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setStateAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setStateAllData(data)
                }
            }
        });
    }


    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setLoading(true)
        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/MASTER/ShowCompanyMaster`, { ...TokenData, ...temp, ...sortConfig, ...filterData }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }

    useEffect(() => {
        CountryData()
        //getFormData()
        handleEdit(TokenData.CompanyID)
        inputrefs.current['txtCompanyCode'].focus();
        document.title = 'Pay Plus : Company Master'
    }, [])

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }
    const handleChange1 = (event, value) => {
        getFormData(value);
    };
    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "CompanyCode", msg: 'Please Enter Company Code.' },
            { field: "CompanyName", msg: 'Please Enter Company Name.' },
            { field: "ContactPerson", msg: 'Please Enter Contact Person.' },
            { field: "Address1", msg: 'Please Enter Address1.' },
            { field: "Country", msg: 'Please Enter Country.' },
            { field: "State", msg: 'Please Enter State.' },
            { field: "City", msg: 'Please Enter City.' },
            { field: "PinCode", msg: 'Please Enter Pin Code.' },
            { field: "MobileNo", msg: 'Please Enter Contact No.' },
            { field: "EmailID", msg: 'Please Enter Email ID.' },
            { field: "EmpPrefix", msg: 'Please Enter Emp Prefix.' },
            { field: "PerDayWorkingHour", msg: 'Please Enter Per Day Working Hour.' },
        ]
        if (recipe['OTDivisor'] === 'Fixed') {
            validation.push(
                { field: "OTRate", msg: 'Please Enter OTRate.!' },
                { field: "OTDaysDivisor", msg: 'Please Enter OT Days Divisor..' },
            )
        };

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {

                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }

    //Submit Handler
    const handleSubmit = () => {
        const myString = Validate();
        // if (myString === "") {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/MASTER/InsertUpdateCompanyMaster`, { ...TokenData, ...recipe }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        inputrefs.current[res.focus].focus();
                        toast.error(res.message)
                        setLoading(false)
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "UNAUTHORIZED") {
                        toast.error(res.Message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        handleClear()
                    }
                }
            });
        }
        else {
            //inputrefs.current[myString[1]].focus();
            // toast.error(myString[0]);
        }
    }

    //Handle Edit
    const handleEdit = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/MASTER/EditCompanyMaster`, { ...TokenData, NewCompanyID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    inputrefs.current[res.focus].focus();
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    let data = res.data
                    setRecipe({
                        ...data,
                        CompanyCode: data.companyCode,
                        CompanyName: data.companyName,
                        ContactPerson: data.contactPerson,
                        Address1: data.address1,
                        Address2: data.address2,
                        Country: data.country,
                        State: data.state,
                        City: data.city,
                        PinCode: data.pincode,
                        GSTIN: data.gstin,
                        MobileNo: data.mobileNo,
                        EmailID: data.emailID,
                        PanNo: data.panNo,
                        Website: data.website,
                        EmpPrefix: data.empPrefix,
                        PerDayWorkingHour: data.perDayWorkingHour,
                        OTDivisor: data.otDivisor,
                        OTRate: data.otRate,
                        OTDaysDivisor: data.otDaysDivisor,
                        ExtraDaysDivisor: data.extraDaysDivisor,
                        LeaveEncashDaysDivisor: data.leaveEncashDaysDivisor,
                        PFApplicable: data.pfApplicable,
                        ESICApplicable: data.esicApplicable,
                        LWFApplicable: data.lwfApplicable,
                        PTaxApplicable: data.pTaxApplicable,

                        UploadLogo: data.uploadLogo,
                        LogoExt: data.logoExt,
                        LogoName: data.logoName,

                        UploadHeader: data.uploadHeader,
                        HeaderExt: data.headerExt,
                        HeaderName: data.headerName,

                        UploadFooter: data.uploadFooter,
                        FooterExt: data.footerExt,
                        FooterName: data.footerName,

                        UploadStamp: data.uploadStamp,
                        StampExt: data.stampExt,
                        StampName: data.stampName,
                        PF: data.pf,
                        PFCutoffLimit: data.pfCutoffLimit,
                        PFNo: data.pfNo,
                        EmplFPF: data.emplFPF,
                        EmplrEPF: data.emplrEPF,
                        EmplrFPF: data.emplrFPF,
                        AddmCharge: data.addmCharge,
                        ESICNo: data.esicNo,
                        ESICLocal: data.esicLocal,
                        EmpLESICCont: data.empLESICCont,
                        EmpRESICCont: data.empRESICCont,
                        ESICCutoffLimit: data.esicCutoffLimit,
                        ESICAgeLimit: data.esicAgeLimit,
                        EmployerName: data.employerName,
                        Designation: data.designation,
                        ResidenceAddress: data.residenceAddress,
                        Place: data.place,
                        PFDeductionOnCrossLimit: data.pfDeductionOnCrossLimit,
                        PaySalaryWithExtraDay: data.paySalaryWithExtraDay,
                        LeaveApprovalType: data.leaveApprovalType
                    })
                    handleFileUploderInput('.CompanyLogo', data, 'logoName', 'logoExt', fileType)
                    handleFileUploderInput('.CompanyHeader', data, 'headerName', 'headerExt', fileType)
                    handleFileUploderInput('.CompanyFooter', data, 'footerName', 'footerExt', fileType)
                    handleFileUploderInput('.CompanyStamp', data, 'stampName', 'stampExt', fileType)
                    setImgRead({
                        ...imgRead,
                        companyLogoImg: data.uploadLogo && `data:application/jpg;base64,${data.uploadLogo}`,
                        companyHeaderImg: data.uploadHeader && `data:application/jpg;base64,${data.uploadHeader}`,
                        companyFooterImg: data.uploadFooter && `data:application/jpg;base64,${data.uploadFooter}`,
                        companyStampImg: data.uploadStamp && `data:application/jpg;base64,${data.uploadStamp}`,
                    })
                    console.log(data.leaveApprovalType, '2222222222222222222')
                }

            }
        });
    }
    //  console.log(imgRead.companyLogoImg)

    //Handle Delete
    const handleDelete = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/MASTER/DeleteCompanyMaster`, { ...TokenData, NewCompanyID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    toast.success(res.message)
                    setLoading(false)
                    getFormData()
                    handleClear()
                }
            }
        });
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe, NewCompanyID: "0",
            CompanyCode: "", CompanyName: "",
            ContactPerson: "", Address1: "", Address2: "", Country: "", State: ""
            , City: "", PinCode: "", GSTIN: "", MobileNo: "", EmailID: "", PanNo: "", Website: "", EmpPrefix: ""
            , PerDayWorkingHour: "", OTDivisor: "", OTRate: "", OTDaysDivisor: "", ExtraDaysDivisor: ""
            , LeaveEncashDaysDivisor: "", PFApplicable: false, ESICApplicable: false, LWFApplicable: false, PTaxApplicable: false
            , UploadLogo: "", LogoExt: "", LogoName: "", UploadHeader: "", HeaderExt: "", HeaderName: "", UploadFooter: "", FooterExt: "",
            FooterName: "", UploadStamp: "", StampExt: "", StampName: "",
            PF: "", PFCutoffLimit: "", PFNo: "", EmplFPF: "", EmplrEPF: "", EmplrFPF: "", AddmCharge: "",
            ESICNo: "", ESICLocal: "", EmpLESICCont: "", EmpRESICCont: "", ESICCutoffLimit: "", ESICAgeLimit: "", EmployerName: ""
            , Designation: "", ResidenceAddress: "", Place: "", PFDeductionOnCrossLimit: "", PaySalaryWithExtraDay: false
            , LeaveApprovalType:""
        }))
        setErrors({})
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        // getFormData()
        setImgRead({
            ...imgRead,
            companyLogoImg: '',
            companyHeaderImg: '',
            companyFooterImg: '',
            companyStampImg: '',
        })
        handleFileUploderInput('.CompanyLogo', '', '', '', fileType)
        handleFileUploderInput('.CompanyHeader', '', '', '', fileType)
        handleFileUploderInput('.CompanyFooter', '', '', '', fileType)
        handleFileUploderInput('.CompanyStamp', '', '', '', fileType)
        handleEdit(TokenData.CompanyID)
    }

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality

    let countryData = []
    for (let i = 0; i < countryAllData.length; i++) {
        let mydata = countryAllData[i]?.values
        countryData.push(mydata)
    };

    let StateData = []
    for (let i = 0; i < stateAllData.length; i++) {
        let mydata = stateAllData[i]?.values
        StateData.push(mydata)
    };

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Company Master</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtCompanyName'].focus() };
                            }}
                            label='Company Code' id='txtCompanyCode' maxLength='10' disabled placeholder="Company Code" value={recipe.CompanyCode}
                            onChange={(e) => HandleChange('CompanyCode', e.target.value)} required error={errors.CompanyCode}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtContactPerson'].focus() };
                            }}
                            label='Company Name' id='txtCompanyName' maxLength='100' disabled placeholder="Company Name"
                            value={recipe.CompanyName} error={errors.CompanyName} onChange={(e) => HandleChange('CompanyName', e.target.value)} required
                        />
                        <div className='row'>
                            <div className='row'>
                                <fieldset className="myfieldset">
                                    <legend className="mylegend">Company Details</legend>
                                    <div className='row'>
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtAddress1'].focus() };
                                            }}
                                            label='Contact Person' id='txtContactPerson' maxLength='50' placeholder="Contact Person"
                                            value={recipe.ContactPerson} error={errors.ContactPerson} onChange={(e) => { HandleChange('ContactPerson', (e.target.value)) }} required
                                        />
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtAddress2'].focus() };
                                            }}
                                            label='Address 1' id='txtAddress1' maxLength='100' placeholder="Address 1"
                                            value={recipe.Address1} error={errors.Address1} onChange={(e) => { HandleChange('Address1', (e.target.value)) }} required
                                        />
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtCountry'].focus() };
                                            }}
                                            label='Address 2' id='txtAddress2' maxLength='100' placeholder="Address 2"
                                            value={recipe.Address2} onChange={(e) => { HandleChange('Address2', (e.target.value)) }}
                                        />
                                        <AutoCompleteBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtState'].focus() };
                                            }}
                                            label='Country' placeholder="Country" maxLength='100' options={countryData}
                                            id='txtCountry' required={true} error={errors.Country} inputValue={recipe.Country ? recipe.Country : ''}
                                            onInputChange={(event, newInputValue) => {
                                                HandleChange('Country', newInputValue)
                                                setCountryPre(newInputValue)
                                                console.log(newInputValue, '2222222222222222222')
                                                StateAllData(newInputValue)
                                            }}
                                        />
                                        <AutoCompleteBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtCity'].focus() };
                                            }}
                                            label='State' placeholder="State" maxLength='50' options={StateData} id='txtState'
                                            required={true} error={errors.State} inputValue={recipe.State ? recipe.State : ''}
                                            onInputChange={(event, newInputValue) => {
                                                HandleChange('State', newInputValue)
                                                // setStatePre(newInputValue)
                                            }}
                                        />
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtPinCode'].focus() };
                                            }}
                                            label='City' id='txtCity' maxLength='100' placeholder="City"
                                            value={recipe.City} error={errors.City} onChange={(e) => { HandleChange('City', (e.target.value)) }} required
                                        />
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtMobileNo'].focus() };
                                            }}
                                            label='Pin Code' id='txtPinCode' maxLength='6' placeholder="Pin Code"
                                            value={recipe.PinCode} error={errors.PinCode} onChange={(e) => { HandleChange('PinCode', number(e.target.value)) }} required
                                        />
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtEmailID'].focus() };
                                            }}
                                            required error={errors.MobileNo}
                                            label='Contact No.' id='txtMobileNo' maxLength='10' placeholder="Contact No."
                                            value={recipe.MobileNo} onChange={(e) => { HandleChange('MobileNo', number(e.target.value)) }}
                                        />
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtWebsite'].focus() };
                                            }}
                                            required error={errors.EmailID}
                                            label='Email ID' id='txtEmailID' maxLength='50' placeholder="Email ID"
                                            value={recipe.EmailID} onChange={(e) => { HandleChange('EmailID', (e.target.value)) }}
                                        />
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtGSTIN'].focus() };
                                            }}
                                            label='Website' id='txtWebsite' maxLength='100' placeholder="Website"
                                            value={recipe.Website} onChange={(e) => { HandleChange('Website', (e.target.value)) }}
                                        />
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtPanNo'].focus() };
                                            }}
                                            onKeyUp={(e) => { handleKeyUp('GSTIN', e.target.value.toUpperCase()) }}
                                            label='GSTIN' id='txtGSTIN' maxLength='15' placeholder="GSTIN"
                                            value={recipe.GSTIN} onChange={(e) => { HandleChange('GSTIN', (e.target.value)) }}
                                        />
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtLeaveApprovalType'].focus() };
                                            }}
                                            label='PAN No' id='txtPanNo' maxLength='10' placeholder="PAN No"
                                            value={recipe.PanNo} onChange={(e) => { HandleChange('PanNo', (e.target.value)) }}
                                        />

                                        <SelectInputBox
                                            label='Leave Approval Type'
                                            inputrefs={inputrefs}
                                            id="txtLeaveApprovalType"
                                            value={recipe.LeaveApprovalType}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtEmpPrefix'].focus() };
                                            }}
                                            onChange={(e) => HandleChange('LeaveApprovalType', e.target.value)}
                                            children={<>
                                                <option value="">-- Select --</option>
                                                <option value="0">All Approvar</option>
                                                <option value="1">1st Approval</option>
                                                <option value="2">Any One</option>
                                            </>}
                                        />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='row'>
                                <fieldset className="myfieldset">
                                    <legend className="mylegend">Statutory Information</legend>
                                    <div className='row'>
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtPerDayWorkingHour'].focus() };
                                            }}
                                            label='Emp Prefix' id='txtEmpPrefix' maxLength='5' placeholder="Emp Prefix"
                                            value={recipe.EmpPrefix} error={errors.EmpPrefix} onChange={(e) => { HandleChange('EmpPrefix', e.target.value) }} required
                                        />
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtOTDivisor'].focus() };
                                            }}
                                            label='Per Day Working Hour' id='txtPerDayWorkingHour' maxLength='2' placeholder="Per Day Working Hour"
                                            value={recipe.PerDayWorkingHour} error={errors.PerDayWorkingHour} onChange={(e) => { HandleChange('PerDayWorkingHour', number(e.target.value)) }} required
                                        />
                                        <SelectInputBox
                                            label='OT Divisor'
                                            inputrefs={inputrefs}
                                            //  required
                                            id="txtOTDivisor"
                                            value={recipe.OTDivisor}
                                            //   error={errors.OTDivisor}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtOTRate'].focus() };
                                            }}
                                            onChange={(e) => HandleChange('OTDivisor', e.target.value)}
                                            children={<>
                                                <option value="">-- Select OT Divisor--</option>
                                                <option value="Fixed" >Fixed</option>
                                                <option value="On Actual" >On Actual</option>
                                                <option value="On Actual LessWO">On Actual LessWO</option>
                                            </>}
                                        />

                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtOTDaysDivisor'].focus() };
                                            }}
                                            label='OT Rate'
                                            id='txtOTRate'
                                            maxLength='2'
                                            placeholder="OT Rate"
                                            error={errors.OTRate}
                                            value={recipe.OTRate}
                                            required={recipe.OTDivisor === 'Fixed'}
                                            onChange={(e) => { HandleChange('OTRate', number(e.target.value)) }}
                                        />
                                        {recipe.OTDivisor === 'Fixed' &&
                                            <InputBox
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtExtraDaysDivisor'].focus() };
                                                }}
                                                required={recipe.OTDivisor === 'Fixed'}
                                                label='OT Days Divisor' id='txtOTDaysDivisor' maxLength='2' placeholder="OT Days Divisor"
                                                error={errors.OTDaysDivisor}
                                                value={recipe.OTDaysDivisor} onChange={(e) => { HandleChange('OTDaysDivisor', number(e.target.value)) }}
                                            />}
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtLeaveEncashDaysDivisor'].focus() };
                                            }}
                                            label='Extra Days Divisor' id='txtExtraDaysDivisor' maxLength='2' placeholder="Extra Days Divisor"
                                            value={recipe.ExtraDaysDivisor} onChange={(e) => { HandleChange('ExtraDaysDivisor', number(e.target.value)) }}
                                        />
                                        <InputBox
                                            inputrefs={inputrefs}
                                            label='Leave Encash Days Divisor'
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['chkPFApplicable'].focus() };
                                            }} id='txtLeaveEncashDaysDivisor' maxLength='2' placeholder="Leave Encash Days Divisor"
                                            value={recipe.LeaveEncashDaysDivisor} onChange={(e) => { HandleChange('LeaveEncashDaysDivisor', number(e.target.value)) }}
                                        />
                                        <Switch
                                            inputrefs={inputrefs}
                                            label='PF Applicable'
                                            checked={recipe.PFApplicable}
                                            id='chkPFApplicable'
                                            onChange={(e) => HandleChange('PFApplicable', e.target.checked)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['chkESICApplicable'].focus() };
                                            }}
                                        />
                                        <Switch
                                            inputrefs={inputrefs}
                                            label='ESIC Applicable'
                                            checked={recipe.ESICApplicable}
                                            id='chkESICApplicable'
                                            onChange={(e) => HandleChange('ESICApplicable', e.target.checked)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['chkLWFApplicable'].focus() };
                                            }}
                                        />
                                        <Switch
                                            inputrefs={inputrefs}
                                            label='LWF Applicable'
                                            checked={recipe.LWFApplicable}
                                            id='chkLWFApplicable'
                                            onChange={(e) => HandleChange('LWFApplicable', e.target.checked)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['chkPtaxApplicable'].focus() };
                                            }}
                                        />
                                        <Switch
                                            inputrefs={inputrefs}
                                            label='PTax Applicable'
                                            checked={recipe.PTaxApplicable}
                                            id='chkPtaxApplicable'
                                            onChange={(e) => HandleChange('PTaxApplicable', e.target.checked)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['fileCompanyLogo'].focus() };
                                            }}
                                        />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className='row'>
                            {recipe.PFApplicable === true &&
                                <div className='row'>
                                    <fieldset className="myfieldset">
                                        <legend className="mylegend">PF Details</legend>
                                        <div className='row'>
                                            <InputBox
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtPFCutoffLimit'].focus() };
                                                }}
                                                label='PF No' id='txtPFNo' maxLength='22' placeholder="PF No"
                                                value={recipe.PFNo} error={errors.PFNo} onChange={(e) => { HandleChange('PFNo', e.target.value) }}
                                            />
                                            <InputBox
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtPF'].focus() };
                                                }}
                                                label='PF Cutoff Limit' id='txtPFCutoffLimit' maxLength='5' placeholder="PF Cutoff Limit"
                                                value={recipe.PFCutoffLimit} error={errors.PFCutoffLimit} onChange={(e) => { HandleChange('PFCutoffLimit', numberwithDot(e.target.value)) }}
                                            />
                                            <InputBox
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtEmplFPF'].focus() };
                                                }}
                                                label='PF %' id='txtPF' maxLength='5' placeholder="PF %"
                                                value={recipe.PF} error={errors.PF} onChange={(e) => { HandleChange('PF', numberwithDot(e.target.value)) }}
                                            />
                                            <InputBox
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtEmplrEPF'].focus() };
                                                }}
                                                label='Empl FPF %' id='txtEmplFPF' maxLength='5' placeholder="Empl FPF %"
                                                value={recipe.EmplFPF} error={errors.EmplFPF} onChange={(e) => { HandleChange('EmplFPF', numberwithDot(e.target.value)) }}
                                            />
                                            <InputBox
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtEmplrFPF'].focus() };
                                                }}
                                                label='Emplr EPF' id='txtEmplrEPF' maxLength='5' placeholder="Emplr EPF"
                                                value={recipe.EmplrEPF} error={errors.EmplrEPF} onChange={(e) => { HandleChange('EmplrEPF', numberwithDot(e.target.value)) }}
                                            />
                                            <InputBox
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtAddmCharge'].focus() };
                                                }}
                                                label='Emplr FPF %' id='txtEmplrFPF' maxLength='5' placeholder="Emplr FPF %"
                                                value={recipe.EmplrFPF} error={errors.EmplrFPF} onChange={(e) => { HandleChange('EmplrFPF', numberwithDot(e.target.value)) }}
                                            />
                                            <InputBox
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtPFDeductionOnCrossLimit'].focus() };
                                                }}
                                                label='Addm. Charge' id='txtAddmCharge' maxLength='5' placeholder="Addm. Charge"
                                                value={recipe.AddmCharge} error={errors.AddmCharge} onChange={(e) => { HandleChange('AddmCharge', numberwithDot(e.target.value)) }}
                                            />
                                            <SelectInputBox
                                                label='PF Ded. On Crossing Limit'
                                                inputrefs={inputrefs}
                                                id="txtPFDeductionOnCrossLimit"
                                                value={recipe.PFDeductionOnCrossLimit}
                                                //   error={errors.OTDivisor}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtESICNo'].focus() };
                                                }}
                                                onChange={(e) => HandleChange('PFDeductionOnCrossLimit', e.target.value)}
                                                children={<>
                                                    <option value="">-- Select --</option>
                                                    <option value="0">No Deduction</option>
                                                    <option value="1" selected="">Upto Cut off Limit</option>
                                                    <option value="2">On Basic</option>
                                                    <option value="3">On Ceil Amount</option>
                                                    <option value="4">On Actual Wages</option>
                                                </>}
                                            />
                                        </div>
                                    </fieldset>
                                </div>}
                        </div>
                        <div className='row'>
                            {recipe.ESICApplicable === true &&
                                <div className='row'>
                                    <fieldset className="myfieldset">
                                        <legend className="mylegend">ESI Details</legend>
                                        <div className='row'>
                                            <InputBox
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtESICLocal'].focus() };
                                                }}
                                                label='ESIC No' id='txtESICNo' maxLength='17' placeholder="ESIC No"
                                                value={recipe.ESICNo} error={errors.ESICNo} onChange={(e) => { HandleChange('ESICNo', e.target.value) }}
                                            />
                                            <InputBox
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtEmpLESICCont'].focus() };
                                                }}
                                                label='ESIC Local' id='txtESICLocal' maxLength='25' placeholder="ESIC Local"
                                                value={recipe.ESICLocal} error={errors.ESICLocal} onChange={(e) => { HandleChange('ESICLocal', e.target.value) }}
                                            />
                                            <InputBox
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtEmpRESICCont'].focus() };
                                                }}
                                                label='EmpL ESIC Cont' id='txtEmpLESICCont' maxLength='5' placeholder="EmpL ESIC Cont"
                                                value={recipe.EmpLESICCont} error={errors.EmpLESICCont} onChange={(e) => { HandleChange('EmpLESICCont', numberwithDot(e.target.value)) }}
                                            />
                                            <InputBox
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtESICCutoffLimit'].focus() };
                                                }}
                                                label='EmpR ESIC Cont' id='txtEmpRESICCont' maxLength='5' placeholder="EmpR ESIC Cont"
                                                value={recipe.EmpRESICCont} error={errors.EmpRESICCont} onChange={(e) => { HandleChange('EmpRESICCont', numberwithDot(e.target.value)) }}
                                            />
                                            <InputBox
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtESICAgeLimit'].focus() };
                                                }}
                                                label='ESIC Cutoff Limit' id='txtESICCutoffLimit' maxLength='6' placeholder="ESIC Cutoff Limit"
                                                value={recipe.ESICCutoffLimit} error={errors.ESICCutoffLimit} onChange={(e) => { HandleChange('ESICCutoffLimit', numberwithDot(e.target.value)) }}
                                            />
                                            <InputBox
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtEmployerName'].focus() };
                                                }}
                                                label='ESIC Age Limit' id='txtESICAgeLimit' maxLength='2' placeholder="ESIC Age Limit"
                                                value={recipe.ESICAgeLimit} error={errors.ESICAgeLimit} onChange={(e) => { HandleChange('ESICAgeLimit', number(e.target.value)) }}
                                            />
                                            <InputBox
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtDesignation'].focus() };
                                                }}
                                                label='Employer Name' id='txtEmployerName' maxLength='100' placeholder="Employer Name"
                                                value={recipe.EmployerName} error={errors.EmployerName} onChange={(e) => { HandleChange('EmployerName', e.target.value) }}
                                            />
                                            <InputBox
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtResidenceAddress'].focus() };
                                                }}
                                                label='Designation' id='txtDesignation' maxLength='50' placeholder="Designation"
                                                value={recipe.Designation} error={errors.Designation} onChange={(e) => { HandleChange('Designation', e.target.value) }}
                                            />
                                            <InputBox
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtPlace'].focus() };
                                                }}
                                                label='Residence Address' id='txtResidenceAddress' maxLength='250' placeholder="Residence Address"
                                                value={recipe.ResidenceAddress} error={errors.ResidenceAddress} onChange={(e) => { HandleChange('ResidenceAddress', e.target.value) }}
                                            />
                                            <InputBox
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtOTDivisor'].focus() };
                                                }}
                                                label='Place' id='txtPlace' maxLength='100' placeholder="Place"
                                                value={recipe.Place} error={errors.Place} onChange={(e) => { HandleChange('Place', e.target.value) }}
                                            />
                                        </div>
                                    </fieldset>
                                </div>}
                        </div>
                        <Switch
                            inputrefs={inputrefs}
                            label='Pay Extra Day with Salary Head'
                            checked={recipe.PaySalaryWithExtraDay}
                            id='chkPaySalaryWithExtraDay'
                            onChange={(e) => HandleChange('PaySalaryWithExtraDay', e.target.checked)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtlogoImage'].focus() };
                            }}
                        />
                        <div className='row'>
                            <div className={`col-md-3 mb-1 cntrl-width`}
                                ref={ref => (inputrefs.current['fileCompanyLogo'] = ref)}  >
                                <label className="form-label">Logo</label>
                                <FileUploader
                                    classes='file-upload'
                                    types={fileType.split(",")}
                                    handleChange={(file) => handleFileUploader(file, fileType.split(","), setRecipe, recipe, 'UploadLogo', 'LogoExt', 'LogoName', setImgRead, imgRead, 'companyLogoImg')}
                                    children={<FileUploadChild message={recipe.LogoName + '.' + recipe.LogoExt} types={fileType.split(",")} />}
                                />
                                <div className="form-group" id="dv1" style={imgRead.companyLogoImg ? {} : { display: 'none' }}
                                >
                                    <div className="col-md-8 col-sm-8 col-xs-12 mt-1" style={{ height: '100px', width: '100px' }}>
                                        <img id="txtlogoImage" height="100" width="100" src={imgRead.companyLogoImg} alt="Company Logo" />
                                    </div>
                                </div>
                            </div>

                            <div className={`col-md-3 mb-1 cntrl-width`}
                                ref={ref => (inputrefs.current['fileCompanyHeader'] = ref)}  >
                                <label className="form-label">Header</label>
                                <FileUploader
                                    classes='file-upload'
                                    types={fileType.split(",")}
                                    handleChange={(file) => handleFileUploader(file, fileType.split(","), setRecipe, recipe, 'UploadHeader', 'HeaderExt', 'HeaderName', setImgRead, imgRead, 'companyHeaderImg')}
                                    children={<FileUploadChild message={recipe.HeaderName + '.' + recipe.HeaderExt} types={fileType.split(",")} />}
                                />
                                <div className="form-group" id="dv1" style={imgRead.companyHeaderImg ? {} : { display: 'none' }}
                                >
                                    <div className="col-md-8 col-sm-8 col-xs-12 mt-1" style={{ height: '100px', width: '100px' }}>
                                        <img id="txtHeaderImage" height="100" width="100" src={imgRead.companyHeaderImg} alt="Company Header" />
                                    </div>
                                </div>
                            </div>



                            <div className={`col-md-3 mb-1 cntrl-width`} ref={ref => (inputrefs.current['fileCompanyHeader'] = ref)}>
                                <label className="form-label">Footer</label>
                                <FileUploader
                                    classes='file-upload'
                                    types={fileType.split(",")}
                                    handleChange={(file) => handleFileUploader(file, fileType.split(","), setRecipe, recipe, 'UploadFooter', 'FooterExt', 'FooterName', setImgRead, imgRead, 'companyFooterImg')}
                                    children={<FileUploadChild message={recipe.FooterName + '.' + recipe.FooterExt} types={fileType.split(",")} />}
                                />

                                <div className="form-group" id="dv1" style={imgRead.companyFooterImg ? {} : { display: 'none' }} >
                                    <div className="col-md-8 col-sm-8 col-xs-12 mt-1" style={{ height: '100px', width: '100px' }}>
                                        <img id="txtFooter" height="100" width="100" src={imgRead.companyFooterImg} alt="Company Footer" />
                                    </div>
                                </div>
                            </div>

                            <div className={`col-md-3 mb-1 cntrl-width`} key="fileCompanyStamp">
                                <label className="form-label">Stamp</label>
                                <FileUploader
                                    classes='file-upload'
                                    types={fileType.split(",")}
                                    handleChange={(file) => handleFileUploader(file, fileType.split(","), setRecipe, recipe, 'UploadStamp', 'StampExt', 'StampName', setImgRead, imgRead, 'companyStampImg')}
                                    children={<FileUploadChild message={recipe.StampName + '.' + recipe.StampExt} types={fileType.split(",")} />}
                                />
                                <div className="form-group" id="dv1" style={imgRead.companyStampImg ? {} : { display: 'none' }} >
                                    <div className="col-md-8 col-sm-8 col-xs-12 mt-1" style={{ height: '100px', width: '100px' }}>
                                        <img id="txtStamp" height="100" width="100" src={imgRead.companyStampImg} alt="Company Stamp" />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <FormButton
                            handleSubmit={handleSubmit}
                            handleClear={handleClear}
                        />
                    </div>

                </div>

            </div >



            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}
        </>
    )
}

export default CompanyMaster
