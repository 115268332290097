import React, { useEffect, useRef, useState } from 'react'
import { InputBox, FormButton, FormButtonWithPrint, PasswordInputBox } from "../commoncomponents/InputBox";

import { useAuth } from '../../context/auth';

//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER } from '../../Utils';

//services
import { post } from '../../apiservices/service';
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { Token } from '../Common/Common';
function ChangePassword() {
    const inputrefs = useRef([]);
    const { user, sessionExpired, logout } = useAuth();

    //token Data
    //const TokenData = {
    //    AuthToken: process.env.REACT_APP_SECRET_KEY,
    //    LoginToken: user.loginsessionID,
    //    UserID: user.userID,
    //    CompanyID: user.companyID
    //}
    const TokenData = Token()
    //initial State
    const [recipe, setRecipe] = useState({ OldPassword: '', NewPassword: '', ConfirmPassword: '' })
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false)



    const handleTogglePassword = (field) => {
        setShowPassword((prevShowPassword) => ({ ...prevShowPassword, [field]: !prevShowPassword[field] }));
    };
    //const [showPassword,setShowPassword] = useState(true)


    //const handleShowPassword = () => {
    //    setShowPassword(!showPassword)
    //    console.log(showPassword, "showPassword")
    //}


    useEffect(() => {
        inputrefs.current['txtOldPassword'].focus();
        document.title = 'Pay Plus: Change Password'
    }, [])

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //----------------------Handle Validate----------------//
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "OldPassword", msg: 'Please Enter Old Password.' },
            { field: "NewPassword", msg: 'Please Enter New Password.' },
            { field: "ConfirmPassword", msg: 'Please Enter Confirm Password.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {

                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }

    //Submit Handler
    const handleSubmit = () => {
        const myString = Validate();
        // if (myString === "") {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/User/ChangePassword`, { ...recipe, ...TokenData }, (res) => {
                // console.log(res);
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        setLoading(false)   
                        toast.error(res.message)
                        inputrefs.current[res.focus].focus()
                        // handleClear()
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "UNAUTHORIZED") {
                        toast.error(res.Message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        handleClear()
                        logout()
                    }
                }
            });
        }
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({ ...recipe, OldPassword: '', NewPassword: '', ConfirmPassword: '' }))
        setErrors({})
    }

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Change Password</h6>
                </div>
                <div className='card-body w-100 row  '>
                    <div className='col-lg-4 col-md-6 col-sm-12 px-4'>

                        <div>
                            <PasswordInputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtNewPassword'].focus() };
                                }}
                                divclassname=' mb-2'
                                label='Old Password'
                                id='txtOldPassword'
                                maxLength='15'
                                required
                                placeholder="Old Password"
                                value={recipe.OldPassword}
                                error={errors.OldPassword}
                                onChange={(e) => HandleChange('OldPassword', e.target.value)}
                            />
                        </div>
                        <div>


                            <PasswordInputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConfirmPassword'].focus() };
                                }}
                                divclassname=' mb-2'
                                label='New Password'
                                id='txtNewPassword'
                                maxLength='15'
                                placeholder="New Password"
                                required
                                value={recipe.NewPassword}
                                error={errors.NewPassword}
                                onChange={(e) => HandleChange('NewPassword', e.target.value)}

                            />
                        </div>

                        <div>

                            <PasswordInputBox
                                divclassname=' mb-2'
                                inputrefs={inputrefs}
                                label='Confirm New Password'
                                id='txtConfirmPassword'
                                maxLength='15'
                                required
                                placeholder="Confirm New Password"
                                value={recipe.ConfirmPassword}
                                error={errors.ConfirmPassword}
                                onChange={(e) => HandleChange('ConfirmPassword', e.target.value)}
                            />
                        </div>

                        <FormButtonWithPrint
                            save={true}
                            print={false}
                            reset={true}
                            handleSubmit={handleSubmit}
                            handleClear={handleClear}
                        />
                    </div>

                    <div className='col-lg-8 col-md-6 col-sm-12 password-policy-border  border border-1  p-0  my-sm-2'>
                        <div className='w-100 py-2 password-policy px-3'>
                            <h5 className="m-0 font-weight-bold text-white">Password Policy</h5>
                        </div>
                        <div className='p-3'>
                            <div className="form-group px-2">
                                <li class="ResetText">White spaces are not allowed.</li>
                                <li class="ResetText">Password must be between 8 to 15 character(s).</li>
                                <li class="ResetText">Password must be combination of number, upper case letter, lower case letter and special character [@@$#!() ].</li>
                                <li class="ResetText">New password and confirm password must be same.</li>
                                <li class="ResetText">Old password and New password should be different.</li>
                            </div>
                        </div>
                    </div>
                </div>

            </div>



            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}
        </>
    )
}

export default ChangePassword;