import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/auth';
import { TableWithoutFilter } from '../commoncomponents/TableWithoutFilter';
//loder
import BlurLoader from "../Loder/BlurLoader";
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER,  initializeFilterData } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { Token } from '../Common/Common';
import { AutoCompleteBox, CustomButton } from '../commoncomponents/InputBox'
import { useRef } from 'react';
import { getCommonData } from '../../apiservices/CommonServices';
import Modal from 'react-bootstrap/Modal';

function LeaveApproval() {
    const { user, sessionExpired } = useAuth();
    const inputrefs = useRef([]);


    //token Data
    const TokenData = Token()

    //initial state
    const [recipe, setRecipe] = useState({
        employee: '',

    })
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [isReject, setIsReject] = useState(false);
    const [ShowError, setShowError] = useState(false);
    const [ID, setID] = useState('')
    const [Emp, setEmp] = useState('')

    const [rejectReason, setRejectReason] = useState("");
    const [employeeAllData, setEmployeeAllData] = useState([])



    //Get Employee Data for AutoComplete
    const GetEmployeeData = (value) => {
        setEmployeeAllData([])
        let temp = {
            Type: "GetLeaveAppliedEmployee",
            Prefix: value ? value : '',
            ContextKey: 'Team',
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setEmployeeAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setEmployeeAllData(data)
                }
            }
        });
    }

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }


    const handleChange1 = (event, value) => {
        getFormData(value);
    };

    useEffect(() => {
        document.title = 'Pay Plus : Pending Leave Approval'
        GetEmployeeData();
    }, [sortConfig])

    const getFormData = (emp, CurrentPage) => {


        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "S"
        }
        post(`${API_SERVER}api/Leave/GetLeaveApprovalData`, { ...TokenData, ...temp, ...sortConfig, ...filterData, employee: emp ? emp : '' }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }


    //handleChange
    const handleChange = (event) => {
        setRejectReason(event.target.value);
    };

    //Handle Approve
    const handleApprove = (Id, Employee) => {
        setLoading(true)
        post(`${API_SERVER}api/Leave/UpdateLeaveData`, { ...TokenData, ID: Id, Type: 'Approve', EmpCode: Employee }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.status === "EXPIRED") {
                    toast.error(res.message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.mssage);
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.success(res.message);
                    setRejectReason('')
                    setIsReject(false);
                    getFormData();
                }
            }
        });
    }



    //Handle Reject
    const handleReject = (Id, Employee) => {
        setIsReject(true);
        setID(Id);
        setEmp(Employee)
    }

    const handleRejectSubmit = () => {

        if (rejectReason !== "") {
            setLoading(true)
            post(`${API_SERVER}api/Leave/UpdateLeaveData`, { ...TokenData, ID: ID, Type: 'Reject', EmpCode: Emp, RejectedReason: rejectReason }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        setLoading(false)
                    } else if (res.status === "EXPIRED") {
                        toast.error(res.message)
                        sessionExpired()
                    } else if (res.status === "UNAUTHORIZED") {
                        toast.error(res.mssage);
                        setLoading(false);
                    } else {
                        toast.success(res.message)
                        setIsReject(false);
                        setLoading(false)
                        setRejectReason('')
                        getFormData();
                    }
                }
            });
        } else {
            setShowError(true)
        }
    }


    let EmployeeData = []
    for (let i = 0; i < employeeAllData.length; i++) {
        let mydata = employeeAllData[i]?.values
        EmployeeData.push(mydata)
    };


    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Pending Leave Approval</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>


                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            // onKeyDown={(e) => {
                            //       if (e.key === 'Enter') { inputrefs.current['txtRepayAmount'].focus() };
                            //   }}
                            label='Employee'
                            placeholder="Employee"
                            //divclassname='col-lg-6  '
                            maxLength='10'
                            options={EmployeeData}
                            id='txtEmployee'
                            key='txtEmployee'
                            //disabled={ShowEdit}
                            //   required={true}
                            // error={errors.employee}
                            inputValue={recipe.employee ? recipe.employee : ''}
                            onInputChange={(event, newInputValue) => {
                                GetEmployeeData(newInputValue)
                                HandleChange('employee', newInputValue)
                                console.log(newInputValue)
                                getFormData(newInputValue)

                            }}
                        />

                    </div>
                </div>
            </div>
            {/*   Modal for Re-submit Request    */}
            <Modal show={isReject} onHide={() => {
                setIsReject(false)
                setShowError(false)
                setRejectReason('')
            }}>
                {/*<Modal.Header closeButton>*/}
                {/*    <Modal.Title>Reject Leave</Modal.Title>*/}
                {/*</Modal.Header>*/}
                <Modal.Body>
                    <h6 className='mb-0 ps-0 ms-0'>Reason for Reject Leave</h6>
                    <div className='row p-3' >
                        <label className='ps-0' > Reason <span style={{ color: "red" }}> *</span></label>
                        <textarea name='Request to Resubmit' className='col-md-12 pt-2' id="txtReason" rows="3 " value={rejectReason} onChange={handleChange} />
                        {(ShowError !== false && rejectReason === '') ? <span className="text-danger ps-0">Please Enter Reason for Reject.!</span> : <></>}
                    </div>

                </Modal.Body>
                <Modal.Footer>

                    <div className='mb-2 d-md-flex gap-2'>
                        <CustomButton
                            id='btnVerifyCandidate'
                            ButtonIcon='fa-check'
                            ButtonText='Send Request'
                            ButtonClass='btn-primary btn-sm me-2 mt-1'
                            onClick={handleRejectSubmit}
                        />
                        <CustomButton
                            id='btnReSubmit'
                            ButtonIcon='fa-close'
                            ButtonText='Cancel Request'
                            ButtonClass='btn-danger btn-sm mt-1'
                            onClick={() => {
                                setIsReject(false)
                                setRejectReason('')
                                setShowError(false)
                            }}
                        />
                    </div>
                </Modal.Footer>
            </Modal>

            {/* Table Component start*/}
            <TableWithoutFilter
                Token={TokenData}
                PageName='LeaveMaster'
                showView={ false}
                showApprove={ true}
                showReject={ true}
                showTotalRecord={ true}
                handleApprove={handleApprove}
                handleReject={handleReject}
                handleFilter={() => {
                    getFormData();
                    setFilterData({
                        ...filterData,
                    });
                }}
                tableData={result}
                //  loading={loading}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting=''
                sortConfig={sortConfig}
            />
            {/* Table Component End*/}


            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}
        </>
    )
}

export default LeaveApproval