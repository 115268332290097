import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox } from "../commoncomponents/InputBox";

import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';

//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';

//services
import { getCommonData } from '../../apiservices/CommonServices';
import { post } from '../../apiservices/service';
import { Token, number } from '../Common/Common';

function BranchMaster() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();

    //token Data
    //const TokenData = {
    //    AuthToken:  process.env.REACT_APP_SECRET_KEY,
    //    LoginToken: user.loginsessionID,
    //    UserID: user.userID,
    //    CompanyID: user.companyID
    //}
    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({
        BranchName: '',
        BranchID: '',
        Address: '',
        BranchHead: '',
        State: '',
        City: '',
        ContactNo: '',
        Email: '',
        PTaxApplicable: false,
        LWFApplicable: false,
        PFAppOnExtraDays: false,
        Active: true,
        Company: TokenData.Company,
    })
    const [errors, setErrors] = useState({});
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
  //  const [countryAllData, setCountryAllData] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const [stateAllData, setStateAllData] = useState([])
    const [GetState, setGetState] = useState('')
    const [companyAllData, setCompanyAllData] = useState([])

    //Get Company Autocomplete Data
    const getCompanyData = () => {
        let temp = {
            Type: "GetCompany",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setCompanyAllData(data)
                }
            }
        });
    }

    const StateAllData = (value) => {

        let temp = {
            Type: "GetState",
            Prefix: value?value:'',
            ContextKey: "",
            ContextKey2: ""
        }

        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {

                    setStateAllData([])
                } else {

                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setStateAllData(data)
                }
            }
        });
    }
   

    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/MASTER/ShowBranchMaster`, { ...TokenData, ...temp, ...sortConfig, ...filterData }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }

    const handleChange1 = (event, value) => {
        getFormData(value);
    };

    useEffect(() => {
        inputrefs.current['txtCompany'].focus()
        document.title = 'Pay Plus : Branch Master'
        getFormData()
        getCompanyData()
    }, [sortConfig, isUpdate])

  

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //validation handler
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            /*{ field: "CountryCode", msg: 'Please Enter Country Name.' },*/
            { field: "BranchName", msg: 'Please Enter Branch Name.' },
            { field: "Address", msg: 'Please Enter Branch Address.' },
            { field: "BranchHead", msg: 'Please Enter Branch Head Name.' },
            { field: "State", msg: 'Please Select State.' },
            { field: "Company", msg: 'Please Select Company.' },
            { field: "City", msg: 'Please Enter City.' },
           { field: "ContactNo", msg: 'Please Enter ContactNo.' },
         { field: "Email", msg: 'Please Enter Email.' },

        ]
     
        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }

    //Submit Handler
    const handleSubmit = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/MASTER/InsertUpdateBranchMaster`, { ...TokenData, ...recipe }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        setLoading(false);
                        toast.error(res.message);
                        inputrefs.current[res.focus].focus();
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "UNAUTHORIZED") {
                        setLoading(false);
                        toast.error(res.Message);
                    } else {
                        setLoading(false);
                        toast.success(res.message);
                        getFormData();
                        handleClear();
                    }
                }
            });
        }
    }

    //Handle Edit
    const handleEdit = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/MASTER/EditBranchMaster`, { ...TokenData, BranchID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    // CountryData()
                    let data = res.data
                    setRecipe({
                        ...data,
                        BranchName: data.branchName,
                        Address: data.address,
                        BranchHead: data.branchHead,
                        State: data.state,
                        Company: data.company,
                        City: data.city,
                        ContactNo: data.contactNo,
                        Email: data.email,
                        PTaxApplicable: data.pTaxApplicable,
                        LWFApplicable: data.lwfApplicable,
                        PFAppOnExtraDays: data.pfAppOnExtraDays,
                        Active: data.active
                    })
                }
            }
        });


    }

    //Handle Delete
    const handleDelete = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/MASTER/DeleteBranchMaster`, { ...TokenData, BranchID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else {
                    toast.success(res.message)
                    setLoading(false)
                    getFormData()
                    handleClear()
                }
            }
        });
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            BranchName: '',
            BranchID: '0',
            Address: '',
            BranchHead: '',
            State: '',
            City: '',
            ContactNo: '',
            Email: '',
            PTaxApplicable: false,
            LWFApplicable: false,
            PFAppOnExtraDays: false,
            Active: true,
            Company: TokenData.Company,
        }))
        setErrors({})
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    let stateData = []
    for (let i = 0; i < stateAllData.length; i++) {
        let mydata = stateAllData[i]?.values
        stateData.push(mydata)
    };
    
    let CompanyData = []
    for (let i = 0; i < companyAllData.length; i++) {
        let mydata = companyAllData[i]?.values
        CompanyData.push(mydata)
    };

    //let countryData = []
    //for (let i = 0; i < countryAllData.length; i++) {
    //    let mydata = countryAllData[i]?.values
    //    countryData.push(mydata)
    //};


    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality

    return (

        <>

            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Branch Master</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>

                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtBranchName'].focus() };
                            }}
                            disabled 
                            label='Company'
                            placeholder="Company"
                            maxLength='50'
                            options={CompanyData}
                            id='txtCompany'
                            required={true}
                            error={errors.Company}
                            inputValue={recipe.Company ? recipe.Company : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('Company', newInputValue)
                            }}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtAddress'].focus()
                                }
                            }}
                            label='Branch Name'
                            id='txtBranchName'
                            maxLength='50'
                            placeholder="Branch Name"
                            value={recipe.BranchName}
                            error={errors.BranchName}
                            onChange={(e) => HandleChange('BranchName', e.target.value)}
                            required
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtBranchHead'].focus()
                                }
                            }}
                            label='Address'
                            id='txtAddress'
                            maxLength='100'
                            placeholder="Address"
                            value={recipe.Address}
                            error={errors.Address}
                            onChange={(e) => HandleChange('Address', e.target.value)}
                            required
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtStateName'].focus() };

                            }}
                            label='Branch Head Name'
                            id='txtBranchHead'
                            maxLength='100'
                            placeholder="Branch Head Name"
                            value={recipe.BranchHead}
                            error={errors.BranchHead}
                            onChange={(e) => HandleChange('BranchHead', e.target.value)}
                            required
                        />
                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtCity'].focus() };
                            }}
                            label='State'
                            placeholder="State"
                            maxLength='50'
                            options={stateData}
                            id='txtStateName'
                            required
                            error={errors.State}
                            //onFocus={() => StateAllData()}
                            inputValue={recipe.State ? recipe.State : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('State', newInputValue)
                                setGetState(newInputValue)
                                StateAllData(newInputValue)
                            }}
                        />
                       
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtContactNo'].focus()
                                }
                            }}
                            label='City'
                            id='txtCity'
                            maxLength='100'
                            placeholder="City"
                            value={recipe.City}
                            error={errors.City}

                            required
                            onChange={(e) => HandleChange('City', e.target.value)}

                        /> 
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtEmail'].focus()
                                }
                            }}
                            label='Contact No.'
                            id='txtContactNo'
                            maxLength='10'
                            placeholder="Contact No."
                            value={recipe.ContactNo}
                            error={errors.ContactNo}
                            required
                            onChange={(e) => HandleChange('ContactNo', e.target.value.replace(/\D/g, ""))}

                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtLWFApplicable'].focus()
                                }
                            }}
                            label='Email'
                            id='txtEmail'
                            maxLength='50'
                            placeholder="Email"
                            value={recipe.Email}
                            error={errors.Email}

                            required
                            onChange={(e) => HandleChange('Email', e.target.value)}

                        />
                        <Switch
                            inputrefs={inputrefs}
                            label='LWF Applicable'
                            checked={recipe.LWFApplicable}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtPTaxApplicable'].focus()
                                }
                            }}
                            id='txtLWFApplicable'
                            nextinputid='txtPTaxApplicable'
                            onChange={(e) => HandleChange('LWFApplicable', e.target.checked)}
                        />
                        <Switch
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtPFAppOnExtraDays'].focus()
                                }
                            }}
                            label='PTax Applicable'
                            checked={recipe.PTaxApplicable}
                            id='txtPTaxApplicable'
                            nextinputid='txtPFAppOnExtraDays'
                            onChange={(e) => HandleChange('PTaxApplicable', e.target.checked)}
                        />
                        <Switch
                            inputrefs={inputrefs}
                            label='PF Applicable ON Extra Days'
                            checked={recipe.PFAppOnExtraDays}
                            id='txtPFAppOnExtraDays'
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtIsActive'].focus()
                                }
                            }}
                            nextinputid='txtIsActive'
                            onChange={(e) => HandleChange('PFAppOnExtraDays', e.target.checked)}
                        />
                        <Switch
                            inputrefs={inputrefs}
                            label='Active'
                            checked={recipe.Active}
                            id='txtIsActive'
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { handleSubmit() };
                            }}
                            // nextinputid='txtFranchiseGroup'
                            onChange={(e) => HandleChange('Active', e.target.checked)}
                        />

                        <FormButton
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/MASTER/ShowBranchMaster', ExportRequest, 'Branch Master')}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>

            {/* Table Component start*/}
            <Table
                Token={TokenData}
                PageName='BranchMaster'
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleFilter={() => {
                    getFormData();
                    setFilterData({
                        ...filterData,
                    });
                }}
                tableData={result}
                loading={loading}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
            {/* Table Component End*/}


            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}

        </>
    )
}

export default BranchMaster