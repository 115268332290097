/// <reference path="documentSubTypemaster.jsx" />
import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, AutoCompleteBoxWithLabel, SelectInputBox } from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
//loder
import BlurLoader from "../Loder/BlurLoader";
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { getMasterData } from '../../apiservices/CommonServices';
import { number, Token } from '../Common/Common';

function DocumentSubTypeMaster() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();
    //token Data
    //const TokenData = {
    //    AuthToken: process.env.REACT_APP_SECRET_KEY,
    //    LoginToken: user.loginsessionID,
    //    UserID: user.userID,
    //    CompanyID: user.companyID
    //} 
    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({
        DocumentType: '',
        DocumentSubTypeID: "",
        DocumentSubType: "",
        Active: true,

    })

    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData);
    const [isUpdate, setIsUpdate] = useState(false);
    const [documentTypeData, setDocumentTypeData] = useState([]);
    const [errors, setErrors] = useState({});

    const DocumentTypeData = () => {
        setDocumentTypeData([])
        let temp = {
            Type: "DocumentType",
            Prefix: '',
            ContextKey: '',
            ContextKey2: ""
        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setDocumentTypeData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setDocumentTypeData(data)
                }
            }
        });
    }

    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/Master/ShowDocumentSubTypeMaster`, { ...TokenData, ...temp, ...sortConfig, ...filterData }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }

    const handleChange1 = (event, value) => {
        getFormData(value);
    };

    useEffect(() => {
        inputrefs.current['txtDocumentType'].focus();
        document.title = 'Pay Plus : Document SubType Master';
        getFormData()
        DocumentTypeData();

    }, [sortConfig, isUpdate])


    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //validation handler
    //const Validate = () => {

    //    if (recipe.DocumentType === "") {
    //        return ["Please Select Document Type.!  ", "txtDocumentType"]
    //    } if (recipe.DocumentSubType === "") {
    //        return ["Please Enter Document Sub Type.!  ", "txtDocumentSubType"]
    //    } else {
    //        return ""
    //    }
    //}

    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "DocumentType", msg: 'Please Enter Document Type.' },
            { field: "DocumentSubType", msg: 'Please Enter Document Sub Type.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {

                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }




    //Submit Handler
    const handleSubmit = () => {
        const myString = Validate();
        //if (myString === "") {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/Master/InsertUpdateSubDocumentTypeMaster`, { ...TokenData, ...recipe }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        inputrefs.current[res.focus].focus();
                        setLoading(false)
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "UNAUTHORIZED") {
                        toast.error(res.Message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        getFormData()
                        handleClear()
                    }
                }
            });
        }
        else {
            //   toast.error(myString[0]);
            //   inputrefs.current[myString[1]].focus();
        }
    }

    //Handle Edit
    const handleEdit = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/Master/EditDocumentSubTypeMaster`, { ...TokenData, DocumentSubTypeID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)

                    let data = res.data
                    setRecipe({
                        ...data,
                        DocumentSubType: data.documentSubType,
                        DocumentType: data.documentType,
                        Active: data.active

                    })
                }
            }
        });
    }

    //Handle Delete
    const handleDelete = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/Master/DeleteDocumentSubTypeMaster`, { ...TokenData, DocumentSubTypeID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else {
                    toast.success(res.message)
                    setLoading(false)
                    getFormData()
                    handleClear()
                }
            }
        });
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            DocumentSubTypeID: "0",
            DocumentType: '',
            DocumentSubType: "",
            Active: true,
        }))
        setErrors({})
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        getFormData()
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0"> Document SubType Master</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        {/*<div className={`col-md-3 mb-1 cntrl-width`} key="txtDocumentType">*/}
                        {/*    <label className="form-label">Document Type<span style={{ color: "red" }}> *</span>*/}
                        {/*    </label>*/}
                        {/*    <select*/}
                        {/*        ref={ref => (inputrefs.current['txtDocumentType'] = ref)}*/}
                        {/*        className="form-control"*/}
                        {/*        id="txtDocumentType"*/}
                        {/*        maxLength='50'*/}
                        {/*        value={recipe.DocumentType}*/}
                        {/*        error={recipe.DocumentType}*/}
                        {/*        onKeyDown={(e) => {*/}
                        {/*            if (e.key === 'Enter') { inputrefs.current['txtDocumentSubType'].focus() };*/}
                        {/*        }}*/}
                        {/*        onChange={(e) => HandleChange('DocumentType', e.target.value)}>*/}
                        {/*        <option value="">--Select Document Type--</option>*/}
                        {/*        {documentTypeData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}*/}
                        {/*    </select>*/}
                        {/*    <div className="error-msg">{errors.DocumentType}</div>*/}
                        {/*</div>*/}

                        <SelectInputBox
                            label='Document Type'
                            inputrefs={inputrefs}
                            required
                            id="txtDocumentType"
                            value={recipe.DocumentType}
                            error={errors.DocumentType}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtDocumentSubType'].focus() };
                            }}
                            onChange={(e) => HandleChange('DocumentType', e.target.value)}
                            children={<>
                                <option value="">--Select Document Type--</option>
                                {documentTypeData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}
                            </>}
                        />
                        {/*<AutoCompleteBox*/}
                        {/*    inputrefs={inputrefs}*/}
                        {/*    onKeyDown={(e) => {*/}
                        {/*        if (e.key === 'Enter') { inputrefs.current['txtDocumentSubType'].focus() };*/}
                        {/*    }}*/}
                        {/*    label='Document Type' placeholder="Document Type" maxLength='50' options={DocumentTypeDataOption} id='txtDocumentType'*/}
                        {/*    required={true} inputValue={recipe.DocumentType ? recipe.DocumentType : ''}*/}
                        {/*    onInputChange={(event, newInputValue) => {*/}
                        {/*        HandleChange('DocumentType', newInputValue)*/}
                        {/*        setDocumentTypePre(newInputValue)*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtIsActive'].focus() };
                            }}
                            label='Document Sub Type '
                            id='txtDocumentSubType'
                            maxLength='50'
                            placeholder="Document Sub Type "
                            value={recipe.DocumentSubType}
                            error={errors.DocumentSubType}
                            onChange={(e) => HandleChange('DocumentSubType', e.target.value)}
                            required
                        />


                        <Switch
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { handleSubmit() };
                            }}
                            label='Active'
                            checked={recipe.Active}
                            id='txtIsActive'
                            // nextinputid='txtFranchiseGroup'
                            onChange={(e) => HandleChange('Active', e.target.checked)}
                        />



                        <FormButton
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/Master/ShowDocumentSubTypeMaster', ExportRequest, 'Document SubType Master')}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>

            <Table
                Token={TokenData}
                PageName='DocumentSubTypeMaster'
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleFilter={() => {
                    getFormData();
                    setFilterData({
                        ...filterData,
                    });
                }}
                tableData={result}
                loading={loading}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
            {loading && < BlurLoader />}
        </>
    )
}

export default DocumentSubTypeMaster
