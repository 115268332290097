///<reference path="LoanTypeMaster.jsx" />
import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, AutoCompleteBoxWithLabel, DateBox, MonthBox, YearBox, SelectInputBox } from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
//loder
import BlurLoader from "../Loder/BlurLoader";
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { Token, currentMonth, currentYear, dateFormate, number, numberwithDot } from '../Common/Common';

function LoanMaster() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();
    //token Data
    //const TokenData = {
    //    AuthToken: process.env.REACT_APP_SECRET_KEY,
    //    LoginToken: user.loginsessionID,
    //    UserID: user.userID,
    //    CompanyID: user.companyID
    //}
    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({
        loanID: "0",
        loanCode: "",
        employee: '',
        loanType: '',
        loanDate: '',
        loanAmount: '',
        interestAmount: '',
        emi: '',
        noOfInstallment: '',
        approvedDate: '',
        approvedBy: '',
        emiStartFromMonth:'',
        emiStartFromYear:'',
    })

    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData);
    const [isUpdate, setIsUpdate] = useState(false);
    const [employeeAllData, setEmployeeAllData] = useState([])
    const [loanTypeAllData, setLoanTypeAllData] = useState([])
    const [approvalEmployeeAllData, setApprovalEmployeeAllData] = useState([])
    const [errors, setErrors] = useState({});
    const [EMIStartFrom, setEMIStartFrom] = useState({
        month: '',
        year: '',
    })
   // console.log(EMIStartFrom, '121212212121', recipe)
    //Get Employee Data for AutoComplete
    const GetEmployeeData = (value) => {
        setEmployeeAllData([])
        let temp = {
            Type: "GetEmployee",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        //setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setEmployeeAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setEmployeeAllData(data)
                }
            }
        });
    }
    //Get Loan Type Data for AutoComplete
    const GetLoanTypeData = (value) => {
        setLoanTypeAllData([])
        let temp = {
            Type: "GetLoanType",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        //  setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setLoanTypeAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setLoanTypeAllData(data)
                }
            }
        });
    }

    // Get Approval Employee Data
    const GetApprovalEmployeeData = (value, contextKey) => {
        console.log(contextKey)
        let temp = {
            Type: "GetApprovalEmployee",
            Prefix: value ? value : '',
            ContextKey: contextKey ? contextKey : "",
            // ContextKey2: contextKey ? contextKey : "",

        }
        //setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setApprovalEmployeeAllData(data)
                }
            }
        });
    }

    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/Loan/ShowLoanMaster`, { ...TokenData, ...temp, ...sortConfig, ...filterData }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }

    const handleChange1 = (event, value) => {
        getFormData(value);
    };
    //useEffect(() => {

    //    GetApprovalEmployeeData(recipe.approvedBy ? recipe.approvedBy : '', recipe.employee ? recipe.employee : '')
    //}, [recipe.employee, recipe.approvedBy])

    useEffect(() => {
        inputrefs.current['txtLoanType'].focus();
        document.title = 'Pay Plus : Loan Master';
        // GetApprovalEmployeeData();
        GetEmployeeData();
        GetLoanTypeData();
        getFormData()
    }, [sortConfig, isUpdate])


    //Change Handler
    const HandleChange = (prop, value) => {
        //if (prop === 'loanAmount' || 'interestAmount') {
        //    setRecipe({ ...recipe, noOfInstallment: HandleCaculate(recipe.emi), emi: HandleCaculate(recipe.noOfInstallment) })
        //}
        if (prop === 'emi') {
            setRecipe({ ...recipe, noOfInstallment: HandleCaculate(value) })
        } else if (prop === 'noOfInstallment') {
            setRecipe({ ...recipe, emi: HandleCaculate(value) })
        }else if (prop === 'interestAmount') {
            setRecipe({ ...recipe, emi: "", noOfInstallment: '', interestAmount: value })
        } else if (prop === 'loanAmount') {
            setRecipe({ ...recipe, emi: "", noOfInstallment: '', loanAmount: value })
        }
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "employee", msg: 'Please Enter Employee.' },
            { field: "loanType", msg: 'Please Select Loan Type.' },
            { field: "loanDate", msg: 'Please Enter Loan Date.' },
            { field: "loanAmount", msg: 'Please Enter Loan Amount.' },
            { field: "emi", msg: 'Please Enter EMI.' },
            { field: "noOfInstallment", msg: 'Please Enter Installment Amount.' },
            { field: "approvedDate", msg: 'Please Enter Approved Date.' },
            { field: "approvedBy", msg: 'Please Enter Approved By.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        return isValid;
    }

    //Submit Handler
    const handleSubmit = () => {
    //    console.log(recipe.emiStartFromMonth.getMonth() + 1,'mmmmmmmmmmmmmmmmmmmm')
        if (Validate()) {
            const tempData = {
                // emiStartFrom: EMIStartFrom.month && EMIStartFrom.year ? `${currentMonth(EMIStartFrom.month, 'long')}-${currentYear(EMIStartFrom.year)}` :'',
              //  emiStartFromMonth: recipe.emiStartFromMonth ? currentMonth(recipe.emiStartFromMonth, 'long') : '',
                emiStartFromMonth: recipe.emiStartFromMonth ?`${recipe.emiStartFromMonth.getMonth()+1}` : '',
                emiStartFromYear: recipe.emiStartFromYear ? currentYear(recipe.emiStartFromYear) : '',
            }
            setLoading(true)
            post(`${API_SERVER}api/Loan/InsertUpdateLoanMaster`, { ...TokenData, ...recipe, ...tempData }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        setLoading(false)
                        inputrefs.current[res.focus].focus();
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "UNAUTHORIZED") {
                        toast.error(res.Message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        getFormData()
                        handleClear()
                    }
                }
            });
        }
        else {
            //   toast.error(myString[0]);
            //   inputrefs.current[myString[1]].focus();
        }
    }

    //Handle Edit
    const handleEdit = (Id) => {
        console.log(Id)
        setLoading(true)
        post(`${API_SERVER}api/loan/EditLoanMaster`, { ...TokenData, loanID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)

                    let data = res.data
                    setRecipe({
                        ...data,
                        emiStartFromMonth: (new Date(data.emiStartFromMonth)),
                        emiStartFromYear: (new Date(data.emiStartFromYear))
                    })

                }
            }
        });
    }

    //Handle Delete
    const handleDelete = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/Loan/DeleteLoanMaster`, { ...TokenData, loanID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    toast.success(res.message)
                    setLoading(false)
                    getFormData()
                    handleClear()
                }
            }
        });
    }


    const HandleCaculate = (value) => {
        let amount = Number(recipe.loanAmount) + Number(recipe.interestAmount);
        return (amount / value).toFixed(2)
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            loanID: "0",
            loanCode: "",
            employee: '',
            loanType: '',
            loanDate: '',
            loanAmount: '',
            interestAmount: '',
            emi: '',
            noOfInstallment: '',
            approvedDate: '',
            approvedBy: '',
            emiStartFromMonth: '',
            emiStartFromYear:'',
        }))
        setErrors({})
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        getFormData()
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    let EmployeeData = []
    for (let i = 0; i < employeeAllData.length; i++) {
        let mydata = employeeAllData[i]?.values
        EmployeeData.push(mydata)
    };

    let LoanTypeData = []
    for (let i = 0; i < loanTypeAllData.length; i++) {
        let mydata = loanTypeAllData[i]?.values
        LoanTypeData.push(mydata)
    };

    //  console.log(loanTypeAllData,'dfgdgdfgddgdfd ')

    let ApprovalEmployeeData = []
    for (let i = 0; i < approvalEmployeeAllData.length; i++) {
        let mydata = approvalEmployeeAllData[i]?.values
        ApprovalEmployeeData.push(mydata)
    };

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1',
        emiStartFromMonth: recipe.emiStartFromMonth ? currentMonth(recipe.emiStartFromMonth, 'long') : '',
        emiStartFromYear: recipe.emiStartFromYear ? currentYear(recipe.emiStartFromYear) : '',
    }
    //End Export functionality


  //  console.log(recipe, 'Loan Master', loanTypeAllData)
    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Loan Master</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <InputBox
                            inputrefs={inputrefs}
                            label='Loan Code'
                            placeholder="SYSTEM GENERATED"
                           // maxLength='12'
                            id='txtLoanCode'
                            value={recipe.loanCode}
                            //error={errors.loanCode}
                            disabled
                            onChange={(e) => {
                                HandleChange('loanCode', e.target.value)
                            }}
                        //required
                        />
                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtLoanType'].focus() };
                            }}
                            label='Employee'
                            placeholder="Employee"
                            maxLength='50'
                            options={EmployeeData}
                            id='txtEmployee'
                            key='txtEmployee'
                            required={true}
                            error={errors.employee}
                            inputValue={recipe.employee ? recipe.employee : ''}
                            onInputChange={(event, newInputValue) => {
                                GetEmployeeData(newInputValue)
                                HandleChange('employee', newInputValue)
                                GetApprovalEmployeeData(recipe.approvedBy ? recipe.approvedBy : '', newInputValue)
                                //  getFormData()

                            }}
                        />

                        {/*<AutoCompleteBox*/}
                        {/*    inputrefs={inputrefs}*/}
                        {/*    onKeyDown={(e) => {*/}
                        {/*        if (e.key === 'Enter') { inputrefs.current['txtLoanDate'].focus() };*/}
                        {/*    }}*/}
                        {/*    label='Loan Type'*/}
                        {/*    placeholder="Loan Type"*/}
                        {/*    id='txtLoanType'*/}
                        {/*    key='txtLoanType'*/}
                        {/*    maxLength='50'*/}
                        {/*    options={LoanTypeData}*/}
                        {/*    required={true}*/}
                        {/*    error={errors.loanType}*/}
                        {/*    inputValue={recipe.loanType ? recipe.loanType : ''}*/}
                        {/*    onInputChange={(event, newInputValue) => {*/}
                        {/*        console.log(newInputValue)*/}
                        {/*        GetLoanTypeData(newInputValue)*/}
                        {/*        HandleChange('loanType', newInputValue)*/}

                        {/*        //  getFormData()*/}

                        {/*    }}*/}
                        {/*/>*/}

                        <SelectInputBox
                            label='Loan Type'
                            id='txtLoanType'
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtLoanDate'].focus() };
                            }}
                            inputrefs={inputrefs}
                            error={errors.loanType}
                            value={recipe.loanType}
                            onChange={(e) => {
                                HandleChange('loanType', e.target.value)
                            }}
                            children={<> 
                                <option  value='' >--Select Loan Type--</option>
                                {loanTypeAllData.map((opt, index) => <option key={index} value={opt.values} >{opt.values}</option>)}
                            </>}
                        />

                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtLoanAmount'].focus() };
                            }}
                            //labelClass='text-nowrap'
                            //divclassname='col-lg-6'
                            id='txtLoanDate'
                            label='Loan Date'
                            placeholder="Loan Date"
                            selected={recipe.loanDate === '' ? '' : new Date(recipe.loanDate)}
                            onChange={(e) => HandleChange('loanDate', dateFormate(e))}
                            error={errors.loanDate}
                            required
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtInterestAmount'].focus() };
                            }}
                            label='Loan Amount'
                            placeholder="Loan Amount"
                            maxLength='12'
                            id='txtLoanAmount'
                            value={recipe.loanAmount}
                            error={errors.loanAmount}
                            onChange={(e) => {

                                HandleChange('loanAmount', numberwithDot(e.target.value))
                                if (!e.target.value) {
                                    setRecipe({ ...recipe, emi: "", noOfInstallment: '', loanAmount: "" })
                                }
                            }}
                            required
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtEMI'].focus() };
                            }}
                            label='Interest Amount'
                            placeholder="Interest Amount"
                            maxLength='12'
                            id='txtInterestAmount'
                            value={recipe.interestAmount}
                            //error={errors.interestAmount}
                            onChange={(e) => {
                                HandleChange('interestAmount', numberwithDot(e.target.value))
                            }}
                        // required
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { recipe.loanAmount && recipe.emi ? inputrefs.current['txtApprovedDate'].focus() : inputrefs.current['txtNoOfInstallment'].focus() };
                            }}
                            label='EMI ( In Months )'
                            placeholder="EMI ( In Months )"
                            maxLength='12'
                            id='txtEMI'
                            // disabled={recipe.loanAmount && recipe.noOfInstallment}
                            //value={recipe.loanAmount && recipe.noOfInstallment ? HandleCaculate(recipe.noOfInstallment) : recipe.emi}
                            value={recipe.emi === 'Infinity' ? '0.00' : recipe.emi}
                            error={errors.emi}
                            required
                            onkeyPress
                            onChange={(e) => {
                                //  recipe.loanAmount && recipe.noOfInstallment ? setRecipe({ ...recipe, emi: HandleCaculate(recipe.noOfInstallment) }) :
                                HandleChange('emi', number(e.target.value));

                            }}
                        />


                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtApprovedDate'].focus() };
                            }}
                            label='Installment Amount'
                            placeholder="Installment Amount"
                            maxLength='12'
                            id='txtNoOfInstallment'
                            value={recipe.noOfInstallment === 'Infinity' ? '0.00' : recipe.noOfInstallment}
                            // disabled={recipe.loanAmount && recipe.emi}
                            // value={recipe.loanAmount && recipe.emi ? HandleCaculate(recipe.emi) :recipe.noOfInstallment}
                            error={errors.noOfInstallment}
                            onChange={(e) => {
                                //recipe.loanAmount && recipe.emi ? setRecipe({ ...recipe, noOfInstallment: HandleCaculate(recipe.emi) }) :
                                HandleChange('noOfInstallment', numberwithDot(e.target.value))
                            }}


                            required
                        />


                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtApprovalBy'].focus() };
                            }}
                            //labelClass='text-nowrap'
                            //divclassname='col-lg-6'
                            id='txtApprovedDate'
                            label='Approved Date'
                            placeholder="Approved Date"
                            selected={recipe.approvedDate === '' ? '' : new Date(recipe.approvedDate)}
                            onChange={(e) => HandleChange('approvedDate', dateFormate(e))}
                            error={errors.approvedDate}
                            required
                        />

                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtMonth'].focus() };
                            }}
                            label='Approval By'
                            placeholder="Approval By"
                            maxLength='50'
                            options={ApprovalEmployeeData}
                            id='txtApprovalBy'
                            key='txtApprovalBy'
                            required={true}
                            error={errors.approvedBy}
                            inputValue={recipe.approvedBy ? recipe.approvedBy : ''}
                            onInputChange={(event, newInputValue) => {
                                GetApprovalEmployeeData(recipe.employee ? recipe.employee : '', newInputValue)
                                HandleChange('approvedBy', newInputValue)
                                //  getFormData()

                            }}
                        />
                        <div className={`col-md-3 mb-1 cntrl-width align-items-start align-items-start `} key="txtEMIStartFrom">
                            <label className="form-label" style={{ textWrap: 'nowrap' }}>EMI Start From</label>
                            <div className='d-flex gap-2 align-items-center'>
                                <MonthBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtYear'].focus() };
                                    }}
                                    // label='Month'
                                    // labelClass='text-nowrap'
                                    //customclass='w-25'
                                    divclassname='col-lg-5'
                                    id='txtMonth'
                                    selected={recipe.emiStartFromMonth ? recipe.emiStartFromMonth : ""}
                                    value={recipe.emiStartFromMonth ?recipe.emiStartFromMonth : ""}
                                    //selected={EMIStartFrom.month ? EMIStartFrom.month : ""}
                                    placeholder="Month"
                                    onChange={(e) => {
                                        HandleChange('emiStartFromMonth', e)
                                       // setEMIStartFrom({ ...EMIStartFrom, month: e })
                                    }}
                                //required
                                // error={errors.month}
                                />
                                <YearBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { handleSubmit() };
                                    }}
                                    //  label='Year'
                                    // labelClass='text-nowrap'
                                    divclassname='col-lg-5'
                                    id='txtYear'
                                    selected={recipe.emiStartFromYear ? recipe.emiStartFromYear : ""}
                                    value={recipe.emiStartFromYear ? recipe.emiStartFromYear : ""}
                                    //selected={EMIStartFrom.year ? EMIStartFrom.year : ""}
                                    placeholder="Year"
                                    onChange={(e) => {
                                        HandleChange('emiStartFromYear', e)
                                       // setEMIStartFrom({ ...EMIStartFrom, year: e })
                                    }}
                                //  required
                                // error={errors.year}
                                />
                            </div>
                        </div>

                        <FormButton
                            Export={false}
                            handleSubmit={handleSubmit}
                            //  handleExport={() => handleDownloadExcel(API_SERVER + 'api/Loan/ShowLoanMaster', ExportRequest, 'Loan Master')}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>

            <Table
                Token={TokenData}
                PageName='LoanMaster'
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleFilter={() => {
                    getFormData();
                    setFilterData({
                        ...filterData,
                    });
                }}
                tableData={result}
                loading={loading}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
            {loading && < BlurLoader />}
        </>
    )
}

export default LoanMaster
