
import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, AutoCompleteBoxWithLabel, DateBox, YearBox, MonthBox } from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
//loder
import BlurLoader from "../Loder/BlurLoader";
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { Token, currentMonth, currentYear, dateFormate, number, numberwithDot } from '../Common/Common';

function VerifySalary() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();

    //token Data
    const TokenData = Token()

    //initial state
    const [recipe, setRecipe] = useState({
        //  company: '',
        branch: '',
        employee: '',
        month: '',
        year: '',
    })
    // const currentMonthInNo = `${(recipe.month).getMonth() + 1}`;
    const [salaryDataCounts, setSalaryDataCounts] = useState('')
    const [showTable, setShowTable] = useState(false)
    const [showSalaryCounts, setShowSalaryCounts] = useState(false)
   

    //const currentY = currentYear(new Date());
    // console.log(recipe.month.toLocaleString('default', { month: 'long' }), 'currentYear')
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData);
    const [isUpdate, setIsUpdate] = useState(false);
    const [branchAllData, setBranchAllData] = useState([])
    const [companyAllData, setCompanyAllData] = useState([])
    const [employeeAllData, setEmployeeAllData] = useState([])
    const [departmentAllData, setDepartmentAllData] = useState([])
    const [errors, setErrors] = useState({});
   


    //Get branch Data for AutoComplete
    const GetBranchData = (value) => {
        setBranchAllData([])
        let temp = {
            Type: "GetBranch",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
      //  setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setBranchAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setBranchAllData(data)
                }
            }
        });
    }

    //Get Employee Data for AutoComplete
    const GetEmployeeData = (value) => {
        setEmployeeAllData([])
        let temp = {
            Type: "GetEmployee",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
      //  setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setEmployeeAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setEmployeeAllData(data)
                }
            }
        });
    }

    // Get Salary  Count Datas 
    const GetSalaryDataCount = (month, year) => {
        post(`${API_SERVER}api/Transaction/GetVerifySalaryData`, {
            ...TokenData, ...recipe, month: month ? currentMonth(month, 'numeric') : recipe.month ? currentMonth(recipe.month, 'numeric') : '', year: year ? currentYear(year) : recipe.year ? currentYear(recipe.year) : ''
        }, (res) => {
            if (res && res.status) {
                if (res.status === "SUCCESS") {
                    setSalaryDataCounts(res)
                    setShowSalaryCounts(true)
                } else if (res.status === "ERROR") {
                    setSalaryDataCounts(res)
                    setShowSalaryCounts(true)
                }
            }
        })
    }
    // Handle Search Salary Data 
    const handleSearch = () => {
        if (Validate()) {
            setLoading(true)
            //const currentMonthInNo = `${(recipe.month).getMonth() + 1}`;
            post(`${API_SERVER}api/Transaction/GetVerifySalaryDetailData`, { ...TokenData, ...recipe, type:"s", month: currentMonth(recipe.month, 'numeric'), year: recipe.year ? currentYear(recipe.year) : '' }, (res) => {
                if (res && res.Status) {
                    if (res.Status === "ERROR") {
                        setLoading(false)
                        toast.error(res.Message)
                        setResult({ ...res })
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "UNAUTHORIZED") {
                        toast.error(res.Message);
                        setLoading(false);
                    } else if (res.Status === "SUCCESS") {
                        setLoading(false)
                      //  toast.success(res.Message)
                        setResult({ ...res })
                        setShowTable(true)
                    } else {
                        setResult({ ...res })
                        setLoading(false)
                        toast.error(res.Message)

                    }
                }
            });
        }

    }

    // Handle Verify Salary 
    const HandleVerifySalary = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/Transaction/VerifyEmployeeSalary`, { ...TokenData, ...recipe, month: currentMonth(recipe.month, 'numeric'), year: recipe.year ? currentYear(recipe.year) : '' }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        setLoading(false)
                        toast.error(res.message)
                    } else if (res.status === "EXPIRED") {
                        toast.error(res.message)
                        sessionExpired()
                    } else if (res.status === "UNAUTHORIZED") {
                        toast.error(res.message);
                        setLoading(false);
                    } else if (res.status === "SUCCESS") {
                        setLoading(false)
                        toast.success(res.message)
                    } else {
                        setLoading(false)
                        toast.error(res.message)

                    }
                }
            });
        }
    }
  //  console.log(result,'result')

    const handleChange1 = (event, value) => {
        //getFormData(value);
        handleSearch(value);
    };

    useEffect(() => {
        inputrefs.current['txtBranch'].focus();
        document.title = 'Pay Plus : Verify Salary';

        GetBranchData();
        GetEmployeeData();


    }, [])


    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    // Handle Validations
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            // { field: "company", msg: 'Please Enter Company.' },
            { field: "month", msg: 'Please Enter Month.' },
            { field: "year", msg: 'Please Enter Year.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            branch: '',
            employee: '',
            month: '',
            year: '',
        }))
        setErrors({})
        //setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        //setIsUpdate(!isUpdate)
        setShowSalaryCounts(false)
         setShowTable(false)
    }

    //const handleSorting = (SortColumn) => {
    //    let SortOrder = 'ASC';

    //    if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
    //        SortOrder = 'DESC';
    //    }
    //    setSortConfig({ SortColumn, SortOrder });
    //};

   
    let BranchData = []
    for (let i = 0; i < branchAllData.length; i++) {
        let mydata = branchAllData[i]?.values
        BranchData.push(mydata)
    };

    let EmployeeData = []
    for (let i = 0; i < employeeAllData.length; i++) {
        let mydata = employeeAllData[i]?.values
        EmployeeData.push(mydata)
    };

    //Start Export functionality
    let ExportRequest = {
        ...TokenData,
        ...recipe,
        Type: 'E',
        PageIndex: '1',
        month: recipe.month? currentMonth(recipe.month, 'numeric') :"",
        year: recipe.year ?currentYear(recipe.year) :"",

    }
    //End Export functionality


    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Verify Salary</h6>
                </div>
                <div className='card-body'>
                    <div className='form-group ms-2'>

                        <div className='row'>
                            {/*<AutoCompleteBox*/}
                            {/*    inputrefs={inputrefs}*/}
                            {/*    onKeyDown={(e) => {*/}
                            {/*        if (e.key === 'Enter') { inputrefs.current['txtBranch'].focus() };*/}
                            {/*    }}*/}
                            {/*    label='Company'*/}
                            {/*    placeholder="Company"*/}
                            {/*    maxLength='50'*/}
                            {/*    options={CompanyData}*/}
                            {/*    id='txtCompany'*/}
                            {/*    key='txtCompany'*/}
                            {/*    required={true}*/}
                            {/*    error={errors.company}*/}
                            {/*    inputValue={recipe.company ? recipe.company : ''}*/}
                            {/*    onInputChange={(event, newInputValue) => {*/}
                            {/*        GetCompanyData(newInputValue)*/}
                            {/*        HandleChange('company', newInputValue)*/}

                            {/*    }}*/}
                            {/*/>*/}
                            <AutoCompleteBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtEmployee'].focus() };
                                }}
                                label='Branch'
                                placeholder="Branch"
                                maxLength='50'
                                options={BranchData}
                                id='txtBranch'
                                key='txtBranch'
                                //required={true}
                                // error={errors.branch}
                                inputValue={recipe.branch ? recipe.branch : ''}
                                onInputChange={(event, newInputValue) => {
                                    GetBranchData(newInputValue)
                                    HandleChange('branch', newInputValue)

                                }}
                            />
                            <AutoCompleteBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtDepartment'].focus() };
                                }}
                                label='Employee'
                                placeholder="Employee"
                                maxLength='50'
                                options={EmployeeData}
                                id='txtEmployee'
                                key='txtEmployee'
                                // required={true}
                                // error={errors.employee}
                                inputValue={recipe.employee ? recipe.employee : ''}
                                onInputChange={(event, newInputValue) => {
                                    GetEmployeeData(newInputValue)
                                    HandleChange('employee', newInputValue)

                                }}
                            />
                            <MonthBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        inputrefs.current['txtYear'].focus()
                                        if (recipe.month && recipe.year) {
                                            GetSalaryDataCount(e, recipe.year)
                                        }
                                    };
                                }}
                                label='Month'
                                // labelClass='text-nowrap'
                                //customclass='w-25'
                                // divclassname='col-lg-6'
                                id='txtMonth'
                                selected={recipe.month ? recipe.month : ""}
                                placeholder="Month"
                                onChange={(e) => {
                                    HandleChange('month', e)
                                    if ((recipe.month || e) && recipe.year) {
                                        GetSalaryDataCount(e, recipe.year)
                                    }
                                }}
                                required
                                error={errors.month}
                            />
                            <YearBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        if (recipe.month && recipe.year) {
                                            GetSalaryDataCount(recipe.month, e)
                                        }
                                    };
                                }}
                                label='Year'
                                // labelClass='text-nowrap'
                                // divclassname='col-lg-4'
                                id='txtYear'
                                selected={recipe.year ? recipe.year : ""}
                                placeholder="Year"
                                onChange={(e) => {
                                    HandleChange('year', e)
                                    if (recipe.month && (recipe.year || e)) {
                                        GetSalaryDataCount(recipe.month, e)
                                    }
                                }}
                                required
                                error={errors.year}
                            />

                        </div>
                        {showSalaryCounts && <div className='row form-label my-4'>
                            <div className='col-md-4 mb-1 cntrl-width'>  <span>Total Employee : </span> <span>{salaryDataCounts.totalEmp}</span> </div>
                            <div className='col-md-4 mb-1 cntrl-width'>  <span>Salary Generated : </span> <span>{salaryDataCounts.salaryGenerated}</span> </div>
                            <div className='col-md-4 mb-1 cntrl-width'>  <span>Salary Not Generated : </span> <span>{salaryDataCounts.salaryNotGenerated}</span> </div>
                            <div className='col-md-4 mb-1 cntrl-width'>  <span>Total Verify: </span> <span>{salaryDataCounts.totalVerified}</span> </div>
                            <div className='col-md-4 mb-1 cntrl-width'>  <span>Total Not Verfiy : </span> <span>{salaryDataCounts.notVerifed}</span> </div>
                        </div>}
                        <FormButton
                            saveButtonIcon='fa-check'
                            SaveText='Verify Salary'
                            Next={true}
                            nextButtonColor='btn-primary'
                            nextButtonIcon='fa-search'
                            nextButtonText='Search'
                            handleNext={handleSearch}
                            handleSubmit={HandleVerifySalary}
                            handleExport={() => {if( Validate()) handleDownloadExcel(API_SERVER + 'api/Transaction/GetVerifySalaryDetailData', ExportRequest, 'Verify Salary')  }}
                            handleClear={handleClear}
                        />

                    </div>
                </div>
            </div>


            {/* Table Component start */}
            {showTable &&

                <Table
                Filter={false}
                Action={false }
                    Token={TokenData}
                    PageName='VerifySalary'
                    //handleCheckData={handleCheckData}
                    // handleFilter={() => {
                    //     // getFormData();
                    //     handleSubmit();
                    //     setFilterData({
                    //         ...filterData,
                    //     });
                    // }}
                    tableData={result}
                    //    loading={loading}
                    //filterData={filterData}
                    //setFilterData={setFilterData}
                    currentPageNo={currentPageNo}
                    handleChange1={handleChange1}
                // handleSorting={handleSorting}
                // sortConfig={sortConfig}
                />
            }
            {/* Table Component End*/}


            {loading && < BlurLoader />}
        </>
    )
}

export default VerifySalary
