import React, { useState } from "react"
import { Link } from 'react-router-dom';
import PayPlus from '../../assests/images/PayPlus.png'
import user_image from '../../assests/images/user.svg'
//react toast 
import { toast } from 'react-toastify';
import { Cookies } from 'react-cookie';
import { useAuth } from "../../context/auth";
import BlurLoader from "../Loder/BlurLoader";
import { API_SERVER, NotificationCount } from "../../Utils";
import { post } from "../../apiservices/service";
import { Token } from "../Common/Common";
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { useRecoilValue } from 'recoil';


const Header = ({ Handletoggle, HandleheaderToogle, setShowModal }) => {
    const { user, logout, sessionExpired } = useAuth()
    //token Data
    const TokenData = Token()
    const [loading, setLoading] = useState(false);
    const cookies = new Cookies();
    const notificationData = useRecoilValue(NotificationCount)
    const userLogOut = () => {
       
        setLoading(true)
        post(`${API_SERVER}api/UserLogin/Logout`, { ...TokenData, UserCode: user.userName  }, (res) => {
                    if (res && res.status) {
                        if (res.status === "ERROR") {
                            toast.error(res.message)
                            setLoading(false)
                        } else if (res.Status === "EXPIRED") {
                            toast.error(res.Message)
                            sessionExpired()
                        } else {
                            console.log(res)
                            cookies.remove('user');
                            logout(res)
                        }
                    }
                });
            }
        
    

    return (
        <>
            <nav className={`navbar navbar-expand-lg fixed-top navbar-light bg-light main-header p-0`}>
                <div className="container-fluid align-items-center ">

                    <Link to='/Dashboard/Dashboard' className=''>
                        <img src={PayPlus} className="icon" alt='Pay Plus logo' width={140} style={{ padding:'4px' }} /> 
                       
                    </Link>

                    <i onClick={() => {
                        HandleheaderToogle()
                        Handletoggle()
                    } } className="fa-solid fa-bars mx-3"></i>
                   

                    <ul className="navbar-nav ms-auto d-flex flex-row nav-side-icon align-items-center">
                        {/*<li key='abc' className='nav-item'>*/}
                        {/*    <span className="nav-link mt-1 me-2 m-lg-1" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">*/}
                        {/*        <i className="fa-solid fa-bell position-relative">*/}
                        {/*            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill btn-danger" style={{ fontSize: '10px' }}>*/}
                        {/*                5*/}
                        {/*            </span>*/}
                        {/*        </i>*/}
                        {/*    </span>*/}
                        {/*    <ul className="dropdown-menu dropdown-menu-end">*/}
                        {/*        <li>*/}
                        {/*            <div className="dropdown-item">Item 1</div>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <div className="dropdown-item">Item 2</div>*/}
                        {/*        </li>*/}

                        {/*    </ul>*/}
                        {/*</li>*/}
                        <li className='m-0 notification_badge p-0' style={{ cursor: 'pointer' }}>
                            <IconButton aria-label='notifications' onClick={()=>setShowModal(true) } >
                                <Badge badgeContent={notificationData.totalNotification} color="primary">
                                    <i class="fa fa-bell fs-5" aria-hidden="true"></i>
                                </Badge>
                            </IconButton>
                        </li>
                        <li key='an' className="nav-item dropdown">
                            <div className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src={user_image} alt='user' style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                            </div>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li className='px-3 pb-2 border-bottom'>
                                    <p className='m-0' style={{ fontSize: '13px' }}><span className='green_dot'></span>{user?user.userName:'lorem data'}</p>
                                </li>
                                <li key='am'>
                                    <Link className="dropdown-item" style={{ fontSize: '13px' }} to='User/ChangePassword'>Change Password</Link>
                                </li>
                                {/* <li className='mx-3'>
                                    <select className="branch-select form-select w-100" id="selectOption" >
                                        <option>Item 1</option>
                                        <option>Item 2</option>
                                        <option>Item 3</option>
                                        <option>Item 4</option>
                                    </select>
                                </li>*/}
                                <li key='al'>
                                    <button className="dropdown-item" style={{ fontSize: '13px' }} onClick={userLogOut}>Logout</button>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}
        </>
    )
}
export default Header;