
import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, AutoCompleteBoxWithLabel, DateBox, MonthBox, YearBox, SelectInputBox } from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
//loder
import BlurLoader from "../Loder/BlurLoader";
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { Token, currentYear, dateFormate, number, numberwithDot } from '../Common/Common';

function AttendanceDetailReport() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();
    //token Data
    //const TokenData = {
    //    AuthToken: process.env.REACT_APP_SECRET_KEY,
    //    LoginToken: user.loginsessionID,
    //    UserID: user.userID,
    //    CompanyID: user.companyID
    //}
    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({
        //  loanTypeID: "0",
        toBranch: '',
        employee: '',
        //   company: '',
        month: '',
        year: '',
    })

    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData);
    const [isUpdate, setIsUpdate] = useState(false);
    const [branchAllData, setBranchAllData] = useState([])
    const [employeeAllData, setEmployeeAllData] = useState([])
    const [companyAllData, setCompanyAllData] = useState([])
    const [errors, setErrors] = useState({});
    const [showTable, setShowTable] = useState(false)
    const [yearData, setYearData] = useState([]);
    const [monthData, setMonthData] = useState([]);

    //Get Month Data
    const GetMonthData = () => {
        setMonthData([])
        let temp = {
            Type: "GetMonth",
            Prefix: '',

        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setMonthData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setMonthData(data)
                }
            }
        });
    };


    //Get Year Data
    const GetYearData = () => {
        setYearData([])
        let temp = {
            Type: "GetYear",
            Prefix: '',

        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setYearData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setYearData(data)
                }
            }
        });
    };

    //validation handler
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "month", msg: 'Please Select Month.' },
            { field: "year", msg: 'Please Select Year.' },
        ]
        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }

    //Get company Data for AutoComplete
    const GetCompanyData = (value) => {
        setCompanyAllData([])
        let temp = {
            Type: "GetCompany",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setCompanyAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setCompanyAllData(data)
                }
            }
        });
    }

    //Get branch Data for AutoComplete
    const GetBranchData = (value) => {
        setBranchAllData([])
        let temp = {
            Type: "GetBranch",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setBranchAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setBranchAllData(data)
                }
            }
        });
    }

    //Get Employee Data for AutoComplete
    const GetEmployeeData = (value) => {
        setEmployeeAllData([])
        let temp = {
            Type: "GetEmployee",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setEmployeeAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setEmployeeAllData(data)
                }
            }
        });
    }

    //Submit Handler
    const handleSubmit = (CurrentPage) => {
        if (Validate()) {
            let PageIndex = 1
            if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
                PageIndex = CurrentPage;
                setCurrentPageNo(CurrentPage);
            }
            else {
                setCurrentPageNo(1);
            }

            // setLoading(true)

            let temp = {
                PageIndex: PageIndex.toString(),
                SearchText: "",
                StartValue: "1",
                SearchFieldValue: "",
                Type: "s",
                // month: recipe.month ? `${(recipe.month).getMonth() + 1}` : '',
                // year: currentYear(recipe.year)
            }
            setLoading(true)
            post(`${API_SERVER}api/Report/AttendanceDetailReport`, { ...TokenData, ...temp, ...sortConfig, ...filterData, ...recipe,/* year: recipe.year? currentYear(recipe.year):'',month: recipe.month ? `${(recipe.month).getMonth() + 1}` : '',*/ }, (res) => {
                if (res && res.Status) {
                    if (res.Status === "ERROR") {
                        toast.error(res.Message)
                        setLoading(false)
                        setResult({ ...res })
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "UNAUTHORIZED") {
                        toast.error(res.Message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        setResult({ ...res })
                        setShowTable(true)
                        //getFormData()
                        // handleClear()
                        //console.log(recipe, 'else')
                    }
                }
            });

        }
    }
    // console.log(currentYear(recipe.year))

    const handleChange1 = (event, value) => {
        //getFormData(value);
        handleSubmit(value);
    };

    useEffect(() => {
        inputrefs.current['txtEmployee'].focus();
        // inputrefs.current['txtCompany'].focus();
        document.title = 'Pay Plus : Attendance Detail Report';
        //  GetCompanyData();
        GetBranchData();
        GetEmployeeData();
        GetMonthData();
        GetYearData();
        if (sortConfig.SortOrder !== null) handleSubmit();


    }, [sortConfig, isUpdate])

    //useEffect(() => {

    //})

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }
    
    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            toBranch: '',
            employee: '',
            // company: '',
            month: '',
            year: '',
        }))
        setErrors({})
        setShowTable(false)
        // setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        //  setIsUpdate(!isUpdate)
        // getFormData()
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    let CompanyData = []
    for (let i = 0; i < companyAllData.length; i++) {
        let mydata = companyAllData[i]?.values
        CompanyData.push(mydata)
    };

    let BranchData = []
    for (let i = 0; i < branchAllData.length; i++) {
        let mydata = branchAllData[i]?.values
        BranchData.push(mydata)
    };

    let EmployeeData = []
    for (let i = 0; i < employeeAllData.length; i++) {
        let mydata = employeeAllData[i]?.values
        EmployeeData.push(mydata)
    };

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        ...recipe,
        Type: 'E',
        PageIndex: '1',
        //  month: recipe.month ? `${(recipe.month).getMonth() + 1}` : '',
        //  year: recipe.year ? currentYear(recipe.year) : '',

    }
    //End Export functionality


    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Attendance Detail Report</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>

                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtBranch'].focus() };
                            }}
                            label='Employee'
                            placeholder="Employee"
                            maxLength='50'
                            options={EmployeeData}
                            id='txtEmployee'
                            key='txtEmployee'
                            // required={true}
                            // error={errors.employee}
                            inputValue={recipe.employee ? recipe.employee : ''}
                            onInputChange={(event, newInputValue) => {
                                GetEmployeeData(newInputValue)
                                HandleChange('employee', newInputValue)

                            }}
                        />
                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtMonth'].focus() };
                            }}
                            label='To Branch'
                            placeholder="To Branch"
                            maxLength='50'
                            options={BranchData}
                            id='txtBranch'
                            key='txtBranch'
                            //required={true}
                            // error={errors.branch}
                            inputValue={recipe.toBranch ? recipe.toBranch : ''}
                            onInputChange={(event, newInputValue) => {
                                GetBranchData(newInputValue)
                                HandleChange('toBranch', newInputValue)

                            }}
                        />

                        <SelectInputBox
                            label='Month'
                            inputrefs={inputrefs}
                            id='txtMonth'
                            value={recipe.month}
                            required
                            error={errors.month}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtYear'].focus() };
                            }}
                            onChange={(e) => HandleChange('month', e.target.value)}
                            children={<>
                                <option value="">--Month--</option>
                                {monthData.map((opt, index) => <option key={index} value={opt.code} >{opt.name}</option>)}
                            </>}
                        />

                        <SelectInputBox
                            label='Year'
                            inputrefs={inputrefs}
                            id="txtYear"
                            value={recipe.year}
                            required
                            error={errors.year}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { handleSubmit() };
                            }}
                            onChange={(e) => HandleChange('year', e.target.value)}
                            children={<>
                                <option value="">--Year--</option>
                                {yearData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}
                            </>}
                        />



                        <FormButton
                            saveButtonIcon='fa-search'
                            SaveText='Search'
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/Report/AttendanceDetailReport', ExportRequest, 'Attendance Detail Report')}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>
            {showTable &&
                <Table
                    Filter={false}
                    Action={false}
                    Token={TokenData}
                    PageName='AttendanceDetailReport'
                    // handleEdit={handleEdit}
                    // handleDelete={handleDelete}
                    //  handleFilter={() => {
                    //      //getFormData();
                    //      handleSubmit();
                    //      setFilterData({
                    //          ...filterData,
                    //      });
                    //  }}
                    tableData={result}
                    loading={loading}
                    //  filterData={filterData}
                    //  setFilterData={setFilterData}
                    currentPageNo={currentPageNo}
                    handleChange1={handleChange1}
                //     handleSorting={handleSorting}
                //     sortConfig={sortConfig}
                />
            }
            {loading && < BlurLoader />}
        </>
    )
}

export default AttendanceDetailReport
