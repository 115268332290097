import { useEffect, useRef, useState } from 'react';
import DesignationMaster from "../Master/DesignationMaster";
import PersonalDetails from './PersonalDetails';
import QualificationDetails from './QualificationDetails';
import DocumentUpload from './DocumentUpload';
import { API_SERVER, NotificationCount } from '../../Utils';
import { post } from '../../apiservices/service';
import { toast } from 'react-toastify';
import { useAuth } from '../../context/auth';
import CandidateFamilyDetails from './CandidateFamilyDetails';
import ProfilePicture from '../commoncomponents/ProfilePicture';
import { InputBox, handleFileUploderInput, FormButton } from '../commoncomponents/InputBox';
import { GetNoificaitonCount, Token } from '../Common/Common';
import BlurLoader from '../Loder/BlurLoader';
import { FileUploader } from "react-drag-drop-files";
import Modal from 'react-bootstrap/Modal';
import { CustomButton } from '../commoncomponents/InputBox';
import { useNavigate } from 'react-router-dom';
import check_button from '../../assests/images/check_button.png';
import remove_button from '../../assests/images/remove_button.png';
import { useRecoilState } from 'recoil';



const CandidateMaster = () => {
    const { user, sessionExpired } = useAuth();
    // get Candidate Code By Url
    let url = window.location.search
    const queryString = new URLSearchParams(url);
    const CandidateCodeByUrl = queryString.get('CandidateCode');
    // -------------------------------//
    //token Data
    //const TokenData = {
    //    AuthToken: process.env.REACT_APP_SECRET_KEY,
    //    LoginToken: user.loginsessionID,
    //    UserID: user.userID,
    //    CompanyID: user.companyID
    //}
    const TokenData = Token()

    const inputrefs = useRef([])
    const navigate = useNavigate()

    const [activeTab, setActiveTab] = useState('PersonalDetails');
    const [persnaolDetails, setPersnaolDetails] = useState([])
    const [qualificationDetails, setQualificationDetails] = useState([])
    // const [qualificationListDetails, setQualificationListDetails] = useState([])
    const [QualificationDetailsList, setQualificationDetailsList] = useState(null);
    const [PreviousEmploymentDetailsList, setPreviousEmploymentDetailsList] = useState([])
    const [documentDetails, setDocumentDetails] = useState([])
    const [loading, setLoading] = useState(false);
    const [isPerAddressSame, setIsPerAddressSame] = useState(false);

    const [ShowRequestResubmitModal, setShowRequestResubmitModal] = useState(false)

    const [RequestToResubmitTextArea,setRequestToResubmitTextArea]=useState('')

    const [verifyCheck, setVerifyCheck] = useState({
        personalDetails: false,
        qualificationDetails: false,
        familyDetails: false,
        documentUpload: false,
    })

    const [ShowUploadImageModal, setShowUploadImageModal] = useState(false)
    
    const fileType = 'PNG,JPG'

    const [recipe, setRecipe] = useState({
        CandidateID: "0",
        CandidateCode: "",
        CandidateName: "",
        Company: "",
        FatherName: "",
        FatherOccupation: "",
        DOB: "",
        Gender: "",
        MaritalStatus: "",
        SpouseName: "",
        SpouseOccupation: "",
        EmailID: "",
        MobileNo: "",
        AadhaarNo: "",
        BloodGroup: "",
        EmergencyContactNo: "",
        CorAddress1: "",
        CorAddress2: "",
        CorState: "",
        CorCity: "",
        CorPinCode: "",
        CorPhoneNo: "",
      // CorAddressProof: "",
      //  CorProofDocNo: "",
        SameAsCorrespondence: false,
        PerAddress1: "",
        PerAddress2: "",
        PerState: "",
        PerCity: "",
        PerPinCode: "",
        PerPhoneNo: "",
       // PerAddressProof: "",
      //  PerProofDocNo: "",
        AccountHolder: '',
        BankName: '',
        BankAddress: "",
        AccountNo: "",
        IFSCCode: "",
        PFNo: "",
        ESINo: '',
        UANNo:'',

    })
    const [dateOfBirth, setDateOfBirth] = useState();

    const [empCode, setEmpCode] = useState('');
    const [imageData, setImageData] = useState()
    const [profilePicture, setProfilePicture] = useState()
    const [notificationData, setNotificationData] = useRecoilState(NotificationCount)
    const [profilePictureRecipe, setProfilePictureRecipe] = useState({
        //code: `${recipe.CandidateCode}: ${recipe.CandidateName}`,
        code: empCode,
        photo: '',
        photoExt: '',
        photoName: ''
    })
    // Handle Upload Image
    const HandleUploadImage = () => {
        post(`${API_SERVER}api/Employee/InsertUpdateProfilePic`, { ...TokenData, ...profilePictureRecipe, code: empCode, type: 'Candidate' }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    inputrefs.current[res.focus].focus();
                    setLoading(false)
                } else if (res.status === "EXPIRED") {
                    toast.error(res.message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    toast.success(res.message)
                    GetProfilePhoto()
                    setShowUploadImageModal(false)
                  //  HandleClearUploadImage()
                }
            }
        });
    }

    //handle Get Submit Check Values
    const GetSubmitCheckValue = () => {
        post(`${API_SERVER}api/Candidate/GetCandidateAllTabsDetails`, { ...TokenData, CandidateCode: CandidateCodeByUrl }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    toast.error(res.message)
                } else if (res.status === "EXPIRED") {
                    sessionExpired()
                } else {
                    setVerifyCheck({ ...res })
                    //const checkimg = { ...submitCheck }
                    //checkimg.PersonalDetailsTab = true
                    //setSubmitCheck(checkimg)
                }
            }
        });
    }

    // get profile photo
    const GetProfilePhoto = () => {
        post(`${API_SERVER}api/Employee/GetProfilePic`, { ...TokenData, code: CandidateCodeByUrl, type: 'Candidate' }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    // toast.error(res.message)
                    setLoading(false)
                    //console.log(recipe, 'if')
                } else if (res.status === "EXPIRED") {
                    toast.error(res.message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    // toast.success(res.message)
                    //  console.log(res, 'else')
                    setImageData(res.data)
                }
            }
        });
    }

    // Handle Clear Upload Image 
    const HandleClearUploadImage = () => {
        setProfilePictureRecipe({
            ...profilePictureRecipe,
            code: '',
            photo: '',
            photoExt: '',
            photoName: ''
        })
    }

    // Handle Tab Click to Tab Change
    const handleTabClick = (tabId) => {
        if (CandidateCodeByUrl) {
            setActiveTab(tabId);
        } else (
            setActiveTab('PersonalDetails')
        )
    };

    const handleEdit = (CandidateCodeByUrl) => {
        setLoading(true)
        post(`${API_SERVER}api/Candidate/EditCandidateMaster`, { ...TokenData, CandidateCode: CandidateCodeByUrl }, (res) => {
            if (res.status === "ERROR") {
                setLoading(false)
            } else if (res.status === "EXPIRED") {
                toast.error(res.message)
                sessionExpired()
            } else if (res.status === "UNAUTHORIZED") {
                toast.error(res.message);
                setLoading(false);
            } else if (res.status === "SUCCESS") {
                let data = res.data.personalDetail;
                setLoading(false);
                setRecipe({
                    ...recipe,
                    CandidateID: data.candidateID,
                    CandidateCode: CandidateCodeByUrl,
                    CandidateName: data.candidateName,
                    Company: data.company,
                    FatherName: data.fatherName,
                    FatherOccupation: data.fatherOccupation,
                    DOB: data.dob,
                    Gender: data.gender,
                    MaritalStatus: data.maritalStatus,
                    SpouseName: data.spouseName,
                    SpouseOccupation: data.spouseOccupation,
                    EmailID: data.emailID,
                    MobileNo: data.mobileNo,
                    AadhaarNo: data.aadhaarNo,
                    BloodGroup: data.bloodGroup,
                    EmergencyContactNo: data.emergencyContactNo,
                    CorAddress1: data.corrAddress1,
                    CorAddress2: data.corrAddress2,
                    CorState: data.corrState,
                    CorCity: data.corrCity,
                    CorPinCode: data.corrPincode,
                    CorPhoneNo: data.corrPhoneNo,
                //    CorAddressProof: data.corrAddressProof,
                //    CorProofDocNo: data.corrProofDocNo,
                    SameAsCorrespondence: data.sameAsCorrespondence,
                    PerAddress1: data.permAddress1,
                    PerAddress2: data.permAddress2,
                    PerState: data.permState,
                    PerCity: data.permCity,
                    PerPinCode: data.permPincode,
                    PerPhoneNo: data.permPhoneNo,
             //       PerAddressProof: data.permAddressProof,
           //         PerProofDocNo: data.permProofDocNo,
                    AccountHolder: data.accountHolder,
                    BankName: data.bankName,
                    BankAddress: data.bankAddress,
                    AccountNo: data.accountNo,
                    IFSCCode: data.ifscCode,
                    PFNo: data.pfNo,
                    ESINo: data.esicNo,
                    UANNo:data.uanNo,
                })
                setProfilePictureRecipe({
                    ...profilePictureRecipe,
                    photo: data.candidatePhoto,
                    photoExt: data.candidatePhotoExtention,
                    photoName: data.candidatePhotoName
                })
                handleFileUploderInput('.ProfilePicture', data, 'candidatePhotoName', 'candidatePhotoExtention', fileType)

                setIsPerAddressSame(data.sameAsCorrespondence)
                setEmpCode(`${data.candidateCode} : ${data.candidateName}`)
                //  setDateOfBirth(data.dob)
                setPersnaolDetails(res.data.personalDetail);
                setQualificationDetails(res.data.qualificationDetail);
                setDocumentDetails(res.data.documentDetails);
                setQualificationDetailsList(res.data.qualificationDetail.qualificationDetailsList);
                setPreviousEmploymentDetailsList(res.data.qualificationDetail.prevEmploymentDetailsList
                );
            }
        });
    }

    //Handle Verify Candidate 
    const handleVerifyCandidate = () => {
        post(`${API_SERVER}api/Candidate/VerifyCandidate`, { ...TokenData, CandidateID: recipe.CandidateID }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        setLoading(false)
                        toast.error(res.message)
                    } else if (res.status === "EXPIRED") {
                        toast.error(res.message)
                        sessionExpired()
                    } else if (res.status === "UNAUTHORIZED") {
                        setLoading(false);
                        toast.error(res.message);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        navigate('/Candidate/VerifyCandidate')
                        GetNoificaitonCount(notificationData, setNotificationData, TokenData, sessionExpired)
                    }
                }
            });
    }
  
    // Handle Re-Submit Candidate
    const handleReSubmit = () => {
        //console.log(RequestToResubmitTextArea)
        if (RequestToResubmitTextArea === "") {
            toast.error('Please add Remarks.!')
        } else {
            post(`${API_SERVER}api/Candidate/RequestToResubmit`, { ...TokenData, CandidateCode: CandidateCodeByUrl, ResubmitRemarks:RequestToResubmitTextArea }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        setLoading(false)
                        toast.error(res.message)
                    } else if (res.status === "EXPIRED") {
                        toast.error(res.message)
                        sessionExpired()
                    } else if (res.status === "UNAUTHORIZED") {
                        setLoading(false);
                        toast.error(res.message);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        setShowRequestResubmitModal(false)
                        setRequestToResubmitTextArea("")
                        navigate('/Candidate/VerifyCandidate')
                        GetNoificaitonCount(notificationData, setNotificationData, TokenData, sessionExpired)
                    }
                }
            });
        }
    }


    useEffect(() => {
        if (CandidateCodeByUrl === null) return;
        else {
            handleEdit(CandidateCodeByUrl)

        }
    }, [CandidateCodeByUrl])

    useEffect(() => {
        document.title = 'Pay Plus : Candidate Master';
        GetProfilePhoto();
        GetSubmitCheckValue();
    }, [])

    return (
        <>


            {CandidateCodeByUrl &&
                <div className='d-md-flex justify-content-between align-items-center'> 

                <InputBox
                    inputrefs={inputrefs}
                    customclass='col-lg-12 mb-2'
                    disabled
                    label='Candidate : &nbsp;'
                    id='txtEmpCode'
                    key='txtEmpCode'
                    maxLength='50'
                    placeholder="SYSTEM GENERATED"
                    value={`${CandidateCodeByUrl} : ${recipe.CandidateName}`}
                    labelleft={true}
                //onChange={(e) => { HandleChange('EmpCode', (e.target.value)) }}

                    />
                    <div className='mb-2 d-md-flex gap-2'>
                        <CustomButton
                            id='btnVerifyCandidate'
                            ButtonIcon='fa-check'
                            ButtonText='Verify Candidate'
                            ButtonClass='btn-primary btn-sm me-2 mt-1'
                            onClick={handleVerifyCandidate}
                        />
                        <CustomButton
                            id='btnReSubmit'
                            ButtonIcon='fa-retweet'
                            ButtonText='Request to Re-Submit'
                            ButtonClass='btn-danger btn-sm mt-1'
                            onClick={() => setShowRequestResubmitModal(true)}
                        />
                    </div>
                </div>
            }

            
            {/* Profile Upload Modal */ }
            <Modal show={ShowUploadImageModal} onHide={() => setShowUploadImageModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Profile Picture</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputBox
                        inputrefs={inputrefs}
                        label='Candidate'
                        placeholder='Candidate'
                        divclassname='mb-2'
                        id='txtCandidate'
                        maxLength='5'
                        value={empCode}
                        //onChange={(e) => { ('',(e.target.value)) }}
                        required
                        disabled
                    //error={errors.ContractDays}
                    />
                  
                    <FileUploader
                        ref={ref => (inputrefs.current['candidateFileImage'] = ref)}
                        id='candidatefileImage'
                        name='ProfilePicture'
                        label=' Upload a right file here'
                        classes='ProfilePicture mb-2'
                        types={fileType.split(",")}
                        handleChange={(file) => {
                            let extArr = (file.name).split(".");
                            if (file) {
                                console.log(file.result, 'from log')
                                const reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = () => {
                                    let logo = (reader.result).split(',')[1]
                                    setProfilePictureRecipe({
                                        ...profilePictureRecipe,
                                        //  photo: reader.result,
                                        photo: logo,
                                        photoExt: extArr[1],
                                        photoName: extArr[0]
                                    });
                                };
                                const fileNameAttribute = document.querySelectorAll('.ProfilePicture')
                                if (fileNameAttribute.length > 0) {
                                    //fileNameAttribute[0].childNodes[2].innerHTML = `${file.name}`;
                                    fileNameAttribute[0].childNodes[2].innerHTML = `<div class="sc-kAyceB DimIH"><span class="sc-imWYAI jRuVVs">${file.name}</span></div>`
                                    //   fileNameAttribute[0].childNodes[1].innerHTML = `<p>hii</p>`;
                                    //  console.log(fileNameAttribute[0].childNodes)
                                }
                            }
                        }}

                    />
                    <img
                        src={profilePictureRecipe.photo ? `data:image/png;base64,${profilePictureRecipe.photo}` : 'http://in.payplushrms.com/ImageHandler.ashx?ID=10367&Type=Profile'}
                        style={{ height: '150px', width: '120px', }}
                        alt='Candidate Profile '
                        className='border img-thumbnail'
                    // src={`data:image/png;base64,${profilePictureRecipe.photo}`}
                    />
                </Modal.Body>
                <Modal.Footer>

                    <button type="button" className="btn btn-rounded btn-sm btn-success" onClick={HandleUploadImage}> <span className="text-white me-2"><i className="fa-solid fa-check"></i></span>Save </button>
                    <button type="button" className="btn btn-rounded btn-sm btn-danger" onClick={HandleClearUploadImage}> <span className="text-white me-2"><i className="fa-solid fa-arrow-rotate-left"></i></span>Reset </button>
                </Modal.Footer>
            </Modal>

            {/*   Modal for Re-submit Request    */ }
            <Modal show={ShowRequestResubmitModal} onHide={() => setShowRequestResubmitModal(false)}>
                <Modal.Header >
                    <Modal.Title>Request to Re-submit Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row p-2' >
                        <textarea name='Request to Resubmit' className='col-md-12 pt-2' id="RequestToResubmit" rows="3 " value={RequestToResubmitTextArea} onChange={(e) => setRequestToResubmitTextArea(e.target.value)} />
                    </div>
                 
                </Modal.Body>
                <Modal.Footer>
                   
                    <div className='mb-2 d-md-flex gap-2'>
                        <CustomButton
                            id='btnVerifyCandidate'
                            ButtonIcon='fa-check'
                            ButtonText='Send Request'
                            ButtonClass='btn-primary btn-sm me-2 mt-1'
                            onClick={handleReSubmit}
                        />
                        <CustomButton
                            id='btnReSubmit'
                            ButtonIcon='fa-close'
                            ButtonText='Cancel Request'
                            ButtonClass='btn-danger btn-sm mt-1'
                            onClick={() => {
                                setShowRequestResubmitModal(false)
                                setRequestToResubmitTextArea('')
                            }}
                        />
                    </div>
                </Modal.Footer>
            </Modal>



            <div>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                        <a className={`nav-link ${activeTab === 'PersonalDetails' ? 'active' : ''}`}
                            style={activeTab === 'PersonalDetails' ? { cursor: 'pointer', borderTop: '2px solid black' } : { cursor: 'pointer', border: '1px solid white', color: 'black' }}
                            id="PersonalDetails-tab"
                            onClick={() => handleTabClick('PersonalDetails')}
                        ><img height='16px' src={verifyCheck.personalDetails === true ? check_button : remove_button} alt='checkImg' /> Personal Details</a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeTab === 'QualificationDetails' ? 'active' : ''}`}
                            style={activeTab === 'QualificationDetails' ? { cursor: 'pointer', borderTop: '2px solid black' } : { cursor: 'pointer', border: '1px solid white', color: 'black' }}
                            id="QualificationDetails-tab"
                            onClick={() => handleTabClick('QualificationDetails')}
                        >
                            <img height='16px' src={verifyCheck.qualificationDetails === true ? check_button : remove_button} alt='checkImg' />  Qualification Details
                        </a>
                    </li>

                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeTab === 'FamilyDetails' ? 'active' : ''}`}
                            style={activeTab === 'FamilyDetails' ? { cursor: 'pointer', borderTop: '2px solid black' } : { cursor: 'pointer', border: '1px solid white', color: 'black' }}
                            id="FamilyDetails-tab"
                            onClick={() => handleTabClick('FamilyDetails')}
                        >
                            {/*<img height='16px' src={verifyCheck.familyDetails === true ? check_button : remove_button} alt='checkImg' />*/}
                            {verifyCheck.familyDetails === true ? <img height='16px' src={check_button} alt='checkImg' /> : <></>}Family Details
                        </a>
                    </li>

                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeTab === 'DocumentUpload' ? 'active' : ''}`}
                            style={activeTab === 'DocumentUpload' ? { cursor: 'pointer', borderTop: '2px solid black' } : { cursor: 'pointer', border: '1px solid white', color: 'black' }}
                            id="DocumentUpload-tab"
                            onClick={() => handleTabClick('DocumentUpload')}
                        >
                            <img height='16px' src={verifyCheck.documentUpload === true ? check_button : remove_button} alt='checkImg' />   Document Upload
                        </a>
                    </li>

                </ul>

                <div className="tab-content" id="myTabContent">
                    <div className={`tab-pane fade ${activeTab === 'PersonalDetails' ? 'show active' : ''}`} id="PersonalDetails" role="tabpanel">
                        <PersonalDetails CandidateCode={CandidateCodeByUrl}
                            setRecipe={setRecipe}
                            recipe={recipe}
                            setDateOfBirth={setDateOfBirth}
                            dateOfBirth={dateOfBirth}
                            handleTabClick={handleTabClick}
                            imageData={imageData}
                            setIsPerAddressSame={setIsPerAddressSame}
                            isPerAddressSame={isPerAddressSame}
                            TokenData={TokenData}
                            sessionExpired={sessionExpired}
                            activeTab={activeTab}
                            setShowUploadImageModal={setShowUploadImageModal}
                            setVerifyCheck={setVerifyCheck}
                            verifyCheck={verifyCheck}
                        />

                    </div>
                    <div className={`tab-pane fade ${activeTab === 'QualificationDetails' ? 'show active' : ''}`} id="QualificationDetails" role="tabpanel">
                        <QualificationDetails
                            CandidateCode={CandidateCodeByUrl}
                            qualificationDetails={qualificationDetails}
                            QualificationDetailsList={QualificationDetailsList ? QualificationDetailsList : []}
                            setQualificationDetailsList={setQualificationDetailsList}
                            PreviousEmploymentDetailsList={PreviousEmploymentDetailsList ? PreviousEmploymentDetailsList : []}
                            setPreviousEmploymentDetailsList={setPreviousEmploymentDetailsList}
                            handleTabClick={handleTabClick}
                            TokenData={TokenData}
                            sessionExpired={sessionExpired}
                            activeTab={activeTab}
                            setVerifyCheck={setVerifyCheck}
                            verifyCheck={verifyCheck}
                        />
                    </div>
                    <div className={`tab-pane fade ${activeTab === 'FamilyDetails' ? 'show active' : ''}`} id="FamilyDetails" role="tabpanel">
                        <CandidateFamilyDetails
                            CandidateCode={CandidateCodeByUrl}
                            handleTabClick={handleTabClick}
                            empCode={empCode}
                            TokenData={TokenData}
                            sessionExpired={sessionExpired}
                            activeTab={activeTab}
                            setVerifyCheck={setVerifyCheck}
                            verifyCheck={verifyCheck}
                        //FamilyDetails={FamilyDetails}
                        //  FamilyDetailsList={FamilyDetailsList ? FamilyDetailsList : []}
                        //  setFamilyDetailsList={setFamilyDetailsList}
                        //  PreviousEmploymentDetailsList={PreviousEmploymentDetailsList ? PreviousEmploymentDetailsList : []}
                        //  setPreviousEmploymentDetailsList={setPreviousEmploymentDetailsList}
                        />
                    </div>
                    <div className={`tab-pane fade ${activeTab === 'DocumentUpload' ? 'show active' : ''}`} id="DocumentUpload" role="tabpanel">
                        <DocumentUpload
                            CandidateCode={CandidateCodeByUrl}
                            documentDetails={documentDetails}
                            handleTabClick={handleTabClick}
                            empCode={empCode}
                            TokenData={TokenData}
                            sessionExpired={sessionExpired}
                            activeTab={activeTab}
                            setVerifyCheck={setVerifyCheck}
                            verifyCheck={verifyCheck}
                        />
                    </div>
                </div>
            </div>

            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}


            {/*   Modal for Re-submit Request    */}
      
        </>
    )
}

export default CandidateMaster;
