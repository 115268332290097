import React, { useEffect, useRef, useState } from 'react'
import { post } from '../../apiservices/service';
import { API_SERVER } from '../../Utils';
import { InputBox, FormButton, AutoCompleteBox, InputBoxWithEye, PasswordInputBox, SelectInputBox } from "../commoncomponents/InputBox";
import { toast } from 'react-toastify';
import { useAuth } from '../../context/auth';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { Token } from '../Common/Common';

function ResetPassword() {
    const inputrefs = useRef([]);

    //initial State
    const [recipe, setRecipe] = useState({ UserType: '', UserName: '', NewPassword: '', ConfirmPassword: '' })
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false);
    const { user, sessionExpired } = useAuth();
    const [userAllData, setUserAllData] = useState([])
    const [userTypeData, setUserTypeData] = useState([])

    // get user Name data
    const GetUserData = (value) => {
        let temp = {
            Type: "GetUser",
            Prefix: value ? value : '',
            ContextKey: recipe.UserType,
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setUserAllData([])
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setUserAllData(data)
                }
            }
        });
    }


    // Get User Type Data
    const GetUserTypeData = () => {
        setUserTypeData([])
        let temp = {
            Type: "GetUserGroup",
            Prefix: '',
        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setUserTypeData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setUserTypeData(data)
                }
            }
        });
    }

    //change handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    const TokenData = Token()

    const [showPassword, setShowPassword] = useState(false)
    const handleTogglePassword = (field) => {
        setShowPassword((prevShowPassword) => ({ ...prevShowPassword, [field]: !prevShowPassword[field] }));
    };


    //validation Handler
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let Validation = [
            { field: 'UserType', msg: 'Please Select User Type.' },
            { field: 'UserName', msg: 'Please Enter User Name.' },
            { field: 'NewPassword', msg: 'Please Enter New Password.' },
            { field: 'ConfirmPassword', msg: 'Please Enter Confirm Password.' },
        ]

        Validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }))
                isValid = false
            }
            return item;
        });

        return isValid;
    }

    //HandleSubmit
    const HandleSubmit = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/User/ResetPassword`, { ...recipe, ...TokenData }, (res) => {
                // console.log(res);
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        inputrefs.current[res.focus].focus();
                        setLoading(false)
                        handleClear();
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        handleClear();
                    }
                }
            });
        }
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({ ...recipe, UserType: '', UserName: '', NewPassword: '', ConfirmPassword: '' }))
        setErrors({})
    }

    let UserData = []
    for (let i = 0; i < userAllData.length; i++) {
        let mydata = userAllData[i]?.values
        UserData.push(mydata)
    };

//console.log(userAllData)

    useEffect(() => {
        inputrefs.current['txtUserType'].focus();
        document.title = 'Pay Plus: Reset Password';
        GetUserData();
        GetUserTypeData();
    }, [])
    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Reset Password</h6>
                </div>
                <div className='card-body w-100 row  '>
                    <div className='col-lg-4 col-md-6 col-sm-12 px-4'>
                        <SelectInputBox
                                divclassname='mb-1'
                            label='User Type'
                            inputrefs={inputrefs}
                            required
                            id="txtUserType"
                            value={recipe.UserType}
                            error={errors.UserType}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtUserName'].focus() };
                            }}
                            onChange={(e) => HandleChange('UserType', e.target.value)}
                            children={<>
                                <option value="User">--Select User Type--</option>
                                {userTypeData.map((opt, index) => <option key={index} value={opt.name} >{opt.name}</option>)}
                            </>}
                        />

                        <div className='row'>

                            <AutoCompleteBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtNewPassword'].focus() };
                                }}
                                divclassname='mb-2'
                                label='User Name'
                                placeholder="User Name"
                                maxLength='50'
                                options={UserData}
                                id='txtUserName'
                                required={true}
                                error={errors.UserName}
                                inputValue={recipe.UserName ? recipe.UserName : ''}
                                onInputChange={(event, newInputValue) => {
                                    HandleChange('UserName', newInputValue)
                                    GetUserData(newInputValue)
                                    //   setStatePre(newInputValue)
                                }}
                            />
                        </div>

                        <div className='row'>
                            <PasswordInputBox
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConfirmPassword'].focus() };
                                }}
                                divclassname=' mb-2'
                                inputrefs={inputrefs}
                                label='New Password'
                                id='txtNewPassword'
                                maxLength='50'
                                placeholder="New Password"
                                error={errors.NewPassword}
                                value={recipe.NewPassword}
                                required
                                onChange={(e) => HandleChange('NewPassword', e.target.value)}
                            />
                        </div>

                        <div className='row'>

                            <PasswordInputBox
                                inputrefs={inputrefs}
                                label='Confirm New Password'
                                divclassname='mb-2'
                                id='txtConfirmPassword'
                                maxLength='50'
                                placeholder="Confirm New Password"
                                error={errors.ConfirmPassword}
                                value={recipe.ConfirmPassword}
                                required
                                onChange={(e) => HandleChange('ConfirmPassword', e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { HandleSubmit() };
                                }}
                            />
                        </div>
                        <FormButton
                            Export={false}
                            handleSubmit={HandleSubmit}
                            handleExport={() => alert('Can`t Export Data Now ')}
                            handleClear={handleClear}
                        />
                    </div>

                    <div className='col-lg-8 col-md-6 col-sm-12 password-policy-border  border border-1  p-0  my-sm-2'>
                        <div className='w-100 py-2 password-policy px-3'>
                            <h5 className="m-0 font-weight-bold text-white">Password Policy</h5>
                        </div>
                        <div className='p-3'>
                            <div className="form-group px-2">
                                <li class="ResetText">White spaces are not allowed.</li>
                                <li class="ResetText">Password must be between 8 to 15 character(s).</li>
                                <li class="ResetText">Password must be combination of number, upper case letter, lower case letter and special character [@@$#!() ].</li>
                                <li class="ResetText">New password and confirm password must be same.</li>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default ResetPassword;