import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../context/auth';
import { AutoCompleteBox, FormButton, MonthBox, YearBox } from "../commoncomponents/InputBox";
//loder
import BlurLoader from "../Loder/BlurLoader";
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';
//services
import { getCommonData } from '../../apiservices/CommonServices';
import { post } from '../../apiservices/service';
import { Token, currentYear } from '../Common/Common';
import * as XLSX from 'xlsx';
function ProcessSalary() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();
    const TokenData = Token()
    const [recipe, setRecipe] = useState({
        all: false,
        branch: '',
        employee: '',
        department: '',
        month: new Date(),
        year: new Date(),
    })
    //const currentY = currentYear(new Date());
    // console.log(recipe.month.toLocaleString('default', { month: 'long' }), 'currentYear')
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData);
    const [isUpdate, setIsUpdate] = useState(false);
    const [branchAllData, setBranchAllData] = useState([])
    const [companyAllData, setCompanyAllData] = useState([])
    const [employeeAllData, setEmployeeAllData] = useState([])
    const [departmentAllData, setDepartmentAllData] = useState([])
    const [errors, setErrors] = useState({});
    const [close, setClose] = useState(false);
    const [excelData, setExcelData] = useState('')
    //Get company Data for AutoComplete
    const GetCompanyData = (value) => {
        setCompanyAllData([])
        let temp = {
            Type: "GetCompany",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setCompanyAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setCompanyAllData(data)
                }
            }
        });
    }

    //Get branch Data for AutoComplete
    const GetBranchData = (value) => {
        setBranchAllData([])
        let temp = {
            Type: "GetBranch",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setBranchAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setBranchAllData(data)
                }
            }
        });
    }

    //Get Employee Data for AutoComplete
    const GetEmployeeData = (value) => {
        setEmployeeAllData([])
        let temp = {
            Type: "GetEmployee",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setEmployeeAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setEmployeeAllData(data)
                }
            }
        });
    }

    //Get Department Data for AutoComplete
    const GetDepartmentData = (value) => {
        setDepartmentAllData([])
        let temp = {
            Type: "GetDepartment",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
      //  setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setDepartmentAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setDepartmentAllData(data)
                }
            }
        });
    }

    //Submit Handler
    const handleSubmit = () => {
        setClose(false)
        if (Validate()) {
            setLoading(true)
            const currentMonthInNo = `${(recipe.month).getMonth() + 1}`;
            post(`${API_SERVER}api/Transaction/ProcessSalary`, { ...TokenData, ...recipe, month: currentMonthInNo, year: currentYear(recipe.year) }, (res) => {
                if (res && res.Status) {
                    if (res.Status === "ERROR") {
                        setLoading(false)
                        toast.error(res.Message)
                        inputrefs.current[res.focus].focus();
                        //    setResult({ ...res })
                        //  console.log(recipe, 'if')
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "SUCCESS") {
                        setLoading(false)
                        toast.success(res.Message)
                        setExcelData(res);
                        setClose(true)
                    } else {
                        setLoading(false)
                        toast.error(res.Message)                       
                    }
                }
            });
        }

    }


    const handleChange1 = (event, value) => {
        //getFormData(value);
        handleSubmit(value);
    };

    useEffect(() => {
        inputrefs.current['txtBranch'].focus();
        document.title = 'Pay Plus : Process Salary';
        GetCompanyData();
        GetBranchData();
        GetEmployeeData();
        GetDepartmentData();
        //      if (sortConfig.SortOrder !== null) handleSubmit();


    }, [])


    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
           // { field: "company", msg: 'Please Enter Company.' },
            { field: "month", msg: 'Please Enter Month.' },
            { field: "year", msg: 'Please Enter Year.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {

                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            all: false,
         //   company: '',
            branch: '',
            employee: '',
            department: '',
            month: '',
            year: '',

        }))
        setClose(false)
        setErrors({})
        setExcelData('')
    }


    let CompanyData = []
    for (let i = 0; i < companyAllData.length; i++) {
        let mydata = companyAllData[i]?.values
        CompanyData.push(mydata)
    };
    let BranchData = []
    for (let i = 0; i < branchAllData.length; i++) {
        let mydata = branchAllData[i]?.values
        BranchData.push(mydata)
    };

    let EmployeeData = []
    for (let i = 0; i < employeeAllData.length; i++) {
        let mydata = employeeAllData[i]?.values
        EmployeeData.push(mydata)
    };
    let DepartmentData = []
    for (let i = 0; i < departmentAllData.length; i++) {
        let mydata = departmentAllData[i]?.values
        DepartmentData.push(mydata)
    };

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        ...recipe,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality

    const DownloadExcel = (FileName, res,) => {

        if (res.Status === 'SUCCESS') {
            const workbook = XLSX.utils.book_new();
            let worksheet = XLSX.utils.aoa_to_sheet([
                res?.HeadersValue
                ,
                ...res?.Data?.map((records) =>
                    res.HeadersValue?.map((data, i) =>
                        records[data]
                    )
                ),
            ]);
            XLSX.utils.book_append_sheet(workbook, worksheet, FileName);
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${FileName}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            return
        }
    }

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Process Salary</h6>
                </div>
                <div className='card-body'>
                    <div className='form-group ms-2'>
                        <fieldset className="myfieldset "   >
                            <legend className="mylegend"> <h6 className="m-0">Basic Details <input type='checkbox' checked={recipe.all} onChange={(e) => HandleChange('all', e.target.checked)} /></h6></legend>
                            <div className='row'>
                                <AutoCompleteBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtEmployee'].focus() };
                                    }}
                                    label='Branch'
                                    placeholder="Branch"
                                    maxLength='50'
                                    options={BranchData}
                                    id='txtBranch'
                                    key='txtBranch'
                                    //required={true}
                                    // error={errors.branch}
                                    inputValue={recipe.branch ? recipe.branch : ''}
                                    onInputChange={(event, newInputValue) => {
                                        GetBranchData(newInputValue)
                                        HandleChange('branch', newInputValue)

                                    }}
                                />
                                <AutoCompleteBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtDepartment'].focus() };
                                    }}
                                    label='Employee'
                                    placeholder="Employee"
                                    maxLength='50'
                                    options={EmployeeData}
                                    id='txtEmployee'
                                    key='txtEmployee'
                                     required={true}
                                    // error={errors.employee}
                                    disabled={recipe.all}
                                    inputValue={recipe.employee ? recipe.employee : ''}
                                    onInputChange={(event, newInputValue) => {
                                        GetEmployeeData(newInputValue)
                                        HandleChange('employee', newInputValue)

                                    }}
                                />

                                <AutoCompleteBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtMonth'].focus() };
                                    }}
                                    label='Department'
                                    placeholder="Department"
                                    maxLength='50'
                                    options={DepartmentData}
                                    id='txtDepartment'
                                    key='txtDepartment'
                                    // required={true}
                                    // error={errors.department}
                                    inputValue={recipe.department ? recipe.department : ''}
                                    onInputChange={(event, newInputValue) => {
                                        GetDepartmentData(newInputValue)
                                        HandleChange('department', newInputValue)

                                    }}
                                />

                                <MonthBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtYear'].focus() };
                                    }}
                                    label='Month'
                                    // labelClass='text-nowrap'
                                    //customclass='w-25'
                                    // divclassname='col-lg-6'
                                    id='txtMonth'
                                    selected={recipe.month ? recipe.month : ""}
                                    placeholder="Month"
                                    onChange={(e) => { HandleChange('month', e) }}
                                    required
                                    error={errors.month}
                                />
                                <YearBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { handleSubmit() };
                                    }}
                                    label='Year'
                                    // labelClass='text-nowrap'
                                    // divclassname='col-lg-4'
                                    id='txtYear'
                                    selected={recipe.year ? recipe.year : ""}
                                    placeholder="Year"
                                    onChange={(e) => { HandleChange('year', e) }}
                                    required
                                    error={errors.year}
                                />

                            </div>
                        </fieldset>
                        <FormButton
                            saveButtonIcon='fa-check'
                            SaveText='Process Salary'
                            Export={false}
                            handleSubmit={handleSubmit}
                            //handleExport={() => handleDownloadExcel(API_SERVER + 'api/Report/ShowLeaveAndLeftEmployeeReport', ExportRequest, 'Leave Status Report')}
                            handleClear={handleClear}
                        />

                        {close && <div className='row '>
                            <div className='ms-2 border rounded d-flex justify-content-between align-items-center ' style={{ background: '#95d295b0' }}>
                                <div className='mx-0 my-2 w-50'>
                                    <span className=" d-flex justify-content-between">
                                        <span className='text-danger' >
                                            <i className='fa fa-check'></i> Total Exception : <span
                                                className=''
                                                onClick={() => {
                                                    if (excelData.TotalException !== '0' && excelData.Status === 'SUCCESS' && excelData.TotalException !== "") {
                                                        DownloadExcel("Exception_ProcessSalary", excelData)
                                                    }
                                                }}
                                                style={{ cursor: "pointer" }}>{excelData.TotalException} &nbsp;&nbsp;Export Exception Data </span></span>
                                    </span>
                                </div>
                                <button onClick={() => setClose(false)} type='button' className='btn btn-outline-danger m-1 min-vw-0 p-1 px-2 rounded' style={{ minWidth: '0' }}><i className='fa fa-close m-0 p-0'></i></button>
                            </div>
                        </div>
                        }

                    </div>
                </div>
            </div>

            {loading && < BlurLoader />}
        </>
    )
}

export default ProcessSalary
