import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import { AutoCompleteBox, FormButton } from "../commoncomponents/InputBox";
import { Table } from '../commoncomponents/Table';
//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';

//services
import { getCommonData } from '../../apiservices/CommonServices';
import { post } from '../../apiservices/service';
import { Token } from '../Common/Common';


function VerifyFamilyDetails() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();

    //token Data
    //const TokenData = {
    //    AuthToken: process.env.REACT_APP_SECRET_KEY,
    //    LoginToken: user.loginsessionID,
    //    UserID: user.userID,
    //    CompanyID: user.companyID
    //}
    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({
        createdBy: '',



    })
    const navigate = useNavigate()
    const [Id, setId] = useState([])
    const [showTable, setShowTable] = useState(false)
    const [errors, setErrors] = useState({});
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [createdByAllData, setCreatedByAllData] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const [dateOfBirth, setDateOfBirth] = useState(new Date());
    // const [dateOfBirthE, setDateOfBirthE] = useState(new Date());
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [Reason, setReason] = useState('')



    //Get createdBy Data for AutoComplete
    const GetCreatedByData = (value) => {
        setCreatedByAllData([])
        let temp = {
            Type: "GetUser",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        // setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setCreatedByAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setCreatedByAllData(data)
                }
            }
        });
    }

    //const getFormData = (CurrentPage) => {
    //    let PageIndex = 1
    //    if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
    //        PageIndex = CurrentPage;
    //        setCurrentPageNo(CurrentPage);
    //    }
    //    else {
    //        setCurrentPageNo(1);
    //    }

    //    setLoading(true)

    //    let temp = {
    //        PageIndex: PageIndex.toString(),
    //        SearchText: "",
    //        StartValue: "1",
    //        SearchFieldValue: "",
    //        Type: "s"
    //    }
    //    post(`${API_SERVER}api/Candidate/SearchVerifyCandidate`, { ...TokenData, ...temp, ...sortConfig, ...filterData, ...recipe, PageType: "VerifyCandidate" }, (res) => {
    //        if (res && res.Status) {
    //            if (res.Status === "ERROR") {
    //                setLoading(false)
    //                setResult({ ...res })
    //            } else {
    //                setLoading(false)
    //                setResult({ ...res })
    //            }
    //        }
    //    });
    //}





    //Change Handler
    const HandleChange = (prop, value) => {

        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //validation handler
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "CandidateCode", msg: 'Please Enter Candidate Code.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });


        return isValid;
    }
    const ValidateReason = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "CandidateCode", msg: 'Please Enter Candidate Code.' },
        ]

        validation.map(item => {
            if (!Reason || Reason.length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });


        return isValid;
    }


    //Submit Handler
    const handleSubmit = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        // setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/Employee/GetEmployeeFamilyDetail`, { ...TokenData, ...temp, ...filterData, ...sortConfig, ...recipe }, (res) => {

            // console.log('1111111111111111111111111',res)
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    toast.error(res.Message)
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    handleClear();
                    setLoading(false);
                } else {
                    setLoading(false)
                    //toast.success(res.Message)
                    setResult({ ...res })
                    setShowTable(true)
                    //getFormData()
                    // handleClear()
                    //console.log(recipe, 'else')
                }
            }
        });

    }



    const handleChange1 = (event, value) => {
        // getFormData(value);
        handleSubmit(value);
    };
    //Handle Edit
    const handleCheckData = (id, e) => {
        if (e === true) {
            setId((preValue) => [...preValue, id])
        } else {
            const updatedIds = Id.filter(ele => ele !== id)
            setId(updatedIds)
        }
    }


    //Handle Delete
    const handleDelete = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/Candidate/DeleteCandidate`, { ...TokenData, CandidateID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else {
                    toast.success(res.message)
                    setLoading(false)
                    //getFormData()
                    //getFormData()
                }
            }
        });
    }

    // handle verify data
    const handleVerify = () => {
        if (recipe.createdBy === '') {
            toast.error('Please Select Created By.!')
        } else {
            if (Id.length > 0) {
                post(`${API_SERVER}api/Employee/VerifyEmployeeFamilyDetail`, { ...TokenData, ID: (Id.length > 0 ? `${Id.join(",")}` : ''), isVerified: '1' }, (res) => {
                    if (res && res.status) {
                        if (res.status === "ERROR") {
                            toast.error(res.message)
                            setLoading(false)
                        } else if (res.status === "EXPIRED") {
                            toast.error(res.message)
                            sessionExpired()
                        } else if (res.status === "UNAUTHORIZED") {
                            toast.error(res.message);
                            handleClear();
                            setLoading(false);
                        } else {
                            toast.success(res.message)
                            setLoading(false)
                            setShowTable(false)
                            handleClear()
                        }
                    }
                });

            } else {
                toast.error('Please Select Employee')
            }
        }

    }

    //Handle Reject
    const HandleReject = () => {
        ////  var Reason = prompt("Please Fill Rejected Reason")
        //if (Reason === null || Reason === '') {
        //    //toast.error('Fill Rejected Reason')
        //    return;
        //} else {
        if (recipe.createdBy === '') {
            toast.error('Please Select Created By.!')
        } else {
            if (Id.length > 0) {
                post(`${API_SERVER}api/Employee/VerifyEmployeeFamilyDetail`, { ...TokenData, ID: (Id.length > 0 ? `${Id.join(",")}` : ''), isVerified: '0' }, (res) => {
                    // console.log('1111111111111111111111111', res)
                    if (res && res.status) {
                        if (res.status === "ERROR") {
                            toast.error(res.message)
                            setLoading(false)
                        } else if (res.Status === "EXPIRED") {
                            toast.error(res.Message)
                            sessionExpired()
                        } else if (res.status === "UNAUTHORIZED") {
                            toast.error(res.message);
                            handleClear();
                            setLoading(false);
                        } else {
                            toast.success(res.message)
                            setLoading(false)
                            setShowTable(false)
                            handleClear()
                        }
                    }
                });

            } else {
                toast.error('Please Select Employee')
            }
        }
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            //  CandidateID: '0',
            createdBy: '',

        }))
        setErrors({})
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        setShowTable(false)
        setId([])
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    let CreatedByData = []
    for (let i = 0; i < createdByAllData.length; i++) {
        let mydata = createdByAllData[i]?.values
        CreatedByData.push(mydata)
    };


    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...recipe,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1',
        //PageType: "VerifyCandidate"

    }
    //End Export functionality

    useEffect(() => {
        inputrefs.current['txtCreatedBy'].focus()
        document.title = 'Pay Plus: Verify Family Details';
        GetCreatedByData();
        //    getFormData()
    }, [sortConfig, isUpdate])


    return (



        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Verify Family Details</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>

                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { handleSubmit() };
                            }}
                            label='Created By'
                            placeholder="Created By"
                            maxLength='50'
                            options={CreatedByData}
                            id='txtCreatedBy'
                            key='txtCreatedBy'
                            required={true}
                            error={errors.createdBy}
                            inputValue={recipe.createdBy ? recipe.createdBy : ''}
                            onInputChange={(event, newInputValue) => {
                                GetCreatedByData(newInputValue)
                                HandleChange('createdBy', newInputValue)

                            }}
                        />


                        <FormButton
                            SaveText='Search'
                            // exportText={'Reject'}
                            // exportButtonIcon='fa-close'
                            //  handleExport={HandleRejectCandidate}
                            Reject={true}
                            rejectColor="btn-danger"
                            handleReject={HandleReject}
                            Next={true}
                            saveButtonIcon='fa-search'
                            nextButtonText='Verify'
                            nextButtonIcon='fa-check'
                            nextButtonColor='btn-success'
                            handleNext={handleVerify}
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/Employee/GetEmployeeFamilyDetail', ExportRequest, 'Verify Family Details')}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>
            {/* Modal for Reject Candidate  
            <div className="modal fade" id="RejectReasonModal" tabIndex="-1" aria-labelledby="RejectReasonModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="RejectReasonModalLabel">Reject Reason</h1>
                            <button type="button" data-bs-dismiss="modal" aria-label="Close" className="btn btn-rounded btn-sm btn-outline-danger" onClick={() => setReason('')} ><i className="fa-solid fa-close"></i></button>

                        </div>
                        <div className="modal-body">
                            <InputBox
                                inputrefs={inputrefs}
                                label='Reason'
                                placeholder='Reason'
                                divclassname='mb-2'
                                id='txtReason'
                                maxLength='100'
                                value={Reason}
                                onChange={(e) => { setReason(e.target.value) }}
                                required
                                error={Reason || Reason.lenght <= 0 ? "Please Fill Rejected Reason" : ''}
                            />

                        </div>
                        <div className="modal-footer">

                            <button type="button" className="btn btn-rounded btn-sm btn-success" onClick={HandleRejectCandidate}> <span className="text-white me-2"><i className="fa-solid fa-check"></i></span>Reject </button>
                            <button type="button" className="btn btn-rounded btn-sm btn-danger" data-bs-dismiss="modal" aria-label="Close" onClick={() => setReason('')} > <span className="text-white me-2"><i className="fa-solid fa-arrow-rotate-left"></i></span>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            */}

            {/* Table Component start */}
            {showTable &&

                <Table
                    Filter={false}
                    Token={TokenData}
                    PageName='VerifyFamilyDetails'
                    handleCheckData={handleCheckData}
                 //   handleFilter={() => {
                 //       // getFormData();
                 //       handleSubmit();
                 //       setFilterData({
                 //           ...filterData,
                 //       });
                 //   }}
                    tableData={result}
                //    filterData={filterData}
                //    setFilterData={setFilterData}
                    loading={loading}
                    handleChange1={handleChange1}
                    currentPageNo={currentPageNo}
              //      handleSorting={handleSorting}
              //      sortConfig={sortConfig}
                />
            }
            {/* Table Component End*/}


            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}

        </>
    )
}

export default VerifyFamilyDetails;