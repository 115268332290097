import { Link } from 'react-router-dom';
import page_lost from '../../assests/images/page_lost.gif'
import { useAuth } from '../../context/auth';

const PageNotFound = () => {
    const auth = useAuth();
    return (
        <section className="py-3" style={{ backgroundColor: '#fff', height:'100vh' }}>
            <div className="container">
                <div className="row pt-5">
                    <div className="col-12 mb-5">
                        <center>
                            <div className="thankyou-img">
                                <img src={page_lost} className="img-fluid" alt='' width='500' />
                            </div>
                            <div className="thankyou-text">
                                <h1 className="main-heading">404</h1>
                                <h6 className="main-heading">Looks like your're lost!</h6>
                               
                                <Link to="/Dashboard/Dashboard" className="btn btn-primary text-white mt-3">
                                    {auth.user ? 'Back to Dashboard' : 'Back'}
                                </Link>
                            </div>
                        </center>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PageNotFound;