import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { useAuth } from './context/auth';
import { atom } from 'recoil';
const protocol = window.location.protocol;
const hostname = window.location.hostname;
const host = window.location.host;
let apiUrl = `${protocol}//${host}/`;
//export const API_SERVER = 'http://localhost:5102/';
////export const API_SERVER = 'http://react.payplushrms.com/';
//export const API_SERVER = apiUrl;// 'http://in.payplushrms.com/';
if (hostname === 'localhost') {
    apiUrl='http://localhost:5102/';
} else {
    apiUrl='http://in.payplushrms.com/';
}

export const API_SERVER = apiUrl;

export const initializeFilterData = {
    "SearchText": "",
    "StartValue": "1",
    "SearchFieldValue": "",
    "PageSize": "10",
    "AdvanceFilter": []
}

export const AutoCompleteList = ( Type , Token, preFix, ContextKey, ContextKey2, cb) => {
        fetch(`${API_SERVER}api/AutoComplete/GetAutoComplete`, {
            method: 'POST',
            body: JSON.stringify({ ...Token, Type, preFix, ContextKey, ContextKey2 }),
            headers: {
                "Content-Type": "application/json"
            }
        }).then((res) => res.json())
            .then((res) => {
                cb({res})
            })
            .catch((err) => (err))
}

function isNumeric(value) {
    return /^-?\d*\.?\d+$/.test(value);
}


export const handleDownloadExcel = async (handleApiUrl, requestData, FileName, sessionExpired) => {
    const apiUrl = handleApiUrl;
    const requestBody = requestData
    const requestHeaders = {
        'Content-Type': 'application/json',
    };
    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: requestHeaders,
        });
        const res = await response.json();
        if (res.Status === "SUCCESS") {
            const workbook = XLSX.utils.book_new();
            let worksheet = XLSX.utils.aoa_to_sheet([
                res?.HeadersValue
                ,
                ...res?.Data?.map((records) =>
                    res.HeadersKey?.map((data, i) =>
                        // records[data]
                        isNumeric(records[data]) ? parseFloat(records[data]) : records[data]
                    )
                ),
            ]);
            XLSX.utils.book_append_sheet(workbook, worksheet, FileName);
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${FileName}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            return
        } else if (res.Status === 'ERROR') {
            console.log("Error")
            toast.error(res.Message)
        } else if (res.Status === "EXPIRED") {
            toast.error(res.Message)
            sessionExpired()
        } else if (res.Status === "UNAUTHORIZED") {
            toast.error(res.Message);
        } 
    } catch (err) {
        toast.error(err.message, {
            position: 'top-center',
        });
    }


};

export const NotificationCount = atom({
    key: "notificationCount",
    default: {
        pendingVerificationData:'',
        pendingConvertToEmployee: '',
        totalNotification:''
    }

})