import React, { useEffect, useState, useRef } from 'react'
import {SelectInputBox, FormButton,AutoCompleteBox,DateBox } from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
//loder
import BlurLoader from "../Loder/BlurLoader";
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { getCommonData } from '../../apiservices/CommonServices';
import { Token, dateFormate} from '../Common/Common';

function RoasterReport() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();
    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({
        loanTypeID: "0",
        fromDate: '',
        toDate: '',
        employee: '',
        shift: '',
    })

    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData);
    const [showTable, setShowTable] = useState(false)
    const [employeeAllData, setEmployeeAllData] = useState([])
    const [loanTypeAllData, setLoanTypeAllData] = useState([])

    //Get Employee Data for AutoComplete
    const GetEmployeeData = (value) => {
        setEmployeeAllData([])
        let temp = {
            Type: "GetEmployee",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setEmployeeAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setEmployeeAllData(data)
                }
            }
        });
    }

    const GetLoanTypeData = (value) => {
        setLoanTypeAllData([])
        let temp = {
            Type: "GetLoanType",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        //  setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setLoanTypeAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setLoanTypeAllData(data)
                }
            }
        });
    }

    //Submit Handler
    const handleSubmit = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        setLoading(true)
        post(`${API_SERVER}api/Loan/ShowLoanReport`, { ...TokenData, ...temp, ...sortConfig, ...filterData, ...recipe }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    toast.error(res.Message)
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    toast.success(res.message)
                    setResult({ ...res })
                    setShowTable(true)
                }
            }
        });

    }

    const handleChange1 = (event, value) => {
        handleSubmit(value);
    };

    useEffect(() => {
        inputrefs.current['txtEmployee'].focus();
        document.title = 'Pay Plus : Loan Report';
        GetLoanTypeData()
        GetEmployeeData();
        if (sortConfig.SortOrder !== null) handleSubmit();
    }, [sortConfig,false])

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            loanTypeID: "0",
            fromDate: '',
            toDate: '',
            employee: '',
            shift: '',
        }))
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setShowTable(false)
        inputrefs.current['txtCompany'].focus();
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    let EmployeeData = []
    for (let i = 0; i < employeeAllData.length; i++) {
        let mydata = employeeAllData[i]?.values
        EmployeeData.push(mydata)
    };

    let LoanTypeData = []
    for (let i = 0; i < loanTypeAllData.length; i++) {
        let mydata = loanTypeAllData[i]?.values
        LoanTypeData.push(mydata)
    };
    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        ...recipe,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Loan Report</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtLoanType'].focus() };
                            }}
                            label='Employee'
                            placeholder="Employee"
                            maxLength='50'
                            options={EmployeeData}
                            id='txtEmployee'
                            key='txtEmployee'
                            inputValue={recipe.employee ? recipe.employee : ''}
                            onInputChange={(event, newInputValue) => {
                                GetEmployeeData(newInputValue)
                                HandleChange('employee', newInputValue)

                            }}
                        />
                        <SelectInputBox
                            label='Loan Type'
                            id='txtLoanType'
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtFromDate'].focus() };
                            }}
                            inputrefs={inputrefs}
                            value={recipe.loanType}
                            onChange={(e) => {
                                HandleChange('loanType', e.target.value)
                            }}
                            children={<>
                                <option value='' >--Select Loan Type--</option>
                                {loanTypeAllData.map((opt, index) => <option key={index} value={opt.values} >{opt.values}</option>)}
                            </>}
                        />
                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtToDate'].focus() };
                            }}
                            id='txtFromDate'
                            label='From Date'
                            placeholder="From Date"
                            selected={recipe.fromDate === '' ? '' : new Date(recipe.fromDate)}
                            onChange={(e) => HandleChange('fromDate', dateFormate(e))}
                        />
                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtApprovalBy'].focus() };
                            }}
                            id='txtToDate'
                            label='To Date'
                            placeholder="To Date"
                            selected={recipe.toDate === '' ? '' : new Date(recipe.toDate)}
                            onChange={(e) => HandleChange('toDate', dateFormate(e))}
                        />

                        <FormButton
                            saveButtonIcon='fa-search'
                            SaveText='Search'
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/Loan/ShowLoanReport', ExportRequest, 'Loan Reports')}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>
            {showTable &&
                <Table
                    Filter={false}
                    Action={false}
                    Token={TokenData}
                    PageName='LoanReport'
                    handleFilter={() => {
                        handleSubmit();
                        setFilterData({
                            ...filterData,
                        });
                    }}
                    tableData={result}
                    loading={loading}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    currentPageNo={currentPageNo}
                    handleChange1={handleChange1}
                    handleSorting={handleSorting}
                    sortConfig={sortConfig}
                />
            }
            {loading && < BlurLoader />}
        </>
    )
}

export default RoasterReport