
import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, AutoCompleteBoxWithLabel, DateBox, YearBox, MonthBox, SelectInputBox } from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
//loder
import BlurLoader from "../Loder/BlurLoader";
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { Token, currentMonth, currentYear, dateFormate, number, numberwithDot } from '../Common/Common';

function SalarySheetInExcel() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();
    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({
        branch: '',
        employee: '',
        department: '',
        designation: '',
        month: new Date(),
        year: new Date(),
        salaryType:""
    })

    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [showTable, setShowTable] = useState(false)
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData);
    const [isUpdate, setIsUpdate] = useState(false);
    const [branchAllData, setBranchAllData] = useState([])
    const [companyAllData, setCompanyAllData] = useState([])
    const [employeeAllData, setEmployeeAllData] = useState([])
    const [departmentAllData, setDepartmentAllData] = useState([])
    const [designationAllData, setDesignationAllData] = useState([])
    const [errors, setErrors] = useState({});
    // const [showTable, setShowTable] = useState(false)


    //Get company Data for AutoComplete
    const GetCompanyData = (value) => {
        setCompanyAllData([])
        let temp = {
            Type: "GetCompany",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
       // setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setCompanyAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setCompanyAllData(data)
                }
            }
        });
    }

    //Get branch Data for AutoComplete
    const GetBranchData = (value) => {
        setBranchAllData([])
        let temp = {
            Type: "GetBranch",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
     //   setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setBranchAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setBranchAllData(data)
                }
            }
        });
    }

    //Get Employee Data for AutoComplete
    const GetEmployeeData = (value) => {
        setEmployeeAllData([])
        let temp = {
            Type: "GetEmployee",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
       // setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setEmployeeAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setEmployeeAllData(data)
                }
            }
        });
    }

    //Get Department Data for AutoComplete
    const GetDepartmentData = (value) => {
        setDepartmentAllData([])
        let temp = {
            Type: "GetDepartment",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
      //  setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setDepartmentAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setDepartmentAllData(data)
                }
            }
        });
    }

    //Get Designation Data for AutoComplete
    const GetDesignationData = (value) => {
        setDesignationAllData([])
        let temp = {
            Type: "GetDesignation",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
      //  setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setDesignationAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setDesignationAllData(data)
                }
            }
        });
    }

    //Submit Handler
    const handleSubmit = (CurrentPage) => {

        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        // setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        if (Validate()) {
            setLoading(true)
            const currentMonthInNo = `${(recipe.month).getMonth() + 1}`;
            post(`${API_SERVER}api/Transaction/SalarySheetInExcel`, { ...TokenData, ...temp, ...filterData, ...sortConfig, ...recipe, month: currentMonthInNo, year: currentYear(recipe.year) }, (res) => {

                // console.log('1111111111111111111111111',res)
                if (res && res.Status) {
                    if (res.Status === "ERROR") {
                        toast.error(res.Message)
                        setLoading(false)
                        setResult({ ...res })
                        console.log(recipe, 'if')
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else {
                        setLoading(false)
                        //toast.success(res.Message)
                        setResult({ ...res })
                        setShowTable(true)
                        //getFormData()
                        // handleClear()
                        //console.log(recipe, 'else')
                    }
                }
            });
        }

    }


    const handleNext = () => {
        setLoading(true)
        post(`${API_SERVER}api/Transaction/PrintSalarySheetWages`, { ...TokenData, ...recipe, month: `${(recipe.month).getMonth() + 1}`,year: currentYear(recipe.year) }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.status === "EXPIRED") {
                    toast.error(res.message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    let base64PDF = res && res.base64PDF
                    window.open(`/PdfView/#data:application/pdf;base64,${base64PDF}`, '_blank')
                }
            }
        });
    }


    const handleChange1 = (event, value) => {
        //getFormData(value);
        handleSubmit(value);
    };

    useEffect(() => {
       // inputrefs.current['txtCompany'].focus();
        inputrefs.current['txtBranch'].focus();
        document.title = 'Pay Plus : Salary Sheet In Excel';
        //GetCompanyData();
        GetBranchData();
        GetEmployeeData();
        GetDepartmentData();
        GetDesignationData();
        //      if (sortConfig.SortOrder !== null) handleSubmit();


    }, [])


    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
          //  { field: "company", msg: 'Please Enter Company.' },
            { field: "month", msg: 'Please Enter Month.' },
            { field: "year", msg: 'Please Enter Year.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {

                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            branch: '',
            employee: '',
            department: '',
            designation: '',
            month: '',
            year: '',
            salaryType: ""
        }))
        setErrors({})
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        setShowTable(false)
        // getFormData()
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    let CompanyData = []
    for (let i = 0; i < companyAllData.length; i++) {
        let mydata = companyAllData[i]?.values
        CompanyData.push(mydata)
    };
    let BranchData = []
    for (let i = 0; i < branchAllData.length; i++) {
        let mydata = branchAllData[i]?.values
        BranchData.push(mydata)
    };

    let EmployeeData = []
    for (let i = 0; i < employeeAllData.length; i++) {
        let mydata = employeeAllData[i]?.values
        EmployeeData.push(mydata)
    };
    let DepartmentData = []
    for (let i = 0; i < departmentAllData.length; i++) {
        let mydata = departmentAllData[i]?.values
        DepartmentData.push(mydata)
    };
    let DesignationData = []
    for (let i = 0; i < designationAllData.length; i++) {
        let mydata = designationAllData[i]?.values
        DesignationData.push(mydata)
    };

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        ...recipe,
        Type: 'E',
        PageIndex: '1',
        month: recipe.month ?`${(recipe.month).getMonth() + 1}`:'',
        year: recipe.year? currentYear(recipe.year):''
    }
    //End Export functionality


    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Salary Sheet In Excel</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtEmployee'].focus() };
                            }}
                            label='Branch'
                            placeholder="Branch"
                            maxLength='50'
                            options={BranchData}
                            id='txtBranch'
                            key='txtBranch'
                            //required={true}
                            // error={errors.branch}
                            inputValue={recipe.branch ? recipe.branch : ''}
                            onInputChange={(event, newInputValue) => {
                                GetBranchData(newInputValue)
                                HandleChange('branch', newInputValue)

                            }}
                        />
                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtDepartment'].focus() };
                            }}
                            label='Employee'
                            placeholder="Employee"
                            maxLength='50'
                            options={EmployeeData}
                            id='txtEmployee'
                            key='txtEmployee'
                            // required={true}
                            // error={errors.employee}
                            disabled={recipe.all}
                            inputValue={recipe.employee ? recipe.employee : ''}
                            onInputChange={(event, newInputValue) => {
                                GetEmployeeData(newInputValue)
                                HandleChange('employee', newInputValue)

                            }}
                        />

                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtDesignation'].focus() };
                            }}
                            label='Department'
                            placeholder="Department"
                            maxLength='50'
                            options={DepartmentData}
                            id='txtDepartment'
                            key='txtDepartment'
                            // required={true}
                            // error={errors.department}
                            inputValue={recipe.department ? recipe.department : ''}
                            onInputChange={(event, newInputValue) => {
                                GetDepartmentData(newInputValue)
                                HandleChange('department', newInputValue)

                            }}
                        />
                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtMonth'].focus() };
                            }}
                            label='Designation'
                            placeholder="Designation"
                            maxLength='50'
                            options={DesignationData}
                            id='txtDesignation'
                            key='txtDesignation'
                            // required={true}
                            // error={errors.designation}
                            inputValue={recipe.designation ? recipe.designation : ''}
                            onInputChange={(event, newInputValue) => {
                                GetDesignationData(newInputValue)
                                HandleChange('designation', newInputValue)

                            }}
                        />

                        <MonthBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtYear'].focus() };
                            }}
                            label='Month'
                            // labelClass='text-nowrap'
                            //customclass='w-25'
                            // divclassname='col-lg-6'
                            id='txtMonth'
                            selected={recipe.month ? recipe.month : ""}
                            placeholder="Month"
                            onChange={(e) => { HandleChange('month', e) }}
                            required
                            error={errors.month}
                        />
                        <YearBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtSalaryType'].focus() };
                            }}
                            label='Year'
                            id='txtYear'
                            selected={recipe.year ? recipe.year : ""}
                            placeholder="Year"
                            onChange={(e) => { HandleChange('year', e) }}
                            required
                            maxDate={new Date() }
                            error={errors.year}
                        />


                        <SelectInputBox
                            label='Salary Type'
                            inputrefs={inputrefs}
                            required
                            error={errors.salaryType}
                            id="txtSalaryType"
                            value={recipe.salaryType}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { handleSubmit() };
                            }}
                            onChange={(e) => HandleChange('salaryType', e.target.value)}
                            children={<>
                                <option value="Completed">Completed</option>
                                <option value="Fixed">Fixed</option>
                                <option value="Variable">Variable</option>

                            </>}
                        />


                        <FormButton
                            saveButtonIcon='fa-search'
                            SaveText='Search'
                            Next={true}
                            nextButtonColor='btn-primary'
                            nextButtonIcon='fa-file-pdf'
                            nextButtonText='Salary Wages Sheet'
                            handleNext={handleNext }
                            handleSubmit={handleSubmit}
                            exportText="Salary Sheet In Excel"
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/Transaction/SalarySheetInExcel', ExportRequest, 'Salary Sheet In Excel')}
                            handleClear={handleClear}
                        />

                    </div>
                </div>
            </div>

            {/* Table Component start */}
            {showTable &&

                <Table
                Action={false }
                    Filter={false}
                    Token={TokenData}
                    PageName='SalarySheetInExcel'
                    tableData={result}
                    loading={loading}
                    currentPageNo={currentPageNo}
                    handleChange1={handleChange1}
                />
            }
            {/* Table Component End*/}

            {loading && < BlurLoader />}
        </>
    )
}

export default SalarySheetInExcel
