import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox } from "../commoncomponents/InputBox";

import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';

//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';

//services
import { post } from '../../apiservices/service';
import { Token, number, numberWithComma } from '../Common/Common';

function BankMaster() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();

    //token Data
    //const TokenData = {
    //    AuthToken: process.env.REACT_APP_SECRET_KEY,
    //    LoginToken: user.loginsessionID,
    //    UserID: user.userID,
    //    CompanyID: user.companyID
    //}
    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({
        BankID: "",
        BankName: '',
        BankCode: '',
        IFSCPrefix: '',
        ACNoLength: '',
        Active: true
    })
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [isUpdate, setIsUpdate] = useState(false)
    const [errors, setErrors] = useState({});

    const handleChange1 = (event, value) => {
        getFormData(value);
    };

    useEffect(() => {
        inputrefs.current['txtBankCode'].focus();
        document.title = 'Pay Plus : Bank Master'
    }, [sortConfig])

    useEffect(() => {
        getFormData()
    }, [isUpdate])


    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //--------------------------validation handler----------------------------------//
    //const Validate = () => {
    //    if (recipe.BankCode=== "") {
    //        return ["Please Enter Bank Code.!", "txtBankCode"]
    //    }
    //    else if (recipe.BankName === "") {
    //        return ["Please Enter Bank Name.! ", "txtBankName"]
    //    }
    //    else if (recipe.IFSCPrefix === "") {
    //        return ["Please Enter IFSC Prefix.! ", "txtIFSCPrefix"]
    //    }
    //    else if (recipe.ACNoLength === "") {
    //        return ["Please Select AC No Length.!", "txtACNoLength"]
    //    }
    //    else {
    //        return ""
    //    }
    //}

    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "BankCode", msg: 'Please Enter Bank Code.' },
            { field: "BankName", msg: 'Please Enter Bank Name.' },
            { field: "IFSCPrefix", msg: 'Please Enter IFSC Prefix.' },
            { field: "ACNoLength", msg: 'Please Enter AC No Length.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {

                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }



    //Submit Handler
    const handleSubmit = () => {
        const myString = Validate();
        //  if (myString === "") {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/MASTER/InsertUpdateBankMaster`, { ...TokenData, ...recipe }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        inputrefs.current[res.focus].focus();
                        setLoading(false)
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "UNAUTHORIZED") {
                        toast.error(res.Message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        handleClear()
                    }
                }
            });
        } else {
            //  toast.error(myString[0])
            //   (inputrefs.current[myString[1]].focus())
        }
    }

    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/MASTER/ShowBankMaster`, { ...TokenData, ...temp, ...sortConfig, ...filterData }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }

    //Handle Edit
    const handleEdit = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/MASTER/EditBankMaster`, { ...TokenData, BankID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    let data = res.data
                    setRecipe({
                        ...data,
                        BankName: data.bankName,
                        BankCode: data.bankCode,
                        IFSCPrefix: data.ifscPrefix,
                        Active: data.active,
                        ACNoLength: data.acNoLength
                    })
                }
            }
        });
    }

    //Handle Delete
    const handleDelete = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/MASTER/DeleteBankMaster`, { ...TokenData, BankID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    toast.success(res.message)
                    setLoading(false)
                    getFormData()
                    handleClear()
                }
            }
        });
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            BankID: "",
            BankName: '',
            BankCode: '',
            IFSCPrefix: '',
            ACNoLength: '',
            Active: true
        }))
        setErrors({})
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        getFormData()
        inputrefs.current['txtBankCode'].focus();
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
        getFormData()
    };

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Bank Master</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>


                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtBankName'].focus() };
                            }}
                            label='Bank Code'
                            id='txtBankCode'
                            maxLength='5'
                            placeholder="Bank Code"
                            value={recipe.BankCode}
                            error={errors.BankCode}
                            onChange={(e) => HandleChange('BankCode', e.target.value)}
                            required
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtIFSCPrefix'].focus() };
                            }}
                            label='Bank Name'
                            id='txtBankName'
                            maxLength='100'
                            placeholder="Bank Name"
                            value={recipe.BankName}
                            error={errors.BankName}
                            onChange={(e) => HandleChange('BankName', e.target.value)}
                            required
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtACNoLength'].focus() };
                            }}
                            label='IFSC Prefix'
                            id='txtIFSCPrefix'
                            maxLength='5'
                            placeholder="IFSC Prefix"
                            value={recipe.IFSCPrefix} error={errors.IFSCPrefix}
                            onChange={(e) => { HandleChange('IFSCPrefix', e.target.value) }}
                            required
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtIsActive'].focus() };
                            }}
                            label='AC No Length'
                            id='txtACNoLength'
                            maxLength='20'
                            placeholder="AC No Length"
                            value={recipe.ACNoLength} error={errors.ACNoLength}
                            onChange={(e) => { HandleChange('ACNoLength', numberWithComma(e.target.value)) }}
                            required
                        />

                        <Switch
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { handleSubmit() };
                            }}
                            label='Active'
                            checked={recipe.Active}
                            id='txtIsActive'
                            nextinputid='txtFranchiseGroup'
                            onChange={(e) => HandleChange('Active', e.target.checked)}
                        />

                        <FormButton
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/MASTER/ShowBankMaster', ExportRequest, 'Bank Master')}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>

            {/* Table Component start*/}
            <Table
                Token={TokenData}
                PageName='BankMaster'
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleFilter={() => {
                    getFormData();
                    setFilterData({
                        ...filterData,
                    });
                }}
                tableData={result}
                //  loading={loading}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
            {/* Table Component End*/}


            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}
        </>
    )
}

export default BankMaster