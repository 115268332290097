import * as XLSX from 'xlsx';
import axios from "axios";
import { post } from '../../apiservices/service';
import { API_SERVER } from '../../Utils';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import { Token } from '../Common/Common';
import { useAuth } from '../../context/auth';
import ExcelJS from 'exceljs';

//ExportError Handler
export const ExportError = (FileName, res) => {
    //console.log(res, 'aaaaaaaa')
    if (res) {
        const workbook = XLSX.utils.book_new();
        let worksheet = XLSX.utils.aoa_to_sheet([
            res?.HeadersValue
            ,
            ...res?.Data?.map((records) =>
                res.HeadersValue?.map((data, i) =>
                    records[data]
                )
            ),
        ]);
        XLSX.utils.book_append_sheet(workbook, worksheet, FileName);
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${FileName}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        return
    }
}

//DownloadFormat Handler
export const DownloadFormat = (Field, fileName) => {
    const arr = Field.split(',');
    const workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.aoa_to_sheet([
        arr,
        [""],
    ]);
    XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    return
}

export const ExportFormat = (FileName, res) => {
    const workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.aoa_to_sheet([
        res?.HeadersValue
        ,
        ...res?.Data?.map((records) =>
            res.HeadersValue?.map((data, i) =>
                records[data]
            )
        ),
    ]);
    XLSX.utils.book_append_sheet(workbook, worksheet, FileName);
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${FileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    return
}

export const readFile = async (e, setFileData) => {
    const file = e.target.files[0]
    const data = await file.arrayBuffer(file)
    const excelFile = XLSX.read(data)
    const excelSheet = excelFile.Sheets[excelFile.SheetNames[0]]
    const exceljson = XLSX.utils.sheet_to_json(excelSheet)
    setFileData(exceljson)
}

export const getHeaderValue = (pageName, setHeaderValue) => {

    const TokenData = Token()
    // const { sessionExpired } = useAuth();
    post(`${API_SERVER}/api/Import/GetImportfields`, { ...TokenData, PageName: pageName }, (res) => {
        if (res && res.status) {
            if (res.status === "ERROR") {
                toast.error(res.message)
                //   setLoading(false)
            }
            //else if (res.status === "EXPIRED") {
            //    toast.error(res.message)
            //    sessionExpired()
            //}
            else if (res.status === "UNAUTHORIZED") {
                toast.error(res.message);
                //     setLoading(false);
            } else {
                setHeaderValue(res.fieldName);
            }
        }
    });
}



//export const DownloadExcelFormat = async (FileName, pageName) => {
//    const TokenData = Token();
//    const [HeaderValue, setHeaderValue] = useState('');
//    post(`${API_SERVER}/api/Import/GetImportfields`, { ...TokenData, PageName: pageName }, (res) => {
//        if (res && res.status) {
//            if (res.status === "ERROR") {
//                toast.error(res.message)
//            }else if (res.status === "UNAUTHORIZED") {
//                toast.error(res.message);
//                //     setLoading(false);
//            } else {
//                if (HeaderValue) {
//                    const arrey = HeaderValue.split(',');
//                    const workbook = XLSX.utils.book_new();
//                    let worksheet = XLSX.utils.aoa_to_sheet([arrey]);
//                    XLSX.utils.book_append_sheet(workbook, worksheet, FileName);
//                    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
//                    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
//                    const url = window.URL.createObjectURL(data);
//                    const link = document.createElement('a');
//                    link.href = url;
//                    link.setAttribute('download', `${FileName}.xlsx`);
//                    document.body.appendChild(link);
//                    link.click();
//                    document.body.removeChild(link);
//                    window.URL.revokeObjectURL(url);
//                    return
//                } else {
//                    toast.error('No Header Value Found')
//                }
//            }
//        }
//    })
//    }




export const DownloadExcelFormat = async (FileName, HeaderValue) => {

    if (HeaderValue) {
        const arrey = HeaderValue.split(',');
        const workbook = XLSX.utils.book_new();
        let worksheet = XLSX.utils.aoa_to_sheet([arrey]);
        XLSX.utils.book_append_sheet(workbook, worksheet, FileName);

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${FileName}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        return
    } else {
        toast.error('No Header Value Found')
    }
}

export const GetFieldValue = (requestData,setState) => {

    post(`${API_SERVER}api/Import/GetImportfields`, requestData, (res) => {
        if (res && res.status) {
            if (res.status === "SUCCESS") {
                setState( res.fieldName)
            }
        }
    })
   

}


export const handleDownloadExcel = async ( requestData, FileName, enableAlternateColor = false, enableHeaderColor = true, enableFilters = false) => {
    const apiUrl = `${API_SERVER}api/Import/GetImportfields`;
    const requestBody = requestData;
    const requestHeaders = { 'Content-Type': 'application/json', };
 //   console.log('1')
    try {
     //   console.log('2')
        const response = await fetch(apiUrl, { method: 'POST', body: JSON.stringify(requestBody), headers: requestHeaders, });
        const res = await response.json();
        if (res.status === "SUCCESS") {

            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sheet 1',
                {
                    views: [{ state: 'frozen', ySplit: 1 }],
                });

            // Example: Set header row to bold and background color
            const headerRow = worksheet.addRow(res.fieldName.split(','))
            headerRow.font = { bold: true };

            // Set border on header row
            headerRow.eachCell((cell) => {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
            // Example: Enable HeaderColor   
            //if (enableHeaderColor) {
            //    headerRow.fill = {
            //        type: 'pattern', pattern: 'solid', fgColor: { argb: 'AAAAAA' },
            //    };
            //}

            // Example: Change color of a specific column (e.g., column B)
            //const columnIndexToColor = 1; // Change this to the index of the column you want to color (0-indexed)
            //console.log(worksheet.columns)
            //worksheet.columns[columnIndexToColor].eachCell((cell, rowNumber) => {
            //    // Check if it's not a header row
            //    //    if (rowNumber > 1) {
            //    console.log(cell, 'value of cell')
            //    cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF0000FF' } }; // Change color as desired
            //    //  }
            //});


            worksheet.eachRow((row, rowNumber) => {
                // Skip the header row
                if (rowNumber === 1) {
                    row.eachCell((cell) => {
                        // Example: Change color based on cell value
                        if (cell.value.includes('*')) {
                            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFCCCB' } };

                        } else if (cell.value) {
                            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFCCFEFF' } };

                        }

                        // Set border around the cell
                        //cell.border = {
                        //    top: { style: 'thin', color: { argb: 'FF000000' } },    
                        //   left: {  color: { argb: 'FF000000' } },   
                        //    bottom: { style: 'thin', color: { argb: 'FF000000' } }, 
                        //   right: {  color: { argb: 'FF000000' } }   
                        //};
                    });
                }
            });

            // Iterate through each row
            worksheet.eachRow((row, rowNumber) => {
                // Skip the header row
                if (rowNumber === 1) {
                    // Iterate through each cell
                    row.eachCell(cell => {
                        // Remove '*' from cell value if present
                        if (cell.value && cell.value.includes('*')) {
                            cell.value = cell.value.replace('*', '');
                        }
                    });
                }
            });



            const buffer = await workbook.xlsx.writeBuffer();
            const data = new Blob([buffer], { bookType: 'xlsx', type: 'array' });
            const url = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${FileName}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);


        } else if (res.status === 'ERROR') {
       //     console.log('4')
            toast.error(res.Message);
        } else if (res.status === 'UNAUTHORIZED') {
            toast.error(res.Message);
        //    console.log('5')
        }
    } catch (err) {
        toast.error(err.message)
      //  console.log('6')
    }
};












//export const handleDownloadExcel = async (handleApiUrl, requestData, FileName, enableAlternateColor = false, enableHeaderColor = true, enableFilters = false) => {
//    // const ExcelJS = require('exceljs');
//    const apiUrl = handleApiUrl;
//    const requestBody = requestData;
//    const requestHeaders = { 'Content-Type': 'application/json', };
//    console.log('1')
//    try {
//        console.log('2')
//        const response = await fetch(apiUrl, { method: 'POST', body: JSON.stringify(requestBody), headers: requestHeaders, });
//        const res = await response.json();
//        if (res.status === "SUCCESS") {
//            console.log('3')
//            const workbook = new ExcelJS.Workbook();
//            const worksheet = workbook.addWorksheet('Sheet 1',
//                {
//                    views: [{ state: 'frozen', ySplit: 1 }],
//                });

//            // Example: Set header row to bold and background color
//            const headerRow = worksheet.addRow(res?.HeadersKey?.length > 0 ? res.HeadersKey.filter((key) => {
//                return res?.HideColumns?.length > 0 ? !res?.HideColumns?.includes(key) : key
//            }) :
//                Array.from(new Set(res?.Data?.flatMap((data) => Object.keys(data)).filter((columnName) => !res?.HideColumns?.includes(columnName))))
//            )
//            const headerKey = (res?.HeadersKey?.length > 0 && res?.HeadersValue?.length > 0) ? res.HeadersValue.filter((key) => {
//                return res?.HideColumns?.length > 0 ? !res?.HideColumns?.includes(key) : key
//            }) :
//                Array.from(new Set(res.Data?.flatMap((data) => Object.keys(data)).filter((columnName) => !res?.HideColumns?.includes(columnName))));
//            headerRow.font = { bold: true };
//            // Example: Enable HeaderColor
//            if (enableHeaderColor) {
//                headerRow.fill = {
//                    type: 'pattern', pattern: 'solid', fgColor: { argb: 'AAAAAA' },
//                };
//            }
//            // Example: Enable filters
//            if (enableFilters) {
//                worksheet.autoFilter = {
//                    from: { row: 1, column: 1 }, to: { row: 1, column: headerKey.length },
//                };
//            }
//            // Example: Set alternate row background color
//            for (let i = 2; i <= res.Data.length + 1; i++) {
//                const row = worksheet.getRow(i);
//                if (enableAlternateColor && i % 2 === 0) {
//                    row.fill = {
//                        type: 'pattern', pattern: 'solid', fgColor: { argb: 'DDDDDD' },
//                    };
//                } row.values = res.HeadersValue?.length > 0 ? res.HeadersValue?.map((key) => res.Data[i - 2][key]) : headerKey?.map((key) => res.Data[i - 2][key]);
//            }
//            // Set column widths to fit content
//            worksheet.columns.forEach((column) => {
//                let maxLength = 0;
//                column.eachCell({ includeEmpty: true }, (cell) => {
//                    const length = cell.value ? String(cell.value).length : 0;
//                    maxLength = Math.max(maxLength, length);
//                });
//                column.width = maxLength < 12 ? 12 : maxLength;  // Set a minimum width to avoid extremely narrow columns
//            });
//            //Auto-fit column width
//            worksheet.columns.forEach((column, index) => {
//                let maxContentLength = column.header ? column.header.length : 0;
//                for (let i = 2; i <= res.Data.length + 1; i++) {
//                    const content = res.Data[i - 2][res.HeadersValue[index]];
//                    if (content) {
//                        maxContentLength = Math.max(maxContentLength, content.toString().length);
//                    }
//                }
//                column.width = maxContentLength < 12 ? 12 : maxContentLength + 2;
//            });
//            const buffer = await workbook.xlsx.writeBuffer();
//            const data = new Blob([buffer], { type: 'application/octet-stream' });
//            const url = window.URL.createObjectURL(data);
//            const link = document.createElement('a');
//            link.href = url;
//            link.setAttribute('download', `${FileName}.xlsx`);
//            document.body.appendChild(link);
//            link.click();
//            document.body.removeChild(link);
//            window.URL.revokeObjectURL(url);
//        } else if (res.status === 'ERROR') {
//            console.log('4')
//            toast.error(res.Message);
//        } else if (res.status === 'UNAUTHORIZED') {
//            toast.error(res.Message);
//            console.log('5')
//        }
//    } catch (err) {
//        toast.error(err.message)
//        console.log('6')
//    }
//};
