import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
/*import LeftPannel from './LeftPannel';*/
import SideNav from './SideNav';
import Modal from 'react-bootstrap/Modal';
import { CustomButton } from '../commoncomponents/InputBox';
import { useNavigate } from 'react-router-dom';
import { API_SERVER, NotificationCount } from '../../Utils';
import { post } from '../../apiservices/service';
import { GetNoificaitonCount, Token } from '../Common/Common';
import { useAuth } from '../../context/auth';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';



const Layout = () => {
    const [open, setopen] = useState(false)
    const [isopenheader, setIsopenheader] = useState(true)

    //---------------------------- for Modal start-------------------------------//
    const TokenData = Token()
    const { user, sessionExpired } = useAuth();
    const [showModal, setShowModal] = useState(false)
    const navigate = useNavigate()

    const [notificationData, setNotificationData] = useRecoilState(NotificationCount)

    useEffect(() => {
        GetNoificaitonCount(notificationData, setNotificationData, TokenData, sessionExpired)
    }, [showModal])


    //---------------------------- for Modal end -------------------------------//
    const handleOpen = () => {
        setopen(true)
    }

    const handleClose = () => {
        setopen(false)
    }

    const Handletoggle = () => {
        setopen(!open)
    }

    const HandleheaderToogle = () => {
        setIsopenheader(!isopenheader)
    }



    return (
        <div>



            <Header Handletoggle={Handletoggle} HandleheaderToogle={HandleheaderToogle} open={open} isopenheader={isopenheader} setShowModal={setShowModal} />
            {/* <LeftPannel handleOpen={handleOpen} handleClose={handleClose} open={open} isopenheader={isopenheader} />*/}
            <SideNav handleOpen={handleOpen} handleClose={handleClose} open={open} isopenheader={isopenheader} />
            <div className={`main-dash ${open ? '' : 'marg-left'}`} style={{ marginTop: '80px' }}>
                <div className='container-fluid'>
                    <Outlet />
                </div>
            </div>





            {/* Modal Delay 5 Minutes */ }
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Panding Candidate Verification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row p-2' >
                        <CustomButton
                            id='btnVerifyCandidate'
                            ButtonIcon='fa-check'
                            ButtonText={`Pending Candidate For Varification : ${notificationData.pendingVerificationData}`}
                            ButtonClass='btn-primary btn-sm me-2 mt-1'
                            onClick={() => {
                                navigate('/Candidate/VerifyCandidate')
                                setShowModal(false)
                            }}
                        />
                    </div>
                    <div className='row p-2 pt-0' >
                        <CustomButton
                            id='btnVerifyCandidate'
                            ButtonIcon='fa-clock'
                            ButtonText={`Pending Convert to Employee : ${notificationData.pendingConvertToEmployee}`}
                            ButtonClass='btn-primary btn-sm me-2 mt-1'
                            onClick={() => {
                                navigate('/Candidate/ConvertToEmployee')
                                setShowModal(false)
                            }}
                        />
                    </div>
                </Modal.Body>
            </Modal>

        </div>



    );
};

export default Layout;

