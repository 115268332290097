import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, InputFile, handleFileUploderInput } from "../commoncomponents/InputBox";
//import { FileUploader } from "react-drag-drop-files";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
//loder
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { number } from '../Common/Common';
import { FileUploader } from "react-drag-drop-files";


function DocumentUpload({ TokenData, activeTab, CandidateCode, empCode }) {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();

    //initial state
    const [recipe, setRecipe] = useState({
        CandidateCode: CandidateCode,
        CandidateID: "0",
        DocumentType: "",
        DocumentSubType: "",
        DocumentFileFrontSide: "",
        DocumentExtFrontSide: "",
        DocumentFileNameFrontSide: "",
        DocumentFileBackSide: "",
        DocumentExtBackSide: "",
        DocumentFileNameBackSide: "",
        CandidateDocID: '0'


    })
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [isUpdate, setIsUpdate] = useState(false)
    const [documentTypeAllData, setDocumentTypeAllData] = useState([])
    const [documentSubTypeAllData, setDocumentSubTypeAllData] = useState([])
    const [errors, setErrors] = useState({});
    const [fileData, setFileData] = useState();

    const fileType = "JPG,PNG,PDF";
    // console.log(fileData, 'fileData')

    const DocumentTypeAllData = () => {
        let temp = {
            Type: "DocumentType",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setDocumentTypeAllData(data)
                }
            }
        });
    }

    const DocumentSubTypeAllData = (value) => {
        let temp = {
            Type: "DocumentSubType",
            Prefix: '',
            ContextKey: value || '',
            ContextKey2: ""
        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setDocumentSubTypeAllData(data)
                }
            }
        });
    };


    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setLoading(true)
        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s",
            Code: CandidateCode,
            //CandidateCode: empCode
        }
        post(`${API_SERVER}api/Candidate/ShowCandidateDocumentUpload`, { ...TokenData, ...temp, ...sortConfig, ...filterData, }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }

    useEffect(() => {
     //   DocumentTypeAllData();
        getFormData();
        //inputrefs.current['txtCandidateName'].focus();
        document.title = 'Pay Plus : Document Upload'
    }, [sortConfig, isUpdate])

    //Change Handler
    const HandleChange = (prop, value) => {
        if (prop === 'DocumentType') {
            DocumentSubTypeAllData(value)
        }
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }
    const handleChange1 = (event, value) => {
        getFormData(value);
    };
    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "DocumentType", msg: 'Please Select Document Type.' },
            { field: "DocumentSubType", msg: 'Please Select Document Sub Type.' },
            { field: "DocumentFileFrontSide", msg: ' Please Select Document File Front Side.' },
        ]

        if (recipe['DocumentSubType'] === '6') {
            validation.push(
                { field: "DocumentFileBackSide", msg: 'Please Select Document File Back Side.' },
            )
        };

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {

                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }

    //Submit Handler
    const handleSubmit = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/Candidate/UpdateCandidateDocumentUpload`, { ...TokenData, ...recipe }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        setLoading(false)
                    } else if (res.status === "EXPIRED") {
                        toast.error(res.message)
                        sessionExpired()
                    } else if (res.status === "UNAUTHORIZED") {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        handleClear()
                    }
                }
            });
        }
        else {
            // inputrefs.current[myString[1]].focus();
            // toast.error(myString[0]);
        }
    }

    //Handle Edit
    const handleEdit = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/Candidate/EditCandidateDocumentUpload`, { ...TokenData, CandidateDocID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.status === "EXPIRED") {
                    toast.error(res.message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    let data = res.data
                    DocumentSubTypeAllData(data.documentType)
                    setRecipe({
                        ...data,
                        CandidateCode: CandidateCode,
                        DocumentType: data.documentType,
                        DocumentSubType: data.documentSubType,
                        DocumentFileFrontSide: data.documentFileFrontSide,
                        DocumentExtFrontSide: data.documentExtFrontSide,
                        DocumentFileNameFrontSide: data.documentFileNameFrontSide,
                        DocumentFileBackSide: data.documentFileBackSide,
                        DocumentExtBackSide: data.documentExtBackSide,
                        DocumentFileNameBackSide: data.documentFileNameBackSide,
                        CandidateDocID: data.candidateDocID
                    })
                    handleFileUploderInput('.DocumentBackSide', data, 'documentFileNameBackSide', 'documentExtBackSide', fileType)
                    handleFileUploderInput('.DocumentFrontSide', data, 'documentFileNameFrontSide', 'documentExtFrontSide', fileType)
                }
            }
        });
    }

    //Handle Delete
    const handleDelete = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/Candidate/DeleteCandidateDocumentUpload`, { ...TokenData, CandidateDocID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.status === "EXPIRED") {
                    toast.error(res.message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    toast.success(res.message)
                    setLoading(false)
                    getFormData()
                }
            }
        });
    }
    const HandleShowDoc = (ID, key) => {
        setLoading(true)
        post(`${API_SERVER}api/Candidate/ShowCandidateDocument`, { ...TokenData, CandidateDocID: ID, Type: key }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    toast.error(res.message)
                } else if (res.status === "EXPIRED") {
                    toast.error(res.message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    let base64 = res && res.document
                    res.documentExtention === '' ? toast.error('No Document Uploaded') : res.documentExtention === 'pdf' ? window.open(`/PDFView/#data:application/pdf;base64,${base64}`, '_blank') :
                        window.open(`/ImageView/#data:application/jpg;base64,${base64}`, '_blank')
                }
            }
        });
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            CandidateID: "0",
            DocumentType: "",
            DocumentSubType: "",
            DocumentFileFrontSide: "",
            DocumentExtFrontSide: "",
            DocumentFileNameFrontSide: "",
            DocumentFileBackSide: "",
            DocumentExtBackSide: "",
            DocumentFileNameBackSide: "",
            CandidateDocID: '0'
        }))
        setErrors({})
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        getFormData()
        handleFileUploderInput('.DocumentBackSide', '', '', '', fileType)
        handleFileUploderInput('.DocumentFrontSide', '', '', '', fileType)
        //  console.log(recipe, recipe.DocumentFileNameBackSide, recipe.DocumentExtBackSide,'11111111')
    }

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Code: CandidateCode,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality

    useEffect(() => {
        if (activeTab === 'DocumentUpload') {
            inputrefs.current['txtDocumentType'].focus();
            document.title = 'Pay Plus : Document Upload'
        }
    }, [activeTab])



    return (
        <>
            <div className='main-card card border-0 d-none'>

                <div className='card-body'>
                    <div className='row p-0 ps-3 pt-2'>

                        <div className='row'>
                            <div className='row'>
                                <fieldset className="myfieldset">
                                    <legend className="mylegend"> <h6 className="m-0">Document Upload</h6></legend>
                                    <div className='row'>

                                        <div className={`col-md-3 mb-1 cntrl-width`} key="txtDocumentType">
                                            <label className="form-label">Document Type<span style={{ color: "red" }}> *</span>
                                            </label>
                                            <select ref={ref => (inputrefs.current['txtDocumentType'] = ref)}
                                                className="form-control"
                                                id="txtDocumentType"
                                                value={recipe.DocumentType}
                                                error={recipe.DocumentType}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtDocumentSubType'].focus() };
                                                }}
                                                onChange={(e) => HandleChange('DocumentType', e.target.value)}>
                                                <option value="">--Select Document Type--</option>
                                                {documentTypeAllData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}

                                            </select>
                                            <div className="error-msg">{errors.DocumentType}</div>
                                        </div>

                                        <div className={`col-md-3 mb-1 cntrl-width`} key="txtDocumentSubType">
                                            <label className="form-label">Document Sub Type<span style={{ color: "red" }}> *</span>
                                            </label>
                                            <select ref={ref => (inputrefs.current['txtDocumentSubType'] = ref)}
                                                className="form-control"
                                                id="txtDocumentSubType"
                                                maxLength='50'
                                                value={recipe.DocumentSubType}
                                                error={recipe.DocumentSubType}
                                                onKeyDown={(e) => {
                                                    //   if (e.key === 'Enter') { inputrefs.current['fileDocumentfront'].focus() };
                                                }}
                                                onChange={(e) => {
                                                    HandleChange('DocumentSubType', e.target.value)
                                                    if (e.target.value) {
                                                        setRecipe({
                                                            ...recipe, DocumentFileFrontSide: "",
                                                            DocumentExtFrontSide: "",
                                                            DocumentFileNameFrontSide: "",
                                                            DocumentFileBackSide: "",
                                                            DocumentExtBackSide: "",
                                                            DocumentFileNameBackSide: "",
                                                            DocumentSubType: e.target.value,
                                                        })
                                                        handleFileUploderInput('.DocumentBackSide', '', '', '', fileType)
                                                        handleFileUploderInput('.DocumentFrontSide', '', '', '', fileType)
                                                    }
                                                }}>
                                                <option value="">--Select DocumentSub Type--</option>
                                                {documentSubTypeAllData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}

                                            </select>
                                            <div className="error-msg">{errors.DocumentSubType}</div>
                                        </div>

                                        {/*<AutoCompleteBox*/}
                                        {/*    inputrefs={inputrefs}*/}
                                        {/*    onKeyDown={(e) => {*/}
                                        {/*        if (e.key === 'Enter') { inputrefs.current['fileDocument'].focus() };*/}
                                        {/*    }}*/}
                                        {/*    label='Document Sub Type' placeholder="Document Sub Type" maxLength='50' options={StateData} id='txtDocumentSubType'*/}
                                        {/*    required={true} inputValue={recipe.DocumentSubType ? recipe.DocumentSubType : ''}*/}
                                        {/*    onInputChange={(event, newInputValue) => {*/}
                                        {/*        HandleChange('State', newInputValue)*/}
                                        {/*        setStatePre(newInputValue)*/}
                                        {/*    }}*/}
                                        {/*/>*/}

                                        {/*<div className={`col-md-3 mb-1 cntrl-width`} key="fileDocumentfront">*/}
                                        {/*    <label className="form-label">Document Front Side <span style={{ color: "red" }}> *</span></label>*/}
                                        {/*    <input*/}
                                        {/*        className='form-control form-control-sm'*/}
                                        {/*        type="file"*/}
                                        {/*        id="fileDocumentfront"*/}
                                        {/*        name="DocumentFrontSide"*/}
                                        {/*        maxLength="50"*/}
                                        {/*        tabIndex="1"*/}
                                        {/*        required*/}
                                        {/*        onChange={(file) => {*/}
                                        {/*            if (file.target.files && file.target.files[0]) {*/}
                                        {/*                //let ele = document.getElementById('fileDocumentfront')*/}
                                        {/*                //console.log(ele.node)*/}
                                        {/*                let img = file.target.files[0];*/}
                                        {/*                let extArr = (img.name).split(".");*/}
                                        {/*                if (extArr[1] !== "png" && extArr[1] !== "jpg" && extArr[1] !== "jpeg") {*/}
                                        {/*                    toast.error("file should be png ,jpg or jpeg type.");*/}
                                        {/*                    return*/}
                                        {/*                }*/}
                                        {/*                const reader = new FileReader();*/}
                                        {/*                reader.readAsDataURL(img);*/}
                                        {/*                reader.onload = () => {*/}
                                        {/*                    let document = (reader.result).split(',')[1]*/}
                                        {/*                    setRecipe({*/}
                                        {/*                        ...recipe,*/}
                                        {/*                        DocumentFileFrontSide: document,*/}
                                        {/*                        DocumentExtFrontSide: extArr[1],*/}
                                        {/*                        DocumentFileNameFrontSide: extArr[0]*/}
                                        {/*                    });*/}
                                        {/*                };*/}
                                        {/*            }*/}
                                        {/*        }}*/}
                                        {/*    />*/}
                                        {/*    <div className="error-msg">{errors.DocumentFileFrontSide}</div>*/}
                                        {/*    */}{/*<span>{recipe.DocumentFileNameFrontSide}</span>*/}
                                        {/*    <div className="form-group" id="dv1" style={{ display: 'none' }}>*/}
                                        {/*        <div className="col-md-8 col-sm-8 col-xs-12 mt-1" style={{ height: '100px', width: '100px' }}>*/}
                                        {/*            <img id="txtHeader" height="100" width="100" src="" alt="Document File Front Side" />*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}


                                        <div className={`col-md-3 mb-1 cntrl-width`} key="fileDocumentfront">
                                            <label className="form-label">Document Front Side <span style={{ color: "red" }}> *</span></label>
                                            <FileUploader
                                                ref={ref => (inputrefs.current['fileDocumentfront'] = ref)}
                                                id="fileDocumentfront"
                                                name="DocumentFrontSide"
                                                label='Upload a right file here'
                                                classes='DocumentFrontSide'
                                                types={fileType.split(",")}
                                                handleChange={(file) => {
                                                    let extArr = (file.name).split(".");
                                                    if (file) {
                                                        const reader = new FileReader();
                                                        reader.readAsDataURL(file);
                                                        reader.onload = () => {
                                                            let logo = (reader.result).split(',')[1]
                                                            setRecipe({
                                                                ...recipe,
                                                                DocumentFileFrontSide: logo,
                                                                DocumentExtFrontSide: extArr[1],
                                                                DocumentFileNameFrontSide: extArr[0],



                                                            });


                                                        };
                                                        // console.log(document.querySelectorAll('.file-uploader')[0])
                                                        const fileNameAttribute = document.querySelectorAll('.DocumentFrontSide')
                                                        if (fileNameAttribute.length > 0) {
                                                            fileNameAttribute[0].childNodes[2].innerHTML = `${file.name}`;
                                                            //console.log(fileNameAttribute[0].childNodes[2].innerHTML)
                                                        }
                                                    }

                                                }}

                                            />
                                            {/*{console.log(document?.querySelectorAll('.file-uploader')[0]?.childNodes[2]?.innerHTML)}*/}
                                            <div className="error-msg">{errors.DocumentFileFrontSide}</div>
                                        </div>


                                        {/*<div className={`col-md-3 mb-1 cntrl-width`} key="fileDocumentBack">*/}
                                        {/*    <label className="form-label">Document Back Side{recipe.DocumentSubType === '6' ? <span style={{ color: "red" }}> *</span> : <></>}</label>*/}
                                        {/*    <input*/}
                                        {/*        className='form-control form-control-sm'*/}
                                        {/*        type="file"*/}
                                        {/*        id="fileDocumentBack"*/}
                                        {/*        name="DocumentBackSide"*/}
                                        {/*        maxLength="50"*/}
                                        {/*        tabIndex="1"*/}
                                        {/*        required={recipe.DocumentSubType === '6' ? true : false}*/}
                                        {/*        onChange={(file) => {*/}
                                        {/*            if (file.target.files && file.target.files[0]) {*/}
                                        {/*                let img = file.target.files[0];*/}
                                        {/*                let extArr = (img.name).split(".");*/}
                                        {/*                if (extArr[1] !== "png" && extArr[1] !== "jpg" && extArr[1] !== "jpeg") {*/}
                                        {/*                    toast.error("file should be png ,jpg or jpeg type.");*/}
                                        {/*                    return*/}
                                        {/*                }*/}
                                        {/*                const reader = new FileReader();*/}
                                        {/*                reader.readAsDataURL(img);*/}
                                        {/*                reader.onload = () => {*/}
                                        {/*                    let document = (reader.result).split(',')[1]*/}
                                        {/*                    setRecipe({*/}
                                        {/*                        ...recipe,*/}
                                        {/*                        DocumentFileBackSide: document,*/}
                                        {/*                        DocumentExtBackSide: extArr[1],*/}
                                        {/*                        DocumentFileNameBackSide: extArr[0]*/}
                                        {/*                    });*/}
                                        {/*                };*/}
                                        {/*            }*/}
                                        {/*        }}*/}
                                        {/*    />*/}
                                        {/*    <div className="error-msg">{errors.DocumentFileBackSide}</div>*/}
                                        {/*    */}{/*<span>{recipe.DocumentFileNameBackSide}</span>*/}
                                        {/*    <div className="form-group" id="dv1" style={{ display: 'none' }}>*/}
                                        {/*        <div className="col-md-8 col-sm-8 col-xs-12 mt-1" style={{ height: '100px', width: '100px' }}>*/}
                                        {/*            <img id="txtHeader" height="100" width="100" src="" alt="Document File Back Side" />*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className='col-md-3 mb-1 cntrl-width' key="fileDocumentBack">
                                            <label className="form-label">Document Back Side{recipe.DocumentSubType === '6' ? <span style={{ color: "red" }}> *</span> : <></>}</label>
                                            <FileUploader
                                                ref={ref => (inputrefs.current['fileDocumentBack'] = ref)}
                                                id='fileDocumentBack'
                                                name='DocumentBackSide'
                                                label='Upload a right file here'
                                                classes='DocumentBackSide'
                                                types={fileType.split(",")}
                                                handleChange={(file) => {
                                                    let extArr = (file.name).split(".");
                                                    if (file) {
                                                        const reader = new FileReader();
                                                        reader.readAsDataURL(file);
                                                        reader.onload = () => {
                                                            let logo = (reader.result).split(',')[1]
                                                            setRecipe({
                                                                ...recipe,
                                                                DocumentFileBackSide: logo,
                                                                DocumentExtBackSide: extArr[1],
                                                                DocumentFileNameBackSide: extArr[0]
                                                            });
                                                        };
                                                        // console.log(document.querySelectorAll('.DocumentBackSide')[0])
                                                        const fileNameAttribute = document.querySelectorAll('.DocumentBackSide')
                                                        if (fileNameAttribute.length > 0) {
                                                            fileNameAttribute[0].childNodes[2].innerHTML = `${file.name}`;
                                                            //console.log(fileNameAttribute[0].childNodes[2].innerHTML)
                                                        }
                                                    }
                                                }}

                                            />
                                            {/*{console.log(document?.querySelectorAll('.file-uploader')[0]?.childNodes[2]?.innerHTML)}*/}
                                            <div className="error-msg">{errors.DocumentFileBackSide}</div>
                                        </div>
                                    </div>
                                </fieldset>

                            </div>
                        </div>

                        <FormButton
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/Candidate/ShowCandidateDocumentUpload', ExportRequest, 'CandidateDocumentUpload')}
                            handleClear={handleClear}
                        />
                    </div>

                </div>

            </div >

            {/* Table Component start*/}
            <Table
                Action={false }
                Token={TokenData}
                PageName='CandidateDocument'
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                HandleShowDoc={HandleShowDoc}
                handleFilter={() => {
                    getFormData();
                    setFilterData({
                        ...filterData,
                    });
                }}
                tableData={result}
                //  loading={loading}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
        </>
    )
}

export default DocumentUpload
