import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, SelectInputBox, AutoCompleteBox, MonthBox, YearBox } from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';
//loder
import BlurLoader from "../Loder/BlurLoader";
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { Token, currentMonth, currentYear, } from '../Common/Common';

function DeleteAttendanceAndSalary() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();

    //token Data
    const TokenData = Token()

    //initial state
    const [recipe, setRecipe] = useState({
        employee: '',
        Type: '',
        month: new Date(),
        year: new Date(),
    })

    const [loading, setLoading] = useState(false);
    const [employeeAllData, setEmployeeAllData] = useState([])
    const [errors, setErrors] = useState({});
    //Get Employee Data for AutoComplete
    const GetEmployeeData = (value) => {
        setEmployeeAllData([])
        let temp = {
            Type: "GetEmployee",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setEmployeeAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setEmployeeAllData(data)
                }
            }
        });
    }

    //Submit Handler
    const handleSubmit = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/Employee/DeleteAttendanceAndSalary`, { ...TokenData, ...recipe, year: recipe.year ? currentYear(recipe.year) : '', month: recipe.month ? currentMonth(recipe.month, 'numeric') : '', }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        setLoading(false)
                        toast.error(res.message)
                    } else if (res.status === "EXPIRED") {
                        toast.error(res.message)
                        sessionExpired()
                    } else if (res.status === "UNAUTHORIZED") {
                        toast.error(res.message);
                        setLoading(false);
                    } else if (res.status === "SUCCESS") {
                        toast.error(res.message);
                        setLoading(false);
                    }
                }
            });
        }
    }

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    // Handle Validate
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "Type", msg: 'Please Select Type.' },
            { field: "month", msg: 'Please Select Month.' },
            { field: "year", msg: 'Please Select Year.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {

                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            employee: '',
            Type: '',
            month: '',
            year: '',
        }))
        setErrors({})
    }

    let EmployeeData = []
    for (let i = 0; i < employeeAllData.length; i++) {
        let mydata = employeeAllData[i]?.values
        EmployeeData.push(mydata)
    };

    useEffect(() => {
        inputrefs.current['txtEmployee'].focus();
        document.title = 'Pay Plus : Delete Attendance And Salary';
    }, [])


    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Delete Attendance And Salary</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <SelectInputBox
                            label='Type'
                            inputrefs={inputrefs}
                             required
                            id="txtType"
                            value={recipe.Type}
                            error={errors.Type}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtEmployee'].focus() };
                            }}
                            onChange={(e) => HandleChange('Type', e.target.value)}
                            children={<>
                                <option value="">-- Select Type--</option>
                                <option value="Attendance">Attendance</option>
                                <option value="Salary">Salary</option>
                                <option value="Both">Both</option>
                            </>}
                        />

                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtMonth'].focus() };
                            }}
                            label='Employee'
                            placeholder="Employee"
                            maxLength='50'
                            options={EmployeeData}
                            id='txtEmployee'
                            key='txtEmployee'
                            inputValue={recipe.employee ? recipe.employee : ''}
                            onInputChange={(event, newInputValue) => {
                                GetEmployeeData(newInputValue)
                                HandleChange('employee', newInputValue)

                            }}
                            onFocus={() => GetEmployeeData()}
                        />

                        <MonthBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtYear'].focus() };
                            }}
                            label='Month'
                            id='txtMonth'
                            selected={recipe.month ? recipe.month : ""}
                            placeholder="Month"
                            onChange={(e) => { HandleChange('month', e) }}
                            required
                            error={errors.month}
                        />
                        <YearBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { handleSubmit() };
                            }}
                            label='Year'
                            id='txtYear'
                            selected={recipe.year ? recipe.year : ""}
                            placeholder="Year"
                            onChange={(e) => { HandleChange('year', e) }}
                            required
                            error={errors.year}
                        />

                        <FormButton
                            saveButtonIcon='fa-check'
                            SaveText='Delete'
                            handleSubmit={handleSubmit}
                            Export={false}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>
            {loading && < BlurLoader />}
        </>
    )
}

export default DeleteAttendanceAndSalary
