import React, { useEffect, useState, useRef } from 'react'
import { InputBox, AutoCompleteBox, Switch, DateBox, PasswordInputBox, FormButton, TimeBox } from "../commoncomponents/InputBox";

import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';

//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';

//services
import { getCommonData } from '../../apiservices/CommonServices';
import { post } from '../../apiservices/service';
import { Token, number } from '../Common/Common';

function ShiftMaster() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();

    const TokenData = Token()
    //initial state
    const [shiftForm, setShiftForm] = useState({
        shiftID: '0',
        shiftCode: '',
        shiftName: '',
        shiftTimeIn: '',
        shiftTimeOut: '',
        use24Hour: false,
        breakFrom: '',
        breakTo: '',
        firstHalfStarts: '',
        secondHalfStarts: '',
        maxLate: '',
        minimumWorkingHrs: '',
        maxLateAsHalfDayActive: false,
        active: true
    })
    const [errors, setErrors] = useState({});
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [groupAllData, setGroupAllData] = useState([])
    const [employeeCodeData, setEmployeeCodeData] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)





    const getShiftFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/RoasterManagement/ShowShiftMaster`, { ...TokenData, ...temp, ...sortConfig, ...filterData }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }

    const handleChange1 = (event, value) => {
        getShiftFormData(value);
    };

    useEffect(() => {
        inputrefs.current['txtShiftCode'].focus()
        document.title = 'Pay Plus: Shift Master'
        getShiftFormData()
    }, [sortConfig, isUpdate])


    //Change Handler
    const HandleChange = (prop, value) => {

        setShiftForm(shiftForm => ({ ...shiftForm, [prop]: value }))
    }

    //validation handler
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "shiftCode", msg: 'Please Enter Shift Code.' },
            { field: "shiftName", msg: 'Please Enter Shift Name.' },
            { field: "shiftTimeIn", msg: 'Please Enter Shift In Time.' },
            { field: "shiftTimeOut", msg: 'Please Enter Shift Out Time.' },
            { field: "breakFrom", msg: 'Please Enter Break From.' },
            { field: "breakTo", msg: 'Please Enter Break To.' },
            { field: "firstHalfStarts", msg: 'Please Enter First Half Starts.' },
            { field: "secondHalfStarts", msg: 'Please Enter Second Half Starts.' },
            { field: "minimumWorkingHrs", msg: 'Please Enter Minimum Working Hours.' },
            
        ]

        if (shiftForm.maxLateAsHalfDayActive === true) validation.push({ field: "maxLate", msg: 'Please Enter Max Late.' })

        validation.map(item => {
            if (!shiftForm[item.field] || shiftForm[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });


        return isValid;
    }

    //Submit Handler
    const handleSubmit = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/RoasterManagement/InsertUpdateShiftMaster`, { ...TokenData, ...shiftForm }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        inputrefs.current[res.focus].focus();
                        setLoading(false)
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "UNAUTHORIZED") {
                        toast.error(res.Message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        getShiftFormData()
                        handleClear()
                    }
                }
            });
        }
    }

    //Handle Edit
    const handleEdit = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/RoasterManagement/EditShiftMaster`, { ...TokenData, shiftID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    let data = res.data
                    setShiftForm({...data})
                }
            }
        });
    }

    //Handle Delete
    const handleDelete = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/RoasterManagement/DeleteShiftMaster`, { ...TokenData, shiftID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    toast.success(res.message)
                    setLoading(false)
                    getShiftFormData()
                    handleClear()
                }
            }
        });
    }

    //Reset Handler
    const handleClear = () => {
        setShiftForm(shiftForm => ({
            ...shiftForm,
            shiftID: '0',
            shiftCode: '',
            shiftName: '',
            shiftTimeIn: '',
            shiftTimeOut: '',
            use24Hour: false,
            breakFrom: '',
            breakTo: '',
            firstHalfStarts: '',
            secondHalfStarts: '',
            maxLate: '',
            minimumWorkingHrs: '',
            maxLateAsHalfDayActive: false,
            active: true
        }))
        setErrors({})
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };


    let UserGroupData = []
    for (let i = 0; i < groupAllData.length; i++) {
        let mydata = groupAllData[i]?.values
        UserGroupData.push(mydata)
    };

    let EmployeeCodeData = []
    for (let i = 0; i < employeeCodeData.length; i++) {
        let mydata = employeeCodeData[i]?.values
        EmployeeCodeData.push(mydata)
    };

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality
    console.log(shiftForm.shiftTimeIn)
    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Shift Master</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtShiftName'].focus() };
                            }}
                            label='Shift Code'
                            id='txtShiftCode'
                            maxLength='50'
                            placeholder="Shift Code"
                            required
                            error={errors.shiftCode}
                            value={shiftForm.shiftCode}
                            onChange={(e) => HandleChange('shiftCode', e.target.value)}
                        />



                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtShiftTimeIn'].focus() };
                            }}
                            label='Shift Name'
                            id='txtShiftName'
                            maxLength='50'
                            placeholder="Shift Name"
                            required
                            error={errors.shiftName}
                            value={shiftForm.shiftName}
                            onChange={(e) => HandleChange('shiftName', e.target.value)}
                        />


                        <TimeBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtShiftTimeOut'].focus() };
                            }}
                            labelClass='text-nowrap'
                            // divclassname='col-lg-2'
                            label='Shift Time In'
                            id='txtShiftTimeIn'
                            selected={shiftForm.shiftTimeIn}
                            placeholder="Shift Time In"
                            value={shiftForm.shiftTimeIn}
                            onChange={(e) => {
                                HandleChange('shiftTimeIn', e.target.value)

                            }}
                             required
                            error={errors.shiftTimeIn}
                        />
                        <TimeBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtBreakFrom'].focus() };
                            }}
                            labelClass='text-nowrap'
                            //divclassname='col-lg-2'
                            label='Shift Time Out'
                            id='txtShiftTimeOut'
                            selected={shiftForm.shiftTimeOut}
                            placeholder="Shift Time Out"
                            value={shiftForm.shiftTimeOut}
                            onChange={(e) => {
                                HandleChange('shiftTimeOut', e.target.value)

                            }}
                             required
                            error={errors.shiftTimeOut}
                        />



                        <TimeBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtBreakTo'].focus() };
                            }}
                            labelClass='text-nowrap'
                            // divclassname='col-lg-2'
                            label='Break From'
                            id='txtBreakFrom'
                            selected={shiftForm.breakFrom}
                            placeholder="Break From"
                            value={shiftForm.breakFrom}
                            onChange={(e) => {
                                HandleChange('breakFrom', e.target.value)

                            }}
                            required
                            error={errors.breakFrom}
                        />
                        <TimeBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtMinimumWorkingHours'].focus() };
                            }}
                            labelClass='text-nowrap'
                            //divclassname='col-lg-2'
                            label='Break To'
                            id='txtBreakTo'
                            selected={shiftForm.breakTo}
                            placeholder="Break To"
                            value={shiftForm.breakTo}
                            onChange={(e) => {
                                HandleChange('breakTo', e.target.value)

                            }}
                            required
                            error={errors.breakTo}
                        />

                  

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtIsActive'].focus() };
                            }}
                            label='Minimum Working Hours'
                            id='txtMinimumWorkingHours'
                            maxLength='50'
                            placeholder="Min. Working Hours"
                            required
                            error={errors.minimumWorkingHrs}
                            value={shiftForm.minimumWorkingHrs}
                            onChange={(e) => HandleChange('minimumWorkingHrs', number(e.target.value))}
                        />
                        <Switch
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtFirstHalfStarts'].focus() };
                            }}
                            label='Active'
                            checked={shiftForm.active}
                            id='txtIsActive'
                            // nextinputid='txtFranchiseGroup'
                            onChange={(e) => HandleChange('active', e.target.checked)}
                        />

                        <fieldset className="myfieldset "  >
                            <legend className="mylegend"> <h6 className="m-0">Late Count</h6></legend>
                            <div className='row'>
                                <TimeBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtSecondHalfStarts'].focus() };
                                    }}
                                    labelClass='text-nowrap'
                                    //divclassname='col-lg-2'
                                    label='First Half Starts'
                                    id='txtFirstHalfStarts'
                                    selected={shiftForm.firstHalfStarts}
                                    placeholder="First Half Starts"
                                    value={shiftForm.firstHalfStarts}
                                    onChange={(e) => {
                                        HandleChange('firstHalfStarts', e.target.value)

                                    }}
                                    required
                                    error={errors.firstHalfStarts}
                                />

                                <TimeBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtMaxLateAsHalfDayActive'].focus() };
                                    }}
                                    labelClass='text-nowrap'
                                    // divclassname='col-lg-2'
                                    label='Second Half Starts'
                                    id='txtSecondHalfStarts'
                                    selected={shiftForm.secondHalfStarts}
                                    placeholder="Second Half Starts"
                                    value={shiftForm.secondHalfStarts}
                                    onChange={(e) => {
                                        HandleChange('secondHalfStarts', e.target.value)

                                    }}
                                    required
                                    error={errors.secondHalfStarts}
                                />

                                <div className={`col-md-3 mb-1 `} >
                                    <label className="form-label" style={{ textWrap: 'nowrap' }}>Max Late Allowed{ !shiftForm.maxLateAsHalfDayActive ? "" : <span style={{ color: "red" }}> *</span>}</label>
                                    <div className='d-flex'>
                                    <Switch
                                            divclassname=''
                                            extraclassname="form-switch mt-1"
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { shiftForm.maxLateAsHalfDayActive ? inputrefs.current['txtMaxLate'].focus() : handleSubmit() };
                                            }}
                                            //   label='Max Late'
                                            checked={shiftForm.maxLateAsHalfDayActive}  
                                            id='txtMaxLateAsHalfDayActive'
                                            // nextinputid='txtFranchiseGroup'
                                            onChange={(e) => {
                                                HandleChange('maxLateAsHalfDayActive', e.target.checked)
                                                if (!e.target.checked) {
                                                    setShiftForm({ ...shiftForm, maxLate: '', maxLateAsHalfDayActive: e.target.checked })
                                                }
                                            }
                                            }
                                    />


                                    <InputBox
                                        inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { handleSubmit() };
                                        }}
                                        divclassname='col-md-7'
                                        //label='Shift Name'
                                            disabled={!shiftForm.maxLateAsHalfDayActive}
                                        id='txtMaxLate'
                                        maxLength='50'
                                        placeholder="Max Late"
                                        required
                                        error={errors.maxLate}
                                        value={shiftForm.maxLate}
                                        onChange={(e) => HandleChange('maxLate', number(e.target.value))}
                                    />


                                    </div>
                                </div>
                            </div>


                        </fieldset>

                        <FormButton
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/RoasterManagement/ShowShiftMaster', ExportRequest, 'Shift Master')}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>

            {/* Table Component start*/}
            <Table
                Token={TokenData}
                PageName='ShiftMaster'
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleFilter={() => {
                    getShiftFormData();
                    setFilterData({
                        ...filterData,
                    });
                }}
                tableData={result}
                //  loading={loading}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
            {/* Table Component End*/}


            {/* Loder start*/}
            {/*  {loading && < BlurLoader />}
          {/* Loder End*/}
        </>
    )
}

export default ShiftMaster