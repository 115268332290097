import React, { useEffect, useRef, useState } from 'react';
import { AutoCompleteBox, CheckBoxInput, DateBox, InputBox, SelectInputBox } from "../commoncomponents/InputBox";
//import { FileUploader } from "react-drag-drop-files";
//loder
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER } from '../../Utils';
//services
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { post } from '../../apiservices/service';
import { dateFormate } from '../Common/Common';

function OtherDetails({ TokenData, retiredEmployee, activeTab, sessionExpired, handleTabClick, EmployeeCodeByUrl, employee, setActiveTab, recipe, setRecipe, PreviousExperienceDetailsList, setPreviousExperienceDetailsList, QualificationDetailsList, setQualificationDetailsList }) {
    const inputrefs = useRef([]);

    const [loading, setLoading] = useState(false)
    const [yearData, setYearData] = useState([]);
    const [qualificationData, setQualificationData] = useState([]);
    // const [QualificationDetailsList, setQualificationDetailsList] = useState([]);
    // const [PreviousExperienceDetailsList, setPreviousExperienceDetailsList] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [editIndex, setEditIndex] = useState('')
    const [errors, setErrors] = useState({});
    const [designationAllData, setDesignationAllData] = useState([])
    const [referenceByAllData, setReferenceByAllData] = useState([])
    const [QualificationForm, setQualificationForm] = useState({
        "qualification": "",
        "college": "",
        "year": "",
        otherQualification: ''
    })
    const [isEditPreviousExperience, setIsEditPreviousExperience] = useState(false)
    const [editIndexPreviousExperience, setEditIndexPreviousExperience] = useState('')
    const [PreviousExperienceForm, setPreviousExperienceForm] = useState({
        "designation": "",
        "companyName": "",
        "fromDate": "",
        "toDate": "",
    })
    const YearData = () => {
        setYearData([])
        let temp = {
            Type: "GetYear",
            Prefix: '',
        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setYearData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setYearData(data)
                }
            }
        });
    }

    const QualificationData = () => {
        setQualificationData([])
        let temp = {
            Type: "GetQualification",
            Prefix: '',
        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setQualificationData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setQualificationData(data)
                }
            }
        });
    }

    // Get Desination Data
    const getDesignationData = () => {
        let temp = {
            Type: "GetDesignation",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setDesignationAllData(data)
                }
            }
        });
    }
    // Get Referance By Data
    const getReferenceByData = (value) => {
        let temp = {
            Type: "GetApprovalEmployee",
            Prefix: value ? value : '',
            ContextKey: EmployeeCodeByUrl ? EmployeeCodeByUrl : "",

        }
        //  setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setReferenceByAllData(data)
                }
            }
        });
    }

    const HandleChange = (prop, value) => {
        if (prop === 'qualification') {
            value === 'Others' ? setQualificationForm(QualificationForm => ({ ...QualificationForm, [prop]: value })) : setQualificationForm({ ...QualificationForm, otherQualification: '' })
        }
        setQualificationForm(QualificationForm => ({ ...QualificationForm, [prop]: value }))
        setPreviousExperienceForm(PreviousExperienceForm => ({ ...PreviousExperienceForm, [prop]: value }))
    }
    const HandleChangeForRecipe = (prop, value) => {
        setRecipe({ ...recipe, [prop]: value })
    }

    //Submit Handler
    const handleSubmit = () => {
        if (QualificationDetailsList.length > 0) {
            if ((recipe.IsRetired === true) && (!recipe.DOL && recipe.DOL === '')) {
                setErrors({ ...errors, DOL: 'Select Date Of Leaving' })
            } else {
                setErrors({ ...errors, DOL: '' })
                setLoading(true)
                post(`${API_SERVER}api/Employee/UpdateEmployeeQualificationDatail`, { ...TokenData, ...recipe, QualificationDetailsList: QualificationDetailsList, PrevEmploymentDetailsList: PreviousExperienceDetailsList, EmpCode: EmployeeCodeByUrl ? EmployeeCodeByUrl : employee ? employee?.empCode : "0" }, (res) => {
                    if (res && res.status) {
                        if (res.status === "ERROR") {
                            toast.error(res.message)
                            setLoading(false)
                        } else if (res.status === "EXPIRED") {
                            toast.error(res.message)
                            sessionExpired()
                        } else if (res.status === "UNAUTHORIZED") {
                            toast.error(res.message);
                            setLoading(false);
                        } else {
                            setLoading(false)
                            toast.success(res.message)
                            handleTabClick("EmployeeSalaryStructure")
                            setActiveTab("EmployeeSalaryStructure")

                            //handleClear()
                        }
                    }
                });
            }
        } else {
            if (QualificationDetailsList.length === 0) toast.error("Please Add Qualification Datails")
            //   else if (PreviousExperienceDetailsList.length === 0) toast.error("Please Add Previous Employement Datails");
        }

    }
    //console.log(QualificationForm, 'QualificationForm')
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "qualification", msg: 'Please Enter Qualification.' },
            { field: "college", msg: 'Please Enter College Name.' },
            { field: "year", msg: 'Select Passing Year.' },
        ]
        if (QualificationForm['qualification'] === 'Others') {
            validation.push(
                { field: "otherQualification", msg: ' Please Enter Other Qualification.' },
            )
        };
        validation.map(item => {
            if (!QualificationForm[item.field] || QualificationForm[item.field].length <= 0 || QualificationForm[item.field] === '') {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        return isValid;
    }

    const ValidatePreviousEmpyolement = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "companyName", msg: 'Please Enter Company Name.' },
            { field: "designation", msg: 'Please Enter Designation.' },
            { field: "fromDate", msg: 'Please Enter From Date.' },
            { field: "toDate", msg: 'Please Enter To Date.' },
        ]
        validation.map(item => {
            if (!PreviousExperienceForm[item.field] || PreviousExperienceForm[item.field].length <= 0 || PreviousExperienceForm[item.field] === '') {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        return isValid;
    }
    const checkDuplicatesDataForQualification = (objectToCheck, arr) => {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].qualification.toLowerCase() === objectToCheck.qualification.toLowerCase() &&
                arr[i].college.toLowerCase() === objectToCheck.college.toLowerCase() &&
                arr[i].otherQualification.toLowerCase() === objectToCheck.otherQualification.toLowerCase() &&
                arr[i].year === objectToCheck.year) {
                return true;
            }

        }
        return false;
    }

    //handle Add Qualification
    const handleAddQualifications = () => {
        if (Validate()) {
            if (checkDuplicatesDataForQualification(QualificationForm, QualificationDetailsList) === true) {
                toast.error('Qualification Already Exist')
            } else {
                if (isEdit) {
                    const list = [...QualificationDetailsList];
                    list[editIndex] = { ...QualificationForm };
                    setQualificationDetailsList(list);
                    setIsEdit(false);
                    setEditIndex('');
                } else {
                    setQualificationDetailsList([...QualificationDetailsList, QualificationForm])
                }
                handleClearQualificationInputs();
            }
        }
    }

    // Handle Reset Qualification Inputs
    const handleClearQualificationInputs = () => {
        setQualificationForm({
            ...QualificationForm,
            "qualification": "",
            "college": "",
            "year": "",
            otherQualification: ''
        })
    }

    //Handle Edit Qualificaion
    const HandleQualificationEdit = (item, index) => {
        setIsEdit(true);
        setEditIndex(index);
        const editQulification = QualificationDetailsList.find((ele, i) => i === index)
        //  console.log(editQulification, '34df')
        setQualificationForm({
            ...QualificationForm,
            "qualification": editQulification.qualification,
            "college": editQulification.college,
            "year": editQulification.year,
            "otherQualification": editQulification.otherQualification,
        })
    }

    // Handle Delete Qualification
    const HandleQualificationDelete = (index) => {
        const filerQualification = QualificationDetailsList.filter((ele, i) => i !== index);
        setQualificationDetailsList(filerQualification);
    }

    const checkDuplicatesDataForExperience = (objectToCheck, arr) => {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].designation.toLowerCase() === objectToCheck.designation.toLowerCase() &&
                arr[i].companyName.toLowerCase() === objectToCheck.companyName.toLowerCase() &&
                arr[i].fromDate === objectToCheck.fromDate &&
                arr[i].toDate === objectToCheck.toDate) {
                return true;
            }

        }
        return false;
    }


    //handle Add PreviousExperience
    const handleAddPreviousExperiences = () => {
        if (ValidatePreviousEmpyolement()) {
            if (checkDuplicatesDataForExperience(PreviousExperienceForm, PreviousExperienceDetailsList) === true) {
                toast.error('Experience Already Exist')
            } else {
                console.log(PreviousExperienceDetailsList)
                // console.log(checkDuplicatesData)
                //const form = { ...PreviousExperienceForm };
                // form.toDate = ToDate;
                //  form.fromDate = FromDate;
                if (isEditPreviousExperience) {
                    const list = [...PreviousExperienceDetailsList];
                    list[editIndexPreviousExperience] = { ...PreviousExperienceForm };
                    // list[editIndexPreviousExperience].toDate = format(ToDate, 'dd-MMM-yyyy');
                    //list[editIndexPreviousExperience].fromDate = format(FromDate, 'dd-MMM-yyyy');
                    setPreviousExperienceDetailsList(list);
                    setIsEditPreviousExperience(false);
                    setEditIndexPreviousExperience('');
                    handleClearPreviousExperienceInputs();
                } else {
                    const fromData = { ...PreviousExperienceForm };
                    // fromData.fromDate = FromDate.toLocaleDateString();
                    // fromData.fromDate = format(FromDate, 'dd-MMM-yyyy');
                    // fromData.toDate = format(ToDate, 'dd-MMM-yyyy');
                    // console.log(PreviousExperienceDetailsList, 'PreviousExperienceDetailsList', fromData)
                    setPreviousExperienceDetailsList([...PreviousExperienceDetailsList, fromData])
                    // console.log(fromData, 'kffffdfhgd')
                    handleClearPreviousExperienceInputs();
                }
            }
        }
    }



    // Handle Reset PreviousExperience Inputs
    const handleClearPreviousExperienceInputs = () => {
        setPreviousExperienceForm({
            ...PreviousExperienceForm,
            "designation": "",
            "companyName": "",
            fromDate: '',
            toDate: ''
        })
        // setToDate(null)
        //  setFromDate(null)
        setIsEditPreviousExperience(false);
        setEditIndexPreviousExperience('');
    }

    //Handle Edit PreviousExperience
    const HandlePreviousExperienceEdit = (item, index) => {
        setIsEditPreviousExperience(true);
        setEditIndexPreviousExperience(index);
        const editQulification = PreviousExperienceDetailsList.find((ele, i) => i === index)
        //setFromDate(editQulification ? new Date(editQulification.fromDate) : '')
        // setToDate(editQulification ? new Date(editQulification.toDate) : "")

        setPreviousExperienceForm({
            ...PreviousExperienceForm,
            "designation": editQulification.designation,
            "companyName": editQulification.companyName,
            "fromDate": editQulification.fromDate,
            "toDate": editQulification.toDate
        })
    }



    // Handle Delete PreviousExperience
    const HandlePreviousExperienceDelete = (index) => {
        const filerPreviousExperience = PreviousExperienceDetailsList.filter((ele, i) => i !== index);
        setPreviousExperienceDetailsList(filerPreviousExperience);
    }


    //Reset Handler
    const handleClear = () => {
        handleTabClick('PersonalDetails')
        setActiveTab('PersonalDetails')
    }



    let DesignationData = []
    for (let i = 0; i < designationAllData.length; i++) {
        let mydata = designationAllData[i]?.values
        DesignationData.push(mydata)
    };

    let ReferenceByData = []
    for (let i = 0; i < referenceByAllData.length; i++) {
        let mydata = referenceByAllData[i]?.values
        ReferenceByData.push(mydata)
    };

    //  console.log(qualificationDetails, 'qualificationDetails')
    // const QualificationDataByEditTime = [...qualificationListDetails];
    // const PrevEmployementDataByEditTime = [...preExperienceListDetails];
    useEffect(() => {
        if (activeTab === 'OtherDetails') {
            inputrefs.current['txtQualification'].focus();
            document.title = 'Pay Plus : Other Details'
            YearData();
            QualificationData();
            getDesignationData();
            getReferenceByData();
        }
    }, [activeTab])


    //useEffect(() => {
    //    //  setQualificationDetailsList(qualificationListDetails);
    //    //  setPreviousExperienceDetailsList(preExperienceListDetails)
    //    YearData();
    //    QualificationData();
    //    getDesignationData();
    //    getReferenceByData();
    //    //  inputrefs.current['txtCompanyCode'].focus();

    //}, [])


    return (
        <>
            <div className='main-card card border-0'>

                <div className='card-body'>
                    <div className='row ps-3 pt-2'>

                        {/*<div className='row'>*/}
                        <div className='row'>
                            <fieldset className="myfieldset">
                                <legend className="mylegend"> <h6 className="m-0"><span style={{ color: "red" }}>* </span>Qualification Details</h6></legend>
                                <div className='row my-2'>
                                    {/*<div className={`col-md-3 mb-1 cntrl-width`} key="txtQualification">*/}

                                    {/*    <select*/}
                                    {/*        ref={ref => (inputrefs.current['txtQualification'] = ref)}*/}
                                    {/*        className="form-control"*/}
                                    {/*        id="txtQualification"*/}
                                    {/*        value={QualificationForm.qualification === 'Other' ? QualificationForm?.otherQualification : QualificationForm.qualification}*/}
                                    {/*        required*/}
                                    {/*        onKeyDown={(e) => {*/}
                                    {/*            if (e.key === 'Enter') { QualificationForm.qualification === 'Others' ? inputrefs.current['txtOtherQualification'].focus() : inputrefs.current['txtCollegeName'].focus() };*/}
                                    {/*        }}*/}
                                    {/*        onChange={(e) => HandleChange('qualification', e.target.value)}>*/}
                                    {/*        <option value="">--Select Qualification--</option>*/}
                                    {/*        {qualificationData.map((opt, index) => <option key={index} value={opt.name === ' Others' ? QualificationForm.otherQualification : opt.id} >{opt.name}</option>)}*/}
                                    {/*    </select>*/}
                                    {/*    <div className="error-msg">{errors.qualification}</div>*/}
                                    {/*</div>*/}

                                    <SelectInputBox
                                        label='Qualification'
                                        inputrefs={inputrefs}
                                        required
                                        id="txtQualification"
                                        error={errors.qualification}
                                        value={QualificationForm.qualification === 'Other' ? QualificationForm?.otherQualification : QualificationForm.qualification}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { QualificationForm.qualification === 'Others' ? inputrefs.current['txtOtherQualification'].focus() : inputrefs.current['txtCollegeName'].focus() };
                                        }}
                                        onChange={(e) => HandleChange('qualification', e.target.value)}
                                        children={<>
                                            <option value="">--Select Qualification--</option>
                                            {qualificationData.map((opt, index) => <option key={index} value={opt.name === ' Others' ? QualificationForm.otherQualification : opt.id} >{opt.name}</option>)}
                                        </>}
                                    />
                                    {QualificationForm.qualification === 'Others' &&
                                        <InputBox
                                        label='Other Qualification'
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtCollegeName'].focus() };
                                            }}
                                            id='txtOtherQualification'
                                            key='txtOtherQualification'
                                            required
                                            maxLength='50' placeholder="Other Qualification"
                                            value={QualificationForm.otherQualification}
                                            error={errors.otherQualification}
                                            onChange={(e) => { HandleChange('otherQualification', e.target.value) }}
                                        />}

                                    <InputBox
                                        label='College/University Name'
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtCourse'].focus() };
                                        }}
                                        id='txtCollegeName'
                                        key='txtCollegeName'
                                        // divclassname={QualificationForm.qualification === 'Others' ? 'col-lg-3  col-md-3 mb-1' : 'col-lg-5 col-md-5 mb-1'}
                                        required
                                        maxLength='50' placeholder="College/University Name"
                                        value={QualificationForm.college}
                                        error={errors.college} onChange={(e) => { HandleChange('college', e.target.value) }}
                                    />


                                    {/*<div className={`col-md-3 col-lg-3 cntrl-width mb-1`} key="txtYear">*/}

                                    {/*    <select*/}
                                    {/*        className="form-control"*/}
                                    {/*        ref={ref => (inputrefs.current['txtYear'] = ref)}*/}
                                    {/*        id="txtYear"*/}
                                    {/*        value={QualificationForm.year}*/}
                                    {/*        required*/}
                                    {/*        onKeyDown={(e) => {*/}
                                    {/*            if (e.key === 'Enter') { handleAddQualifications() };*/}
                                    {/*        }}*/}
                                    {/*        onChange={(e) => HandleChange('year', e.target.value)}>*/}
                                    {/*        <option value="">--Year--</option>*/}
                                    {/*        {yearData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}*/}
                                    {/*    </select>*/}
                                    {/*    <div className="error-msg">{errors.year}</div>*/}
                                    {/*</div>*/}
                                    <SelectInputBox
                                        label='Year'
                                        inputrefs={inputrefs}
                                        id="txtYear"
                                        value={QualificationForm.year}
                                        error={errors.year}
                                        required
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { handleAddQualifications() };
                                        }}
                                        onChange={(e) => HandleChange('year', e.target.value)}
                                        children={<>
                                            <option value="">--Year--</option>
                                            {yearData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}
                                        </>}
                                    />


                                    <div className={`col-lg-3 cntrl-width  mt-lg-4 mt-2 `} style={{ paddingTop: '0.2rem' }}>
                                        <button onClick={() => handleAddQualifications()} className="btn btn-rounded btn-success p-1 px-2" style={{ minWidth: "1.5rem" }} ><i className="fa-solid  fa fa-check"></i></button>
                                        <button onClick={() => handleClearQualificationInputs()} className="btn btn-rounded btn-danger p-1 px-2" style={{ minWidth: "1.5rem" }}><i className="fa-solid  fa-arrow-rotate-left"></i></button>
                                    </div>
                                    {QualificationDetailsList?.length !== 0 ?

                                        <div className='row pt-2 '>
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-striped">
                                                    <thead>
                                                        <tr className='light-bg'>
                                                            <th >Action</th>
                                                            <th >Qualification</th>
                                                            <th >Other Qualification</th>
                                                            <th >College/University Name</th>
                                                            <th >Year</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>


                                                        {QualificationDetailsList?.map((item, index) => {
                                                            //   console.log(item,'dgsdjfk')
                                                            return <tr key={index}>
                                                                <td><i className="fa-regular fa-pen-to-square text-success me-2" onClick={() => HandleQualificationEdit(item, index)} />  <i className="fa-regular fa-trash-can text-danger me-2" onClick={() => HandleQualificationDelete(index)} /></td>
                                                                <td>{item?.qualification}</td>
                                                                <td>{item?.otherQualification}</td>
                                                                <td>{item?.college}</td>
                                                                <td>{item?.year}</td>

                                                            </tr>
                                                        })}
                                                        {/*{QualificationDataByEditTime?.map((item, index) => {*/}
                                                        {/*    return <tr key={index}>*/}
                                                        {/*        <td><i className="fa-regular fa-pen-to-square text-success me-2" onClick={() => HandleQualificationEdit(item, index)} />  <i className="fa-regular fa-trash-can text-danger me-2" onClick={() => HandleQualificationDelete(index)} /></td>*/}
                                                        {/*        <td>{item?.qualification}</td>*/}
                                                        {/*        <td>{item?.college}</td>*/}
                                                        {/*        <td>{item?.year}</td>*/}

                                                        {/*    </tr>*/}
                                                        {/*}) }*/}

                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                        : <></>}
                                </div>
                            </fieldset>
                        </div>
                        <div className='row'>
                            <fieldset className="myfieldset">
                                <legend className="mylegend"> <h6 className="m-0">{/*<span style={{ color: "red" }}>* </span>*/}Experience</h6></legend>
                                <div className='row my-2'>
                                    <AutoCompleteBox
                                        label='Designation'
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtCompanyName'].focus() };
                                        }}
                                        //label=''
                                        placeholder="Designation"
                                        maxLength='50'
                                        options={DesignationData}
                                        id='txtDesignation'
                                        //required={true}
                                        error={errors.designation}
                                        inputValue={PreviousExperienceForm.designation ? PreviousExperienceForm.designation : ''}
                                        onInputChange={(event, newInputValue) => {
                                            HandleChange('designation', newInputValue)
                                            //setStatePre(newInputValue)
                                        }}
                                    />
                                    <InputBox
                                        label='Company Name'
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtFromDate'].focus() };
                                        }}
                                        id='txtCompanyName'
                                        //divclassname="col-lg-4  col-md-4 mb-1"
                                        maxLength='50' placeholder="Company Name"
                                        value={PreviousExperienceForm.companyName}
                                        error={errors.companyName} onChange={(e) => { HandleChange('companyName', e.target.value) }}
                                    />

                                    <DateBox
                                        label='From Date'
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtToDate'].focus() };
                                        }}
                                        // label='From Date'
                                        id='txtFromDate'
                                        maxLength='11'

                                        //isClearable
                                        placeholder="From Date"
                                        selected={PreviousExperienceForm.fromDate === '' ? '' : new Date(PreviousExperienceForm.fromDate)}
                                        // selected={FromDate}
                                        // value={FromDate}
                                        maxDate={new Date()}
                                        error={errors.fromDate}
                                        onChange={(e) => {
                                            HandleChange('fromDate', dateFormate(e))
                                            if (e) setPreviousExperienceForm({ ...PreviousExperienceForm, toDate: '', fromDate: dateFormate(e) })
                                        }}
                                    // required
                                    />
                                    <DateBox
                                        label='To Date'
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { handleAddPreviousExperiences() };
                                        }}
                                        maxLength='11'
                                        // label='To Date'
                                        minDate={new Date(PreviousExperienceForm.fromDate)}
                                        maxDate={new Date()}
                                        id='txtToDate'
                                        //isClearable
                                        placeholder="To Date"
                                        selected={PreviousExperienceForm.toDate === '' ? '' : new Date(PreviousExperienceForm.toDate)}
                                        //selected={ToDate}
                                        // value={ToDate}
                                        error={errors.toDate}
                                        onChange={(e) => HandleChange('toDate', dateFormate(e))}
                                    //required
                                    />


                                    <div className='cntrl-width col-md-3 mt-lg-4 mt-2' style={{paddingTop:'0.2rem'} }>
                                        <button onClick={() => handleAddPreviousExperiences()} className="btn btn-rounded btn-success p-1 px-2" style={{ minWidth: "1.5rem" }} ><i className="fa-solid  fa fa-check"></i></button>
                                        <button onClick={() => handleClearPreviousExperienceInputs()} className="btn btn-rounded btn-danger p-1 px-2" style={{ minWidth: "1.5rem" }}><i className="fa-solid  fa-arrow-rotate-left"></i></button>
                                    </div>
                                    {PreviousExperienceDetailsList?.length !== 0 ?

                                        <div className='row pt-3 '>
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-striped">
                                                    <thead>
                                                        <tr className='light-bg'>
                                                            <th >Action</th>
                                                            <th >Designation</th>
                                                            <th >Company Name</th>
                                                            <th >From Date</th>
                                                            <th >To Date</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>


                                                        {PreviousExperienceDetailsList?.map((item, index) => {
                                                            return <tr key={index}>
                                                                <td><i className="fa-regular fa-pen-to-square text-success me-2" onClick={() => HandlePreviousExperienceEdit(item, index)} />  <i className="fa-regular fa-trash-can text-danger me-2" onClick={() => HandlePreviousExperienceDelete(index)} /></td>
                                                                <td>{item?.designation}</td>
                                                                <td>{item?.companyName}</td>
                                                                <td>{item?.fromDate}</td>
                                                                <td>{item?.toDate}</td>
                                                            </tr>
                                                        })}

                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                        : <></>}

                                </div>
                            </fieldset>
                        </div>
                        <div className='row'>
                            <div className='myfieldset'>
                                <div className='row'>

                                    <AutoCompleteBox
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtReferenceRelation'].focus() };
                                        }}
                                        label='Reference By'
                                        placeholder="Reference By"
                                        maxLength='50'
                                        options={ReferenceByData}
                                        id='txtReferenceBy'
                                        //   required={true}
                                        // error={errors.ReferenceBy}
                                        inputValue={recipe.ReferenceBy ? recipe.ReferenceBy : ''}
                                        onInputChange={(event, newInputValue) => {
                                            HandleChangeForRecipe('ReferenceBy', newInputValue)
                                            getReferenceByData(newInputValue)
                                        }}
                                    //  onFocus={() => getReferenceByData() }
                                    />
                                    <InputBox
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtRetired'].focus() };
                                        }}
                                        label='Reference Relation'
                                        //   divclassname={'col-lg-4  col-md-4 mb-1'}
                                        id='txtReferenceRelation'
                                        maxLength='50'
                                        placeholder="Reference Relation"
                                        value={recipe.ReferenceRelation}
                                        // required
                                        // error={errors.ReferenceRelation}
                                        onChange={(e) => { HandleChangeForRecipe('ReferenceRelation', e.target.value) }}
                                    />
                                    <InputBox
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtICardIssued'].focus() };
                                        }}
                                        label='Remarks'
                                        id='txtRemarks'
                                        maxLength='50'
                                        //  divclassname={'col-lg-4  col-md-4 mb-1'}
                                        placeholder='Remarks'
                                        value={recipe.Remarks}
                                        onChange={(e) => { HandleChangeForRecipe('Remarks', (e.target.value)) }}
                                        disabled={recipe.EmployeeBank === '' ? true : false}
                                    //required
                                    //error={errors.Remarks}
                                    />

                                    <CheckBoxInput
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtJoiningConfirmationReceived'].focus() };
                                        }}
                                        customclass={'mt-4'}
                                        divclassname={'cntrl-width col-md-3 mt-1'}
                                        checked={recipe.ICardIssued}
                                        label='I-Card Issued'
                                        id='txtICardIssued'
                                        //  value={recipe.isICardIssued}
                                        onChange={(e) => { HandleChangeForRecipe('ICardIssued', e.target.checked) }}


                                    />
                                    <CheckBoxInput
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { handleSubmit() };
                                        }}
                                        customclass={'mt-4'}
                                        divclassname={'cntrl-width col-md-3 mt-1'}
                                        checked={recipe.JoiningConfirmationReceived}
                                        label='Joining Confirmation'
                                        id='txtJoiningConfirmationReceived'
                                        //  value={recipe.isJoiningConfirmation}
                                        onChange={(e) => { HandleChangeForRecipe('JoiningConfirmationReceived', e.target.checked) }}


                                    />
                                </div>
                            </div>
                        </div>
                        <fieldset className="myfieldset">
                            <legend className="mylegend"> <h6 className="m-0">Left The Service &nbsp; <input type='checkbox' className={'mt-1'} disabled={retiredEmployee === true && EmployeeCodeByUrl} checked={recipe.IsRetired} id='txtRetired' value={recipe.IsRetired} onChange={(e) => {

                                HandleChangeForRecipe('IsRetired', e.target.checked)
                                if (e.target.checked === false) {
                                    setRecipe({ ...recipe, DOL: '', IsRetired: e.target.checked })
                                }
                            }} /> </h6></legend>
                            {recipe.IsRetired === true &&
                                <div className='row my-2'>

                                    <DateBox
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtReasonOfLeaving'].focus() };
                                        }}
                                        label="Date of Leaving"
                                        id='txtDOL'
                                        maxLength='11'
                                        // isClearable
                                        selected={recipe.DOL === '' ? '' : new Date(recipe.DOL)}
                                        // selected={recipe.DOL}
                                        placeholder="Date of Leaving"
                                        // value={recipe.DOL}
                                        disabled={retiredEmployee === true && EmployeeCodeByUrl}
                                        onChange={(e) => HandleChangeForRecipe('DOL', dateFormate(e))}
                                        required={recipe.IsRetired}
                                        divclassname={'col-lg-6  col-md-6 mb-1'}
                                        error={errors.DOL}
                                    />
                                    <InputBox
                                        inputrefs={inputrefs}
                                        // onKeyDown={(e) => {if (e.key === 'Enter') { inputrefs.current['txtCourse'].focus() }}}
                                        placeholder="Reason of Leaving"
                                        label="Reason of Leaving"

                                        id='txtReasonOfLeaving'
                                        key='txtReasonOfLeaving'
                                        divclassname={'col-lg-6  col-md-6 mb-1'}
                                        // required
                                        maxLength='50'
                                        value={recipe.ReasonOfLeaving}
                                        // error={errors.ReasonOfLeaving}
                                        onChange={(e) => { HandleChangeForRecipe('ReasonOfLeaving', e.target.value) }}
                                    />
                                </div>
                            }
                        </fieldset>
                        {/*</div>*/}
                        <div style={{ alignItems: 'center' }} className='d-flex align-items-center justify-content pt-3'>
                            <button onClick={handleSubmit} className="btn btn-rounded btn-success p-1 px-2"  ><i className="fa-solid  fa fa-check"></i> Submit</button>
                            <button onClick={handleClear} className="btn btn-rounded btn-danger p-1 px-2" ><i className="fa-solid  fa-arrow-rotate-left"></i> Reset</button>
                        </div>

                    </div>

                </div>

            </div >

        </>
    )
}

export default OtherDetails
