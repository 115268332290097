import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../context/auth';
import { AutoCompleteBox, FormButton } from "../commoncomponents/InputBox";
//loder
import BlurLoader from "../Loder/BlurLoader";
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER } from '../../Utils';
//services
import { getCommonData} from '../../apiservices/CommonServices';
import { post } from '../../apiservices/service';
import { Token } from '../Common/Common';

function LoanStatement() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();

    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({
        employee: '',
    })

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [employeeAllData, setEmployeeAllData] = useState([])

    //Get Employee Data for AutoComplete
    const GetEmployeeData = (value) => {
        setEmployeeAllData([])
        let temp = {
            Type: "GetEmployee",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setEmployeeAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setEmployeeAllData(data)
                }
            }
        });
    }

    //useEffect(() => {
    //    inputrefs.current['txtEmployee'].focus();
    //    document.title = 'Pay Plus : Loan Statement';
    //   GetEmployeeData();
    //}, [])

    useEffect(() => {
        inputrefs.current['txtEmployee'].focus();
        document.title = 'Pay Plus : Loan Statement';
        GetEmployeeData();
    },[])


    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "employee", msg: 'Please Enter Employee.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        return isValid;
    }

    //Submit Handler
    const handleSubmit = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/loan/PrintLoanStatement`, { ...TokenData, ...recipe }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        setLoading(false)
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "UNAUTHORIZED") {
                        toast.error(res.Message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        handleClear()
                        let base64PDF = res && res.base64PDF
                        window.open(`/PdfView/#data:application/pdf;base64,${base64PDF}`, '_blank');
                    }
                }
            });
        }
    }
    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            employee: "",
        }))
        setErrors({})
        GetEmployeeData();
    }

    let EmployeeData = []
    for (let i = 0; i < employeeAllData.length; i++) {
        let mydata = employeeAllData[i]?.values
        EmployeeData.push(mydata)
    };

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Loan Statement</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            label='Employee'
                            placeholder="Employee"
                            divclassname='col-lg-4'
                            maxLength='10'
                            options={EmployeeData}
                            id='txtEmployee'
                            key='txtEmployee'
                            required={true}
                            error={errors.employee}
                            inputValue={recipe.employee ? recipe.employee : ''}
                            onInputChange={(event, newInputValue) => {
                                GetEmployeeData(newInputValue)
                                HandleChange('employee', newInputValue)
                            }}
                        />

                        <FormButton
                            saveButtonIcon='fa-print'
                            SaveText='Print'
                            Export={false}
                            handleSubmit={handleSubmit}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>
            {loading && < BlurLoader />}
        </>
    )
}

export default LoanStatement
