import React, { useEffect, useRef, useState } from 'react';
import { AutoCompleteBox, DateBox, FormButton, InputBox, SelectInputBox } from "../commoncomponents/InputBox";
//import { FileUploader } from "react-drag-drop-files";
import { useAuth } from '../../context/auth';
//loder
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, initializeFilterData } from '../../Utils';
//services
import { useNavigate } from 'react-router-dom';
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { post } from '../../apiservices/service';
import { dateFormate, number } from '../Common/Common';
import ProfilePicture from '../commoncomponents/ProfilePicture';

function EmployeePersonalDetails({ TokenData, setShowUploadImageModal, employee, errorFocus, setErrorFocus,sessionExpired, recipe, setRecipe, imageData, handleTabClick, setActiveTab, activeTab, dateOfBirth, setDateOfBirth, isPerAddressSame, setIsPerAddressSame, EmployeeCodeByUrl, profilePictureRecipe }) {
    const inputrefs = useRef([]);
    const { user } = useAuth();

    const navigate = useNavigate()


    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [isUpdate, setIsUpdate] = useState(false)
    const [countryPre, setCountryPre] = useState('')
    const [countryAllData, setCountryAllData] = useState([])
    const [statePre, setStatePre] = useState('')
    const [stateAllData, setStateAllData] = useState([])
    const [companyAllData, setCompanyAllData] = useState([])
    const [addressProofDocument, setAddressProofDocument] = useState();
    // const [isPerAddressSame, setIsPerAddressSame] = useState(false);
    const [BloodGroupData, setBloodGroupData] = useState([])
    const [errors, setErrors] = useState({});

    const GetAddressProofDocument = () => {
        let temp = {
            Type: "AddressProof",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false);
                } else {
                    setLoading(false);
                    let data = res.dataList;
                    setAddressProofDocument(data);
                }
            }
        });
    }

    const GetBloodGroupData = () => {
        setBloodGroupData([])
        let temp = {
            Type: "GetBloodGroup",
            Prefix: '',

        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setBloodGroupData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setBloodGroupData(data)
                }
            }
        });
    }

    const StateAllData = (value) => {
        setStateAllData([])
        let temp = {
            Type: "GetState",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        // setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setStateAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setStateAllData(data)
                }
            }
        });
    }

    const CompanyAllData = (value) => {
        setCompanyAllData([])
        let temp = {
            Type: "GetCompany",
            Prefix: value ? value : "",
            ContextKey: '',
            ContextKey2: ""
        }
        // setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setCompanyAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setCompanyAllData(data)
                }
            }
        });
    }

    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setLoading(true)
        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/MASTER/ShowCompanyMaster`, { ...TokenData, ...temp, ...sortConfig, ...filterData }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }

    const fillCorres = (value) => {
        if (value) {
            setRecipe({
                ...recipe,
                PerAddress1: recipe.CorAddress1,
                PerAddress2: recipe.CorAddress2,
                PerState: recipe.CorState,
                PerCity: recipe.CorCity,
                PerPinCode: recipe.CorPinCode,
                PerPhoneNo: recipe.CorPhoneNo,
                PerAddressProof: recipe.CorAddressProof,
                PerProofDocNo: recipe.CorProofDocNo,
            })
        } else {
            setRecipe({
                ...recipe,
                PerAddress1: '',
                PerAddress2: '',
                PerState: '',
                PerCity: '',
                PerPinCode: '',
                PerPhoneNo: '',
                PerAddressProof: '',
                PerProofDocNo: '',
            })
        }
        setIsPerAddressSame(value)
    }


    //useEffect(() => {
    //    fillCorres(isPerAddressSame)
    //}, [recipe])

    //Change Handler
    const HandleChange = (prop, value) => {
        if (prop === 'DOB') {
            setDateOfBirth(value)
        }
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
        //fillCorres(isPerAddressSame)
    }

    const handleChange1 = (event, value) => {
        getFormData(value);
    };

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "EmpName", msg: 'Please Enter Employee Name.' },
            { field: "FatherName", msg: "Please Enter Father's Name." },
            { field: "Company", msg: "Please Enter Company." },
            // { field: "FatherOccupation", msg: "Please Enter Father's Occupation." },
            { field: "DOB", msg: 'Please Enter DOB.' },
            { field: "Gender", msg: 'Please Select Gender.' },
            { field: "MaritalStatus", msg: 'Please Select Marital Status.' },
            { field: "EmailID", msg: 'Please Enter Email ID.' },
            { field: "MobileNo", msg: 'Please Enter Mobile No.' },
            { field: "AadhaarNo", msg: 'Please Enter Aadhaar No.' },
            { field: "CorAddress1", msg: 'Please Enter Address Line 1.' },
            { field: "CorState", msg: 'Please Select State.' },
            { field: "CorCity", msg: 'Please Enter City.' },
            { field: "CorPinCode", msg: 'Please Enter Pin Code.' },
           // { field: "CorAddressProof", msg: 'Please Select Address Proof.' },
        //    { field: "CorProofDocNo", msg: 'Please Select Address Proof No.' },

            { field: "PerAddress1", msg: 'Please Enter Address Line 1.' },
            { field: "PerState", msg: 'Please Select State.' },
            { field: "PerCity", msg: 'Please Enter City.' },
            { field: "PerPinCode", msg: 'Please Enter Pin Code.' },
         //   { field: "PerAddressProof", msg: 'Please Select Address Proof.' },
         //   { field: "PerProofDocNo", msg: 'Please Select Address Proof No.' },
        ];

        if (recipe['MaritalStatus'] === 'Married') {
            validation.push(
                { field: "SpouseName", msg: ' Please Enter Spouse Name.' },
               // { field: "SpouseOccupation", msg: 'Please Enter Spouse Occupation.' }
            )
        };
        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;

    }


    //Submit Handler
    const handleNext = () => {
        if (Validate()) {
            handleTabClick('EmploymenteDetails');
            setActiveTab('EmploymenteDetails');
            setRecipe({ ...recipe, SameAsCorrespondence: isPerAddressSame })
            setErrorFocus('')
        }
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe, EmployeeID: "0",
            CandidateID: '0',
            Company: TokenData.Company,
            EmpCode: "",
            EmpName: "",
            FatherName: "",
            FatherOccupation: "",
            DOB: "",
            Gender: "",
            MaritalStatus: "",
            SpouseName: "",
            SpouseOccupation: "",
            EmailID: "",
            MobileNo: "",
            AadhaarNo: "",
            BloodGroup: "",
            EmergencyContactNo: "",
            CorAddress1: "",
            CorAddress2: "",
            CorState: "",
            CorCity: "",
            CorPinCode: "",
            CorPhoneNo: "",
          //  CorAddressProof: "",
          //  CorProofDocNo: "",
            PerAddress1: "",
            PerAddress2: "",
            PerState: "",
            PerCity: "",
            PerPinCode: "",
            PerPhoneNo: "",
         //   PerAddressProof: "",
         //   PerProofDocNo: "",
        }))
        setErrors({})

    }

    let countryData = []
    for (let i = 0; i < countryAllData.length; i++) {
        let mydata = countryAllData[i]?.values
        countryData.push(mydata)
    };

    let StateData = []
    for (let i = 0; i < stateAllData.length; i++) {
        let mydata = stateAllData[i]?.values
        StateData.push(mydata)
    };

    let CompanyData = []
    for (let i = 0; i < companyAllData.length; i++) {
        let mydata = companyAllData[i]?.values
        CompanyData.push(mydata)
    };


    //useEffect(() => {
    //    if (activeTab === 'PersonalDetails') {
    //        errorFocus ? inputrefs?.current[errorFocus]?.focus() : inputrefs.current['txtCompany'].focus();
    //        document.title = 'Pay Plus : Personal Details'
    //    }
    //}, [activeTab])

    useEffect(() => {
        if (activeTab === 'PersonalDetails') {
            errorFocus ? inputrefs?.current[errorFocus]?.focus() : inputrefs.current['txtCompany'].focus();
            document.title = 'Pay Plus : Personal Details'
        GetAddressProofDocument()
        StateAllData()
        CompanyAllData()
        GetBloodGroupData()
        getFormData()
       
        //getFormData()
        }
    }, [sortConfig, isUpdate, activeTab])

    return (
        <>
            <div className='main-card card border-0 '>
                <div className='card-body'>
                    <div className='row ps-3 pt-2'>

                        <div className='row'>
                            <fieldset className="myfieldset">
                                <legend className="mylegend"><h6 className="m-0">Personal Details</h6></legend>
                                <div className='row flex-row-reverse justify-content-around '>
                                    <div className='col-lg-1 col-md-2 row'>
                                        <ProfilePicture
                                            openModal={()=>setShowUploadImageModal(true) }
                                            // HandleNewEntry={() => EmployeeCodeByUrl ? navigate(`/Employee/EmployeeMaster`) : window.location.reload()}
                                            imgSrc={imageData?.photo ? `data:image/png;base64,${imageData?.photo}` : 'http://in.payplushrms.com/ImageHandler.ashx?ID=10367&Type=Profile'}
                                        //isText={true}
                                        />
                                    </div>
                                    <div className='col-lg-11  col-md-10 row'>

                                        <InputBox
                                            inputrefs={inputrefs}
                                            disabled
                                            label='Employee Code'
                                            id='txtEmpCode'
                                            key='txtEmpCode'
                                            maxLength='50'
                                            placeholder="SYSTEM GENERATED"
                                            value={employee ? `${employee?.employee}` : recipe.EmpCode}
                                            onChange={(e) => { HandleChange('EmpCode', (e.target.value)) }}

                                        />
                                        <AutoCompleteBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtEmpName'].focus() };
                                            }}
                                            label='Company'
                                            placeholder="Company"
                                            maxLength='10'
                                            disabled
                                            options={CompanyData}
                                            id='txtCompany'
                                            key='txtCompany'
                                            /*disabled={EmployeeCodeByUrl}*/
                                            required={true} error={errors.Company}
                                            inputValue={recipe.Company ? recipe.Company : ''}
                                            onInputChange={(event, newInputValue) => {
                                                HandleChange('Company', newInputValue)
                                                CompanyAllData(newInputValue)

                                            }}
                                        />
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtDOB'].focus() };
                                            }}
                                            label='Employee Name'
                                            id='txtEmpName'
                                            key='txtEmpName'
                                            maxLength='50'
                                            placeholder="Employee Name"
                                            value={recipe.EmpName} error={errors.EmpName}
                                            onChange={(e) => { HandleChange('EmpName', (e.target.value)) }}
                                            required
                                        />
                                        <DateBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtGender'].focus() };
                                            }}
                                            label='DOB'
                                            id='txtDOB'
                                            key='txtDOB'
                                            selected={recipe.DOB === '' ? "" : new Date(recipe.DOB)}
                                            placeholder="DOB"
                                           // value={recipe.DOB}
                                            error={errors.DOB}
                                            onChange={(e) => HandleChange('DOB', dateFormate( e))}
                                            required
                                        />
                                        {/*<div className={`col-md-3 mb-1 cntrl-width`} key="txtGender">*/}
                                        {/*    <label className="form-label">Gender<span style={{ color: "red" }}> *</span>*/}
                                        {/*    </label>*/}
                                        {/*    <select*/}
                                        {/*        ref={ref => (inputrefs.current['txtGender'] = ref)}*/}
                                        {/*        className="form-control"*/}
                                        {/*        id="txtGender"*/}

                                        {/*        value={recipe.Gender}*/}
                                        {/*        error={recipe.Gender}*/}
                                        {/*        onKeyDown={(e) => {*/}
                                        {/*            if (e.key === 'Enter') { inputrefs.current['txtMobileNo'].focus() };*/}
                                        {/*        }}*/}
                                        {/*        onChange={(e) => HandleChange('Gender', e.target.value)}>*/}
                                        {/*        <option value="">--Select Gender--</option>*/}
                                        {/*        <option value="Male">Male</option>*/}
                                        {/*        <option value="Female">Female</option>*/}

                                        {/*    </select>*/}
                                        {/*    <div className="error-msg">{errors.Gender}</div>*/}
                                        {/*</div>*/}

                                        <SelectInputBox
                                            label='Gender'
                                            id='txtGender'
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtMobileNo'].focus() };
                                            }}
                                            inputrefs={inputrefs}
                                            error={errors.Gender}
                                            value={recipe.Gender}
                                            onChange={(e) => {
                                                HandleChange('Gender', e.target.value)
                                            }}
                                            children={<>
                                                <option value="">--Select Gender--</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </>}
                                        />
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtEmailID'].focus() };
                                            }}
                                            label='Mobile No.'
                                            id='txtMobileNo'
                                            key='txtMobileNo'
                                            maxLength='10'
                                            placeholder="Mobile No."
                                            required
                                            value={recipe.MobileNo} error={errors.MobileNo}
                                            onChange={(e) => { HandleChange('MobileNo', number(e.target.value)) }}
                                        />
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtAadhaarNo'].focus() };
                                            }}
                                            label='Email ID'
                                            id='txtEmailID'
                                            key='txtEmailID'
                                            maxLength='50'
                                            placeholder="Email ID"
                                            value={recipe.EmailID} error={errors.EmailID}
                                            onChange={(e) => { HandleChange('EmailID', (e.target.value)) }}
                                            required
                                        />
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtBloodGroup'].focus() };
                                            }}
                                            label='Aadhaar No'
                                            id='txtAadhaarNo'
                                            key='txtAadhaarNo'
                                            maxLength='12'
                                            placeholder="Aadhaar No"
                                            required
                                            value={recipe.AadhaarNo} error={errors.AadhaarNo}
                                            onChange={(e) => { HandleChange('AadhaarNo', number(e.target.value)) }}
                                        />
                                        {/*<div className={`col-md-3 mb-1 cntrl-width`} key="txtBloodGroup">*/}
                                        {/*    <label className="form-label">Blood Group </label>*/}
                                        {/*    <select ref={ref => (inputrefs.current['txtBloodGroup'] = ref)}*/}
                                        {/*        className="form-control"*/}
                                        {/*        maxLength='5'*/}
                                        {/*        id="txtBloodGroup"*/}
                                        {/*        value={recipe.BloodGroup}*/}
                                        {/*        //error={recipe.Blood Group}*/}
                                        {/*        onKeyDown={(e) => {*/}
                                        {/*            if (e.key === 'Enter') { inputrefs.current['txtEmergencyContactNo'].focus() };*/}
                                        {/*        }}*/}
                                        {/*        onChange={(e) => HandleChange('BloodGroup', e.target.value)}>*/}
                                        {/*        <option value="">--Select Blood Group--</option>*/}
                                        {/*        {BloodGroupData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}*/}

                                        {/*    </select>*/}
                                        {/*</div>*/}

                                        <SelectInputBox
                                            label='Blood Group'
                                            id="txtBloodGroup"
                                            value={recipe.BloodGroup}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtEmergencyContactNo'].focus() };
                                            }}
                                            inputrefs={inputrefs}
                                            onChange={(e) => {
                                                HandleChange('BloodGroup', e.target.value)
                                            }}
                                            children={<>
                                                <option value="">--Select Blood Group--</option>
                                                {BloodGroupData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}
                                            </>}
                                        />
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { if (e.key === 'Enter') { inputrefs.current['txtMaritalStatus'].focus() }; };
                                            }}
                                            label='Emergency Contact No.'
                                            id='txtEmergencyContactNo'
                                            key='txtEmergencyContactNo'
                                            maxLength='10'
                                            placeholder="Emergency Contact No."
                                            value={recipe.EmergencyContactNo}
                                            onChange={(e) => { HandleChange('EmergencyContactNo', number(e.target.value)) }}
                                        />



                                        {/*<div className={`col-md-3 mb-1 cntrl-width`} key="txtMaritalStatus">*/}
                                        {/*    <label className="form-label">Marital Status<span style={{ color: "red" }}> *</span>*/}
                                        {/*    </label>*/}
                                        {/*    <select ref={ref => (inputrefs.current['txtMaritalStatus'] = ref)}*/}
                                        {/*        className="form-control"*/}
                                        {/*        id="txtMaritalStatus"*/}
                                        {/*        value={recipe.MaritalStatus}*/}
                                        {/*        // error={recipe.MaritalStatus}*/}
                                        {/*        onKeyDown={(e) => {*/}
                                        {/*            if (e.key === 'Enter') { recipe.MaritalStatus === 'Married' ? inputrefs.current['txtSpouseName'].focus() : inputrefs.current['txtFatherName'].focus() };*/}
                                        {/*        }}*/}
                                        {/*        onChange={(e) => {*/}
                                        {/*            HandleChange('MaritalStatus', e.target.value);*/}
                                        {/*            if (e.target.value) setRecipe({ ...recipe, MaritalStatus: e.target.value, SpouseName: '', SpouseOccupation: '' })*/}
                                        {/*        }}>*/}
                                        {/*        <option value="">--Select Marital Status--</option>*/}
                                        {/*        <option value="Single">Single</option>*/}
                                        {/*        <option value="Married">Married</option>*/}
                                        {/*        <option value="Divorced">Divorced</option>*/}
                                        {/*    </select>*/}
                                        {/*    <div className="error-msg">{errors.MaritalStatus}</div>*/}
                                        {/*</div>*/}


                                        <SelectInputBox
                                            label='Marital Status'
                                            id='txtMaritalStatus'
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { recipe.MaritalStatus === 'Married' ? inputrefs.current['txtSpouseName'].focus() : inputrefs.current['txtFatherName'].focus() };
                                            }}
                                            required
                                            inputrefs={inputrefs}
                                            error={errors.MaritalStatus}
                                            value={recipe.MaritalStatus}
                                            onChange={(e) => {
                                                HandleChange('MaritalStatus', e.target.value);
                                                if (e.target.value) setRecipe({ ...recipe, MaritalStatus: e.target.value, SpouseName: '', SpouseOccupation: '' })
                                            }}
                                            children={<>
                                                <option value="">--Select Marital Status--</option>
                                                <option value="Single">Single</option>
                                                <option value="Married">Married</option>
                                                <option value="Divorced">Divorced</option>
                                            </>}
                                        />


                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtSpouseOccupation'].focus() };
                                            }}
                                            label='Spouse Name'
                                            placeholder='Spouse Name'
                                            id='txtSpouseName'
                                            key='txtSpouseName'
                                            maxLength='50'
                                            //placeholder="City"
                                            disabled={recipe.MaritalStatus !== 'Married'}
                                            value={recipe.SpouseName}
                                            error={errors.SpouseName}
                                            onChange={(e) => { HandleChange('SpouseName', (e.target.value)) }}
                                            required={recipe.MaritalStatus === 'Married'}
                                        />
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtFatherName'].focus() };
                                            }}
                                            label='Spouse Occupation'
                                            placeholder='Spouse Occupation'
                                            id='txtSpouseOccupation'
                                            key='txtSpouseOccupation'
                                            maxLength='50'
                                            //placeholder="City"
                                            disabled={recipe.MaritalStatus !== 'Married'}
                                            value={recipe.SpouseOccupation} error={errors.SpouseOccupation}
                                            onChange={(e) => { HandleChange('SpouseOccupation', (e.target.value)) }}
                                           // required={recipe.MaritalStatus === 'Married'}
                                        />

                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtFatherOccupation'].focus() };
                                            }}
                                            label={`Father's Name`}
                                            id='txtFatherName'
                                            key='txtFatherName'
                                            maxLength='50'
                                            placeholder={`Father's Name`}
                                            value={recipe.FatherName} error={errors.FatherName}
                                            onChange={(e) => { HandleChange('FatherName', (e.target.value)) }}
                                            required
                                        />
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtCorAddress1'].focus() };
                                            }}
                                            label={`Father's Occupation`}
                                            id='txtFatherOccupation'
                                            key='txtFatherOccupation'
                                            maxLength='50'
                                            placeholder={`Father's Occupation`}
                                            value={recipe.FatherOccupation}// error={errors.FatherOccupation}
                                            onChange={(e) => { HandleChange('FatherOccupation', (e.target.value)) }}
                                        //required
                                        />

                                    </div>



                                </div>
                            </fieldset>

                        </div>





                        <div className='row px-2'>

                            <fieldset className="myfieldset ">
                                <legend className="mylegend"><h6 className="m-0">Correspondence Address</h6></legend>
                                <div className='row'>

                                    <InputBox
                                        divclassname='col-md-2 mb-1 '
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtCorAddress2'].focus() };
                                        }}
                                        label='Address Line 1'
                                        id='txtCorAddress1'
                                        key='txtCorAddress1'
                                        maxLength='100'
                                        placeholder="Address Line 1"
                                        value={recipe.CorAddress1} error={errors.CorAddress1}
                                        onChange={(e) => {
                                            HandleChange('CorAddress1', (e.target.value))
                                            setIsPerAddressSame(false)
                                        }}
                                        required
                                    />
                                    <InputBox
                                        divclassname='col-md-2 mb-1 '
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtCorState'].focus() };
                                        }}
                                        label='Address Line 2'
                                        id='txtCorAddress2'
                                        key='txtCorAddress2'
                                        maxLength='100'
                                        placeholder="Address Line 2"
                                        value={recipe.CorAddress2}
                                        onChange={(e) => {
                                            HandleChange('CorAddress2', (e.target.value))
                                            setIsPerAddressSame(false)                                        }}
                                    />

                                    <AutoCompleteBox
                                        divclassname='col-md-2 mb-1 '
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtCorCity'].focus() };
                                        }}
                                        label='State'
                                        placeholder="State"
                                        maxLength='10'
                                        options={StateData}
                                        // options={[]}
                                        id='txtCorState'
                                        key='txtCorState'
                                        required={true} error={errors.CorState}
                                        inputValue={recipe.CorState ? recipe.CorState : ''}
                                        onInputChange={(event, newInputValue) => {
                                            HandleChange('CorState', newInputValue)
                                            StateAllData(newInputValue)
                                            setIsPerAddressSame(false)
                                        }}
                                    />
                                    <InputBox
                                        divclassname='col-md-2 mb-1 '
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtCorPinCode'].focus() };
                                        }}
                                        label='City'
                                        id='txtCorCity'
                                        key='txtCorCity'
                                        maxLength='50'
                                        placeholder="City"
                                        value={recipe.CorCity} error={errors.CorCity}
                                        onChange={(e) => {
                                            HandleChange('CorCity', (e.target.value))
                                            setIsPerAddressSame(false)                                        }} required
                                    />
                                    <InputBox
                                        divclassname='col-md-2 mb-1 '
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtCorPhoneNo'].focus() };
                                        }}
                                        label='Pin Code'
                                        id='txtCorPinCode'
                                        key='txtCorPinCode'
                                        maxLength='6'
                                        placeholder="Pin Code"
                                        value={recipe.CorPinCode} error={errors.CorPinCode}
                                        onChange={(e) => {
                                            HandleChange('CorPinCode', number(e.target.value))
                                            setIsPerAddressSame(false)                                        }} required
                                    />
                                    <InputBox
                                        divclassname='col-md-2 mb-1 '
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtCorAddressProof'].focus() };
                                        }}
                                        label='Phone No.'
                                        id='txtCorPhoneNo'
                                        key='txtCorPhoneNo'
                                        maxLength='10'
                                        placeholder="Phone No."
                                        value={recipe.CorPhoneNo}
                                        onChange={(e) => {
                                            HandleChange('CorPhoneNo', number(e.target.value))
                                            setIsPerAddressSame(false)                                        }}
                                    />

                                    {/*<AutoCompleteBox*/}
                                    {/*    inputrefs={inputrefs}*/}
                                    {/*    onKeyDown={(e) => {*/}
                                    {/*        if (e.key === 'Enter') { inputrefs.current['txtCorProofDocNo'].focus() };*/}
                                    {/*    }}*/}
                                    {/*    label='Address Proof' placeholder="Address Proof" maxLength='50' options={AddressProofDocument} id='txtCorAddressProof'*/}
                                    {/*    required={true} inputValue={recipe.CorAddressProof ? recipe.CorAddressProof : ''}*/}
                                    {/*    onInputChange={(event, newInputValue) => {*/}
                                    {/*        HandleChange('CorAddressProof', newInputValue)*/}

                                    {/*    }}*/}
                                    {/*/>*/}

                                    {/*<div className={`col-md-3 mb-1 cntrl-width`} key="txtCorAddressProof">*/}
                                    {/*    <label className="form-label">Address Proof<span style={{ color: "red" }}> *</span></label>*/}
                                    {/*    <select ref={ref => (inputrefs.current['txtCorAddressProof'] = ref)}*/}
                                    {/*        className="form-control"*/}
                                    {/*        maxLength='18'*/}
                                    {/*        id="txtCorAddressProof"*/}
                                    {/*        // key="txtCorAddressProof"*/}
                                    {/*        value={recipe.CorAddressProof}*/}
                                    {/*        error={recipe.CorAddressProof}*/}
                                    {/*        onKeyDown={(e) => {*/}
                                    {/*            if (e.key === 'Enter') { inputrefs.current['txtCorProofDocNo'].focus() };*/}
                                    {/*        }}*/}
                                    {/*        onChange={(e) => {*/}
                                    {/*            HandleChange('CorAddressProof', e.target.value)*/}
                                    {/*            setIsPerAddressSame(false)                                            }}>*/}
                                    {/*        <option value="">--Select Address Proof--</option>*/}
                                    {/*        {addressProofDocument?.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}*/}

                                    {/*    </select>*/}
                                    {/*    <div className="error-msg">{errors.CorAddressProof}</div>*/}
                                    {/*</div>*/}


                                    {/*<InputBox*/}
                                    {/*    inputrefs={inputrefs}*/}
                                    {/*    onKeyDown={(e) => {*/}
                                    {/*        if (e.key === 'Enter') { inputrefs.current['txtcheckSameAddress'].focus() };*/}
                                    {/*    }}*/}
                                    {/*    label='Proof Doc No'*/}
                                    {/*    id='txtCorProofDocNo'*/}
                                    {/*    key='txtCorProofDocNo'*/}
                                    {/*    maxLength='20'*/}
                                    {/*    placeholder="Proof Doc No"*/}
                                    {/*    value={recipe.CorProofDocNo}*/}
                                    {/*    error={errors.CorProofDocNo}*/}
                                    {/*    onChange={(e) => {*/}
                                    {/*        HandleChange('CorProofDocNo', (e.target.value))*/}
                                    {/*        setIsPerAddressSame(false)*/}
                                    {/*    }}*/}
                                    {/*    required*/}
                                    {/*/>*/}
                                </div>
                            </fieldset>
                           
                            {/*{!isPerAddressSame ?*/}
                            <fieldset className="myfieldset ">
                                <legend className="mylegend"> <span><h6 className='d-inline'>Permanent Address</h6> | <input type='checkbox' id="txtcheckSameAddress" ref={ref => (inputrefs.current['txtcheckSameAddress'] = ref)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { isPerAddressSame ? handleNext() : inputrefs.current['txtPerAddress1'].focus() };
                                    }} checked={isPerAddressSame} onChange={(e) => fillCorres(e.target.checked)} /> Same As Correspondence Address </span></legend>
                                <div className='row' >
                                    {/*<div className='row' style={isPerAddressSame ? { display: 'none' } : {}}>*/}

                                    <InputBox
                                        divclassname='col-md-2 mb-1 '
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtPerAddress2'].focus() };
                                        }}
                                        label='Address Line 1'
                                        id='txtPerAddress1'
                                        key='txtPerAddress1'
                                        maxLength='100'
                                        placeholder="Address Line 1"
                                        //value={recipe.PerAddress1}
                                        error={errors.PerAddress1}
                                        value={isPerAddressSame ? recipe.CorAddress1 : recipe.PerAddress1}
                                        // disabled={isPerAddressSame ? true: false}
                                        onChange={(e) => { HandleChange('PerAddress1', (e.target.value)) }} required
                                    />
                                    <InputBox
                                        divclassname='col-md-2 mb-1 '
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtPerState'].focus() };
                                        }}
                                        label='Address Line 2'
                                        id='txtPerAddress2'
                                        key='txtPerAddress2'
                                        maxLength='100'
                                        placeholder="Address Line 2"
                                        //  value={recipe.PerAddress2}
                                        value={isPerAddressSame ? recipe.CorAddress2 : recipe.PerAddress2}
                                        // disabled={isPerAddressSame ? true: false}
                                        onChange={(e) => { HandleChange('PerAddress2', (e.target.value)) }}
                                    />

                                    <AutoCompleteBox
                                        divclassname='col-md-2 mb-1 '
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtPerCity'].focus() };
                                        }}
                                        label='State'
                                        placeholder="State"
                                        maxLength='10'
                                        //options={[]}
                                        options={StateData}
                                        id='txtPerState'
                                        key='txtPerState'
                                        error={errors.PerState}
                                        required={true}// inputValue={recipe.PerState ? recipe.PerState : ''}
                                        inputValue={!isPerAddressSame ? recipe.PerState : recipe.CorState}
                                        // disabled={isPerAddressSame ? true: false}
                                        onInputChange={(event, newInputValue) => {
                                            HandleChange('PerState', newInputValue)
                                            setStatePre(newInputValue)
                                            StateAllData(newInputValue)
                                        }}
                                    />
                                    <InputBox
                                        divclassname='col-md-2 mb-1 '
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtPerPinCode'].focus() };
                                        }}
                                        label='City'
                                        id='txtPerCity'
                                        key='txtPerCity'
                                        maxLength='50'
                                        placeholder="City"
                                        // value={recipe.PerCity}
                                        error={errors.PerCity}
                                        value={isPerAddressSame ? recipe.CorCity : recipe.PerCity}
                                        // disabled={isPerAddressSame ? true: false}
                                        onChange={(e) => { HandleChange('PerCity', (e.target.value)) }} required
                                    />
                                    <InputBox
                                        divclassname='col-md-2 mb-1 '
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtPerPhoneNo'].focus() };
                                        }}
                                        label='Pin Code'
                                        id='txtPerPinCode'
                                        key='txtPerPinCode'
                                        maxLength='6'
                                        placeholder="Pin Code"
                                        //  value={recipe.PerPinCode}
                                        error={errors.PerPinCode}
                                        value={isPerAddressSame ? recipe.CorPinCode : recipe.PerPinCode}
                                        // disabled={isPerAddressSame ? true: false}
                                        onChange={(e) => { HandleChange('PerPinCode', number(e.target.value)) }} required
                                    />
                                    <InputBox
                                        divclassname='col-md-2 mb-1 '
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { handleNext() };
                                        }}
                                        label='Phone No.'
                                        id='txtPerPhoneNo'
                                        key='txtPerPhoneNo'
                                        maxLength='10'
                                        placeholder="Phone No."
                                        // value={recipe.PerPhoneNo}
                                        value={isPerAddressSame ? recipe.CorPhoneNo : recipe.PerPhoneNo}
                                        // disabled={isPerAddressSame ? true: false}
                                        onChange={(e) => { HandleChange('PerPhoneNo', number(e.target.value)) }}
                                    />

                                    {/*<div className={`col-md-3 mb-1 cntrl-width`} key="txtPerAddressProof">*/}
                                    {/*    <label className="form-label">Address Proof<span style={{ color: "red" }}> *</span></label>*/}
                                    {/*    <select*/}
                                    {/*        ref={ref => (inputrefs.current['txtPerAddressProof'] = ref)}*/}
                                    {/*        className="form-control"*/}
                                    {/*        maxLength='18'*/}
                                    {/*        id="txtPerAddressProof"*/}
                                    {/*        //key="txtPerAddressProof"*/}
                                    {/*        // value={recipe.PerAddressProof}*/}
                                    {/*        value={isPerAddressSame ? recipe.CorAddressProof : recipe.PerAddressProof}*/}
                                    {/*        error={recipe.PerAddressProof}*/}
                                    {/*        onKeyDown={(e) => {*/}
                                    {/*            if (e.key === 'Enter') { inputrefs.current['txtPerProofDocNo'].focus() };*/}
                                    {/*        }}*/}
                                    {/*        onChange={(e) => HandleChange('PerAddressProof', e.target.value)}>*/}
                                    {/*        <option value="">--Select Address Proof--</option>*/}
                                    {/*        {addressProofDocument?.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}*/}

                                    {/*    </select>*/}
                                    {/*    <div className="error-msg">{errors.PerAddressProof}</div>*/}
                                    {/*</div>*/}
                                    {/*<InputBox*/}
                                    {/*    inputrefs={inputrefs}*/}
                                    {/*    onKeyDown={(e) => {*/}
                                    {/*        if (e.key === 'Enter') { handleNext() };*/}
                                    {/*    }}*/}
                                    {/*    label='Proof Doc No'*/}
                                    {/*    id='txtPerProofDocNo'*/}
                                    {/*    key='txtPerProofDocNo'*/}
                                    {/*    maxLength='20'*/}
                                    {/*    placeholder="Proof Doc No"*/}
                                    {/*    error={errors.PerProofDocNo}*/}
                                    {/*    //value={recipe.PerProofDocNo}*/}
                                    {/*    value={isPerAddressSame ? recipe.CorProofDocNo : recipe.PerProofDocNo}*/}
                                    {/*    // disabled={isPerAddressSame ? true: false}*/}
                                    {/*    onChange={(e) => { HandleChange('PerProofDocNo', (e.target.value)) }}*/}
                                    {/*    required*/}
                                    {/*/>*/}
                                </div>
                            </fieldset>
                            {/*: <></>}*/}

                        </div>





                        <FormButton
                            handleNext={handleNext}
                            save={false}
                            Export={false}
                            Next={true}
                            nextButtonColor='btn-primary'
                            handleClear={handleClear}
                        />
                    </div>

                </div>

            </div >

        </>
    )
}

export default EmployeePersonalDetails
