
import { useEffect, useRef, useState } from "react";
import Pagination from '@mui/material/Pagination';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getSearchingColumn } from "../../apiservices/CommonServices";
import { ColorRing } from 'react-loader-spinner'
import { confirmAlert } from 'react-confirm-alert'
import { DateBox, TimeBox } from "./InputBox";
import { dateFormate } from "../Common/Common";

export const Table = ({
    Token, PageName,
    Action = true, totalRecordText = 'Total Records : ',
    isOverFlow = false, noRecordText = 'No Record Found',
    tableData, currentPageNo, openModal, handleChange1,
    handleCheckData, handleSorting, handlePrintPdfData,
    handleViewDetails, sortConfig, filterData, setFilterData,
    handleFilter, Filter = true, AdvancedFilter = true,
    handleEdit, handleDelete, handleCancel, HandleShowDoc, handleRedirect, pathName, headingText, showTotalRecord = true }) => {

    const inputrefs = useRef([]);
    const AdvanceSearchModelcloseButtonRef = useRef(null);
    const AdvanceSearchModelInputRef = useRef([]);
    const [ModalData, setModalData] = useState([]);
    const [MainData, setMainData] = useState(tableData?.Data ? tableData?.Data : []);
    const [ModalTitleKey, setModalTitleKey] = useState([]);
    const [ModalHeaderKey, setModalHaderKey] = useState([]);
    const [AdvanceModelCleared, setAdvanceModelCleared] = useState(true);
    const [serchingData, setSerchingData] = useState([]);
    const [advanceSearchingData, setAdvanceSearchingData] = useState([]);

    const handleDeleteData = (UserID) => {
        confirmAlert(

            {
                clssName: "react-confirm-alert-body ",
                message: 'Do you want to Delete this Record ?',
                buttons: [
                    {
                        label: 'Yes',
                        className: 'bg-success',
                        onClick: () => handleDelete(UserID)
                    },
                    {
                        label: 'No',
                        className: 'bg-danger',
                        onClick: () => setColoredRow(null)
                    }
                ]
            });
    }
    const handleCancelData = (UserID) => {
        confirmAlert(

            {
                clssName: "react-confirm-alert-body ",
                message: 'Do you want to Cancel this Leave Application?',
                buttons: [
                    {
                        label: 'Yes',
                        className: 'bg-success',
                        onClick: () => handleCancel(UserID)
                    },
                    {
                        label: 'No',
                        className: 'bg-danger',
                        onClick: () => setColoredRow(null)
                    }
                ]
            });
    }

    const ShowEyeRecords = (data, key) => {
        setModalData(data)
        setModalTitleKey(key)
        setModalHaderKey(Object?.keys(data[0]))
        console.log(data, key)
    };

    const finalHeaderKeys = tableData?.HeadersValue?.filter(
        (key, index) => {
            return !tableData?.HideColumns.find((column) => key === column)
        }
    );

    const finalHeadersValue = tableData?.HeadersKey?.filter(
        (key) => {
            return !tableData?.HideColumns.find((column) => key === column)
        }
    );

    const [ColoredRow, setColoredRow] = useState('');
    const handleHighlight = (id) => {
        setColoredRow(id)
    };

    const handleResetAdvanceFilter = () => {
        let updatedDataList = advanceSearchingData?.map((item, index) => ({
            ...item,
            Value: ''
        }));
        setAdvanceSearchingData(updatedDataList);
        setFilterData({ ...filterData, AdvanceFilter: [] });
        let iscleared = AdvanceModelCleared ? false : true;
        setAdvanceModelCleared(iscleared)

    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Enter') {
            e.preventDefault();

            if (advanceSearchingData?.length > index + 1) {
                AdvanceSearchModelInputRef.current[index + 1].focus();
            } else {
                AdvanceSearchModelInputRef.current['btnAdvSubmit'].focus();
            }
        }
    };

    const serching = async () => {
        if (Filter) {
            getSearchingColumn({ ...Token, PageName }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        console.log("status", res.status)
                    } else {
                        let data = res
                        setSerchingData(data.dataList)
                        //setAdvanceSearchingData(data.advanceDataList)
                        setFilterData({ ...filterData, SearchFieldValue: data.dataList[0].valuePart })
                    }
                }
            });
        }
    };

    const handleShowFile = () => {

    }

    const countNo = Math.ceil((parseInt(tableData?.TotalRecord) / parseInt(tableData?.PageSize)));

    useEffect(() => {
        serching();
    }, []);

    useEffect(() => {
        setMainData(tableData?.Data)
    }, [tableData]);

    useEffect(() => {
        if (handleFilter) handleFilter();
    }, [AdvanceModelCleared]);


    return (

        <>
            {headingText && <h6 className='mb-1 ms-0 ps-0 '>{headingText}</h6>}
            {
            tableData ? (
                <div className='main-card card border-0' >
                    <div className='card-header mb-1'>
                        <div className='row justify-content-between align-items-center'>
                                {showTotalRecord ?
                                    <div className={` ${pathName === 'ConvertToEmployee' ? 'd-flex justify-content-between' : 'col-md-4'}`}>

                                    <h6 className='m-0'>{`${tableData?.TotalRecord !== undefined ? totalRecordText === 'Total Records : ' ? totalRecordText + tableData?.TotalRecord : totalRecordText : noRecordText}`}</h6>
                                    {pathName === 'ConvertToEmployee' &&
                                        <h6 className='m-0 text-danger me-2 d-none'>{`${tableData?.Data !== undefined ? (`Pending for Verification : ${tableData?.Data[0]?.PendingforVerification}`) : 'Pending for Verification : 0'}`}</h6>
                                    }
                                    </div>
                                    : <></>}
                            {Filter ?
                                <div className='col-md-8 col-lg-6 d-flex flex-row filters'>
                                    <select
                                        value={filterData?.SearchFieldValue}
                                        onChange={(e) => setFilterData({ ...filterData, SearchFieldValue: e.target.value })}
                                        className="form-select me-2"
                                        aria-label="Default select example">
                                        {Array.isArray(serchingData) ? (
                                            serchingData?.map((list, i) => (
                                                <option key={`filterList${i}`} value={list?.valuePart}> {list?.textPart}</option>
                                            ))
                                        ) : (<></>)}
                                    </select>
                                    <select className="form-select me-2" aria-label="Condition"
                                        value={filterData?.StartValue} onChange={(e) => setFilterData({ ...filterData, StartValue: e.target.value })}
                                    >
                                        <option value="1">Exactly</option>
                                        <option value="2">Contains</option>
                                        <option value="3">Start With</option>
                                    </select>
                                    <input type="text"
                                        className="form-control search"
                                        placeholder="Search"
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleFilter()
                                            };
                                        }}
                                        value={filterData.SearchText}
                                        onChange={(e) => setFilterData({ ...filterData, SearchText: e.target.value })}
                                    />
                                    <button onClick={handleFilter} className="btn search-btn me-2"><i className="fa-solid fa-magnifying-glass"></i></button>

                                    {
                                        advanceSearchingData?.length !== 0 && (<button className='btn advance-search-btn' data-bs-toggle="modal" data-bs-target="#AdvanceSearchModal" >
                                            <i className="fa-solid fa-filter"></i>
                                        </button>)
                                    }

                                    {
                                        filterData?.PageSize ?
                                            <select className="form-select me-2" aria-label="PageSize" style={{ width: "110px" }}
                                                value={filterData?.PageSize ? filterData?.PageSize : "10"} onChange={(e) => {
                                                    setFilterData({ ...filterData, PageSize: e.target.value })
                                                    let iscleared = AdvanceModelCleared ? false : true;
                                                    setAdvanceModelCleared(iscleared)
                                                }}
                                            >
                                                <option value="1">1</option>
                                                <option value="10">10</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="200">200</option>
                                                <option value="500">500</option>
                                            </select> : <></>
                                    }
                                </div>
                                : <></>}
                        </div>
                    </div>

                    <div className='card-body'>
                        <div className={`table-responsive`} style={isOverFlow ? { maxHeight: '200px', overflow: 'auto' } : {}}>
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr className='light-bg'>
                                        {Action && (tableData?.Actions ? (
                                            <th style={{ width: '10px' }} key="thActions">Action</th>
                                        ) : (
                                            <></>
                                        ))}
                                        {finalHeaderKeys?.map((th, headIndex) => (
                                            <th key={`th${headIndex || 0}`} style={{ cursor: 'pointer' }}
                                                onClick={() =>
                                                    handleSorting ?
                                                        handleSorting(finalHeadersValue[headIndex]) : ''}
                                            >
                                                {th}
                                                {Filter ?
                                                    (sortConfig?.SortColumn !== null
                                                        && sortConfig?.SortOrder !== null
                                                        && sortConfig?.SortColumn === finalHeadersValue[headIndex]) ?
                                                        (
                                                            <>
                                                                <i className="fa fa-arrow-up ms-2" style={{ color: sortConfig?.SortOrder !== 'DESC' ? '#d4d4d4' : 'red' }}></i>
                                                                <i className="fa fa-arrow-down" style={{ color: sortConfig?.SortOrder !== 'ASC' ? '#d4d4d4' : 'red' }}  ></i>
                                                            </>
                                                        )
                                                        : (
                                                            <>
                                                                <i className="fa fa-arrow-up ms-2" style={{ color: '#d4d4d4' }}></i>
                                                                <i className="fa fa-arrow-down" style={{ color: '#d4d4d4' }}  ></i>
                                                            </>
                                                        )
                                                    : ''}

                                            </th> || <></>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {MainData?.map((item, bodyIndex) => (
                                        <tr className="TableTrhover" key={`row${bodyIndex}`} onClick={() => handleHighlight(`row${bodyIndex}`)} style={{ background: `row${bodyIndex}` === ColoredRow ? '#fee77f' : '' }} >
                                            {Action && (tableData?.Actions ? (
                                                <td key={`tdAction${bodyIndex}`}>
                                                    {tableData.Actions?.map((btn, index) => (
                                                        <span key={index}>
                                                            {btn === 'Edit' ? <i title="Edit" className="fa-regular fa-pen-to-square text-success me-2" style={{ cursor: 'pointer' }} key={`row${bodyIndex}edit${index}`} onClick={() => handleEdit(item.Edit, item)} /> : (<></>)}
                                                            {btn === 'Cancel' ? <button className="btn btn-sm btn-primary border rounded p-0 m-0" style={{ minWidth: '3.5rem' }} key={`row${bodyIndex}cancel${index}`} onClick={() => handleCancelData(item.Cancel, item)} >{btn}</button> : (<></>)}
                                                            {btn === 'Del' ? <i title="Delete" className="fa-regular fa-trash-can text-danger me-2" style={{ cursor: 'pointer' }} key={`row${bodyIndex}del${index}`} onClick={() => handleDeleteData(item.Del)} /> : (<></>)}
                                                            {btn === 'CheckBox' ? <input type='checkbox' className="fa-regular text-danger me-2" style={{ cursor: 'pointer' }} id={`row${item.CheckBox}checkBox`} key={`row${bodyIndex}checkBox${index}`} onClick={(e) => handleCheckData(item.CheckBox, e.target.checked, item)} /> : (<></>)}
                                                            {btn === 'Print' ? <i title="Print" className="fa fa-file-pdf text-primary" style={{ cursor: 'pointer' }} key={`row${bodyIndex}print${index}`} onClick={() => handlePrintPdfData(item.Print)} /> : (<></>)}
                                                            {btn === 'View' ? <i title="View" className="fa fa-info-circle text-primary me-2" data-bs-toggle="modal" data-bs-target={`#${openModal}`} style={{ cursor: 'pointer' }} key={`row${bodyIndex}view${index}`} onClick={() => handleViewDetails(item.View)} /> : (<></>)}
                                                        </span>
                                                    ))}
                                                </td>
                                            ) : (
                                                <></>
                                            ))}
                                            {finalHeadersValue?.map((key, tdIndex) => (
                                                (key === 'FrontSideDoc') ?
                                                    <td className='' key={`key${tdIndex}FrontSideDoc`}>
                                                        {item[key] !== "" ? (item.FrontSideDocExt === 'jpg') || (item.FrontSideDocExt === 'png') ?
                                                            <i className="fa fa-image text-primary" title='View'
                                                                // data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                style={{ cursor: 'pointer', }}
                                                                onClick={() => HandleShowDoc(item[key], key, item)}
                                                            />
                                                            : (item.FrontSideDocExt === 'pdf') && <i className="fa fa-file-pdf text-danger" title='View'
                                                                // data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                style={{ cursor: 'pointer', color: '#5a8dee' }}
                                                                onClick={() => HandleShowDoc(item[key], key, item)}
                                                            />
                                                            : < i className="fa fa-eye" title='View'
                                                                style={{ cursor: 'pointer', color: '#5a8dee' }}
                                                                onClick={() => HandleShowDoc(item[key], key, item)}
                                                            />}
                                                            
                                                    </td>
                                                    : key === 'BackSideDoc' ? <td className='' key={`key${tdIndex}BackSideDoc`}>
                                                        {item[key] !== "" ? (item.BackSideDocExt === 'jpg') || (item.BackSideDocExt === 'png') ?
                                                            <i className="fa fa-image text-primary" title='View'
                                                                // data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                style={{ cursor: 'pointer', }}
                                                                onClick={() => HandleShowDoc(item[key], key, item)}
                                                            />
                                                            : (item.BackSideDocExt === 'pdf') && <i className="fa fa-file-pdf text-danger" title='View'
                                                                style={{ cursor: 'pointer', color: '#5a8dee' }}
                                                                onClick={() => HandleShowDoc(item[key], key, item)}
                                                            />
                                                            : <>< i className="fa fa-eye d-none" title='View'
                                                                style={{ cursor: 'pointer', color: '#5a8dee' }}
                                                                onClick={() => HandleShowDoc(item[key], key, item)}
                                                            /></>}
                                                    </td>
                                                    : key === 'CandidateCode' && pathName === 'VerifyCandidate' ?
                                                    <td className="link-primary text-decoration-underline " style={{ cursor: 'pointer' }} key={`key${tdIndex}`} onClick={() => handleRedirect(item[key])}> {item[key]}</td>
                                                    : key === 'DOJ' && pathName === 'ConvertToEmployee' ? 
                                                    <td className="m-0 p-0 " style={{ cursor: 'pointer', minWidth: '7rem' }} key={`key${tdIndex}`}>
                                                        <DateBox
                                                            inputrefs={inputrefs}
                                                            divclassname='w-100'
                                                            id={`key${tdIndex}tableDOJ`}
                                                            selected={new Date(item[key])}
                                                            onChange={(e) => {
                                                                const data = [...MainData]
                                                                document.getElementById(`row${item.CheckBox}checkBox`).checked = false;
                                                                data[bodyIndex] = { ...data[bodyIndex], [key]: dateFormate(e) }
                                                                setMainData(data)
                                                                handleCheckData(item.CheckBox, false, item)
                                                                //console.log(item)
                                                            }}
                                                        />
                                                                </td>
                                                                : key === 'ActualInTime' && pathName === 'VerifyAttendance' ?
                                                                    <td className="m-0 p-0 " style={{ width: '30px', height: '20px',cursor: 'pointer', minWidth: '7rem' }} key={`key${tdIndex}`}>
                                                                        <TimeBox
                                                                            inputrefs={inputrefs}
                                                                            style={{ width: '100px', height: '30px' }}
                                                                            divclassname='col-md-1 '
                                                                            customclass="RemoveLabel"
                                                                            id={`key${tdIndex}tableActualINTime`}
                                                                            //selected={new Date(item[key])}

                                                                            //selected={(item[key])}
                                                                            value={(item[key])}
                                                                            onChange={(e) => {
                                                                                const data = [...MainData]
                                                                                document.getElementById(`row${item.CheckBox}checkBox`).checked = false;
                                                                                data[bodyIndex] = { ...data[bodyIndex], [key]: (e.target.value) }
                                                                                setMainData(data)
                                                                                handleCheckData(item.CheckBox, false, item, (item['ActualOutTime']))
                                                                                console.log(item)
                                                                                
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    : key === 'ActualOutTime' && pathName === 'VerifyAttendance' ?
                                                                        <td className="m-0 p-0 " style={{ width: '30px', height:'20px', cursor: 'pointer', minWidth: '7rem' }} key={`key${tdIndex}`}>
                                                                            <TimeBox
                                                                                inputrefs={inputrefs}
                                                                                style={{ width: '100px', height: '30px' }}
                                                                                divclassname='col-md-1 '
                                                                                customclass="RemoveLabel"
                                                                                id={`key${tdIndex}tableActualINTime`}
                                                                                //selected={(item[key])}
                                                                                value={(item[key])}
                                                                                onChange={(e) => {
                                                                                    const data = [...MainData]
                                                                                    document.getElementById(`row${item.CheckBox}checkBox`).checked = false;
                                                                                    data[bodyIndex] = { ...data[bodyIndex], [key]: (e.target.value) }
                                                                                    setMainData(data)
                                                                                    handleCheckData(item.CheckBox, false, item['ActualInTime'], item)
                                                                                    console.log(item)
                                                                                }}
                                                                            />
                                                                        </td>

                                                      : <td key={`key${tdIndex}`}>{item[key]}</td>

                                            ))}

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {countNo > 1 ?
                            < Pagination className='pt-2' count={countNo} variant="outlined" page={currentPageNo} onChange={handleChange1} shape="rounded" />
                            : ''}
                    </div>

                    {AdvancedFilter ?
                        <>
                            <div className="modal fade" id="AdvanceSearchModal" tabIndex="-1" role="dialog" aria-labelledby="AdvanceSearchModalLabel" aria-hidden="true">
                                <div className='modal-dialog modal-lg modal-dialog-centered' style={{ width: '500px' }}>
                                    <div className='modal-content main-card'>
                                        <div className="modal-header">
                                            <h5 className="modal-title">Advance Search</h5>
                                            <button type="button" ref={AdvanceSearchModelcloseButtonRef} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className='row'>

                                                {Array.isArray(advanceSearchingData) ? (
                                                    advanceSearchingData?.map((list, index) => (

                                                        <div key={`advancedFilterList${index}`} className='col-md-12 mb-2 d-flex justify-content-between align-items-center'>

                                                            <label className="col-md-4" style={{ textAlign: 'right' }} value={list?.valuePart}>{list?.textPart}</label>
                                                            <input type="text"
                                                                className="ps-1"
                                                                style={{ width: "20px", height: "20px" }}
                                                                placeholder={list?.operator}
                                                                value={list?.operator}
                                                                disabled />

                                                            <div className='col-md-7'>
                                                                {list.dataType === 'string' ?
                                                                    <input type="text"
                                                                        className="form-control"
                                                                        ref={ref => (AdvanceSearchModelInputRef.current[index] = ref)}
                                                                        onKeyDown={(e) => handleKeyDown(index, e)}
                                                                        placeholder={list?.textPart}
                                                                        value={list?.Value ? list?.Value : ''}
                                                                        onChange={(e) => {

                                                                            const value = e.target.value;
                                                                            const columnName = list?.valuePart;
                                                                            const operator = list?.operator;

                                                                            advanceSearchingData[index] = { ...advanceSearchingData[index], Value: value };
                                                                            setAdvanceSearchingData(advanceSearchingData)

                                                                            const existingIndex = filterData?.AdvanceFilter?.findIndex(item => item.ColumnName === columnName && item.Operator === operator);

                                                                            if (value.trim() === '') {
                                                                                if (existingIndex !== -1) {
                                                                                    const updatedAdvList = [...filterData.AdvanceFilter];
                                                                                    updatedAdvList.splice(existingIndex, 1);
                                                                                    setFilterData({ ...filterData, AdvanceFilter: updatedAdvList });
                                                                                }
                                                                            } else {
                                                                                if (existingIndex !== -1) {
                                                                                    const updatedAdvList = [...filterData.AdvanceFilter];
                                                                                    updatedAdvList[existingIndex] = { ...updatedAdvList[existingIndex], ColumnValue: value };
                                                                                    setFilterData({ ...filterData, AdvanceFilter: updatedAdvList });
                                                                                } else {
                                                                                    const newAdvList = [...filterData.AdvanceFilter, { ColumnName: columnName, Operator: operator, ColumnValue: value }];
                                                                                    setFilterData({ ...filterData, AdvanceFilter: newAdvList });
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                    : <></>}
                                                                {list.dataType === 'bool' ?
                                                                    <input
                                                                        className=""
                                                                        type="checkbox"
                                                                        checked={list?.Value ? list?.Value : ''}
                                                                        onChange={(e) => {
                                                                            const value = e.target.checked;
                                                                            const columnName = list?.valuePart;
                                                                            const operator = list?.operator;
                                                                            advanceSearchingData[index] = { ...advanceSearchingData[index], Value: value };
                                                                            setAdvanceSearchingData(advanceSearchingData)

                                                                            const existingIndex = filterData?.AdvanceFilter?.findIndex(item => item.ColumnName === columnName && item.Operator === operator);

                                                                            if (value === false) {
                                                                                if (existingIndex !== -1) {
                                                                                    const updatedAdvList = [...filterData.AdvanceFilter];
                                                                                    updatedAdvList.splice(existingIndex, 1);
                                                                                    setFilterData({ ...filterData, AdvanceFilter: updatedAdvList });
                                                                                }
                                                                            } else {
                                                                                if (existingIndex !== -1) {
                                                                                    const updatedAdvList = [...filterData.AdvanceFilter];
                                                                                    updatedAdvList[existingIndex] = { ...updatedAdvList[existingIndex], ColumnValue: value };
                                                                                    setFilterData({ ...filterData, AdvanceFilter: updatedAdvList });
                                                                                } else {
                                                                                    const newAdvList = [...filterData.AdvanceFilter, { ColumnName: columnName, Operator: operator, ColumnValue: value }];
                                                                                    setFilterData({ ...filterData, AdvanceFilter: newAdvList });
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                    : <></>}
                                                                {list.dataType === 'datetime' ?
                                                                    <DatePicker
                                                                        //  selected={new Date()}
                                                                        selected={list.Value ? new Date(list.Value) : ''}
                                                                        className='form-control input-fields'
                                                                        dateFormat="dd-MMM-yyyy"
                                                                        placeholderText={list.textPart}
                                                                        inputrefs={inputrefs}
                                                                        onChange={(e) => {
                                                                            let dateArr = e?.toString()?.split(' ')
                                                                            dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`

                                                                            const columnName = list?.valuePart;

                                                                            const operator = list?.operator;

                                                                            advanceSearchingData[index] = { ...advanceSearchingData[index], Value: dateArr };
                                                                            /* console.log(advanceSearchingData[index],"jgs")*/
                                                                            setAdvanceSearchingData(advanceSearchingData)

                                                                            const existingIndex = filterData?.AdvanceFilter?.findIndex(item => item.ColumnName === columnName && item.Operator === operator);

                                                                            if (dateArr === '') {
                                                                                if (existingIndex !== -1) {
                                                                                    const updatedAdvList = [...filterData.AdvanceFilter];
                                                                                    updatedAdvList.splice(existingIndex, 1);
                                                                                    setFilterData({ ...filterData, AdvanceFilter: updatedAdvList });
                                                                                }
                                                                            } else {
                                                                                if (existingIndex !== -1) {
                                                                                    const updatedAdvList = [...filterData.AdvanceFilter];
                                                                                    updatedAdvList[existingIndex] = { ...updatedAdvList[existingIndex], ColumnValue: dateArr };
                                                                                    setFilterData({ ...filterData, AdvanceFilter: updatedAdvList });
                                                                                } else {
                                                                                    const newAdvList = [...filterData.AdvanceFilter, { ColumnName: columnName, Operator: operator, ColumnValue: dateArr }];
                                                                                    setFilterData({ ...filterData, AdvanceFilter: newAdvList });
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                    : <></>}
                                                            </div>
                                                        </div>
                                                    ))

                                                ) : (<></>)}
                                            </div>
                                        </div>
                                        <div className="modal-footer p-0">
                                            <button type="button"
                                                ref={ref => (AdvanceSearchModelInputRef.current['btnAdvSubmit'] = ref)}
                                                className="btn btn-rounded btn-outline-success" onClick={(e) => {
                                                    if (AdvanceSearchModelcloseButtonRef.current) {
                                                        AdvanceSearchModelcloseButtonRef.current.click();
                                                    }
                                                    handleFilter()
                                                }}><span className=" text-success me-2"><i className="fa-solid fa-hand-pointer"></i>
                                                </span>Submit</button>
                                            <button type="button" className="btn btn-rounded btn-outline-danger" onClick={() => handleResetAdvanceFilter}><span className=" text-danger me-2"><i className="fa-solid fa-refresh"></i>
                                            </span>Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        : <></>}

                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content" style={{ background: "mintcream" }}>
                                <div className="modal-header" style={{ padding: '7px 15px' }}>
                                    <h5 className="modal-title" id="exampleModalLabel">{ModalTitleKey ? ModalTitleKey : 'Show Records'}</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body table-responsive" style={{ maxHeight: '210px', padding: '8px' }}>
                                    <table className="table border text-center">
                                        <thead>
                                            <tr className='light-bg'>
                                                {ModalHeaderKey?.map((th, headIndex) => (
                                                    <>
                                                        <th key={`th${headIndex || 0}`} style={{ padding: '5px 10px', background: '#ffdcdc' }}>{th}</th>
                                                    </>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ModalData?.map((item, bodyIndex) => (
                                                <tr className="TableTrhover" key={`row${bodyIndex}`} >
                                                    {ModalHeaderKey?.map((key, tdIndex) => (
                                                        <>
                                                            <td key={`key${tdIndex}`}>{item[key]}</td>
                                                        </>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modal-footer" style={{ padding: '3px' }}>
                                    <button type="button" className="btn btn-secondary" style={{ margin: '5px', minWidth: '30px' }} data-bs-dismiss="modal">Close <i className='fa fa-close' /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

            ) : (<> </>)
        }

        </>
    )
}

