///<reference path="LoanTypeMaster.jsx" />
import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, AutoCompleteBoxWithLabel, CheckBoxInput } from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
//loder
import BlurLoader from "../Loder/BlurLoader";
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { Token, number } from '../Common/Common';

function LeaveStatus() {
	const inputrefs = useRef([]);
	const { user, sessionExpired } = useAuth();
	//token Data
	//const TokenData = {
	//	AuthToken: process.env.REACT_APP_SECRET_KEY,
	//	LoginToken: user.loginsessionID,
	//	UserID: user.userID,
	//	CompanyID: user.companyID
	//}
	const TokenData = Token()
	//initial state
	const [recipe, setRecipe] = useState({
		employee: '',
		
	})

	const [result, setResult] = useState({});
	const [loading, setLoading] = useState(false);
	const [currentPageNo, setCurrentPageNo] = useState(1);
	const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
	const [filterData, setFilterData] = useState(initializeFilterData);
	const [isUpdate, setIsUpdate] = useState(false);
	const [errors, setErrors] = useState({});
	const [ShowEdit, setShowEdit] = useState(false);
	const [employeeAllData, setEmployeeAllData] = useState([])
	//  const [EmpCode, setEmpCode] = useState()
	const [yearData, setYearData] = useState([]);
	const [monthData, setMonthData] = useState([]);

	//Get Employee Data for AutoComplete
	const GetEmployeeData = (value) => {
		setEmployeeAllData([])
		let temp = {
			Type: "GetEmployee",
			Prefix: value ? value : '',
			ContextKey: '',
			ContextKey2: ""
		}
		setLoading(true)
		getCommonData({ ...TokenData, ...temp }, (res) => {
			if (res && res.status) {
				if (res.status === "ERROR") {
					setLoading(false)
					setEmployeeAllData([])
				} else {
					setLoading(false)
					let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
					setEmployeeAllData(data)
				}
			}
		});
	}


	const YearData = () => {
		setYearData([])
		let temp = {
			Type: "GetYear",
			Prefix: '',

		}
		setLoading(true)
		getMasterData({ ...TokenData, ...temp }, (res) => {
			if (res && res.status) {
				if (res.status === "ERROR") {
					setLoading(false)
					setYearData([])
				} else {
					setLoading(false)
					let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
					setYearData(data)
				}
			}
		});
	}
	const MonthData = () => {
		setMonthData([])
		let temp = {
			Type: "GetMonth",
			Prefix: '',

		}
		setLoading(true)
		getMasterData({ ...TokenData, ...temp }, (res) => {
			if (res && res.status) {
				if (res.status === "ERROR") {
					setLoading(false)
					setMonthData([])
				} else {
					setLoading(false)
					let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
					setMonthData(data)
				}
			}
		});
	}


	const getFormData = (emp,CurrentPage) => {
		let PageIndex = 1
		if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
			PageIndex = CurrentPage;
			setCurrentPageNo(CurrentPage);
		}
		else {
			setCurrentPageNo(1);
		}

		setLoading(true)

		let temp = {
			PageIndex: PageIndex.toString(),
			SearchText: "",
			StartValue: "1",
			SearchFieldValue: "",
			Type: "s",
		}
		post(`${API_SERVER}api/Leave/GetLeaveStatusData`, { ...TokenData, ...temp, ...sortConfig, ...filterData, employee: emp ? emp : recipe.employee }, (res) => {
			if (res && res.Status) {
				if (res.Status === "ERROR") {
					setLoading(false)
					setResult({ ...res })
				} else if (res.Status === "EXPIRED") {
					toast.error(res.Message)
					sessionExpired()
				} else if (res.Status === "UNAUTHORIZED") {
					toast.error(res.Message);
					handleClear();
					setLoading(false);
				} else {
					setLoading(false)
					setResult({ ...res })
				}
			}
		});
	}

	// console.log(result);

	const handleChange1 = (event, value) => {
		getFormData(recipe.employee, value);
		//tableData(value)

	};

	useEffect(() => {
		//  inputrefs.current['txtEmployee'].focus();
		document.title = 'Pay Plus : Leave Status';
		//getFormData()
	    GetEmployeeData();
		// YearData();
		// MonthData();

	}, [sortConfig, isUpdate])


	//Change Handler
	const HandleChange = (prop, value) => {
		setRecipe(recipe => ({ ...recipe, [prop]: value }))
	}

	const Validate = () => {
		setErrors({})
		let isValid = true;
		let validation = [
			{ field: "employee", msg: 'Please Enter Employee.' },
			{ field: "repayAmount", msg: 'Please Enter Repay Amount.' },
			{ field: "payslipMonth", msg: 'Please Enter Payslip Month.' },
			{ field: "payslipYear", msg: 'Please Enter Payslip Year.' },
		]

		validation.map(item => {
			if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {

				setErrors(errors => ({ ...errors, [item.field]: item.msg }));
				isValid = false;
			}
			return item;
		});

		return isValid;
	}

	//Handle Edit
	const handleCancel = (Id) => {
		post(`${API_SERVER}api/Leave/UpdateLeaveStatus`, { ...TokenData,id:Id, employee: recipe.employee }, (res) => {
			
			if (res && res.status) {
				if (res.status === "ERROR") {
					toast.error(res.message)
					setLoading(false)
					//setResult({ ...res })
				} else if (res.Status === "EXPIRED") {
					toast.error(res.Message)
					sessionExpired()
				} else if (res.Status === "UNAUTHORIZED") {
					toast.error(res.Message);
					handleClear();
					setLoading(false);
				} else {
					toast.success(res.message)
					setLoading(false)
					getFormData()
					//setResult({ ...res })
				}
			}
		});
	}
	
	//Reset Handler
	const handleClear = () => {
		setRecipe(recipe => ({
			...recipe,
			employee: "",
		}))
		setErrors({})
		setFilterData(filterData => ({ ...filterData, SearchText: '' }))
		setIsUpdate(!isUpdate)
		getFormData()
		setShowEdit(false)
	}

	const handleSorting = (SortColumn) => {
		let SortOrder = 'ASC';

		if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
			SortOrder = 'DESC';
		}
		setSortConfig({ SortColumn, SortOrder });
	};

	let EmployeeData = []
	for (let i = 0; i < employeeAllData.length; i++) {
		let mydata = employeeAllData[i]?.values
		EmployeeData.push(mydata)
	};

	//Start Export functionality
	let ExportRequest = {
		...filterData,
		...TokenData,
		...sortConfig,
		Type: 'E',
		PageIndex: '1'
	}
	//End Export functionality

	return (
		<>
			<div className='main-card card border-0'>  
			<div className='card-header mb-1'>
				<h6 className="m-0">Leave Status</h6>
			</div>

			
                <div className='card-body'>
                    <div className='row'>


                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            // onKeyDown={(e) => {
                            //       if (e.key === 'Enter') { inputrefs.current['txtRepayAmount'].focus() };
                            //   }}
                            label='Employee'
                            placeholder="Employee"
                            //divclassname='col-lg-6  '
                            maxLength='10'
                            options={EmployeeData}
                            id='txtEmployee'
                            key='txtEmployee'
                            //disabled={ShowEdit}
                            required={true}
                            error={errors.employee}
                            inputValue={recipe.employee ? recipe.employee : ''}
                            onInputChange={(event, newInputValue) => {
                                GetEmployeeData(newInputValue)
								HandleChange('employee', newInputValue)
								getFormData(newInputValue)

                            }}
                        />

					</div>
                </div>
			</div>
			{recipe.employee &&
				<Table
				Filter={false}
				totalRecordText='Leave Status : '
				Token={TokenData}
				PageName='LeaveStatus'
				handleCancel={handleCancel}
				//handleDelete={handleDelete}
			//	handleFilter={() => {
			//		getFormData();
			//		setFilterData({
			//			...filterData,
			//		});
			//	}}
				tableData={result}
				loading={loading}
			//	filterData={filterData}
			//	setFilterData={setFilterData}
				currentPageNo={currentPageNo}
				handleChange1={handleChange1}
			//	handleSorting={handleSorting}
				//sortConfig={sortConfig}
			/>
	}
			{ loading && < BlurLoader />}
        </>
    )
}

export default LeaveStatus
