import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, DateBox, View, SelectInputBox } from "../commoncomponents/InputBox";
//import { FileUploader } from "react-drag-drop-files";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
import { useNavigate } from 'react-router-dom'

//loder
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { number, numberWithComma } from '../Common/Common';
import BlurLoader from '../Loder/BlurLoader';
import ProfilePicture from '../commoncomponents/ProfilePicture';

function PersonalDetails({ TokenData, setShowUploadImageModal, CandidateCode, activeTab, isPerAddressSame, setIsPerAddressSame, imageData, setRecipe, handleTabClick, recipe, dateOfBirth, setDateOfBirth }) {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();


    //initial state
    //const [recipe, setRecipe] = useState({
    //    CandidateID: "0",
    //    CandidateCode: "",
    //    CandidateName: "",
    //    FatherName: "",
    //    FatherOccupation: "",
    //    DOB: "",
    //    Gender: "",
    //    MaritalStatus: "",
    //    SpouseName: "",
    //    SpouseOccupation: "",
    //    EmailID: "",
    //    MobileNo: "",
    //    AadhaarNo: "",
    //    BloodGroup: "",
    //    EmergencyContactNo: "",
    //    CorAddress1: "",
    //    CorAddress2: "",
    //    CorState: "",
    //    CorCity: "",
    //    CorPinCode: "",
    //    CorPhoneNo: "",
    //    CorAddressProof: "",
    //    CorProofDocNo: "",
    //    PerAddress1: "",
    //    PerAddress2: "",
    //    PerState: "",
    //    PerCity: "",
    //    PerPinCode: "",
    //    PerPhoneNo: "",
    //    PerAddressProof: "",
    //    PerProofDocNo: "",
    //    AccountHolder: '',
    //    BankName: '',
    //    AccountNo: "",
    //    IFSCCode: "",
    //    PFNo: "",
    //    ESINo: '',

    //})
    const navigate = useNavigate()
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [isUpdate, setIsUpdate] = useState(false)
    const [countryPre, setCountryPre] = useState('')
    const [countryAllData, setCountryAllData] = useState([])
    const [statePre, setStatePre] = useState('')
    const [stateAllData, setStateAllData] = useState([])
    const [companyAllData, setCompanyAllData] = useState([])
    const [BloodGroupData, setBloodGroupData] = useState([])
    //const [dateOfBirth, setDateOfBirth] = useState();
    const [addressProofDocument, setAddressProofDocument] = useState();
    const [bankNameData, setBankNameData] = useState();
    //const [isPerAddressSame, setIsPerAddressSame] = useState(false);
    const [IFSCPrefix, setIFSCPrefix] = useState('');
    const [errors, setErrors] = useState({});
    const [isNameInBank, setIsNameInBank] = useState(true);

    const GetAddressProofDocument = () => {
        let temp = {
            Type: "AddressProof",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false);
                } else {
                    setLoading(false);
                    let data = res.dataList;
                    setAddressProofDocument(data);
                }
            }
        });
    }
    const GetBankNameData = (value) => {
        let temp = {
            Type: "GetBankName",
            Prefix: value ? value : "",
            ContextKey: "",
            ContextKey2: ""
        }
        // setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false);
                } else {
                    setLoading(false);
                    let data = res.dataList;
                    setBankNameData(data);
                }
            }
        });
    }

    const CompanyAllData = (value) => {
        setCompanyAllData([])
        let temp = {
            Type: "GetCompany",
            Prefix: value ? value : "",
            ContextKey: '',
            ContextKey2: ""
        }
        // setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setCompanyAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setCompanyAllData(data)
                }
            }
        });
    }

    const GetIFSCPrefix = (value) => {
        post(`${API_SERVER}api/AutoComplete/GetIFSCPrefix`, { ...TokenData, BankCode: value, BankName: value }, (res) => {
            if (res.status === 'SUCCESS') setRecipe({ ...recipe, IFSCCode: res.ifscPrefix, BankName: value });
            else if (res.status === 'ERROR') setRecipe({ ...recipe, IFSCCode: '', BankName: value });
            else if (res.Status === "EXPIRED") {
                toast.error(res.Message)
                sessionExpired()
            }
        })
    }
    const StateAllData = (value) => {
        setStateAllData([])
        let temp = {
            Type: "GetState",
            Prefix: value ? value : "",
            ContextKey: '',
            ContextKey2: ""
        }
        //setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setStateAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setStateAllData(data)
                }
            }
        });
    }

    const GetBloodGroupData = () => {
        setBloodGroupData([])
        let temp = {
            Type: "GetBloodGroup",
            Prefix: '',

        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setBloodGroupData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setBloodGroupData(data)
                }
            }
        });
    }
    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setLoading(true)
        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/MASTER/ShowCompanyMaster`, { ...TokenData, ...temp, ...sortConfig, ...filterData }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }

    const fillCorres = (value) => {
        if (value) {
            setRecipe({
                ...recipe,
                PerAddress1: recipe.CorAddress1,
                PerAddress2: recipe.CorAddress2,
                PerState: recipe.CorState,
                PerCity: recipe.CorCity,
                PerPinCode: recipe.CorPinCode,
                PerPhoneNo: recipe.CorPhoneNo,
                PerAddressProof: recipe.CorAddressProof,
                PerProofDocNo: recipe.CorProofDocNo,
            })
            setIsPerAddressSame(value)
        } else {
            setRecipe({
                ...recipe,
                PerAddress1: '',
                PerAddress2: '',
                PerState: '',
                PerCity: '',
                PerPinCode: '',
                PerPhoneNo: '',
                PerAddressProof: '',
                PerProofDocNo: '',
            })
            setIsPerAddressSame(value)
        }
        // setRecipe({ ...recipe, SameAsCorrespondence: isPerAddressSame })
    }
    //Change Handler
    const HandleChange = (prop, value) => {
        if (prop === 'DOB') {
            setDateOfBirth(value)
        }
        //else if (prop === 'IFSCCode') {
        //    value = IFSCPrefix;
        //    setRecipe({})
        //}

        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }
    const handleChange1 = (event, value) => {
        //  getFormData(value);
    };
    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "CandidateName", msg: 'Please Enter Candidate Name.' },
            { field: "FatherName", msg: "Please Enter Father's Name." },
            { field: "Company", msg: "Please Enter Company." },
            // { field: "FatherOccupation", msg: "Please Enter Father's Occupation." },
            { field: "DOB", msg: 'Please Enter DOB.' },
            { field: "Gender", msg: 'Please Select Gender.' },
            { field: "MaritalStatus", msg: 'Please Select Marital Status.' },
            { field: "EmailID", msg: 'Please Enter Email ID.' },
            { field: "MobileNo", msg: 'Please Enter Mobile No.' },
            { field: "AadhaarNo", msg: 'Please Enter Aadhaar No.' },
            { field: "CorAddress1", msg: 'Please Enter Address1.' },
            { field: "CorState", msg: 'Please Select State.' },
            { field: "CorCity", msg: 'Please Enter City.' },
            { field: "CorPinCode", msg: 'Please Enter Pin Code.' },
            { field: "CorAddressProof", msg: 'Please Select Address Proof.' },
            { field: "CorProofDocNo", msg: 'Please Select Address Proof No.' },

            { field: "PerAddress1", msg: 'Please Enter Address1.' },
            { field: "PerState", msg: 'Please Select State.' },
            { field: "PerCity", msg: 'Please Enter City.' },
            { field: "PerPinCode", msg: 'Please Enter Pin Code.' },
            { field: "PerAddressProof", msg: 'Please Select Address Proof.' },
            { field: "PerProofDocNo", msg: 'Please Select Address Proof No.' },
        ];

        if (recipe['MaritalStatus'] === 'Married') {
            validation.push(
                { field: "SpouseName", msg: ' Please Enter Spouse Name.' },
                //   { field: "SpouseOccupation", msg: 'Please Enter Spouse Occupation.' }
            )
        };
        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;

    }


    //Submit Handler
    const handleSubmit = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/Candidate/InsertUpdateCandidatePersonalDatail`, { ...TokenData, ...recipe, SameAsCorrespondence: isPerAddressSame }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        setLoading(false)
                        inputrefs.current[res.focus].focus();
                    } else if (res.status === "EXPIRED") {
                        toast.error(res.message)
                        sessionExpired()
                    } else if (res.status === "UNAUTHORIZED") {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        //handleClear()
                        // handleTabClick('QualificationDetails')
                    }
                }
            });
        }
        else {
            //   inputrefs.current[myString[1]].focus();
            //   toast.error(myString[0]);
        }
    }

    const handleNext = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/Candidate/InsertUpdateCandidatePersonalDatail`, { ...TokenData, ...recipe, SameAsCorrespondence: isPerAddressSame }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        inputrefs.current[res.focus].focus();
                        toast.error(res.message)
                        setLoading(false)
                    } else if (res.status === "EXPIRED") {
                        toast.error(res.message)
                        sessionExpired()
                    } else if (res.status === "UNAUTHORIZED") {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        handleTabClick('QualificationDetails')
                    }
                }
            });
        }
    }

    //Handle Edit



    //  console.log(persnaolDetails,'persnaolDetails')
    //Handle Delete
    const handleDelete = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/MASTER/DeleteCompanyMaster`, { ...TokenData, NewCompanyID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else {
                    toast.success(res.message)
                    setLoading(false)
                    //getFormData()
                }
            }
        });
    }

    //Reset Handler
    //const handleClear = () => {
    //    setRecipe(recipe => ({
    //        ...recipe, CandidateID: "0",
    //        CandidateCode: "",
    //        CandidateName: "",
    //        FatherName: "",
    //        FatherOccupation: "",
    //        DOB: "",
    //        Gender: "",
    //        MaritalStatus: "",
    //        SpouseName: "",
    //        SpouseOccupation: "",
    //        EmailID: "",
    //        MobileNo: "",
    //        AadhaarNo: "",
    //        BloodGroup: "",
    //        EmergencyContactNo: "",
    //        CorAddress1: "",
    //        CorAddress2: "",
    //        CorState: "",
    //        CorCity: "",
    //        CorPinCode: "",
    //        CorPhoneNo: "",
    //        CorAddressProof: "",
    //        CorProofDocNo: "",
    //        PerAddress1: "",
    //        PerAddress2: "",
    //        PerState: "",
    //        PerCity: "",
    //        PerPinCode: "",
    //        PerPhoneNo: "",
    //        PerAddressProof: "",
    //        PerProofDocNo: "",
    //        AccountHolder: '',
    //        BankName: '',
    //        AccountNo: "",
    //        IFSCCode: "",
    //        PFNo: "",
    //        ESINo: '',
    //    }))
    //    setFilterData(filterData => ({ ...filterData, SearchText: '' }))
    //    setIsUpdate(!isUpdate)
    //    getFormData()
    //}



    //-------------------Handle ReSet -----------//
    const handleClear = () => {
        navigate(-1)
    }

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality

    let AddressProofDocument = []
    for (let i = 0; i < addressProofDocument?.length; i++) {
        let mydata = addressProofDocument[i]?.name
        AddressProofDocument.push(mydata)
    };
    let BankNameData = []
    for (let i = 0; i < bankNameData?.length; i++) {
        let mydata = bankNameData[i]?.values
        BankNameData.push(mydata)
    };

    let StateData = []
    for (let i = 0; i < stateAllData.length; i++) {
        let mydata = stateAllData[i]?.values
        StateData.push(mydata)
    };
    let CompanyData = []
    for (let i = 0; i < companyAllData.length; i++) {
        let mydata = companyAllData[i]?.values
        CompanyData.push(mydata)
    };

    useEffect(() => {
        if (activeTab === 'PersonalDetails') {
            inputrefs.current['txtCompany'].focus();
            document.title = 'Pay Plus : Personal Details'
        }
    }, [activeTab])


    useEffect(() => {
     //   GetAddressProofDocument()
     //   StateAllData();
     //   CompanyAllData();
     //   GetBloodGroupData();
       // GetBankNameData();
        //   getFormData()

        //getFormData()
    }, [sortConfig, isUpdate])



    return (
        <>
            <div className='main-card card border-0 '>
                <div className='card-body'>
                    <div className='row ps-3 pt-2'>

                        <div className='row'>
                            <fieldset className="myfieldset">
                                <legend className="mylegend"><h6 className="m-0">Personal Details</h6></legend>
                                <div className='row flex-row-reverse justify-content-around'>
                                    <div className='col-lg-1 col-md-2 row'>
                                        <ProfilePicture
                                        //    openModal={() => setShowUploadImageModal(true)}
                                            imgSrc={imageData?.photo ? `data:image/png;base64,${imageData?.photo}` : 'http://in.payplushrms.com/ImageHandler.ashx?ID=10367&Type=Profile'}
                                            //isText={true}
                                            text='Update Photo'
                                        />
                                    </div>
                                    <div className='col-lg-11  col-md-10 row'>
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtCandidateName'].focus() };
                                            }}
                                            disabled
                                            label='Candidate Code' id='txtCandidateCode' key='txtCandidateCode' maxLength='20' placeholder="Candidate Code"
                                            value={recipe.CandidateCode} onChange={(e) => { HandleChange('CandidateCode', (e.target.value)) }} required
                                        />
                                        <AutoCompleteBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtCandidateName'].focus() };
                                            }}
                                            label='Company'
                                            placeholder="Company"
                                            maxLength='10'
                                            options={CompanyData}
                                            id='txtCompany'
                                            key='txtCompany'
                                            disabled={CandidateCode}
                                            required={true} error={errors.Company}
                                            inputValue={recipe.Company ? recipe.Company : ''}
                                            onInputChange={(event, newInputValue) => {
                                                HandleChange('Company', newInputValue)
                                            //    CompanyAllData(newInputValue)

                                            }}
                                        />
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtDOB'].focus() };
                                            }}
                                            label='Candidate Name'
                                            id='txtCandidateName'
                                            key='txtCandidateName'
                                            maxLength='100' placeholder="Candidate Name"
                                            value={recipe.CandidateName}
                                            error={errors.CandidateName}
                                            required
                                            onChange={(e) => { HandleChange('CandidateName', (e.target.value)) }}
                                        />
                                        <DateBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtGender'].focus() };
                                            }}
                                            label='DOB'
                                            id='txtDOB'
                                            key='txtDOB'
                                            maxLength='11'
                                            selected={dateOfBirth}
                                            placeholder="DOB"
                                            value={recipe.DOB}
                                            error={errors.DOB}
                                            onChange={(e) => HandleChange('DOB', e)}
                                            required
                                        />
                                        <SelectInputBox
                                            label='Gender'
                                            id='txtGender'
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtMobileNo'].focus() };
                                            }}
                                            inputrefs={inputrefs}
                                            error={errors.Gender}
                                            value={recipe.Gender}
                                            onChange={(e) => {
                                                HandleChange('Gender', e.target.value)
                                            }}
                                            children={<>
                                                <option value="">--Select Gender--</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </>}
                                        />

                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtEmailID'].focus() };
                                            }}
                                            label='Mobile No.'
                                            id='txtMobileNo'
                                            key='txtMobileNo'
                                            maxLength='10'
                                            placeholder="Mobile No."
                                            required
                                            value={recipe.MobileNo}
                                            error={errors.MobileNo}
                                            onChange={(e) => { HandleChange('MobileNo', number(e.target.value)) }}
                                        />

                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtAadhaarNo'].focus() };
                                            }}
                                            label='Email ID'
                                            id='txtEmailID'
                                            key='txtEmailID'
                                            maxLength='60'
                                            placeholder="Email ID"
                                            required
                                            value={recipe.EmailID}
                                            error={errors.EmailID}
                                            onChange={(e) => { HandleChange('EmailID', (e.target.value)) }}
                                        />


                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtBloodGroup'].focus() };
                                            }}
                                            label='Aadhaar No'
                                            id='txtAadhaarNo'
                                            key='txtAadhaarNo'
                                            maxLength='12'
                                            placeholder="Aadhaar No"
                                            required
                                            value={recipe.AadhaarNo}
                                            error={errors.AadhaarNo}
                                            onChange={(e) => { HandleChange('AadhaarNo', number(e.target.value)) }}
                                        />

                                        {/*<div className={`col-md-3 mb-1 cntrl-width`} key="txtBloodGroup">*/}
                                        {/*    <label className="form-label">Blood Group </label>*/}
                                        {/*    <select ref={ref => (inputrefs.current['txtBloodGroup'] = ref)}*/}
                                        {/*        className="form-control"*/}
                                        {/*        maxLength='5'*/}
                                        {/*        id="txtBloodGroup"*/}
                                        {/*        value={recipe.BloodGroup}*/}
                                        {/*        //error={recipe.Blood Group}*/}
                                        {/*        onKeyDown={(e) => {*/}
                                        {/*            if (e.key === 'Enter') { inputrefs.current['txtEmergencyContactNo'].focus() };*/}
                                        {/*        }}*/}
                                        {/*        onChange={(e) => HandleChange('BloodGroup', e.target.value)}>*/}
                                        {/*        <option value="">--Select Blood Group--</option>*/}
                                        {/*        {BloodGroupData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}*/}

                                        {/*    </select>*/}
                                        {/*</div>*/}
                                        <SelectInputBox
                                            label='Blood Group'
                                            id="txtBloodGroup"
                                            value={recipe.BloodGroup}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtLoanDate'].focus() };
                                            }}
                                            inputrefs={inputrefs}
                                            onChange={(e) => {
                                                HandleChange('BloodGroup', e.target.value)
                                            }}
                                            children={<>
                                                <option value="">--Select Blood Group--</option>
                                                {BloodGroupData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}
                                            </>}
                                        />

                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { if (e.key === 'Enter') { inputrefs.current['txtMaritalStatus'].focus() }; };
                                            }}
                                            label='Emergency Contact No.'
                                            id='txtEmergencyContactNo'
                                            key='txtEmergencyContactNo'
                                            maxLength='10'
                                            placeholder="Emergency Contact No."
                                            value={recipe.EmergencyContactNo}
                                            onChange={(e) => { HandleChange('EmergencyContactNo', number(e.target.value)) }}
                                        />

                                        {/*<div className={`col-md-3 mb-1 cntrl-width`} key="txtMaritalStatus">*/}
                                        {/*    <label className="form-label">Marital Status<span style={{ color: "red" }}> *</span>*/}
                                        {/*    </label>*/}
                                        {/*    <select*/}
                                        {/*        ref={ref => (inputrefs.current['txtMaritalStatus'] = ref)}*/}
                                        {/*        id='txtMaritalStatus'*/}
                                        {/*        className="form-control"*/}
                                        {/*        maxLength='10'*/}
                                        {/*        onKeyDown={(e) => {*/}
                                        {/*            if (e.key === 'Enter') { recipe.MaritalStatus === 'Married' ? inputrefs.current['txtSpouseName'].focus() : inputrefs.current['txtFatherName'].focus() };*/}
                                        {/*        }}*/}
                                        {/*        value={recipe.MaritalStatus}*/}
                                        {/*        onChange={(e) => {*/}
                                        {/*            HandleChange('MaritalStatus', e.target.value);*/}
                                        {/*            if (e.target.value) setRecipe({ ...recipe, MaritalStatus: e.target.value, SpouseName: '', SpouseOccupation: '' })*/}
                                        {/*        }}>*/}
                                        {/*        <option value="">--Select Marital Status--</option>*/}
                                        {/*        <option value="Single">Single</option>*/}
                                        {/*        <option value="Married">Married</option>*/}
                                        {/*        <option value="Divorced">Divorced</option>*/}
                                        {/*    </select>*/}
                                        {/*    <div className="error-msg">{errors.MaritalStatus}</div>*/}
                                        {/*</div>*/}

                                        <SelectInputBox
                                            label='Marital Status'
                                            id='txtMaritalStatus'
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { recipe.MaritalStatus === 'Married' ? inputrefs.current['txtSpouseName'].focus() : inputrefs.current['txtFatherName'].focus() };
                                            }}
                                            required
                                            inputrefs={inputrefs}
                                            error={errors.MaritalStatus}
                                            value={recipe.MaritalStatus}
                                            onChange={(e) => {
                                                HandleChange('MaritalStatus', e.target.value);
                                                if (e.target.value) setRecipe({ ...recipe, MaritalStatus: e.target.value, SpouseName: '', SpouseOccupation: '' })
                                            }}
                                            children={<>
                                                <option value="">--Select Marital Status--</option>
                                                <option value="Single">Single</option>
                                                <option value="Married">Married</option>
                                                <option value="Divorced">Divorced</option>
                                            </>}
                                        />

                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtSpouseOccupation'].focus() };
                                            }}
                                            label='Spouse Name'
                                            placeholder='Spouse Name'
                                            id='txtSpouseName'
                                            key='txtSpouseName'
                                            maxLength='100'
                                            //placeholder="City"
                                            required={recipe.MaritalStatus === 'Married'}
                                            disabled={recipe.MaritalStatus !== 'Married'}
                                            value={recipe.SpouseName}
                                            error={errors.SpouseName}
                                            onChange={(e) => { HandleChange('SpouseName', (e.target.value)) }}
                                        />

                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    inputrefs.current['txtFatherName'].focus()
                                                };
                                            }}
                                            label='Spouse Occupation'
                                            placeholder='Spouse Occupation'
                                            id='txtSpouseOccupation'
                                            key='txtSpouseOccupation'
                                            maxLength='100'
                                            //placeholder="City"
                                            disabled={recipe.MaritalStatus !== 'Married'}
                                            value={recipe.SpouseOccupation}
                                            // error={errors.SpouseOccupation}
                                            onChange={(e) => { HandleChange('SpouseOccupation', (e.target.value)) }}
                                        //  required={recipe.MaritalStatus === 'Married'}
                                        />

                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtFatherOccupation'].focus() };
                                            }}
                                            required
                                            label={`Father's Name`}
                                            id='txtFatherName'
                                            key='txtFatherName'
                                            maxLength='100' placeholder={`Father's Name`}
                                            value={recipe.FatherName}
                                            error={errors.FatherName} onChange={(e) => { HandleChange('FatherName', (e.target.value)) }}
                                        />
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtPFNo'].focus() };
                                            }}
                                            label={`Father's Occupation`}
                                            id='txtFatherOccupation'
                                            key='txtFatherOccupation'
                                            maxLength='100' placeholder={`Father's Occupation`}
                                            value={recipe.FatherOccupation}
                                            // error={errors.FatherOccupation}
                                            onChange={(e) => { HandleChange('FatherOccupation', (e.target.value)) }}
                                        //   required
                                        />

                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtESICNo'].focus() };
                                            }}
                                            label={`PF No.`}
                                            id='txtPFNo'
                                            key='txtPFNo'
                                            maxLength='22' placeholder={`PF No.`}
                                            value={recipe.PFNo}
                                            onChange={(e) => { HandleChange('PFNo', (e.target.value)) }}
                                        />
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtUANNo'].focus() };
                                            }}
                                            label={`ESIC No.`}
                                            id='txtESICNo'
                                            key='txtESICNo'
                                            maxLength='17' placeholder={`ESIC No.`}
                                            value={recipe.ESICNo}
                                            onChange={(e) => { HandleChange('ESICNo', number(e.target.value)) }}
                                        />
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtCorAddress1'].focus() };
                                            }}
                                            label={`UAN No.`}
                                            id='txtUANNo'
                                            key='txtUANNo'
                                            maxLength='12' placeholder={`UAN No.`}
                                            value={recipe.UANNo}
                                            onChange={(e) => { HandleChange('UANNo', number(e.target.value)) }}
                                        />


                                    </div>


                                </div>
                            </fieldset>
                        </div>


                        <div className='row'>
                            <fieldset className="myfieldset ">
                                <legend className="mylegend"><h6 className="m-0">Address</h6></legend>
                                <div className='row px-2'>

                                    <fieldset className="myfieldset ">
                                        <legend className="mylegend"><span>Correspondence Address</span></legend>
                                        <div className='row'>

                                            <InputBox
                                                divclassname='col-md-2 mb-1 '
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtCorAddress2'].focus() };
                                                }}
                                                label='Address Line 1'
                                                id='txtCorAddress1'
                                                key='txtCorAddress1'
                                                maxLength='100'
                                                placeholder="Address Line 1"
                                                value={recipe.CorAddress1}
                                                error={errors.CorAddress1}
                                                onChange={(e) => {
                                                    HandleChange('CorAddress1', (e.target.value))
                                                    setIsPerAddressSame(false)
                                                    //  fillCorres(false)
                                                    // setRecipe({ ...recipe, CorAddress1: e.target.value })
                                                }} required
                                            />
                                            <InputBox
                                                divclassname='col-md-2 mb-1 '
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtCorState'].focus() };
                                                }}
                                                label='Address Line 2'
                                                id='txtCorAddress2'
                                                key='txtCorAddress2'
                                                maxLength='100'
                                                placeholder="Address Line 2"
                                                value={recipe.CorAddress2}
                                                onChange={(e) => {
                                                    HandleChange('CorAddress2', (e.target.value))
                                                    setIsPerAddressSame(false)
                                                }}
                                            />

                                            <AutoCompleteBox
                                                divclassname='col-md-2 mb-1 '
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtCorCity'].focus() };
                                                }}
                                                label='State'
                                                placeholder="State"
                                                maxLength='10'
                                                options={StateData}
                                                // options={[]}
                                                id='txtCorState'
                                                key='txtCorState'
                                                required={true}
                                                error={errors.CorState}
                                                inputValue={!recipe.CorState ? '' : recipe.CorState}
                                                onInputChange={(event, newInputValue) => {
                                                    HandleChange('CorState', newInputValue)
                                                //    StateAllData(newInputValue)
                                                    setIsPerAddressSame(false)

                                                }}
                                            />
                                            <InputBox
                                                divclassname='col-md-2 mb-1 '
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtCorPinCode'].focus() };
                                                }}
                                                label='City'
                                                id='txtCorCity'
                                                key='txtCorCity'
                                                maxLength='50'
                                                placeholder="City"
                                                value={recipe.CorCity}
                                                error={errors.CorCity}
                                                onChange={(e) => {
                                                    HandleChange('CorCity', (e.target.value))
                                                    setIsPerAddressSame(false)
                                                }} required
                                            />
                                            <InputBox
                                                divclassname='col-md-2 mb-1 '
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtCorPhoneNo'].focus() };
                                                }}
                                                label='Pin Code'
                                                id='txtCorPinCode'
                                                key='txtCorPinCode'
                                                maxLength='6'
                                                placeholder="Pin Code"
                                                value={recipe.CorPinCode}
                                                error={errors.CorPinCode}
                                                onChange={(e) => {
                                                    HandleChange('CorPinCode', number(e.target.value))
                                                    setIsPerAddressSame(false)
                                                }} required
                                            />
                                            <InputBox
                                                divclassname='col-md-2 mb-1 '
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtcheckSameAddress'].focus() };
                                                }}
                                                label='Phone No.'
                                                id='txtCorPhoneNo'
                                                key='txtCorPhoneNo'
                                                maxLength='10'
                                                placeholder="Phone No."
                                                value={recipe.CorPhoneNo}
                                                onChange={(e) => {
                                                    HandleChange('CorPhoneNo', number(e.target.value))
                                                    setIsPerAddressSame(false)
                                                }}
                                            />

                                            {/*<AutoCompleteBox*/}
                                            {/*    inputrefs={inputrefs}*/}
                                            {/*    onKeyDown={(e) => {*/}
                                            {/*        if (e.key === 'Enter') { inputrefs.current['txtCorProofDocNo'].focus() };*/}
                                            {/*    }}*/}
                                            {/*    label='Address Proof' placeholder="Address Proof" maxLength='50' options={AddressProofDocument} id='txtCorAddressProof'*/}
                                            {/*    required={true} inputValue={recipe.CorAddressProof ? recipe.CorAddressProof : ''}*/}
                                            {/*    onInputChange={(event, newInputValue) => {*/}
                                            {/*        HandleChange('CorAddressProof', newInputValue)*/}

                                            {/*    }}*/}
                                            {/*/>*/}

                                            {/*<div className={`col-md-3 mb-1 cntrl-width`} key="txtCorAddressProof">*/}
                                            {/*    <label className="form-label">Address Proof<span style={{ color: "red" }}> *</span></label>*/}
                                            {/*    <select ref={ref => (inputrefs.current['txtCorAddressProof'] = ref)}*/}
                                            {/*        className="form-control"*/}
                                            {/*        maxLength='18'*/}
                                            {/*        id="txtCorAddressProof"*/}
                                            {/*        // key="txtCorAddressProof"*/}
                                            {/*        value={recipe.CorAddressProof}*/}
                                            {/*        onKeyDown={(e) => {*/}
                                            {/*            if (e.key === 'Enter') { inputrefs.current['txtCorProofDocNo'].focus() };*/}
                                            {/*        }}*/}
                                            {/*        onChange={(e) => {*/}
                                            {/*            HandleChange('CorAddressProof', e.target.value)*/}
                                            {/*            setIsPerAddressSame(false)*/}
                                            {/*        }}>*/}
                                            {/*        <option value="">--Select Address Proof--</option>*/}
                                            {/*        {addressProofDocument?.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}*/}

                                            {/*    </select>*/}
                                            {/*    <div className="error-msg">{errors.CorAddressProof}</div>*/}

                                            {/*</div>*/}


                                            {/*<InputBox*/}
                                            {/*    inputrefs={inputrefs}*/}
                                            {/*    onKeyDown={(e) => {*/}
                                            {/*        if (e.key === 'Enter') { inputrefs.current['txtcheckSameAddress'].focus() };*/}
                                            {/*    }}*/}
                                            {/*    label='Proof Doc No'*/}
                                            {/*    id='txtCorProofDocNo'*/}
                                            {/*    key='txtCorProofDocNo'*/}
                                            {/*    maxLength='15'*/}
                                            {/*    placeholder="Proof Doc No"*/}
                                            {/*    required*/}
                                            {/*    value={recipe.CorProofDocNo}*/}
                                            {/*    error={errors.CorProofDocNo}*/}
                                            {/*    onChange={(e) => {*/}
                                            {/*        HandleChange('CorProofDocNo', (e.target.value))*/}
                                            {/*        setIsPerAddressSame(false)*/}
                                            {/*    }}*/}
                                            {/*/>*/}
                                        </div>
                                    </fieldset>

                                    <fieldset className="myfieldset ">
                                        <legend className="mylegend"><span>Permanent Address | <input
                                            type='checkbox'
                                            id="txtcheckSameAddress"
                                            disabled
                                            ref={ref => (inputrefs.current['txtcheckSameAddress'] = ref)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { isPerAddressSame ? inputrefs.current['txtAccountHolder'].focus() : inputrefs.current['txtPerAddress1'].focus() };
                                            }}
                                            // checked={isPerAddressSame}
                                            checked={isPerAddressSame} onChange={(e) => fillCorres(e.target.checked)} /> Same As Correspondence Address </span></legend>
                                        <div className='row' >
                                            {/*<div className='row' style={isPerAddressSame ? { display: 'none' } : {}}>*/}

                                            <InputBox
                                                divclassname='col-md-2 mb-1 '
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtPerAddress2'].focus() };
                                                }}
                                                label='Address Line 1'
                                                id='txtPerAddress1'
                                                key='txtPerAddress1'
                                                maxLength='100'
                                                placeholder="Address Line 1"
                                                //value={recipe.PerAddress1}
                                                error={errors.PerAddress1}
                                                value={isPerAddressSame ? recipe.CorAddress1 : recipe.PerAddress1}
                                                // disabled={isPerAddressSame ? true: false}
                                                onChange={(e) => { HandleChange('PerAddress1', (e.target.value)) }} required
                                            />
                                            <InputBox
                                                divclassname='col-md-2 mb-1 '
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtPerState'].focus() };
                                                }}
                                                label='Address Line 2'
                                                id='txtPerAddress2'
                                                key='txtPerAddress2'
                                                maxLength='100'
                                                placeholder="Address Line 2"
                                                //  value={recipe.PerAddress2}
                                                value={isPerAddressSame ? recipe.CorAddress2 : recipe.PerAddress2}
                                                // disabled={isPerAddressSame ? true: false}
                                                onChange={(e) => { HandleChange('PerAddress2', (e.target.value)) }}
                                            />

                                            <AutoCompleteBox
                                                divclassname='col-md-2 mb-1 '
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtPerCity'].focus() };
                                                }}
                                                label='State'
                                                placeholder="State"
                                                maxLength='10'
                                                //options={[]}
                                                options={StateData}
                                                id='txtPerState'
                                                key='txtPerState'
                                                required={true}
                                                error={errors.PerState} // inputValue={recipe.PerState ? recipe.PerState : ''}
                                                inputValue={!isPerAddressSame ? recipe.PerState : recipe.CorState}
                                                // disabled={isPerAddressSame ? true: false}
                                                onInputChange={(event, newInputValue) => {
                                                    HandleChange('PerState', newInputValue)
                                                //    StateAllData(newInputValue)
                                                }}
                                            />
                                            <InputBox
                                                divclassname='col-md-2 mb-1 '
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtPerPinCode'].focus() };
                                                }}
                                                label='City'
                                                id='txtPerCity'
                                                key='txtPerCity'
                                                maxLength='50'
                                                placeholder="City"
                                                // value={recipe.PerCity}
                                                error={errors.PerCity}
                                                value={isPerAddressSame ? recipe.CorCity : recipe.PerCity}
                                                // disabled={isPerAddressSame ? true: false}
                                                onChange={(e) => { HandleChange('PerCity', (e.target.value)) }} required
                                            />
                                            <InputBox
                                                divclassname='col-md-2 mb-1 '
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtPerPhoneNo'].focus() };
                                                }}
                                                label='Pin Code'
                                                id='txtPerPinCode'
                                                key='txtPerPinCode'
                                                maxLength='6'
                                                placeholder="Pin Code"
                                                //  value={recipe.PerPinCode}
                                                error={errors.PerPinCode}
                                                value={isPerAddressSame ? recipe.CorPinCode : recipe.PerPinCode}
                                                // disabled={isPerAddressSame ? true: false}
                                                onChange={(e) => { HandleChange('PerPinCode', number(e.target.value)) }} required
                                            />
                                            <InputBox
                                                divclassname='col-md-2 mb-1 '
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtAccountHolder'].focus() };
                                                }}
                                                label='Phone No.'
                                                id='txtPerPhoneNo'
                                                key='txtPerPhoneNo'
                                                maxLength='10'
                                                placeholder="Phone No."
                                                // value={recipe.PerPhoneNo}
                                                value={isPerAddressSame ? recipe.CorPhoneNo : recipe.PerPhoneNo}
                                                // disabled={isPerAddressSame ? true: false}
                                                onChange={(e) => { HandleChange('PerPhoneNo', number(e.target.value)) }}
                                            />

                                            {/*<div className={`col-md-3 mb-1 cntrl-width`} key="txtPerAddressProof">*/}
                                            {/*    <label className="form-label">Address Proof<span style={{ color: "red" }}> *</span></label>*/}
                                            {/*    <select*/}
                                            {/*        ref={ref => (inputrefs.current['txtPerAddressProof'] = ref)}*/}
                                            {/*        className="form-control"*/}
                                            {/*        maxLength='18'*/}
                                            {/*        id="txtPerAddressProof"*/}
                                            {/*        //key="txtPerAddressProof"*/}
                                            {/*        // value={recipe.PerAddressProof}*/}
                                            {/*        value={isPerAddressSame ? recipe.CorAddressProof : recipe.PerAddressProof}*/}

                                            {/*        onKeyDown={(e) => {*/}
                                            {/*            if (e.key === 'Enter') { inputrefs.current['txtPerProofDocNo'].focus() };*/}
                                            {/*        }}*/}
                                            {/*        onChange={(e) => HandleChange('PerAddressProof', e.target.value)}>*/}
                                            {/*        <option value="">--Select Address Proof--</option>*/}
                                            {/*        {addressProofDocument?.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}*/}
                                            {/*    </select>*/}
                                            {/*    <div className="error-msg">{errors.PerAddressProof}</div>*/}
                                            {/*</div>*/}
                                            {/*<InputBox*/}
                                            {/*    inputrefs={inputrefs}*/}
                                            {/*    onKeyDown={(e) => {*/}
                                            {/*        if (e.key === 'Enter') { inputrefs.current['txtAccountHolder'].focus() };*/}
                                            {/*    }}*/}
                                            {/*    label='Proof Doc No'*/}
                                            {/*    id='txtPerProofDocNo'*/}
                                            {/*    key='txtPerProofDocNo'*/}
                                            {/*    maxLength='15'*/}
                                            {/*    placeholder="Proof Doc No"*/}
                                            {/*    error={errors.PerProofDocNo}*/}
                                            {/*    required*/}
                                            {/*    //value={recipe.PerProofDocNo}*/}
                                            {/*    value={isPerAddressSame ? recipe.CorProofDocNo : recipe.PerProofDocNo}*/}
                                            {/*    // disabled={isPerAddressSame ? true: false}*/}
                                            {/*    onChange={(e) => { HandleChange('PerProofDocNo', (e.target.value)) }}*/}
                                            {/*/>*/}
                                        </div>
                                    </fieldset>

                                </div>
                            </fieldset>

                        </div>

                        <div className='row'>
                            <fieldset className="myfieldset">
                                <legend className="mylegend"><h6 className="m-0">Bank Details</h6></legend>
                                <div className='row'>
                                    <InputBox
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtBankName'].focus() };
                                        }}
                                        label='Name in Bank Account'
                                        id='txtAccountHolder'
                                        key='txtAccountHolder'
                                        maxLength='100'
                                        placeholder="Name in Bank Account"
                                        value={isNameInBank ? recipe.CandidateName : recipe.AccountHolder}
                                        onChange={(e) => {
                                            HandleChange('AccountHolder', e.target.value)
                                            setIsNameInBank(false);
                                        }}
                                    //  required
                                    />

                                    {/*<InputBox*/}
                                    {/*    inputrefs={inputrefs}*/}
                                    {/*    onKeyDown={(e) => {*/}
                                    {/*        if (e.key === 'Enter') { inputrefs.current['txtAccountNo'].focus() };*/}
                                    {/*    }}*/}
                                    {/*    label='Bank Name'*/}
                                    {/*    id='txtBankName'*/}
                                    {/*    maxLength='50'*/}
                                    {/*    placeholder="Bank Name"*/}
                                    {/*    value={recipe.BankName}*/}
                                    {/*    onChange={(e) => HandleChange('BankName', e.target.value)}*/}
                                    {/*//  required*/}
                                    {/*/>*/}
                                    <AutoCompleteBox
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtAccountNo'].focus() };
                                        }}
                                        label='Bank Name'
                                        placeholder="Bank Name"
                                        maxLength='100'
                                        options={BankNameData}
                                        // options={[]}
                                        id='txtBankName'
                                        key='txtBankName'
                                        //required={true}
                                        inputValue={recipe.BankName ? recipe.BankName : ''}
                                        onInputChange={(event, newInputValue) => {
                                       //     GetIFSCPrefix(newInputValue)
                                            HandleChange('BankName', newInputValue)
                                       //     GetBankNameData(newInputValue)
                                        }}
                                    />

                                    <InputBox
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtIFSCCode'].focus() };
                                        }}
                                        label='Account No'
                                        id='txtAccountNo'
                                        key='txtAccountNo'
                                        maxLength='50'
                                        placeholder="Account No"
                                        value={recipe.AccountNo}
                                        onChange={(e) => { HandleChange('AccountNo', number(e.target.value)) }}
                                    // required
                                    />

                                    <InputBox
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtBankAddress'].focus() };
                                        }}
                                        label='IFSC Code'
                                        id='txtIFSCCode'
                                        key='txtIFSCCode'
                                        maxLength='11'
                                        placeholder="IFSC Code"
                                        value={recipe.IFSCCode}
                                        onChange={(e) => { HandleChange('IFSCCode', e.target.value.toUpperCase()) }}
                                    //required
                                    />
                                    <InputBox
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                       //     if (e.key === 'Enter') { handleSubmit() };
                                        }}
                                        label='Bank Address'
                                        id='txtBankAddress'
                                        key='txtBankAddress'
                                        maxLength='100'
                                        placeholder="Bank Address"
                                        value={recipe.BankAddress}
                                        onChange={(e) => { HandleChange('BankAddress', e.target.value) }}
                                    //required
                                    />

                                </div>
                            </fieldset>

                        </div>



                        {/*<FormButton*/}
                        {/*    handleSubmit={handleSubmit}*/}
                        {/*    Export={false}*/}
                        {/*    Next={true}*/}
                        {/*    nextButtonText='Next & Save'*/}
                        {/*    handleNext={handleNext}*/}
                        {/*    handleClear={handleClear}*/}
                        {/*/>*/}
                    </div>

                </div>

            </div >
            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}

        </>
    )
}

export default PersonalDetails;
