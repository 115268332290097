import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../context/auth';
import * as XLSX from 'xlsx';
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { Token, currentMonth, currentYear } from '../Common/Common';
import * as imp from '../commoncomponents/Import';
import { AutoCompleteBox, MonthBox, SelectInputBox, YearBox } from '../commoncomponents/InputBox';
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';

function ImportLeaveRegister() {
    const { user, sessionExpired } = useAuth();
    const inputrefs = useRef([]);
    //token Data
    //const TokenData = {
    //    AuthToken: process.env.REACT_APP_SECRET_KEY,
    //    LoginToken: user.loginsessionID,
    //    UserID: user.userID,
    //    CompanyID: user.companyID
    //}
    const TokenData = Token()
    //initial state 
    const [recipe, setRecipe] = useState({
        company: '',
        month: "",
        year: ''
    })
    const [errorCount, setErrorCount] = useState('');;
    const [errorData, setErrorData] = useState(0);
    const [loading, setLoading] = useState(false);
    const [Field, setField] = useState('');
    const [fileData, setFileData] = useState(null);
    const requestData = { ...TokenData, PageName: `ImportLeaveRegister_${recipe.company}` }
    const [fieldValue, setFieldValue] = useState('')
    const [close, setClose] = useState(false)
    const [companyAllData, setCompanyAllData] = useState([])
    const [yearData, setYearData] = useState([]);
    const [monthData, setMonthData] = useState([]);
    const [errors, setErrors] = useState({})

    //Get company Data for AutoComplete
    const GetCompanyData = (value) => {
        setCompanyAllData([])
        let temp = {
            Type: "GetCompany",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setCompanyAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setCompanyAllData(data)
                }
            }
        });
    }

    //Get Month Data
    const GetMonthData = () => {
        setMonthData([])
        let temp = {
            Type: "GetMonth",
            Prefix: '',

        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setMonthData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setMonthData(data)
                }
            }
        });
    };


    //Get Year Data
    const GetYearData = () => {
        setYearData([])
        let temp = {
            Type: "GetYear",
            Prefix: '',

        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setYearData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setYearData(data)
                }
            }
        });
    };


    const HandleChange = (prop, value) => {
        setRecipe({ ...recipe, [prop]: value })
    }



    useEffect(() => {
        document.title = 'Pay Plus : Import Opening Leave';
        inputrefs.current['txtCompany'].focus();
        GetYearData();
        GetMonthData();
    }, []);


    //GetImportField Handler
    const ImportField = (requestData) => {
        setLoading(true)
        post(`${API_SERVER}api/Import/ImportFormat`, requestData, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.status === "EXPIRED") {
                    toast.error(res.message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setField(res.fieldName);
                }
            }
        });
    }

    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "company", msg: 'Please Select Company.' },
            { field: "month", msg: 'Please Select Month.' },
            { field: "year", msg: 'Please Select Year.' },
        ]
        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;

    }

    const handleUpload = () => {
        if (Validate()) {
            setErrors({})
            if (fileData == null) {
                toast.error("Please Upload File.!")
            }
            else {

                const formData = new FormData();
                formData.append('file', fileData);
                formData.append('FieldName', Field);
                formData.append('Month', recipe.month);
                formData.append('Year', recipe.year);
                formData.append('Company', recipe.company);
                formData.append('AuthToken', process.env.REACT_APP_SECRET_KEY);
                formData.append('LoginToken', user.loginsessionID);
                formData.append('UserID', user.userID);
                formData.append('CompanyID', user.companyID);
                setLoading(true)
                post(`${API_SERVER}api/Import/ImportLeaveRegister`, formData, (res) => {
                    handleClear();
                    if (res.Status === "ERROR") {
                        //toast.error(res.Message);
                        setErrorData(res);
                        setErrorCount(res);
                        setClose(true);
                        setErrors({})
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message);
                        sessionExpired();
                        setLoading(false);
                    } else if (res.Status === "UNAUTHORIZED") {
                        toast.error(res.Message);
                    } else if (res.Status === "") {
                        toast.error(res.Message);
                        setLoading(false);
                    } else {
                        //  toast.success(res.Message);
                        setLoading(false);
                        setErrorCount(res);
                        setErrorData(res);
                        setClose(true);
                        setErrors({})
                    }
                })
            }
        }
    }

    // Clear File Input 
    const handleClear = () => {
        setFileData(null)
    }


    //handleDownloadFormat Handler
    const handleDownloadErrorData = () => {
        imp.ExportError("ImportOpeningLeaveError", errorData);
    }

    //handleDownloadFormat Handler
    const handleDownloadFormat = () => {
        if (recipe.company === '') {
            toast.error("Please Select Company")
        } else {
            imp.handleDownloadExcel(requestData, 'ImportOpeningLeaveFormat')
        }
    }

    let CompanyData = []
    for (let i = 0; i < companyAllData.length; i++) {
        let mydata = companyAllData[i]?.values
        CompanyData.push(mydata)
    };



    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Import Opening Leave</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className={`col-md-4 mb-1 row `} key="txtStatus">


                            <AutoCompleteBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtMonth'].focus() };
                                }}
                                divclassname=''
                                label='Company'
                                placeholder="Company"
                                maxLength='50'
                                options={CompanyData}
                                id='txtCompany'
                                key='txtCompany'
                                required={true}
                                error={errors.company}
                                inputValue={recipe.company ? recipe.company : ''}
                                onInputChange={(event, newInputValue) => {
                                    GetCompanyData(newInputValue)
                                    HandleChange('company', newInputValue)
                                    if (newInputValue) {
                                        ImportField({ ...TokenData, PageName: `ImportLeaveRegister_${newInputValue}` });
                                        imp.GetFieldValue({ ...TokenData, PageName: `ImportLeaveRegister_${newInputValue}` }, setFieldValue)
                                    }

                                }}
                                onFocus={() => GetCompanyData()}
                            />
                            <SelectInputBox
                                divclassname=''
                                label='Month'
                                inputrefs={inputrefs}
                                id='txtMonth'
                                value={recipe.month}
                                required
                                error={errors.month}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtYear'].focus() };
                                }}
                                onChange={(e) => HandleChange('month', e.target.value)}
                                children={<>
                                    <option value=''>--Month--</option>
                                    {monthData.map((opt, index) => <option key={index} value={opt.code} >{opt.name}</option>)}
                                </>}
                            />

                            <SelectInputBox
                                divclassname=''
                                label='Year'
                                inputrefs={inputrefs}
                                id="txtYear"
                                value={recipe.year}
                                required
                                error={errors.year}
                                // onKeyDown={(e) => {
                                ///      if (e.key === 'Enter') { handleSubmit() };
                                //   }}
                                onChange={(e) => { HandleChange('year', e.target.value) }}
                                children={<>
                                    <option value=''>--Year--</option>
                                    {yearData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}
                                </>}
                            />


                        </div>

                        <div className='row col-md-6'>
                            <div>
                                <b>Note : </b> <br />
                                <label>1. Please Upload Only Excel File of Format .xlsx.</label><br />
                                <label>2. Below Given Fields and Excel Header Should be Same.</label>
                                <br />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <fieldset className="myfieldset">
                            <legend className="mylegend"><h6 className="m-0">Excel File</h6></legend>
                            <div className='row'>
                                <div className={`col-md-3 mb-1`} key="fileDocument">
                                    <input
                                        className="form-control form-control-sm"
                                        type="file"
                                        id="fileDocument"
                                        name="DocumentFrontSide"
                                        maxLength="50"
                                        tabIndex="1"
                                        onBlur={(e) => {
                                            setClose(false)
                                            if (e.target.files && e.target.files[0]) {
                                                let fu = e.target.files[0];
                                                let extArr = (fu.name).split(".");
                                                if (extArr[1] !== "xls" && extArr[1] !== "xlsx") {
                                                    toast.error("Please Upload only excel file.!");
                                                    // console.log(e.target.files, 'blur if')
                                                    return
                                                }
                                                else {
                                                    setFileData(e.target.files[0]);
                                                    //  console.log(e.target.files, 'blur else')
                                                    return
                                                }
                                            }
                                        }}
                                        required
                                        onChange={(e) => {
                                            setClose(false)
                                            if (e.target.files && e.target.files[0]) {
                                                let fu = e.target.files[0];
                                                let extArr = (fu.name).split(".");
                                                if (extArr[1] !== "xls" && extArr[1] !== "xlsx") {
                                                    toast.error("Please Upload only excel file.!");
                                                    //   console.log(e.target.files, 'if')
                                                    return
                                                }
                                                else {
                                                    setFileData(e.target.files[0]);
                                                    console.log(e.target.files, 'else')
                                                    return
                                                }
                                            }
                                            // console.log(e, 'all')
                                        }}
                                    />
                                </div>




                                <div className={`col-lg-9 mb-1`} >
                                    <button type="button" name='btnUpload'
                                        onClick={handleUpload}
                                        className="btn btn-rounded btn-success col-md-1">
                                        <span className=" text-white me-2"><i className='fa fa-upload text-white color-success'></i></span>Upload
                                    </button>
                                    <button type="button" name='btnDownloadFormat'
                                        onClick={handleDownloadFormat}
                                        className="btn btn-rounded btn-primary">
                                        <span className=" text-white me-2"><i className='fa fa-download text-white color-success'></i></span>Download Format
                                    </button>
                                </div>
                            </div>
                            {recipe.company && < div className='row m-0'>
                                <textarea className="form-control"
                                    disabled
                                    defaultValue={Field}
                                    id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>}
                        </fieldset>
                        {close && <div className='row '>
                            <div className=' m-0 ms-2 border rounded d-flex justify-content-between ' style={{ background: '#95d295b0' }}>
                                <div className='mx-0 my-2'>
                                    <pre className="m-0 p-0 fw-bold"><span className='text-success ' > <i className='fa fa-check'></i> Total Data : <span className=''>{errorCount.TotalData}</span></span>      <span className='text-success' >Total Merge Data : <span className=''>{errorCount.TotalMergeData}</span></span>      <span className='text-danger' > <i className='fa fa-question'></i> Total Exception : <span className='' onClick={errorCount?.TotalException === '' && null && undefined && '0' ? "" : handleDownloadErrorData} style={{ cursor: "pointer" }}>{errorCount.TotalException}</span></span></pre>
                                </div>
                                <button onClick={() => setClose(false)} type='button' className='btn btn-outline-danger m-1 min-vw-0 p-1 px-2 rounded' style={{ minWidth: '0' }}><i className='fa fa-close m-0 p-0'></i></button>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ImportLeaveRegister
