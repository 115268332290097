import { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API_SERVER } from '../../Utils';
import { post } from '../../apiservices/service';
import { useAuth } from '../../context/auth';
import { Token } from '../Common/Common';
import BlurLoader from '../Loder/BlurLoader';
import { InputBox, handleFileUploderInput } from '../commoncomponents/InputBox';
import EmployeeDocumentUpload from './EmployeeDocumentUpload';
import EmployeeFamilyDetails from './EmployeeFamilyDetails';
import EmployeePersonalDetails from './EmployeePersonalDetails';
import EmployeeSalaryStructure from './EmployeeSalaryStructure';
import EmploymenteDetails from './EmploymentDetails';
import OtherDetails from './OtherDetails';

const EmployeeMaster = () => {
    const [activeTab, setActiveTab] = useState('PersonalDetails');
    const { user, sessionExpired } = useAuth();

    const navigate = useNavigate()

    // get Employee Code By Url
    let url = window.location.search
    const queryString = new URLSearchParams(url);
    const EmployeeCodeByUrl = queryString.get('EmpCode');

    const TokenData = Token()

    const inputrefs = useRef([])
    const [recipe, setRecipe] = useState({
        CandidateID: '0',
        Company: TokenData.Company,
        EmpID: "0",
        EmpCode: "",
        EmpName: "",
        FatherName: "",
        FatherOccupation: "",
        DOB: "",
        Gender: "",
        MaritalStatus: "",
        SpouseName: "",
        SpouseOccupation: "",
        EmailID: "",
        MobileNo: "",
        AadhaarNo: "",
        BloodGroup: "",
        EmergencyContactNo: "",
        CorAddress1: "",
        CorAddress2: "",
        CorState: "",
        CorCity: "",
        CorPinCode: "",
        CorPhoneNo: "",
        SameAsCorrespondence: false,
        PerAddress1: "",
        PerAddress2: "",
        PerState: "",
        PerCity: "",
        PerPinCode: "",
        PerPhoneNo: "",
        IsPayslipGenerated:false,
        Branch: '',
        Shift: "",
        CardNo:'',
        Department: '',
        Designation: '',
        OldEmpCode: '',
        DOJ: '',
        InTime: '',
        OutTime: '',
        ContractPeriod: '',
        ContractDays: '',
        NoticePeriodDays: '',
        NoticePeriod: '',
        ApprovalAuthority1: '',
        ApprovalAuthority2: '',
        ApprovalAuthority3: '',
        OfficialEmailID: '',
        EmployeeType: '',
        PANNo: '',
        SalaryType: '',
        WeeklyOffDetailsList: [],
        LeaveEncashmentApplicable: false,
        PFApplicable: false,
        BonusApplicable: false,
        ESIApplicable: false,
        PFNo: '',
        UANNo: '',
        OnCrossingLimitDeductPFon: '',
        CeilAmount: '',
        VolPF: false,
        EmprVolPF: '',
        EmpVolPF: '',
        TICNo: '',
        TICStatus: '',
        Dispensary: '',
        SmartCard: '',
        ESINo: '',
        TICMode: '',
        PaymentMode: '',
        EmployeeBank: '',
        AccountNo: '',
        NameinBankAccount: '',
        IFSCCode: '',
        AccountUpdateDate: '',
        Remarks: '',
    })

    const [dateOfBirth, setDateOfBirth] = useState();
    const [dateOfJoining, setDateOfJoining] = useState();
    const [inTime, setInTime] = useState();
    const [outTime, setOutTime] = useState();
    const [dateOfConfirmation, setDateOfConfirmation] = useState();
    const [dateOfAccountUpdate, setDateOfAccountUpdate] = useState();
    const [profilePicture, setProfilePicture] = useState()
    const [imageData, setImageData] = useState()
    const [loading, setLoading] = useState(false)
    const [isPerAddressSame, setIsPerAddressSame] = useState(false)
    const [employee, setEmployee] = useState()
    const [EmployeeInEditTime, setEmployeeInEditTime] = useState()
    const [WeeklyOffDetailsList, setWeeklyOffDetailsList] = useState([])
    const [QualificationDetailsList, setQualificationDetailsList] = useState([]);
    const [PreviousExperienceDetailsList, setPreviousExperienceDetailsList] = useState([])
    const [otherDetailsRecipe, setOtherDetailsRecipe] = useState({
        IsRetired: false,
        RedAlert: false,
        ICardIssued: false,
        JoiningConfirmationReceived: false,
        DOL: '',
        ReasonOfLeaving: '',
        ReasonDate: '',
        Reason: '',
        ReferenceBy: '',
        ReferenceRelation: '',
        Remarks: '',
        QualificationDetailsList: [],
        PrevEmploymentDetailsList: [],
        EmpCode: EmployeeCodeByUrl ? EmployeeCodeByUrl : employee ? employee?.empCode : "0"
    })
    const fileType = 'PNG,JPG'
    const [retiredEmployee, setRetiredEmployee] = useState(false)

    const [errorFocus, setErrorFocus] = useState('');

    const [profilePictureRecipe, setProfilePictureRecipe] = useState({
        code: employee?.empCode || '',
        photo: '',
        photoExt: '',
        photoName: ''
    })

    const [ShowUploadImageModal, setShowUploadImageModal] = useState(false)

    // Handle Upload Image
    const HandleUploadImage = () => {
        post(`${API_SERVER}api/Employee/InsertUpdateProfilePic`, { ...TokenData, ...profilePictureRecipe, code: EmployeeCodeByUrl ? EmployeeCodeByUrl : employee?.empCode, type: 'Employee' }, (res) => {

            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.status === "EXPIRED") {
                    toast.error(res.message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    toast.success(res.message)
                    GetProfilePhoto()
                    setShowUploadImageModal(false)
                }
            }
        });

    }


    // get profile photo
    const GetProfilePhoto = () => {
        post(`${API_SERVER}api/Employee/GetProfilePic`, { ...TokenData, code: EmployeeCodeByUrl ? EmployeeCodeByUrl : employee?.empCode, type: 'Employee' }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    // toast.error(res.message)
                    setLoading(false)
                } else if (res.status === "EXPIRED") {
                    toast.error(res.message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    // toast.success(res.message)
                    setImageData(res.data)
                }
            }
        });
    }


    //-----------------------Handle Tab Change------------------//
    const handleTabClick = (tabId, curretTab) => {
        if ((EmployeeCodeByUrl || employee) && tabId) {
            setActiveTab(tabId);
        } else {
            if (curretTab === 'EmploymenteDetails') {
                setActiveTab('EmploymenteDetails')
            } else {
            setActiveTab('PersonalDetails')
            }

        }
    };
    //const handleTabClick = (tabId) => {
    //    setActiveTab(tabId);
    //};

    //Handle Edit Employee Master
    const handleEdit = () => {
        setLoading(true)
        post(`${API_SERVER}api/Employee/EditEmployeeMaster`, { ...TokenData, EmpCode: EmployeeCodeByUrl }, (res) => {
            if (res.status === "EXPIRED") {
                toast.error(res.message)
                sessionExpired()
            } else if (res.status === "UNAUTHORIZED") {
                toast.error(res.message);
                setLoading(false);
            } else if (res.status === "SUCCESS") {
                let data = res.data.personalDetail;
                let OtherData = res.data.qualificationDetail;
                setLoading(false);
                setRecipe({
                    ...recipe,
                    CandidateID: data.candidateID,
                    Company: data.company,
                    EmpID: data.empID,
                    EmpCode: data.empCode,
                    EmpName: data.empName,
                    FatherName: data.fatherName,
                    FatherOccupation: data.fatherOccupation,
                    DOB: data.dob,
                    Gender: data.gender,
                    MaritalStatus: data.maritalStatus,
                    SpouseName: data.spouseName,
                    SpouseOccupation: data.spouseOccupation,
                    EmailID: data.emailID,
                    MobileNo: data.mobileNo,
                    AadhaarNo: data.aadhaarNo,
                    BloodGroup: data.bloodGroup,
                    EmergencyContactNo: data.emergencyContactNo,
                    CorAddress1: data.corAddress1,
                    CorAddress2: data.corAddress2,
                    CorState: data.corState,
                    CorCity: data.corCity,
                    CorPinCode: data.corPincode,
                    CorPhoneNo: data.corPhoneNo,
                    PerAddress1: data.perAddress1,
                    PerAddress2: data.perAddress2,
                    PerState: data.perState,
                    PerCity: data.perCity,
                    PerPinCode: data.perPincode,
                    PerPhoneNo: data.perPhoneNo,
                    SameAsCorrespondence: data.sameAsCorrespondence,
                    IsPayslipGenerated:data.isPayslipGenerated,
                    Branch: data.branch,
                    Department: data.department,
                    Designation: data.designation,
                    OldEmpCode: data.oldEmpCode,
                    DOJ: data.doj,
                    InTime: data.inTime,
                    OutTime: data.outTime,
                    ContractPeriod: data.contractType,
                    ContractDays: data.contractPeriod,
                    NoticePeriodDays: data.noticePeriodDays,
                    NoticePeriod: data.noticePeriod,
                    //  ConfirmationDate: data.confirmationDate,
                    ApprovalAuthority1: data.approvalAuthority1,
                    ApprovalAuthority2: data.approvalAuthority2,
                    ApprovalAuthority3: data.approvalAuthority3,
                    OfficialEmailID: data.officialEmailID,
                    EmployeeType: data.employeeType,
                    PANNo: data.panNo,
                    SalaryType: data.salaryType,
                    Shift: data.shift,
                    CardNo: data.cardNo,
                    WeeklyOffDetailsList: data.weeklyOffDetailsList,
                    LeaveEncashmentApplicable: data.leaveEncashmentApplicable,
                    BonusApplicable: data.bonusApplicable,
                    PFApplicable: data.pfApplicable,
                    ESIApplicable: data.esiApplicable,
                    PFNo: data.pfNo,
                    UANNo: data.uanNo,
                    OnCrossingLimitDeductPFon: data.onCrossingLimitDeductPFon,
                    CeilAmount: data.ceilAmount,
                    VolPF: data.volPF,
                    EmprVolPF: data.emprVolPF,
                    EmpVolPF: data.empVolPF,
                    TICNo: data.ticNo,
                    TICStatus: data.ticStatus,
                    Dispensary: data.dispensary,
                    SmartCard: data.smartCard,
                    ESINo: data.esiNo,
                    TICMode: data.ticMode,
                    PaymentMode: data.paymentMode,
                    EmployeeBank: data.employeeBank,
                    AccountNo: data.accountNo,
                    NameinBankAccount: data.nameinBankAccount,
                    IFSCCode: data.ifscCode,
                    AccountUpdateDate: data.accountUpdateDate,
                    Remarks: data.remarks,

                })
                setIsPerAddressSame(data.sameAsCorrespondence)
                setWeeklyOffDetailsList(data.weeklyOffDetailsList)
                setProfilePictureRecipe({
                    ...profilePictureRecipe,
                    photo: data.empProfilePic,
                    photoExt: data.empProfilePicExt,
                    photoName: data.empProfilePicName
                })
                setImageData({
                    ...imageData,
                    photo: data.empProfilePic,
                    photoExt: data.empProfilePicExt,
                    photoName: data.empProfilePicName
                })
                handleFileUploderInput('.ProfilePicture', data, 'empProfilePicName', 'empProfilePicExt', fileType)

                setOtherDetailsRecipe({
                    ...otherDetailsRecipe,
                    IsRetired: OtherData.isRetired,
                    RedAlert: OtherData.redAlert,
                    ICardIssued: OtherData.iCardIssued,
                    JoiningConfirmationReceived: OtherData.joiningConfirmationReceived,
                    DOL: OtherData.dol,
                    ReasonOfLeaving: OtherData.reasonOfLeaving,
                    ReasonDate: OtherData.reasonDate,
                    Reason: OtherData.reason,
                    ReferenceBy: OtherData.referenceBy,
                    ReferenceRelation: OtherData.referenceRelation,
                    Remarks: OtherData.remarks,
                    QualificationDetailsList: OtherData.qualificationDetailsList,
                    PrevEmploymentDetailsList: OtherData.prevEmploymentDetailsList,
                    EmpCode: EmployeeCodeByUrl
                })

                setRetiredEmployee(OtherData.isRetired)

                setEmployeeInEditTime({
                    ...EmployeeInEditTime,
                    EmpID: data.empID,
                    EmpCode: data.empCode,
                    EmpName: data.empName,
                })
                setQualificationDetailsList(OtherData.qualificationDetailsList);
                setPreviousExperienceDetailsList(OtherData.prevEmploymentDetailsList);

            } else if (res.Status === "EXPIRED") {
                toast.error(res.Message)
                sessionExpired()
            }

        });
    }

    // Handle Clear Upload Image 
    const HandleClearUploadImage = () => {
        setProfilePictureRecipe({
            ...profilePictureRecipe,
            code: '',
            photo: '',
            photoExt: '',
            photoName: ''
        })
    }

    //------------------ For Hit HandleEdit Employee Master When get Emp Code by URL-------------------//
    useEffect(() => {
        if (EmployeeCodeByUrl === null) return;
        else {
            handleEdit(EmployeeCodeByUrl)

        }
    }, [EmployeeCodeByUrl])

    // console.log(EmployeeInEditTime,'EmployeeInEditTime')
    return (
        <>

            <div className='row'>
                {(employee || EmployeeCodeByUrl) &&
                    <InputBox
                        inputrefs={inputrefs}
                        customclass='col-lg-6 mb-2'
                        divclassname='col-lg-6 mb-2'
                        disabled
                        label='Employee : &nbsp;'
                        id='txtEmpCode'
                        key='txtEmpCode'
                        maxLength='50'
                        placeholder="SYSTEM GENERATED"
                        value={EmployeeCodeByUrl ? EmployeeInEditTime ? `${EmployeeInEditTime?.EmpCode} : ${EmployeeInEditTime?.EmpName}` : "" : employee ? `${employee?.employee}` : ''}
                        labelleft={true}
                    //onChange={(e) => { HandleChange('EmpCode', (e.target.value)) }}

                    />
                }

                <p className={(employee || EmployeeCodeByUrl) ? 'col-lg-6 col-md-3 col-sm-3 pt-2 mb-2 text-end' : 'col-lg-12 pt-2 mb-2 text-end'} onClick={() => EmployeeCodeByUrl ? (navigate(`/Employee/EmployeeMaster`) || window.location.reload()) : window.location.reload()} style={{ cursor: 'pointer' }}>Add New Employee</p>

            </div>
            {/*   <!-- Modal -->*/}
            <Modal show={ShowUploadImageModal} onHide={() => setShowUploadImageModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Profile Picture</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputBox
                        inputrefs={inputrefs}
                        label='Employee'
                        placeholder='Employee'
                        divclassname='mb-2'
                        id='txtEmployee'
                        maxLength='5'
                        //   value={EmployeeCodeByUrl ? EmployeeCodeByUrl : employee ? employee.employee : ''}
                        value={EmployeeCodeByUrl ? EmployeeInEditTime ? `${EmployeeInEditTime?.EmpCode} : ${EmployeeInEditTime?.EmpName}` : "" : employee ? `${employee?.employee}` : ''}
                        //onChange={(e) => { ('',(e.target.value)) }}
                        required
                        disabled
                    //error={errors.ContractDays}
                    />
                   
                    <FileUploader
                        ref={ref => (inputrefs.current['employeefileImage'] = ref)}
                        id='employeefileImage'
                        name='ProfilePicture'
                        label=' Upload a right file here'
                        classes='ProfilePicture mb-2'
                        types={fileType.split(",")}
                        handleChange={(file) => {
                            let extArr = (file.name).split(".");
                            if (file) {
                                console.log(file.result, 'from log')
                                const reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = () => {
                                    let logo = (reader.result).split(',')[1]
                                    setProfilePictureRecipe({
                                        ...profilePictureRecipe,
                                        //  photo: reader.result,
                                        photo: logo,
                                        photoExt: extArr[1],
                                        photoName: extArr[0]
                                    });
                                };
                                const fileNameAttribute = document.querySelectorAll('.ProfilePicture')
                                if (fileNameAttribute.length > 0) {
                                    //fileNameAttribute[0].childNodes[2].innerHTML = `${file.name}`;
                                    fileNameAttribute[0].childNodes[2].innerHTML = `<div class="sc-kAyceB DimIH"><span class="sc-imWYAI jRuVVs">${file.name}</span></div>`
                                    //   fileNameAttribute[0].childNodes[1].innerHTML = `<p>hii</p>`;
                                    //  console.log(fileNameAttribute[0].childNodes)
                                }
                            }
                        }}

                    />
                    <img
                        src={profilePictureRecipe.photo ? `data:image/png;base64,${profilePictureRecipe.photo}` : 'http://in.payplushrms.com/ImageHandler.ashx?ID=10367&Type=Profile'}
                        style={{ height: '150px', width: '120px', }}
                        alt='Employee Profile '
                        className='border img-thumbnail'
                    // src={`data:image/png;base64,${profilePictureRecipe.photo}`}
                    />
                </Modal.Body>
                <Modal.Footer>

                    <button type="button" className="btn btn-rounded btn-sm btn-success" onClick={HandleUploadImage}> <span className="text-white me-2"><i className="fa-solid fa-check"></i></span>Save </button>
                    <button type="button" className="btn btn-rounded btn-sm btn-danger" onClick={HandleClearUploadImage}> <span className="text-white me-2"><i className="fa-solid fa-arrow-rotate-left"></i></span>Reset </button>
                </Modal.Footer>
            </Modal>



            <div>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                        <a className={`nav-link  ${activeTab === 'PersonalDetails' ? 'active' : ''}`}
                            style={activeTab === 'PersonalDetails' ? { cursor: 'pointer', borderTop: '2px solid black' } : { cursor: 'pointer', border: '1px solid white', color: 'black' }}
                            id="PersonalDetails-tab"
                            onClick={() => handleTabClick('PersonalDetails')}
                        >Personal Details</a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeTab === 'EmploymenteDetails' ? 'active' : ''}`}
                            style={activeTab === 'EmploymenteDetails' ? { cursor: 'pointer', borderTop: '2px solid black' } : { cursor: 'pointer', border: '1px solid white', color: 'black' }}
                            id="EmploymenteDetails-tab"
                            onClick={() => handleTabClick('EmploymenteDetails')}
                          //  onClick={() => handleTabClick('EmploymenteDetails', activeTab === 'EmploymenteDetails'? 'EmploymenteDetails' :'')}
                        >
                            Employment Details
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeTab === 'OtherDetails' ? 'active' : ''}`}
                            style={activeTab === 'OtherDetails' ? { cursor: 'pointer', borderTop: '2px solid black' } : { cursor: 'pointer', border: '1px solid white', color: 'black' }}
                            id="OtherDetails-tab"
                            onClick={() => handleTabClick('OtherDetails')}
                        >
                            Other Details
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeTab === 'EmployeeSalaryStructure' ? 'active' : ''}`}
                            style={activeTab === 'EmployeeSalaryStructure' ? { cursor: 'pointer', borderTop: '2px solid black' } : { cursor: 'pointer', border: '1px solid white', color: 'black' }}
                            id="EmployeeSalaryStructure-tab"
                            onClick={() => handleTabClick('EmployeeSalaryStructure')}
                        >
                            Salary Structure
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeTab === 'EmployeeFamilyDetails' ? 'active' : ''}`}
                            style={activeTab === 'EmployeeFamilyDetails' ? { cursor: 'pointer', borderTop: '2px solid black' } : { cursor: 'pointer', border: '1px solid white', color: 'black' }}
                            id="EmployeeFamilyDetails-tab"
                            onClick={() => handleTabClick('EmployeeFamilyDetails')}
                        >
                            Family Details
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeTab === 'DocumentUpload' ? 'active' : ''}`}
                            style={activeTab === 'DocumentUpload' ? { cursor: 'pointer', borderTop: '2px solid black' } : { cursor: 'pointer', border: '1px solid white', color: 'black' }}
                            id="DocumentUpload-tab"
                            onClick={() => handleTabClick('DocumentUpload')}
                        >
                            Document Upload
                        </a>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className={`tab-pane fade ${activeTab === 'PersonalDetails' ? 'show active' : ''}`} id="PersonalDetails" role="tabpanel">
                        <EmployeePersonalDetails
                            handleTabClick={handleTabClick}
                            employee={employee}
                            recipe={recipe}
                            setRecipe={setRecipe}
                            setDateOfBirth={setDateOfBirth}
                            dateOfBirth={dateOfBirth}
                            profilePictureRecipe={profilePictureRecipe}
                            EmployeeCodeByUrl={EmployeeCodeByUrl}
                            EmployeeInEditTime={EmployeeInEditTime}
                            errorFocus={errorFocus}
                            setErrorFocus={setErrorFocus}
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            imageData={imageData}
                            setIsPerAddressSame={setIsPerAddressSame}
                            isPerAddressSame={isPerAddressSame}
                            TokenData={TokenData}
                            sessionExpired={sessionExpired}
                            setShowUploadImageModal={setShowUploadImageModal}
                        />

                    </div>
                    <div className={`tab-pane fade ${activeTab === 'EmploymenteDetails' ? 'show active' : ''}`} id="EmploymenteDetails" role="tabpanel">
                        <EmploymenteDetails handleTabClick={handleTabClick}
                            recipe={recipe}
                            setRecipe={setRecipe}
                            dateOfJoining={dateOfJoining}
                            setDateOfJoining={setDateOfJoining}
                            inTime={inTime}
                            setInTime={setInTime}
                            outTime={outTime}
                            setOutTime={setOutTime}
                            dateOfConfirmation={dateOfConfirmation}
                            setDateOfConfirmation={setDateOfConfirmation}
                            dateOfAccountUpdate={dateOfAccountUpdate}
                            setDateOfAccountUpdate={setDateOfAccountUpdate}
                            //handleNext={handleNext}
                            employee={employee}
                            setErrorFocus={setErrorFocus }
                            setEmployee={setEmployee}
                            WeeklyOffDetailsList={WeeklyOffDetailsList ? WeeklyOffDetailsList : []}
                            setWeeklyOffDetailsList={setWeeklyOffDetailsList}
                            EmployeeInEditTime={EmployeeInEditTime}
                            EmployeeCodeByUrl={EmployeeCodeByUrl}
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            setIsPerAddressSame={setIsPerAddressSame}
                            isPerAddressSame={isPerAddressSame}
                            TokenData={TokenData}
                            sessionExpired={sessionExpired}
                        />
                    </div>
                    <div className={`tab-pane fade ${activeTab === 'OtherDetails' ? 'show active' : ''}`} id="OtherDetails" role="tabpanel">
                        <OtherDetails
                            handleTabClick={handleTabClick}
                            employee={employee}
                            PreviousExperienceDetailsList={PreviousExperienceDetailsList ? PreviousExperienceDetailsList : []}
                            setPreviousExperienceDetailsList={setPreviousExperienceDetailsList}
                            QualificationDetailsList={QualificationDetailsList ? QualificationDetailsList : []}
                            setQualificationDetailsList={setQualificationDetailsList}
                            recipe={otherDetailsRecipe}
                            setRecipe={setOtherDetailsRecipe}
                            EmployeeInEditTime={EmployeeInEditTime}
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            EmployeeCodeByUrl={EmployeeCodeByUrl}
                            TokenData={TokenData}
                            sessionExpired={sessionExpired}
                            retiredEmployee={retiredEmployee}

                        />
                    </div>
                    <div className={`tab-pane fade ${activeTab === 'EmployeeSalaryStructure' ? 'show active' : ''}`} id="EmployeeSalaryStructure" role="tabpanel">
                        <EmployeeSalaryStructure
                            handleTabClick={handleTabClick}
                            setActiveTab={setActiveTab}
                            employee={employee}
                            EmployeeCodeByUrl={EmployeeCodeByUrl}
                            EmployeeInEditTime={EmployeeInEditTime}
                            activeTab={activeTab}
                            TokenData={TokenData}
                            sessionExpired={sessionExpired}
                        />
                    </div>
                    <div className={`tab-pane fade ${activeTab === 'EmployeeFamilyDetails' ? 'show active' : ''}`} id="EmployeeFamilyDetails" role="tabpanel">
                        <EmployeeFamilyDetails
                            handleTabClick={handleTabClick}
                            setActiveTab={setActiveTab}
                            employee={employee}
                            EmployeeCodeByUrl={EmployeeCodeByUrl}
                            EmployeeInEditTime={EmployeeInEditTime}
                            activeTab={activeTab}
                            TokenData={TokenData}
                            sessionExpired={sessionExpired} />
                    </div>
                    <div className={`tab-pane fade ${activeTab === 'DocumentUpload' ? 'show active' : ''}`} id="DocumentUpload" role="tabpanel">
                        <EmployeeDocumentUpload
                            handleTabClick={handleTabClick}
                            setActiveTab={setActiveTab}
                            employee={employee}
                            EmployeeCodeByUrl={EmployeeCodeByUrl}
                            EmployeeInEditTime={EmployeeInEditTime}
                            activeTab={activeTab}
                            TokenData={TokenData}
                            sessionExpired={sessionExpired}
                        />
                    </div>
                </div>
            </div>

            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}
        </>
    )
}

export default EmployeeMaster;
