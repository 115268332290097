import { Link, useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import { ColorRing } from 'react-loader-spinner'
import Pagination from '@mui/material/Pagination';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import * as XLSX from "xlsx";
import * as debounce from 'lodash/debounce';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getSearchingColumn } from "../../apiservices/CommonServices";
import { useRecoilState } from 'recoil';

import { useAuth } from '../../context/auth';
import { post } from '../../apiservices/service';
import { API_SERVER, NotificationCount } from '../../Utils';

export const Token = () => {
    const { user } = useAuth();

    const allToken = {
        AuthToken: process.env.REACT_APP_SECRET_KEY,
        LoginToken: user.loginsessionID,
        UserID: user.userID,
        CompanyID: user.companyID,
        Company: user.company,
    }
    return allToken;
}


export const number = (Value) => {
    Value = Value.replace(/[^0-9]/g, '')
    return Value;//.replace(/[^0-9]/g, '');
}
export const numberwithDot = (Value) => {
    Value = Value.replace(/[^0-9.]/g, '')
    return Value;//.replace(/[^0-9]/g, '');
}
export const numberWithComma = (Value) => {
    Value = Value.replace(/[^0-9,]/g, '')
    return Value;//.replace(/[^0-9]/g, '');
}
export const dateFormate = (value) => {
    let dateArr = value?.toString()?.split(' ');
    dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
    return dateArr;

}

export const currentYear = (date) => date.toString().split(" ")[3];
export const currentMonth = (date, type) => date.toLocaleString('default', { month: type });


export const isValidIFSCCode = (str) => {
    // Regex to check valid IFSC Code.
    let regex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    return str.trim().match(regex) != null;
}

export const handleSortingTable = (SortColumn, sortConfig, setSortConfig) => {
    let SortOrder = 'ASC';

    if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
        SortOrder = 'DESC';
    }
    setSortConfig({ SortColumn, SortOrder });
};



export const BindGrid = ({ Token, tableData, filterData, setFilterData, loading, handleEditData, handleDeleteData, FormName, Filter = true, handleFilter }) => {
    const [FilterList, setFilterList] = useState([]);

    useEffect(() => {
        if (FilterList.length > 0) {
            setFilterData({ ...filterData, SearchFieldValue: FilterList[0].valuePart });
        }
    }, [FilterList]);

    const countNo = Math.ceil((parseInt(tableData.totalRecord) / parseInt(10)))

    const serching = async () => {
        if (Filter) {
            getSearchingColumn({ ...Token, PageName: FormName }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        console.log("status", res.status)
                    } else {
                        let data = res
                        setFilterList(data.dataList)
                        //setAdvanceSearchingData(data.advanceDataList)
                        setFilterData({ ...filterData, SearchFieldValue: data.dataList[0].valuePart })
                    }
                }
            });
        }
    };
    useEffect(() => {
        serching();
        //getSearchingList({ PageName: FormName, Token: Token, setFilterList })
    }, []);
    return (
        <>

            <>  {
                tableData ? (
                    <div className='main-card card border-0' >
                        <div className='card-header mb-1'>
                            <div className='row justify-content-between align-items-center'>
                                <div className='col-md-4'>
                                    <h6 className='m-0'>{`${tableData?.totalRecord !== undefined ? 'Total Records : ' + tableData?.totalRecord : 'No Record Found'}`}</h6>
                                </div>
                                {Filter ?
                                    <div className='col-md-8 col-lg-6 d-flex flex-row filters'>
                                        <select
                                            className="form-select me-2"
                                            aria-label="Default select example"
                                            placeholder='Please select'
                                            value={filterData.SearchFieldValue}
                                            onChange={(e) => setFilterData({ ...filterData, SearchFieldValue: e.target.value })}>

                                            {FilterList ? (FilterList.map((item, index) => (
                                                <option key={index} value={item.textPart}>{item.textPart}</option>
                                            ))) : (<></>)}
                                        </select>
                                        <select className="form-select me-2" aria-label="Condition"
                                            value={filterData?.StartValue} onChange={(e) => setFilterData({ ...filterData, StartValue: e.target.value })}
                                        >
                                            <option value="1">Exactly</option>
                                            <option value="2">Contains</option>
                                            <option value="3">Start With</option>
                                        </select>
                                        <input type="text"
                                            className="form-control search"
                                            placeholder="Search"
                                            value={filterData.SearchText}
                                            onChange={(e) => {
                                                setFilterData({ ...filterData, SearchText: e.target.value });
                                            }}
                                        />
                                        <button onClick={handleFilter} className="btn search-btn me-2"><i className="fa-solid fa-magnifying-glass"></i></button>
                                    </div>
                                    : <></>}
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="table-responsive">
                                {tableData?.totalRecord > 0 ?
                                    (
                                        tableData?.totalRecord !== null ? (<table className="table table-bordered table-striped" id="dataTable" width="100%" cellSpacing="0">
                                            <thead className='light-bg'>
                                                <tr>
                                                    {Object.keys(tableData.gridData[0]).map((header, index) => (

                                                        (<th key={index}>{header}</th>)

                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableData?.gridData !== null ?
                                                    (
                                                        (tableData?.gridData)?.map((item, rowIndex) => (
                                                            <tr key={rowIndex} className="TableTrhover">
                                                                {Object.values(item).map((value, cellIndex) => (
                                                                    <td key={cellIndex}>{value ?
                                                                        (
                                                                            (Object.keys(item)[cellIndex] === 'Edit' || Object.keys(item)[cellIndex] === 'Del') ?
                                                                                (
                                                                                    (Object.keys(item)[cellIndex] === 'Edit' ? (
                                                                                        <i title="Edit" className="fa-regular fa-pen-to-square text-success me-2" style={{ fontSize: '15px' }}
                                                                                            onClick={() => handleEditData(value.toString())}>
                                                                                            <i className="bi bi-pencil-square" />
                                                                                        </i>) : (<i title="Delete" className="fa-regular fa-trash-can text-danger me-2" style={{ fontSize: '15px' }}
                                                                                            onClick={() => handleDeleteData(value.toString())}>
                                                                                            <i className="bi bi-trash3" />
                                                                                        </i>)
                                                                                    )
                                                                                ) : (value)
                                                                        ) : (<></>)
                                                                    }
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))
                                                    ) : (<></>)}
                                            </tbody>
                                        </table>) : (<>No Record Found !.</>)

                                    ) : (<></>)}
                            </div>
                            {countNo > 1 ?
                                (<Pagination count={countNo} variant="outlined" page={parseInt(filterData.PageIndex)}
                                    onChange={(event, value) => setFilterData({ ...filterData, PageIndex: value.toString() })} shape="rounded" />)
                                :
                                (<></>)}
                        </div>
                    </div >

                ) : (<> </>)
            }

            </>
        </>
    )
}




//Handle Get Verify Candidate 
export const GetNoificaitonCount = (notificationData, setNotificationData, TokenData, sessionExpired) => {
    post(`${API_SERVER}api/Dashboard/GetPendingVerification`, { ...TokenData, }, (res) => {
        if (res && res.status) {
            if (res.status === "ERROR") {

            } else if (res.status === "EXPIRED") {
                toast.error(res.message)
                sessionExpired()
            } else if (res.status === "UNAUTHORIZED") {

            } else {
                setNotificationData({
                    ...notificationData,
                    pendingVerificationData: res.data.pendingVerification,
                    pendingConvertToEmployee: res.data.pendingConvertToEmployee,
                    totalNotification: Number(res.data.pendingVerification) + Number(res.data.pendingConvertToEmployee)
                })
            }
        }
    });
}
