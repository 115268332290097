import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, AutoCompleteBox, Switch, DateBox, CheckBoxInput } from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
import { useNavigate } from 'react-router-dom'

//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';

//services
import { getCommonData } from '../../apiservices/CommonServices';
import { post } from '../../apiservices/service';
import { dateFormate, number, Token } from '../Common/Common';


function SearchCandidate() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();

    //token Data
    //const TokenData = {
    //    AuthToken: process.env.REACT_APP_SECRET_KEY,
    //    LoginToken: user.loginsessionID,
    //    UserID: user.userID,
    //    CompanyID: user.companyID
    //}
    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({
        //  CandidateID: '0',
        CandidateCode: '',
        CandidateName: '',
        FatherName: '',
        DOB: '',
        MobileNo: '',
        FromDate: '',
        ToDate: '',
        PageType: "SearchCandidate"


    })
    const navigate = useNavigate()
    const [showTable, setShowTable] = useState(false)
    const [errors, setErrors] = useState({});
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)

    const [isUpdate, setIsUpdate] = useState(false)
    const [dateOfBirth, setDateOfBirth] = useState(new Date());
    // const [dateOfBirthE, setDateOfBirthE] = useState(new Date());
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());


    //const getFormData = (CurrentPage) => {
    //    let PageIndex = 1
    //    if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
    //        PageIndex = CurrentPage;
    //        setCurrentPageNo(CurrentPage);
    //    }
    //    else {
    //        setCurrentPageNo(1);
    //    }

    //    setLoading(true)

    //    let temp = {
    //        PageIndex: PageIndex.toString(),
    //        SearchText: "",
    //        StartValue: "1",
    //        SearchFieldValue: "",
    //        Type: "s"
    //    }
    //    post(`${API_SERVER}api/Candidate/SearchVerifyCandidate`, { ...TokenData, ...temp, ...sortConfig, ...filterData, ...recipe, PageType: "SearchCandidate" }, (res) => {
    //        if (res && res.Status) {
    //            if (res.Status === "ERROR") {
    //                setLoading(false)
    //                setResult({ ...res })
    //            } else {
    //                setLoading(false)
    //                setResult({ ...res })
    //            }
    //        }
    //    });
    //}





    //Change Handler
    const HandleChange = (prop, value) => {
        if (prop === 'DOB') {
            setDateOfBirth(value)
        } else if (prop === 'ToDate') {
            setToDate(value)
        } else if (prop === 'FromDate') {
            setFromDate(value)
        }
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //validation handler
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "CandidateCode", msg: 'Please Enter Candidate Code.' },
            { field: "CandidateName", msg: 'Please Enter Candidate Name.' },
            { field: "FatherName", msg: 'Please Enter Father Name.' },
            { field: "DOB", msg: 'Please Enter DOB.' },
            { field: "MobileNo", msg: 'Please Enter Mobile No.' },
            { field: "EmailID", msg: 'Please Enter Email ID.' },
            { field: "FromDate", msg: 'Please Select From Date.' },
            { field: "ToDate", msg: 'Please Enter ToDate.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });


        return isValid;
    }

    //Submit Handler
    const handleSubmit = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        // setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/Candidate/SearchVerifyCandidate`, { ...TokenData, ...temp, ...sortConfig, ...filterData, ...recipe, PageType: "SearchCandidate" }, (res) => {

            // console.log('1111111111111111111111111',res)
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                    setResult({ ...res })
                    //  console.log(recipe, 'if')
                } else if (res.status === "EXPIRED") {
                    toast.error(res.message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    toast.success(res.message)
                    setResult({ ...res })
                    setShowTable(true)
                    //getFormData()
                    // handleClear()
                    //console.log(recipe, 'else')
                }
            }
        });

    }

    const handleChange1 = (event, value) => {
        // getFormData(value);
        handleSubmit(value);
    };
    //Handle Edit
    const handleEdit = (Id, item) => {
        if (item.CandidateCode) {
            navigate(`/Candidate/CandidateMaster?CandidateCode=${item.CandidateCode}`)
        }
        //setLoading(true)
        //post(`${API_SERVER}api/Candidate/EditCandidate`, { ...TokenData, CandidateID: Id }, (res) => {
        //    if (res && res.status) {
        //        if (res.status === "ERROR") {
        //            toast.error(res.message)
        //            setLoading(false)
        //        } else {
        //            setLoading(false)
        //            let data = res.data
        //            setRecipe(recipe => ({

        //                CandidateID: data.candidateID,
        //                CandidateName: data.candidateName,
        //                FatherName: data.fatherName,
        //                DOB: data.dob,
        //                MobileNo: data.mobileNo,
        //                EmailID: data.emailID,
        //                Department: data.department,
        //                Designation: data.designation,
        //                FromDate: data.fromDate,
        //                ToDate: data.toDate,
        //                Gender: data.gender,
        //            }))
        //        }
        //    }
        //});


    }

    //Handle Delete
    const handleDelete = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/Candidate/DeleteCandidate`, { ...TokenData, CandidateID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.status === "EXPIRED") {
                    toast.error(res.message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    toast.success(res.message)
                    setLoading(false)
                   // getFormData()
                    //getFormData()
                    handleSubmit()
                }
            }
        });
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            //  CandidateID: '0',
            CandidateCode: '',
            CandidateName: '',
            FatherName: '',
            DOB: '',
            MobileNo: '',
            FromDate: '',
            ToDate: '',
            PageType: "SearchCandidate"
        }))
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        setShowTable(false)
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };




    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        ...recipe,
        Type: 'E',
        PageIndex: '1',
        PageType: "SearchCandidate"

    }
    //End Export functionality

    useEffect(() => {
        inputrefs.current['txtCandidateCode'].focus()
        document.title = 'Pay Plus: Search Candidate'

        //    getFormData()
    }, [sortConfig, isUpdate])


    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Search Candidate</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtName'].focus() };
                            }}
                            label='Candidate Code'
                            id='txtCandidateCode'
                            maxLength='50'
                            placeholder="Candidate Code"
                            value={recipe.CandidateCode}
                            onChange={(e) => { HandleChange('CandidateCode', (e.target.value)) }}
                        //required
                        //  error={errors.CandidateCode}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtFatherName'].focus() };
                            }}
                            label='Candidate Name'
                            id='txtName'
                            maxLength='50'
                            placeholder="Candidate Name"
                            value={recipe.CandidateName}
                            onChange={(e) => { HandleChange('CandidateName', (e.target.value)) }}
                        //  required
                        //  error={errors.CandidateName}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtDOB'].focus() };
                            }}
                            label={`Father's Name`}
                            id='txtFatherName'
                            maxLength='50'
                            placeholder={`Father's Name`}
                            value={recipe.FatherName}
                            onChange={(e) => { HandleChange('FatherName', (e.target.value)) }}
                        //   required
                        //  error={errors.FatherName}
                        />

                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtMobileNo'].focus() };
                            }}
                            label='DOB'
                            id='txtDOB'
                            selected={recipe.DOB === "" ? "" : new Date(recipe.DOB)}
                            placeholder="DOB"
                           // value={recipe.DOB}
                            onChange={(e) => HandleChange('DOB', dateFormate(e))}
                        //   required
                        //     error={errors.DOB}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtEmailID'].focus() };
                            }}
                            label='Mobile No.'
                            id='txtMobileNo'
                            maxLength='10'
                            placeholder="Mobile No."
                            //  required
                            //   error={errors.MobileNo}
                            value={recipe.MobileNo} onChange={(e) => { HandleChange('MobileNo', number(e.target.value)) }}
                        />

                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtToDate'].focus() };
                            }}
                            label='From Date'
                            id='txtFromDate'
                            selected={recipe.FromDate === "" ? "" : new Date(recipe.FromDate)}
                            placeholder="From Date"
                          //  value={recipe.FromDate}
                            onChange={(e) => HandleChange('FromDate', dateFormate(e))}
                        // required
                        //  error={errors.FromDate}
                        />
                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { handleSubmit() };
                            }}
                            label='To Date'
                            id='txtToDate'
                            selected={recipe.ToDate === "" ? "" : new Date(recipe.ToDate)}
                            placeholder="To Date"
                           // value={recipe.ToDate}
                            onChange={(e) => HandleChange('ToDate', dateFormate(e))}
                        //   required
                        //  error={errors.ToDate}
                        />

                        <FormButton
                            SaveText='Search'
                            saveButtonIcon='fa-search'
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/Candidate/SearchVerifyCandidate', ExportRequest, 'Search Candidate')}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>

            {/* Table Component start */}
            {showTable &&

                <Table
                    Filter={false}
                    Token={TokenData}
                    PageName='SearchCandidate'
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                  //  handleFilter={() => {
                  //      // getFormData();
                  //      handleSubmit();
                  //      setFilterData({
                  //          ...filterData,
                  //      });
                  //  }}
                    tableData={result}
                    loading={loading}
                  //  filterData={filterData}
                  //  setFilterData={setFilterData}
                    currentPageNo={currentPageNo}
                    handleChange1={handleChange1}
                  //  handleSorting={handleSorting}
                  //  sortConfig={sortConfig}
                />
            }
            {/* Table Component End*/}


            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}

        </>
    )
}

export default SearchCandidate;