
import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, AutoCompleteBoxWithLabel, DateBox, MonthBox, YearBox } from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
//loder
import BlurLoader from "../Loder/BlurLoader";
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { getCommonData, getFormatData, getMasterData } from '../../apiservices/CommonServices';
import { Token, currentYear, dateFormate, number, numberwithDot } from '../Common/Common';
import TextEditor from '../commoncomponents/TextEditor';

function CandidateOfferLetter() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();

    //token Data
    const TokenData = Token()

    //initial state
    const [recipe, setRecipe] = useState({
        branch: '',
        candidate: '',
        company: TokenData.Company,

    })

    const [loading, setLoading] = useState(false);
    const [branchAllData, setBranchAllData] = useState([])
    const [candidateAllData, setCandidateAllData] = useState([])
    const [companyAllData, setCompanyAllData] = useState([])
    const [errors, setErrors] = useState({});
    const [textAreaFormate, setTextAreaFormate] = useState([]);

    const [result, setResult] = useState({});
    const [currentPageNo, setCurrentPageNo] = useState(1);
    //Get company Data for AutoComplete
    const GetCompanyData = (value) => {
        setCompanyAllData([])
        let temp = {
            Type: "GetCompany",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        //  setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setCompanyAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setCompanyAllData(data)
                }
            }
        });
    }


    //Get branch Data for AutoComplete
    const GetBranchData = (value) => {
        setBranchAllData([])
        let temp = {
            Type: "GetBranch",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        //   setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setBranchAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setBranchAllData(data)
                }
            }
        });
    }


    //Get candidate Data for AutoComplete with call GetHistory API
    const GetCandidateData = (value) => {
        setCandidateAllData([])
        let temp = {
            Type: "GetCandidate",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        //   setLoading(true)
        handleGetHistory(value)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setCandidateAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setCandidateAllData(data)
                }
            }
        });
    }

    //Change Handler
    const HandleChange = (prop, value) => {

        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    // Handle Validation
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "company", msg: 'Please Select Company.' },
            { field: "branch", msg: 'Please Select Branch.' },
            { field: "candidate", msg: 'Please Select Candidate.' },
            //  { field: "appointmentDate", msg: 'Please Enter Appointment Date.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {

                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }

    // Get History Data for table 
    const handleGetHistory = (emp, CurrentPage) => {
        //  console.log(emp, 'employe')
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s",
            candidate: emp ? emp : '',
        }
        post(`${API_SERVER}api/Print/GetCandidateOfferLetterHistory`, { ...TokenData, ...temp, }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }

    // Handle change for table Pagination
    const handleChange1 = (event, value) => {

        handleGetHistory(recipe.candidate, value);
    };

    // Handle Print and Insert  Formate
    const handlePrint = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/Print/InsertCandidateOfferLetterFormat`, { ...TokenData, ...recipe, CandidateOfferLetterFormat: textAreaFormate }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        setLoading(false)
                        toast.error(res.message)
                        inputrefs.current[res.focus].focus();
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "UNAUTHORIZED") {
                        setLoading(false);
                        toast.error(res.Message);
                    } else {
                        setLoading(false)
                        let base64PDF = res && res.candidateOfferLetterFormat
                        window.open(`/PdfView/#data:application/pdf;base64,${base64PDF}`, '_blank')
                        setTimeout(handleGetHistory(recipe.candidate), 1000)
                    }
                }
            });
        }
    }

    // Handle Print PDF Form Table
    const PrintPDFFromTable = (ID) => {
        post(`${API_SERVER}api/Print/PrintCandidateOfferLetter`, { ...TokenData, CandidateOfferLetterID: ID, Candidate: recipe.candidate }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)

                    let base64PDF = res && res.candidateOfferLetterFormat
                    window.open(`/PdfView/#data:application/pdf;base64,${base64PDF}`, '_blank')

                }
            }
        });
    }

    //Handle Get Candidate Offer Letter Formate
    const GetLetterFormatPreview = () => {
        let temp = {
            formatTypeName: "Candidate Offer Letter",
            candidateCode: recipe.candidate
        }
        setLoading(true)
        post(`${API_SERVER}api/Print/PreviewCandidateOfferLetter`, { ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    setTextAreaFormate(res.candidateOfferLetterFormat)
                   // setRecipe({ ...recipe, candidateOfferLetterFormat: res.candidateOfferLetterFormat })
                }
            }
        });
    }

    //console.log(recipe, 'RecipeRecipeRecipe')
    //Reset Handler
    const handleClear = () => {
        setRecipe({
            ...recipe,
            branch: '',
            candidate: '',
            company: TokenData.Company,
           // candidateOfferLetterFormat: ''
        })
        setTextAreaFormate('')
        setErrors({})
        setResult({})
        inputrefs.current['txtCompany'].focus();
        //  GetCompanyData();
        //   GetBranchData();
        //  GetCandidateData();
        //  handleGetHistory()
        //window.location.reload();
    }

    let CompanyData = []
    for (let i = 0; i < companyAllData.length; i++) {
        let mydata = companyAllData[i]?.values
        CompanyData.push(mydata)
    };

    let BranchData = []
    for (let i = 0; i < branchAllData.length; i++) {
        let mydata = branchAllData[i]?.values
        BranchData.push(mydata)
    };

    let CandidateData = []
    for (let i = 0; i < candidateAllData.length; i++) {
        let mydata = candidateAllData[i]?.values
        CandidateData.push(mydata)
    };


    useEffect(() => {
        // inputrefs.current['txtBranch'].focus();
       // console.log('ram')
        inputrefs.current['txtCompany'].focus();
        document.title = 'Pay Plus : Candidate Offer Letter';
        GetCompanyData();
          GetBranchData();
           GetCandidateData();
    }, [])





    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Candidate Offer Letter</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <div class="row">
                                <AutoCompleteBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtBranch'].focus() };
                                    }}
                                    divclassname='col-md-4'
                                    id='txtCompany'
                                    disabled
                                    label='Company'
                                    placeholder="Company"
                                    maxLength='50'
                                    options={CompanyData}
                                    key='txtCompany'
                                    required={true}
                                    error={errors.company}
                                    inputValue={recipe.company ? recipe.company : ''}
                                    onInputChange={(event, newInputValue) => {
                                        GetCompanyData(newInputValue)
                                        HandleChange('company', newInputValue)

                                    }}
                                   // onFocus={() => GetCompanyData()}
                                />

                                <AutoCompleteBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtCandidate'].focus() };
                                    }}
                                    divclassname='col-md-4'
                                    label='Branch'
                                    placeholder="Branch"
                                    maxLength='50'
                                    options={BranchData}
                                    id='txtBranch'
                                    key='txtBranch'
                                    required={true}
                                    error={errors.branch}
                                    inputValue={recipe.branch ? recipe.branch : ''}
                                    onInputChange={(event, newInputValue) => {
                                        GetBranchData(newInputValue)
                                        HandleChange('branch', newInputValue)

                                    }}
                                  //  onFocus={() => GetBranchData()}
                                />
                                <AutoCompleteBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleGetHistory(e.target.value);
                                            // inputrefs.current['txtAppointmentDate'].focus();
                                        };
                                    }}
                                    divclassname='col-md-4'
                                    label='Candidate'
                                    placeholder="Candidate"
                                    maxLength='50'
                                    options={CandidateData}
                                    id='txtCandidate'
                                    key='txtCandidate'
                                    required={true}
                                    error={errors.candidate}
                                    inputValue={recipe.candidate ? recipe.candidate : ''}
                                    onInputChange={(event, newInputValue) => {
                                        GetCandidateData(newInputValue)
                                        HandleChange('candidate', newInputValue)
                                        //handleGetHistory(newInputValue)

                                    }}
                                  //  onFocus={() => GetCandidateData()}
                                />
                                {/*<DateBox*/}
                                {/*    inputrefs={inputrefs}*/}
                                {/*    onKeyDown={(e) => {*/}
                                {/*        if (e.key === 'Enter') { handleGetHistory(recipe.employee) };*/}
                                {/*    }}*/}
                                {/*    //labelClass='text-nowrap'*/}
                                {/*    divclassname='col-md-4'*/}
                                {/*    id='txtAppointmentDate'*/}
                                {/*    label='Appointment Date'*/}
                                {/*    placeholder="Appointment Date"*/}
                                {/*    selected={recipe.appointmentDate === '' ? '' : new Date(recipe.appointmentDate)}*/}
                                {/*    onChange={(e) => {*/}
                                {/*        HandleChange('appointmentDate', dateFormate(e))*/}
                                {/*        // handleGetHistory() */}
                                {/*    }}*/}
                                {/*    error={errors.appointmentDate}*/}
                                {/*    required*/}
                                {/*/>*/}
                                <FormButton
                                    saveButtonIcon='fa-search'
                                    SaveText='Preview'
                                    Export={false}
                                    reset={false}
                                    handleSubmit={GetLetterFormatPreview}
                                />

                            </div>
                        </div>

                        <div className='col-md-6 col-sm-6 col-xs-6'>

                            <Table
                                isOverFlow={true}
                                Filter={false}
                                Token={TokenData}
                                totalRecordText="History"
                                PageName='CandidateOfferLetter'
                                handlePrintPdfData={PrintPDFFromTable}
                                // handleCheckData={handleCheckData}
                                // handleFilter={() => {
                                //     // getFormData();
                                //     handleSubmit();
                                //     setFilterData({
                                //         ...filterData,
                                //     });
                                // }}
                                tableData={result}
                                loading={loading}
                                // filterData={filterData}
                                //    setFilterData={setFilterData}
                                currentPageNo={currentPageNo}
                                handleChange1={handleChange1}
                            //    handleSorting={handleSorting}
                            //    sortConfig={sortConfig}
                            />

                        </div>



                        <TextEditor
                            //value={recipe.candidateOfferLetterFormat}
                            // setValue={(e) => setRecipe({ ...recipe, candidateOfferLetterFormat: e })}
                            value={textAreaFormate}
                            setValue={(formate) => setTextAreaFormate(formate)}
                        />
                        <FormButton
                            save={false}
                            Export={false}
                            print={true}
                            handlePrint={handlePrint}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>



            {loading && < BlurLoader />}
        </>
    )
}

export default CandidateOfferLetter
