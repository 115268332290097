import React, { useEffect, useRef, useState } from 'react';
import { AutoCompleteBox, CheckBoxInput, DateBox, FormButton, InputBox, SelectInputBox } from "../commoncomponents/InputBox";

import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';

//loder

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER, initializeFilterData } from '../../Utils';

//services
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { post } from '../../apiservices/service';
import { dateFormate, number, numberwithDot } from '../Common/Common';

function EmployeeFamilyDetails({ TokenData, EmployeeCodeByUrl, setActiveTab, activeTab, employee }) {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();

    //initial state
    const [recipe, setRecipe] = useState({
        EmployeeID: '0',
        AadharNo: '',
        Address: '',
        CandidateFamilyDetailID: '',
        CandidateCode: EmployeeCodeByUrl ? EmployeeCodeByUrl : employee ? employee?.empCode : '0',
        District: '',
        DOB: '',
        //   EmployeeCode: '',
        EmergencyContactNo: '',
        IsResidingWithEmp: 'YES',
        Name: '',
        Percentage: '',
        Relation: '',
        State: '',
        IsNominee: false,
        Code: EmployeeCodeByUrl ? EmployeeCodeByUrl : employee ? employee?.empCode : '0'

    })
    // console.log(employee)

    const [errors, setErrors] = useState({});
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)

    const [isUpdate, setIsUpdate] = useState(false)
    const [dateOfBirth, setDateOfBirth] = useState()
    //const [statePre, setStatePre] = useState('')
    const [stateAllData, setStateAllData] = useState([])
    const [relationDetailData, setRelationDetailData] = useState([])



    const GetStateData = (value) => {
        let temp = {
            Type: "GetState",
            Prefix: value ? value : '',
            ContextKey: "",
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setStateAllData([])
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setStateAllData(data)
                }
            }
        });
    }

    const GetRelationData = () => {
        setRelationDetailData([])
        let temp = {
            Type: "GetRelation",
            Prefix: '',

        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setRelationDetailData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setRelationDetailData(data)
                }
            }
        });
    }

    // console.log(relationDetailData,'adfsdfdasf')
    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s",
            PageType: "Employee",
            Code: EmployeeCodeByUrl ? EmployeeCodeByUrl : employee ? employee?.empCode : '0'
        }
        //    console.log(employee)

        post(`${API_SERVER}api/Candidate/ShowCandidateFamilyDetails`, { ...TokenData, ...temp, ...sortConfig, ...filterData }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }
    const handleChange1 = (event, value) => {
        getFormData(value);
    };



    //Change Handler
    const HandleChange = (prop, value) => {
        if (prop === 'DOB') {
            //              console.log(value, 'sdgkjdf')

            setDateOfBirth(value)
        }

        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }
    const Validate1 = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "Name", msg: 'Please Enter Name.' },
            { field: "Relation", msg: 'Please State Relation.' },
            { field: "DOB", msg: 'Please Enter Date of Birth.' },
            { field: "IsResidingWithEmp", msg: 'Please Enter Residing with Employee.' },
            { field: "State", msg: 'Please State State.' },
            { field: "District", msg: 'Please Enter District.' },
            { field: "Address", msg: 'Please Enter Address.' },
            { field: "AadharNo", msg: 'Please Enter Aadhaar Card Number.' },
            { field: "EmergencyContactNo", msg: 'Please Enter Emergency Contact No.' },

        ]

        if (recipe['IsNominee'] === true) {
            validation.push(
                { field: "Percentage", msg: 'Please Enter Percentage.' },
            )
        };

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {

                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }

    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "Name", msg: 'Please Enter Name.' },
            { field: "Relation", msg: 'Please State Relation.' },
            { field: "DOB", msg: 'Please Enter Date of Birth.' },
            { field: "IsResidingWithEmp", msg: 'Please Enter Residing with Employee.' },
            { field: "State", msg: 'Please State State.' },
            { field: "District", msg: 'Please Enter District.' },
            { field: "Address", msg: 'Please Enter Address.' },
            { field: "AadharNo", msg: 'Please Enter Aadhaar Card Number.' },
            { field: "EmergencyContactNo", msg: 'Please Enter Emergency Contact No.' },


        ]

      
        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === null || 'undefind' || "") {
                //  console.log(isValid, 'daf', recipe)
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        return isValid;
    }

    //Submit Handler
    const handleSubmit = () => {
        // console.log(EmployeeCodeByUrl,'11111111111111111', Validate() ,recipe)
        console.log(Validate(), 'daf', recipe)
        if (Validate1()) {
            setLoading(true)
            post(`${API_SERVER}api/Candidate/UpdateCandidateFamilyDetails`, { ...TokenData, ...recipe, PageType: "Employee", CandidateCode: EmployeeCodeByUrl ? EmployeeCodeByUrl : employee ? employee?.empCode : '0', Code: EmployeeCodeByUrl ? EmployeeCodeByUrl : employee ? employee?.empCode : '0' }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        setLoading(false)
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "UNAUTHORIZED") {
                        toast.error(res.Message);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        getFormData()
                        //setActiveTab('PersonalDetails')
                        handleClear()
                    }
                }
            });
        }
    }

    //Handle Edit
    const handleEdit = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/Candidate/EditCandidateFamilyDetails`, { ...TokenData, CandidateFamilyDetailID: Id, PageType: "Employee" }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.status === "EXPIRED") {
                    toast.error(res.message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    let data = res.data
                    setRecipe(recipe => ({
                        CandidateFamilyDetailID: data.candidateFamilyDetailID,
                        CandidateCode: data.candidateCode,
                        Name: data.name,
                        Relation: data.relation,
                        IsResidingWithEmp: data.isResidingWithEmp,
                        State: data.state,
                        District: data.district,
                        Address: data.address,
                        Percentage: data.percentage,
                        EmergencyContactNo: data.emergencyContactNo,
                        AadharNo: data.aadharNo,
                        IsNominee: data.isNominee,
                        DOB: data.dob
                    }))
                    //  console.log(dateOfBirth, 'adfsdfdsf')
                    //setDateOfBirth(data.dob)
                }
            }
        });


    }

    //Handle Delete
    const handleDelete = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/Candidate/DeleteCandidateFamilyDetails`, { ...TokenData, CandidateFamilyDetailID: Id, PageType: "Employee" }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    toast.success(res.message)
                    setLoading(false)
                    getFormData()
                }
            }
        });
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            EmployeeID: '0',

            Name: '',
            Relation: '',
            IsResidingWithEmp: '',
            State: '',
            District: '',
            Address: '',
            Percentage: '',
            AadharNo: '',
            EmergencyContactNo: '',
            DOB: '',
            IsNominee: false,
        }))
        setErrors({})
        setDateOfBirth()
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };


    let StateData = []
    for (let i = 0; i < stateAllData.length; i++) {
        let mydata = stateAllData[i]?.values
        StateData.push(mydata)
    };


    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality


    useEffect(() => {
        if (activeTab === 'EmployeeFamilyDetails') {
            inputrefs.current['txtName'].focus();
            document.title = 'Pay Plus: Family Details'
            GetStateData();
            getFormData();
            GetRelationData();
        }
    }, [activeTab, sortConfig, isUpdate,])


    //useEffect(() => {
    //    //  inputrefs.current['txtEmployeeCode'].focus();
    //    //inputrefs.current['txtEmployeeCode'].focus()

    //    GetStateData();
    //    getFormData();
    //    GetRelationData();
    //}, [sortConfig, isUpdate, activeTab === 'EmployeeFamilyDetails'])


    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Family Details</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtRelation'].focus() };
                            }}
                            label='Name'
                            id='txtName'
                            maxLength='50'
                            placeholder='Name'
                            value={recipe.Name}
                            onChange={(e) => { HandleChange('Name', (e.target.value)) }}
                            //disabled
                            error={errors.Name}
                            required
                        // inputClassName=''
                        //  divclassname='w-100 '
                        // inputStyle={{ width: '25%' }}
                        />
                        {/*<div className={`col-md-3 mb-1 cntrl-width`} >*/}
                        {/*    <label className="form-label">Relataion<span style={{ color: "red" }}> *</span>*/}
                        {/*    </label>*/}
                        {/*    <select ref={ref => (inputrefs.current['txtRelation'] = ref)}*/}
                        {/*        className="form-control"*/}
                        {/*        id="txtRelation"*/}
                        {/*        value={recipe.Relation}*/}
                        {/*        error={errors.Relation}*/}
                        {/*        onKeyDown={(e) => {*/}
                        {/*            if (e.key === 'Enter') { inputrefs.current['txtDOB'].focus() };*/}
                        {/*        }}*/}
                        {/*        onChange={(e) => HandleChange('Relation', e.target.value)}>*/}
                        {/*        <option value="">--Select Relation--</option>*/}
                        {/*        {relationDetailData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}*/}
                        {/*    </select>*/}
                        {/*    <div className="error-msg">{errors.Relation}</div>*/}
                        {/*</div>*/}


                        <SelectInputBox
                            label='Relataion'
                            inputrefs={inputrefs}
                            id="txtRelation"
                            required
                            value={recipe.Relation}
                            error={errors.Relation}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtDOB'].focus() };
                            }}
                            onChange={(e) => HandleChange('Relation', e.target.value)}
                            children={<>
                                <option value="">--Select Relation--</option>
                                {relationDetailData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}
                            </>}
                        />

                        <DateBox

                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtResidingwithEmployee'].focus() };
                            }}
                            label='Date of Birth'
                            id='txtDOB'
                            selected={recipe.DOB === "" ? "" : new Date(recipe.DOB)}
                            placeholder="Date of Birth"
                           // value={recipe.DOB}
                            onChange={(e) => HandleChange('DOB', dateFormate(e) )}
                            required
                            error={errors.DOB}
                        />
                        {/*<div className={`col-md-3 mb-1 cntrl-width`} >*/}
                        {/*    <label className="form-label">Residing with Employee<span style={{ color: "red" }}> *</span>*/}
                        {/*    </label>*/}
                        {/*    <select ref={ref => (inputrefs.current['txtResidingwithEmployee'] = ref)}*/}
                        {/*        className="form-control"*/}
                        {/*        id="txtResidingwithEmployee"*/}
                        {/*        value={recipe.IsResidingWithEmp}*/}
                        {/*        error={errors.IsResidingWithEmp}*/}
                        {/*        onKeyDown={(e) => {*/}
                        {/*            if (e.key === 'Enter') { inputrefs.current['txtState'].focus() };*/}
                        {/*        }}*/}
                        {/*        onChange={(e) => HandleChange('IsResidingWithEmp', e.target.value)}>*/}
                        {/*        <option value="YES">YES</option>*/}
                        {/*        <option value="NO">NO</option>*/}

                        {/*    </select>*/}
                        {/*</div>*/}

                        <SelectInputBox
                            label='Residing with Employee'
                            inputrefs={inputrefs}
                            required
                            id="txtResidingwithEmployee"
                            value={recipe.IsResidingWithEmp}
                            error={errors.IsResidingWithEmp}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtState'].focus() };
                            }}
                            onChange={(e) => HandleChange('IsResidingWithEmp', e.target.value)}
                            children={<>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </>}
                        />

                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtDistrict'].focus() };
                            }}
                            label='State'
                            placeholder="State"
                            maxLength='50'
                            options={StateData}
                            id='txtState'
                            required={true}
                            error={errors.State}
                            inputValue={recipe.State ? recipe.State : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('State', newInputValue)
                                GetStateData(newInputValue)
                                //   setStatePre(newInputValue)
                            }}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtAddress'].focus() };
                            }}
                            label='District'
                            id='txtDistrict'
                            maxLength='50'
                            placeholder='District'
                            value={recipe.District}
                            onChange={(e) => { HandleChange('District', (e.target.value)) }}
                            //disabled
                            required
                            error={errors.District}
                        // inputClassName=''
                        //  divclassname='w-100 '
                        // inputStyle={{ width: '25%' }}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtNominee'].focus() };
                            }}
                            label='Address'
                            id='txtAddress'
                            maxLength='100'
                            placeholder='Address'
                            value={recipe.Address}
                            onChange={(e) => { HandleChange('Address', (e.target.value)) }}
                            //disabled
                            required
                            error={errors.Address}
                        // inputClassName=''
                        //  divclassname='w-100 '
                        // inputStyle={{ width: '25%' }}
                        />

                        <CheckBoxInput
                            customclass={'form-check form-switch p-0 m-0 mt-4'}
                            divclassname='col-md-4 col-lg-3 col-6 mb-1 cntrl-width'
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { recipe.IsNominee === true ? inputrefs.current['txtPercentage'].focus() : inputrefs.current['txtAadharNo'].focus() };
                            }}
                            checked={recipe.IsNominee}
                            label='Nominee'
                            id='txtNominee'

                            //  value={recipe.IsCalculate}
                            onChange={(e) => {
                                HandleChange('IsNominee', e.target.checked)
                                if (e.target.checked) setRecipe({ ...recipe, IsNominee: e.target.checked, Percentage: '' })
                            }}
                        />


                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtAadharNo'].focus() };
                            }}
                            label='Percentage'
                            id='txtPercentage'
                            maxLength='6'
                            placeholder='Percentage'
                            value={recipe.IsNominee === true ? recipe.Percentage : ''}
                            onChange={(e) => { HandleChange('Percentage', numberwithDot(e.target.value)) }}
                            onKeyUp={(e) => {
                                if (e.target.value > 100) { HandleChange('Percentage', '') };
                            }}
                            disabled={!recipe.IsNominee}
                            required={recipe.IsNominee}
                            error={errors.Percentage}
                        // inputClassName=''
                        //  divclassname='w-100 '
                        // inputStyle={{ width: '25%' }}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtEmergencyContactNo'].focus() };
                            }}
                            label='Aadhaar Card Number'
                            id='txtAadharNo'
                            maxLength='12'
                            placeholder='Aadhaar Card Number'
                            value={recipe.AadharNo}
                            onChange={(e) => { HandleChange('AadharNo', number(e.target.value)) }}
                            // disabled
                            required
                            error={errors.AadharNo}
                        // inputClassName=''
                        //  divclassname='w-100 '
                        // inputStyle={{ width: '25%' }}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { handleSubmit() };
                            }}
                            label='Emergency Contact No.'
                            id='txtEmergencyContactNo'
                            maxLength='10'
                            placeholder='Emergency Contact No.'
                            value={recipe.EmergencyContactNo}
                            onChange={(e) => { HandleChange('EmergencyContactNo', number(e.target.value)) }}
                            //disabled
                            required
                            error={errors.EmergencyContactNo}
                        // inputClassName=''
                        //  divclassname='w-100 '
                        // inputStyle={{ width: '25%' }}
                        />


                        <FormButton
                            handleSubmit={handleSubmit}
                            Export={false}
                            //handleExport={() => handleDownloadExcel(API_SERVER + 'api/Candidate/ShowCandidateFamilyDetails', ExportRequest, 'User Master')}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>

            {/* Table Component start */}
            <Table
                Token={TokenData}
                PageName='EmployeeFamilyDetails'
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleFilter={() => {
                    getFormData();
                    setFilterData({
                        ...filterData,
                    });
                }}
                tableData={result}
                //  loading={loading}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}

            />
            {/* Table Component End*/}


            {/* Loder start*/}
            {/*  {loading && < BlurLoader />}
          {/* Loder End*/}
        </>
    )
}

export default EmployeeFamilyDetails;