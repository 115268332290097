import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, DateBox, SelectInputBox } from "../commoncomponents/InputBox";

import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';

//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';

//services
import { post } from '../../apiservices/service';
import { dateFormate, number, Token } from '../Common/Common';
import { getMasterData } from '../../apiservices/CommonServices';

function RoasterMaster() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();

    //token Data
    //const TokenData = {
    //    AuthToken: process.env.REACT_APP_SECRET_KEY,
    //    LoginToken: user.loginsessionID,
    //    UserID: user.userID,
    //    CompanyID: user.companyID
    //}
    const TokenData = Token()
    //initial state
    const [roasterForm, setRecipe] = useState({
        roasterID: "0",
        roasterName: '',
        shiftID: '',
        dateFrom: '',
       dateTo:'',
        active: true,
    })
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [isUpdate, setIsUpdate] = useState(false);
    const [errors, setErrors] = useState({});
    const [shiftData, setShiftData] = useState([])



    //Get Shift Data 
    const GetShiftData = () => {
        setShiftData([])
        let temp = {
            Type: "GetShift",
            Prefix: '',
        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setShiftData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setShiftData(data)
                }
            }
        });
    }


    const handleChange1 = (event, value) => {
        getFormData(value);
    };

    useEffect(() => {
        inputrefs.current['txtRoasterName'].focus();
        document.title = 'Pay Plus : Roaster Master';
        GetShiftData();
    }, [sortConfig])

    useEffect(() => {
        getFormData()
    }, [isUpdate])


    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(roasterForm => ({ ...roasterForm, [prop]: value }))
    }

    //validation handler
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "roasterName", msg: 'Please Enter Roaster Name.' },
            { field: "shiftID", msg: 'Please Select Shift.' },
            { field: "dateFrom", msg: 'Please Enter Date From.' },
            { field: "dateTo", msg: 'Please Enter Date To.' },
         

        ]

        validation.map(item => {
            if (!roasterForm[item.field] || roasterForm[item.field].length <= 0 || roasterForm[item.field] === '') {

                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }




    //Submit Handler
    const handleSubmit = () => {
        const myString = Validate();
        // if (myString === "") {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/RoasterManagement/InsertUpdateRoasterMaster`, { ...TokenData, ...roasterForm }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        inputrefs.current[res.focus].focus();
                        setLoading(false)
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.status === "UNAUTHORIZED") {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        handleClear()
                    }
                }
            });
        } else {
            // toast.error(myString[0])
            //       (inputrefs.current[myString[1]].focus())
        }
    }


    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/RoasterManagement/ShowRoasterMaster`, { ...TokenData, ...temp, ...sortConfig, ...filterData }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }

    //Handle Edit
    const handleEdit = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/RoasterManagement/EditRoasterMaster`, { ...TokenData, roasterID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    let data = res.data
                    setRecipe({
                        ...data
                    })
                }
            }
        });
    }

    //Handle Delete
    const handleDelete = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/RoasterManagement/DeleteRoasterMaster`, { ...TokenData, roasterID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    toast.success(res.message)
                    setLoading(false)
                    getFormData()
                    handleClear()
                }
            }
        });
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(roasterForm => ({
            ...roasterForm,
            roasterID: "0",
            roasterName: '',
            shiftID: '',
            dateFrom: '',
            dateTo: '',
            active: true,
        }))
        setErrors({})
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        getFormData()
        inputrefs.current['txtRoasterName'].focus();
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
        getFormData()
    };

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Roaster Master</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtShiftID'].focus() };
                            }}
                            label='Roaster Name'
                            id='txtRoasterName'
                            maxLength='50'
                            placeholder="Roaster Name"
                            value={roasterForm.roasterName}
                            onChange={(e) => HandleChange('roasterName', e.target.value)}
                            required
                            error={errors.roasterName}
                        />
                        <SelectInputBox
                            label='Shift'
                            id="txtShiftID"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtDateFrom'].focus() };
                            }}
                            required
                            inputrefs={inputrefs}
                            error={errors.shiftID}
                            value={roasterForm.shiftID}
                            onChange={(e) => {
                                HandleChange('shiftID', e.target.value)
                            }}
                            children={<>
                                <option value='' >--Select Shift--</option>
                                {shiftData.map((opt, index) => <option key={index} value={opt.id} > {opt.code} : {opt.name}</option>)}

                            </>}
                        />


                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtDateTo'].focus() };
                            }}
                            //labelClass='text-nowrap'
                            //divclassname='col-lg-6'
                            id='txtDateFrom'
                            label='Date From'
                            placeholder="Date From"
                            selected={roasterForm.dateFrom === '' ? '' : new Date(roasterForm.dateFrom)}
                            onChange={(e) => HandleChange('dateFrom', dateFormate(e))}
                            error={errors.dateFrom}
                            required
                        />
                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtIsActive'].focus() };
                            }}
                            //labelClass='text-nowrap'
                            //divclassname='col-lg-6'
                            id='txtDateTo'
                            label='Date To'
                            placeholder="Date To"
                            selected={roasterForm.dateTo === '' ? '' : new Date(roasterForm.dateTo)}
                            onChange={(e) => HandleChange('dateTo', dateFormate(e))}
                            error={errors.dateTo}
                            required
                        />
                       
                        <Switch
                            inputrefs={inputrefs}
                            label='Active'
                            checked={roasterForm.active}
                            id='txtIsActive'
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { handleSubmit() };
                            }}
                            onChange={(e) => HandleChange('active', e.target.checked)}
                        />

                        <FormButton
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/RoasterManagement/ShowRoasterMaster', ExportRequest, 'Roaster Master')}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>

            {/* Table Component start*/}
            <Table
                Token={TokenData}
                PageName='RoasterMaster'
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleFilter={() => {
                    getFormData();
                    setFilterData({
                        ...filterData,
                    });
                }}
                tableData={result}
                //  loading={loading}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
            {/* Table Component End*/}


            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}
        </>
    )
}

export default RoasterMaster