
import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, AutoCompleteBoxWithLabel, DateBox, SelectInputBox } from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
//loder
import BlurLoader from "../Loder/BlurLoader";
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { Token, dateFormate, number, numberwithDot } from '../Common/Common';

function WorkingAndLeftEmployee() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();
    //token Data
    //const TokenData = {
    //    AuthToken: process.env.REACT_APP_SECRET_KEY,
    //    LoginToken: user.loginsessionID,
    //    UserID: user.userID,
    //    CompanyID: user.companyID
    //}
    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({
      //  loanTypeID: "0",
       // company: '',
        employee: '',
        dojFromDate: '',
        dojToDate: '',
        workingStatus: '',
    })

    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData);
    const [isUpdate, setIsUpdate] = useState(false);
    const [companyAllData, setCompanyAllData] = useState([])
    const [employeeAllData, setEmployeeAllData] = useState([])
    const [errors, setErrors] = useState({});
    const [showTable, setShowTable] = useState(false)


    //Get company Data for AutoComplete
    const GetCompanyData = (value) => {
        setCompanyAllData([])
        let temp = {
            Type: "GetCompany",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
      //  setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setCompanyAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setCompanyAllData(data)
                }
            }
        });
    }
    
    //Get Employee Data for AutoComplete
    const GetEmployeeData = (value) => {
        setEmployeeAllData([])
        let temp = {
            Type: "GetEmployee",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        //setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setEmployeeAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setEmployeeAllData(data)
                }
            }
        });
    }

    //Submit Handler
    const handleSubmit = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        // setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        setLoading(true)
        post(`${API_SERVER}api/Report/ShowWorkingAndLeftEmployeeReport`, { ...TokenData, ...temp, ...sortConfig, ...filterData, ...recipe }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    toast.error(res.Message)
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    toast.success(res.message)
                    setResult({ ...res })
                    setShowTable(true)
                    //getFormData()
                    // handleClear()
                    //console.log(recipe, 'else')
                }
            }
        });
    }

    const handleChange1 = (event, value) => {
        //getFormData(value);
        handleSubmit(value);
    };

    useEffect(() => {
        //inputrefs.current['txtCompany'].focus();
        inputrefs.current['txtEmployee'].focus();
        document.title = 'Pay Plus : Working and Left Employee';
       // GetCompanyData();
        GetEmployeeData();
        //getFormData()
        if (sortConfig.SortOrder !== null) handleSubmit();

    }, [sortConfig, isUpdate])


    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "company", msg: 'Please Enter Company.' },
        ]
        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        return isValid;
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            //loanTypeID: "0",
        //    company: '',
            employee: '',
            dojFromDate: '',
            dojToDate: '',
            workingStatus: '',
        }))
        setErrors({})
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        setShowTable(false)
        // getFormData()
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';
        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    let CompanyData = []
    for (let i = 0; i < companyAllData.length; i++) {
        let mydata = companyAllData[i]?.values
        CompanyData.push(mydata)
    };

    let EmployeeData = []
    for (let i = 0; i < employeeAllData.length; i++) {
        let mydata = employeeAllData[i]?.values
        EmployeeData.push(mydata)
    };

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        ...recipe,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Working and Left Employee</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>

                        {/*<AutoCompleteBox*/}
                        {/*    inputrefs={inputrefs}*/}
                        {/*    onKeyDown={(e) => {*/}
                        {/*        if (e.key === 'Enter') { inputrefs.current['txtEmployee'].focus() };*/}
                        {/*    }}*/}
                        {/*    label='Company'*/}
                        {/*    placeholder="Company"*/}
                        {/*    maxLength='50'*/}
                        {/*    options={CompanyData}*/}
                        {/*    id='txtCompany'*/}
                        {/*    key='txtCompany'*/}
                        {/*    //required={true}*/}
                        {/*   // error={errors.company}*/}
                        {/*    inputValue={recipe.company ? recipe.company : ''}*/}
                        {/*    onInputChange={(event, newInputValue) => {*/}
                        {/*        GetCompanyData(newInputValue)*/}
                        {/*        HandleChange('company', newInputValue)*/}

                        {/*    }}*/}
                        {/*/>*/}
                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtDOJFromDate'].focus() };
                            }}
                            label='Employee'
                            placeholder="Employee"
                            maxLength='50'
                            options={EmployeeData}
                            id='txtEmployee'
                            key='txtEmployee'
                           // required={true}
                           // error={errors.employee}
                            inputValue={recipe.employee ? recipe.employee : ''}
                            onInputChange={(event, newInputValue) => {
                                GetEmployeeData(newInputValue)
                                HandleChange('employee', newInputValue)

                            }}
                        />
                        <div className={`col-md-4 mb-1 `} key="txtWorkingStatus">
                            <label className="form-label">DOJ{/*<span style={{ color: "red" }}> *</span>*/}</label>
                            <div className='row'>
                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtDOJToDate'].focus() };
                            }}
                            //labelClass='text-nowrap'
                            divclassname='col-lg-6'
                                    id='txtDOJFromDate'
                          //  label='From Date'
                            placeholder="From Date"
                                    selected={recipe.dojFromDate === '' ? '' : new Date(recipe.dojFromDate)}
                                    onChange={(e) => HandleChange('dojFromDate', dateFormate(e))}
                        // error={errors.fromDate}
                        // required
                        />
                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtWorkingStatus'].focus() };
                            }}
                            //labelClass='text-nowrap'
                            divclassname='col-lg-6'
                                    id='txtDOJToDate'
                            //label='To Date'
                            placeholder="To Date"
                                    selected={recipe.dojToDate === '' ? '' : new Date(recipe.dojToDate)}
                                    onChange={(e) => HandleChange('dojToDate', dateFormate(e))}
                        // error={errors.toDate}
                        //required
                                />
                            </div>

                        </div>

                        {/*<div className={`col-md-3 mb-1 cntrl-width`} key="txtWorkingStatus">*/}
                        {/*    <label className="form-label">Working Status*/}{/*<span style={{ color: "red" }}> *</span>*/}{/*</label>*/}
                        {/*    <select ref={ref => (inputrefs.current['txtWorkingStatus'] = ref)}*/}
                        {/*        className="form-control"*/}
                        {/*        maxLength='18'*/}
                        {/*        id="txtWorkingStatus"*/}
                        {/*        // key="txtWorkingStatus"*/}
                        {/*        value={recipe.workingStatus}*/}
                        {/*        error={recipe.workingStatus}*/}
                        {/*        onKeyDown={(e) => {*/}
                        {/*            if (e.key === 'Enter') { handleSubmit() };*/}
                        {/*        }}*/}
                        {/*        onChange={(e) => HandleChange('workingStatus', e.target.value)}>*/}
                        {/*        <option value="Working">Working</option>*/}
                        {/*        <option value="Left">Left</option>*/}
                        {/*        */}{/*{addressProofDocument?.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}*/}

                        {/*    </select>*/}
                        {/*   <div className="error-msg">{errors.workingStatus}</div>*/}
                        {/*</div>*/}


                        <SelectInputBox
                            //     divclassname='col-4 mt-4 p-0 ps-0'
                            //      inputStyle={{ marginTop: '0.1rem' }}
                            label="Working Status"
                            inputrefs={inputrefs}
                            id="txtWorkingStatus"
                            value={recipe.workingStatus}
                            error={errors.workingStatus}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { handleSubmit() };
                            }}
                            onChange={(e) => HandleChange('workingStatus', e.target.value)}
                            children={<>
                                <option value="Working">Working</option>
                                <option value="Left">Left</option>
                            </>}
                        />

                        <FormButton
                            saveButtonIcon='fa-search'
                            SaveText='Search'
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/Report/ShowWorkingAndLeftEmployeeReport', ExportRequest, 'Working and Left Employee')}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>
            {showTable &&
                <Table
                    Filter={false}
                    Action={false}
                    Token={TokenData}
                    PageName='WorkingAndLeftEmployee'
                    // handleEdit={handleEdit}
                    // handleDelete={handleDelete}
//                    handleFilter={() => {
//                        //getFormData();
//                        handleSubmit();
//                        setFilterData({
//                            ...filterData,
//                        });
//                    }}
                    loading={loading}
                    tableData={result}
                    currentPageNo={currentPageNo}
                    handleChange1={handleChange1}
  //                  filterData={filterData}
  //                  setFilterData={setFilterData}
  //                  handleSorting={handleSorting}
  //                  sortConfig={sortConfig}
                />
            }
            {loading && < BlurLoader />}
        </>
    )
}

export default WorkingAndLeftEmployee
