
import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, AutoCompleteBoxWithLabel, DateBox, MonthBox, YearBox } from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
//loder
import BlurLoader from "../Loder/BlurLoader";
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { getCommonData, getFormatData, getMasterData } from '../../apiservices/CommonServices';
import { Token, currentYear, dateFormate, number, numberwithDot } from '../Common/Common';
import TextEditor from '../commoncomponents/TextEditor';

function WarningLetter() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();

    //token Data
    const TokenData = Token()

    //initial state
    const [recipe, setRecipe] = useState({
        branch: '',
        employee: '',
        warningDate: '',
     //   warningLetterFormat: ''
        //   company: '',

    })

    const [loading, setLoading] = useState(false);
    const [branchAllData, setBranchAllData] = useState([])
    const [employeeAllData, setEmployeeAllData] = useState([])
    const [companyAllData, setCompanyAllData] = useState([])
    const [errors, setErrors] = useState({});
    const [textAreaFormate, setTextAreaFormate] = useState([]);
    const [result, setResult] = useState({});
    const [currentPageNo, setCurrentPageNo] = useState(1);




    //Get company Data for AutoComplete
    const GetCompanyData = (value) => {
        setCompanyAllData([])
        let temp = {
            Type: "GetCompany",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        //  setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setCompanyAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setCompanyAllData(data)
                }
            }
        });
    }


    //Get branch Data for AutoComplete
    const GetBranchData = (value) => {
        setBranchAllData([])
        let temp = {
            Type: "GetBranch",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        //   setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setBranchAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setBranchAllData(data)
                }
            }
        });
    }


    //Get employee Data for AutoComplete with call GetHistory API
    const GetEmployeeData = (value) => {
        setEmployeeAllData([])
        let temp = {
            Type: "GetEmployee",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        //   setLoading(true)
        handleGetHistory(value)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setEmployeeAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setEmployeeAllData(data)
                }
            }
        });
    }

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    // Handle Validation
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            //   { field: "branch", msg: 'Please Enter Branch.' },
            { field: "employee", msg: 'Please Enter Employee.' },
            { field: "warningDate", msg: 'Please Enter Warning Date.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {

                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }

    // Get History Data for table 
    const handleGetHistory = (emp, CurrentPage) => {
        //  console.log(emp, 'employe')
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s",
            employee: emp ? emp : '',
        }
        post(`${API_SERVER}api/Print/GetWarningLetterHistory`, { ...TokenData, ...temp, }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }

    // Handle change for table Pagination
    const handleChange1 = (event, value) => {
        handleGetHistory(recipe.employee, value);
    };

    // Handle Print and Insert  Formate
    const handlePrint = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/Print/InsertWarningLetterFormat`, { ...TokenData, ...recipe, WarningLetterFormat: textAreaFormate }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        inputrefs.current[res.focus].focus();
                        setLoading(false)
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "UNAUTHORIZED") {
                        toast.error(res.Message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        let base64PDF = res && res.warningLetterFormat
                        window.open(`/PdfView/#data:application/pdf;base64,${base64PDF}`, '_blank')
                        setTimeout(handleGetHistory(recipe.employee), 1000)
                    }
                }
            });
        }
    }

    // Handle Print PDF Form Table
    const PrintPDFFromTable = (ID) => {
        post(`${API_SERVER}api/Print/PrintWarningLetter`, { ...TokenData, WarningLetterID: ID, employeeCode: recipe.employee }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)

                    let base64PDF = res && res.warningLetterFormat
                    window.open(`/PdfView/#data:application/pdf;base64,${base64PDF}`, '_blank')

                }
            }
        });
    }

    //Handle Get Warning Letter Formate
    const GetLetterFormatPreview = () => {
        let temp = {
            formatTypeName: "Warning Letter",
            employeeCode: recipe.employee
        }
        setLoading(true)
        post(`${API_SERVER}api/Print/PreviewAppointmentLetter`, { ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    setTextAreaFormate(res.appointmentLetterFormat)
                  //  setRecipe({ ...recipe, warningLetterFormat: res.appointmentLetterFormat })
                }
            }
        });
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            branch: '',
            employee: '',
            warningDate: '',
           // warningLetterFormat: ''
            // company: '',

        }))
        setTextAreaFormate('')
        setErrors({})
        setResult({})
        inputrefs.current['txtBranch'].focus();

    }

    let CompanyData = []
    for (let i = 0; i < companyAllData.length; i++) {
        let mydata = companyAllData[i]?.values
        CompanyData.push(mydata)
    };

    let BranchData = []
    for (let i = 0; i < branchAllData.length; i++) {
        let mydata = branchAllData[i]?.values
        BranchData.push(mydata)
    };

    let EmployeeData = []
    for (let i = 0; i < employeeAllData.length; i++) {
        let mydata = employeeAllData[i]?.values
        EmployeeData.push(mydata)
    };


    useEffect(() => {
        inputrefs.current['txtBranch'].focus();
        // inputrefs.current['txtCompany'].focus();
        document.title = 'Pay Plus : Warning Letter';
        // GetCompanyData();
        GetBranchData();
        GetEmployeeData();
    }, [])





    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Warning Letter</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <div class="row">
                                {/*<AutoCompleteBox*/}
                                {/*    inputrefs={inputrefs}*/}
                                {/*    onKeyDown={(e) => {*/}
                                {/*        if (e.key === 'Enter') { inputrefs.current['txtBranch'].focus() };*/}
                                {/*    }}*/}
                                {/*    id='txtCompany'*/}
                                {/*    label='Company'*/}
                                {/*    placeholder="Company"*/}
                                {/*    maxLength='50'*/}
                                {/*    options={CompanyData}*/}
                                {/*    key='txtCompany'*/}
                                {/*    //  required={true}*/}
                                {/*    //error={errors.company}*/}
                                {/*    inputValue={recipe.company ? recipe.company : ''}*/}
                                {/*    onInputChange={(event, newInputValue) => {*/}
                                {/*        GetCompanyData(newInputValue)*/}
                                {/*        HandleChange('company', newInputValue)*/}

                                {/*    }}*/}
                                {/*/>*/}

                                <AutoCompleteBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtEmployee'].focus() };
                                    }}
                                    divclassname='col-md-4'
                                    label='Branch'
                                    placeholder="Branch"
                                    maxLength='50'
                                    options={BranchData}
                                    id='txtBranch'
                                    key='txtBranch'
                                    // required={true}
                                    //  error={errors.branch}
                                    inputValue={recipe.branch ? recipe.branch : ''}
                                    onInputChange={(event, newInputValue) => {
                                        GetBranchData(newInputValue)
                                        HandleChange('branch', newInputValue)

                                    }}
                                />
                                <AutoCompleteBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleGetHistory(e.target.value);
                                            inputrefs.current['txtWarningDate'].focus();
                                        };
                                    }}
                                    divclassname='col-md-4'
                                    label='Employee'
                                    placeholder="Employee"
                                    maxLength='50'
                                    options={EmployeeData}
                                    id='txtEmployee'
                                    key='txtEmployee'
                                    required={true}
                                    error={errors.employee}
                                    inputValue={recipe.employee ? recipe.employee : ''}
                                    onInputChange={(event, newInputValue) => {
                                        GetEmployeeData(newInputValue)
                                        HandleChange('employee', newInputValue)
                                        //handleGetHistory(newInputValue)

                                    }}
                                />
                                <DateBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { handleGetHistory(recipe.employee) };
                                    }}
                                    //labelClass='text-nowrap'
                                    divclassname='col-md-4'
                                    id='txtWarningDate'
                                    label='Warning Date'
                                    placeholder="Warning Date"
                                    selected={recipe.warningDate === '' ? '' : new Date(recipe.warningDate)}
                                    onChange={(e) => {
                                        HandleChange('warningDate', dateFormate(e))
                                        // handleGetHistory() 
                                    }}
                                    //      maxDate={new Date() }
                                    error={errors.warningDate}
                                    required
                                />

                                <FormButton
                                    saveButtonIcon='fa-search'
                                    SaveText='Preview'
                                    Export={false}
                                    reset={false}
                                    handleSubmit={GetLetterFormatPreview}
                                />

                            </div>
                        </div>

                        <div className='col-md-6 col-sm-6 col-xs-6'>

                            <Table
                                isOverFlow={true}
                                Filter={false}
                                Token={TokenData}
                                totalRecordText="History"
                                PageName='WarningLetter'
                                handlePrintPdfData={PrintPDFFromTable}
                                // handleCheckData={handleCheckData}
                                // handleFilter={() => {
                                //     // getFormData();
                                //     handleSubmit();
                                //     setFilterData({
                                //         ...filterData,
                                //     });
                                // }}
                                tableData={result}
                                loading={loading}
                                // filterData={filterData}
                                //    setFilterData={setFilterData}
                                currentPageNo={currentPageNo}
                                handleChange1={handleChange1}
                            //    handleSorting={handleSorting}
                            //    sortConfig={sortConfig}
                            />

                        </div>


                        <TextEditor
                          //  value={recipe.warningLetterFormat}
                            //  setValue={(e) => setRecipe({ ...recipe, warningLetterFormat: e })}  
                            value={textAreaFormate}
                            setValue={(formate) => setTextAreaFormate(formate)}
                        />


                        <FormButton
                            save={false}
                            Export={false}
                            print={true}
                            handlePrint={handlePrint}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>



            {loading && < BlurLoader />}
        </>
    )
}

export default WarningLetter
