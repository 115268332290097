import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, SelectInputBox } from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
import BlurLoader from "../Loder/BlurLoader"; //loder
import { toast } from 'react-toastify'; //react toast 
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils'; //common Data
import { post } from '../../apiservices/service'; //services
import { Token, number, numberWithComma, numberwithDot } from '../Common/Common';

function LeaveMaster() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();
    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({
        Leave: "",
        LeaveAbbreviation: '',
        LeaveAliasCode: '',
        Credit: '',
        CreditType: 'PerMonth',
        CreditFrom: '',
        MaxLeaveDays: '',
        MaxLeaveType: 'PerMonth',
        Calculate: 'Fixed',
        CalculateDays: '',
        CalculateLeave: '',
        Limit: '',
        IsCarryForwardAll: false,
        IsActive: true,
        LeaveMasterID: "0",
        Gender: "",
        ApplicationType:""
    })
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [isUpdate, setIsUpdate] = useState(false)
    const [errors, setErrors] = useState({});

    const handleChange1 = (event, value) => {
        getFormData(value);
    };

    useEffect(() => {
        inputrefs.current['txtLeave'].focus();
        document.title = 'Pay Plus : Leave Master'
    }, [sortConfig])

    useEffect(() => {
        getFormData()
    }, [isUpdate])


    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "Leave", msg: 'Please Enter Leave Name.' },
            { field: "LeaveAbbreviation", msg: 'Please Enter Leave Abbreviation.' },
            { field: "LeaveAliasCode", msg: 'Please Enter Leave Alias Code.' },
            { field: "Credit", msg: 'Please Enter Credit Leave Days.' },
            { field: "CreditFrom", msg: 'Please Enter Credit Leave From.' },
            { field: "MaxLeaveDays", msg: 'Please Enter Leave Days.' },
            { field: "MaxLeaveType", msg: 'Please Select Leave Type.' },
            { field: "ApplicationType", msg: 'Please Select Application Type' },
            { field: "Gender", msg: 'Please Select Gender.' },
        ]

        if (recipe['Calculate'] === 'Calculate') {
            validation.push(
                { field: "CalculateDays", msg: 'Please Enter Days.' },
                { field: "CalculateLeave", msg: 'Please Enter Leave.' },
            )
        };
        if (recipe['IsCarryForwardAll'] === true) {
            validation.push(
                { field: "Limit", msg: 'Please Enter Limit.' }
            )
        };

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {

                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }



    //Submit Handler
    const handleSubmit = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/MASTER/InsertUpdateLeaveMaster`, { ...TokenData, ...recipe }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        setLoading(false)
                        toast.error(res.message)
                        inputrefs.current[res.focus].focus();                      
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "UNAUTHORIZED") {
                        setLoading(false)
                        toast.error(res.Message);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        handleClear()
                    }
                }
            });
        }
    }

    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/MASTER/ShowLeaveMaster`, { ...TokenData, ...temp, ...sortConfig, ...filterData }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }

    //Handle Edit
    const handleEdit = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/MASTER/EditLeaveMaster`, { ...TokenData, LeaveMasterID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    let data = res.data
                    setRecipe({
                        ...data,
                        Leave: data.leave,
                        CreditType: data.creditType,
                        Credit: data.credit,
                        CreditFrom: data.creditFrom,
                        LeaveAbbreviation: data.leaveAbbreviation,
                        MaxLeaveDays: data.maxLeaveDays,
                        MaxLeaveType: data.maxLeaveType,
                        Calculate: data.calculate,
                        CalculateDays: data.calculateDays,
                        CalculateLeave: data.calculateLeave,
                        Limit: data.limit,
                        IsCarryForwardAll: data.carryForwardAll,
                        IsActive: data.active,
                        LeaveAliasCode: data.leaveAliasCode,
                        Gender: data.gender,
                        ApplicationType: data.applicationType
                    })
                }
            }
        });
    }

    //Handle Delete
    const handleDelete = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/MASTER/DeleteLeaveMaster`, { ...TokenData, LeaveMasterID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    toast.success(res.message)
                    setLoading(false)
                    getFormData()
                    handleClear()
                }
            }
        });
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            Leave: "",
            LeaveAbbreviation: '',
            LeaveAliasCode: '',
            CreditType: "PerMonth",
            Credit: "",
            CreditFrom: "",
            MaxLeaveDays: '',
            MaxLeaveType: 'PerMonth',
            Calculate: 'Fixed',
            CalculateDays: '',
            CalculateLeave: '',
            Limit: '',
            IsCarryForwardAll: false,
            IsActive: true,
            LeaveMasterID: "0",
            Gender: "",
            ApplicationType: ""
        }))
        setErrors({})
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        getFormData()
        inputrefs.current['txtLeave'].focus();
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
        getFormData()
    };

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Leave Master</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtLeaveAbbreviation'].focus() };
                            }}
                            label='Leave Name'
                            id='txtLeave'
                            maxLength='100'
                            placeholder="Leave Name"
                            value={recipe.Leave}
                            error={errors.Leave}
                            onChange={(e) => HandleChange('Leave', e.target.value)}
                            required
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtCredit'].focus() };
                            }}
                            label='Leave Abbreviation'
                            id='txtAliasCode'
                            maxLength='1'
                            placeholder="Leave Abbreviation"
                            value={recipe.LeaveAbbreviation}
                            error={errors.LeaveAbbreviation}
                            onChange={(e) => HandleChange('LeaveAbbreviation', e.target.value)}
                            required
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtCredit'].focus() };
                            }}
                            label='Leave Alias Code'
                            id='txtAliasCode'
                            maxLength='5'
                            placeholder="Leave Alias Code"
                            value={recipe.LeaveAliasCode}
                            error={errors.LeaveAliasCode}
                            onChange={(e) => HandleChange('LeaveAliasCode', e.target.value)}
                            required
                        />

                        <div className='cntrl-width col-md-3 d-flex mb-1' >
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtCreditType'].focus() };
                                }}
                                maxLength='5'
                                labelClass='text-nowrap'
                                divclassname='col-lg-7 me-1'
                                label='Credit Leave'
                                id='txtCredit'
                                required
                                placeholder='Credit Leave'
                                value={recipe.Credit}
                                onChange={(e) => HandleChange('Credit', numberwithDot(e.target.value))}
                                error={errors.Credit}
                            />
                            <SelectInputBox
                                divclassname='col-lg-5 '
                                divstyle={{ marginTop: '.1rem' }}
                                inputClassName='mt-4 form-control'
                                inputrefs={inputrefs}
                                required
                                id="txtCreditType"
                                value={recipe.CreditType}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtCreditFrom'].focus() };
                                }}
                                onChange={(e) => HandleChange('CreditType', e.target.value)}
                                error={errors.CreditType}
                                children={<>
                                    <option value="PerMonth" >Per Month</option>
                                    <option value="PerYear" >Per Year</option>
                                </>}
                            />
                        </div>

                        <SelectInputBox
                            label='Credit From'
                            inputrefs={inputrefs}
                            required
                            id="txtCreditFrom"
                            value={recipe.CreditFrom}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtMaxLeaveDays'].focus() };
                            }}
                            onChange={(e) => HandleChange('CreditFrom', e.target.value)}
                            error={errors.CreditFrom}
                            children={<>
                                <option value="" >--Select From Month--</option>
                                <option value="JoiningMonth" >Joining Month</option>
                                <option value="NextMonth" >Next Month</option>
                                <option value="AfterProvision" >After Provision</option>
                            </>}
                        />

                        <div className='cntrl-width col-md-3 d-flex mb-1' >
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtMaxLeaveType'].focus() };
                                }}
                                maxLength='5'
                                labelClass='text-nowrap'
                                divclassname='col-lg-7 me-1'
                                label='Max Leave'
                                id='txtMaxLeaveDays'
                                required
                                placeholder='Days'
                                value={recipe.MaxLeaveDays}
                                onChange={(e) => HandleChange('MaxLeaveDays', numberwithDot(e.target.value))}
                                // required
                                error={errors.MaxLeaveDays}
                            />
                            {/*<select*/}
                            {/*    ref={ref => (inputrefs.current['txtMaxLeaveType']=ref)}*/}
                            {/*  //  inputrefs={inputrefs}*/}
                            {/*    className="form-control mt-4 mb-3"*/}
                            {/*    required*/}
                            {/*    id="txtMaxLeaveType" value={recipe.MaxLeaveType}*/}
                            {/*    onKeyDown={(e) => {*/}
                            {/*        if (e.key === 'Enter') { inputrefs.current['ddlCalculate'].focus() };*/}
                            {/*    }}*/}
                            {/*    onChange={(e) => HandleChange('MaxLeaveType', e.target.value)}*/}
                            {/*    error={errors.MaxLeaveType} >*/}
                            {/*    <option value="PerMonth" >Per Month</option>*/}
                            {/*    <option value="PerYear" >Per Year</option>*/}
                            {/*</select>*/}

                            <SelectInputBox
                                //   label='Deduction Type'
                                divclassname='col-lg-5 '
                                divstyle={{ marginTop: '.1rem' }}
                                inputClassName='mt-4 form-control'
                                inputrefs={inputrefs}
                                required
                                id="txtMaxLeaveType"
                                value={recipe.MaxLeaveType}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['ddlCalculate'].focus() };
                                }}
                                onChange={(e) => HandleChange('MaxLeaveType', e.target.value)}
                                error={errors.MaxLeaveType}
                                children={<>
                                    <option value="PerMonth" >Per Month</option>
                                    <option value="PerYear" >Per Year</option>
                                </>}
                            />
                        </div>

                        <SelectInputBox
                            label='Calculate'
                            inputrefs={inputrefs}
                            required
                            id="txtMaxLeaveType"
                            value={recipe.Calculate}
                            error={errors.Calculate}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { recipe.Calculate === 'Calculate' ? inputrefs.current['txtCalculateDays'].focus() : inputrefs.current['txtIsCarryForwardAll'].focus() };
                            }}
                            onChange={(e) => {
                                HandleChange('Calculate', e.target.value)
                                if (e.target.value) {
                                    setRecipe({ ...recipe, CalculateDays: '', CalculateLeave: "", Calculate: e.target.value })
                                }
                            }}
                            children={<>
                                <option value="Fixed" >Fixed</option>
                                <option value="Calculate" >Calculate</option>
                            </>}
                        />

                        {recipe.Calculate === 'Calculate' ? <>
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtCalculateLeave'].focus() };
                                }}
                                label='Days'
                                id='txtCalculateDays'
                                maxLength='5'
                                placeholder="Days"
                                value={recipe.CalculateDays} error={errors.CalculateDays}
                                onChange={(e) => { HandleChange('CalculateDays', numberwithDot(e.target.value)) }}
                                required={recipe.Calculate === 'Calculate'}
                            />

                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtIsCarryForwardAll'].focus() };
                                }}
                                label='Leave'
                                id='txtCalculateLeave'
                                maxLength='5'
                                placeholder="Leave"
                                value={recipe.CalculateLeave}
                                error={errors.CalculateLeave}
                                onChange={(e) => HandleChange('CalculateLeave', numberwithDot(e.target.value))}
                                required
                            />
                        </> : <></>}

                        <Switch
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { recipe.IsCarryForwardAll ? inputrefs.current['txtLimit'].focus() : inputrefs.current['txtIsActive'].focus() };
                            }}
                            label='Carry Forward All'
                            checked={recipe.IsCarryForwardAll}
                            id='txtIsCarryForwardAll'
                            onChange={(e) => {
                                HandleChange('IsCarryForwardAll', e.target.checked)
                                if (!e.target.checked) {
                                    setRecipe({ ...recipe, Limit: '', IsCarryForwardAll: e.target.checked })
                                }
                            }}
                        />

                        {recipe.IsCarryForwardAll === true ? <>
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtIsActive'].focus() };
                                }}
                                label='Limit'
                                id='txtLimit'
                                maxLength='5'
                                placeholder="Limit"
                                value={recipe.Limit} error={errors.Limit}
                                onChange={(e) => { HandleChange('Limit', numberwithDot(e.target.value)) }}
                                required
                            />
                        </> : <></>}

                        <Switch
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtApplicationType'].focus() };
                               // if (e.key === 'Enter') { handleSubmit() };
                            }}
                            label='Active'
                            checked={recipe.IsActive}
                            id='txtIsActive'
                            nextinputid='txtFranchiseGroup'
                            onChange={(e) => HandleChange('IsActive', e.target.checked)}
                        />
                        <SelectInputBox
                            label='Application Type'
                            inputrefs={inputrefs}
                            required
                            id="txtApplicationType"
                            value={recipe.ApplicationType}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtGender'].focus() };
                            }}
                            onChange={(e) => HandleChange('ApplicationType', e.target.value)}
                            error={errors.ApplicationType}
                            children={<>
                                <option value="">-- Select Application Type --</option>
                                <option value="All">All</option>
                                <option value="Absent">Against Absenteeism</option>
                                <option value="Planned">Planned Leave</option>
                            </>}
                        />

                        <SelectInputBox
                            label='Gender'
                            inputrefs={inputrefs}
                            required
                            id="txtGender"
                            value={recipe.Gender}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { handleSubmit() };
                            }}
                            onChange={(e) => HandleChange('Gender', e.target.value)}
                            error={errors.Gender}
                            children={<>
                                <option value="">-- Select Gender --</option>
                                <option value="All">All</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </>}
                        />

                        <FormButton
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/MASTER/ShowLeaveMaster', ExportRequest, 'Leave Master')}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>

            {/* Table Component start*/}
            <Table
                Token={TokenData}
                PageName='LeaveMaster'
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleFilter={() => {
                    getFormData();
                    setFilterData({
                        ...filterData,
                    });
                }}
                tableData={result}
                //  loading={loading}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
            {/* Table Component End*/}


            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}
        </>
    )
}

export default LeaveMaster