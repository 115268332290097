import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import { AutoCompleteBox, DateBox, FormButton, InputBox, SelectInputBox } from "../commoncomponents/InputBox";
import { Table } from '../commoncomponents/Table';

//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';

//services
import { getCommonData } from '../../apiservices/CommonServices';
import { post } from '../../apiservices/service';
import { dateFormate, number, Token } from '../Common/Common';


function SearchEmployee() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();
    const TokenData = Token()

    const [recipe, setRecipe] = useState({
        Company: TokenData.Company,
        EmpCode: '',
        EmpName: '',
        FatherName: '',
        Department: '',
        Designation: '',
        Branch: '',
        MobileNo: '',
        DOJFromDate: '',
        ToDate: '',
        AccountNo: '',
        PFNo: '',
        UANNo: '',
        CreatedDateFrom: '',
        CreatedDateTo: '',
        DateofJoining: '',
        Status: '',
        State: '',
        AadhaarCardNo: '',
        ESICNo: '', 
        OldEmpCode: '',
    })
    const navigate = useNavigate()
    const [showTable, setShowTable] = useState(false)
    const [errors, setErrors] = useState({});
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [departmentAllData, setDepartmentAllData] = useState([])
    const [stateAllData, setStateAllData] = useState([])
    const [designationAllData, setDesignationAllData] = useState([])
    const [branchAllData, setBranchAllData] = useState([])
    const [companyAllData, setCompanyAllData] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const [oldEmpDataAllData, setOldEmpDataAllData] = useState([])



    const StateAllData = (value) => {
        setStateAllData([])
        let temp = {
            Type: "GetState",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setStateAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setStateAllData(data)
                }
            }
        });
    }

    const getCompanyData = () => {
        let temp = {
            Type: "GetCompany",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setCompanyAllData(data)
                }
            }
        });
    }

    const getDepartmentData = () => {
        let temp = {
            Type: "GetDepartment",
            Prefix: '',
            ContextKey: recipe.Company ? recipe.Company : '',
            ContextKey2: ""
        }
        //setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setDepartmentAllData(data)
                }
            }
        });
    }


    const getDesignationData = () => {
        let temp = {
            Type: "GetDesignation",
            Prefix: '',
            ContextKey: recipe.Company ? recipe.Company : '',
            ContextKey2: ""
        }
        //setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setDesignationAllData(data)
                }
            }
        });
    }
    
    const getBranchData = () => {
        let temp = {
            Type: "GetBranch",
            Prefix: '',
            ContextKey: recipe.Company ? recipe.Company : '',
            ContextKey2: ""
        }
        //setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    //setLoading(false)
                } else {
                    //setLoading(false)
                    let data = res.dataList
                    setBranchAllData(data)
                }
            }
        });
    }
    //Change Handler
    const HandleChange = (prop, value) => {

        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //Submit Handler
    const handleSubmit = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        // setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        setLoading(true)
        post(`${API_SERVER}api/Employee/SearchEmployee`, { ...TokenData, ...temp, ...sortConfig, ...filterData, ...recipe, PageType: "SearchEmployee" }, (res) => {

            // console.log('1111111111111111111111111',res)
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    toast.error(res.Message)
                    setLoading(false)
                    setResult({ ...res })
                    //  console.log(recipe, 'if')
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else {
                    setLoading(false)
                    toast.success(res.message)
                    setResult({ ...res })
                    setShowTable(true)
                }
            }
        });

    }

    const handleChange1 = (event, value) => {
        // getFormData(value);
        handleSubmit(value);
    };
    //Handle Edit
    const handleEdit = (Id, item) => {
        console.log(Id, item.EmpCode)
        if (item.EmpCode) {
            navigate(`/Employee/EmployeeMaster?EmpCode=${item.EmpCode}`)
        }
     }

    //Handle Delete
    const handleDelete = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/Candidate/DeleteCandidate`, { ...TokenData, CandidateID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else {
                    toast.success(res.message)
                    setLoading(false)
                }
            }
        });
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            Company: TokenData.Company,
            EmpCode: '',
            EmpName: '',
            FatherName: '',
            Department: '',
            Designation: '',
            Branch: '',
            MobileNo: '',
            DOJFromDate: '',
            ToDate: '',
            AccountNo: '',
            PFNo: '',
            UANNo: '',
            CreatedDateFrom: '',
            CreatedDateTo: '',
            DateofJoining: '',
            Status: '',
            State: '',
            AadhaarCardNo: '',
            ESICNo: '',
            OldEmpCode:'',
        }))
        setErrors({})
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        setShowTable(false)
    }

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        ...recipe,
        Type: 'E',
        PageIndex: '1',
        PageType: "SearchEmployee"

    }
    //End Export functionality

    let StateData = []
    for (let i = 0; i < stateAllData.length; i++) {
        let mydata = stateAllData[i]?.values
        StateData.push(mydata)
    };

    let CompanyData = []
    for (let i = 0; i < companyAllData.length; i++) {
        let mydata = companyAllData[i]?.values
        CompanyData.push(mydata)
    };

    let DepartmentData = []
    for (let i = 0; i < departmentAllData.length; i++) {
        let mydata = departmentAllData[i]?.values
        DepartmentData.push(mydata)
    };

    let DesignationData = []
    for (let i = 0; i < designationAllData.length; i++) {
        let mydata = designationAllData[i]?.values
        DesignationData.push(mydata)
    };
    
    let BranchData = []
    for (let i = 0; i < branchAllData.length; i++) {
        let mydata = branchAllData[i]?.values
        BranchData.push(mydata)
    };

    let OldEmpData = []
    for (let i = 0; i < oldEmpDataAllData.length; i++) {
        let mydata = oldEmpDataAllData[i]?.values
        OldEmpData.push(mydata)
    };

    const getOldEmpData = () => {
        let temp = {
            Type: "GetOldEmpCode",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setOldEmpDataAllData(data)
                }
            }
        });
    }

    useEffect(() => {
        inputrefs.current['txtCompany'].focus()
        document.title = 'Pay Plus: Search Employee'
        StateAllData()
        getDepartmentData()
        getDesignationData()
        getBranchData()
        getCompanyData()
        getOldEmpData()
        //    getFormData()
    }, [sortConfig, isUpdate])

    console.log(recipe)
    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Search Employee</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtOldEmpCode'].focus() };
                            }}
                            disabled 
                            label='Company'
                            placeholder="Company"
                            maxLength='50'
                            options={CompanyData}
                            id='txtCompany'
                            // required={true}
                            error={errors.Company}
                            inputValue={recipe.Company ? recipe.Company : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('Company', newInputValue)
                                //setStatePre(newInputValue)
                            }}
                        />
                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtEmpCode'].focus() };
                            }}
                            label='Old Emp Code'
                            placeholder="Old Emp Code"
                            maxLength='50'
                            options={OldEmpData}
                            id='txtOldEmpCode'
                            error={errors.OldEmpCode}
                            inputValue={recipe.OldEmpCode ? recipe.OldEmpCode : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('OldEmpCode', newInputValue)
                            }}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtEmpName'].focus() };
                            }}
                            label='Emp Code'
                            id='txtEmpCode'
                            maxLength='50'
                            placeholder="Emp Code"
                            value={recipe.EmpCode}
                            onChange={(e) => { HandleChange('EmpCode', (e.target.value)) }}
                        //required
                        //  error={errors.EmpCode}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtDepartment'].focus() };
                            }}
                            label='Emp Name'
                            id='txtEmpName'
                            maxLength='50'
                            placeholder="Emp Name"
                            value={recipe.EmpName}
                            onChange={(e) => { HandleChange('EmpName', (e.target.value)) }}
                        //  required
                        //  error={errors.EmpName}
                        />
                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtDesignation'].focus() };
                            }}
                            onFocus={(e) => {
                                getDepartmentData()
                            }}
                            label='Department'
                            placeholder="Department"
                            maxLength='50'
                            options={DepartmentData}
                            id='txtDepartment'
                            // required={true}
                            error={errors.Department}
                            inputValue={recipe.Department ? recipe.Department : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('Department', newInputValue)
                                
                                
                                //setStatePre(newInputValue)
                            }}
                        />
                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtBranch'].focus() };
                            }}
                            onFocus={(e) => {
                                getDesignationData()
                            }}
                            label='Designation'
                            placeholder="Designation"
                            maxLength='50'
                            options={DesignationData}
                            id='txtDesignation'
                            //  required={true}
                            error={errors.Designation}
                            inputValue={recipe.Designation ? recipe.Designation : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('Designation', newInputValue)
                                //setStatePre(newInputValue)
                            }}
                        />
                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtFatherName'].focus() };
                            }}
                            onFocus={(e) => {
                                getBranchData()
                            }}
                            label='Branch'
                            placeholder="Branch"
                            maxLength='50'
                            options={BranchData}
                            id='txtBranch'
                            //  required={true}
                           // error={errors.Branch}
                            inputValue={recipe.Branch ? recipe.Branch : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('Branch', newInputValue)
                                //setStatePre(newInputValue)
                            }}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtMobileNo'].focus() };
                            }}
                            label={`Father's Name`}
                            id='txtFatherName'
                            maxLength='50'
                            placeholder={`Father's Name`}
                            value={recipe.FatherName}
                            onChange={(e) => { HandleChange('FatherName', (e.target.value)) }}
                        //   required
                        //  error={errors.FatherName}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtDOJFromDate'].focus() };
                            }}
                            label='Mobile No.'
                            id='txtMobileNo'
                            maxLength='10'
                            placeholder="Mobile No."
                            //  required
                            //   error={errors.MobileNo}
                            value={recipe.MobileNo} onChange={(e) => { HandleChange('MobileNo', number(e.target.value)) }}
                        />
                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtToDate'].focus() };
                            }}
                            label='DOJ From Date'
                            id='txtDOJFromDate'
                            selected={recipe.DOJFromDate === '' ? "" : new Date(recipe.DOJFromDate)}
                            placeholder="DOJ From Date"
                            //  value={recipe.DOJFromDate}
                            onChange={(e) => HandleChange('DOJFromDate', dateFormate(e))}

                        />


                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtAccountNo'].focus() };
                            }}
                            label='To Date'
                            id='txtToDate'
                            selected={recipe.ToDate === "" ? '' : new Date(recipe.ToDate)}
                            placeholder="To Date"
                            //   value={recipe.ToDate}
                            onChange={(e) => HandleChange('ToDate', dateFormate(e))}
                        //   required
                        //  error={errors.ToDate}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtAadhaarCardNo'].focus() };
                            }}
                            label='Account No.'
                            id='txtAccountNo'
                            maxLength='18'
                            placeholder="Account No"
                            value={recipe.AccountNo}
                            onChange={(e) => { HandleChange('AccountNo', number(e.target.value)) }}
                        //required
                        //  error={errors.Branch}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtPFNo'].focus() };
                            }}
                            label='Aadhaar Card No.'
                            id='txtAadhaarCardNo'
                            maxLength='12'
                            placeholder="Aadhaar Card No"
                            value={recipe.AadhaarCardNo}
                            onChange={(e) => { HandleChange('AadhaarCardNo', number(e.target.value)) }}
                        //required
                        //  error={errors.Branch}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtESICNo'].focus() };
                                
                            }}
                            label='PF No.'
                            id='txtPFNo'
                            maxLength='22'
                            placeholder="PF No"
                            value={recipe.PFNo}
                            onChange={(e) => { HandleChange('PFNo', (e.target.value)) }}
                        //required
                        //  error={errors.Branch}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtUANNo'].focus() };
                            }}
                            label='ESIC No.'
                            id='txtESICNo'
                            maxLength='17'
                            placeholder="ESIC No"
                            value={recipe.ESICNo}
                            onChange={(e) => { HandleChange('ESICNo', number(e.target.value)) }}
                        //required
                        //  error={errors.Branch}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtCreatedDateFrom'].focus() };
                            }}
                            label='UAN No.'
                            id='txtUANNo'
                            maxLength='12'
                            placeholder="UAN No"
                            value={recipe.UANNo}
                            onChange={(e) => { HandleChange('UANNo', number(e.target.value)) }}
                        //required
                        //  error={errors.Branch}
                        />
                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtCreatedDateTo'].focus() };
                            }}
                            label='Created Date From'
                            id='txtCreatedDateFrom'
                            selected={recipe.CreatedDateFrom === "" ? "" : new Date(recipe.CreatedDateFrom)}
                            placeholder="Created Date From"
                            // value={recipe.CreatedDateFrom}
                            onChange={(e) => HandleChange('CreatedDateFrom', dateFormate(e))}
                        />
                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtDateOfJoining'].focus() };
                            }}
                            label='Created Date To'
                            id='txtCreatedDateTo'
                            selected={recipe.CreatedDateTo === "" ? "" : new Date(recipe.CreatedDateTo)}
                            placeholder="Created Date To"
                            //  value={recipe.CreatedDateTo}
                            onChange={(e) => HandleChange('CreatedDateTo', dateFormate(e))}
                        />
                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtStatus'].focus() };
                            }}
                            label='Date of Joining'
                            id='txtDateOfJoining'
                            selected={recipe.DateofJoining === "" ? "" : new Date(recipe.DateofJoining)}
                            placeholder="Date of Joining"
                            //  value={recipe.DateofJoining}
                            onChange={(e) => HandleChange('DateofJoining', dateFormate(e))}
                        />


                        {/*<DateBox*/}
                        {/*    inputrefs={inputrefs}*/}
                        {/*    onKeyDown={(e) => {*/}
                        {/*        if (e.key === 'Enter') { inputrefs.current['txtStatus'].focus() };*/}
                        {/*    }}*/}
                        {/*    label='Date of Joining'*/}
                        {/*    id='txtDateOfJoining'*/}
                        {/*    selected={recipe.DateOfJoining === "" ? "" : new Date(recipe.DateOfJoining)}*/}
                        {/*    placeholder="Date of Joining"*/}
                        {/* //   value={recipe.DateOfJoining}*/}
                        {/*    onChange={(e) => HandleChange('DateOfJoining', dateFormate(e))}*/}
                        {/*/>*/}
                        {/*<div className={`col-md-3 mb-1 cntrl-width`} key="txtStatus">*/}
                        {/*    <label className="form-label">Status</label>*/}
                        {/*    <select*/}
                        {/*        ref={ref => (inputrefs.current['txtStatus'] = ref)}*/}
                        {/*        className="form-control"*/}
                        {/*        maxLength='18'*/}
                        {/*        id="txtStatus"*/}
                        {/*        //key="txtPerAddressProof"*/}
                        {/*        value={recipe.Status}*/}
                        {/*        // value={isPerAddressSame ? recipe.CorAddressProof : recipe.PerAddressProof}*/}
                        {/*       // error={recipe.Status}*/}
                        {/*        onKeyDown={(e) => {*/}
                        {/*            if (e.key === 'Enter') { inputrefs.current['txtState'].focus() };*/}
                        {/*        }}*/}
                        {/*        onChange={(e) => HandleChange('Status', e.target.value)}>*/}
                        {/*        <option value="">--Select Status--</option>*/}
                        {/*        <option value="1">Retired</option>*/}
                        {/*        <option value="2">ALL</option>*/}
                        {/*        <option value="3">Bank A/C Not Open</option>*/}
                        {/*        */}{/*   {addressProofDocument?.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}*/}

                        {/*    </select>*/}
                        {/*</div>*/}


                        <SelectInputBox
                            //     divclassname='col-4 mt-4 p-0 ps-0'
                            //      inputStyle={{ marginTop: '0.1rem' }}
                            label='Status'
                            inputrefs={inputrefs}
                            
                            id="txtStatus"
                            value={recipe.Status}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtState'].focus() };
                            }}
                            onChange={(e) => HandleChange('Status', e.target.value)}
                            children={<>
                                <option value="">--Select Status--</option>
                                <option value="1">Retired</option>
                                <option value="2">ALL</option>
                                <option value="3">Bank A/C Not Open</option>

                            </>}
                        />
                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { handleSubmit() };
                            }}
                            label='State'
                            placeholder="State"
                            maxLength='10'
                            options={StateData}
                            id='txtState'
                            key='txtState'
                            // required={true}
                            inputValue={recipe.State ? recipe.State : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('State', newInputValue)
                                StateAllData(newInputValue)

                            }}
                        />
                       
                     
                        <FormButton
                            SaveText='Search'
                            saveButtonIcon='fa-search'
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/Employee/SearchEmployee', ExportRequest, 'Search Employee')}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>

            {/* Table Component start */}
            {showTable &&

                <Table
                    Filter={false}
                    Token={TokenData}
                    PageName='SearchEmployee'
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    // handleFilter={() => {
                    //     // getFormData();
                    //     handleSubmit();
                    //     setFilterData({
                    //         ...filterData,
                    //     });
                    // }}
                    tableData={result}
                    loading={loading}
                    // filterData={filterData}
                    // setFilterData={setFilterData}
                    currentPageNo={currentPageNo}
                    handleChange1={handleChange1}
                //  handleSorting={handleSorting}
                //  sortConfig={sortConfig}
                />
            }
            {/* Table Component End*/}


            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}

        </>
    )
}

export default SearchEmployee;