
import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, AutoCompleteBoxWithLabel, DateBox, SelectInputBox } from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
//loder
import BlurLoader from "../Loder/BlurLoader";
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { Token, dateFormate, number, numberwithDot } from '../Common/Common';

function LeaveStatusReport() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();
    //token Data
    //const TokenData = {
    //    AuthToken: process.env.REACT_APP_SECRET_KEY,
    //    LoginToken: user.loginsessionID,
    //    UserID: user.userID,
    //    CompanyID: user.companyID
    //}
    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({
        //  loanTypeID: "0",
        branch: '',
        employee: '',
        leavePeriodFromDate: '',
        leavePeriodToDate: '',
        leaveStatus: '',
    })

    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData);
    const [isUpdate, setIsUpdate] = useState(false);
    const [branchAllData, setBranchAllData] = useState([])
    const [employeeAllData, setEmployeeAllData] = useState([])
    const [errors, setErrors] = useState({});
    const [showTable, setShowTable] = useState(false)


    //Get branch Data for AutoComplete
    const GetBranchData = (value) => {
        setBranchAllData([])
        let temp = {
            Type: "GetBranch",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setBranchAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setBranchAllData(data)
                }
            }
        });
    }

    //Get Employee Data for AutoComplete
    const GetEmployeeData = (value) => {
        setEmployeeAllData([])
        let temp = {
            Type: "GetEmployee",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setEmployeeAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setEmployeeAllData(data)
                }
            }
        });
    }

    //Submit Handler
    const handleSubmit = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        // setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        setLoading(true)
        post(`${API_SERVER}api/Report/LeaveStatusReport`, { ...TokenData, ...temp, ...sortConfig, ...filterData, ...recipe }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    toast.error(res.Message)
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED" ) {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    toast.success(res.message)
                    setResult({ ...res })
                    setShowTable(true)
                    //getFormData()
                    // handleClear()
                    //console.log(recipe, 'else')
                }
            }
        });

    }

    
    const handleChange1 = (event, value) => {
        //getFormData(value);
        handleSubmit(value);
    };

    useEffect(() => {
        inputrefs.current['txtBranch'].focus();
        document.title = 'Pay Plus : Leave Status Report';
        GetBranchData();
        GetEmployeeData();
        if (sortConfig.SortOrder !== null) handleSubmit();


    }, [sortConfig, isUpdate])

    //useEffect(() => {

    //})

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "branch", msg: 'Please Enter Branch.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {

                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            branch: '',
            employee: '',
            leavePeriodFromDate: '',
            leavePeriodToDate: '',
            leaveStatus: '',
        }))
        setErrors({})
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        setShowTable(false)
        // getFormData()
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    let BranchData = []
    for (let i = 0; i < branchAllData.length; i++) {
        let mydata = branchAllData[i]?.values
        BranchData.push(mydata)
    };

    let EmployeeData = []
    for (let i = 0; i < employeeAllData.length; i++) {
        let mydata = employeeAllData[i]?.values
        EmployeeData.push(mydata)
    };

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        ...recipe,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality


    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Leave Status Report</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>

                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtEmployee'].focus() };
                            }}
                            label='Branch'
                            placeholder="Branch"
                            maxLength='50'
                            options={BranchData}
                            id='txtBranch'
                            key='txtBranch'
                            //required={true}
                            // error={errors.branch}
                            inputValue={recipe.branch ? recipe.branch : ''}
                            onInputChange={(event, newInputValue) => {
                                GetBranchData(newInputValue)
                                HandleChange('branch', newInputValue)

                            }}
                        />
                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtLeaveStatus'].focus() };
                            }}
                            label='Employee'
                            placeholder="Employee"
                            maxLength='50'
                            options={EmployeeData}
                            id='txtEmployee'
                            key='txtEmployee'
                            // required={true}
                            // error={errors.employee}
                            inputValue={recipe.employee ? recipe.employee : ''}
                            onInputChange={(event, newInputValue) => {
                                GetEmployeeData(newInputValue)
                                HandleChange('employee', newInputValue)

                            }}
                        />
                        {/*<div className={`col-md-3 mb-1 cntrl-width`} key="txtLeaveStatus">*/}
                        {/*    <label className="form-label">Leave Status*/}{/*<span style={{ color: "red" }}> *</span>*/}{/*</label>*/}
                        {/*    <select ref={ref => (inputrefs.current['txtLeaveStatus'] = ref)}*/}
                        {/*        className="form-control"*/}
                        {/*        maxLength='18'*/}
                        {/*        id="txtLeaveStatus"*/}
                        {/*        // key="txtLeaveStatus"*/}
                        {/*        value={recipe.leaveStatus}*/}
                        {/*        error={recipe.leaveStatus}*/}
                        {/*        onKeyDown={(e) => {*/}
                        {/*            if (e.key === 'Enter') { inputrefs.current['txtLeavePeriodFromDate'].focus() };*/}
                        {/*        }}*/}
                        {/*        onChange={(e) => HandleChange('leaveStatus', e.target.value)}>*/}
                        {/*        <option value="">--Select Leave Status--</option>*/}
                        {/*        <option value="All">All</option>*/}
                        {/*        <option value="Approved">Approved</option>*/}
                        {/*        <option value="Rejected">Rejected</option>*/}
                        {/*        <option value="Pending">Pending</option>*/}
                        {/*        <option value="Cancelled">Cancelled</option>*/}
                        {/*        */}{/*{addressProofDocument?.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}*/}

                        {/*    </select>*/}
                        {/*    */}{/*<div className="error-msg">{errors.leaveStatus}</div>*/}
                        {/*</div>*/}
                        <SelectInputBox
                            //divclassname='col-4 mt-4 p-0 ps-0'
                          //  inputStyle={{ marginTop: '0.1rem' }}
                            label='Leave Status'
                            inputrefs={inputrefs}
                            id="txtLeaveStatus"
                            // key="txtLeaveStatus"
                            value={recipe.leaveStatus}
                            error={errors.leaveStatus}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtLeavePeriodFromDate'].focus() };
                            }}
                            onChange={(e) => HandleChange('leaveStatus', e.target.value)}
                            children={<>
                                <option value="">--Select Leave Status--</option>
                                <option value="All">All</option>
                                <option value="Approved">Approved</option>
                                <option value="Rejected">Rejected</option>
                                <option value="Pending">Pending</option>
                                <option value="Cancelled">Cancelled</option>
                            </>}
                        />

                        <div className={`col-md-4 mb-1 `} key="txtLeavePeriod">
                            <label className="form-label"> Leave Period{/*<span style={{ color: "red" }}> *</span>*/}</label>
                            <div className='row'>
                                <DateBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtLeavePeriodToDate'].focus() };
                                    }}
                                    //labelClass='text-nowrap'
                                    divclassname='col-lg-6'
                                    id='txtLeavePeriodFromDate'
                                    //  label='From Date'
                                    placeholder="Leave From"
                                    selected={recipe.leavePeriodFromDate === '' ? '' : new Date(recipe.leavePeriodFromDate)}
                                    onChange={(e) => HandleChange('leavePeriodFromDate', dateFormate(e))}
                                // error={errors.fromDate}
                                // required
                                />
                                <DateBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { handleSubmit() };
                                    }}
                                    //labelClass='text-nowrap'
                                    divclassname='col-lg-6'
                                    id='txtLeavePeriodToDate'
                                    //label='To Date'
                                    placeholder="Leave To"
                                    selected={recipe.leavePeriodToDate === '' ? '' : new Date(recipe.leavePeriodToDate)}
                                    onChange={(e) => HandleChange('leavePeriodToDate', dateFormate(e))}
                                // error={errors.toDate}
                                //required
                                />
                            </div>

                        </div>

                      

                        <FormButton
                            saveButtonIcon='fa-search'
                            SaveText='Search'
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/Report/LeaveStatusReport', ExportRequest, 'Leave Status Report')}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>
            {showTable &&
                <Table
                    Filter={false}
                    Action={false}
                    Token={TokenData}
                PageName='LeaveStatusReport'
                    // handleEdit={handleEdit}
                    // handleDelete={handleDelete}
                   // handleFilter={() => {
                   //     //getFormData();
                   //     handleSubmit();
                   //     setFilterData({
                   //         ...filterData,
                   //     });
                   // }}
                    tableData={result}
                    loading={loading}
                   // filterData={filterData}
                   // setFilterData={setFilterData}
                    currentPageNo={currentPageNo}
                    handleChange1={handleChange1}
                  //  handleSorting={handleSorting}
                  //  sortConfig={sortConfig}
                />
            }
            {loading && < BlurLoader />}
        </>
    )
}

export default LeaveStatusReport
