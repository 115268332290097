
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AutoCompleteBox, FormButton, InputBox, SelectInputBox } from "../commoncomponents/InputBox";
import { useRef, useState } from "react";
import { useAuth } from "../../context/auth";
import { API_SERVER, initializeFilterData } from "../../Utils";
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { useEffect } from 'react';
import TextEditor from '../commoncomponents/TextEditor';
import { post } from "../../apiservices/service";
import { toast } from 'react-toastify';
import { Token } from '../Common/Common';
import BlurLoader from "../Loder/BlurLoader";

const MailTemplate = () => {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();
  
    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({
        MailType: "",
        Company: TokenData.Company,
        MailTemplateID:'0'
    })
    const [textAreaValue, setTextAreaValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [mailTypeData, setMailTypeData] = useState([]);
    const [textAreaData, setTextAreaData] = useState([]);
    const [companyAllData, setCompanyAllData] = useState([]);
    const [errors, setErrors] = useState({});

    //Get Company Autocomplete Data
    const getCompanyData = () => {
        let temp = {
            Type: "GetCompany",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setCompanyAllData(data)
                }
            }
        });
    }

    const GetMailTypeData = () => {
        setMailTypeData([])
        let temp = {
            Type: "GetMailType",
            Prefix: '',

        }
        //  setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setMailTypeData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setMailTypeData(data)
                }
            }
        });
    }


    const GetLetterFormateData = (type) => {
        setLoading(true)
        post(`${API_SERVER}api/Master/GetMailTemplate`, { ...TokenData, MailType: type, }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    let data = res.data
                   // console.log(res.data)
                    setTextAreaValue(data.mailTemplate ? data.mailTemplate : '')
                    setRecipe({
                        ...recipe,
                        MailType: data.mailType,
                        MailTemplate: data.mailTemplate,
                        MailTemplateID: data.mailTemplateID
                        //  data.letterFormat: data.letterFormat
                    })
                }
            }
        });
    }


    const GetTextAreaData = () => {
        setTextAreaData([])
        setLoading(true)
        post(`${API_SERVER}api/Master/GetMailTemplateFields`, { ...TokenData, }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setTextAreaData([])
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    let data = res.data
                    setTextAreaData(data.value)

                }
            }
        });
    }

    //validation handler
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "MailType", msg: 'Please Select Mail Type.' },
            { field: "Company", msg: 'Please Select Company.' },

        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {

                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }
   // console.log(errors)


    const HandleChange = (prop, value) => {
        if (prop === 'MailType') {
            GetLetterFormateData(value)
        }
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    const HandleSave = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/Master/InsertUpdateMailTemplate`, { ...TokenData, ...recipe, MailTemplate: textAreaValue }, (res) => {

                if (res && res.status) {
                    if (res.status === "ERROR") {
                        setLoading(false)
                        toast.error(res.message)
                        inputrefs.current[res.focus].focus();
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "UNAUTHORIZED") {
                        setLoading(false);
                        toast.error(res.Message);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        handleClear()
                    }
                }
            });
        }
    }
    const handleClear = () => {
        setRecipe({
            ...recipe,
            MailType: "",
            Company: TokenData.Company,
            MailTemplateID: '0'
        })
        setErrors({})
        setTextAreaValue('')
    }


    let CompanyData = []
    for (let i = 0; i < companyAllData.length; i++) {
        let mydata = companyAllData[i]?.values
        CompanyData.push(mydata)
    };

    useEffect(() => {
        inputrefs.current['txtCompany'].focus();
        document.title = 'Pay Plus : Mail Template';
        GetMailTypeData();
        getCompanyData();
        GetTextAreaData();
    }, [])


    return (
        <div className='main-card card border-0'>
            <div className='card-header mb-1'>
                <h6 className="m-0">Mail Template</h6>
            </div>
            <div className='card-body'>
                <div className='row'>
                    <div className='row '>

                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtFormatType'].focus() };
                            }}
                            disabled
                            label='Company'
                            placeholder="Company"
                            maxLength='50'
                            options={CompanyData}
                            id='txtCompany'
                            required={true}
                            error={errors.Company}
                            inputValue={recipe.Company ? recipe.Company : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('Company', newInputValue)
                            }}
                          //  onFocus={getCompanyData }
                        />
                       

                        <SelectInputBox
                            label='Mail Type'
                            id='txtMailType'
                            onKeyDown={(e) => {
                                //    if (e.key === 'Enter') { inputrefs.current['txtLoanDate'].focus() };
                            }}
                            required
                            inputrefs={inputrefs}
                            error={errors.MailType}
                            value={recipe.MailType}
                            onChange={(e) => {
                                HandleChange('MailType', e.target.value)
                            }}
                            placeholder='Mail Type'
                            children={<>
                                <option value='' >--Select Format Type--</option>
                                {mailTypeData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}
                            </>}
                        />

                    </div>
                    <div className="row">
                        <div className='col-md-8 col-sm-8 col-xs-8'>
                            <TextEditor
                                value={textAreaValue}
                                setValue={setTextAreaValue}
                            />
                        </div>

                        <textarea
                            disabled
                            value={textAreaData}
                            className='col-md-4 col-sm-4 col-xs-4'
                        />

                    </div>

                    <FormButton
                        handleNext={HandleSave}
                        Export={false}
                        Next={true}
                        save={false}
                        nextButtonColor='btn-primary'
                        nextButtonIcon='fa-check'
                        nextButtonText='Save'
                        handleClear={handleClear}
                    />
                </div>
            </div>
            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}
        </div>
    )
}

export default MailTemplate;