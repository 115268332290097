import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { AutoCompleteBox, FormButton, SelectInputBox } from "../commoncomponents/InputBox";

//toast
import { toast } from 'react-toastify';

//user auth
import { useAuth } from '../../context/auth';

//loder
import BlurLoader from "../Loder/BlurLoader";

//services
import { getCommonData, getMasterData } from "../../apiservices/CommonServices";
import { post } from "../../apiservices/service";
import { API_SERVER } from "../../Utils";
import { Token } from "../Common/Common";

function UserMenuAccess() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();

    //initial states
    const [CheckView, setCheckView] = useState(false);
    const [CheckEdit, setCheckEdit] = useState(false);
    const [loading, setLoading] = useState(false)
    const [CheckDel, setCheckDel] = useState(false);
    const [CheckAdd, setCheckAdd] = useState(false);
    const [CheckExp, setCheckExp] = useState(false);
    const [CheckAll, setCheckAll] = useState(false);
    const [checkByFolderName, setCheckByFolderName] = useState(false);
    const [UserIDPre, setUserIDPre] = useState('');
    const [UserAllData, setUserAllData] = useState([])
    //const [userGroupIDData, setUserGroupIDData] = useState([])
    const [errors, setErrors] = useState({});
    const [recipe, setRecipe] = useState({
        UserName: '',
    })
    const [formData, setFormData] = useState([])
    const [dataLoading, setDataLoading] = useState(false)

    //auth Token
    //const TokenData = {
    //    AuthToken: process.env.REACT_APP_SECRET_KEY,
    //    LoginToken: user.loginsessionID,
    //    UserID: user.userID,
    //    CompanyID: user.companyID
    //}
    const TokenData = Token()

    let Request = {
        data: formData,

    }

    let MPageID = '';

    //------------to get the data of Menus---------------------------------//
    const getData = async () => {
        await post(`${API_SERVER}api/User/EditUserMenuAccess`, { ...TokenData, ...recipe }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setFormData([])
                    toast.error(res.message)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else if (res.status === 'SUCCESS') {
                    setFormData(res.data)
                }
            }
        });

    };

    //---------------------------to get user data-----------------------------------//
    const UserData = (DATA) => {
        let temp = {
            Type: "GetUser",
            Prefix: DATA ? DATA : '',
            ContextKey:  "",
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            console.log(res)
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setUserAllData([])
                    setDataLoading(false)
                } else {
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setUserAllData(data)
                    setDataLoading(false)
                }
            }
        });
    }



    //---------------------- Get User Type Data ----------------------------------------------//
    //const GetUserTypeData = () => {
    //    setUserGroupIDData([])
    //    let temp = {
    //        Type: "GetUserGroup",
    //        Prefix: '',
    //    }
    //    //  setLoading(true)
    //    getMasterData({ ...TokenData, ...temp }, (res) => {
    //        if (res && res.status) {
    //            if (res.status === "ERROR") {
    //                setLoading(false)
    //                setUserGroupIDData([])
    //            } else {
    //                setLoading(false)
    //                let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
    //                setUserGroupIDData(data)
    //            }
    //        }
    //    });
    //}

    const UserList = [];
    for (let i = 0; i < UserAllData?.length; i++) {
        let USERNAME = (UserAllData[i]?.values)
        UserList?.push(USERNAME)
    };


    useEffect(() => {
        inputrefs.current['txtUserName'].focus()
        document.title = 'Pay Plus: User Menu Access'
        getData()
    }, [recipe])



    //Handle Clear
    const handleClear = () => {
        setRecipe({  UserName: '', })
        setErrors({})
        setUserIDPre('')
        setCheckAll(false)
        setCheckAdd(false)
        setCheckEdit(false)
        setCheckDel(false)
        setCheckExp(false)
        setCheckView(false)
        getData()
    }
    //-----------------------------------Handle Validate------------------------//
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            //{ field: "UserGroupID", msg: 'Please Enter User Group ID.' },
            { field: "UserName", msg: 'Please Enter User Name.' },

        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }

    //-----------------------------------Handle Submit------------------------//
    const handleSubmit = () => {
        const myString = Validate();
        // if (myString === "") {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/User/InsertUpdateUserMenuAccess`, { ...TokenData, ...Request, ...recipe }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        setLoading(false)
                        inputrefs.current[res.focus].focus()
                        toast.error(res.message)
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                        setLoading(false)
                    } else if (res.Status === "UNAUTHORIZED") {
                        toast.error(res.Message);
                        setLoading(false);
                    } else {
                        toast.success(res.message)
                        getData()
                        handleClear()
                        setCheckAll(false)
                        setCheckAdd(false)
                        setCheckEdit(false)
                        setCheckDel(false)
                        setCheckExp(false)
                        setCheckView(false)
                        setLoading(false)
                    }
                }
            });
        }
    }


    //---------------------Handle View all--------------------------------------------//
    const ViewCheckAll = () => {
        if (CheckView === false) {
            for (let i = 0; i < formData.length; i++) {
                const updatedData = [...formData];
                updatedData[i]['view'] = true;
                setFormData(updatedData);
            }
            setCheckView(true)
        } else if (CheckView === true) {
            setCheckView(false)
            setCheckAdd(false)
            setCheckEdit(false)
            setCheckExp(false)
            setCheckDel(false)
            setCheckAll(false)
            for (let i = 0; i < formData.length; i++) {
                const updatedData = [...formData];
                updatedData[i]['view'] = false;
                updatedData[i]['add'] = false;
                updatedData[i]['edit'] = false;
                updatedData[i]['del'] = false;
                updatedData[i]['exp'] = false;
                updatedData[i]['all'] = false;
                setFormData(updatedData);
            }
        }
    };


    //----------------------------Handle Add All ------------------------------------------//
    const AddCheckAll = () => {
        if (CheckAdd === false) {
            for (let i = 0; i < formData.length; i++) {
                const updatedData = [...formData];
                updatedData[i]['add'] = true;
                setFormData(updatedData);
            }
            setCheckAdd(true)
        } else if (CheckAdd === true) {
            setCheckAdd(false)
            for (let i = 0; i < formData.length; i++) {
                const updatedData = [...formData];
                updatedData[i]['add'] = false;
                setFormData(updatedData);
            }
        }
    };


    //-----------------------------Edit All----------------------------------------//
    const EditCheckAll = () => {
        if (CheckEdit === false) {
            for (let i = 0; i < formData.length; i++) {
                const updatedData = [...formData];
                updatedData[i]['edit'] = true;
                setFormData(updatedData);
            }
            setCheckEdit(true)
        } else if (CheckEdit === true) {
            setCheckEdit(false)
            for (let i = 0; i < formData.length; i++) {
                const updatedData = [...formData];
                updatedData[i]['edit'] = false;
                setFormData(updatedData);
            }
        }
    };


    //--------------------------------Handle Delete All----------------------------------------//
    const DelCheckAll = () => {
        if (CheckDel === false) {
            for (let i = 0; i < formData.length; i++) {
                const updatedData = [...formData];
                updatedData[i]['del'] = true;
                setFormData(updatedData);
            }
            setCheckDel(true)
        } else if (CheckDel === true) {
            setCheckDel(false)
            for (let i = 0; i < formData.length; i++) {
                const updatedData = [...formData];
                updatedData[i]['del'] = false;
                setFormData(updatedData);
            }
        }
    };


    //--------------------------------Handle Export All ---------------------------------------------------//
    const ExpCheckAll = () => {
        if (CheckExp === false) {
            for (let i = 0; i < formData.length; i++) {
                const updatedData = [...formData];
                updatedData[i]['exp'] = true;
                setFormData(updatedData);
            }
            setCheckExp(true)
        } else if (CheckExp === true) {
            setCheckExp(false)
            for (let i = 0; i < formData.length; i++) {
                const updatedData = [...formData];
                updatedData[i]['exp'] = false;
                setFormData(updatedData);
            }
        }
    };


    //-------------------------------------Handle Check All ----------------------------------------------//
    const AllCheckAll = () => {
        if (CheckAll === false) {
            for (let i = 0; i < formData.length; i++) {
                const updatedData = [...formData];
                updatedData[i]['view'] = true;
                updatedData[i]['add'] = true;
                updatedData[i]['del'] = true;
                updatedData[i]['exp'] = true;
                updatedData[i]['edit'] = true;
                updatedData[i]['all'] = true;
                setFormData(updatedData);
            }
            setCheckAll(true)
            setCheckAdd(true)
            setCheckEdit(true)
            setCheckView(true)
            setCheckDel(true)
            setCheckExp(true)

        } else if (CheckAll === true) {
            setCheckAll(false)
            setCheckAdd(false)
            setCheckEdit(false)
            setCheckDel(false)
            setCheckExp(false)
            setCheckView(false)
            for (let i = 0; i < formData.length; i++) {
                const updatedData = [...formData];
                updatedData[i]['view'] = false;
                updatedData[i]['add'] = false;
                updatedData[i]['del'] = false;
                updatedData[i]['exp'] = false;
                updatedData[i]['edit'] = false;
                updatedData[i]['all'] = false;
                setFormData(updatedData);
            }
        }
    };

    //------------------------------------Handle Check by folder Name------------------------------------------//
    const CheckByFolderNameCheckBox = (e, index, mPageID) => {

        for (let i = 0; i < formData.length; i++) {
            const updatedData = [...formData];
            if (updatedData[i]['mPageID'] === mPageID) {
                if (e.target.checked === true) {
                    updatedData[i]['view'] = true;
                    updatedData[i]['add'] = true;
                    updatedData[i]['del'] = true;
                    updatedData[i]['exp'] = true;
                    updatedData[i]['edit'] = true;
                    updatedData[i]['all'] = true;
                }
                else {
                    updatedData[i]['view'] = false;
                    updatedData[i]['add'] = false;
                    updatedData[i]['del'] = false;
                    updatedData[i]['exp'] = false;
                    updatedData[i]['edit'] = false;
                    updatedData[i]['all'] = false;
                }
            }
            setCheckByFolderName(e.target.checked);
            setFormData(updatedData);
        }
    }

    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }



    const RightToLeftCheck = (e, index) => {
        if (e.target.checked === true) {
            formData[index].view = true
            formData[index].add = true
            formData[index].exp = true
            formData[index].del = true
            formData[index].edit = true
        } else {
            formData[index].view = false
            formData[index].add = false
            formData[index].exp = false
            formData[index].del = false
            formData[index].edit = false
        }
    };

    const handleCheckboxChange = (pageIndex, key, e) => {
        const updatedData = [...formData];
        if (key === 'view') {
            updatedData[pageIndex].view = !updatedData[pageIndex][key]
            updatedData[pageIndex].add = false
            updatedData[pageIndex].exp = false
            updatedData[pageIndex].del = false
            updatedData[pageIndex].edit = false
            updatedData[pageIndex].all = false
            setFormData(updatedData);
        } else {
            updatedData[pageIndex][key] = !updatedData[pageIndex][key];
            setFormData(updatedData);
        }
    };
    useEffect(() => {
        UserData()
    }, [])

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">User Menu Access</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        {/*<div className={`col-md-3 mb-1 cntrl-width`}  >*/}
                        {/*    <label className="form-label">User Group<span style={{ color: "red" }}> *</span>*/}
                        {/*    </label>*/}
                        {/*    <select ref={ref => (inputrefs.current['txtUserGroupID'] = ref)}*/}
                        {/*        className="form-control"*/}
                        {/*        id="txtUserGroupID"*/}
                        {/*        value={recipe.UserGroupID}*/}
                        {/*        onKeyDown={(e) => {*/}
                        {/*            if (e.key === 'Enter') { inputrefs.current['txtUserName'].focus() };*/}
                        {/*        }}*/}
                        {/*        onBlur={() => UserData()}*/}
                        {/*        //  onFocus={() => GetUserTypeData()}*/}
                        {/*        onChange={(e) => HandleChange('UserGroupID', e.target.value)}>*/}
                        {/*        <option value="User">--Select User Group--</option>*/}
                        {/*        {userGroupIDData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}*/}


                        {/*    </select>*/}
                        {/*    <div className="error-msg">{errors.UserGroupID}</div>*/}

                        {/*</div>*/}

                        {/*<SelectInputBox*/}
                        {/*    //     divclassname='col-4 mt-4 p-0 ps-0'*/}
                        {/*    //      inputStyle={{ marginTop: '0.1rem' }}*/}
                        {/*    style={{ display: 'none' }}*/}
                        {/*    label='User Group'*/}
                        {/*    inputrefs={inputrefs}*/}
                        {/*    required*/}
                        {/*    error={errors.UserGroupID}*/}
                        {/*    id="txtUserGroupID"*/}
                        {/*    value={recipe.UserGroupID}*/}
                        {/*    onBlur={() => UserData()}*/}
                        {/*    onKeyDown={(e) => {*/}
                        {/*        if (e.key === 'Enter') { inputrefs.current['txtUserName'].focus() };*/}
                        {/*    }}*/}
                        {/*    onChange={(e) => HandleChange('UserGroupID', e.target.value)}*/}
                        {/*    children={<>*/}
                        {/*        <option value="User">--Select User Group--</option>*/}
                        {/*        {userGroupIDData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}*/}

                        {/*    </>}*/}
                        {/*/>*/}
                        <AutoCompleteBox
                            loading={dataLoading}
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['btnSave'].focus() };
                            }}
                            label='User'
                            placeholder="User"
                            maxLength='100'
                            options={UserList}
                            id='txtUserName'
                            required={true}
                            //onFocus={() => UserData()}
                            error={errors.UserName}
                            inputValue={recipe.UserName ? recipe.UserName : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('UserName', newInputValue)
                                UserData(newInputValue)
                                setUserIDPre(newInputValue)

                            }}
                        />


                    </div>
                    <FormButton
                        id='btnSave'
                        inputrefs={inputrefs}
                        Export={false}
                        handleSubmit={handleSubmit}
                        handleClear={handleClear}

                    />
                </div>
                <>
                    <div className='card-body'>

                        <div className="table-responsive">
                            {formData ?
                                (
                                    <table className="table border">
                                        <thead>
                                            <tr className='light-bg' key={'Header'}>
                                                <th>Folder Name</th>
                                                <th></th>
                                                <th>Page</th>
                                                <th><input type="checkbox" onChange={ViewCheckAll} checked={CheckView} />View</th>
                                                <th><input type="checkbox" onChange={AddCheckAll} checked={CheckAdd} disabled={!CheckView} />Add</th>
                                                <th><input type="checkbox" onChange={EditCheckAll} checked={CheckEdit} disabled={!CheckView} />Edit</th>
                                                <th><input type="checkbox" onChange={DelCheckAll} checked={CheckDel} disabled={!CheckView} />Delete</th>
                                                <th><input type="checkbox" onChange={ExpCheckAll} checked={CheckExp} disabled={!CheckView} />Export</th>
                                                <th><input type="checkbox" onChange={AllCheckAll} checked={CheckAll}
                                                // disabled={!CheckView}
                                                />CheckAll</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (formData)?.map((item, index) => (
                                                    <React.Fragment key={`${item.pageID}Validity`}>
                                                        {MPageID !== item.mPageID && index > 0 ?
                                                            <tr key={`${item.pageID}Validity`} style={{ background: '#fae9b2' }}>
                                                                <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr> : <></>}
                                                        <tr key={`${index}${item.menuName}`}>
                                                            {MPageID !== item.mPageID ?
                                                                <><td rowSpan={item.rowspan}
                                                                    className="text-danger fs-6 fw-bold">
                                                                    {
                                                                        MPageID = item.mPageID,
                                                                        item.folderName === '' ? <></> : item.folderName
                                                                    }
                                                                </td>
                                                                    <td rowSpan={item.rowspan}>
                                                                        <span className="MM">
                                                                            <input
                                                                                name='check'
                                                                                type="checkbox"
                                                                                onClick={(e) => CheckByFolderNameCheckBox(e, index, item.mPageID)}
                                                                            />
                                                                        </span>
                                                                    </td></>
                                                                : <></>}
                                                            <td>{item.menuName}</td>
                                                            <td>
                                                                <span className="MM">
                                                                    <input
                                                                        name='view'
                                                                        type="checkbox"
                                                                        checked={item.view}
                                                                        onChange={() => handleCheckboxChange(index, 'view')}
                                                                    />
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="MM">
                                                                    <input
                                                                        name='add'
                                                                        type="checkbox"
                                                                        checked={item.add}
                                                                        onChange={() => handleCheckboxChange(index, 'add')}
                                                                        disabled={!formData[index].view}
                                                                    />
                                                                </span></td>
                                                            <td>
                                                                <span className="MM">
                                                                    <input
                                                                        name='edit'
                                                                        type="checkbox"
                                                                        checked={item.edit}
                                                                        onChange={() => handleCheckboxChange(index, 'edit')}
                                                                        disabled={!formData[index].view}
                                                                    />
                                                                </span>
                                                            </td>
                                                            <td><span className="MM">
                                                                <input
                                                                    name='del'
                                                                    type="checkbox"
                                                                    checked={item.del}
                                                                    onChange={() => handleCheckboxChange(index, 'del')}
                                                                    disabled={!formData[index].view}
                                                                />
                                                            </span>
                                                            </td>
                                                            <td>
                                                                <span className="MM">
                                                                    <input
                                                                        name='exp'
                                                                        type="checkbox"
                                                                        checked={item.exp}
                                                                        onChange={() => handleCheckboxChange(index, 'exp')}
                                                                        disabled={!formData[index].view}
                                                                    />
                                                                </span>
                                                            </td>
                                                            <td> <span className="MM">
                                                                <input
                                                                    name='all'
                                                                    type="checkbox"
                                                                    checked={item.all}
                                                                    onClick={(e) => RightToLeftCheck(e, index)}
                                                                    onChange={() => handleCheckboxChange(index, 'all')}
                                                                   // disabled={!formData[index].view}
                                                                />
                                                            </span>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                ) : (<></>)}
                        </div>

                    </div>

                </>
            </div>

            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}
        </>
    )
}


export default UserMenuAccess