import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../context/auth';
import * as XLSX from 'xlsx';
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { Token, currentMonth, currentYear } from '../Common/Common';
import * as imp from '../commoncomponents/Import';
import { MonthBox, YearBox } from '../commoncomponents/InputBox';

function ImportAttendance() {
    const { user, sessionExpired } = useAuth();
    const inputrefs = useRef([]);
    //token Data
    //const TokenData = {
    //    AuthToken: process.env.REACT_APP_SECRET_KEY,
    //    LoginToken: user.loginsessionID,
    //    UserID: user.userID,
    //    CompanyID: user.companyID
    //}
    const TokenData = Token()
    //initial state 
    const [errorCount, setErrorCount] = useState('');;
    const [errorData, setErrorData] = useState(0);
    const [loading, setLoading] = useState(false);
    const [Field, setField] = useState('');
    const [fileData, setFileData] = useState(null);
    const requestData = { ...TokenData, PageName: "ImportAttendance" }
    const [fieldValue, setFieldValue] = useState('')
    const [close, setClose] = useState(false)
    const [errors, setErrors] = useState({});

    const [recipe, setRecipe] = useState({
        month: "",
        year: ''
    })

    const HandleChange = (prop, value) => {
        setRecipe({ ...recipe, [prop]: value })
    }

    useEffect(() => {
        document.title = 'Pay Plus : Import Attendance';
        ImportField();
        imp.GetFieldValue(requestData, setFieldValue) // for field value with * mark
    }, []);


    //GetImportField Handler
    const ImportField = () => {
        setLoading(true)
        post(`${API_SERVER}api/Import/ImportFormat`, requestData, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.status === "EXPIRED") {
                    toast.error(res.message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setField(res.fieldName);
                }
            }
        });
    }
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "month", msg: 'Please Select month.' },
            { field: "year", msg: 'Please Select Year.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        return isValid;
    }

    const handleUpload = () => {
        if (Validate()) {
            setLoading(true)
            if (fileData == null) {
                toast.error("Please Upload File.!")
            }
            else {
                const formData = new FormData();
                formData.append('file', fileData);
                formData.append('FieldName', Field);
                formData.append('Month', currentMonth(recipe.month, 'long'));
                formData.append('Year', currentYear(recipe.year));
                formData.append('AuthToken', process.env.REACT_APP_SECRET_KEY);
                formData.append('LoginToken', user.loginsessionID);
                formData.append('UserID', user.userID);
                formData.append('CompanyID', user.companyID);
                setLoading(true)
                post(`${API_SERVER}api/Import/ImportAttendance`, formData, (res) => {
                    handleClear();
                    //if (res.Status === "ERROR") {
                    //    //toast.error(res.Message);
                    //    setErrorData(res);
                    //    setErrorCount(res);
                    //    setClose(true);
                    //} else
                    if (res.Status === "EXPIRED") {
                        toast.error(res.Message);
                        sessionExpired();
                        setLoading(false);
                    } else if (res.Status === "UNAUTHORIZED") {
                        toast.error(res.Message);
                    } else if (res.Status === "") {
                        toast.error(res.Message);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        if (res.Message === "") {
                            setErrorCount(res);
                            setErrorData(res);
                            setClose(true);
                        } else {
                            toast.error(res.Message);
                        }
                        //  toast.success(res.Message);
                        /* setLoading(false);*/
                        //setErrorCount(res);
                        //setErrorData(res);
                        //setClose(true);
                    }
                })
            }
        }
    }

    // Clear File Input 
    const handleClear = () => {
        setFileData(null)
    }


    //handleDownloadFormat Handler
    const handleDownloadErrorData = () => {
        imp.ExportError("ImportAttendanceError", errorData);
    }

    //handleDownloadFormat Handler
    const handleDownloadFormat = () => {
        imp.handleDownloadExcel(requestData, 'ImportAttendanceFormat')
    }
    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Import Attendance</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className={`col-md-4 mb-1 row `} key="txtStatus">

                            <MonthBox
                                inputrefs={inputrefs}
                                // onKeyDown={(e) => {
                                //  if (e.key === 'Enter') { inputrefs.current['txtYear'].focus() };
                                //}}
                                label='Month'
                                // labelClass='text-nowrap'
                                //customclass='w-25'
                                divclassname='col-md-6'
                                id='txtMonth'
                                selected={recipe.month ? recipe.month : ""}
                                placeholder="Month"
                                onChange={(e) => { HandleChange('month', e) }}
                                required
                                error={errors.month}
                            />
                            <YearBox
                                inputrefs={inputrefs}
                                //  onKeyDown={(e) => {
                                //       if (e.key === 'Enter') { handleSubmit() };
                                //    }}
                                label='Year'
                                // labelClass='text-nowrap'
                                divclassname='col-md-6'
                                id='txtYear'
                                selected={recipe.year ? recipe.year : ""}
                                placeholder="Year"
                                onChange={(e) => { HandleChange('year', e) }}
                                required
                                error={errors.year}
                            />



                        </div>

                        <div className='row col-md-6'>
                            <b>Note : </b>
                            <label>1. Please Upload Only Excel File of Format .xlsx.</label>
                            <label>2. Below Given Fields and Excel Header Should be Same.</label>
                            <label>3. DOL Should Be In DD-MMM-YYYY Format (i.e. 01-Jan-2019) In Excel Sheet.</label>
                            <br />
                        </div>
                    </div>
                    <div className='row'>
                        <fieldset className="myfieldset">
                            <legend className="mylegend"><h6 className="m-0">Excel File</h6></legend>
                            <div className='row'>
                                <div className={`col-md-3 mb-1`} key="fileDocument">
                                    <input
                                        className="form-control form-control-sm"
                                        type="file"
                                        id="fileDocument"
                                        name="DocumentFrontSide"
                                        maxLength="50"
                                        tabIndex="1"
                                        onBlur={(e) => {
                                            setClose(false)
                                            if (e.target.files && e.target.files[0]) {
                                                let fu = e.target.files[0];
                                                let extArr = (fu.name).split(".");
                                                if (extArr[1] !== "xls" && extArr[1] !== "xlsx") {
                                                    toast.error("Please Upload only excel file.!");
                                                    // console.log(e.target.files, 'blur if')
                                                    return
                                                }
                                                else {
                                                    setFileData(e.target.files[0]);
                                                    //  console.log(e.target.files, 'blur else')
                                                    return
                                                }
                                            }
                                        }}
                                        required
                                        onChange={(e) => {
                                            setClose(false)
                                            if (e.target.files && e.target.files[0]) {
                                                let fu = e.target.files[0];
                                                let extArr = (fu.name).split(".");
                                                if (extArr[1] !== "xls" && extArr[1] !== "xlsx") {
                                                    toast.error("Please Upload only excel file.!");
                                                    //   console.log(e.target.files, 'if')
                                                    return
                                                }
                                                else {
                                                    setFileData(e.target.files[0]);
                                                    console.log(e.target.files, 'else')
                                                    return
                                                }
                                            }
                                            // console.log(e, 'all')
                                        }}
                                    />
                                </div>




                                <div className={`col-lg-9 mb-1`} >
                                    <button type="button" name='btnUpload'
                                        onClick={handleUpload}
                                        className="btn btn-rounded btn-success col-md-1">
                                        <span className=" text-white me-2"><i className='fa fa-upload text-white color-success'></i></span>Upload
                                    </button>
                                    <button type="button" name='btnDownloadFormat'
                                        onClick={handleDownloadFormat}
                                        className="btn btn-rounded btn-primary">
                                        <span className=" text-white me-2"><i className='fa fa-download text-white color-success'></i></span>Download Format
                                    </button>
                                </div>
                            </div>
                            <div className='row m-0'>
                                <textarea className="form-control"
                                    disabled
                                    defaultValue={Field}
                                    id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                        </fieldset>
                        {close && <div className='row '>
                            <div className=' m-0 ms-2 border rounded d-flex justify-content-between ' style={{ background: '#95d295b0' }}>
                                <div className='mx-0 my-2'>
                                    <pre className="m-0 p-0 fw-bold"><span className='text-success ' > <i className='fa fa-check'></i> Total Data : <span className=''>{errorCount.TotalData}</span></span>      <span className='text-success' >Total Merge Data : <span className=''>{errorCount.TotalMergeData}</span></span>      <span className='text-danger' > <i className='fa fa-question'></i> Total Exception : <span className='' onClick={errorCount?.TotalException === '' && null && undefined && '0' ? "" : handleDownloadErrorData} style={{ cursor: "pointer" }}>{errorCount.TotalException}</span></span></pre>
                                </div>
                                <button onClick={() => setClose(false)} type='button' className='btn btn-outline-danger m-1 min-vw-0 p-1 px-2 rounded' style={{ minWidth: '0' }}><i className='fa fa-close m-0 p-0'></i></button>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ImportAttendance
