import { useRef } from "react";
import { InputBox } from "./InputBox";


const ProfilePicture = (props) => {
    const {
        MainDivClassName = 'mb-1 mt-4',
        textClass = ' mb-1 row justify-content-center text-center',
        text = 'Refresh for New Entry',
        isText = false,
        textStyle = { cursor: 'pointer' },
        imgDivClass = 'row justify-content-center',
        imgDivStyle = { },
        imgSrc = 'http://in.payplushrms.com/ImageHandler.ashx?ID=10367&Type=Profile',
        imageAlt = 'Profile',
        imageClass = 'border p-0',
        imgStyle = { height: '120px', width: '100px', cursor: 'pointer' },
        HandleNewEntry, openModal
    } = props;



    return (
        <div className={MainDivClassName}>
            {isText && <p className={textClass} onClick={HandleNewEntry} style={textStyle}>{text}</p>}
            <div className={imgDivClass} style={imgDivStyle} onClick={openModal } >
                <img src={imgSrc} alt={imageAlt} className={imageClass} style={imgStyle} />
            </div>


        </div>
    )
}

export default ProfilePicture;