/// <reference path="employeemaster.jsx" />
import { useEffect, useRef, useState } from "react";
import { API_SERVER, initializeFilterData } from "../../Utils";
import { getCommonData, getMasterData } from "../../apiservices/CommonServices";
import { useAuth } from "../../context/auth";
import { dateFormate, number, numberwithDot } from "../Common/Common";
import { AutoCompleteBox, CheckBoxInput, DateBox, FormButton, InputBox, SelectInputBox, TimeBox } from "../commoncomponents/InputBox";
//react toast 
import { toast } from 'react-toastify';
import { post } from "../../apiservices/service";


const EmploymenteDetails = ({ recipe,
    setRecipe,
    handleTabClick,
    dateOfJoining,
    setDateOfJoining,
    inTime,
    setInTime,
    outTime,
    setOutTime,
    dateOfConfirmation,
    setDateOfConfirmation,
    dateOfAccountUpdate,
    setDateOfAccountUpdate,
    employee,
    setEmployee,
    WeeklyOffDetailsList,
    setWeeklyOffDetailsList,
    setActiveTab,
    activeTab,
    EmployeeInEditTime,
    EmployeeCodeByUrl,
    TokenData, sessionExpired,
    setErrorFocus,
}) => {
    const { user } = useAuth();
    const inputrefs = useRef([]);
    const [errors, setErrors] = useState({});
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [departmentAllData, setDepartmentAllData] = useState([])
    const [branchAllData, setBranchAllData] = useState([])
    const [employeeBankAllData, setEmployeeBankAllData] = useState([])
    const [approvalAuthorityAllData, setApprovalAuthorityAllData] = useState([])
    const [designationAllData, setDesignationAllData] = useState([])
    const [employeeTypeAllData, setEmployeeTypeAllData] = useState([])
    const [shiftData, setShiftData] = useState([])
    const [paymentModeAllData, setPaymentModeAllData] = useState([])
    
    const [isUpdate, setIsUpdate] = useState(false)
    const [dateOfBirth, setDateOfBirth] = useState();
    const [isEdit, setIsEdit] = useState(false)
    const [editIndex, setEditIndex] = useState('')
    const [type, setType] = useState([])
    const [WeeklyOffDetailsForm, setWeeklyOffDetailsForm] = useState({
        "weeklyOffDay": "",
        "typeOfDay": "",
    })

    const [weekDayType, setWeekDayType] = useState({
        All: false,
        First: false,
        Second: false,
        Third: false,
        Fourth: false,
        Last: false,
    })

    const HandleChangeWeekDayType = (prop, value) => {
        if (value === true) {
            setType((prevType) => [...prevType, prop])
        } else {
            setType((prevType) => prevType?.filter((val) => val !== prop))
        }
        setWeekDayType((prevWeekDayType) => ({ ...prevWeekDayType, [prop]: value }))
        if (prop === 'weeklyOffDay') {
            setWeeklyOffDetailsForm({
                ...WeeklyOffDetailsForm,
                [prop]: value
            })
        }
    }

    useEffect(() => {
            setWeeklyOffDetailsForm({ ...WeeklyOffDetailsForm, typeOfDay: type.join(',') });
    }, [type]);


    const getDepartmentData = (value) => {
        let temp = {
            Type: "GetCompanyWiseDepartment",
            Prefix: value ? value : '',
            ContextKey: recipe.Company ? recipe.Company : '',
            ContextKey2: ""
        }
        //     setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setDepartmentAllData([])
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setDepartmentAllData(data)
                }
            }
        });
    }

    const getBranchData = (value) => {
        let temp = {
            Type: "GetCompanyWiseBranch",
            Prefix: value ? value : '',
            ContextKey: recipe.Company ? recipe.Company : '',
            ContextKey2: ""
        }
        //  setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setBranchAllData([])
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setBranchAllData(data)
                }
            }
        });
    }

    const getEmployeeBankData = (value) => {
        let temp = {
            Type: "GetBankName",
            Prefix: value ? value : '',
            ContextKey: "",
            ContextKey2: ""
        }
        //   setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setEmployeeBankAllData(data)
                    // console.log(res, 'banks name',employeeBankAllData)
                }
            }
        });
    }

    const GetIFSCPrefix = (value) => {
        post(`${API_SERVER}api/AutoComplete/GetIFSCPrefix`, { ...TokenData, BankCode: value, EmployeeBank: value }, (res) => {
            if (res.status === 'SUCCESS') {
                //  setIFSCPrefix(res.ifscPrefix);
                setRecipe({ ...recipe, IFSCCode: res.ifscPrefix, EmployeeBank: value })
            }
            else if (res.status === 'ERROR') {
                //   setIFSCPrefix('')
                setRecipe({ ...recipe, IFSCCode: '', EmployeeBank: value })
            } else if (res.Status === "EXPIRED") {
                toast.error(res.Message)
                sessionExpired()
            }
        })
    }

    // Get Approval Authority Data
    const getApprovalAuthorityData = (value) => {
        let temp = {
            Type: "GetCompanyWiseApprovalEmployee",
            Prefix: value ? value : '',
            ContextKey: EmployeeCodeByUrl ? EmployeeCodeByUrl : "",
            ContextKey2: recipe.Company ? recipe.Company : '',
        }
        //  setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setApprovalAuthorityAllData(data)
                }
            }
        });
    }

    const getDesignationData = (value) => {
        let temp = {
            Type: "GetCompanyWiseDesignation",
            Prefix: value ? value : '',
            ContextKey: recipe.Company ? recipe.Company : '',
            ContextKey2: ""
        }
        //  setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setDesignationAllData([])
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setDesignationAllData(data)
                }
            }
        });
    }

    const getEmployeeTypeData = () => {
        setEmployeeTypeAllData([])
        let temp = {
            Type: "GetEmployeeType",
            Prefix: '',
        }
        //   setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setEmployeeTypeAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setEmployeeTypeAllData(data)
                }
            }
        });
    }

    //Get Shift Data 
    const GetShiftData = () => {
        setShiftData([])
        let temp = {
            Type: "GetShift",
            Prefix: '',
        }
        //     setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setShiftData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setShiftData(data)
                }
            }
        });
    }

    const getPaymentModeData = () => {
        setPaymentModeAllData([])
        let temp = {
            Type: "GetPaymentMode",
            Prefix: '',
        }
        //   setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setPaymentModeAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setPaymentModeAllData(data)
                }
            }
        });
    }

    // Check Duplicate Data for Weekly Off
    const checkDuplicatesDataForWeeklyOff = (objectToCheck, arr) => {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].weeklyOffDay.toLowerCase() === objectToCheck.weeklyOffDay.toLowerCase() &&
                arr[i].typeOfDay.toLowerCase() === objectToCheck.typeOfDay.toLowerCase()) {
            //    console.log(arr[i].typeOfDay.toLowerCase() === objectToCheck.typeOfDay.toLowerCase())
                return true;
            }

        }
        return false;
    }

    //handle Add WeeklyOffDetails
    const handleAddWeeklyOffDetailss = () => {
        if (WeeklyOffDetailsForm.weeklyOffDay === '') {
            toast.error('Please fill Weekly Off Day')
            inputrefs.current['txtWeeklyOffDay'].focus();
        } else if (WeeklyOffDetailsForm.typeOfDay === '') {
            toast.error('Please fill Type Of Day')
        } else if (WeeklyOffDetailsForm.weeklyOffDay && WeeklyOffDetailsForm.typeOfDay) {
            if (checkDuplicatesDataForWeeklyOff(WeeklyOffDetailsForm, WeeklyOffDetailsList) === true) {
                toast.error('Please Enter Unique Weekly off')
            } else {
                setWeeklyOffDetailsList([...WeeklyOffDetailsList, WeeklyOffDetailsForm])
                handleClearWeeklyOffDetailsInputs();
            }
        }
    }

    // Handle Delete WeeklyOffDetails
    const HandleWeeklyOffDetailsDelete = (index) => {
        const filerWeeklyOffDetails = WeeklyOffDetailsList.filter((ele, i) => i !== index);
        setWeeklyOffDetailsList(filerWeeklyOffDetails);
    }

    // Handle Reset WeeklyOffDetails Inputs
    const handleClearWeeklyOffDetailsInputs = () => {
        setWeeklyOffDetailsForm({
            ...WeeklyOffDetailsForm,
            typeOfDay: "",
            weeklyOffDay: ""
        });
        setWeekDayType({
            ...weekDayType,
            All: false,
            First: false,
            Second: false,
            Third: false,
            Fourth: false,
            Last: false,
        });
        setType([]);
    }

    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "Branch", msg: 'Please select Branch.' },
            { field: "Department", msg: "Please select Department." },
            { field: "Designation", msg: "Please select Designation." },
            { field: "DOJ", msg: 'Please Enter Date of Joining.' },
            { field: "NoticePeriodDays", msg: 'Please Enter Notice Period.' },
            { field: "PaymentMode", msg: 'Please Enter Payment Mode.' },
        ];

        if (recipe['PaymentMode'] !== '3' && recipe['PaymentMode'] !== '2') {
            validation.push(
                { field: "EmployeeBank", msg: 'Please Enter Employee Bank.' },
                { field: "AccountNo", msg: 'Please Enter Account No.' },
                { field: "NameinBankAccount", msg: 'Please Enter Name in Bank Account.' },
                { field: "IFSCCode", msg: 'Please Enter IFSC Code.' },
                { field: "AccountUpdateDate", msg: 'Please Enter Account Update Date.' },
            )
        };

        if (recipe['PFApplicable'] === true) {
            if (recipe['OnCrossingLimitDeductPFon'] === '3') {
                validation.push(
                    { field: "CeilAmount", msg: 'Please Enter Ceil Amount.' },
                )
            };

            if (recipe['VolPF'] === true) {
                validation.push(
                    { field: "VolPFType", msg: 'Please select Vol PF Type.' },
                    { field: "EmprVolPF", msg: 'Please Enter Empr Vol PF.' },
                    { field: "EmpVolPF", msg: 'Please Enter Emp Vol PF.' },
                )
            };
        }

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        return isValid;
    }


    const HandleChange = (prop, value) => {
        if (prop === 'DOJ') {
            setDateOfJoining(value)
            //  setRecipe({ ...recipe, DOJ: value.toLocaleDateString() })
        } else if (prop === 'InTime') {
            setInTime(value)
        } else if (prop === 'OutTime') {
            setOutTime(value)
        } else if (prop === 'ConfirmationDate') {
            setDateOfConfirmation(value)
        } else if (prop === 'AccountUpdateDate') {
            setDateOfAccountUpdate(value)
        } else if (prop === 'TICNo') {
            setRecipe({ ...recipe, ESINo: value, TICNo: value })
        } else if (prop === 'PFApplicable') {
            if (value === false) {
                setRecipe({
                    ...recipe,
                    PFApplicable: value,
                    PFNo: "",
                    UANNo: "",
                    OnCrossingLimitDeductPFon: "0",
                    CeilAmount: "",
                    VolPF: false,
                    EmprVolPF: '',
                    EmpVolPF: '',
                    VolPFType: ''
                })
            }
        } else if (prop === "ESIApplicable") {
            if (value === false) {
                setRecipe({
                    ...recipe,
                    ESIApplicable: value,
                    TICNo: "",
                    TICStatus: "Pending",
                    Dispensary: "",
                    SmartCard: "",
                    ESINo: "",
                    TICMode: "",
                })
            }
        } else if (prop === 'PaymentMode') {
            if (value === '3' || value === "" || value === '2') {
                setRecipe({
                    ...recipe,
                    EmployeeBank: "",
                    AccountNo: '',
                    NameinBankAccount: "",
                    IFSCCode: "",
                    AccountUpdateDate: ""
                })
            }
        }
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //Submit Handler
    const handleNext = () => {
        //const myString = Validate();
        //if (myString === "") {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/Employee/InsertUpdateEmployeePersonalDatail`, { ...TokenData, ...recipe, WeeklyOffDetailsList: WeeklyOffDetailsList, EmpID: employee ? employee?.empID : recipe.EmpID }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        //handleTabClick(res.tab)
                        setActiveTab(res.tab)
                        toast.error(res.message);
                        setErrorFocus(res.focus);
                        inputrefs.current[res.focus].focus();
                        setLoading(false)
                    } else if (res.status === "EXPIRED") {
                        toast.error(res.message)
                        sessionExpired()
                    } else if (res.status === "UNAUTHORIZED") {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        setEmployee(res)
                        setRecipe({ ...recipe, EmpID: res.empID, EmpCode: res.employee })
                        handleTabClick('OtherDetails')
                        setActiveTab('OtherDetails');
                        setErrorFocus('')
                    }
                }
            });

        }
    }

  //  console.log(employee,'11111')

    let DepartmentData = []
    for (let i = 0; i < departmentAllData.length; i++) {
        let mydata = departmentAllData[i]?.values
        DepartmentData.push(mydata)
    };

    let DesignationData = []
    for (let i = 0; i < designationAllData.length; i++) {
        let mydata = designationAllData[i]?.values
        DesignationData.push(mydata)
    };

    let BranchData = []
    for (let i = 0; i < branchAllData.length; i++) {
        let mydata = branchAllData[i]?.values
        BranchData.push(mydata)
    };

    let EmployeeBankData = []
    for (let i = 0; i < employeeBankAllData.length; i++) {
        let mydata = employeeBankAllData[i]?.values
        EmployeeBankData.push(mydata)
    };

    let ApprovalAuthorityData = []
    for (let i = 0; i < approvalAuthorityAllData.length; i++) {
        let mydata = approvalAuthorityAllData[i]?.values
        ApprovalAuthorityData.push(mydata)
    };

    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe, EmplID: '0',
            Branch: '',
            Shift: "",
            Department: '',
            Designation: '',
            OldEmpCode: '',
            DOJ: '',
            InTime: '',
            OutTime: '',
            ContractPeriod: '',
            ContractDays: '',
            NoticePeriodDays: '',
            NoticePeriod: '',
            ConfirmationDate: '',
            ApprovalAuthority1: '',
            ApprovalAuthority2: '',
            ApprovalAuthority3: '',
            OfficialEmailID: '',
            EmployeeType: '',
            PANNo: '',
            SalaryType: '',
            WeeklyOffDetailsList: [],
            LeaveEncashmentApplicable: false,
            BonusApplicable: false,
            PFApplicable: false,
            ESIApplicable: false,
            PFNo: '',
            CardNo: '',
            UANNo: '',
            OnCrossingLimitDeductPFon: '',
            CeilAmount: '',
            VolPF: false,
            EmprVolPF: '',
            EmpVolPF: '',
            VolPFType: '',
            TICNo: '',
            TICStatus: '',
            Dispensary: '',
            SmartCard: '',
            ESINo: '',
            TICMode: '',
            PaymentMode: '',
            EmployeeBank: '',
            AccountNo: '',
            NameinBankAccount: '',
            IFSCCode: '',
            AccountUpdateDate: '',
            Remarks: '',


        }))

        setErrors({})
    }

    useEffect(() => {
        if (activeTab === 'EmploymenteDetails') {
            inputrefs.current['txtBranch'].focus()
            document.title = 'Pay Plus: Employment Details'
            getDepartmentData()
            getBranchData()
            getApprovalAuthorityData()
            getDesignationData()
            getEmployeeTypeData()
            getPaymentModeData()
            getEmployeeBankData()
            GetShiftData()
        }
    }, [activeTab])

  
    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Employement Details</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>

                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtDepartment'].focus() };
                            }}
                            label='Branch'
                            placeholder="Branch"
                            maxLength='50'
                            options={BranchData}
                            id='txtBranch'
                            required={true}
                            error={errors.Branch}
                            disabled={recipe.IsPayslipGenerated}
                            inputValue={recipe.Branch ? recipe.Branch : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('Branch', newInputValue)
                                getBranchData(newInputValue)
                                //setStatePre(newInputValue)
                            }}
                        />
                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtDesignation'].focus() };
                            }}
                            disabled={recipe.IsPayslipGenerated}
                            label='Department'
                            placeholder="Department"
                            maxLength='50'
                            options={DepartmentData}
                            id='txtDepartment'
                            required={true}
                            error={errors.Department}
                            inputValue={recipe.Department ? recipe.Department : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('Department', newInputValue)
                                getDepartmentData(newInputValue)
                                //setStatePre(newInputValue)
                            }}
                        />
                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtOldEmpCode'].focus() };
                            }}
                            disabled={recipe.IsPayslipGenerated}
                            label='Designation'
                            placeholder="Designation"
                            maxLength='50'
                            options={DesignationData}
                            id='txtDesignation'
                            required={true}
                            error={errors.Designation}
                            inputValue={recipe.Designation ? recipe.Designation : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('Designation', newInputValue)
                                getDesignationData(newInputValue)
                                //setStatePre(newInputValue)
                            }}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtDOJ'].focus() };
                            }}
                            label={`Old Emp Code`}
                            id='txtOldEmpCode'
                            maxLength='20'
                            placeholder={`Old Emp Code`}
                            value={recipe.OldEmpCode}
                            onChange={(e) => { HandleChange('OldEmpCode', number(e.target.value)) }}
                            // required
                            error={errors.OldEmpCode}
                        />
                        {/*<div className='cntrl-width col-md-3 d-flex justify-content-between mb-1 gap-1 ' >*/}
                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtInTime'].focus() };
                            }}
                            label='Date of Joining'
                            // labelClass='text-nowrap'
                            //divclassname='col-lg-6'
                            id='txtDOJ'
                            selected={recipe.DOJ === '' ? '' : new Date(recipe.DOJ)}
                            placeholder="Date of Joining"
                            onChange={(e) => HandleChange('DOJ', dateFormate(e))}
                            required
                            disabled={EmployeeCodeByUrl ||employee }
                            error={errors.DOJ}
                        />
                        {/*<DateBox*/}
                        {/*    inputrefs={inputrefs}*/}
                        {/*    onKeyDown={(e) => {*/}
                        {/*        if (e.key === 'Enter') { inputrefs.current['txtInTime'].focus() };*/}
                        {/*    }}*/}
                        {/*    label='Confirmation Date'*/}
                        {/*    labelClass='text-nowrap'*/}
                        {/*    divclassname='col-lg-6  '*/}
                        {/*    id='txtConfirmationDate'*/}
                        {/*    selected={recipe.ConfirmationDate === '' ? '' : new Date(recipe.ConfirmationDate)}*/}
                        {/*    placeholder="Confirmation Date"*/}
                        {/*    //value={recipe.ConfirmationDate}*/}
                        {/*    onChange={(e) => HandleChange('ConfirmationDate', dateFormate(e))}*/}
                        {/*    required*/}
                        {/*    error={errors.ConfirmationDate}*/}
                        {/*/>*/}
                        {/*</div>*/}
                        <div className='cntrl-width col-md-3 d-flex justify-content-between mb-1' >
                            <TimeBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtOutTime'].focus() };
                                }}
                                labelClass='text-nowrap'
                                divclassname='col-lg-5 '
                                label='In Time'
                                id='txtInTime'
                                selected={inTime}
                                placeholder="In Time"
                                value={recipe.InTime}
                                onChange={(e) => HandleChange('InTime', e.target.value)}
                                // required
                                error={errors.inTime}
                            />

                            <TimeBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtContractDays'].focus() };
                                }}
                                labelClass='text-nowrap'
                                divclassname='col-lg-5'
                                label='Out Time'
                                id='txtOutTime'
                                selected={outTime}
                                placeholder="Out Time"
                                value={recipe.OutTime}
                                onChange={(e) => HandleChange('OutTime', e.target.value)}
                                //  required
                                error={errors.OutTime}
                            />
                        </div>

                        <div className={`col-md-3 mb-1 cntrl-width `} >
                            <label className="form-label" style={{ textWrap: 'nowrap' }}>Probation Period{/*<span style={{ color: "red" }}> *</span>*/}</label>
                            <div className='d-flex'>
                                <InputBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtContractPeriod'].focus() };
                                    }}
                                    label=''
                                    divclassname='m-0 me-2'
                                    id='txtContractDays'
                                    maxLength='5'
                                    placeholder='Probation'
                                    value={recipe.ContractDays}
                                    onChange={(e) => { HandleChange('ContractDays', number(e.target.value)) }}
                                    onKeyUp={(e) => {
                                        if (recipe.ContractPeriod === 'Month') {
                                            if (e.target.value > 12) { HandleChange('ContractDays', '') };
                                        } else {
                                            if (e.target.value > 365) { HandleChange('ContractDays', '') };
                                        }
                                    }}
                                //required
                                //error={errors.ContractDays}
                                />
                                {/*<div>*/}
                                {/*    <select*/}
                                {/*        ref={ref => (inputrefs.current['txtContractPeriod'] = ref)}*/}
                                {/*        inputrefs={inputrefs}*/}
                                {/*        className="form-control"*/}
                                {/*        id="txtContractPeriod"*/}
                                {/*        value={recipe.ContractPeriod}*/}
                                {/*        //error={errors.ContractPeriod}*/}
                                {/*        onKeyDown={(e) => {*/}
                                {/*            if (e.key === 'Enter') { inputrefs.current['txtNoticePeriodDays'].focus() };*/}
                                {/*        }}*/}

                                {/*        onChange={(e) => HandleChange('ContractPeriod', e.target.value)}>*/}

                                {/*        <option value="Month">Month</option>*/}
                                {/*        <option value="Days">Days</option>*/}

                                {/*    </select>*/}
                                {/*    */}{/*<div className="error-msg">{errors.ContractPeriod}</div>*/}
                                {/*</div>*/}

                                <SelectInputBox
                                    // label='Blood Group'
                                    divclassname=''
                                    //inputClassName=''
                                    id="txtContractPeriod"
                                    value={recipe.ContractPeriod}
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtNoticePeriodDays'].focus() };
                                    }}

                                    onChange={(e) => HandleChange('ContractPeriod', e.target.value)}
                                    children={<>
                                        <option value="Month">Month</option>
                                        <option value="Days">Days</option>
                                    </>}
                                />


                            </div>
                        </div>

                        {/*<InputBox*/}
                        {/*    inputrefs={inputrefs}*/}
                        {/*    onKeyDown={(e) => {*/}
                        {/*        if (e.key === 'Enter') { inputrefs.current['txtApprovalAuthority1'].focus() };*/}
                        {/*    }}*/}
                        {/*    label={`Notice Period (Days)`}*/}
                        {/*    placeholder={`Notice Period (Days)`}*/}
                        {/*    id='txtNoticePeriodDays'*/}
                        {/*    maxLength='2'*/}
                        {/*    value={recipe.NoticePeriodDays}*/}
                        {/*    onChange={(e) => { HandleChange('NoticePeriodDays', number(e.target.value)) }}*/}
                        {/*    required*/}
                        {/*    error={errors.NoticePeriodDays}*/}
                        {/*/>*/}
                        <div className={`col-md-3 mb-1 cntrl-width `} >
                            <label className="form-label" style={{ textWrap: 'nowrap' }}>{`Notice Period`}<span style={{ color: "red" }}> *</span></label>
                            <div className='d-flex'>
                                <InputBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtNoticePeriod'].focus() };
                                    }}
                                    label=''
                                    divclassname='m-0 me-2'
                                    id='txtNoticePeriodDays'
                                    maxLength='5'
                                    placeholder='Notice Period'
                                    value={recipe.NoticePeriodDays}
                                    onChange={(e) => { HandleChange('NoticePeriodDays', number(e.target.value)) }}
                                    onKeyUp={(e) => {
                                        if (recipe.NoticePeriod === 'Month') {
                                            if (e.target.value > 12) { HandleChange('NoticePeriodDays', '') };
                                        } else {
                                            if (e.target.value > 365) { HandleChange('NoticePeriodDays', '') };
                                        }
                                    }}
                                    // required
                                    error={errors.NoticePeriodDays}
                                />
                                {/*<div>*/}
                                {/*    <select*/}
                                {/*        ref={ref => (inputrefs.current['txtNoticePeriod'] = ref)}*/}
                                {/*        inputrefs={inputrefs}*/}
                                {/*        className="form-control"*/}
                                {/*        id="txtNoticePeriod"*/}
                                {/*        value={recipe.NoticePeriod}*/}
                                {/*        //error={errors.NoticePeriod}*/}
                                {/*        onKeyDown={(e) => {*/}
                                {/*            if (e.key === 'Enter') { inputrefs.current['txtApprovalAuthority1'].focus() };*/}
                                {/*        }}*/}

                                {/*        onChange={(e) => HandleChange('NoticePeriod', e.target.value)}>*/}

                                {/*        <option value="Month">Month</option>*/}
                                {/*        <option value="Days">Days</option>*/}

                                {/*    </select>*/}
                                {/*    <div className="error-msg">{errors.NoticePeriod}</div>*/}
                                {/*</div>*/}


                                <SelectInputBox
                                    // label='Blood Group'
                                    divclassname=''
                                    //inputClassName=''
                                    error={errors.NoticePeriod}
                                    id="txtNoticePeriod"
                                    value={recipe.NoticePeriod}
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtApprovalAuthority1'].focus() };
                                    }}
                                    onChange={(e) => HandleChange('NoticePeriod', e.target.value)}
                                    children={<>
                                        <option value="Month">Month</option>
                                        <option value="Days">Days</option>
                                    </>}
                                />

                            </div>
                        </div>

                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtApprovalAuthority2'].focus() };
                            }}
                            label='Approval Authority1'
                            placeholder="Approval Authority1"
                            maxLength='50'
                            options={ApprovalAuthorityData}
                            id='txtApprovalAuthority1'
                            //   required={true}
                            error={errors.ApprovalAuthority1}
                            inputValue={recipe.ApprovalAuthority1 ? recipe.ApprovalAuthority1 : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('ApprovalAuthority1', newInputValue)
                                getApprovalAuthorityData(newInputValue)
                                //setStatePre(newInputValue)
                            }}
                        />
                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtApprovalAuthority3'].focus() };
                            }}
                            label='Approval Authority2'
                            placeholder="Approval Authority2"
                            maxLength='50'
                            options={ApprovalAuthorityData}
                            id='txtApprovalAuthority2'
                            // required={true}
                            error={errors.ApprovalAuthority2}
                            inputValue={recipe.ApprovalAuthority2 ? recipe.ApprovalAuthority2 : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('ApprovalAuthority2', newInputValue)
                                getApprovalAuthorityData(newInputValue)
                                //setStatePre(newInputValue)
                            }}
                        />
                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtOfficialEmailID'].focus() };
                            }}
                            label='Approval Authority3'
                            placeholder="Approval Authority3"
                            maxLength='50'
                            options={ApprovalAuthorityData}
                            id='txtApprovalAuthority3'
                            //  required={true}
                            error={errors.ApprovalAuthority3}
                            inputValue={recipe.ApprovalAuthority3 ? recipe.ApprovalAuthority3 : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('ApprovalAuthority3', newInputValue)
                                getApprovalAuthorityData(newInputValue)
                                //setStatePre(newInputValue)
                            }}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtEmployeeType'].focus() };
                            }}
                            label='Official Email ID'
                            id='txtOfficialEmailID'
                            maxLength='50'
                            placeholder="Official Email ID"
                            error={errors.OfficialEmailID}
                            value={recipe.OfficialEmailID}
                            onChange={(e) => { HandleChange('OfficialEmailID', (e.target.value)) }}
                        />

                        {/*<div className={`col-md-3 mb-1 cntrl-width`} key="txtEmployeeType">*/}
                        {/*    <label className="form-label">Employee Type*/}
                        {/*    </label>*/}
                        {/*    <select*/}
                        {/*        ref={ref => (inputrefs.current['txtEmployeeType'] = ref)}*/}
                        {/*        className="form-control"*/}
                        {/*        id="txtEmployeeType"*/}
                        {/*        value={recipe.EmployeeType}*/}
                        {/*        error={errors.EmployeeType}*/}
                        {/*        onKeyDown={(e) => {*/}
                        {/*            if (e.key === 'Enter') { inputrefs.current['txtPANNo'].focus() };*/}
                        {/*        }}*/}
                        {/*        onChange={(e) => HandleChange('EmployeeType', e.target.value)}>*/}
                        {/*        <option value="">--Select Employee Type--</option>*/}
                        {/*        {employeeTypeAllData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}*/}
                        {/*    </select>*/}
                        {/*    <div className="error-msg">{errors.EmployeeType}</div>*/}

                        {/*</div>*/}

                        <SelectInputBox
                            label='Employee Type'
                            id="txtEmployeeType"
                            value={recipe.EmployeeType}
                            error={errors.EmployeeType}
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtPANNo'].focus() };
                            }}
                            onChange={(e) => HandleChange('EmployeeType', e.target.value)}
                            children={<>
                                <option value="">--Select Employee Type--</option>
                                {employeeTypeAllData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}
                            </>}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtShift'].focus() };
                            }}
                            label='PAN NO.'
                            id='txtPANNo'
                            maxLength='10'
                            placeholder="PAN No."
                            error={errors.PANNo}
                            value={recipe.PANNo}
                            onChange={(e) => { HandleChange('PANNo', (e.target.value)) }}
                        />
                        <SelectInputBox
                            inputrefs={inputrefs}
                            label='Shift'
                            id="txtShift"
                           // required
                            value={recipe.Shift}
                           // error={errors.Shift}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtWeeklyOffDay'].focus()
                                }
                            }}
                            onChange={(e) => HandleChange('Shift', e.target.value)}
                            children={<>
                                <option value="">--Select Shift--</option>
                                {shiftData.map((opt, index) => <option key={index} value={opt.id} > {opt.code} : {opt.name}</option>)}
                            </>}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtWeeklyOffDay'].focus() };
                            }}
                            disabled={EmployeeCodeByUrl || employee}
                            label='Card No'
                            id='txtCardNo'
                            maxLength='10'
                            placeholder="Card No."
                            error={errors.CardNo}
                            value={recipe.CardNo}
                            onChange={(e) => { HandleChange('CardNo', (e.target.value)) }}
                        />

                    </div>
                    <fieldset className="myfieldset "  >
                        <legend className="mylegend"> <h6 className="m-0">Weekly off Details</h6></legend>
                        <div className='row'>
                            <SelectInputBox
                                inputrefs={inputrefs}
                                label='Weekly off'
                                id="txtWeeklyOffDay"
                                value={WeeklyOffDetailsForm.weeklyOffDay}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtAll'].focus() };
                                }}
                                onChange={(e) => HandleChangeWeekDayType('weeklyOffDay', e.target.value)}
                                children={<>
                                    <option value="">--Select Days--</option>
                                    <option value="SUNDAY">SUNDAY</option>
                                    <option value="MONDAY">MONDAY</option>
                                    <option value="TUESDAY">TUESDAY</option>
                                    <option value="WEDNESDAY">WEDNESDAY</option>
                                    <option value="THURSDAY">THURSDAY</option>
                                    <option value="FRIDAY">FRIDAY</option>
                                    <option value="SATURDAY">SATURDAY</option>
                                </>}
                            />
                            <div className='d-flex w-50' style={{ alignItems: 'flex-end' }}>
                                <CheckBoxInput
                                    customclass={'form-check form-switch p-0 m-0'}
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtFirst'].focus() };
                                    }}
                                    checked={weekDayType.All}
                                    value={weekDayType.All}
                                    label='All'
                                    name='All'
                                    id='txtAll'
                                    placeholder="All"
                                    //  value={recipe.Calculate}
                                    onChange={(e) => {
                                        HandleChangeWeekDayType('All', e.target.checked)
                                        setWeekDayType({
                                            ...weekDayType,
                                            All: e.target.checked,
                                            First: e.target.checked,
                                            Second: e.target.checked,
                                            Third: e.target.checked,
                                            Fourth: e.target.checked,
                                            Last: e.target.checked,
                                        })
                                      //  setType('All')
                                    }}
                                />
                                <CheckBoxInput
                                    customclass={'form-check form-switch p-0 m-0'}
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtSecond'].focus() };
                                    }}
                                    disabled={weekDayType.All}
                                    checked={weekDayType.First}
                                    value={weekDayType.First}
                                    label='First'
                                    name='First'
                                    id='txtFirst'
                                    //  value={recipe.Calculate}
                                    onChange={(e) => { HandleChangeWeekDayType('First', e.target.checked) }}


                                /> <CheckBoxInput
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtThird'].focus() };
                                    }}
                                    disabled={weekDayType.All}
                                    customclass={'form-check form-switch p-0 m-0'}
                                    checked={weekDayType.Second}
                                    value={weekDayType.Second}
                                    label='Second'
                                    name='Second'
                                    id='txtSecond'

                                    //  value={recipe.Calculate}
                                    onChange={(e) => { HandleChangeWeekDayType('Second', e.target.checked) }}


                                />
                                <CheckBoxInput
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtFourth'].focus() };
                                    }}
                                    disabled={weekDayType.All}
                                    customclass={'form-check form-switch p-0 m-0'}
                                    checked={weekDayType.Third}
                                    value={weekDayType.Third}
                                    label='Third'
                                    name='Third'
                                    id='txtThird'
                                    //  value={recipe.Calculate}
                                    onChange={(e) => { HandleChangeWeekDayType('Third', e.target.checked) }}

                                />
                                <CheckBoxInput
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtLast'].focus() };
                                    }}
                                    disabled={weekDayType.All}
                                    checked={weekDayType.Fourth}
                                    value={weekDayType.Fourth}
                                    customclass={'form-check form-switch p-0 m-0'}
                                    label='Fourth'
                                    name='Fourth'
                                    id='txtFourth'
                                    //  value={recipe.Calculate}
                                    onChange={(e) => { HandleChangeWeekDayType('Fourth', e.target.checked) }}

                                />
                                <CheckBoxInput
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['btnHandleAddWeeklyOffDetailss'].focus() };
                                    }}
                                    disabled={weekDayType.All}
                                    customclass={'form-check form-switch p-0 m-0'}
                                    checked={weekDayType.Last}
                                    value={weekDayType.Last}
                                    label='Last'
                                    name='Last'
                                    id='txtLast'

                                    onChange={(e) => { HandleChangeWeekDayType('Last', e.target.checked) }}

                                />
                                <button className='btn btn-success' onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtPFApplicable'].focus() };
                                }}
                                    ref={ref => (inputrefs.current['btnHandleAddWeeklyOffDetailss'])}
                                    style={{ minWidth: '1.5rem', height: '1.5rem', padding: '0' }}
                                    onClick={handleAddWeeklyOffDetailss}
                                >
                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                        <div className='d-flex'>

                            <div className='mt-3' style={{ width: '49%' }} >
                                {WeeklyOffDetailsList?.length !== 0 ?

                                    <div className='row me-2 '>
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Delete</th>
                                                        <th>WeeklyOffDay</th>
                                                        <th>TypeOfDay</th>
                                                    </tr>
                                                </thead>
                                                <tbody>


                                                    {WeeklyOffDetailsList?.map((item, index) => {
                                                        //   console.log(item,'dgsdjfk')
                                                        return <tr key={index}>
                                                            <td>
                                                                <i className="fa-regular fa-trash-can text-danger me-2" onClick={() => HandleWeeklyOffDetailsDelete(index)} />
                                                            </td>
                                                            <td> {item.weeklyOffDay}</td>
                                                            <td> {item.typeOfDay}</td>

                                                        </tr>
                                                    })}

                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                    : <></>}

                            </div>
                        </div>


                    </fieldset>

                    <fieldset className="myfieldset "  >
                        <legend className="mylegend"> <h6 className="m-0">PF Applicable &nbsp; <input type='checkbox' onKeyDown={(e) => { if (e.key === 'Enter') { recipe.PFApplicable ? inputrefs.current['txtPFNo'].focus() : inputrefs.current['txtESIApplicable'].focus() } }} ref={ref => (inputrefs.current['txtPFApplicable'] = ref)} className={'mt-1'} checked={recipe.PFApplicable} id='txtPFApplicable' value={recipe.PFApplicable} onChange={(e) => { HandleChange('PFApplicable', e.target.checked) }} /> </h6></legend>
                        {recipe.PFApplicable &&
                            < div className='row'>

                                <InputBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtUANNo'].focus() };
                                    }}
                                    label='PF No.'
                                    id='txtPFNo'
                                    maxLength='30'
                                    placeholder='PF No.'
                                    value={recipe.PFNo}
                                    onChange={(e) => { HandleChange('PFNo', e.target.value) }}
                                // required
                                // error={errors.PFNo}
                                />
                                <InputBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtOnCrossingLimitDeductPFon'].focus() };
                                    }}
                                    label='UAN No.'
                                    id='txtUANNo'
                                    maxLength='50'
                                    placeholder='UAN No.'
                                    value={recipe.UANNo}
                                    onChange={(e) => { HandleChange('UANNo', number(e.target.value)) }}
                                />

                                <SelectInputBox
                                    inputrefs={inputrefs}
                                    label='On Crossing Limit, Deduct PF on'
                                    id="txtOnCrossingLimitDeductPFon"
                                    value={recipe.OnCrossingLimitDeductPFon}
                                    error={errors.OnCrossingLimitDeductPFon}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { recipe.OnCrossingLimitDeductPFon === '3' ? inputrefs.current['txtCeilAmount'].focus() : inputrefs.current['txtVolPF'].focus() };
                                    }}
                                    onChange={(e) => {
                                        HandleChange('OnCrossingLimitDeductPFon', e.target.value);
                                        if (e.target.value) setRecipe({ ...recipe, OnCrossingLimitDeductPFon: e.target.value, CeilAmount: '' })
                                    }}
                                    children={<>
                                        <option value="0">No Deduction</option>
                                        <option value="1">Upto Cut off Limit</option>
                                        <option value="2">On Basic</option>
                                        <option value="3">On Ceil Amount</option>
                                    </>}
                                />

                                <InputBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtDOB'].focus() };
                                    }}
                                    label='Ceil Amount'
                                    id='txtCeilAmount'
                                    maxLength='10'
                                    placeholder='Ceil Amount'
                                    value={recipe.OnCrossingLimitDeductPFon === '3' ? recipe.CeilAmount : ''}
                                    onChange={(e) => { HandleChange('CeilAmount', numberwithDot(e.target.value)) }}
                                    disabled={recipe.OnCrossingLimitDeductPFon !== '3'}
                                    required={recipe.OnCrossingLimitDeductPFon === '3'}
                                    error={errors.CeilAmount}
                                />
                                <CheckBoxInput
                                    customclass={'form-check form-switch p-0 m-0 mt-4'}
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => { if (e.key === 'Enter') {inputrefs.current['txtVolPFType'].focus() } }}
                                    checked={recipe.VolPF}

                                    label='Vol PF'
                                    id='txtVolPF'
                                    //  value={recipe.IsCalculate}
                                    onChange={(e) => { HandleChange('VolPF', e.target.checked) }}
                                />

                                <SelectInputBox
                                    inputrefs={inputrefs}
                                    label='Vol PF Type'
                                    disabled={!recipe.VolPF}
                                    id="txtVolPFType"
                                    value={recipe.VolPFType}
                                    error={errors.VolPFType}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { recipe.VolPFType === '3' ? inputrefs.current['txtEmpVolPF'].focus() : inputrefs.current['txtESIApplicable'].focus() };
                                    }}
                                    onChange={(e) => {
                                        HandleChange('VolPFType', e.target.value);
                                        if (e.target.value) setRecipe({ ...recipe, VolPFType: e.target.value})
                                    }}
                                    children={<>
                                        <option value="">--Select Vol PF Type--</option>
                                        <option value="Amount">Amount</option>
                                        <option value="Percentage">Percentage</option>
                                    </>}
                                />

                                <InputBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtEmpVolPF'].focus() };
                                    }}
                                    label='Empr Vol PF (%)'
                                    id='txtEmprVolPF'
                                    maxLength='6'
                                    placeholder='Empr Vol PF (%)'
                                    value={recipe.EmprVolPF}
                                    onChange={(e) => { HandleChange('EmprVolPF', numberwithDot(e.target.value)) }}
                                    /*onKeyUp={(e) => {*}
                                    /*    if (e.target.value > 100) { HandleChange('EmprVolPF', '') };*/
                                    /*}}*/
                                    disabled={!recipe.VolPF}
                                    required={recipe.VolPF}
                                    error={errors.EmprVolPF}
                                />
                                <InputBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtESIApplicable'].focus() };
                                    }}
                                    label='Emp Vol PF (%)'
                                    id='txtEmpVolPF'
                                    maxLength='6'
                                    placeholder='Emp Vol PF (%)'
                                    value={recipe.EmpVolPF}
                                    onChange={(e) => { HandleChange('EmpVolPF', numberwithDot(e.target.value)) }}
                                    /*onKeyUp={(e) => {*}
                                    /*    if (e.target.value > 100) { HandleChange('EmpVolPF', '') };*}
                                    /*}}*/
                                    disabled={!recipe.VolPF}
                                    required={recipe.VolPF}
                                    error={errors.EmpVolPF}
                                />

                            </div>
                        }
                    </fieldset>

                    <fieldset className="myfieldset "  >
                        <legend className="mylegend"> <h6 className="m-0">ESIC Applicable &nbsp; <input onKeyDown={(e) => { if (e.key === 'Enter') { recipe.ESIApplicable ? inputrefs.current['txtTICNo'].focus() : inputrefs.current['txtPaymentMode'].focus() } }} type='checkbox' className={'mt-1'} checked={recipe.ESIApplicable} ref={ref => (inputrefs.current['txtESIApplicable'] = ref)} id='txtESIApplicable' value={recipe.ESIApplicable} onChange={(e) => { HandleChange('ESIApplicable', e.target.checked) }} /> </h6></legend>
                        {recipe.ESIApplicable &&
                            <div className='row'>
                                <InputBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtTICStatus'].focus() };
                                    }}
                                    label='TIC No.'
                                    id='txtTICNo'
                                    maxLength='30'
                                    placeholder='TIC No.'
                                    value={recipe.TICNo}
                                    onChange={(e) => { HandleChange('TICNo', number(e.target.value)) }}
                                />

                                <SelectInputBox
                                    inputrefs={inputrefs}
                                    label='TIC Status'
                                    id="txtTICStatus"
                                    value={recipe.TICStatus}
                                    error={errors.TICStatus}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtDispensary'].focus() };
                                    }}
                                    onChange={(e) => HandleChange('TICStatus', e.target.value)}
                                    children={<>
                                        <option value="Pending">Pending</option>
                                        <option value="Failed">Failed</option>
                                        <option value="Sucess">Success</option>
                                    </>}
                                />
                                <InputBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtSmartCard'].focus() };
                                    }}
                                    label='Dispensary'
                                    id='txtDispensary'
                                    maxLength='30'
                                    placeholder='Dispensary'
                                    value={recipe.Dispensary}
                                    onChange={(e) => { HandleChange('Dispensary', number(e.target.value)) }}
                                />
                               
                                <SelectInputBox
                                    inputrefs={inputrefs}
                                    label='Smart Card'
                                    id="txtSmartCard"
                                    value={recipe.SmartCard}
                                    error={errors.SmartCard}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtTICMode'].focus() };
                                    }}
                                    onChange={(e) => HandleChange('SmartCard', e.target.value)}
                                    children={<>
                                        <option value="">--Select--</option>
                                        <option value="Pending">Pending</option>
                                        <option value="Issued">Issued</option>
                                    </>}
                                />

                                <InputBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtTICMode'].focus() };
                                    }}
                                    label='ESIC No.'
                                    id='txtESINo'
                                    maxLength='30'
                                    placeholder='ESIC No.'
                                    value={recipe.ESINo}
                                    onChange={(e) => { HandleChange('ESINo', number(e.target.value)) }}
                                    disabled
                                />
                              
                                <SelectInputBox
                                    inputrefs={inputrefs}
                                    label='TIC Mode'
                                    id="txtTICMode"
                                    value={recipe.TICMode}
                                    error={errors.TICMode}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtPaymentMode'].focus() };
                                    }}
                                    onChange={(e) => HandleChange('TICMode', e.target.value)}
                                    children={<>
                                        <option value="">--Select TIC Mode--</option>
                                        <option value="By Hand">By Hand</option>
                                        <option value="Courier">Courier</option>
                                    </>}
                                />

                            </div>
                        }
                    </fieldset>
                    <div className='myfieldset'>
                        <div className='row' >
                           
                            <SelectInputBox
                                label='Payment Mode'
                                id="txtPaymentMode"
                                value={recipe.PaymentMode}
                                error={errors.PaymentMode}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtRemarks'].focus() };
                                }}
                                required
                                inputrefs={inputrefs}
                                onChange={(e) => HandleChange('PaymentMode', e.target.value)}
                                children={<>
                                    <option value="">--Select Payment Mode--</option>
                                    {paymentModeAllData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}
                                </>}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtLeaveEncashmentApplicable'].focus() };
                                }}

                                label='Remarks'
                                id='txtRemarks'
                                maxLength='50'
                                placeholder='Remarks'
                                value={recipe.Remarks}
                                onChange={(e) => { HandleChange('Remarks', (e.target.value)) }}
                            />

                            <CheckBoxInput
                                customclass={'form-check form-switch p-0 mt-4'}
                                inputrefs={inputrefs}
                                onKeyDown={(e) => { if (e.key === 'Enter') inputrefs.current['txtBonusApplicable'].focus() }}
                                checked={recipe.LeaveEncashmentApplicable}

                                label='Leave Encashment Applicable'
                                id='txtLeaveEncashmentApplicable'
                                //  value={recipe.IsCalculate}
                                onChange={(e) => { HandleChange('LeaveEncashmentApplicable', e.target.checked) }}


                            />
                            <CheckBoxInput
                                customclass={'form-check form-switch p-0 mt-4'}
                                onKeyDown={(e) => { if (e.key === 'Enter') { recipe.PaymentMode === '4' ? inputrefs.current['txtEmployeeBank'].focus() : handleNext() } }}
                                checked={recipe.BonusApplicable}
                                inputrefs={inputrefs}
                                label='Bonus Applicable'
                                id='txtBonusApplicable'
                                //  value={recipe.IsCalculate}
                                onChange={(e) => { HandleChange('BonusApplicable', e.target.checked) }}
                            />
                        </div>
                    </div>

                    {(recipe.PaymentMode === '4') &&
                        <fieldset className="myfieldset "  >
                            <legend className="mylegend"> <h6 className="m-0">Bank Details</h6></legend>
                            <div className='row'>
                                <AutoCompleteBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtAccountNo'].focus() };
                                    }}
                                    label='Employee Bank'
                                    placeholder="Employee Bank"
                                    maxLength='50'
                                    options={EmployeeBankData}

                                    id='txtEmployeeBank'
                                    required={recipe.PaymentMode === '4' ? true : false}
                                    error={errors.EmployeeBank}
                                    inputValue={recipe.EmployeeBank ? recipe.EmployeeBank : ''}
                                    onInputChange={(event, newInputValue) => {
                                        HandleChange('EmployeeBank', newInputValue)
                                        GetIFSCPrefix(newInputValue)
                                        getEmployeeBankData(newInputValue)
                                    }}
                                />
                                <InputBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtNameinBankAccount'].focus() };
                                    }}
                                    label='Account No'
                                    id='txtAccountNo'
                                    maxLength='16'
                                    placeholder='Account No'
                                    value={recipe.AccountNo}
                                    onChange={(e) => { HandleChange('AccountNo', number(e.target.value)) }}
                                    disabled={recipe.EmployeeBank === '' ? true : false}
                                    required={recipe.PaymentMode === '4' ? true : false}
                                    error={errors.AccountNo}
                                />
                                <InputBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtIFSCCode'].focus() };
                                    }}
                                    label='Name in Bank Account'
                                    id='txtNameinBankAccount'
                                    maxLength='50'
                                    placeholder='Name in Bank Account'
                                    value={recipe.NameinBankAccount}
                                    onChange={(e) => { HandleChange('NameinBankAccount', (e.target.value)) }}
                                    required={recipe.PaymentMode === '4'}
                                    disabled={!recipe.EmployeeBank}
                                    error={errors.NameinBankAccount}
                                />
                                <InputBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtAccountUpdateDate'].focus() };
                                    }}
                                    label='IFSC Code'
                                    id='txtIFSCCode'
                                    maxLength='11'
                                    placeholder='IFSC Code'
                                    value={recipe.IFSCCode}
                                    onChange={(e) => { HandleChange('IFSCCode', e.target.value.toUpperCase()) }}
                                    required={recipe.PaymentMode === '4'}
                                    disabled={!recipe.EmployeeBank}
                                    error={errors.IFSCCode}
                                />
                                <DateBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { handleNext() };
                                    }}
                                    label='Account Update Date'
                                    id='txtAccountUpdateDate'
                                    selected={recipe.AccountUpdateDate === '' ? '' : new Date(recipe.AccountUpdateDate)}
                                    placeholder="Account Update Date"
                                    //value={recipe.AccountUpdateDate}
                                    onChange={(e) => HandleChange('AccountUpdateDate', dateFormate(e))}
                                    error={errors.AccountUpdateDate}
                                    required={recipe.PaymentMode === '4'}
                                    disabled={!recipe.EmployeeBank}
                                />

                            </div>
                        </fieldset>
                    }

                    <FormButton
                        handleNext={handleNext}
                        save={false}
                        Export={false}
                        Next={true}
                        nextButtonText='Save & Next'
                        nextButtonIcon='fa-check'
                        nextButtonColor='btn-primary'
                        handleClear={handleClear}
                    />
                </div>
            </div>
        </>
    )
}

export default EmploymenteDetails;