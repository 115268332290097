
import { useEffect, useRef, useState } from "react";
import Pagination from '@mui/material/Pagination';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getSearchingColumn } from "../../apiservices/CommonServices";
import { ColorRing } from 'react-loader-spinner'
import { confirmAlert } from 'react-confirm-alert'

export const TableWithoutFilter = ({ Token, PageName, Action = true, showView = false, showApprove = false, showReject = false, showTotalRecord = false, totalRecordText = 'Total Records : ', noRecordText = 'No Record Found', tableData, currentPageNo, handleChange1, handleCheckData, handleSorting, handlePrintPdfData, sortConfig, filterData, setFilterData, handleFilter, Filter = true, AdvancedFilter = true, handleView, handleApprove, handleReject, handleCancel, NameForReportModal }) => {
    const inputrefs = useRef([]);
    const AdvanceSearchModelcloseButtonRef = useRef(null);
    const AdvanceSearchModelInputRef = useRef([]);
    const [ModalData, setModalData] = useState([]);
    const [MainData, setMainData] = useState(tableData?.Data ? tableData?.Data : []);
    const [ModalTitleKey, setModalTitleKey] = useState([]);
    const [ModalHeaderKey, setModalHaderKey] = useState([]);
    const [AdvanceModelCleared, setAdvanceModelCleared] = useState(true);
    const [serchingData, setSerchingData] = useState([]);
    const [advanceSearchingData, setAdvanceSearchingData] = useState([]);

    const ShowEyeRecords = (data, key) => {
        setModalData(data)
        setModalTitleKey(key)
        setModalHaderKey(Object?.keys(data[0]))
    };

    const finalHeaderKeys = tableData?.HeadersValue?.filter(
        (key, index) => {
            return !tableData?.HideColumns.find((column) => key === column)
        }
    );

    const finalHeadersValue = tableData?.HeadersKey?.filter(
        (key) => {
            return !tableData?.HideColumns.find((column) => key === column)
        }
    );

    const [ColoredRow, setColoredRow] = useState('');
    const handleHighlight = (id) => {
        setColoredRow(id)
    };

    const handleResetAdvanceFilter = () => {
        let updatedDataList = advanceSearchingData?.map((item, index) => ({
            ...item,
            Value: ''
        }));
        setAdvanceSearchingData(updatedDataList);
        setFilterData({ ...filterData, AdvanceFilter: [] });
        let iscleared = AdvanceModelCleared ? false : true;
        setAdvanceModelCleared(iscleared)

    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            
            if (advanceSearchingData?.length > index + 1) {
                AdvanceSearchModelInputRef.current[index + 1].focus();
            } else {
                AdvanceSearchModelInputRef.current['btnAdvSubmit'].focus();
            }
        }
    };

    const serching = async () => {
        if (Filter) {
            getSearchingColumn({ ...Token, PageName }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                    } else {
                        let data = res
                        setSerchingData(data.dataList)
                        //setAdvanceSearchingData(data.advanceDataList)
                        setFilterData({ ...filterData, SearchFieldValue: data.dataList[0].valuePart })
                    }
                }
            });
        }
    };

    const handleShowFile = () => {

    }

    const countNo = Math.ceil((parseInt(tableData?.TotalRecord) / parseInt(tableData?.PageSize)));

    useEffect(() => {
        serching();
    }, []);

    useEffect(() => {
        setMainData(tableData?.Data)
    }, [tableData]);

    useEffect(() => {
        handleFilter();
    }, [AdvanceModelCleared]);


    return (

        <>  {
            tableData ? (
                <div className='main-card card border-0' >
                    <div className='card-header mb-1'>
                        {showTotalRecord ?
                            <>
                                <div className='row justify-content-between align-items-center'>
                                    <div className='col-md-4'>
                                        <h6 className='m-0'>{`${tableData?.TotalRecord !== undefined ? totalRecordText + tableData?.TotalRecord : noRecordText}`}</h6>
                                    </div>
                                </div>
                            </> : <></>}
                    </div>
                   
                    <div className='card-body'>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr className='light-bg'>
                                        {showView && (tableData?.Actions ? (
                                            <th style={{ width: '10px' }} key="thView">View</th>
                                        ) : (
                                            <></>
                                        ))}
                                        {showApprove && (tableData?.Actions ? (
                                            <th style={{ width: '10px' }} key="thApprove">Approve</th>
                                        ) : (
                                            <></>
                                        ))}
                                        {showReject && (tableData?.Actions ? (
                                            <th style={{ width: '10px' }} key="thReject">Reject</th>
                                        ) : (
                                            <></>
                                        ))}
                                    {finalHeaderKeys?.map((th, headIndex) => (
                                        <th key={`th${headIndex || 0}`} style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                handleSorting ?
                                                    handleSorting(finalHeadersValue[headIndex]) : ''}
                                        >
                                            {th}
                                            {
                                                (sortConfig?.SortColumn !== null
                                                    && sortConfig?.SortOrder !== null
                                                    && sortConfig?.SortColumn === finalHeadersValue[headIndex]) ?
                                                    (
                                                        <>
                                                            <i className="fa fa-arrow-up ms-2" style={{ color: sortConfig?.SortOrder !== 'DESC' ? '#d4d4d4' : 'red' }}></i>
                                                            <i className="fa fa-arrow-down" style={{ color: sortConfig?.SortOrder !== 'ASC' ? '#d4d4d4' : 'red' }}  ></i>
                                                        </>
                                                    )
                                                    : (
                                                        <>
                                                            <i className="fa fa-arrow-up ms-2" style={{ color: '#d4d4d4' }}></i>
                                                            <i className="fa fa-arrow-down" style={{ color: '#d4d4d4' }}  ></i>
                                                        </>
                                                    )
                                            }

                                        </th> || <></>
                                    ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {MainData?.map((item, bodyIndex) => (
                                        <tr className="TableTrhover" key={`row${bodyIndex}`} onClick={() => handleHighlight(`row${bodyIndex}`)} style={{ background:`row${bodyIndex}` === ColoredRow ? '#fee77f' : '' }} >
                                            {showView && (tableData?.Actions ? (
                                                <td key={`tdView${bodyIndex}`}>
                                                    {tableData.Actions?.map((btn, index) => (
                                                        <>
                                                            {btn === 'View' ? <button type="button" style={{ minWidth: '3.5rem' }} className="btn btn-outline-primary p-0 m-0" key={`row${bodyIndex}edit${index}`} onClick={() => handleView(item.View, item.Employee)} >View</button> : (<></>)}
                                                        </>
                                                    ))}
                                                </td>
                                            ) : (
                                                <></>
                                            ))}
                                            {showApprove && (tableData?.Actions ? (
                                                <td key={`tdApprove${bodyIndex}`}>
                                                    {tableData.Actions?.map((btn, index) => (
                                                        <>
                                                            {btn === 'Approve' ? <button type="button" style={{ minWidth: '3.5rem' }} className="btn btn-outline-success p-0 m-0" key={`row${bodyIndex}edit${index}`} onClick={() => handleApprove(item.Approve, item.Employee)} >Approve</button> : (<></>)}
                                                        </>
                                                    ))}
                                                </td>
                                            ) : (
                                                <></>
                                            ))}
                                            {showReject && (tableData?.Actions ? (
                                                <td key={`tdReject${bodyIndex}`}>
                                                    {tableData.Actions?.map((btn, index) => (
                                                        <>
                                                            {btn === 'Reject' ? <button type="button" style={{ minWidth: '3.5rem' }} className="btn btn-outline-danger p-0 m-0" key={`row${bodyIndex}edit${index}`} onClick={() => handleReject(item.Reject, item.Employee)} >Reject</button> : (<></>)}
                                                        </>
                                                    ))}
                                                </td>
                                            ) : (
                                                <></>
                                            ))}
                                            {finalHeadersValue?.map((key, tdIndex) => (
                                                (typeof (item[key]) === 'object') ? (
                                                    Array.isArray(item[key]) ? 
                                                        <td className='text-center'>
                                                            <i className="fa fa-eye"
                                                                data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                style={{ cursor: 'pointer', color: '#5a8dee' }}
                                                                onClick={() => ShowEyeRecords(item[key], key)}
                                                            />
                                                        </td> : (
                                                            typeof (item[key]) === 'object' && item[key]?.fileType === 'pdf' ?
                                                                <td className='text-center'>
                                                                    <i className="fa fa-pdf"

                                                                        style={{ cursor: 'pointer', color: '#5a8dee' }}

                                                                    />
                                                                </td> : (<td className='text-center' onClick={() => {handleShowFile(item[key]) } }>
                                                                    <i className="fa fa-image"
                                                                            
                                                                        style={{ cursor: 'pointer', color: '#5a8dee' }}

                                                                    />
                                                                </td>))
                                                )
                                                    :
                                                    <td key={`key${tdIndex}`}>{item[key]}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            </div>
                        {countNo > 1 ?
                            < Pagination className='pt-2' count={countNo} variant="outlined" page={currentPageNo} onChange={handleChange1} shape="rounded" />
                            : ''}
                        </div>

                    {AdvancedFilter ?
                        <>
                            <div className="modal fade" id="AdvanceSearchModal" tabIndex="-1" role="dialog" aria-labelledby="AdvanceSearchModalLabel" aria-hidden="true">
                                <div className='modal-dialog modal-lg modal-dialog-centered' style={{ width: '500px' }}>
                                    <div className='modal-content main-card'>
                                        <div className="modal-header">
                                            <h5 className="modal-title">Advance Search</h5>
                                            <button type="button" ref={AdvanceSearchModelcloseButtonRef} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className='row'>

                                                {Array.isArray(advanceSearchingData) ? (
                                                    advanceSearchingData?.map((list, index) => (

                                                        <div key={`advancedFilterList${index}`} className='col-md-12 mb-2 d-flex justify-content-between align-items-center'>

                                                            <label className="col-md-4" style={{ textAlign: 'right' }} value={list?.valuePart}>{list?.textPart}</label>
                                                            <input type="text"
                                                                className="ps-1"
                                                                style={{ width: "20px", height: "20px" }}
                                                                placeholder={list?.operator}
                                                                value={list?.operator}
                                                                disabled />

                                                            <div className='col-md-7'>
                                                                {list.dataType === 'string' ?
                                                                    <input type="text"
                                                                        className="form-control"
                                                                        ref={ref => (AdvanceSearchModelInputRef.current[index] = ref)}
                                                                        onKeyDown={(e) => handleKeyDown(index, e)}
                                                                        placeholder={list?.textPart}
                                                                        value={list?.Value ? list?.Value : ''}
                                                                        onChange={(e) => {

                                                                            const value = e.target.value;
                                                                            const columnName = list?.valuePart;
                                                                            const operator = list?.operator;

                                                                            advanceSearchingData[index] = { ...advanceSearchingData[index], Value: value };
                                                                            setAdvanceSearchingData(advanceSearchingData)

                                                                            const existingIndex = filterData?.AdvanceFilter?.findIndex(item => item.ColumnName === columnName && item.Operator === operator);

                                                                            if (value.trim() === '') {
                                                                                if (existingIndex !== -1) {
                                                                                    const updatedAdvList = [...filterData.AdvanceFilter];
                                                                                    updatedAdvList.splice(existingIndex, 1);
                                                                                    setFilterData({ ...filterData, AdvanceFilter: updatedAdvList });
                                                                                }
                                                                            } else {
                                                                                if (existingIndex !== -1) {
                                                                                    const updatedAdvList = [...filterData.AdvanceFilter];
                                                                                    updatedAdvList[existingIndex] = { ...updatedAdvList[existingIndex], ColumnValue: value };
                                                                                    setFilterData({ ...filterData, AdvanceFilter: updatedAdvList });
                                                                                } else {
                                                                                    const newAdvList = [...filterData.AdvanceFilter, { ColumnName: columnName, Operator: operator, ColumnValue: value }];
                                                                                    setFilterData({ ...filterData, AdvanceFilter: newAdvList });
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                    : <></>}
                                                                {list.dataType === 'bool' ?
                                                                    <input
                                                                        className=""
                                                                        type="checkbox"
                                                                        checked={list?.Value ? list?.Value : ''}
                                                                        onChange={(e) => {
                                                                            const value = e.target.checked;
                                                                            const columnName = list?.valuePart;
                                                                            const operator = list?.operator;
                                                                            advanceSearchingData[index] = { ...advanceSearchingData[index], Value: value };
                                                                            setAdvanceSearchingData(advanceSearchingData)

                                                                            const existingIndex = filterData?.AdvanceFilter?.findIndex(item => item.ColumnName === columnName && item.Operator === operator);

                                                                            if (value === false) {
                                                                                if (existingIndex !== -1) {
                                                                                    const updatedAdvList = [...filterData.AdvanceFilter];
                                                                                    updatedAdvList.splice(existingIndex, 1);
                                                                                    setFilterData({ ...filterData, AdvanceFilter: updatedAdvList });
                                                                                }
                                                                            } else {
                                                                                if (existingIndex !== -1) {
                                                                                    const updatedAdvList = [...filterData.AdvanceFilter];
                                                                                    updatedAdvList[existingIndex] = { ...updatedAdvList[existingIndex], ColumnValue: value };
                                                                                    setFilterData({ ...filterData, AdvanceFilter: updatedAdvList });
                                                                                } else {
                                                                                    const newAdvList = [...filterData.AdvanceFilter, { ColumnName: columnName, Operator: operator, ColumnValue: value }];
                                                                                    setFilterData({ ...filterData, AdvanceFilter: newAdvList });
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                    : <></>}
                                                                {list.dataType === 'datetime' ?
                                                                    <DatePicker
                                                                        //  selected={new Date()}
                                                                        selected={list.Value ? new Date(list.Value) : ''}
                                                                        className='form-control input-fields'
                                                                        dateFormat="dd-MMM-yyyy"
                                                                        placeholderText={list.textPart}
                                                                        inputrefs={inputrefs}
                                                                        onChange={(e) => {
                                                                            let dateArr = e?.toString()?.split(' ')
                                                                            dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`

                                                                            const columnName = list?.valuePart;

                                                                            const operator = list?.operator;

                                                                            advanceSearchingData[index] = { ...advanceSearchingData[index], Value: dateArr };
                                                                            /* console.log(advanceSearchingData[index],"jgs")*/
                                                                            setAdvanceSearchingData(advanceSearchingData)

                                                                            const existingIndex = filterData?.AdvanceFilter?.findIndex(item => item.ColumnName === columnName && item.Operator === operator);

                                                                            if (dateArr === '') {
                                                                                if (existingIndex !== -1) {
                                                                                    const updatedAdvList = [...filterData.AdvanceFilter];
                                                                                    updatedAdvList.splice(existingIndex, 1);
                                                                                    setFilterData({ ...filterData, AdvanceFilter: updatedAdvList });
                                                                                }
                                                                            } else {
                                                                                if (existingIndex !== -1) {
                                                                                    const updatedAdvList = [...filterData.AdvanceFilter];
                                                                                    updatedAdvList[existingIndex] = { ...updatedAdvList[existingIndex], ColumnValue: dateArr };
                                                                                    setFilterData({ ...filterData, AdvanceFilter: updatedAdvList });
                                                                                } else {
                                                                                    const newAdvList = [...filterData.AdvanceFilter, { ColumnName: columnName, Operator: operator, ColumnValue: dateArr }];
                                                                                    setFilterData({ ...filterData, AdvanceFilter: newAdvList });
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                    : <></>}
                                                            </div>
                                                        </div>
                                                    ))

                                                ) : (<></>)}
                                            </div>
                                        </div>
                                        <div className="modal-footer p-0">
                                            <button type="button"
                                                ref={ref => (AdvanceSearchModelInputRef.current['btnAdvSubmit'] = ref)}
                                                className="btn btn-rounded btn-outline-success" onClick={(e) => {
                                                    if (AdvanceSearchModelcloseButtonRef.current) {
                                                        AdvanceSearchModelcloseButtonRef.current.click();
                                                    }
                                                    handleFilter()
                                                }}><span className=" text-success me-2"><i className="fa-solid fa-hand-pointer"></i>
                                                </span>Submit</button>
                                            <button type="button" className="btn btn-rounded btn-outline-danger" onClick={()=>handleResetAdvanceFilter}><span className=" text-danger me-2"><i className="fa-solid fa-refresh"></i>
                                            </span>Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        : <></>}

                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content" style={{ background: "mintcream" }}>
                                <div className="modal-header" style={{ padding: '7px 15px' }}>
                                    <h5 className="modal-title" id="exampleModalLabel">{ModalTitleKey ? ModalTitleKey : 'Show Records'}</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body table-responsive" style={{ maxHeight: '210px', padding: '8px' }}>
                                    <table className="table border text-center">
                                        <thead>
                                            <tr className='light-bg'>
                                                {ModalHeaderKey?.map((th, headIndex) => (
                                                    <>
                                                        <th key={`th${headIndex || 0}`} style={{ padding: '5px 10px', background: '#ffdcdc' }}>{th}</th>
                                                    </>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ModalData?.map((item, bodyIndex) => (
                                                <tr className="TableTrhover" key={`row${bodyIndex}`} >
                                                    {ModalHeaderKey?.map((key, tdIndex) => (
                                                        <>
                                                            <td key={`key${tdIndex}`}>{item[key]}</td>
                                                        </>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modal-footer" style={{ padding: '3px' }}>
                                    <button type="button" className="btn btn-secondary" style={{ margin: '5px', minWidth: '30px' }} data-bs-dismiss="modal">Close <i className='fa fa-close' /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

            ) : (<> </>)
        }

        </>
    )
}

