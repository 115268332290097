import React, { createContext,useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Cookies } from 'react-cookie';
//react toast 
import { toast } from 'react-toastify';


const authContext = createContext();

export function AuthProvider({ children }) {
    const cookies =new Cookies();
    const [user, setUser] = useState((cookies.get('user')) || null)
    const navigate = useNavigate();

    const login = (userDetails) => {
        cookies.set('user', (userDetails), {path:'/'});
        setUser(userDetails);
        navigate("/Dashboard/Dashboard");
    };

    const logout = (res) => {
        setUser(null);
        cookies.remove('user', {path:'/'});
        //cookies.removeAll('user');
        if (!cookies) {
            toast.success(res.message);
            navigate("/");
        }
    };
    const sessionExpired = () => {
        setUser(null);
        cookies.remove('user');
        navigate("/");
    };

    return (
        <authContext.Provider value={{ user, login, logout, sessionExpired }}>
            {children}
        </authContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(authContext)
}
