import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import { DateBox, FormButton, InputBox } from "../commoncomponents/InputBox";
import { Table } from '../commoncomponents/Table';
//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER, NotificationCount, initializeFilterData } from '../../Utils';

//services
import { useRecoilState } from 'recoil';
import { post } from '../../apiservices/service';
import { GetNoificaitonCount, Token, dateFormate, number } from '../Common/Common';

function VerifyCandidate() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();

   
    //Get PathName
    let url = window.location.pathname.split("/")
    const pathName = url[url.length-1]
  

    //token Data
    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({
        //  CandidateID: '0',
        CandidateCode: '',
        CandidateName: '',
        FatherName: '',
        DOB: '',
        MobileNo: '',
        FromDate: '',
        ToDate: '',
        PageType: "VerifyCandidate"


    })
    const navigate = useNavigate()
    const [candidateId, setCandidateId] = useState([])
    const [showTable, setShowTable] = useState(false)
    const [errors, setErrors] = useState({});
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)

    const [isUpdate, setIsUpdate] = useState(false)
    const [dateOfBirth, setDateOfBirth] = useState(new Date());
    // const [dateOfBirthE, setDateOfBirthE] = useState(new Date());
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [Reason, setReason] = useState('')

    const [notificationData, setNotificationData] = useRecoilState(NotificationCount)

    ////Handle Get Verify Candidate 
    //const GetNotificationCount = () => {
    //    post(`${API_SERVER}api/Dashboard/GetPendingVerification`, { ...TokenData, }, (res) => {
    //        if (res && res.status) {
    //            if (res.status === "ERROR") {

    //            } else if (res.status === "EXPIRED") {
    //                toast.error(res.message)
    //                sessionExpired()
    //            } else if (res.status === "UNAUTHORIZED") {

    //            } else {
    //                setNotificationData({
    //                    ...notificationData,
    //                    pendingVerificationData: res.data.pendingVerification,
    //                    pendingConvertToEmployee: res.data.pendingConvertToEmployee,
    //                    totalNotification: Number(res.data.pendingVerification) + Number(res.data.pendingConvertToEmployee)
    //                })
    //            }
    //        }
    //    });
    //}


    //const getFormData = (CurrentPage) => {
    //    let PageIndex = 1
    //    if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
    //        PageIndex = CurrentPage;
    //        setCurrentPageNo(CurrentPage);
    //    }
    //    else {
    //        setCurrentPageNo(1);
    //    }

    //    setLoading(true)

    //    let temp = {
    //        PageIndex: PageIndex.toString(),
    //        SearchText: "",
    //        StartValue: "1",
    //        SearchFieldValue: "",
    //        Type: "s"
    //    }
    //    post(`${API_SERVER}api/Candidate/SearchVerifyCandidate`, { ...TokenData, ...temp, ...sortConfig, ...filterData, ...recipe, PageType: "VerifyCandidate" }, (res) => {
    //        if (res && res.Status) {
    //            if (res.Status === "ERROR") {
    //                setLoading(false)
    //                setResult({ ...res })
    //            } else {
    //                setLoading(false)
    //                setResult({ ...res })
    //            }
    //        }
    //    });
    //}





    //Change Handler
    const HandleChange = (prop, value) => {
        if (prop === 'DOB') {
            setDateOfBirth(value)
        } else if (prop === 'ToDate') {
            setToDate(value)
        } else if (prop === 'FromDate') {
            setFromDate(value)
        }
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //validation handler
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "CandidateCode", msg: 'Please Enter Candidate Code.' },
            { field: "CandidateName", msg: 'Please Enter Candidate Name.' },
            { field: "FatherName", msg: 'Please Enter Father Name.' },
            { field: "DOB", msg: 'Please Enter DOB.' },
            { field: "MobileNo", msg: 'Please Enter Mobile No.' },
            { field: "EmailID", msg: 'Please Enter Email ID.' },
            { field: "FromDate", msg: 'Please Select Interview Date.' },
            { field: "ToDate", msg: 'Please Enter DOJ.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });


        return isValid;
    }
    const ValidateReason = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "CandidateCode", msg: 'Please Enter Candidate Code.' },
        ]

        validation.map(item => {
            if (!Reason || Reason.length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });


        return isValid;
    }


    //Submit Handler
    const handleSubmit = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        // setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/Candidate/SearchVerifyCandidate`, { ...TokenData, ...temp, ...sortConfig, ...filterData, ...recipe, PageType: "VerifyCandidate" }, (res) => {

            // console.log('1111111111111111111111111',res)
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    toast.error(res.Message)
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    //toast.success(res.Message)
                    setResult({ ...res })
                    setShowTable(true)
                    //getFormData()
                    // handleClear()
                    //console.log(recipe, 'else')
                }
            }
        });

    }



    const handleChange1 = (event, value) => {
        // getFormData(value);
        handleSubmit(value);
    };
    //Handle Check box click
    const handleCheckData = (Id, e) => {
        if (e===true) {
            setCandidateId((preValue) => [...preValue, Id])
        } else {
            const updatedIds = candidateId.filter(ele => ele !== Id)
            setCandidateId(updatedIds)
        }
    }


    // handle Click on CandidateCode

    const handleRedirectToCandidateMaster = (Id) => {
        if (Id) {
            window.open(`${window.location.origin}/Candidate/CandidateMaster?CandidateCode=${Id}`, '_blank')
            //window.open(`${window.location.origin}/Candidate/CandidateMaster?CandidateCode=${Id}`, '_blank', 'rel=noopener noreferrer')
        }
    }



    //Handle Delete
    const handleDelete = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/Candidate/DeleteCandidate`, { ...TokenData, CandidateID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else {
                    toast.success(res.message)
                    setLoading(false)
                    //getFormData()
                    //getFormData()
                }
            }
        });
    }

    // handle verify data
    const handleVerify = () => {
       // console.log(candidateId)
        post(`${API_SERVER}api/Candidate/VerifyCandidate`, { ...TokenData, CandidateID: (candidateId.length > 0 ? `${candidateId.join(",")}` : '') }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.status === "EXPIRED") {
                    toast.error(res.message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    toast.success(res.message)
                    setLoading(false)
                    setShowTable(false)
                    GetNoificaitonCount(notificationData, setNotificationData, TokenData, sessionExpired)
                }
            }
        });
    }

    //Handle Reject
    const HandleRejectCandidate = () => {
      //  var Reason = prompt("Please Fill Rejected Reason")
        //if (Reason === null || Reason === '') {
        //    //toast.error('Fill Rejected Reason')
        //    return;
        //} else {
            post(`${API_SERVER}api/Candidate/RejectCandidateVerification`, { ...TokenData, RejectedReason: Reason, CandidateID: (candidateId.length > 0 ? `${candidateId.join(",")}` : ''), }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        setLoading(false)
                        setResult({ ...res })
                    } else if (res.status === "EXPIRED") {
                        toast.error(res.message)
                        sessionExpired()
                    } else if (res.status === "UNAUTHORIZED") {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        toast.success(res.message)
                        setLoading(false)
                        setResult({ ...res })
                        setShowTable(true)
                        GetNoificaitonCount(notificationData, setNotificationData, TokenData, sessionExpired)
                    }
                }
            });
        //}
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            //  CandidateID: '0',
            CandidateCode: '',
            CandidateName: '',
            FatherName: '',
            DOB: '',
            MobileNo: '',
            FromDate: '',
            ToDate: '',
            PageType: "VerifyCandidate"
        }))
        setErrors({})
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        setShowTable(false)
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };




    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1',
        PageType: "VerifyCandidate"

    }
    //End Export functionality

    useEffect(() => {
        inputrefs.current['txtCandidateCode'].focus()
        document.title = 'Pay Plus: Verify Candidate'

        //    getFormData()
    }, [sortConfig, isUpdate])


    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0"> Verify Candidate</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtName'].focus() };
                            }}
                            label='Candidate Code'
                            id='txtCandidateCode'
                            maxLength='50'
                            placeholder="Candidate Code"
                            value={recipe.CandidateCode}
                            onChange={(e) => { HandleChange('CandidateCode', (e.target.value)) }}
                        //required
                        //  error={errors.CandidateCode}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtFatherName'].focus() };
                            }}
                            label='Candidate Name'
                            id='txtName'
                            maxLength='50'
                            placeholder="Candidate Name"
                            value={recipe.CandidateName}
                            onChange={(e) => { HandleChange('CandidateName', (e.target.value)) }}
                        //  required
                        //  error={errors.CandidateName}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtDOB'].focus() };
                            }}
                            label={`Father's Name`}
                            id='txtFatherName'
                            maxLength='50'
                            placeholder={`Father's Name`}
                            value={recipe.FatherName}
                            onChange={(e) => { HandleChange('FatherName', (e.target.value)) }}
                        //   required
                        //  error={errors.FatherName}
                        />

                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtMobileNo'].focus() };
                            }}
                            label='DOB'
                            id='txtDOB'
                            selected={recipe.DOB === "" ? "" : new Date(recipe.DOB)}
                            placeholder="DOB"
                           // value={recipe.DOB}
                            onChange={(e) => HandleChange('DOB', dateFormate(e))}
                        //   required
                        //     error={errors.DOB}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtFromDate'].focus() };
                            }}
                            label='Mobile No.'
                            id='txtMobileNo'
                            maxLength='10'
                            placeholder="Mobile No."
                            //  required
                            //   error={errors.MobileNo}
                            value={recipe.MobileNo} onChange={(e) => { HandleChange('MobileNo', number(e.target.value)) }}
                        />

                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtToDate'].focus() };
                            }}
                            label='Interview Date'
                            id='txtFromDate'
                            selected={recipe.FromDate === "" ? "" : new Date(recipe.FromDate)}
                            placeholder="Interview Date"
                          //  value={recipe.FromDate}
                            onChange={(e) => HandleChange('FromDate', dateFormate(e))}
                        // required
                        //  error={errors.FromDate}
                        />
                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { handleSubmit() };
                            }}
                            label='DOJ'
                            id='txtToDate'
                            selected={recipe.ToDate === "" ? "" : new Date(recipe.ToDate)}
                            placeholder="DOJ"
                           // value={recipe.ToDate}
                            onChange={(e) => HandleChange('ToDate', dateFormate(e))}
                        //   required
                        //  error={errors.ToDate}
                        />

                        <FormButton
                            SaveText='Search'
                           // exportText={'Reject'}
                           // exportButtonIcon='fa-close'
                          //  handleExport={HandleRejectCandidate}
                            Reject={true }
                            Export={false}
                            NameForModal={candidateId.length > 0 ? 'RejectReasonModal' : ''}
                            handleReject={() => toast.error('Please select at least one candidate to Reject.!')}
                            Next={true}
                            saveButtonIcon='fa-search'
                            nextButtonText='Verify'
                            nextButtonIcon='fa-check'
                            nextButtonColor='btn-success'
                            handleNext={handleVerify}
                            handleSubmit={handleSubmit}
                            // handleExport={() => handleDownloadExcel(API_SERVER + 'api/Candidate/SearchVerifyCandidate', ExportRequest, ' Verify Candidate')}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>
            {/* Modal for Reject Candidate  */ }
            <div className="modal fade" id="RejectReasonModal" tabIndex="-1" aria-labelledby="RejectReasonModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="RejectReasonModalLabel">Reject Reason</h1>
                            <button type="button" data-bs-dismiss="modal" aria-label="Close" className="btn btn-rounded btn-sm btn-outline-danger" onClick={() => setReason('')} ><i className="fa-solid fa-close"></i></button>

                        </div>
                        <div className="modal-body">
                            <InputBox
                                inputrefs={inputrefs}
                                label='Reason'
                                placeholder='Reason'
                                divclassname='mb-2'
                                id='txtCandidate'
                                maxLength='100'
                                value={Reason}
                                onChange={(e) => { setReason(e.target.value) }}
                                required
                                error={Reason || Reason.lenght<=0 ? "Please Fill Rejected Reason" : ''}
                            />
                         
                        </div>
                        <div className="modal-footer">

                            <button type="button" className="btn btn-rounded btn-sm btn-success" onClick={HandleRejectCandidate }> <span className="text-white me-2"><i className="fa-solid fa-check"></i></span>Reject </button>
                            <button type="button" className="btn btn-rounded btn-sm btn-danger" data-bs-dismiss="modal" aria-label="Close" onClick={()=>setReason('') } > <span className="text-white me-2"><i className="fa-solid fa-arrow-rotate-left"></i></span>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

           
            {/* Table Component start */}
            {showTable &&

                <Table
                    Filter={false}
                    Token={TokenData}
                    PageName='VerifyCandidate'
                    handleCheckData={handleCheckData}
                   // handleFilter={() => {
                   //     // getFormData();
                   //     handleSubmit();
                   //     setFilterData({
                   //         ...filterData,
                   //     });
                // }}
                handleRedirect={handleRedirectToCandidateMaster} 
                pathName={pathName }
                    tableData={result}
                    loading={loading}
                   // filterData={filterData}
                //    setFilterData={setFilterData}
                    currentPageNo={currentPageNo}
                    handleChange1={handleChange1}
                //    handleSorting={handleSorting}
                //    sortConfig={sortConfig}
                />
            }
            {/* Table Component End*/}


            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}

        </>
    )
}

export default VerifyCandidate;