import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, AutoCompleteBox, Switch, DateBox, CheckBoxInput } from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
import { useNavigate } from 'react-router-dom'
import * as XLSX from 'xlsx';

//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER, NotificationCount, handleDownloadExcel, initializeFilterData } from '../../Utils';

//services
import { getCommonData } from '../../apiservices/CommonServices';
import { post } from '../../apiservices/service';
import { GetNoificaitonCount, Token, dateFormate, number } from '../Common/Common';
import { ExportError } from '../commoncomponents/Import';
import { useRecoilState } from 'recoil';

function ConvertToEmployee() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();


    //Get PathName
    let url = window.location.pathname.split("/")
    const pathName = url[url.length - 1]


    //token Data
    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({
        //  CandidateID: '0',
        CandidateCode: '',
        CandidateName: '',
        FatherName: '',
        DOB: '',
        MobileNo: '',
        FromDate: '',
        ToDate: '',
        PageType: "ConvertToEmployee"


    })
    const navigate = useNavigate()
    const [candidateId, setCandidateId] = useState([])
    const [showTable, setShowTable] = useState(false)
    const [errors, setErrors] = useState({});
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)

    const [close, setClose] = useState(false);
    const [checkboxId, setCheckboxId] = useState(false);
    const [successErrorCount, setSuccessErrorCount] = useState('');

    const [isUpdate, setIsUpdate] = useState(false)
    const [excelData, setExcelData] = useState('')
    const [notificationData, setNotificationData] = useRecoilState(NotificationCount)

    const DownloadExcelConvetedEmploye = (FileName, res,) => {

        if (res.Status === 'SUCCESS') {
            const workbook = XLSX.utils.book_new();
            let worksheet = XLSX.utils.aoa_to_sheet([
                res?.HeadersValue
                ,
                ...res?.Data?.map((records) =>
                    res.HeadersValue?.map((data, i) =>
                        records[data]
                    )
                ),
            ]);
            XLSX.utils.book_append_sheet(workbook, worksheet, FileName);
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${FileName}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            return
        }
    }



    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //validation handler
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "CandidateCode", msg: 'Please Enter Candidate Code.' },
            { field: "CandidateName", msg: 'Please Enter Candidate Name.' },
            { field: "FatherName", msg: 'Please Enter Father Name.' },
            { field: "DOB", msg: 'Please Enter DOB.' },
            { field: "MobileNo", msg: 'Please Enter Mobile No.' },
            { field: "EmailID", msg: 'Please Enter Email ID.' },
            { field: "FromDate", msg: 'Please Select From Date.' },
            { field: "ToDate", msg: 'Please Enter ToDate.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });


        return isValid;
    }

    //Submit Handler
    const handleSubmit = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

         setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/Candidate/SearchVerifyCandidate`, { ...TokenData, ...temp, ...sortConfig, ...filterData, ...recipe, PageType: "ConvertToEmployee" }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                  //  toast.error(res.Message)
                    setLoading(false)
                    setResult({ ...res })
                    //  console.log(recipe, 'if')
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    //toast.success(res.Message)
                    setResult({ ...res })
                    setShowTable(true)
                    setCandidateId([])
                    //getFormData()
                    // handleClear()
                    //console.log(recipe, 'else')

                }
            }
        });

    }

    const handleChange1 = (event, value) => {
        // getFormData(value);
        handleSubmit(value);
    };

    //Handle Check Box
    const handleCheckData = (Id, e, item, index) => {
        setCheckboxId(Id)
        if (e === true) {
            setCandidateId((preValue) => [...preValue, `${Id}|${item.DOJ}`])
        } else if (e === false) {
            const updatedIds = candidateId.filter(ele => ele.split("|")[0] !== Id)
            setCandidateId(updatedIds)
        }
    }

    // handle Convert Candidate To Employee
    const handleConvertCandidateToEmployee = () => {
        if (candidateId.length <= 0) {
            // console.log(candidateId, '111111111111',)
            toast.error('Please select at least one candidate to convert.!')
        } else {

            //console.log(candidateId, '00000000000000')
           
            post(`${API_SERVER}api/Candidate/ConvertCandidateToEmployee`, { ...TokenData, CandidateID: (candidateId.length > 0 ? `${candidateId.join(",")}` : '') }, (res) => {
                if (res && res.Status) {
                    if (res.Status === "ERROR") {
                        toast.error(res.Message);
                        setLoading(false);
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message);
                        sessionExpired();
                    } else if (res.Status === "UNAUTHORIZED") {
                        toast.error(res.Message);
                        setLoading(false);
                    } else {
                        setClose(true);
                       // console.log('00000000000000', document.getElementById(`row${checkboxId}checkBox`).checked, checkboxId)
                        // toast.success(res.Message);
                        // DownloadExcelConvetedEmploye('ConvertedEmployee', res)
                        //  setSuccessErrorCount(res);
                        setExcelData(res);
                        setLoading(false);
                        setShowTable(false);
                        setCandidateId([]);
                        handleSubmit();
                        GetNoificaitonCount(notificationData, setNotificationData, TokenData, sessionExpired)
                        document.getElementById(`row${checkboxId}checkBox`).checked = false;
                    }
                }
            });
        }
    }



    //Handle Edit
    const handleEdit = (Id, CandidateCode) => {
        if (CandidateCode) {
            navigate(`/Candidate/CandidateMaster?CandidateCode=${CandidateCode}`)
        }
    }


    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            //  CandidateID: '0',
            CandidateCode: '',
            CandidateName: '',
            FatherName: '',
            DOB: '',
            MobileNo: '',
            FromDate: '',
            ToDate: '',
            PageType: "ConvertToEmployee"
        }))
        setErrors({})
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        setShowTable(false)
        setCandidateId([])
        setClose(false)
        setExcelData('')
        handleSubmit();
        if (checkboxId) document.getElementById(`row${checkboxId}checkBox`).checked = false;
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };




    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1',
        PageType: "ConvertToEmployee"

    }
    //End Export functionality

    useEffect(() => {
        inputrefs.current['txtCandidateCode'].focus()
        document.title = 'Pay Plus: Convert To Employee'

        //    getFormData()
        handleSubmit()
    }, [sortConfig, isUpdate])

    // console.log(tableDOJ,'DOJ')

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0"> Convert To Employee</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtName'].focus() };
                            }}
                            label='Candidate Code'
                            id='txtCandidateCode'
                            maxLength='50'
                            placeholder="Candidate Code"
                            value={recipe.CandidateCode}
                            onChange={(e) => { HandleChange('CandidateCode', (e.target.value)) }}
                        //required
                        //  error={errors.CandidateCode}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtFatherName'].focus() };
                            }}
                            label='Candidate Name'
                            id='txtName'
                            maxLength='50'
                            placeholder="Candidate Name"
                            value={recipe.CandidateName}
                            onChange={(e) => { HandleChange('CandidateName', (e.target.value)) }}
                        //  required
                        //  error={errors.CandidateName}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtDOB'].focus() };
                            }}
                            label={`Father's Name`}
                            id='txtFatherName'
                            maxLength='50'
                            placeholder={`Father's Name`}
                            value={recipe.FatherName}
                            onChange={(e) => { HandleChange('FatherName', (e.target.value)) }}
                        //   required
                        //  error={errors.FatherName}
                        />

                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtMobileNo'].focus() };
                            }}
                            label='DOB'
                            id='txtDOB'
                            selected={recipe.DOB === "" ? "" : new Date(recipe.DOB)}
                            placeholder="DOB"
                            //  value={recipe.DOB}
                            onChange={(e) => HandleChange('DOB', dateFormate(e))}
                        //   required
                        //     error={errors.DOB}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtEmailID'].focus() };
                            }}
                            label='Mobile No.'
                            id='txtMobileNo'
                            maxLength='10'
                            placeholder="Mobile No."
                            //  required
                            //   error={errors.MobileNo}
                            value={recipe.MobileNo} onChange={(e) => { HandleChange('MobileNo', number(e.target.value)) }}
                        />

                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtToDate'].focus() };
                            }}
                            label='From Date'
                            id='txtFromDate'
                            selected={recipe.FromDate === "" ? "" : new Date(recipe.FromDate)}
                            placeholder="From Date"
                            //  value={recipe.FromDate}
                            onChange={(e) => HandleChange('FromDate', dateFormate(e))}
                        // required
                        //  error={errors.FromDate}
                        />
                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { handleSubmit() };
                            }}
                            label='To Date'
                            id='txtToDate'
                            selected={recipe.ToDate === "" ? "" : new Date(recipe.ToDate)}
                            placeholder="To Date"
                            // value={recipe.ToDate}
                            onChange={(e) => HandleChange('ToDate', dateFormate(e))}
                        //   required
                        //  error={errors.ToDate}
                        />

                        <FormButton
                            SaveText='Search'
                            Next={true}
                            Export={false}
                            saveButtonIcon='fa-search'
                            nextButtonText='Convert'
                            nextButtonIcon='fa-check'
                            nextButtonColor='btn-success'
                            handleNext={handleConvertCandidateToEmployee}
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/Candidate/SearchVerifyCandidate', ExportRequest, 'Convert To Employee')}
                            handleClear={handleClear}
                        />


                        {close && <div className='row '>
                            <div className='ms-2 border rounded d-flex justify-content-between align-items-center ' style={{ background: '#95d295b0' }}>
                                <div className='mx-0 my-2 w-50'>
                                    <span className=" d-flex justify-content-between">
                                        <span className='text-success ' >
                                            <i className='fa fa-check'></i> Total Data :

                                            <span
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    if (excelData.TotalException !== '0' && excelData.Status === 'SUCCESS' && excelData.TotalException !== "") {
                                                        DownloadExcelConvetedEmploye("Conveted Employee Excel", excelData)
                                                    }
                                                }}>{excelData.TotalData}</span>
                                        </span>
                                        <span className='text-success' >Total Merge Data : <span
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                if (excelData.TotalException === '0' && excelData.Status === 'SUCCESS' && excelData.TotalException !== "") {
                                                    DownloadExcelConvetedEmploye("Conveted Employee Excel", excelData)
                                                }
                                            }}>{excelData.TotalMergeData}</span></span>
                                        <span className='text-danger' >
                                            <i className='fa fa-question'></i> Total Exception : <span
                                                className=''
                                                onClick={() => {
                                                    if (excelData.TotalException !== '0' && excelData.Status === 'SUCCESS' && excelData.TotalException !== "") {
                                                        DownloadExcelConvetedEmploye("Conveted Employee Excel", excelData)
                                                    }
                                                }}
                                                style={{ cursor: "pointer" }}>{excelData.TotalException}</span></span>
                                    </span>
                                </div>
                                <button onClick={() => setClose(false)} type='button' className='btn btn-outline-danger m-1 min-vw-0 p-1 px-2 rounded' style={{ minWidth: '0' }}><i className='fa fa-close m-0 p-0'></i></button>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>

            {/* Table Component start
            {showTable &&
             */}
            <Table
                Filter={false}
                pathName={pathName}
                Token={TokenData}
                PageName='ConvertToEmployee'
                handleCheckData={handleCheckData}
                //     handleEdit={handleEdit}
                handleFilter={() => {
                    // getFormData();
                    handleSubmit();
                    setFilterData({
                        ...filterData,
                    });
                }}
                tableData={result}
                //  loading={loading}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
            {/*    }
          Table Component End*/}


            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}

        </>
    )
}

export default ConvertToEmployee;