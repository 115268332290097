import { useEffect, useState, useRef } from "react";
import { Link, Navigate } from 'react-router-dom';
//react toast 
import { toast } from 'react-toastify';
//custom images
import sipllogo from '../../assests/images/sipllogo.png';
//import loginpage from '../../assests/images/loginpage.png';
//import logo from '../../assests/images/logo.png';
import PayPlus from '../../assests/images/PayPlus.png'
//loder
import BlurLoader from "../Loder/BlurLoader";
//services
import { useAuth } from "../../context/auth";
import { post } from "../../apiservices/service";
import { API_SERVER } from "../../Utils";
import { Cookies } from 'react-cookie';

const cookies = new Cookies();
const Login = () => {
    const inputrefs = useRef([]);
    const { login, user } = useAuth();

    //initial state
    const [recipe, setRecipe] = useState({ UserCode: '', Password: '', CompanyCode:'' })
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!user) {
            inputrefs.current['txtCompanyCode'].focus();
            document.title = 'Pay Plus : Login'
        }
    }, [loading, user])


    //Recipe Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //validation handler
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "CompanyCode", msg: 'Please Enter Company Code.' },
            { field: "UserCode", msg: 'Please Enter User ID.' },
            { field: "Password", msg: 'Please Enter Password.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }

    //submit button Handler
    const HandleSubmit = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/UserLogin/Login`, { ...recipe, AuthToken: process.env.REACT_APP_SECRET_KEY }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                       // setRecipe(recipe => ({ ...recipe, UserCode: '', Password: '', CompanyCode:'' }))
                        setLoading(false)
                    } else {
                        login(res)
                    }
                }
            });
        }
    }

    const HandleForgatePassword = () => {
        if (!recipe.UserCode) {
            setErrors({ ...errors, UserCode: 'Please Enter User ID.' })
        } else {

            setLoading(true)
            post(`${API_SERVER}api/UserLogin/ForgetPassword`, { UserCode: recipe.UserCode, AuthToken: process.env.REACT_APP_SECRET_KEY }, (res) => {
                // console.log(res);
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        setRecipe(recipe => ({ ...recipe, UserCode: "" }))
                        setLoading(false)
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        setErrors({})
                    }
                }
            });
        }

    }

    useEffect(() => {
        cookies.remove('user', { path: '/' });
    }, [])

    return (
        <>
            {/* Login Form Start */}
            <div className='login-page login-bg'>
                <div className="container-fluid bg-login vh-100 d-flex justify-content-center align-items-center">
                    <div className="col-sm-3 px-4 py-3 main-login text-black rounded">
                        <center>
                            <img className="img1" src={PayPlus} alt='login_image' />
                            {/*<h4 className='mt-1 text-danger'>Admin Login</h4>*/}
                        </center>
                        <div className="">
                            <div className="col-lg-3 col-md-4 col-sm-6" style={{ width: '100%' }}>
                                <div className="form-group text-start mb-3" >
                                    <label className="form-label mb-1">Company Code<span className='required'>*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        ref={ref => (inputrefs.current['txtCompanyCode'] = ref)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtUserId'].focus() };
                                        }}
                                        placeholder="Company Code"
                                        name='CompanyCode'
                                        value={recipe.CompanyCode}
                                        onChange={(e) => HandleChange('CompanyCode', e.target.value)}
                                    />

                                    {errors.CompanyCode && <div className="error-msg">{errors.CompanyCode}</div>}

                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6" style={{ width: '100%' }}>
                                <div className="form-group text-start mb-3" >
                                    <label className="form-label mb-1">User ID <span className='required'>*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        ref={ref => (inputrefs.current['txtUserId'] = ref)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtPassword'].focus() };
                                        }}
                                        placeholder="User ID"
                                        name='UserCode'
                                        value={recipe.UserCode}
                                        onChange={(e) => HandleChange('UserCode', e.target.value)}
                                    />

                                    {errors.UserCode && <div className="error-msg">{errors.UserCode}</div>}

                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6" style={{ width: '100%' }}>
                                <div className="form-group text-start mb-3 mt-2">
                                    <label className="form-label mb-1">Password <span className='required'>*</span></label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="floatingPassword"
                                        ref={ref => (inputrefs.current['txtPassword'] = ref)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['btnSubmit'].focus() };
                                        }}
                                        placeholder="Password"
                                        name='Password'
                                        value={recipe.Password}
                                        onChange={(e) => HandleChange('Password', e.target.value)}
                                    />

                                    {errors.Password && <div className="error-msg">{errors.Password}</div>}

                                </div>
                            </div>
                            <div className="py-2 loading">
                                <button
                                    ref={ref => (inputrefs.current['btnSubmit'] = ref)}
                                    className='btn-primary btn w-100'
                                    onClick={HandleSubmit}
                                >
                                    Sign in
                                </button>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between login-trouble mb-3'>
                            <Link onClick={HandleForgatePassword} >Forgot Password ?</Link>
                        </div>
                        <div className='sagar_logo text-center d-flex flex-column'>
                            <img src={sipllogo} alt='sagar logo' />
                            <Link to="https://www.sagarinfotech.com/" className='small' target='_blank'>Sagar Informatics Pvt. Ltd.</Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* Login Form End */}

            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}
        </>

    )
}
//}

export default Login;