
import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, AutoCompleteBoxWithLabel, DateBox, MonthBox, YearBox, SelectInputBox } from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
//loder
import BlurLoader from "../Loder/BlurLoader";
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { Token, currentYear, dateFormate, number, numberwithDot } from '../Common/Common';

function ESICReport() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();
    //token Data
    //const TokenData = {
    //    AuthToken: process.env.REACT_APP_SECRET_KEY,
    //    LoginToken: user.loginsessionID,
    //    UserID: user.userID,
    //    CompanyID: user.companyID
    //}
    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({
        //  loanTypeID: "0",
        branch: '',
        // employee: '',
        //   company: '',
        month: '',
        year: '',
    })

    const [loading, setLoading] = useState(false);
    const [branchAllData, setBranchAllData] = useState([])
    const [companyAllData, setCompanyAllData] = useState([])
    const [errors, setErrors] = useState({});



    const [yearData, setYearData] = useState([]);
    const [monthData, setMonthData] = useState([]);

    //Get Month Data
    const GetMonthData = () => {
        setMonthData([])
        let temp = {
            Type: "GetMonth",
            Prefix: '',

        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setMonthData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setMonthData(data)
                }
            }
        });
    };


    //Get Year Data
    const GetYearData = () => {
        setYearData([])
        let temp = {
            Type: "GetYear",
            Prefix: '',

        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setYearData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setYearData(data)
                }
            }
        });
    };

    //Get company Data for AutoComplete
    const GetCompanyData = (value) => {
        setCompanyAllData([])
        let temp = {
            Type: "GetCompany",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        //  setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setCompanyAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setCompanyAllData(data)
                }
            }
        });
    }


    //Get branch Data for AutoComplete
    const GetBranchData = (value) => {
        setBranchAllData([])
        let temp = {
            Type: "GetBranch",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        //   setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setBranchAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setBranchAllData(data)
                }
            }
        });
    }


   //Handle Submit
    const handleSubmit = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/Report/PrintESICReport`, { ...TokenData, ...recipe, /*year: recipe.year ? currentYear(recipe.year) : '', month: recipe.month ? `${(recipe.month).getMonth() + 1}` : '',*/ }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        setLoading(false)
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "UNAUTHORIZED") {
                        toast.error(res.Message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        let base64PDF = res && res.base64PDF
                        window.open(`/PdfView/#data:application/pdf;base64,${base64PDF}`, '_blank')
                    }
                }
            });
        }
    }






    // console.log(currentYear(recipe.year))

    useEffect(() => {
        inputrefs.current['txtBranch'].focus();
        // inputrefs.current['txtCompany'].focus();
        document.title = 'Pay Plus : ESIC Report';
        // GetCompanyData();
        GetBranchData();
        GetMonthData();
        GetYearData();
    }, [])

    //useEffect(() => {

    //})

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "branch", msg: 'Please Enter Branch.' },
            { field: "month", msg: 'Please Enter Month.' },
            { field: "year", msg: 'Please Enter Year.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {

                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            branch: '',
            // employee: '',
            // company: '',
            month: '',
            year: '',
        }))
        setErrors({})
    }

    let CompanyData = []
    for (let i = 0; i < companyAllData.length; i++) {
        let mydata = companyAllData[i]?.values
        CompanyData.push(mydata)
    };

    let BranchData = []
    for (let i = 0; i < branchAllData.length; i++) {
        let mydata = branchAllData[i]?.values
        BranchData.push(mydata)
    };


    //Start Export functionality
    let ExportRequest = {
        ...TokenData,
        ...recipe,
        Type: 'E',
        PageIndex: '1',
        //month: recipe.month ? `${(recipe.month).getMonth() + 1}` : '',
        //year: recipe.year ? currentYear(recipe.year) : '',

    }
    //End Export functionality


    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">ESIC Report</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        {/*<AutoCompleteBox*/}
                        {/*    inputrefs={inputrefs}*/}
                        {/*    onKeyDown={(e) => {*/}
                        {/*        if (e.key === 'Enter') { inputrefs.current['txtBranch'].focus() };*/}
                        {/*    }}*/}
                        {/*    id='txtCompany'*/}
                        {/*    label='Company'*/}
                        {/*    placeholder="Company"*/}
                        {/*    maxLength='50'*/}
                        {/*    options={CompanyData}*/}
                        {/*    key='txtCompany'*/}
                        {/*    //  required={true}*/}
                        {/*    //error={errors.company}*/}
                        {/*    inputValue={recipe.company ? recipe.company : ''}*/}
                        {/*    onInputChange={(event, newInputValue) => {*/}
                        {/*        GetCompanyData(newInputValue)*/}
                        {/*        HandleChange('company', newInputValue)*/}

                        {/*    }}*/}
                        {/*/>*/}
                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtMonth'].focus() };
                            }}
                            label='Branch'
                            placeholder="Branch"
                            maxLength='50'
                            options={BranchData}
                            id='txtBranch'
                            key='txtBranch'
                            required={true}
                            error={errors.branch}
                            inputValue={recipe.branch ? recipe.branch : ''}
                            onInputChange={(event, newInputValue) => {
                                GetBranchData(newInputValue)
                                HandleChange('branch', newInputValue)

                            }}
                        />


                        <SelectInputBox
                            label='Month'
                            inputrefs={inputrefs}
                            id='txtMonth'
                            value={recipe.month}
                            required
                            error={errors.month}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtYear'].focus() };
                            }}
                            onChange={(e) => HandleChange('month', e.target.value)}
                            children={<>
                                <option value="">--Month--</option>
                                {monthData.map((opt, index) => <option key={index} value={opt.code} >{opt.name}</option>)}
                            </>}
                        />

                        <SelectInputBox
                            label='Year'
                            inputrefs={inputrefs}
                            id="txtYear"
                            value={recipe.year}
                            required
                            error={errors.year}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { handleSubmit() };
                            }}
                            onChange={(e) => HandleChange('year', e.target.value)}
                            children={<>
                                <option value="">--Year--</option>
                                {yearData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}
                            </>}
                        />
                        {/*<MonthBox*/}
                        {/*    inputrefs={inputrefs}*/}
                        {/*    onKeyDown={(e) => {*/}
                        {/*        if (e.key === 'Enter') { inputrefs.current['txtYear'].focus() };*/}
                        {/*    }}*/}
                        {/*    label='Month'*/}
                        {/*    // labelClass='text-nowrap'*/}
                        {/*    //customclass='w-25'*/}
                        {/*    // divclassname='col-lg-6'*/}
                        {/*    id='txtMonth'*/}
                        {/*    selected={recipe.month ? recipe.month : ""}*/}
                        {/*    placeholder="Month"*/}
                        {/*    onChange={(e) => { HandleChange('month', e) }}*/}
                        {/*    required*/}
                        {/*    error={errors.month}*/}
                        {/*/>*/}
                        {/*<YearBox*/}
                        {/*    inputrefs={inputrefs}*/}
                        {/*    onKeyDown={(e) => {*/}
                        {/*        if (e.key === 'Enter') { handleSubmit() };*/}
                        {/*    }}*/}
                        {/*    label='Year'*/}
                        {/*    // labelClass='text-nowrap'*/}
                        {/*    // divclassname='col-lg-4'*/}
                        {/*    id='txtYear'*/}
                        {/*    selected={recipe.year ? recipe.year : ""}*/}
                        {/*    placeholder="Year"*/}
                        {/*    onChange={(e) => { HandleChange('year', e) }}*/}
                        {/*    required*/}
                        {/*    error={errors.year}*/}
                        {/*/>*/}



                        <FormButton
                            saveButtonIcon='fa-search'
                            SaveText='Search'
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/Report/ESICReport', ExportRequest, 'ESIC Report', sessionExpired)}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>

            {loading && < BlurLoader />}
        </>
    )
}

export default ESICReport
