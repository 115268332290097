import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../context/auth';
import { CheckBoxInput, FormButton, InputBox, MonthBox, SelectInputBox, YearBox } from "../commoncomponents/InputBox";
import { Table } from '../commoncomponents/Table';
//loder
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, handleDownloadExcel } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { currentMonth, currentYear, number, numberwithDot } from '../Common/Common';
//import styles from './DatePickerCSS.module.css'

const EmployeeSalaryStructure = ({ TokenData, sessionExpired, employee, setActiveTab, activeTab, handleTabClick, EmployeeCodeByUrl, EmployeeInEditTime }) => {
    const inputrefs = useRef([]);
    const { user } = useAuth();

    //initial state
    const [recipe, setRecipe] = useState({
        // CandidateID: '0',
        //  TotalAmount: '',
        DeductionTotal: '',
        GrossTotal: '',
        TotalSalaryAmount: '',
        AllowanceList: [],
        DeductionList: [],
        EffectiveDate: "",
        EmpCode: EmployeeCodeByUrl ? EmployeeCodeByUrl : employee ? employee?.empCode : '0'

    })

    const [valueChanged, setvalueChanged] = useState(false)
    const [errors, setErrors] = useState({});
    const [result, setResult] = useState({});
    const [history, setHistory] = useState({});
    const [historyDetailData, setHistoryDetailData] = useState({});
    const [showHistory, setShowHistory] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [currentPageNoHistoryTable, setCurrentPageNoHistoryTable] = useState(1);
    const [currentPageNoHistoryDetailsTable, setCurrentPageNoHistoryDetailsTable] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [sortConfigHistoryTable, setSortConfigHistoryTable] = useState({ SortColumn: null, SortOrder: null });
    const [sortConfigHistoryDetailsTable, setSortConfigHistoryDetailsTable] = useState({ SortColumn: null, SortOrder: null });
    //  const [filterData, setFilterData] = useState(initializeFilterData);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isCalculate, setIsCalculate] = useState(false);
    const [isPF, setIsPF] = useState(false);
    const [isOT, setIsOT] = useState(false);
    const [isGross, setIsGross] = useState(false);
    const [isESI, setIsESI] = useState(false);
    const [allowamceTableData, setAllowanceTableData] = useState(false);
     const [allTableData, setAllTableData] = useState(null);
    const [ColoredRow, setColoredRow] = useState('');
    const [yearData, setYearData] = useState([]);
    const [monthData, setMonthData] = useState([]);
    const [tempEffectiveDate, setTempEffectiveDate] = useState({
        EffectiveMonth: new Date(),
        EffectiveYear: new Date(),
    })

    const [recipTable3, setRecipTable3] = useState({
        employerContribution: '',
        ctc: '',
        nth: ''
    })

    const candidateID = EmployeeCodeByUrl ? EmployeeInEditTime?.EmpID : employee ? employee?.empID : '0'

    const tableData = () => {
        const temp = {
            candidateID: candidateID,
            Type: 'Employee',
            EffectiveDate: tempEffectiveDate.EffectiveMonth && tempEffectiveDate.EffectiveYear ? `01-${currentMonth(tempEffectiveDate.EffectiveMonth, 'short')}-${currentYear(tempEffectiveDate.EffectiveYear)}` : "",
        }
        post(`${API_SERVER}api/Candidate/GetAllowanceAndDeductionDetails`, { ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    //toast.error(res.message)
                    setLoading(false)
                } else if (res.status === "EXPIRED") {
                    toast.error(res.message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    //toast.success(res.message)
                    let data = res.data
                    setAllTableData(data)
                    setRecipe({
                        ...recipe, DeductionTotal: data.deductionTotal, GrossTotal: data.grossTotal, TotalSalaryAmount: data.totalSalaryAmount,
                        DeductionList: data.deductionList, AllowanceList: data.allowanceList
                    })
                    setRecipTable3({ ...recipTable3, nth: data.nth, ctc: data.ctc, employerContribution: data.employerContribution })
                }
            }
        })
    };

    const HandleBlur = () => {
        if (!recipe.TotalAmount || recipe.TotalAmount === '0') {
            return toast.error('Please Fill Total Amount')
        } else {
            const temp = {
                TotalAmount: recipe.TotalAmount,
                EmpCode: recipe.EmpCode,
            };
            post(`${API_SERVER}api/Candidate/GetCandidateSalaryStructureCalculation`, { ...TokenData, ...temp, }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        setLoading(false)
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "UNAUTHORIZED") {
                        toast.error(res.Message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        setvalueChanged(true)
                        let data = res.data
                        setAllTableData(data)
                        setRecipe({ ...recipe, DeductionTotal: data.deductionTotal, GrossTotal: data.grossTotal, TotalSalaryAmount: data.totalSalaryAmount, DeductionList: data.deductionList, AllowanceList: data.allowanceList })
                        setRecipTable3({ ...recipTable3, nth: data.nth, ctc: data.ctc, employerContribution: data.employerContribution })
                        // handleSalaryCalculation();
                        // console.log(data, 'else')
                    }
                }
            })
        }
    }

    const handleHighlight = (id) => {
        setColoredRow(id)
    };
    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s",
            EmpCode: EmployeeCodeByUrl ? EmployeeCodeByUrl : employee ? employee?.empCode : '0'
        }
        post(`${API_SERVER}api/Employee/SalaryStructureReport`, { ...TokenData, ...temp, ...sortConfig,/* ...filterData*/ }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }

    const handleChange1 = (event, value) => {
        getFormData(value);
    };


    const handleChangeHistoryTable = (event, value) => {
        handleHistory(value);
    };

    //const handleChangeHistoryDetailsTable = (event, value) => {
    //    handleViewHistoryDetails(value);
    //};



    //Change Handler
    const HandleChange = (prop, value, index, type) => {
        //if (prop === 'PF') {
        //    setIsPF(value)
        //} else if (prop === 'OT') {
        //    setIsOT(value)
        //} else if (prop === 'ESI') {
        //    setIsESI(value)
        //} else if (prop === 'Gross') {
        //    setIsGross(value)
        //}
        //setRecipe(recipe => ({ ...recipe, [prop]: value }))
        //console.log(prop)
        if (type === 'allowanceList') {
            let list = [...allTableData.allowanceList];
            list[index] = { ...list[index], [prop]: value };
            setAllTableData({ ...allTableData, allowanceList: list })
            setRecipe({ ...recipe, AllowanceList: list })
            //console.log(prop, value, list)
            //setRecipe({ ...recipe, [prop]: value })

            //setAllTableData({ ...allTableData, allowanceList: list }, () => {
            //    setRecipe({ ...recipe, AllowanceList: list }, () => {
            //        console.log(prop, value, list);
            //        setvalueChanged(true);
            //        handleSalaryCalculation();
            //    });
            //});

        } else {
            let list = [...allTableData.deductionList];
            list[index] = { ...list[index], [prop]: value };
            //console.log(list);
            setRecipe({ ...recipe, DeductionList: list })
            setAllTableData({ ...allTableData, deductionList: list })
        }
        setvalueChanged(true)
        // handleSalaryCalculation()

    };
    const HandleChangeForInput = (prop, value) => {
        setRecipe({ ...recipe, [prop]: value })
        setvalueChanged(true)
        // handleSalaryCalculation()
    }


    //validation handler
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "EffectiveMonth", msg: 'Please Enter Effective Month.' },
            { field: "EffectiveYear", msg: 'Please Enter Effective Year.' },


        ]
        let validationRecipe = [

            //  { field: "DeductionTotal", msg: 'Please Enter Deduction Total.' },
            { field: "GrossTotal", msg: 'Please Enter Gross Total.' },
            { field: "TotalSalaryAmount", msg: 'Please Enter Total Salary Amount.' },

        ]


        validation.map(item => {
            if (!tempEffectiveDate[item.field] || tempEffectiveDate[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        validationRecipe.map(item => {
            if (!recipe[item.field] || recipe[item.field] <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;

            }
            return item;
        });


        return isValid;
    }

    //Submit Handler
    const handleSubmit = () => {
        const temp = {
            EffectiveDate: `01-${currentMonth(tempEffectiveDate.EffectiveMonth, 'short').substring(0, 3)}-${currentYear(tempEffectiveDate.EffectiveYear)}`,
            EmpCode: EmployeeCodeByUrl ? EmployeeCodeByUrl : employee ? employee?.empCode : '0'
        }
        //  console.log(tempEffectiveDate,'sdkjfg')
        if (Validate()) {
            //   console.log(recipe, 'function',)

            setLoading(true)
            post(`${API_SERVER}api/Employee/InsertEmployeeSalaryStructure`, { ...TokenData, ...recipe, ...temp }, (res) => {

                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        setLoading(false)
                        inputrefs.current[res.focus].focus();
                        //     console.log(recipe, 'if')
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "UNAUTHORIZED") {
                        toast.error(res.Message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        handleTabClick("EmployeeFamilyDetails")
                        setActiveTab("EmployeeFamilyDetails")
                        setShowHistory(false)
                        // getFormData()
                        // handleClear()
                        //       console.log(recipe, 'else')
                    }
                }
            });
        }
    }

    const handleSalaryCalculation = () => {
        const temp = {
            EffectiveDate: `01-${currentMonth(tempEffectiveDate.EffectiveMonth, 'short')}-${currentYear(tempEffectiveDate.EffectiveYear)}`,
            EmpCode: EmployeeCodeByUrl ? EmployeeCodeByUrl : employee ? employee?.empCode : '0'
        }
        post(`${API_SERVER}api/Employee/GetSalaryStructureCalculation`, { ...TokenData, ...recipe, DeductionList: allTableData?.deductionList, AllowanceList: allTableData?.allowanceList, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    //toast.success(res.message)
                    let data = res.data
                    setAllTableData(data)
                    setRecipe({ ...recipe, DeductionTotal: data.deductionTotal, GrossTotal: data.grossTotal, TotalSalaryAmount: data.totalSalaryAmount, DeductionList: data.deductionList, AllowanceList: data.allowanceList })
                    setRecipTable3({ ...recipTable3, nth: data.nth, ctc: data.ctc, employerContribution: data.employerContribution })
                    //console.log(data, 'else')
                    setvalueChanged(false)
                }
            }
        })
    }



    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            CandidateID: '0',
            CandidateName: '',
            FatherName: '',
            DOB: '',
            MobileNo: '',
            EmailID: '',
            Department: '',
            Designation: '',
            InterviewDate: '',
            DOJ: '',
            Gender: '',
        }))
        setErrors({})
        //   setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        setShowHistory(false)
    }

    // get Histroy data
    const handleHistory = (CurrentPage) => {

        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNoHistoryTable(CurrentPage);
        }
        else {
            setCurrentPageNoHistoryTable(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s",
            EmpCode: EmployeeCodeByUrl ? EmployeeCodeByUrl : employee ? employee?.empCode : '0'
        }
        setLoading(true)
        post(`${API_SERVER}api/Employee/GetSalaryHistory`, { ...TokenData, ...temp, ...sortConfigHistoryTable, /*...filterData*/ }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setHistory({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    //   console.log(res)
                    setLoading(false)
                    setHistory({ ...res })
                    setShowHistory(true)

                }
            }
        });
    }

    // get history veiw data
    const handleViewHistoryDetails = (ID, CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNoHistoryDetailsTable(CurrentPage);
        }
        else {
            setCurrentPageNoHistoryDetailsTable(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s",
            EmpCode: EmployeeCodeByUrl ? EmployeeCodeByUrl : employee ? employee?.empCode : '0'
        }
        setLoading(true)
        post(`${API_SERVER}api/Employee/GetSalaryHistoryDetail`, { ...TokenData, ...temp, ...sortConfigHistoryDetailsTable, /*...filterData,*/ SalaryID: ID }, (res) => {
            //    console.log(res)
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setHistoryDetailData({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    //  console.log(res)
                    setLoading(false)
                    setHistoryDetailData({ ...res })
                    //    setShowHistory(true)

                }
            }
        });
    }

    useEffect(() => {
        if (activeTab === 'EmployeeSalaryStructure') {
            if (showHistory) handleHistory();
        }
    }, [sortConfigHistoryTable, activeTab])



    //Start Export functionality
    let ExportRequest = {
        //  ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1',
        EmpCode: EmployeeCodeByUrl ? EmployeeCodeByUrl : employee ? employee?.empCode : '0'
    }
    //End Export functionality

    // if (activeTab === 'EmployeeSalaryStructure') { tableData() }
    useEffect(() => {
        if (activeTab === 'EmployeeSalaryStructure') {
            document.title = 'Pay Plus: Salary Structure'
            getFormData();

        }
    }, [activeTab, sortConfig])


    useEffect(() => {
        if (activeTab === 'EmployeeSalaryStructure') {
            if (valueChanged) {
                handleSalaryCalculation();
            }
        }
    }, [recipe.AllowanceList, activeTab]);

    useEffect(() => {
        if (activeTab === 'EmployeeSalaryStructure') {
            //getFormData();
            // YearData();
            // MonthData();
            tableData();
            //  handleSalaryCalculation()
        }
    }, [tempEffectiveDate, activeTab])

    //   console.log(historyDetailData, 'History', history)

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Salary Structure</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className={`col-md-3 mb-1  align-items-start align-items-start `} key="txtEffectiveFrom">
                            <label className="form-label" style={{ textWrap: 'nowrap' }}>Effective From<span style={{ color: "red" }}> *</span></label>
                            <div className='d-flex gap-2 align-items-center'>
                                <MonthBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtConfirmationDate'].focus() };
                                    }}
                                    // label=''
                                    // labelClass='text-nowrap'
                                    //customclass='w-25'
                                    divclassname='col-lg-6'
                                    id='txtEffectiveMonth'
                                    selected={tempEffectiveDate.EffectiveMonth ? tempEffectiveDate.EffectiveMonth : ""}
                                    placeholder="Year"
                                    onChange={(e) => {
                                        setvalueChanged(true)
                                        setTempEffectiveDate({ ...tempEffectiveDate, EffectiveMonth: e })
                                    }}
                                    //required
                                    error={errors.EffectiveMonth}
                                />
                                <YearBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtConfirmationDate'].focus() };
                                    }}
                                    //label=''
                                    // labelClass='text-nowrap'
                                    divclassname='col-lg-4'
                                    id='txtEffectiveYear'
                                    selected={tempEffectiveDate.EffectiveYear ? tempEffectiveDate.EffectiveYear : ""}
                                    placeholder="Year"
                                    onChange={(e) => {
                                        setvalueChanged(true)
                                        setTempEffectiveDate({ ...tempEffectiveDate, EffectiveYear: e })

                                    }}
                                    maxDate={new Date() }
                                    //required
                                    error={errors.EffectiveYear}
                                />
                            </div>


                        </div>

                        <CheckBoxInput
                            customclass='form-check form-switch ps-3 no-wrap'
                            divclassname="col-md-4 mt-4 ps-0"
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current[isCalculate ? 'txtTotalAmount' : ''].focus() };
                            }}
                            checked={isCalculate}
                            label='Auto Calculate Salary Structure'
                            id='txtIsCalculate'
                            //maxLength='50'
                            placeholder="Is Calculate"
                            //  value={recipe.IsCalculate}
                            onChange={(e) => { setIsCalculate(e.target.checked) }}
                        // required

                        />
                        {isCalculate &&
                            <div className='col-md-5'>
                                <div className='row'>
                                    <InputBox
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtEmailID'].focus() };
                                        }}
                                        divclassname='col-md-5'
                                        label='Total Salary Amount'
                                        id='txtTotalAmount'
                                        maxLength='10'
                                        placeholder="Total Salary Amount"
                                        required
                                        // onBlur={recipe.TotalAmount ? HandleBlur : ''}
                                        error={errors.TotalAmount}
                                        value={recipe.TotalAmount} onChange={(e) => { HandleChangeForInput('TotalAmount', number(e.target.value)) }}
                                    />
                                    <button className='btn btn-primary m-0 p-0 col-md-1 mt-4 ' onClick={HandleBlur}>Calculate </button>
                                </div>
                            </div>
                        }
                        <div className='row'>
                            <div className='col-md-8 col-lg-8 col-sm-12 col-xs-12'// style={{ width: '55%' }} 
                            >
                                <div className='form-group ms-2'>
                                    <fieldset className="myfieldset "   >
                                        <legend className="mylegend"> <h6 className="m-0">Allowance</h6></legend>
                                        {allTableData ?
                                            (allTableData?.allowanceList?.length !== 0 ?

                                                <div className="table-responsive " >
                                                    <table className="table table-bordered table-striped table-hover">
                                                        <thead>
                                                            <tr className='light-bg'>
                                                                <th align='left' key="Select">Select</th>
                                                                <th style={{ textAlign: 'left' }} key="allowanceName">Allowance Name</th>
                                                                <th
                                                                    style={{ textAlign: 'right' }}
                                                                    key="amount">Amount</th>
                                                                <th align='left' key="pf">PF</th>
                                                                <th align='left' key="esi">ESI</th>
                                                                <th align='left' key="gross">Gross</th>
                                                                <th align='left' key="ot">OT</th>
                                                                <th align='left' key="leaveEncash">Leave</th>
                                                                <th align='left' key="taxable">Taxable</th>
                                                                <th align='left' key="calculationType">Calculation Type</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {allTableData?.allowanceList?.map((item, bodyIndex) => (
                                                                <tr className="TableTrhover" key={item.allowanceID} >
                                                                    <td style={{ width: '10%' }}>{bodyIndex + 1}</td>
                                                                    <td style={{ width: '10%' }}>{item.allowanceName}</td>
                                                                    <td className='text-end p-0 m-0' style={{ height: '1rem', width: '15%' }} key={`${bodyIndex}`}>
                                                                        <InputBox
                                                                            inputrefs={inputrefs}
                                                                            id={`txt${item.allowanceName}amount`}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === 'Enter') {
                                                                                    handleSalaryCalculation()
                                                                                    //  allTableData.allowanceList.forEach((ele) => inputrefs.current[`txt${ele.allowanceName }amount`].focus() )
                                                                                }
                                                                            }}
                                                                            inputClassName='text-end m-0 p-0 '
                                                                            divclassname='col-md-12 col-lg-12 m-0 p-0 '
                                                                            maxLength='10'
                                                                            //inputStyle={{ width: '5rem' }}
                                                                            placeholder="Amount"
                                                                            required
                                                                            //error={errors.Amount}
                                                                            key={bodyIndex}
                                                                            value={item.amount}
                                                                            onChange={(e) => {
                                                                                HandleChange(`amount`, numberwithDot(e.target.value), bodyIndex, 'allowanceList')
                                                                                //handleSalaryCalculation();
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td style={{ width: '10%' }} >
                                                                        <input
                                                                            //  onClick={handleSalaryCalculation() }
                                                                            type='checkbox'
                                                                            //style={{height:''} }
                                                                            inputrefs={inputrefs}
                                                                            checked={item.pf}
                                                                            // id={`txtpf${item.allowanceID}`}
                                                                            id={`txt${item.allowanceName}pf`}
                                                                            //  value={recipe.IsCalculate}
                                                                            onChange={(e) => {
                                                                                HandleChange('pf', (e.target.checked), bodyIndex, 'allowanceList')
                                                                                //handleSalaryCalculation()
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td style={{ width: '10%' }}>
                                                                        <input
                                                                            //  onClick={handleSalaryCalculation()}
                                                                            type='checkbox'
                                                                            inputrefs={inputrefs}
                                                                            checked={item.esi}
                                                                            // id={`txtesi${item.esi}`}
                                                                            id={`txt${item.allowanceName}esi`}
                                                                            //  value={recipe.IsCalculate}
                                                                            onChange={(e) => { HandleChange('esi', (e.target.checked), bodyIndex, 'allowanceList') }}
                                                                        />
                                                                    </td>
                                                                    <td style={{ width: '10%' }}>
                                                                        <input
                                                                            // onClick={handleSalaryCalculation()}
                                                                            type='checkbox'
                                                                            inputrefs={inputrefs}
                                                                            checked={item.gross}
                                                                            // id={`txtgross${item.allowanceID}`}
                                                                            id={`txt${item.allowanceName}gross`}
                                                                            //  value={recipe.IsCalculate}
                                                                            onChange={(e) => { HandleChange('gross', (e.target.checked), bodyIndex, 'allowanceList') }}
                                                                        />
                                                                    </td>
                                                                    <td style={{ width: '10%' }}>
                                                                        <input
                                                                            // onClick={handleSalaryCalculation()}
                                                                            type='checkbox'
                                                                            inputrefs={inputrefs}
                                                                            checked={item.ot}
                                                                            id={`txt${item.allowanceName}ot`}
                                                                            //  value={recipe.IsCalculate}
                                                                            onChange={(e) => {
                                                                                HandleChange('ot', (e.target.checked), bodyIndex, 'allowanceList')
                                                                                // handleSalaryCalculation()
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td style={{ width: '10%' }}> <input
                                                                        // onClick={handleSalaryCalculation()}
                                                                        type='checkbox'
                                                                        inputrefs={inputrefs}
                                                                        checked={item.leaveEncash}
                                                                        // id={`txtleaveEncash${item.allowanceID}`}
                                                                        id={`txt${item.allowanceName}leaveEncash`}
                                                                        //  value={recipe.IsCalculate}
                                                                        onChange={(e) => {
                                                                            HandleChange('leaveEncash', (e.target.checked), bodyIndex, 'allowanceList')
                                                                        }}
                                                                    /></td>
                                                                    <td style={{ width: '10%' }}> <input
                                                                        // onClick={handleSalaryCalculation()}
                                                                        type='checkbox'
                                                                        inputrefs={inputrefs}
                                                                        checked={item.taxable}
                                                                        // id={`txttaxable${item.allowanceID}`}
                                                                        id={`txt${item.allowanceName}taxable`}
                                                                        //  value={recipe.IsCalculate}
                                                                        onChange={(e) => {
                                                                            HandleChange('taxable', (e.target.checked), bodyIndex, 'allowanceList')
                                                                        }}
                                                                    /></td>

                                                                    <td style={{ width: '15%' }} className='p-0 m-0'>
                                                                        {/*<select className="form-control col-md-12 col-lg-12 m-0 p-0"*/}
                                                                        {/*    //  id="txtDeductionType"*/}
                                                                        {/*    id={`txt${item.allowanceName}calculationType`}*/}
                                                                        {/*    value={item.calculationType}*/}
                                                                        {/*    // error={recipe.DeductionType}*/}
                                                                        {/*    // onClick={handleSalaryCalculation()}*/}
                                                                        {/*    // onKeyDown={(e) => {*/}
                                                                        {/*    //     if (e.key === 'Enter') {*/}
                                                                        {/*    //         handleSalaryCalculation()*/}
                                                                        {/*    //         //  allTableData.allowanceList.forEach((ele) => inputrefs.current[`txt${ele.allowanceName }amount`].focus() )*/}
                                                                        {/*    //     }*/}
                                                                        {/*    // }}*/}
                                                                        {/*    onChange={(e) => {*/}
                                                                        {/*        HandleChange("calculationType", e.target.value, bodyIndex, 'allowanceList')*/}
                                                                        {/*    }}>*/}
                                                                        {/*    <option value="Fixed Monthly" >Fixed Monthly</option>*/}
                                                                        {/*    <option value="On Attendance" >On Attendance</option>*/}
                                                                        {/*    <option value="Per Day">Per Day</option>*/}
                                                                        {/*</select>*/}
                                                                        <SelectInputBox
                                                                            divclassname=''
                                                                            inputClassName="form-control col-md-12 col-lg-12 m-0 p-0"
                                                                            id={`txt${item.allowanceName}calculationType`}
                                                                            inputrefs={inputrefs}
                                                                            value={item.calculationType}
                                                                            onChange={(e) => {
                                                                                HandleChange("calculationType", e.target.value, bodyIndex, 'allowanceList')
                                                                            }}
                                                                            children={<>
                                                                                <option value="Fixed Monthly" >Fixed Monthly</option>
                                                                                <option value="On Attendance" >On Attendance</option>
                                                                                <option value="Per Day">Per Day</option>
                                                                            </>}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                            <tr className='border-white pt-4 bg-white' style={{}}>
                                                                <th key="grossTotal" className='pt-2 pb-0 text-end ' colSpan='2'>Gross Total</th>
                                                                <th key="grossTotalAmout" className='p-0 m-0 '>
                                                                    <InputBox
                                                                        inputrefs={inputrefs}
                                                                        //label='Gross Total'
                                                                        id='txtGrossTotal'
                                                                        maxLength='10'
                                                                        placeholder="Gross Total"
                                                                        required
                                                                        inputClassName='text-end m-0 p-0'
                                                                        divclassname='col-md-12 col-lg-12 m-0 p-0 '
                                                                        disabled
                                                                        error={errors.GrossTotal}
                                                                        value={recipe.GrossTotal}
                                                                        onChange={(e) => { HandleChangeForInput('GrossTotal', numberwithDot(e.target.value)) }}
                                                                    /> </th>
                                                            </tr>
                                                            <tr className='border-white pt-4 bg-white' style={{}}>
                                                                <th key="txtTotalSalary" className='pt-2 pb-0 text-end ' colSpan='2'>Total Salary Amount</th>
                                                                <th key="txtTotalSalaryAmount" className='p-0 m-0 '>
                                                                    <InputBox
                                                                        inputrefs={inputrefs}
                                                                        //label='Total Salary Amount'
                                                                        id='txtTotalSalaryAmount'
                                                                        maxLength='10'
                                                                        placeholder="Total Salary Amount"
                                                                        required
                                                                        disabled
                                                                        inputClassName='text-end m-0 p-0'
                                                                        divclassname='col-md-12 col-lg-12 m-0 p-0 '

                                                                        error={errors.TotalSalaryAmount}
                                                                        value={recipe.TotalSalaryAmount}
                                                                        onChange={(e) => { HandleChangeForInput('TotalSalaryAmount', numberwithDot(e.target.value)) }}
                                                                    /> </th>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>

                                                : <></>)
                                            : <></>}
                                        {/*<div className='row flex-row-reverse'>*/}
                                        {/*    <button type='button' className='btn btn-success btn-sm col-md-1 me-3 '*/}
                                        {/*        // disabled={!valueChanged}*/}
                                        {/*        //style={!valueChanged ? {cursor: 'not-allowed'} : {}}*/}
                                        {/*        onClick={handleSalaryCalculation} >*/}
                                        {/*        Re-Calculate*/}
                                        {/*    </button>*/}
                                        {/*</div>*/}
                                    </fieldset>

                                </div>
                            </div>
                            <div className='col-md-4 col-lg-4 col-sm-12 col-xs-12'// style={{ width: '45%' }}
                            >
                                <div className='form-group ms-2'>
                                    <fieldset className="myfieldset ">
                                        <legend className="mylegend"> <h6 className="m-0">Deduction</h6></legend>
                                        {allTableData ?
                                            (allTableData?.deductionList?.length !== 0 ?

                                                <div >
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered  w-100  table-hover">
                                                            <thead>
                                                                <tr className='light-bg'>
                                                                    <th key="Select">S No.</th>
                                                                    <th key="deductionName">Deduction Name</th>
                                                                    <th style={{ textAlign: 'right' }} key="amount" > Amount</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {allTableData?.deductionList?.map((item, bodyIndex) => (
                                                                    <tr className="TableTrhover" key={item.allowanceID} >
                                                                        <td style={{ width: '10%' }}>{bodyIndex + 1}</td>
                                                                        <td style={{ width: '20%' }}>{item.deductionName}</td>
                                                                        <td className='p-0 m-0' style={{ height: '1rem', width: '20%' }} key={`${bodyIndex}`}>
                                                                            <InputBox
                                                                                inputrefs={inputrefs}
                                                                                inputClassName='text-end m-0 p-0'
                                                                                divclassname='col-md-12 col-lg-12 m-0 p-0 '
                                                                                id={`txtamount${item.deductionID}`}
                                                                                style={{ float: 'inline-end' }}
                                                                                maxLength='10'
                                                                                disabled={item.deductionName === 'ESI' || item.deductionName === 'PF' || item.deductionName === "TDS" }
                                                                                // inputStyle={{ width: '5rem' }}
                                                                                placeholder="Amount"
                                                                                required
                                                                                //error={errors.Amount}
                                                                                key={bodyIndex}
                                                                                value={item.amount}
                                                                                onChange={(e) => { HandleChange('amount', numberwithDot(e.target.value), bodyIndex, 'deductionList') }}
                                                                            />

                                                                        </td>


                                                                    </tr>
                                                                ))}


                                                                <tr className='border-white pt-4 ' style={{}}>
                                                                    <th key="deductionTotal" className='pt-2 pb-0 text-end ' colSpan='2'>Deduction Total</th>
                                                                    <th key="deductionTotalAmout" className='p-0 m-0 '>
                                                                        <InputBox
                                                                            inputrefs={inputrefs}
                                                                            // label='Deduction Total'
                                                                            id='txtDeductionTotal'
                                                                            maxLength='10'
                                                                            placeholder="Deduction Total"
                                                                            disabled
                                                                            required
                                                                            inputClassName='text-end m-0 p-0'
                                                                            divclassname='col-md-12 col-lg-12 m-0 p-0 '

                                                                            error={errors.DeductionTotal}
                                                                            value={recipe.DeductionTotal}
                                                                            onChange={(e) => { HandleChangeForInput('DeductionTotal', numberwithDot(e.target.value)) }}
                                                                        /> </th>
                                                                </tr>
                                                                <tr className='border-white pt-4 ' style={{}}>
                                                                    <th key="deductionTotal" className='pt-2 pb-0 text-end ' colSpan='2'>NTH</th>
                                                                    <th key="deductionTotalAmout" className='p-0 m-0 '>
                                                                        <InputBox
                                                                            inputrefs={inputrefs}
                                                                            inputClassName='text-end m-0 p-0'
                                                                            divclassname='col-md-12 col-lg-12 m-0 p-0 '
                                                                            // id={`txtamount${item.allowanceID}`}
                                                                            id={`txtNTH`}
                                                                            style={{ float: 'inline-end' }}
                                                                            maxLength='10'
                                                                            disabled
                                                                            // inputStyle={{ width: '5rem' }}
                                                                            // placeholder="Amount"
                                                                            required
                                                                            //error={errors.Amount}
                                                                            value={recipTable3.nth}
                                                                            onChange={(e) => { setRecipTable3({ ...recipTable3, nth: numberwithDot(e.target.value) }) }}
                                                                        />
                                                                    </th>
                                                                </tr>
                                                                <tr className='border-white pt-4 ' style={{}}>
                                                                    <th key="deductionTotal" className='pt-2 pb-0 text-end ' colSpan='2'>Employer Contribution</th>
                                                                    <th key="deductionTotalAmout" className='p-0 m-0 '>
                                                                        <InputBox
                                                                            inputrefs={inputrefs}
                                                                            inputClassName='text-end m-0 p-0'
                                                                            divclassname='col-md-12 col-lg-12 m-0 p-0 '
                                                                            // id={`txtamount${item.allowanceID}`}
                                                                            id={`txtEmployerContribution`}
                                                                            style={{ float: 'inline-end' }}
                                                                            maxLength='10'
                                                                            disabled
                                                                            // inputStyle={{ width: '5rem' }}
                                                                            // placeholder="Amount"
                                                                            required
                                                                            //error={errors.Amount}
                                                                            value={recipTable3.employerContribution}
                                                                            //value={allTableData.employerContribution}
                                                                            onChange={(e) => { setRecipTable3({ ...recipTable3, employerContribution: numberwithDot(e.target.value) }) }}
                                                                        />
                                                                    </th>
                                                                </tr>
                                                                <tr className='border-white pt-4 ' style={{}}>
                                                                    <th key="deductionTotal" className='pt-2 pb-0 text-end ' colSpan='2'>CTC</th>
                                                                    <th key="deductionTotalAmout" className='p-0 m-0 '>
                                                                        <InputBox
                                                                            inputrefs={inputrefs}
                                                                            inputClassName='text-end m-0 p-0'
                                                                            divclassname='col-md-12 col-lg-12 m-0 p-0 '
                                                                            // id={`txtamount${item.allowanceID}`}
                                                                            id={`txtCTC`}
                                                                            style={{ float: 'inline-end' }}
                                                                            maxLength='10'
                                                                            disabled
                                                                            // inputStyle={{ width: '5rem' }}
                                                                            // placeholder="Amount"
                                                                            required
                                                                            //error={errors.Amount}
                                                                            value={recipTable3.ctc}
                                                                            // value={allTableData.ctc}
                                                                            onChange={(e) => { setRecipTable3({ ...recipTable3, ctc: numberwithDot(e.target.value) }) }}
                                                                        />
                                                                    </th>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {/*{countNo > 1 ?*/}
                                                    {/*    < Pagination className='pt-2' count={countNo} variant="outlined" page={currentPageNo} onChange={handleChange1} shape="rounded" />*/}
                                                    {/*    : ''}*/}
                                                </div>
                                                : <></>)
                                            : <></>}
                                        <br />

                                        <table className="table table-bordered  w-100 " style={{ display: "none" }}>
                                            <thead>
                                                <tr className='light-bg'>
                                                    <th style={{ width: '20%' }}>NTH</th>
                                                    <th style={{ width: '20%' }}>Employer Contribution</th>
                                                    <th style={{ width: '20%' }}>CTC</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="TableTrhover"  >
                                                    {/*<td style={{ width: '20%' }}>NTH</td>*/}
                                                    <td className='p-0 m-0' style={{ height: '1rem', width: '20%' }} key={`txtNTH`}>
                                                        <InputBox
                                                            inputrefs={inputrefs}
                                                            inputClassName='text-end m-0 p-0'
                                                            divclassname='col-md-12 col-lg-12 m-0 p-0 '
                                                            // id={`txtamount${item.allowanceID}`}
                                                            id={`txtNTH`}
                                                            style={{ float: 'inline-end' }}
                                                            maxLength='10'
                                                            // inputStyle={{ width: '5rem' }}
                                                            // placeholder="Amount"
                                                            required
                                                            //error={errors.Amount}
                                                            value={recipTable3.nth}
                                                            onChange={(e) => { setRecipTable3({ ...recipTable3, nth: numberwithDot(e.target.value) }) }}
                                                        />
                                                    </td>
                                                    {/*</tr>*/}
                                                    {/*<tr className="TableTrhover"  >*/}
                                                    {/*<td style={{ width: '20%' }}>Employer Contribution</td>*/}
                                                    <td className='p-0 m-0' style={{ height: '1rem', width: '20%' }} key={`txtEmployerContribution`}>
                                                        <InputBox
                                                            inputrefs={inputrefs}
                                                            inputClassName='text-end m-0 p-0'
                                                            divclassname='col-md-12 col-lg-12 m-0 p-0 '
                                                            // id={`txtamount${item.allowanceID}`}
                                                            id={`txtEmployerContribution`}
                                                            style={{ float: 'inline-end' }}
                                                            maxLength='10'
                                                            // inputStyle={{ width: '5rem' }}
                                                            // placeholder="Amount"
                                                            required
                                                            //error={errors.Amount}
                                                            value={recipTable3.employerContribution}
                                                            //value={allTableData.employerContribution}
                                                            onChange={(e) => { setRecipTable3({ ...recipTable3, employerContribution: numberwithDot(e.target.value) }) }}
                                                        />

                                                    </td>
                                                    {/*</tr>*/}
                                                    {/*<tr className="TableTrhover"  >*/}
                                                    {/*<td style={{ width: '20%' }}>CTC</td>*/}
                                                    <td className='p-0 m-0' style={{ height: '1rem', width: '20%' }} key={`txtCTC`}>
                                                        <InputBox
                                                            inputrefs={inputrefs}
                                                            inputClassName='text-end m-0 p-0'
                                                            divclassname='col-md-12 col-lg-12 m-0 p-0 '
                                                            // id={`txtamount${item.allowanceID}`}
                                                            id={`txtCTC`}
                                                            style={{ float: 'inline-end' }}
                                                            maxLength='10'
                                                            // inputStyle={{ width: '5rem' }}
                                                            // placeholder="Amount"
                                                            required
                                                            //error={errors.Amount}
                                                            value={recipTable3.ctc}
                                                            // value={allTableData.ctc}
                                                            onChange={(e) => { setRecipTable3({ ...recipTable3, ctc: numberwithDot(e.target.value) }) }}
                                                        />

                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>



                                    </fieldset>
                                </div>
                            </div>

                        </div>
                        <span style={{ color: "red" }} >Any changes in the salary sheet will be reflected after regeneration of salary (If salary is already generated).</span>

                        <FormButton
                            handleSubmit={handleSubmit}
                            exportText='History'
                            exportButtonIcon='fa-history'
                            handleExport={handleHistory}
                            Report={true}
                            NameForReportModal={'ReportModal'}
                            handleReport={getFormData}
                            Next={false}
                            handleClear={handleClear}
                        //Report={true}
                        />
                    </div>
                </div >
            </div >
            {showHistory &&
                <Table
                    Filter={false}
                    Token={TokenData}
                    PageName='History'
                    handleViewDetails={handleViewHistoryDetails}
                    tableData={history}
                    openModal='HistoryViewModal'
                    loading={loading}
                    currentPageNo={currentPageNoHistoryTable}
                    handleChange1={handleChangeHistoryTable}
                //   handleSorting={handleSortingHistory}
                //  sortConfig={sortConfigHistoryTable}
                />

            }
            {/* Modal for Employee Salary Modal */}
            <div className="modal fade" id="ReportModal" tabIndex="-1" aria-labelledby="ReportModalLabel" aria-hidden="true">
                <div className="modal-dialog  modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className='d-flex gap-2'>
                                <h1 className="modal-title fs-5" id="ReportModalLabel">Report </h1>
                                <button
                                    type='button'
                                    className='btn btn-success btn-sm'
                                    onClick={() => handleDownloadExcel(API_SERVER + 'api/Employee/SalaryStructureReport', ExportRequest, 'Salary Structure Report')}
                                ><span className=" text-warning me-2"><i className={`fa fa-download text-white color-success`}></i>
                                    </span>Export</button>
                            </div>
                            <button type="button" data-bs-dismiss="modal" aria-label="Close" className="btn btn-rounded btn-sm btn-outline-danger"  ><i className="fa-solid fa-close"></i></button>

                        </div>
                        <div className="modal-body">
                            <Table
                                Filter={false}
                                Token={TokenData}
                                PageName='Reports'
                                Action={false}
                                tableData={result}
                                loading={loading}
                                // filterData={filterData}
                                // setFilterData={setFilterData}
                                currentPageNo={currentPageNo}
                                handleChange1={handleChange1}
                            // handleSorting={handleSorting}
                            //   sortConfig={sortConfig}
                            />

                        </div>

                    </div>
                </div>
            </div>


            {/* Modal for View Employee Salary Update History Details Modal*/}
            <div className="modal fade" id="HistoryViewModal" tabIndex="-1" aria-labelledby="HistoryViewModalLabel" aria-hidden="true">
                <div className="modal-dialog  modal-lg">
                    <div className="modal-content p-3 pb-1 ">
                        {/*<div className="modal-header">*/}
                        {/*    <div className='d-flex gap-2'>*/}
                        {/*        <h1 className="modal-title fs-5" id="ReportModalLabel">Report </h1>*/}
                        {/*        <button*/}
                        {/*            type='button'*/}
                        {/*            className='btn btn-success btn-sm'*/}
                        {/*            onClick={() => handleDownloadExcel(API_SERVER + 'api/Employee/SalaryStructureReport', ExportRequest, 'Salary Structure Report')}*/}
                        {/*        ><span className=" text-warning me-2"><i className={`fa fa-download text-white color-success`}></i>*/}
                        {/*            </span>Export</button>*/}
                        {/*    </div>*/}
                        {/*    <button type="button" data-bs-dismiss="modal" aria-label="Close" className="btn btn-rounded btn-sm btn-outline-danger"  ><i className="fa-solid fa-close"></i></button>*/}

                        {/*</div>*/}
                        <Table
                            Filter={false}
                            Token={TokenData}
                            PageName='Reports'
                            Action={false}
                            tableData={historyDetailData}
                            loading={loading}
                            // filterData={filterData}
                            //    setFilterData={setFilterData}
                            currentPageNo={currentPageNoHistoryDetailsTable}
                        // handleChange1={handleChangeHistoryDetailsTable}
                        //  handleSorting={handleSortingHistoryDetails}
                        //sortConfig={sortConfigHistoryDetailsTable}
                        />


                    </div>
                </div>
            </div>
        </>
    );
}

export default EmployeeSalaryStructure;