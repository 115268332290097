/// <reference path="holidaymaster.jsx" />
import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, AutoCompleteBoxWithLabel, DateBox, MultiSelectInputBox } from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
//loder
import BlurLoader from "../Loder/BlurLoader";
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { getCommonData } from '../../apiservices/CommonServices';
import { number, Token } from '../Common/Common';
import Select from 'react-select'

function HolidayMaster() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();
    //token Data
    //const TokenData = {
    //    AuthToken:  process.env.REACT_APP_SECRET_KEY,
    //    LoginToken: user.loginsessionID,
    //    UserID: user.userID,
    //    CompanyID: user.companyID
    //}
    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({

        state: [],
        holidayID: "0",
        holidayDes: "",
        date: "",
        appEveryYear: true,
        active: true,

    })

    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData);
    const [isUpdate, setIsUpdate] = useState(false);
    const [stateData, setStateData] = useState([]);
    const [holidayDate, setHolidayDate] = useState(new Date());
    const [errors, setErrors] = useState({});

    // get current window size
    const [windowWidth, setWindonWidth] = useState(window.innerWidth)
    useEffect(() => {
        const handleResize = () => setWindonWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize); // Cleanup
    }, []);


    const StateData = () => {
        setStateData([])
        let temp = {
            Type: "GetState",
            Prefix: '',
            ContextKey: '',
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setStateData([])
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setStateData(data)
                }
            }
        });
    }

    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/Master/ShowHolidayMaster`, { ...TokenData, ...temp, ...sortConfig, ...filterData }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }

    const handleChange1 = (event, value) => {
        getFormData(value);
    };

    useEffect(() => {
        inputrefs.current['txtHolidayDes'].focus();
        document.title = 'Pay Plus : Holiday Master';
        getFormData()
        StateData();

    }, [sortConfig, isUpdate])


    //Change Handler
    const HandleChange = (prop, value) => {
        if (prop === 'date') {
            setHolidayDate(value)
        }

        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //validation handler
    //const Validate = () => {
    //    if (recipe.State === "") {
    //        return ["Please Select State.!", "txtState"]
    //    } else if (recipe.HolidayDes === "") {
    //        return ["Please Enter Holiday Description.!", "txtHolidayDes"]
    //    }
    //    else if (recipe.Date === "") {
    //        return ["Please Enter Date.!", "txtDate"]
    //    } if (recipe.Date < holidayDate) {
    //        return ['Please Enter Future Date.!']
    //    } else {
    //        return ""
    //    }
    //}

    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            //  { field: "State", msg: 'Please Select State.' },
            { field: "holidayDes", msg: 'Please Enter Holiday Name.' },
            { field: "date", msg: 'Please Enter Date.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }

    //Submit Handler
    const handleSubmit = () => {
        const myString = Validate();
        // if (myString === "") {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/Master/InsertUpdateHolidayMaster`, { ...TokenData, ...recipe, state: recipe.state?.map(ele => ele?.value) }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        inputrefs.current[res.focus].focus();
                        setLoading(false)
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "UNAUTHORIZED") {
                        toast.error(res.Message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        getFormData()
                        handleClear()
                    }
                }
            });
        }
        else {
            //  toast.error(myString[0]);
            //  inputrefs.current[myString[1]].focus();
        }
    }

    //Handle Edit
    const handleEdit = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/Master/EditHolidayMaster`, { ...TokenData, HolidayID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    let data = res.data
                    //  console.log(recipe, 'ddddddddddddddddddddddddddddd', data)
                    setRecipe({
                        ...data,
                        state: data?.state.split(",").map(ele => { return { label: ele, value: ele } }),
                      
                    })
                  
                }
            }
        });
    }

    //Handle Delete
    const handleDelete = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/Master/DeleteHolidayMaster`, { ...TokenData, HolidayID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    toast.success(res.message)
                    setLoading(false)
                    getFormData()
                    handleClear()
                }
            }
        });
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            state: "",
            holidayID: "0",
            holidayDes: "",
            date: "",
            appEveryYear: true,
            active: true,
        }))
        setErrors({})
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        getFormData()
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };
    // console.log(recipe.State?.map(ele=>ele?.value), 'skldlkfls')

    const StateDataForSelect = stateData.map((ele) => {
        return { label: ele.values, value: ele.values }
    });


    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Holiday Master</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className='row'>

                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtDate'].focus() };
                                }}
                                divclassname='col-md-3 mb-1'
                                label='Holiday Name'
                                id='txtHolidayDes'
                                maxLength='50'
                                placeholder="Holiday Name"
                                value={recipe.holidayDes}
                                error={errors.holidayDes}
                                onChange={(e) => HandleChange('holidayDes', e.target.value)}
                                required
                            />
                            <DateBox
                                divclassname='col-md-3 mb-1'
                                inputrefs={inputrefs}
                                ref={ref => (inputrefs.current['txtDate'] = ref)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtAppEveryYear'].focus() };
                                }}
                                label='Date'
                                id='txtDate'
                                selected={holidayDate}
                                placeholder="Date"
                                value={recipe.date}
                                error={errors.date}
                                onChange={(e) => HandleChange('date', e)}
                                required
                            />

                            <Switch
                                divclassname='col-md-3 mb-1'
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtIsActive'].focus() };
                                }}
                                label='Applicable Every Year'
                                checked={recipe.appEveryYear}
                                id='txtAppEveryYear'
                                // nextinputid='txtFranchiseGroup'
                                onChange={(e) => HandleChange('appEveryYear', e.target.checked)}
                            // extraclassname=' form-switch ps-4 ps-md-5 mt-4'
                            />
                            <Switch
                                divclassname='col-md-3 mb-1'
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['State'].focus() };
                                }}
                                // extraclassname=' form-switch ps-4 ps-md-5 mt-4'
                                label='Active'
                                checked={recipe.active}
                                id='txtIsActive'
                                // nextinputid='txtFranchiseGroup'
                                onChange={(e) => HandleChange('active', e.target.checked)}
                            />
                            <div className={windowWidth >= 992 ? '' : 'd-none'}>
                                <FormButton
                                    handleSubmit={handleSubmit}
                                    handleExport={() => handleDownloadExcel(API_SERVER + 'api/Master/ShowHolidayMaster', ExportRequest, 'Holiday Master')}
                                    handleClear={handleClear}
                                />
                            </div>
                            </div>
                        </div>

                        <div className='col-lg-4 cntrl-width'>
                            <MultiSelectInputBox
                                inputrefs={inputrefs}
                                label='State'
                                id='State'
                                divclassname='col-md-12 mb-1'
                                options={StateDataForSelect}
                                placeholder="State"
                                value={recipe.state}
                                error={errors.state}
                                onChange={(e) => { HandleChange('state', e) }}
                            />
                        </div>
                        <div className={windowWidth >= 992 ? 'd-none' : ''}>
                            <FormButton
                                handleSubmit={handleSubmit}
                                handleExport={() => handleDownloadExcel(API_SERVER + 'api/Master/ShowHolidayMaster', ExportRequest, 'Holiday Master')}
                                handleClear={handleClear}
                            />
                        </div>
                    </div>

                </div>
            </div>

            <Table
                Token={TokenData}
                PageName='HolidayMaster'
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleFilter={() => {
                    getFormData();
                    setFilterData({
                        ...filterData,
                    });
                }}
                tableData={result}
                loading={loading}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
            {loading && < BlurLoader />}
        </>
    )
}

export default HolidayMaster
