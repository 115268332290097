import React, { useEffect, useRef, useState } from 'react';
import { FileUploadChild, FormButton, SelectInputBox, handleFileUploader, handleFileUploderInput } from "../commoncomponents/InputBox";
//import { FileUploader } from "react-drag-drop-files";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
//loder
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';
//services
import { FileUploader } from "react-drag-drop-files";
import { getMasterData } from '../../apiservices/CommonServices';
import { post } from '../../apiservices/service';

function EmployeeDocumentUpload({
    handleTabClick,
    setActiveTab,
    employee,
    EmployeeCodeByUrl,
    EmployeeInEditTime,
    activeTab,
    TokenData,
}) {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();

    //initial state
    const [recipe, setRecipe] = useState({
        EmpCode: EmployeeCodeByUrl ? EmployeeCodeByUrl : employee ? employee?.empCode : '0',
        CandidateID: "0",
        EmpDocTypeID: "",
        EmpSubDocTypeID: "",
        FrontSideDocFileBase64: "",
        FrontSideDocFileExt: "",
        FrontSideDocFileName: "",
        BackSideDocFileBase64: "",
        BackSideDocFileExt: "",
        BackSideDocFileName: "",
        EmpDocID: '0'


    })
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [isUpdate, setIsUpdate] = useState(false)
    const [documentTypeAllData, setDocumentTypeAllData] = useState([])
    const [documentSubTypeAllData, setDocumentSubTypeAllData] = useState([])
    const [errors, setErrors] = useState({});
    const fileType = "jpg,png,pdf";

    const DocumentTypeAllData = () => {
        let temp = {
            Type: "DocumentType",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setDocumentTypeAllData(data)
                }
            }
        });
    }

    const DocumentSubTypeAllData = (value) => {
        let temp = {
            Type: "DocumentSubType",
            Prefix: '',
            ContextKey: value || '',
            ContextKey2: ""
        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setDocumentSubTypeAllData([])
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setDocumentSubTypeAllData(data)
                }
            }
        });
    };


    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setLoading(true)
        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s",
            Code: EmployeeCodeByUrl ? EmployeeCodeByUrl : employee ? employee?.empCode : '0',
            //CandidateCode: empCode
        }
        post(`${API_SERVER}api/Employee/ShowEmployeeDocumentUpload`, { ...TokenData, ...temp, ...sortConfig, ...filterData, }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }


    //Change Handler
    const HandleChange = (prop, value) => {
        if (prop === 'EmpDocTypeID') {
            DocumentSubTypeAllData(value)
            setRecipe({ ...recipe, EmpDocTypeID: value, EmpSubDocTypeID: '' })

        }
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }
    const handleChange1 = (event, value) => {
        getFormData(value);
    };
    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };
    //validation handler
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "EmpDocTypeID", msg: 'Please Select Document Type.' },
            { field: "EmpSubDocTypeID", msg: 'Please Select Document Sub Type.' },
            { field: "FrontSideDocFileBase64", msg: ' Please Select Document File Front Side.' },
        ]

        if (recipe['EmpSubDocTypeID'] === '6') {
            validation.push(
                { field: "BackSideDocFileBase64", msg: 'Please Select Document File Back Side.' },
            )
        };

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {

                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }
    //Submit Handler
    const handleSubmit = () => {
        const myString = Validate();

      //  console.log(EmployeeCodeByUrl ? EmployeeCodeByUrl : employee, 'Employeee')
        // if (myString === "") {
        if (Validate()) {
            const temp = {
                EmpCode: EmployeeCodeByUrl ? EmployeeCodeByUrl : employee?.empCode,
                CandidateCode: EmployeeCodeByUrl ? EmployeeCodeByUrl : employee?.empCode,
            }
         //   console.log(recipe, 'reci[e', temp)
            setLoading(true)
            post(`${API_SERVER}api/Employee/UpdateEmployeeDocumentUpload`, { ...TokenData, ...recipe, ...temp }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        setLoading(false)
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "UNAUTHORIZED") {
                        toast.error(res.Message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        handleClear()
                    }
                }
            });
        }
        else {
            // inputrefs.current[myString[1]].focus();
            // toast.error(myString[0]);
        }
    }

    //Handle Edit
    const handleEdit = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/Employee/EditEmployeeDocumentUpload`, { ...TokenData, EmpDocID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    let data = res.data
                    DocumentSubTypeAllData(data.documentType)
                    setRecipe({
                        ...data,
                        EmpCode: EmployeeCodeByUrl ? EmployeeCodeByUrl : employee ? employee?.empCode : '0',
                        EmpDocTypeID: data.documentType,
                        EmpSubDocTypeID: data.subDocumentType,

                        FrontSideDocFileBase64: data.frontSideDocFileBase64,
                        FrontSideDocFileExt: data.frontSideDocFileExt,
                        FrontSideDocFileName: data.frontSideDocFileName,
                        BackSideDocFileBase64: data.backSideDocFileBase64,
                        BackSideDocFileExt: data.backSideDocFileExt,
                        BackSideDocFileName: data.backSideDocFileName,
                        EmpDocID: data.empDocID
                    })
                    handleFileUploderInput('.DocumentBackSide', data, 'documentFileNameBackSide', 'documentExtBackSide', fileType)
                    handleFileUploderInput('.DocumentFrontSide', data, 'documentFileNameFrontSide', 'documentExtFrontSide', fileType)

                }
            }
        });
    }

    //Handle Delete
    const handleDelete = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/Employee/DeleteEmployeeDocumentUpload`, { ...TokenData, EmpDocID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    toast.success(res.message)
                    setLoading(false)
                    getFormData()
                }
            }
        });
    }

    // Handle Show Documents
    const HandleShowDoc = (ID, key) => {
        setLoading(true)
        post(`${API_SERVER}api/Employee/ShowEmployeeDocument`, { ...TokenData, EmpDocID: ID, Type: key }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    toast.error(res.message)
                } else if (res.status === "EXPIRED") {
                    toast.error(res.message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    let base64 = res && res.document
                    res.documentExtention === '' ? toast.error('No Document Uploaded') : res.documentExtention === 'pdf' ? window.open(`/PDFView/#data:application/pdf;base64,${base64}`, '_blank')
                       : window.open(`/ImageView/#data:application/jpg;base64,${base64}`, '_blank')
                }
            }
        });
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            CandidateID: "0",
            // EmpCode: "",
            EmpDocTypeID: "",
            EmpSubDocTypeID: "",
            FrontSideDocFileBase64: "",
            FrontSideDocFileExt: "",
            FrontSideDocFileName: "",
            BackSideDocFileBase64: "",
            BackSideDocFileExt: "",
            BackSideDocFileName: "",
            EmpDocID: "",

        }))
        setErrors({})
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        getFormData()

        handleFileUploderInput('.DocumentBackSide', '', '', '', fileType)
        handleFileUploderInput('.DocumentFrontSide', '', '', '', fileType)
    }

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        EmpCode: EmployeeCodeByUrl ? EmployeeCodeByUrl : employee?.empCode,
        Code: EmployeeCodeByUrl ? EmployeeCodeByUrl : employee?.empCode,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality

    //useEffect(() => {
    //    if (activeTab === 'DocumentUpload') {
    //        inputrefs.current['txtEmpDocTypeID'].focus();
    //        document.title = 'Pay Plus : Document Upload'
    //    }
    //}, [activeTab])


    useEffect(() => {
        if (activeTab === 'DocumentUpload') {
            inputrefs.current['txtEmpDocTypeID'].focus();
            document.title = 'Pay Plus : Document Upload'
        DocumentTypeAllData();
        getFormData();
        }
    }, [sortConfig, isUpdate, activeTab])


    return (
        <>
            <div className='main-card card border-0'>

                <div className='card-body'>
                    <div className='row p-0 ps-3 pt-2'>

                        <div className='row'>
                            <div className='row'>
                                <fieldset className="myfieldset">
                                    <legend className="mylegend"> <h6 className="m-0">Document Upload</h6></legend>
                                    <div className='row'>

                                        {/*<div className={`col-md-3 mb-1 cntrl-width`} key="txtEmpDocTypeID">*/}
                                        {/*    <label className="form-label">Document Type<span style={{ color: "red" }}> *</span>*/}
                                        {/*    </label>*/}
                                        {/*    <select ref={ref => (inputrefs.current['txtEmpDocTypeID'] = ref)}*/}
                                        {/*        className="form-control"*/}
                                        {/*        id="txtEmpDocTypeID"*/}
                                        {/*        value={recipe.EmpDocTypeID}*/}
                                        {/*        error={recipe.EmpDocTypeID}*/}
                                        {/*        onKeyDown={(e) => {*/}
                                        {/*            if (e.key === 'Enter') { inputrefs.current['txtEmpSubDocTypeID'].focus() };*/}
                                        {/*        }}*/}
                                        {/*        onChange={(e) => HandleChange('EmpDocTypeID', e.target.value)}>*/}
                                        {/*        <option value="">--Select Document Type--</option>*/}
                                        {/*        {documentTypeAllData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}*/}

                                        {/*    </select>*/}
                                        {/*    <div className="error-msg">{errors.EmpDocTypeID}</div>*/}
                                        {/*</div>*/}

                                        <SelectInputBox
                                            label='Document Type'
                                            inputrefs={inputrefs}
                                            required
                                            id="txtEmpDocTypeID"
                                            value={recipe.EmpDocTypeID}
                                            error={errors.EmpDocTypeID}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtEmpSubDocTypeID'].focus() };
                                            }}
                                            onChange={(e) => HandleChange('EmpDocTypeID', e.target.value)}
                                            children={<>
                                                <option value="">--Select Document Type--</option>
                                                {documentTypeAllData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}
                                            </>}
                                        />

                                        {/*<div className={`col-md-3 mb-1 cntrl-width`} key="txtEmpSubDocTypeID">*/}
                                        {/*    <label className="form-label">Document Sub Type<span style={{ color: "red" }}> *</span>*/}
                                        {/*    </label>*/}
                                        {/*    <select ref={ref => (inputrefs.current['txtEmpSubDocTypeID'] = ref)}*/}
                                        {/*        className="form-control"*/}
                                        {/*        id="txtEmpSubDocTypeID"*/}
                                        {/*        value={recipe.EmpSubDocTypeID}*/}
                                        {/*        error={recipe.EmpSubDocTypeID}*/}
                                        {/*        //    onKeyDown={(e) => {*/}
                                        {/*        //       if (e.key === 'Enter') { inputrefs.current['fileDocument'].focus() };*/}
                                        {/*        //       }}*/}
                                        {/*        onChange={(e) => {*/}
                                        {/*            HandleChange('EmpSubDocTypeID', e.target.value)*/}
                                        {/*            if (e.target.value) {*/}
                                        {/*                setRecipe({*/}
                                        {/*                    ...recipe,*/}
                                        {/*                    FrontSideDocFileBase64: "",*/}
                                        {/*                    FrontSideDocFileExt: "",*/}
                                        {/*                    FrontSideDocFileName: "",*/}
                                        {/*                    BackSideDocFileBase64: "",*/}
                                        {/*                    BackSideDocFileExt: "",*/}
                                        {/*                    BackSideDocFileName: "",*/}
                                        {/*                    EmpSubDocTypeID: e.target.value,*/}
                                        {/*                })*/}
                                        {/*                handleFileUploderInput('.DocumentBackSide', '', '', '', fileType)*/}
                                        {/*                handleFileUploderInput('.DocumentFrontSide', '', '', '', fileType)*/}
                                        {/*            }*/}
                                        {/*        }}>*/}
                                        {/*        <option value="">--Select DocumentSub Type--</option>*/}
                                        {/*        {documentSubTypeAllData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}*/}

                                        {/*    </select>*/}
                                        {/*    <div className="error-msg">{errors.EmpSubDocTypeID}</div>*/}
                                        {/*</div>*/}

                                        <SelectInputBox
                                            label='Document Sub Type'
                                            inputrefs={inputrefs}
                                            required
                                            id="txtEmpSubDocTypeID"
                                            value={recipe.EmpSubDocTypeID}
                                            error={errors.EmpSubDocTypeID}
                                            onKeyDown={(e) => {
                                                //if (e.key === 'Enter') { inputrefs.current['txtEmpSubDocTypeID'].focus() };
                                            }}
                                            onChange={(e) => {
                                                HandleChange('EmpSubDocTypeID', e.target.value)
                                                if (e.target.value) {
                                                    setRecipe({
                                                        ...recipe,
                                                        FrontSideDocFileBase64: "",
                                                        FrontSideDocFileExt: "",
                                                        FrontSideDocFileName: "",
                                                        BackSideDocFileBase64: "",
                                                        BackSideDocFileExt: "",
                                                        BackSideDocFileName: "",
                                                        EmpSubDocTypeID: e.target.value,
                                                    })
                                                    handleFileUploderInput('.DocumentBackSide', '', '', '', fileType)
                                                    handleFileUploderInput('.DocumentFrontSide', '', '', '', fileType)
                                                }
                                            }}
                                            children={<>
                                                <option value="">--Select DocumentSub Type--</option>
                                                {documentSubTypeAllData !==null && documentSubTypeAllData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}
                                            </>}
                                        />

                                        {/*<div className={`col-md-3 mb-1 cntrl-width`} key="fileDocument">*/}
                                        {/*    <label className="form-label">Document Front Side <span style={{ color: "red" }}> *</span></label>*/}
                                        {/*    <input*/}
                                        {/*        type="file"*/}
                                        {/*        id="fileDocument"*/}
                                        {/*        name="DocumentFrontSide"*/}
                                        {/*        maxLength="50"*/}
                                        {/*        tabIndex="1"*/}
                                        {/*        required*/}
                                        {/*        onChange={(file) => {*/}
                                        {/*            if (file.target.files && file.target.files[0]) {*/}
                                        {/*                let img = file.target.files[0];*/}

                                        {/*                let extArr = (img.name).split(".");*/}
                                        {/*                if (extArr[1] !== "png" && extArr[1] !== "jpg" && extArr[1] !== "jpeg") {*/}
                                        {/*                    toast.error("file should be png ,jpg or jpeg type.");*/}
                                        {/*                    return*/}
                                        {/*                }*/}
                                        {/*                const reader = new FileReader();*/}
                                        {/*                reader.readAsDataURL(img);*/}
                                        {/*                reader.onload = () => {*/}
                                        {/*                    let document = (reader.result).split(',')[1]*/}
                                        {/*                    setRecipe({*/}
                                        {/*                        ...recipe,*/}
                                        {/*                        FrontSideDocFileBase64: document,*/}
                                        {/*                        FrontSideDocFileExt: extArr[1],*/}
                                        {/*                        FrontSideDocFileName: extArr[0]*/}
                                        {/*                    });*/}
                                        {/*                };*/}
                                        {/*            }*/}
                                        {/*        }}*/}
                                        {/*    />*/}
                                        {/*    <div className="error-msg">{errors.FrontSideDocFileBase64}</div>*/}
                                        {/*    */}{/*<span>{recipe.FrontSideDocFileName}</span>*/}
                                        {/*    <div className="form-group" id="dv1" style={{ display: 'none' }}>*/}
                                        {/*        <div className="col-md-8 col-sm-8 col-xs-12 mt-1" style={{ height: '100px', width: '100px' }}>*/}
                                        {/*            <img id="txtHeader" height="100" width="100" src="" alt="Document File Front Side" />*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        <div className={`col-md-3 mb-1 cntrl-width`} key="fileDocument">
                                            <label className="form-label">Document Front Side <span style={{ color: "red" }}> *</span></label>
                                            <FileUploader
                                                classes='file-upload text-truncate'
                                                types={fileType.split(",")}
                                                handleChange={(file) => handleFileUploader(file, fileType.split(","), setRecipe, recipe, 'FrontSideDocFileBase64', 'FrontSideDocFileExt', 'FrontSideDocFileName')}
                                                children={<FileUploadChild message={recipe.FrontSideDocFileName + '.' + recipe.FrontSideDocFileExt} types={fileType.split(",")} />}
                                            />

                                            {/*{console.log(document?.querySelectorAll('.file-uploader')[0]?.childNodes[2]?.innerHTML)}*/}
                                            <div className="error-msg">{errors.FrontSideDocFileBase64}</div>
                                        </div>

                                        {/*<div className={`col-md-3 mb-1 cntrl-width`} key="fileDocumentBack">*/}
                                        {/*    <label className="form-label">Document Back Side{recipe.EmpSubDocTypeID === '6' ? <span style={{ color: "red" }}> *</span> : <></>}</label>*/}
                                        {/*    <input*/}
                                        {/*        type="file"*/}
                                        {/*        id="fileDocumentBack"*/}
                                        {/*        name="DocumentBackSide"*/}
                                        {/*        maxLength="50"*/}
                                        {/*        tabIndex="1"*/}
                                        {/*        required={recipe.EmpSubDocTypeID === '6' ? true : false}*/}
                                        {/*        onChange={(file) => {*/}
                                        {/*            if (file.target.files && file.target.files[0]) {*/}
                                        {/*                let img = file.target.files[0];*/}
                                        {/*                let extArr = (img.name).split(".");*/}
                                        {/*                if (extArr[1] !== "png" && extArr[1] !== "jpg" && extArr[1] !== "jpeg") {*/}
                                        {/*                    toast.error("file should be png ,jpg or jpeg type.");*/}
                                        {/*                    return*/}
                                        {/*                }*/}
                                        {/*                const reader = new FileReader();*/}
                                        {/*                reader.readAsDataURL(img);*/}
                                        {/*                reader.onload = () => {*/}
                                        {/*                    let document = (reader.result).split(',')[1]*/}
                                        {/*                    setRecipe({*/}
                                        {/*                        ...recipe,*/}
                                        {/*                        BackSideDocFileBase64: document,*/}
                                        {/*                        BackSideDocFileExt: extArr[1],*/}
                                        {/*                        BackSideDocFileName: extArr[0]*/}
                                        {/*                    });*/}
                                        {/*                };*/}
                                        {/*            }*/}
                                        {/*        }}*/}
                                        {/*    />*/}
                                        {/*    <div className="error-msg">{errors.BackSideDocFileBase64}</div>*/}
                                        {/*    */}{/*<span>{recipe.BackSideDocFileName}</span>*/}
                                        {/*    <div className="form-group" id="dv1" style={{ display: 'none' }}>*/}
                                        {/*        <div className="col-md-8 col-sm-8 col-xs-12 mt-1" style={{ height: '100px', width: '100px' }}>*/}
                                        {/*            <img id="txtHeader" height="100" width="100" src="" alt="Document File Back Side" />*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}


                                        <div className={`col-md-3 mb-1 cntrl-width`} key="fileDocumentBack">
                                            <label className="form-label">Document Back Side{recipe.EmpSubDocTypeID === '6' ? <span style={{ color: "red" }}> *</span> : <></>}</label>
                                             
                                            <FileUploader
                                                classes='file-upload text-truncate'
                                                types={fileType.split(",")}
                                                handleChange={(file) => handleFileUploader(file, fileType.split(","), setRecipe, recipe, 'BackSideDocFileBase64', 'BackSideDocFileExt', 'BackSideDocFileName')}
                                                children={<FileUploadChild message={recipe.BackSideDocFileName + '.' + recipe.BackSideDocFileExt} types={fileType.split(",")} />}
                                            />
                                            <div className="error-msg">{errors.BackSideDocFileBase64}</div>
                                        </div>


                                    </div>
                                </fieldset>

                            </div>
                        </div>

                        <FormButton
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/Employee/ShowEmployeeDocumentUpload', ExportRequest, 'EmployeeDocumentUpload')}
                            handleClear={handleClear}
                        />
                    </div>

                </div>

            </div >

            {/* Table Component start*/}
            <Table
                Token={TokenData}
                PageName='CandidateDocument'
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                HandleShowDoc={HandleShowDoc}
                handleFilter={() => {
                    getFormData();
                    setFilterData({
                        ...filterData,
                    });
                }}
                tableData={result}
                //  loading={loading}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
        </>
    )
}

export default EmployeeDocumentUpload
