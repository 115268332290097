import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox } from "../commoncomponents/InputBox";

import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';

//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';

//services
import { getCommonData } from '../../apiservices/CommonServices';
import { post } from '../../apiservices/service';
import { number, Token } from '../Common/Common';

function StateMaster() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();

    //token Data
    //const TokenData = {
    //    AuthToken:  process.env.REACT_APP_SECRET_KEY,
    //    LoginToken: user.loginDetailID,
    //    UserID: user.userID,
    //    CompanyID: user.companyID
    //}
    const TokenData = Token()

    //initial state
    const [recipe, setRecipe] = useState({
        StateName: '',
        StateCode: '',
        GSTStateCode: '',
        EmplContr: '',
        EmprContr: '',
        LWFApplicable: false,
        PTaxApplicable: false,
        Active: true,
    })
    const [errors, setErrors] = useState({});
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [countryAllData, setCountryAllData] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)

    //const CountryData = () => {
    //    let temp = {
    //        Type: "GetCountry",
    //        Prefix: ".",
    //        ContextKey: "",
    //        ContextKey2: ""
    //    }
    //    setLoading(true)
    //    getCommonData({ ...TokenData, ...temp }, (res) => {
    //        if (res && res.status) {
    //            if (res.status === "ERROR") {
    //                setLoading(false)
    //            } else {
    //                setLoading(false)
    //                let data = res.dataList
    //                setCountryAllData(data)
    //            }
    //        }
    //    });
    //}

    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/MASTER/ShowStateMaster`,{ ...TokenData, ...temp, ...sortConfig, ...filterData }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }

    const handleChange1 = (event, value) => {
        getFormData(value);
    };

    useEffect(() => {
        inputrefs.current['txtStateCode'].focus()
        document.title = 'Pay Plus : State Master'
        getFormData()
      //  CountryData()
    }, [sortConfig])

    useEffect(() => {
        getFormData()
    }, [isUpdate])

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //validation handler
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            /*{ field: "CountryCode", msg: 'Please Enter Country Name.' },*/
            { field: "StateName", msg: 'Please Enter State Name.' },
            { field: "StateCode", msg: 'Please Enter State Code.' },
            { field: "GSTStateCode", msg: 'Please Enter GST State Code.' },
            //{ field: "EmplContr", msg: 'Please Enter Empl Contr.' },
            //{ field: "EmprContr", msg: 'Please Enter Empr Contr.' },
          
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }

    //Submit Handler
    const handleSubmit = () => {
        console.log("validate", Validate())
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/MASTER/InsertUpdateStateMaster`,{ ...TokenData, ...recipe }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message);
                        inputrefs.current[res.focus].focus();
                        setLoading(false);
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "UNAUTHORIZED") {
                        toast.error(res.Message);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        toast.success(res.message);
                        getFormData();
                        handleClear();
                    }
                }
            });
        }
    }

    //Handle Edit
    const handleEdit = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/MASTER/EditStateMaster`,{ ...TokenData, StateID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                   // CountryData()
                    let data = res.data
                    setRecipe({
                        ...data,
                        CountryCode: data.countryCode,
                        StateName: data.stateName,
                        StateCode: data.stateCode,
                        EmprContr: data.emprContr,
                        EmplContr: data.emplContr,
                        GSTStateCode: data.gstStateCode,
                        LWFApplicable: data.lwfApplicable,
                        PTaxApplicable: data.pTaxApplicable,
                        Active: data.active
                    })
                }
            }
        });


    }

    //Handle Delete
    const handleDelete = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/MASTER/DeleteStateMaster`,{ ...TokenData, StateID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    toast.success(res.message)
                    setLoading(false)
                    getFormData()
                    handleClear()
                }
            }
        });
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe, StateName: '',
            StateCode: '',
            GSTStateCode: '',
            EmplContr: '',
            EmprContr: '',
            LWFApplicable: false,
            PTaxApplicable: false,
            Active: true,
        }))
        setErrors({})
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };


    //let countryData = []
    //for (let i = 0; i < countryAllData.length; i++) {
    //    let mydata = countryAllData[i]?.values
    //    countryData.push(mydata)
    //};


    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality

    return (

        <>

            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">State Master</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtStateName'].focus()
                                }
                            }}
                            label='State Code'
                            id='txtStateCode'
                            maxLength='2'
                            placeholder="State Code"
                            value={recipe.StateCode}
                            error={errors.StateCode}
                            onChange={(e) => HandleChange('StateCode', e.target.value)}
                            required
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtGSTStateCode'].focus() };
                            }}
                            label='State Name'
                            id='txtStateName'
                            maxLength='50'
                            placeholder="State Name"
                            value={recipe.StateName}
                            error={errors.StateName}
                            onChange={(e) => HandleChange('StateName', e.target.value)}
                            required
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtEmplContr'].focus() };
                            }}
                            label='GST State Code'
                            id='txtGSTStateCode'
                            maxLength='3'
                            placeholder="GST State Code"
                            value={recipe.GSTStateCode}
                            error={errors.GSTStateCode}
                            onChange={(e) => { HandleChange('GSTStateCode', number(e.target.value)) }}
                          required
                        />
                         <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtEmprContr'].focus() };
                            }}
                            label='Empl Contr'
                            id='txtEmplContr'
                            maxLength='3'
                            placeholder="Empl Contr"
                            value={recipe.EmplContr}
                            error={errors.EmplContr}
                            onChange={(e) => { HandleChange('EmplContr', number(e.target.value)) }}
                            required
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtLWFApplicable'].focus() };
                            }}
                            label='Empr Contr'
                            id='txtEmprContr'
                            maxLength='3'
                            placeholder="Empr Contr"
                            value={recipe.EmprContr}
                            error={errors.EmprContr}
                            onChange={(e) => { HandleChange('EmprContr', number(e.target.value)) }}
                            required
                        />

                        <Switch
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtPTaxApplicable'].focus() };
                            }}
                            label='LWF Applicable'
                            checked={recipe.LWFApplicable}
                            id='txtLWFApplicable'
                            nextinputid='txtPTaxApplicable'
                            onChange={(e) => HandleChange('LWFApplicable', e.target.checked)}
                        />
                        <Switch
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtIsActive'].focus() };
                            }}
                            label='PTax Applicable'
                            checked={recipe.PTaxApplicable}
                            id='txtPTaxApplicable'
                            nextinputid='txtIsActive'
                            onChange={(e) => HandleChange('PTaxApplicable', e.target.checked)}
                        />
                        <Switch
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { handleSubmit() };
                            }}
                            label='Active'
                            checked={recipe.Active}
                            id='txtIsActive'
                           // nextinputid='txtFranchiseGroup'
                            onChange={(e) => HandleChange('Active', e.target.checked)}
                        />

                        <FormButton
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/MASTER/ShowStateMaster', ExportRequest, 'State Master')}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>

            {/* Table Component start*/}
            <Table
                Token={TokenData}
                PageName='StateMaster'
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleFilter={() => {
                    getFormData();
                    setFilterData({
                        ...filterData,
                    });
                }}
                tableData={result}
                  loading={loading}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
            {/* Table Component End*/}


            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}

        </>
    )
}

export default StateMaster