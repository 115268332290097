import React, { useEffect,useState, useRef } from 'react'
import { InputBox, FormButton, AutoCompleteBox, Switch, DateBox, PasswordInputBox } from "../commoncomponents/InputBox";

import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';

//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';

//services
import { getCommonData } from '../../apiservices/CommonServices';
import { post } from '../../apiservices/service';
import { Token } from '../Common/Common';
import { setDefaultLocale } from 'react-datepicker';

function UserMaster() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();

    //token Data
    //const TokenData = {
    //    AuthToken:  process.env.REACT_APP_SECRET_KEY,
    //    LoginToken: user.loginsessionID,
    //    UserID: user.userID,
    //    CompanyID: user.companyID
    //}
    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({
        NewUserID:'0',
        EmployeeCode: '',
        UserName: '',
        UserGroup: '',
        Password: '',
        Active: true
    })
    const [errors, setErrors] = useState({});
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [groupAllData, setGroupAllData] = useState([])
    const [employeeCodeData, setEmployeeCodeData] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const [disableOnEdit, setDisableOnEdit] = useState(false)


    const getUserGroup = (value) => {
        let temp = {
            Type: "GetUserGroupName",
            Prefix:value?value: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setGroupAllData(data)
                }
            }
        });
    }

    const getEmployeeCodeData = (value) => {
        let temp = {
            Type: "GetEmployee",
            Prefix: value?value:'',
            ContextKey: "",
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setEmployeeCodeData(data)
                }
            }
        });
    }

    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/user/ShowUserMaster`, { ...TokenData, ...temp, ...sortConfig, ...filterData }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }

    const handleChange1 = (event, value) => {
        getFormData(value);
    };
 
    useEffect(() => {
        getUserGroup()
        getEmployeeCodeData()
        getFormData()
    }, [sortConfig, isUpdate])
 
    useEffect(() => {
        inputrefs.current['txtEmployeeCode'].focus()
        document.title = 'Pay Plus: User Master'
    }, [])


    //Change Handler
    const HandleChange = (prop, value) => {
       
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //validation handler
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "EmployeeCode", msg: 'Please Enter Employee Code.' },
            { field: "UserName", msg: 'Please Enter User Name.' },
            { field: "UserGroup", msg: 'Please Select User Group.' },
            { field: "Password", msg: 'Please Enter Password.' }
        ]

        //if (recipe.UserID === '0') validation.push({ field: "Password", msg: 'Please Enter Password.' })

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });


        return isValid;
    }

    //Submit Handler
    const handleSubmit = () => {  
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/user/InsertUpdateUserMaster`, { ...TokenData, ...recipe }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        inputrefs.current[res.focus].focus();
                        setLoading(false)
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        getFormData()
                        handleClear()
                    }
                }
            });
        }
    }

    //Handle Edit
    const handleEdit = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/user/EditUserMaster`, { ...TokenData, NewUserID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else {
                    setLoading(false)
                    let data= res.data
                    setRecipe(recipe => ({
                        ...recipe,
                        NewUserID: data.newUserID,
                        EmployeeCode: data.employeeCode,
                        UserName: data.userName,
                        UserGroup: data.userGroup,
                        Password: data.password,
                        Active: data.active
                    }))
                    setDisableOnEdit(true)
                }
            }
        });
    }

    //Handle Delete
    const handleDelete = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/user/DeleteUserMaster`, { ...TokenData, NewUserID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else {
                    toast.success(res.message)
                    setLoading(false)
                    getFormData()
                    handleClear()
                }
            }
        });
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            NewUserID: '0',
            EmployeeCode: '',
            UserName: '',
            UserGroup: '',
            Password: '',
            Active: true
        }))
        setErrors({})
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        setDisableOnEdit(false)
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };


    let UserGroupData = []
    for (let i = 0; i < groupAllData.length; i++) {
        let mydata = groupAllData[i]?.values
        UserGroupData.push(mydata)
    };

    let EmployeeCodeData = []
    for (let i = 0; i < employeeCodeData.length; i++) {
        let mydata = employeeCodeData[i]?.values
        EmployeeCodeData.push(mydata)
    };

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality

  return (
      <>
          <div className='main-card card border-0'>
              <div className='card-header mb-1'>
                  <h6 className="m-0">User Master</h6>
              </div>
              <div className='card-body'>
                  <div className='row'>

                    
                      <AutoCompleteBox
                          inputrefs={inputrefs}
                          onKeyDown={(e) => {
                              if (e.key === 'Enter') { inputrefs.current['txtUserName'].focus() };
                          }}
                          label='Employee Code'
                          placeholder='Employee Code'
                          maxLength='10'
                          options={EmployeeCodeData}
                          id='txtEmployeeCode'
                          required={true}
                          error={errors.EmployeeCode}
                          inputValue={recipe.EmployeeCode ? recipe.EmployeeCode : ''}
                          onInputChange={(event, newInputValue) => {
                              HandleChange('EmployeeCode', newInputValue)
                              getEmployeeCodeData(newInputValue)
                          }}
                      />
                      <InputBox
                          inputrefs={inputrefs}
                          onKeyDown={(e) => {
                              if (e.key === 'Enter') { inputrefs.current['txtUserGroup'].focus() };
                          }}
                          label='User Name'
                          id='txtUserName'
                          maxLength='50'
                          placeholder="User Name"
                          required
                          error={errors.UserName}
                          value={recipe.UserName}
                          onChange={(e) => HandleChange('UserName', e.target.value)}
                      />

                      <AutoCompleteBox
                          inputrefs={inputrefs}
                          onKeyDown={(e) => {
                              if (e.key === 'Enter') { inputrefs.current['txtPassword'].focus() };
                          }}
                          label='User Group'
                          placeholder="User Group"
                          maxLength='50'
                          options={UserGroupData}
                          id='txtUserGroup'
                          required={true}
                          error={errors.UserGroup}
                          inputValue={recipe.UserGroup ? recipe.UserGroup : ''}
                          onInputChange={(event, newInputValue) => {
                              HandleChange('UserGroup', newInputValue)
                              getUserGroup(newInputValue)
                          }}
                          
                      />
                      {disableOnEdit === true ? <></> :
                          <PasswordInputBox
                              inputrefs={inputrefs}
                              onKeyDown={(e) => {
                                  if (e.key === 'Enter') { inputrefs.current['txtIsActive'].focus() };
                              }}
                              label='Password'
                              id='txtPassword'
                              maxLength='50'
                              placeholder="Password"
                              required
                              disableOnEdit={disableOnEdit}
                              error={errors.Password}
                              value={recipe.Password}
                              onChange={(e) => HandleChange('Password', e.target.value)}
                          />}
                      <Switch
                          inputrefs={inputrefs}
                          onKeyDown={(e) => {
                              if (e.key === 'Enter') { handleSubmit() };
                          }}
                          label='Active'
                          checked={recipe.Active}
                          id='txtIsActive'
                         // nextinputid='txtFranchiseGroup'
                          onChange={(e) => HandleChange('Active', e.target.checked)}
                      />

                      <FormButton
                          handleSubmit={handleSubmit}
                          handleExport={() => handleDownloadExcel(API_SERVER + 'api/user/ShowUserMaster', ExportRequest, 'User Master')}
                          handleClear={handleClear}
                      />
                  </div>
              </div>
          </div>

          {/* Table Component start*/}
          <Table
              Token={TokenData}
              PageName='UserMaster'
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              handleFilter={() => {
                  getFormData();
                  setFilterData({
                      ...filterData,
                  });
              }}
              tableData={result}
              //  loading={loading}
              filterData={filterData}
              setFilterData={setFilterData}
              currentPageNo={currentPageNo}
              handleChange1={handleChange1}
              handleSorting={handleSorting}
              sortConfig={sortConfig}
          /> 
          {/* Table Component End*/}


          {/* Loder start*/}
          {/*  {loading && < BlurLoader />}
          {/* Loder End*/}
      </>
  )
}

export default UserMaster