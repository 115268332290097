///<reference path="LoanTypeMaster.jsx" />
import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../context/auth';
import { AutoCompleteBox, DateBox, FormButton, InputBox, SelectInputBox } from "../commoncomponents/InputBox";
import { Table } from '../commoncomponents/Table';
//loder
import BlurLoader from "../Loder/BlurLoader";
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, initializeFilterData } from '../../Utils';
//services
import { getCommonData } from '../../apiservices/CommonServices';
import { post } from '../../apiservices/service';
import { Token, dateFormate, number } from '../Common/Common';

function EmployeeRejoining() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();
    //token Data
    //const TokenData = {
    //    AuthToken: process.env.REACT_APP_SECRET_KEY,
    //    LoginToken: user.loginsessionID,
    //    UserID: user.userID,
    //    CompanyID: user.companyID
    //}
    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({
        empCode: '',
        mobileNo: '',
        aadhaarCardNo: '',
        branch: "",
        designation: "",
        department: "",
        doj: '',
        //confirmationDate: '',
        contractPeriod: "",
        remarks: "",
    })

    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData);
    const [isUpdate, setIsUpdate] = useState(false);
    const [errors, setErrors] = useState({});
    const [ShowEdit, setShowEdit] = useState(false);
    const [ShowTable, setShowTable] = useState(false);
    const [branchAllData, setBranchAllData] = useState([])
    const [companyAllData, setCompanyAllData] = useState([])
    const [employeeAllData, setEmployeeAllData] = useState([])
    const [departmentAllData, setDepartmentAllData] = useState([])
    const [designationAllData, setDesignationAllData] = useState([])
    //  const [EmpCode, setEmpCode] = useState()
    const [yearData, setYearData] = useState([]);
    const [data, setData] = useState({});



    //Get company Data for AutoComplete
    const GetCompanyData = (value) => {
        setCompanyAllData([])
        let temp = {
            Type: "GetCompany",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        //   setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setCompanyAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setCompanyAllData(data)
                }
            }
        });
    }

    //Get branch Data for AutoComplete
    const GetBranchData = (value) => {
        setBranchAllData([])
        let temp = {
            Type: "GetBranch",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        //   setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setBranchAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setBranchAllData(data)
                }
            }
        });
    }

    //Get Employee Data for AutoComplete
    const GetEmployeeData = (value) => {
        setEmployeeAllData([])
        let temp = {
            Type: "GetRetiredEmployee",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        if (value) getFormData(value);
        //if (!temp.Prefix) {
            //setShowEdit(false);
            //setRecipe(recipe => ({
            //    ...recipe,
            //    empCode: '',
            //    mobileNo: '',
            //    aadhaarCardNo: '',
                //branch: "",
                //designation: "",
                //department: "",
                //doj: dateFormate(new Date()),
                //confirmationDate: dateFormate(new Date()),
                //contractPeriod: "",
                //remarks: "",
          //  }))
          //  setShowTable(false)
       //}
        //if (temp.Prefix) {
        //    setRecipe({...recipe, mobileNo:data.MobileNo ,aadhaarCardNo:data.AadhaarNo,
        //    })
        //    console.log(recipe, '11111111111111111111111',data)
        //}

        // setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setEmployeeAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setEmployeeAllData(data)
                }
            }
        });
    }

    //Get Department Data for AutoComplete
    const GetDepartmentData = (value) => {
        setDepartmentAllData([])
        let temp = {
            Type: "GetDepartment",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        //    setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setDepartmentAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setDepartmentAllData(data)
                }
            }
        });
    }

    //Get Designation Data for AutoComplete
    const GetDesignationData = (value) => {
        setDesignationAllData([])
        let temp = {
            Type: "GetDesignation",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        //   setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setDesignationAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setDesignationAllData(data)
                }
            }
        });
    }

    // table Data
    const getFormData1 = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s",
            empCode: recipe.empCode,
            mobileNo: recipe.mobileNo,
            aadhaarCardNo: recipe.aadhaarCardNo,
        }
        post(`${API_SERVER}api/Employee/GetRetiredEmployee`, { ...TokenData, ...temp, ...sortConfig, ...filterData }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    toast.error(res.Message)
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setShowTable(true)
                    const tempData = res.Data
                    // console.log("Before Update:", data)
                    setData(prevData => [...tempData]);
                    //console.log(tempData)
                    setLoading(false)
                    setResult({ ...res })
                    setRecipe({ ...recipe, mobileNo: tempData[0].MobileNo, aadhaarCardNo: tempData[0].AadhaarNo, empCode: tempData[0].Employee })
                }
            }
        });
    }
    //console.log(result, "After Update:", data)
    // console.log(result);


    const getFormData = (emp, CurrentPage) => {
        //  console.log(emp, 'employe')
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)
        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s",
            mobileNo: recipe.mobileNo,
            aadhaarCardNo: recipe.aadhaarCardNo,
        }
        post(`${API_SERVER}api/Employee/GetRetiredEmployee`, { ...TokenData, ...temp, ...sortConfig, ...filterData, empCode: emp }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {

                    setLoading(false)
                    setResult({ ...res })
                    setShowTable(true)
                    const tempData = res.Data
                    // console.log("Before Update:", data)
                    setData(prevData => [...tempData]);
                    //console.log(tempData)
                    setRecipe({ ...recipe, mobileNo: tempData[0].MobileNo, aadhaarCardNo: tempData[0].AadhaarNo, empCode: tempData[0].Employee })
                }
            }
        });
    }





    const handleChange1 = (event, value) => {
        getFormData(recipe.empCode, value);
        //tableData(value)

    };

    useEffect(() => {

        inputrefs.current['txtEmployee'].focus()
        document.title = 'Pay Plus : Employee Rejoining';
        //getFormData()
        if (ShowTable) {
            // GetCompanyData();
            //  GetBranchData();
           // GetEmployeeData();
            // GetDepartmentData();
            //   GetDesignationData();
        }
        //GetEmployeeData();
    }, [ShowTable])


    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [

            { field: "branch", msg: 'Please Select Branch.' },
            { field: "company", msg: 'Please Select Company.' },
            { field: "department", msg: 'Please Select Department.' },
            { field: "designation", msg: 'Please Select Designation.' },
            { field: "doj", msg: 'Please Enter Date of Joining.' },
            // { field: "confirmationDate", msg: 'Please Enter Confirmation Date.' },
            { field: "contractPeriod", msg: 'Please Select Contract Period.' },

        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {

                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }


    //Submit Handler
    const handleSubmit = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/Employee/InsertUpdateEmployeeRejoining`, { ...TokenData, ...recipe }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        setLoading(false)
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "UNAUTHORIZED") {
                        toast.error(res.Message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        handleClear()
                        //getFormData()
                    }
                }
            });
        }
        else {
            //   toast.error(myString[0]);
            //   inputrefs.current[myString[1]].focus();
        }
    }

    //Handle Edit
    const handleEdit = (Id) => {
        setShowEdit(true)
        ShowEdit && inputrefs.current['txtCompany'].focus()
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            empCode: '',
            mobileNo: '',
            aadhaarCardNo: '',
            branch: "",
            designation: "",
            department: "",
            doj: '',
            // confirmationDate: '',
            contractPeriod: "",
            remarks: "",
        }))
        setErrors({})
        // setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        //  getFormData()
        setShowEdit(false)
        setShowTable(false)
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    let CompanyData = []
    for (let i = 0; i < companyAllData.length; i++) {
        let mydata = companyAllData[i]?.values
        CompanyData.push(mydata)
    };
    let BranchData = []
    for (let i = 0; i < branchAllData.length; i++) {
        let mydata = branchAllData[i]?.values
        BranchData.push(mydata)
    };

    let EmployeeData = []
    for (let i = 0; i < employeeAllData.length; i++) {
        let mydata = employeeAllData[i]?.values
        EmployeeData.push(mydata)
    };
    let DepartmentData = []
    for (let i = 0; i < departmentAllData.length; i++) {
        let mydata = departmentAllData[i]?.values
        DepartmentData.push(mydata)
    };
    let DesignationData = []
    for (let i = 0; i < designationAllData.length; i++) {
        let mydata = designationAllData[i]?.values
        DesignationData.push(mydata)
    };

    // const mNo = data[0]?.MobileNo

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality


    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Employee Rejoining</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>


                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtMobileNo'].focus()
                                    //    getFormData()
                                };
                            }}
                            label='Employee'
                            placeholder="Employee"
                            //divclassname='col-lg-6  '
                            maxLength='10'
                            options={EmployeeData}
                            id='txtEmployee'
                            key='txtEmployee'
                            onFocus={() => GetEmployeeData()}
                            required={true}
                            error={errors.empCode}
                            inputValue={recipe.empCode ? recipe.empCode : ''}
                            onInputChange={(event, newInputValue) => {
                                GetEmployeeData(newInputValue)
                                HandleChange('empCode', newInputValue)
                                console.log(newInputValue, '00000000000000')
                                if (newInputValue === '') {
                                    handleClear()
                                }
                                // getFormData()

                            }}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtPayslipMonth'].focus() };
                            }}
                            label='Mobile No.'
                            id='txtMobileNo'
                            key='txtMobileNo'
                            maxLength='10'
                            placeholder="Mobile No"
                            //   disabled={recipe.empCode}
                            disabled={ShowTable}
                            // required
                            value={recipe.mobileNo}
                            error={errors.mobileNo}
                            onChange={(e) => {
                                HandleChange('mobileNo', number(e.target.value))
                            }}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtPayslipMonth'].focus() };
                            }}
                            label='Aadhaar Card No.'
                            id='txtAadhaarCardNo'
                            key='txtAadhaarCardNo'
                            maxLength='10'
                            placeholder="Aadhaar Card No"
                            // disabled={recipe.empCode}
                            disabled={ShowTable}
                            // required
                            value={recipe.aadhaarCardNo}
                            error={errors.aadhaarCardNo}
                            onChange={(e) => { HandleChange('aadhaarCardNo', number(e.target.value)) }}
                        />



                        {ShowEdit && <>
                            <AutoCompleteBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtBranch'].focus() };
                                }}
                                
                                label='Company'
                                placeholder="Company"
                                maxLength='50'
                                options={CompanyData}
                                id='txtCompany'
                                key='txtCompany'
                                required={true}
                                error={errors.company}
                                inputValue={recipe.company ? recipe.company : ''}
                                onInputChange={(event, newInputValue) => {
                                    GetCompanyData(newInputValue)
                                    HandleChange('company', newInputValue)
                                }}
                                onFocus={() => GetCompanyData()}
                            />
                            <AutoCompleteBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtDepartment'].focus() };
                                }}
                                label='Branch'
                                placeholder="Branch"
                                maxLength='50'
                                options={BranchData}
                                id='txtBranch'
                                key='txtBranch'
                                required={true}
                                error={errors.branch}
                                inputValue={recipe.branch ? recipe.branch : ''}
                                onInputChange={(event, newInputValue) => {
                                    GetBranchData(newInputValue)
                                    HandleChange('branch', newInputValue)

                                }}
                                onFocus={() => GetBranchData()}
                            />

                            <AutoCompleteBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtDesignation'].focus() };
                                }}
                                label='Department'
                                placeholder="Department"
                                maxLength='50'
                                options={DepartmentData}
                                id='txtDepartment'
                                key='txtDepartment'
                                required={true}
                                error={errors.department}
                                inputValue={recipe.department ? recipe.department : ''}
                                onInputChange={(event, newInputValue) => {
                                    GetDepartmentData(newInputValue)
                                    HandleChange('department', newInputValue)

                                }}
                                onFocus={() => GetDepartmentData()}
                            />
                            <AutoCompleteBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtDOJ'].focus() };
                                }}
                                label='Designation'
                                placeholder="Designation"
                                maxLength='50'
                                options={DesignationData}
                                id='txtDesignation'
                                key='txtDesignation'
                                required={true}
                                error={errors.designation}
                                inputValue={recipe.designation ? recipe.designation : ''}
                                onInputChange={(event, newInputValue) => {
                                    GetDesignationData(newInputValue)
                                    HandleChange('designation', newInputValue)

                                }}
                                onFocus={() => GetDesignationData()}
                            />

                            <DateBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtContractPeriod'].focus() };
                                }}
                                label='Date of Joining'
                                // labelClass='text-nowrap'
                                // divclassname='col-lg-6'
                                id='txtDOJ'
                                selected={recipe.doj === '' ? '' : new Date(recipe.doj)}
                                placeholder="Date of Joining"
                                onChange={(e) => HandleChange('doj', dateFormate(e))}
                                required
                                error={errors.doj}
                            />
                            {/*   <div className={`col-md-3 mb-1 cntrl-width`} key="">
                                <label className="form-label">Contract Period<span style={{ color: "red" }}> *</span></label>
                                <select
                                    ref={ref => (inputrefs.current['txtContractPeriod'] = ref)}
                                    className="form-control"
                                    id="txtContractPeriod"
                                    value={recipe.contractPeriod}
                                    //error={errors.contractPeriod}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtRemarks'].focus() };
                                    }}
                                    onChange={(e) => HandleChange('contractPeriod', e.target.value)}>
                                    <option value="">--Select--</option>
                                    <option value="Month">Month</option>
                                    <option value="Days">Days</option>

                                </select>
                                <div className="error-msg">{errors.contractPeriod}</div>
                            </div>
                            */}
                            <SelectInputBox
                                label='Contract Period'
                                inputrefs={inputrefs}
                                required
                                id="txtContractPeriod"
                                value={recipe.contractPeriod}
                                error={errors.contractPeriod}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtRemarks'].focus() };
                                }}
                                onChange={(e) => HandleChange('contractPeriod', e.target.value)}
                                children={<>
                                    <option value="">--Select Contract Period--</option>
                                    <option value="Month">Month</option>
                                    <option value="Days">Days</option>
                                </>}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { handleSubmit() };
                                }}
                                label='Remarks'
                                id='txtRemarks'
                                key='txtRemarks'
                                maxLength='100'
                                placeholder="Remarks"
                                //disabled={ShowEdit}
                                // required
                                value={recipe.remarks}
                                error={errors.remarks}
                                onChange={(e) => { HandleChange('remarks', (e.target.value)) }}
                            />


                            <FormButton
                                Export={false}
                                handleSubmit={handleSubmit}
                                //handleExport={() => handleDownloadExcel(API_SERVER + 'api/Loan/ShowLoanTypeMaster', ExportRequest, 'Loan Type Master')}
                                handleClear={handleClear}
                            />
                        </>}

                    </div>
                </div>
            </div>
            {ShowTable &&
                <Table
                    Filter={false}
                    totalRecordText='Histroy'
                    Token={TokenData}
                    PageName='LoanRepay'
                    handleEdit={handleEdit}
                    //handleDelete={handleDelete}
                    // handleFilter={() => {
                    //      getFormData();
                    //      setFilterData({
                    //            ...filterData,
                    //          });
                    //       }}
                    tableData={result}
                    loading={loading}
                //     filterData={filterData}
                //    setFilterData={setFilterData}
                //  currentPageNo={currentPageNo}
                // handleChange1={handleChange1}
                //    handleSorting={handleSorting}
                //  sortConfig={sortConfig}
                />
            }
            {loading && < BlurLoader />}
        </>
    )
}

export default EmployeeRejoining
