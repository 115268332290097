
import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, AutoCompleteBoxWithLabel, DateBox, YearBox, MonthBox } from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
//loder
import BlurLoader from "../Loder/BlurLoader";
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { Token, currentMonth, currentYear, dateFormate, number, numberwithDot } from '../Common/Common';

function UnFreezeSalary() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();

    //token Data
    const TokenData = Token()

    //initial state
    const [recipe, setRecipe] = useState({
        //  company: '',
        branch: '',
        state: '',
        employee: '',
        month: '',
        year: '',
    })
    const [loading, setLoading] = useState(false);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [branchAllData, setBranchAllData] = useState([])
    const [stateAllData, setStateAllData] = useState([])
    const [employeeAllData, setEmployeeAllData] = useState([])
    const [errors, setErrors] = useState({});



    //Get branch Data for AutoComplete
    const GetBranchData = (value) => {
        setBranchAllData([])
        let temp = {
            Type: "GetBranch",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        //  setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setBranchAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setBranchAllData(data)
                }
            }
        });
    }

    //Get state Data for AutoComplete
    const GetStateData = (value) => {
        setStateAllData([])
        let temp = {
            Type: "GetState",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        //  setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setStateAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setStateAllData(data)
                }
            }
        });
    }



    //Get Employee Data for AutoComplete
    const GetEmployeeData = (value) => {
        setEmployeeAllData([])
        let temp = {
            Type: "GetEmployee",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        //  setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setEmployeeAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setEmployeeAllData(data)
                }
            }
        });
    }

    // Handle UnFreeze Salary 
    const HandleUnFreezeSalary = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/Transaction/UnFreezeSalary`, { ...TokenData, ...recipe, month: currentMonth(recipe.month, 'numeric'), year: recipe.year ? currentYear(recipe.year) : '' }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        setLoading(false)
                        toast.error(res.message)
                    } else if (res.status === "EXPIRED") {
                        toast.error(res.message)
                        sessionExpired()
                    } else if (res.status === "UNAUTHORIZED") {
                        toast.error(res.message);
                        setLoading(false);
                    } else if (res.status === "SUCCESS") {
                        setLoading(false)
                        toast.success(res.message)
                    } else {
                        setLoading(false)
                        toast.error(res.message)

                    }
                }
            });
        }
    }

    useEffect(() => {
        inputrefs.current['txtBranch'].focus();
        document.title = 'Pay Plus : UnFreeze Salary';
        GetBranchData();
        GetStateData();
        GetEmployeeData();
    }, [])


    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    // Handle Validations
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "employee", msg: 'Please Enter Employee.' },
            { field: "month", msg: 'Please Enter Month.' },
            { field: "year", msg: 'Please Enter Year.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            branch: '',
            state: '',
            employee: '',
            month: '',
            year: '',
        }))
        setErrors({})
        //setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        //setIsUpdate(!isUpdate)
    }

    //const handleSorting = (SortColumn) => {
    //    let SortOrder = 'ASC';

    //    if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
    //        SortOrder = 'DESC';
    //    }
    //    setSortConfig({ SortColumn, SortOrder });
    //};


    let BranchData = []
    for (let i = 0; i < branchAllData.length; i++) {
        let mydata = branchAllData[i]?.values
        BranchData.push(mydata)
    };

    let StateData = []
    for (let i = 0; i < stateAllData.length; i++) {
        let mydata = stateAllData[i]?.values
        StateData.push(mydata)
    };

    let EmployeeData = []
    for (let i = 0; i < employeeAllData.length; i++) {
        let mydata = employeeAllData[i]?.values
        EmployeeData.push(mydata)
    };

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">UnFreeze Salary</h6>
                </div>
                <div className='card-body'>
                    <div className='form-group ms-2'>

                        <div className='row'>
                            <AutoCompleteBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtEmployee'].focus() };
                                }}
                                label='Branch'
                                placeholder="Branch"
                                maxLength='50'
                                options={BranchData}
                                id='txtBranch'
                                key='txtBranch'
                                //required={true}
                                // error={errors.branch}
                                inputValue={recipe.branch ? recipe.branch : ''}
                                onInputChange={(event, newInputValue) => {
                                    GetBranchData(newInputValue)
                                    HandleChange('branch', newInputValue)

                                }}
                            />
                            <AutoCompleteBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtMonth'].focus() };
                                }}
                                label='Employee'
                                placeholder="Employee"
                                maxLength='50'
                                options={EmployeeData}
                                id='txtEmployee'
                                key='txtEmployee'
                                required={true}
                                error={errors.employee}
                                inputValue={recipe.employee ? recipe.employee : ''}
                                onInputChange={(event, newInputValue) => {
                                    GetEmployeeData(newInputValue)
                                    HandleChange('employee', newInputValue)

                                }}
                            />
                            <MonthBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        if (e.key === 'Enter') { inputrefs.current['txtYear'].focus() };
                                    };
                                }}
                                label='Month'
                                // labelClass='text-nowrap'
                                //customclass='w-25'
                                // divclassname='col-lg-6'
                                id='txtMonth'
                                selected={recipe.month ? recipe.month : ""}
                                placeholder="Month"
                                onChange={(e) => {
                                    HandleChange('month', e)
                                }}
                                required
                                error={errors.month}
                            />
                            <YearBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        if (e.key === 'Enter') { inputrefs.current['txtState'].focus() };
                                    };
                                }}
                                label='Year'
                                // labelClass='text-nowrap'
                                // divclassname='col-lg-4'
                                id='txtYear'
                                selected={recipe.year ? recipe.year : ""}
                                placeholder="Year"
                                onChange={(e) => {
                                    HandleChange('year', e)
                                }}
                                required
                                error={errors.year}
                            />
                            <AutoCompleteBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtEmployee'].focus() };
                                }}
                                label='State'
                                placeholder="State"
                                maxLength='50'
                                options={StateData}
                                id='txtState'
                                key='txtState'
                                //required={true}
                                // error={errors.state}
                                inputValue={recipe.state ? recipe.state : ''}
                                onInputChange={(event, newInputValue) => {
                                    GetStateData(newInputValue)
                                    HandleChange('state', newInputValue)

                                }}
                            />

                        </div>
                        <FormButton
                            saveButtonIcon='fa-check'
                            SaveText='UnFreeze Salary'
                            Export={false}
                            handleSubmit={HandleUnFreezeSalary}
                            handleClear={handleClear}
                        />

                    </div>
                </div>
            </div>


            {loading && < BlurLoader />}
        </>
    )
}

export default UnFreezeSalary
