//import { BarChart, DonutChart, SparklineChart } from "./Charts";
import AOS from 'aos';
import React, { useEffect, useState} from 'react';
import { useAuth } from '../../context/auth';
import 'aos/dist/aos.css';
import { API_SERVER} from '../../Utils';
import { post } from '../../apiservices/service';
import { toast } from 'react-toastify';
import { Token} from '../Common/Common';


const Dashboard = () => {
    const [loading, setLoading] = useState(false)
    const { user, sessionExpired } = useAuth();
    const [recipe, setRecipe] = useState([])
    const TokenData = Token()

    useEffect(() => {
        document.title = 'Pay Plus: Dashboard'
        AOS.init(
            {
                duration: 1200,
            }
        );
        ShowInstruction(TokenData.CompanyID)
    }, [])

    const ShowInstruction = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/Dashboard/ShowInstruction`, { ...TokenData, NewCompanyID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.status === "EXPIRED") {
                    toast.error(res.message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setRecipe(res.dataList);
                }
            }
        });
    }
    return (
        <>
            {recipe.length !== 0 ? (
                <div className="container-fluid" id="dvMaster">
                    <div className="col-md-12 col-sm-12">
                        <div className="row page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0 font-size-18">Instructions</h4>
                        </div>
                        <div
                            className="m-2 p-2"
                            style={{
                                border: '1px solid gray',
                                borderRadius: '5px',
                                boxShadow: 'rgba(0, 0, 0, 0.2) 2px 2px 2px',
                            }}
                        >
                            <h6 className="text-danger text-bold font-size-18 align-items-center">
                                Instructions are given below which have to be followed before Onboarding
                            </h6>
                            <ul style={{ listStyleType: 'disc' }}>
                                <li className="fs-6">To insert data, click on button.</li>
                                <li className="fs-6">
                                    Please insert data, if any of these list have cross (
                                    <span className="fa fa-close text-danger"></span>) sign.
                                </li>
                                <li className="fs-6">
                                    You can start onboarding, if all of master's have status Success and check (
                                    <span className="fa fa-check text-success"></span>) sign.
                                </li>
                            </ul>
                        </div>
                        {/*<div className="row margin-top-10" style={{ border: "2px solid #aebfee" }}>*/}
                        {recipe.map((item, index) => (
                            <div className="row">
                                <div className="list-group col-sm-12 d-sm-flex align-items-center page-title-box row-gap-6" id="dvListGroup">
                                    <div style={{ float: 'left' }}
                                        href="#"
                                        id={`dv${item.master}`}
                                        className="row list-group-item list-group-item-action list-group-item-light list-group-item-success"
                                    >
                                        <div className="col-sm-3 col-md-3 col-xs-3" style={{ float: 'left' }}>
                                            <a target="_blank" href={item.uRL} className={item.count > 0 ? "btn-outline-success btn" : "btn-outline-danger btn"} id={`dv${item.master}btn`}>
                                                {item.master}{/*style={{width:'200px' }}*/}
                                            </a>
                                        </div>
                                        <div style={{ float: 'left' }} className={`col-sm-3 col-md-3 col-xs-3 ${item.count > 0 ? "text-success" : "text-danger"}`} id={`dv${item.master}Record`}>
                                            Total Record(s) :&nbsp;{item.count}
                                        </div>
                                        <div style={{ float: 'left' }} className={`col-sm-5 col-md-5 col-xs-5 ${item.count > 0 ? "text-success" : "text-danger"}`}>
                                            {item.count > 0 ? "Success" : `Need to Insert Data in ${item.master}`}</div>

                                        <div style={{ float: 'left' }} className="col-sm-1 col-md-1 col-xs-1">
                                            <span className={item.count > 0 ? "fa-check fa text-success" : "fa-close fa text-danger"} id={`${item.master}Icon`}></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/*</div>*/}
                    </div>
                </div>) : (
                <div className='container-fluid dashboard db-wrapper'>
                    <div className='row '>
                        <h2>Welcome To Pay Plus</h2>
                    </div>
                </div>
            )}
        </>
    )
}
export default Dashboard;
