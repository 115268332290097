import { useEffect } from "react"//import { Document, Page } from 'react-pdf';const PDFView = () => {    useEffect(() => {        document.title = 'PDF Viewer'    }, [])    let url = window.location.href    let pdf = url.split('#')[1]    let title = url.split('#')[0]    const a = document.createElement('a');    a.href = url;    // a.download = "Offer Letter";    // Trigger a click to open the PDF in a new tab    a.click();    // Clean up    URL.revokeObjectURL(url);    return (        <div className='p-0 m-0 container-fluid' style={{ height: '100vh', width: '100%' }}>            <div className='row' style={{ height: "100vh", width: "100%" }}>                <iframe title={title} className='p-0 m-0' src={pdf} />            </div>        </div>    )}export default PDFView//export const Viewer = () => {//    useEffect(() => {
//        document.title = 'PDF Viewer'
//    }, [])

//    let url = window.location.href
//    let pdf = url.split('#')[1]
//    let title = url.split('#')[0]

//    return (
//        <div className='p-0 m-0 container-fluid' style={{ height: '100vh', width: '100%' }}>
//            <div className='row' style={{ height: "100vh", width: "100%" }}>
//                <iframe className='p-0 m-0' title={title} src={pdf} />
//            </div>
//        </div>
//    )
//}

export const ImageView = () => {    useEffect(() => {        document.title = 'Image Viewer'    }, [])    let url = window.location.href    let img = url.split('#')[1]    let title = url.split('#')[0]    const a = document.createElement('a');    a.href = url;    // a.download = "Offer Letter";    // Trigger a click to open the PDF in a new tab    a.click();    // Clean up    URL.revokeObjectURL(url);    return (        <div className='p-0 m-0 container-fluid'>            <div className='container  ' style={{ marginTop: "18%" }}>                <div className='row' style={{ height: "100%", width: "100%", overflow: 'auto' }}>                    <center> <img title={title} className='p-0 m-0' src={img} alt={title} style={{ height: "25vh", width: "25vh" }} /></center>                </div>            </div>        </div>    )}