import React, { useEffect, useState, useRef } from 'react'
//import { Document, Page } from 'react-pdf';
import { InputBox, FormButton, AutoCompleteBox, Switch, DateBox, CheckBoxInput, SelectInputBox } from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';

//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';

//services
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { post } from '../../apiservices/service';
import { Token, dateFormate, number, numberwithDot } from '../Common/Common';
import SalaryStructure from './SalaryStructure';
import PdfViewer from '../commoncomponents/PdfView';

function CandidateRegistration() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();

    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({
        CandidateID: '0',
        CandidateName: '',
        FatherName: '',
        DOB: '',
        // DOBE: '',
        MobileNo: '',
        EmailID: '',
        Department: '',
        Designation: '',
        InterviewDate: '',
        DOJ: '',
        Gender: '',
        Branch: '',
        Company: TokenData.Company,
        PFApplicable: true,
    })

    const [salaryStructureRecipe, setSalaryStructureRecipe] = useState({
        TotalAmount: '',
        DeductionTotal: '',
        GrossTotal: '',
        TotalSalaryAmount: '',
        AllowanceList: [],
        DeductionList: [],
        CTC: '',
        NTH: '',
        EmployerContribution: '',
    })
    const [candidateID, setCandidateID] = useState('')
    const [errors, setErrors] = useState({});
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [companyAllData, setCompanyAllData] = useState([])
    const [departmentAllData, setDepartmentAllData] = useState([])
    const [designationAllData, setDesignationAllData] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const [dateOfBirth, setDateOfBirth] = useState(new Date());
    // const [dateOfBirthE, setDateOfBirthE] = useState(new Date());
    const [interviewDate, setInterviewDate] = useState(new Date());
    const [dateOfJoining, setDateOfJoining] = useState(new Date());
    const [branchAllData, setBranchAllData] = useState([])
    const [isCalculate, setIsCalculate] = useState(false);
    const [AutoTotalAmount, setAutoTotalAmount] = useState('');
    const [CalculationAmountTypeAllData, setCalculationAmountTypeAllData] = useState([])
    const [CalculationAmountType, setCalculationAmountType] = useState('CTC');

    const [valueChanged, setvalueChanged] = useState(false);


    //Get Company Autocomplete Data
    const getCompanyData = () => {
        let temp = {
            Type: "GetCompany",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setCompanyAllData([])
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setCompanyAllData(data)
                }
            }
        });
    }

    //Get Department Autocomplete Data
    const getDepartmentData = (value) => {
        let temp = {
            Type: "GetCompanyWiseDepartment",
            Prefix: '',
            ContextKey: value ? value : '',
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setDepartmentAllData([])
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setDepartmentAllData(data)
                }
            }
        });
    }

    //Get Branch Autocomplete Data
    const getBranchData = (value) => {
        let temp = {
            Type: "GetCompanyWiseBranch",
            Prefix: '',
            ContextKey: value ? value : '',
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setBranchAllData([])
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setBranchAllData(data)
                }
            }
        });
    }

    //Get Calculation Amount Type Autocomplete Data
    const GetCalculationAmountTypeData = (value) => {
        let temp = {
            Type: "GetCalculationOn",
            Prefix: '',
        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setCalculationAmountTypeAllData([])
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setCalculationAmountTypeAllData(data)
                }
            }
        });
    }

    //Get Designation Autocomplete Data
    const getDesignationData = (value) => {
        let temp = {
            Type: "GetCompanyWiseDesignation",
            Prefix: '',
            ContextKey: value ? value : '',
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setDesignationAllData([])
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setDesignationAllData(data)
                }
            }
        });
    }

    // Get Main Table Data
    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/Candidate/ShowCandidate`, { ...TokenData, ...temp, ...sortConfig, ...filterData }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }

    // Salary Structure Table Data
    const tableData = (ID) => {
        post(`${API_SERVER}api/Candidate/GetAllowanceAndDeductionDetails`, { ...TokenData, CandidateID: ID, Type: "Candidate", EffectiveDate: "" }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    //toast.error(res.message)
                    setLoading(false)
                } else if (res.status === "EXPIRED") {
                    toast.error(res.message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    //toast.success(res.message)
                    let data = res.data
                    setSalaryStructureRecipe({
                        ...salaryStructureRecipe,
                        DeductionTotal: data.deductionTotal,
                        GrossTotal: data.grossTotal,
                        DeductionList: data.deductionList,
                        AllowanceList: data.allowanceList,
                        NTH: data.nth,
                        EmployerContribution: data.employerContribution,
                        CTC: data.ctc,
                        TotalSalaryAmount: data.totalSalaryAmount,

                    })
                    
                }
            }
        })
    }

    // Handle AutoCalculate Salary Structure Data
    const HandleBlur = () => {
        if (!CalculationAmountType) {
            return toast.error('Please select Calculation On.!')
        } else if (!AutoTotalAmount || AutoTotalAmount === '0') {
            return toast.error('Please Fill Total Amount')
        } else {
            const temp = {
                CalType: CalculationAmountType ? CalculationAmountType : '',
                TotalAmount: AutoTotalAmount,
                PFApplicable: recipe.PFApplicable,
                CandidateID: candidateID ? candidateID : '0',
                Branch: recipe.Branch,
            };
            post(`${API_SERVER}api/Candidate/GetCandidateSalaryStructureCalculation`, { ...TokenData, ...temp }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        setLoading(false)
                    } else if (res.status === "EXPIRED") {
                        toast.error(res.message)
                        sessionExpired()
                    } else if (res.status === "UNAUTHORIZED") {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        //toast.success(res.message)
                        let data = res.data
                        setSalaryStructureRecipe({
                            ...salaryStructureRecipe,
                            DeductionTotal: data.deductionTotal,
                            GrossTotal: data.grossTotal,
                            DeductionList: data.deductionList,
                            AllowanceList: data.allowanceList,
                            NTH: data.nth,
                            EmployerContribution: data.employerContribution,
                            CTC: data.ctc,
                            TotalSalaryAmount: data.totalSalaryAmount,
                        })
                        setvalueChanged(true)
                    }
                }
            })
        }
    }

    // Handle Change for Table
    const handleChange1 = (event, value) => {
        getFormData(value);
    };

    useEffect(() => {
        inputrefs.current['txtCompany'].focus()
        document.title = 'Pay Plus: Candidate Registration'
        getDepartmentData()
        getCompanyData()
        getDesignationData()
        getBranchData()
        getFormData()
        tableData()
        GetCalculationAmountTypeData()
    }, [sortConfig, isUpdate])

    //useEffect(() => {
    //    getFormData()
    //}, [isUpdate])

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    // HandelChange For SalaryStruture 
    const HandleSalaryStructureChange = (prop, value, index, type) => {
        if (type === 'allowanceList') {
            let list = [...salaryStructureRecipe.AllowanceList];
            list[index] = { ...list[index], [prop]: value };
            setSalaryStructureRecipe({ ...salaryStructureRecipe, AllowanceList: list });
        } else if (type === 'deductionList') {
            let list = [...salaryStructureRecipe.DeductionList];
            list[index] = { ...list[index], [prop]: value };
            setSalaryStructureRecipe({ ...salaryStructureRecipe, DeductionList: list });
        } else {
            setSalaryStructureRecipe(salaryStructureRecipe => ({ ...salaryStructureRecipe, [prop]: value }));
        }
        setvalueChanged(true)
    }


    //validation handler
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "CandidateName", msg: 'Please Enter Candidate Name.' },
            { field: "FatherName", msg: 'Please Enter Father Name.' },
            { field: "DOB", msg: 'Please Enter DOB.' },
            { field: "Gender", msg: 'Please Enter Gender.' },
            { field: "MobileNo", msg: 'Please Enter Mobile No.' },
            { field: "EmailID", msg: 'Please Enter Email ID.' },
            { field: "Company", msg: 'Please Select Company.' },
            { field: "Department", msg: 'Please Select Department.' },
            { field: "Designation", msg: 'Please Select Designation.' },
            { field: "InterviewDate", msg: 'Please Select Interview Date.' },
            { field: "DOJ", msg: 'Please Enter DOJ.' },
            { field: "Branch", msg: 'Please Enter Branch.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        return isValid;
    }

    //Handle Save as Draft
    const handleSubmit = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/Candidate/CandidateRegistration`, {
                ...TokenData, ...recipe, IsSendMail: false, ...salaryStructureRecipe
            }, (res) => {

                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        setLoading(false)
                    } else if (res.status === "EXPIRED") {
                        toast.error(res.message)
                        sessionExpired()
                    } else if (res.status === "UNAUTHORIZED") {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        getFormData()
                        handleClear()
                    }
                }
            });
        }
    }
    //Handle Save & Send Mail
    const handleSaveAndMail = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/Candidate/CandidateRegistration`, {
                ...TokenData, ...recipe, IsSendMail: true, ...salaryStructureRecipe
            }, (res) => {

                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        setLoading(false)
                    } else if (res.status === "EXPIRED") {
                        toast.error(res.message)
                        sessionExpired()
                    } else if (res.status === "UNAUTHORIZED") {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        getFormData()
                        handleClear()
                    }
                }
            });
        }
    }

    //Handle Edit
    const handleEdit = (Id) => {
        setCandidateID(Id);
        setLoading(true)
        post(`${API_SERVER}api/Candidate/EditCandidate`, { ...TokenData, CandidateID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.status === "EXPIRED") {
                    toast.error(res.message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    let data = res.data
                    setRecipe(recipe => ({
                        CandidateID: data.candidateID,
                        CandidateName: data.candidateName,
                        FatherName: data.fatherName,
                        DOB: data.dob,
                        MobileNo: data.mobileNo,
                        EmailID: data.emailID,
                        Company: data.company,
                        Department: data.department,
                        Designation: data.designation,
                        InterviewDate: data.interviewDate,
                        DOJ: data.doj,
                        Gender: data.gender,
                        Branch: data.branch,
                        PFApplicable: data.pfApplicable,
                    }))
                    tableData(Id)
                    setIsCalculate(false)
                    setvalueChanged(true)
                }
            }
        });
    }

    //Handle Delete
    const handleDelete = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/Candidate/DeleteCandidate`, { ...TokenData, CandidateID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.status === "EXPIRED") {
                    toast.error(res.message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    toast.success(res.message)
                    setLoading(false)
                    getFormData()
                }
            }
        });
    }

    const handlePrintPdfData = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/Candidate/PrintCandidateOfferLetter`, { ...TokenData, CandidateID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.status === "EXPIRED") {
                    toast.error(res.message)
                    sessionExpired()
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    let base64PDF = res && res.base64PDF
                    window.open(`/PdfView/#data:application/pdf;base64,${base64PDF}`, '_blank')
                }
            }
        });
    }

    // Salary Calculation
    const handleSalaryCalculation = () => {
        const temp = {
            EffectiveDate: ``,
            EmpCode: candidateID ? candidateID : '0',
            Branch: recipe.Branch,
            PFApplicable: recipe.PFApplicable,
            CalType: CalculationAmountType ? CalculationAmountType : '',
        }

        //   console.log(recipe.AllowanceList, 'SalarySalarySalarySalarySalary', allTableData?.allowanceList)
        post(`${API_SERVER}api/Candidate/GetSalaryStructureCalculationOnChange`, { ...TokenData, ...salaryStructureRecipe, ...temp, }, (res) => {
            // post(`${API_SERVER}api/Employee/GetSalaryStructureCalculation`, { ...TokenData, ...recipe, DeductionList: allTableData?.deductionList, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    //toast.success(res.message)
                    let data = res.data
                    setSalaryStructureRecipe({
                        ...salaryStructureRecipe,
                        DeductionTotal: data.deductionTotal,
                        GrossTotal: data.grossTotal,
                        DeductionList: data.deductionList,
                        AllowanceList: data.allowanceList,
                        NTH: data.nth,
                        EmployerContribution: data.employerContribution,
                        CTC: data.ctc,
                        TotalSalaryAmount: data.totalSalaryAmount,
                    })
                    setvalueChanged(false)
                }
            }
        })
    }



    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            CandidateID: '0',
            CandidateName: '',
            FatherName: '',
            DOB: '',
            MobileNo: '',
            EmailID: '',
            Department: '',
            Designation: '',
            InterviewDate: '',
            DOJ: '',
            Gender: '',
            Branch: '',
            Company: TokenData.Company,
            PFApplicable: false,

        }))
        setAutoTotalAmount('')
        setErrors({})
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        setCandidateID('')
        tableData()
        setIsCalculate(false)

    }

    // Handle Sorting 
    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };


    let CompanyData = []
    for (let i = 0; i < companyAllData.length; i++) {
        let mydata = companyAllData[i]?.values
        CompanyData.push(mydata)
    };

    let DepartmentData = []
    for (let i = 0; i < departmentAllData.length; i++) {
        let mydata = departmentAllData[i]?.values
        DepartmentData.push(mydata)
    };

    let BranchData = []
    for (let i = 0; i < branchAllData.length; i++) {
        let mydata = branchAllData[i]?.values
        BranchData.push(mydata)
    };


    let DesignationData = []
    for (let i = 0; i < designationAllData.length; i++) {
        let mydata = designationAllData[i]?.values
        DesignationData.push(mydata)
    };

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality


    useEffect(() => {
        if (valueChanged) {
            handleSalaryCalculation();
        }
    }, [salaryStructureRecipe.AllowanceList]);
    return (
        <>
            <div>

                <>
                    <div className='main-card card border-0'>
                        <div className='card-header mb-1'>
                            <h6 className="m-0">Candidate Registration</h6>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <AutoCompleteBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtName'].focus() };
                                    }}
                                    label='Company'
                                    placeholder="Company"
                                    maxLength='50'
                                    options={CompanyData}
                                    id='txtCompany'
                                    required={true}
                                    error={errors.Company}
                                    inputValue={recipe.Company ? recipe.Company : ''}
                                    onInputChange={(event, newInputValue) => {
                                        HandleChange('Company', newInputValue)
                                        getBranchData(newInputValue);
                                        getDepartmentData(newInputValue);
                                        getDesignationData(newInputValue);
                                        GetCalculationAmountTypeData();

                                    }}
                                />
                                <InputBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtFatherName'].focus() };
                                    }}
                                    label='Candidate Name'
                                    id='txtName'
                                    maxLength='50'
                                    placeholder="Candidate Name"
                                    value={recipe.CandidateName}
                                    onChange={(e) => { HandleChange('CandidateName', (e.target.value)) }}
                                    required
                                    error={errors.CandidateName}
                                />
                                <InputBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtDOB'].focus() };
                                    }}
                                    label={`Father's Name`}
                                    id='txtFatherName'
                                    maxLength='50'
                                    placeholder={`Father's Name`}
                                    value={recipe.FatherName}
                                    onChange={(e) => { HandleChange('FatherName', (e.target.value)) }}
                                    required
                                    error={errors.FatherName}
                                />

                                <DateBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtGender'].focus() };
                                    }}
                                    label='DOB'
                                    id='txtDOB'
                                    selected={recipe.DOB === '' ? "" : new Date(recipe.DOB)}
                                    placeholder="DOB"
                                    onChange={(e) => HandleChange('DOB', dateFormate(e))}
                                    required
                                    error={errors.DOB}
                                />
                                <SelectInputBox
                                    label='Gender'
                                    id="txtGender"
                                    value={recipe.Gender}
                                    error={errors.Gender}
                                    inputrefs={inputrefs}

                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtMobileNo'].focus() };
                                    }}
                                    onChange={(e) => HandleChange('Gender', e.target.value)}
                                    children={<>
                                        <option value="">--Select Gender--</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </>}
                                />
                                <InputBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtEmailID'].focus() };
                                    }}
                                    label='Mobile No.'
                                    id='txtMobileNo'
                                    maxLength='10'
                                    placeholder="Mobile No."
                                    required
                                    error={errors.MobileNo}
                                    value={recipe.MobileNo} onChange={(e) => { HandleChange('MobileNo', number(e.target.value)) }}
                                />
                                <InputBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtDepartment'].focus() };
                                    }}
                                    label='Email ID' id='txtEmailID' maxLength='50' placeholder="Email ID" required
                                    error={errors.EmailID}
                                    value={recipe.EmailID} onChange={(e) => { HandleChange('EmailID', (e.target.value)) }}
                                />
                                <AutoCompleteBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtDesignation'].focus() };
                                    }}
                                    label='Department'
                                    placeholder="Department"
                                    maxLength='50'
                                    options={DepartmentData}
                                    id='txtDepartment'
                                    required={true}
                                    error={errors.Department}
                                    inputValue={recipe.Department ? recipe.Department : ''}
                                    onInputChange={(event, newInputValue) => {
                                        HandleChange('Department', newInputValue)
                                        //setStatePre(newInputValue)
                                    }}
                                />
                                <AutoCompleteBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtInterviewDate'].focus() };
                                    }}
                                    label='Designation'
                                    placeholder="Designation"
                                    maxLength='50'
                                    options={DesignationData}
                                    id='txtDesignation'
                                    required={true}
                                    error={errors.Designation}
                                    inputValue={recipe.Designation ? recipe.Designation : ''}
                                    onInputChange={(event, newInputValue) => {
                                        HandleChange('Designation', newInputValue)
                                        //setStatePre(newInputValue)
                                    }}
                                />
                                <DateBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtDOJ'].focus() };
                                    }}
                                    label='Interview Date'
                                    id='txtInterviewDate'
                                    selected={recipe.InterviewDate === '' ? "" : new Date(recipe.InterviewDate)}
                                    placeholder="Interview Date"
                                    //  value={recipe.InterviewDate}
                                    onChange={(e) => HandleChange('InterviewDate', dateFormate(e))}
                                    required
                                    error={errors.InterviewDate}
                                />
                                <DateBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtBranch'].focus() };
                                    }}
                                    label='Date of Joining'
                                    id='txtDOJ'
                                    selected={recipe.DOJ === '' ? "" : new Date(recipe.DOJ)}
                                    placeholder="Date of Joining"
                                    //   value={recipe.DOJ}
                                    onChange={(e) => HandleChange('DOJ', dateFormate(e))}
                                    required
                                    error={errors.DOJ}
                                />
                                <AutoCompleteBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            inputrefs.current['PFApplicable'].focus()
                                        };
                                    }}
                                    label='Branch'
                                    placeholder="Branch"
                                    maxLength='50'
                                    options={BranchData}
                                    id='txtBranch'
                                    required={true}
                                    error={errors.Branch}
                                    inputValue={recipe.Branch ? recipe.Branch : ''}
                                    onInputChange={(event, newInputValue) => {
                                        HandleChange('Branch', newInputValue)
                                        //setStatePre(newInputValue)
                                    }}
                                />
                                <Switch
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtIsCalculate'].focus() };
                                    }}
                                    label='PF Applicable'
                                    checked={recipe.PFApplicable}
                                    id='PFApplicable'
                                    onChange={(e) => {
                                        HandleChange('PFApplicable', e.target.checked)
                                        setvalueChanged(true)
                                    }}
                                />
                                <div className='row ms-0'>
                                    <fieldset className="myfieldset "  >
                                        <legend className="mylegend"> <h6 className="m-0">Salary Structure</h6></legend>
                                        <div className='row'>
                                            <CheckBoxInput
                                                customclass='form-check form-switch ps-3 no-wrap'
                                                divclassname="col-md-3 mt-1"
                                                inputrefs={inputrefs}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current[isCalculate ? 'txtCalculationAmountType' : ''].focus() };
                                                }}

                                                checked={isCalculate}
                                                label='Auto Calculate Salary Structure'
                                                id='txtIsCalculate'
                                                //maxLength='50'
                                                placeholder="Is Calculate"
                                                //  value={recipe.IsCalculate}
                                                onChange={(e) => {
                                                    setIsCalculate(e.target.checked)
                                                    if (e.target.checked) {
                                                        setAutoTotalAmount('')
                                                        setIsCalculate(e.target.checked)
                                                    }
                                                }}
                                            // required

                                            />

                                            {isCalculate &&
                                                <div className='col-md-9'>
                                                    <div className='row'>

                                                        <SelectInputBox
                                                            inputrefs={inputrefs}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') { inputrefs.current['txtTotalAmount'].focus() };
                                                            }}
                                                            label='Calculation On'
                                                            placeholder="Calculation On"
                                                            divclassname='col-md-2'
                                                            maxLength='50'
                                                            id='txtCalculationAmountType'
                                                            required={true}
                                                            error={errors.CalType}
                                                            onChange={(e) => {
                                                                setCalculationAmountType(e.target.value)
                                                                setvalueChanged(true)
                                                            }}
                                                            children={<>{/*<option value="">--Select Calculation On--</option>*/}
                                                                {CalculationAmountTypeAllData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}
                                                            </>}
                                                        />
                                                        <InputBox
                                                            inputrefs={inputrefs}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') { HandleBlur() };
                                                            }}
                                                            divclassname='col-md-2'
                                                            //onBlur={recipe.TotalAmount ? HandleBlur : ''}
                                                            label='Total Salary Amount'
                                                            id='txtTotalAmount'
                                                            maxLength='10'
                                                            placeholder="Total Salary Amount"
                                                            required
                                                            value={AutoTotalAmount}
                                                            onChange={(e) => {
                                                                setAutoTotalAmount(number(e.target.value))
                                                                setvalueChanged(true)
                                                            }}
                                                        />

                                                        <button className='btn btn-primary mb-0 p-0 col-md-1 mt-4 ' onClick={HandleBlur}>Calculate </button>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div className='row'>
                                            <div className='col-md-8 col-lg-8 col-sm-12 col-xs-12' //style={{ width: '70%' }}
                                            >
                                                <div className='form-group ms-2'>
                                                    <fieldset className="myfieldset "  >
                                                        <legend className="mylegend"> <h6 className="m-0">Allowance</h6></legend>
                                                        {salaryStructureRecipe ?
                                                            (salaryStructureRecipe?.AllowanceList?.length !== 0 ?

                                                                <div className="table-responsive">
                                                                    <table className="table table-bordered table-striped table-hover">
                                                                        <thead>
                                                                            <tr className='light-bg'>
                                                                                <th align='left' key="sno">S.No.</th>
                                                                                <th style={{ textAlign: 'left' }} key="allowanceName">Allowance Name</th>
                                                                                <th
                                                                                    style={{ textAlign: 'right' }}
                                                                                    key="amount">Amount</th>
                                                                                <th align='left' key="pf">PF</th>
                                                                                <th align='left' key="esi">ESI</th>
                                                                                <th align='left' key="gross">Gross</th>
                                                                                <th align='left' key="ot">OT</th>
                                                                                <th align='left' key="leaveEncash">Leave</th>
                                                                                <th align='left' key="taxable">Taxable</th>
                                                                                <th align='left' key="calculationType">Calculation Type</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {salaryStructureRecipe.AllowanceList?.map((item, bodyIndex) => (
                                                                                <tr className="TableTrhover" key={item.allowanceID} >
                                                                                    <td style={{ width: '10%' }}>{bodyIndex + 1}</td>
                                                                                    <td style={{ width: '10%' }}>{item.allowanceName}</td>
                                                                                    <td className='text-end p-0 m-0' style={{ height: '1rem', width: '15%' }} key={`${bodyIndex}`}>
                                                                                        <InputBox
                                                                                            inputrefs={inputrefs}
                                                                                            id={`txtamount${item.allowanceID}`}
                                                                                            inputClassName='text-end m-0 p-0 '
                                                                                            divclassname='col-md-12 col-lg-12 m-0 p-0 '
                                                                                            maxLength='10'
                                                                                            //inputStyle={{ width: '5rem' }}
                                                                                            placeholder="Amount"
                                                                                            required
                                                                                            // error={errors.Amount}
                                                                                            key={bodyIndex}
                                                                                            value={item.amount}
                                                                                            onChange={(e) => { HandleSalaryStructureChange(`amount`, numberwithDot(e.target.value), bodyIndex, 'allowanceList') }}
                                                                                        />
                                                                                    </td>
                                                                                    <td style={{ width: '10%' }}>
                                                                                        <input
                                                                                            type='checkbox'
                                                                                            //style={{height:''} }
                                                                                            inputrefs={inputrefs}
                                                                                            checked={item.pf}
                                                                                            id={`txtpf${item.allowanceID}`}
                                                                                            //  value={recipe.IsCalculate}
                                                                                            onChange={(e) => { HandleSalaryStructureChange('pf', (e.target.checked), bodyIndex, 'allowanceList') }}
                                                                                        />
                                                                                    </td>
                                                                                    <td style={{ width: '10%' }}>
                                                                                        <input
                                                                                            type='checkbox'
                                                                                            inputrefs={inputrefs}
                                                                                            checked={item.esi}
                                                                                            id={`txtesi${item.esi}`}
                                                                                            //  value={recipe.IsCalculate}
                                                                                            onChange={(e) => { HandleSalaryStructureChange('esi', (e.target.checked), bodyIndex, 'allowanceList') }}
                                                                                        />
                                                                                    </td>
                                                                                    <td style={{ width: '10%' }}>
                                                                                        <input
                                                                                            type='checkbox'
                                                                                            inputrefs={inputrefs}
                                                                                            checked={item.gross}
                                                                                            id={`txtgross${item.allowanceID}`}
                                                                                            //  value={recipe.IsCalculate}
                                                                                            onChange={(e) => { HandleSalaryStructureChange('gross', (e.target.checked), bodyIndex, 'allowanceList') }}
                                                                                        />
                                                                                    </td>
                                                                                    <td style={{ width: '10%' }}>
                                                                                        <input
                                                                                            type='checkbox'
                                                                                            inputrefs={inputrefs}
                                                                                            checked={item.ot}
                                                                                            id={`txtot${item.allowanceID}`}
                                                                                            //  value={recipe.IsCalculate}
                                                                                            onChange={(e) => { HandleSalaryStructureChange('ot', (e.target.checked), bodyIndex, 'allowanceList') }}
                                                                                        />
                                                                                    </td>
                                                                                    <td style={{ width: '10%' }}> <input
                                                                                        type='checkbox'
                                                                                        inputrefs={inputrefs}
                                                                                        checked={item.leaveEncash}
                                                                                        id={`txtleaveEncash${item.allowanceID}`}
                                                                                        //  value={recipe.IsCalculate}
                                                                                        onChange={(e) => { HandleSalaryStructureChange('leaveEncash', (e.target.checked), bodyIndex, 'allowanceList') }}
                                                                                    /></td>
                                                                                    <td style={{ width: '10%' }}> <input
                                                                                        type='checkbox'
                                                                                        inputrefs={inputrefs}
                                                                                        checked={item.taxable}
                                                                                        id={`txttaxable${item.allowanceID}`}
                                                                                        //  value={recipe.IsCalculate}
                                                                                        onChange={(e) => { HandleSalaryStructureChange('taxable', (e.target.checked), bodyIndex, 'allowanceList') }}
                                                                                    /></td>

                                                                                    <td style={{ width: '10%' }} className='p-0 m-0'>
                                                                                        {/*<select className="form-control col-md-12 col-lg-12 m-0 p-0"*/}
                                                                                        {/*    id="txtDeductionType"*/}
                                                                                        {/*    value={item.calculationType}*/}
                                                                                        {/*  //  error={recipe.DeductionType}*/}
                                                                                        {/*    onChange={(e) => HandleSalaryStructureChange("calculationType", e.target.value, bodyIndex, 'allowanceList')}>*/}
                                                                                        {/*    <option value="On Attendance" >On Attendance</option>*/}
                                                                                        {/*    <option value="Fixed Monthly" >Fixed Monthly</option>*/}
                                                                                        {/*    <option value="Per Day">Per Day</option>*/}
                                                                                        {/*</select>*/}

                                                                                        <SelectInputBox
                                                                                            divclassname=''
                                                                                            inputClassName="form-control col-md-12 col-lg-12 m-0 p-0"
                                                                                            id="txtDeductionType"
                                                                                            inputrefs={inputrefs}
                                                                                            value={item.calculationType}
                                                                                            onChange={(e) => HandleSalaryStructureChange("calculationType", e.target.value, bodyIndex, 'allowanceList')}
                                                                                            children={<>
                                                                                                <option value="On Attendance" >On Attendance</option>
                                                                                                <option value="Fixed Monthly" >Fixed Monthly</option>
                                                                                                <option value="Per Day">Per Day</option>
                                                                                            </>}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                            <tr className='border-white pt-4 bg-white' style={{}}>
                                                                                <th key="grossTotal" className='pt-2 pb-0 text-end ' colSpan='2'>Gross Total</th>
                                                                                <th key="grossTotalAmout" className='p-0 m-0 '>
                                                                                    <InputBox
                                                                                        inputrefs={inputrefs}
                                                                                        //label='Gross Total'
                                                                                        id='txtGrossTotal'
                                                                                        maxLength='10'
                                                                                        placeholder="Gross Total"
                                                                                        required
                                                                                        inputClassName='text-end m-0 p-0'
                                                                                        divclassname='col-md-12 col-lg-12 m-0 p-0 '
                                                                                        disabled
                                                                                        //error={errors.GrossTotal}
                                                                                        value={salaryStructureRecipe.GrossTotal}
                                                                                        onChange={(e) => { HandleSalaryStructureChange('GrossTotal', numberwithDot(e.target.value)) }}
                                                                                    /> </th>
                                                                            </tr>

                                                                            <tr className='border-white pt-4 bg-white' style={{}}>
                                                                                <th key="txtTotalSalary" className='pt-2 pb-0 text-end ' colSpan='2'>Total Salary Amount</th>
                                                                                <th key="txtTotalSalaryAmount" className='p-0 m-0 '>
                                                                                    <InputBox
                                                                                        inputrefs={inputrefs}
                                                                                        //label='Total Salary Amount'
                                                                                        id='txtTotalSalaryAmount'
                                                                                        maxLength='10'
                                                                                        placeholder="Total Salary Amount"
                                                                                        required
                                                                                        disabled
                                                                                        inputClassName='text-end m-0 p-0'
                                                                                        divclassname='col-md-12 col-lg-12 m-0 p-0 '

                                                                                        error={errors.TotalSalaryAmount}
                                                                                        value={salaryStructureRecipe.TotalSalaryAmount}
                                                                                        onChange={(e) => { HandleSalaryStructureChange('TotalSalaryAmount', numberwithDot(e.target.value)) }}
                                                                                    /> </th>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                                : <></>)
                                                            : <></>}
                                                    </fieldset>

                                                </div>
                                            </div>
                                            <div className='col-md-4 col-lg-4 col-sm-12 col-xs-12' //style={{ width: '30%' }}
                                            >
                                                <div className='form-group ms-2'>
                                                    <fieldset className="myfieldset ">
                                                        <legend className="mylegend"> <h6 className="m-0">Deduction</h6></legend>
                                                        {salaryStructureRecipe ?
                                                            (salaryStructureRecipe.DeductionList?.length !== 0 ?

                                                                <div className="table-responsive">
                                                                    <table className="table table-bordered  w-100">
                                                                        <thead>
                                                                            <tr className='light-bg'>
                                                                                <th key="Select">S No.</th>
                                                                                <th key="deductionName">Deduction Name</th>
                                                                                <th style={{ textAlign: 'right' }} key="amount" > Amount</th>

                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {salaryStructureRecipe.DeductionList?.map((item, bodyIndex) => (
                                                                                <tr className="TableTrhover" key={item.allowanceID} >
                                                                                    <td style={{ width: '10%' }}>{bodyIndex + 1}</td>
                                                                                    <td style={{ width: '20%' }}>{item.deductionName}</td>
                                                                                    <td className='p-0 m-0' style={{ height: '1rem', width: '20%' }} key={`${bodyIndex}`}>
                                                                                        <InputBox
                                                                                            inputrefs={inputrefs}
                                                                                            inputClassName='text-end m-0 p-0'
                                                                                            divclassname='col-md-12 col-lg-12 m-0 p-0 '
                                                                                            id={`txtamount${item.deductionID}`}
                                                                                            style={{ float: 'inline-end' }}
                                                                                            maxLength='10'
                                                                                            disabled={item.deductionName === 'ESI' || item.deductionName === 'PF' || item.deductionName === "TDS"}
                                                                                            // inputStyle={{ width: '5rem' }}
                                                                                            placeholder="Amount"
                                                                                            required
                                                                                            //error={errors.Amount}
                                                                                            key={bodyIndex}
                                                                                            value={item.amount}
                                                                                            onChange={(e) => { HandleSalaryStructureChange('amount', number(e.target.value), bodyIndex, 'deductionList') }}
                                                                                        />

                                                                                    </td>


                                                                                </tr>
                                                                            ))}
                                                                            <tr className='border-white pt-4 ' style={{}}>
                                                                                <th key="deductionTotal" className='pt-2 pb-0 text-end ' colSpan='2'>Deduction Total</th>
                                                                                <th key="deductionTotalAmout" className='p-0 m-0 '>
                                                                                    <InputBox
                                                                                        inputrefs={inputrefs}
                                                                                        // label='Deduction Total'
                                                                                        id='txtDeductionTotal'
                                                                                        maxLength='10'
                                                                                        placeholder="Deduction Total"
                                                                                        required
                                                                                        inputClassName='text-end m-0 p-0'
                                                                                        divclassname='col-md-12 col-lg-12 m-0 p-0 '
                                                                                        disabled
                                                                                        // error={errors.DeductionTotal}
                                                                                        value={salaryStructureRecipe.DeductionTotal}
                                                                                        onChange={(e) => { HandleSalaryStructureChange('DeductionTotal', numberwithDot(e.target.value)) }}
                                                                                    /> </th>
                                                                            </tr>

                                                                            <tr className='border-white pt-4 ' style={{}}>
                                                                                <th key="deductionTotal" className='pt-2 pb-0 text-end ' colSpan='2'>NTH</th>
                                                                                <th key="deductionTotalAmout" className='p-0 m-0 '>
                                                                                    <InputBox
                                                                                        inputrefs={inputrefs}
                                                                                        inputClassName='text-end m-0 p-0'
                                                                                        divclassname='col-md-12 col-lg-12 m-0 p-0 '
                                                                                        // id={`txtamount${item.allowanceID}`}
                                                                                        id={`txtNTH`}
                                                                                        style={{ float: 'inline-end' }}
                                                                                        maxLength='10'
                                                                                        disabled
                                                                                        // inputStyle={{ width: '5rem' }}
                                                                                        // placeholder="Amount"
                                                                                        // required
                                                                                        //error={errors.Amount}
                                                                                        value={salaryStructureRecipe.NTH}
                                                                                        onChange={(e) => { HandleSalaryStructureChange('NTH', numberwithDot(e.target.value)) }}
                                                                                    />
                                                                                </th>
                                                                            </tr>
                                                                            <tr className='border-white pt-4 ' style={{}}>
                                                                                <th key="deductionTotal" className='pt-2 pb-0 text-end ' colSpan='2'>Employer Contribution</th>
                                                                                <th key="deductionTotalAmout" className='p-0 m-0 '>
                                                                                    <InputBox
                                                                                        inputrefs={inputrefs}
                                                                                        inputClassName='text-end m-0 p-0'
                                                                                        divclassname='col-md-12 col-lg-12 m-0 p-0 '
                                                                                        // id={`txtamount${item.allowanceID}`}
                                                                                        id={`txtEmployerContribution`}
                                                                                        style={{ float: 'inline-end' }}
                                                                                        maxLength='10'
                                                                                        disabled
                                                                                        // inputStyle={{ width: '5rem' }}
                                                                                        // placeholder="Amount"
                                                                                        required
                                                                                        //error={errors.Amount}
                                                                                        value={salaryStructureRecipe.EmployerContribution}
                                                                                        //value={allTableData.employerContribution}
                                                                                        onChange={(e) => { HandleSalaryStructureChange('EmployerContribution', numberwithDot(e.target.value)) }}
                                                                                    />
                                                                                </th>
                                                                            </tr>
                                                                            <tr className='border-white pt-4 ' style={{}}>
                                                                                <th key="deductionTotal" className='pt-2 pb-0 text-end ' colSpan='2'>CTC</th>
                                                                                <th key="deductionTotalAmout" className='p-0 m-0 '>
                                                                                    <InputBox
                                                                                        inputrefs={inputrefs}
                                                                                        inputClassName='text-end m-0 p-0'
                                                                                        divclassname='col-md-12 col-lg-12 m-0 p-0 '
                                                                                        // id={`txtamount${item.allowanceID}`}
                                                                                        id={`txtCTC`}
                                                                                        style={{ float: 'inline-end' }}
                                                                                        maxLength='10'
                                                                                        disabled
                                                                                        // inputStyle={{ width: '5rem' }}
                                                                                        // placeholder="Amount"
                                                                                        required
                                                                                        //error={errors.Amount}
                                                                                        value={salaryStructureRecipe.CTC}
                                                                                        // value={allTableData.ctc}
                                                                                        onChange={(e) => { HandleSalaryStructureChange('CTC', numberwithDot(e.target.value)) }}
                                                                                    />
                                                                                </th>
                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                                : <></>)
                                                            : <></>}

                                                    </fieldset>
                                                </div>
                                            </div>

                                        </div>



                                    </fieldset>
                                </div>


                                <FormButton
                                    handleSubmit={handleSubmit}
                                    SaveText='Save as Draft'
                                    Next={true}
                                    handleNext={handleSaveAndMail}
                                    nextButtonText={'Save & Send Mail'}
                                    handleExport={() => handleDownloadExcel(API_SERVER + 'api/Candidate/ShowCandidate', ExportRequest, 'Candidate Registration')}
                                    handleClear={handleClear}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Table Component start*/}
                    <Table
                        Token={TokenData}
                        PageName='CandidateMaster'
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        handlePrintPdfData={handlePrintPdfData}
                        handleFilter={() => {
                            getFormData();
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        tableData={result}
                        //  loading={loading}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        currentPageNo={currentPageNo}
                        handleChange1={handleChange1}
                        handleSorting={handleSorting}
                        sortConfig={sortConfig}
                    />
                    {/* Table Component End*/}


                    {/* Loder start*/}
                    {loading && < BlurLoader />}
                    {/* Loder End*/}
                </>
            </div>
        </>
    )
}

export default CandidateRegistration