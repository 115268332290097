
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AutoCompleteBox, FormButton, InputBox, SelectInputBox } from "../commoncomponents/InputBox";
import { useRef, useState } from "react";
import { useAuth } from "../../context/auth";
import { API_SERVER, initializeFilterData } from "../../Utils";
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { useEffect } from 'react';
import TextEditor from '../commoncomponents/TextEditor';
import { post } from "../../apiservices/service";
import { toast } from 'react-toastify';
import { Token } from '../Common/Common';
import BlurLoader from "../Loder/BlurLoader";

const LetterFormatForPrint = () => {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();
    //token Data
    //const TokenData = {
    //    AuthToken:  process.env.REACT_APP_SECRET_KEY,
    //    LoginToken: user.loginsessionID,
    //    UserID: user.userID,
    //    CompanyID: user.companyID
    //}
    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({
        DepartmentID: "",
        FormatType: "",
        LetterFormatID: '',
        Company: TokenData.Company,
    })
    const [textAreaValue, setTextAreaValue] = useState('')
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [isUpdate, setIsUpdate] = useState(false)
    const [formatTypeData, setformateTypeData] = useState([]);
    const [formatLetterData, setFormatLetterData] = useState([]);
    const [textAreaData, setTextAreaData] = useState([]);
    const [companyAllData, setCompanyAllData] = useState([]);
    const [errors, setErrors] = useState({});

    //Get Company Autocomplete Data
    const getCompanyData = () => {
        let temp = {
            Type: "GetCompany",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setCompanyAllData(data)
                }
            }
        });
    }

    const FormateData = () => {
        setformateTypeData([])
        let temp = {
            Type: "GetFormatType",
            Prefix: '',

        }
      //  setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setformateTypeData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setformateTypeData(data)
                }
            }
        });
    }


    const GetLetterFormateData = (type) => {
        setLoading(true)
        post(`${API_SERVER}api/Master/GetLetterFormat`, { ...TokenData, FormatType: type }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    let data = res.data
                    setTextAreaValue(data.letterFormat ? data.letterFormat : '')
                    setRecipe({
                        ...recipe,
                        FormatType: data.formatType,
                        LetterFormatID: data.letterFormatID,
                      //  data.letterFormat: data.letterFormat
                    })
                }
            }
        });
    }


    const GetTextAreaData = () => {
        setTextAreaData([])
        setLoading(true)
        post(`${API_SERVER}api/Master/GetEmployeeMasterFields`, { ...TokenData, }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setTextAreaData([])
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    let data = res.data
                    setTextAreaData(data.value)
                  
                }
            }
        });
    }

    //validation handler
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "FormatType", msg: 'Please Select Format Type.' },
            { field: "Company", msg: 'Please Select Company.' },

        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {

                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }
    console.log(errors)


    const HandleChange = (prop, value) => {
        if (prop === 'FormatType') {
            GetLetterFormateData(value)
        }
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    const HandleSave = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/Master/InsertUpdateLetterFormat`, { ...TokenData, ...recipe, LetterFormat: textAreaValue }, (res) => {

                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        inputrefs.current[res.focus].focus();
                        setLoading(false)
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else if (res.Status === "UNAUTHORIZED") {
                        toast.error(res.Message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        handleClear()
                    }
                }
            });
        }
    }
    const handleClear = () => {
        
        setRecipe({
            ...recipe,
            DepartmentID: "",
            FormatType: "",
            LetterFormatID: '0',
            LetterFormat: '',
            Company:TokenData.Company,
            
        })
        setErrors({})
        setTextAreaValue('')
    }


    let CompanyData = []
    for (let i = 0; i < companyAllData.length; i++) {
        let mydata = companyAllData[i]?.values
        CompanyData.push(mydata)
    };

    useEffect(() => {
        inputrefs.current['txtFormatType'].focus();
        document.title = 'Pay Plus : Letter Format For Print';
        FormateData();
        getCompanyData();
        GetTextAreaData();
    }, [])


    return (
        <div className='main-card card border-0'>
            <div className='card-header mb-1'>
                <h6 className="m-0">Letter Format For Print</h6>
            </div>
            <div className='card-body'>
                <div className='row'>
                    <div className='row '>

                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtFormatType'].focus() };
                            }}
                            disabled
                            label='Company'
                            placeholder="Company"
                            maxLength='50'
                            options={CompanyData}
                            id='txtCompany'
                            required={true}
                            error={errors.Company}
                            inputValue={recipe.Company ? recipe.Company : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('Company', newInputValue)
                            }}
                        />
                        {/*
                        <div className={`mb-1 w-25 d-flex align-items-center gap-2 text-nowrap pe-0`} key="txtFormatType">
                            <label className="form-label  ">Format Type<span style={{ color: "red" }}> *</span>
                            </label>
                            <select ref={ref => (inputrefs.current['txtFormatType'] = ref)} className="form-control col-md-4 col-sm-4 col-xs-4" id="txtFormatType" value={recipe.FormatType} error={recipe.FormatType}
                                onChange={(e) => HandleChange('FormatType', e.target.value)}>
                                <option value="">-- Select Format Type--</option>
                                {formatTypeData?.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}

                            </select>
                        </div>
                        */}


                        <SelectInputBox
                            label='Format Type'
                            id='txtFormatType'
                            onKeyDown={(e) => {
                            //    if (e.key === 'Enter') { inputrefs.current['txtLoanDate'].focus() };
                            }}
                            required
                            inputrefs={inputrefs}
                            error={errors.FormatType}
                            value={recipe.FormatType}
                            onChange={(e) => {
                                HandleChange('FormatType', e.target.value)
                            }}
                            placeholder='Format Type'
                            children={<>
                                <option value='' >--Select Format Type--</option>
                                {formatTypeData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}
                            </>}
                        />

                    </div>
                    <div className="row">
                        <div className='col-md-8 col-sm-8 col-xs-8'>
                            <TextEditor
                                value={textAreaValue}
                                setValue={setTextAreaValue}
                            />
                        </div>

                        <textarea
                            disabled
                            value={textAreaData}
                            className='col-md-4 col-sm-4 col-xs-4'
                        />


                    </div>

                    <FormButton
                        handleNext={HandleSave}
                        Export={false}
                        Next={true}
                        save={false}
                        nextButtonColor='btn-primary'
                        nextButtonIcon='fa-check'
                        nextButtonText='Save'
                        handleClear={handleClear}
                    />
                </div>
            </div>
            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}
        </div>
    )
}

export default LetterFormatForPrint;