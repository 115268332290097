import React, { useEffect, useRef, useState } from 'react';
import { AutoCompleteBox, DateBox, FormButton, InputBox } from "../commoncomponents/InputBox";

import { useAuth } from '../../context/auth';

//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER } from '../../Utils';

//services
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { post } from '../../apiservices/service';
import { Token, dateFormate, number, } from '../Common/Common';

function UpdateBankDetails() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();

    //token Data
    const TokenData = Token()

    //initial state
    const [recipe, setRecipe] = useState({
        "EmpCode": "",
        "IFSCCode": "",
        "AccountNo": "",
        "PaymentMode": "",
        "FromBank": "",
        "EmployeeBank": "",
        "EffectiveDate": "",
        "AccountUpdateDate": "",
        "NameinBankAccount": ""
    })
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({});

    const [employeeAllData, setEmployeeAllData] = useState([])
    const [paymentModeAllData, setPaymentModeAllData] = useState([]);
    const [employeeBankAllData, setEmployeeBankAllData] = useState([])



    //Get Employee Data for AutoComplete
    const GetEmployeeData = (value) => {
        setEmployeeAllData([])
        let temp = {
            Type: "GetEmployee",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setEmployeeAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setEmployeeAllData(data)
                }
            }
        });
    }
    // Get Payment Mode
    const getPaymentModeData = () => {
        setPaymentModeAllData([])
        let temp = {
            Type: "GetPaymentMode",
            Prefix: '',

        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setPaymentModeAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setPaymentModeAllData(data)
                }
            }
        });
    }

    //Get Employee Bank Data for AutoComplete
    const getEmployeeBankData = (value) => {
        let temp = {
            Type: "GetBankName",
            Prefix: value ? value : '',
            ContextKey: "",
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setEmployeeBankAllData(data)
                }
            }
        });
    }

    //Get EmployeeBank IFSC  Data for AutoComplete 
    const GetIFSCPrefix = (value) => {
        post(`${API_SERVER}api/AutoComplete/GetIFSCPrefix`, { ...TokenData, BankCode: value, EmployeeBank: value }, (res) => {
            if (res.status === 'SUCCESS') {
                setRecipe({ ...recipe, IFSCCode: res.ifscPrefix, EmployeeBank: value })
            }
            else if (res.status === 'ERROR') {
                setRecipe({ ...recipe, IFSCCode: '', EmployeeBank: value })
            } else if (res.Status === "EXPIRED") {
                toast.error(res.Message)
                sessionExpired()
            }
        })
    }


    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //--------------------------validation handler----------------------------------//
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "PaymentMode", msg: 'Please Enter Payment Mode.' },
            { field: "EmployeeBank", msg: 'Please Enter Employee Bank.' },
            { field: "EmpCode", msg: 'Please Enter Employee.' },
            { field: "AccountNo", msg: 'Please Enter Account No.' },
            { field: "NameinBankAccount", msg: 'Please Enter Name in Bank Account.' },
            { field: "IFSCCode", msg: 'Please Enter IFSC Code.' },
            { field: "AccountUpdateDate", msg: 'Please Enter Account Update Date.' },
            { field: "EffectiveDate", msg: 'Please Enter EffectiveDate Date.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {

                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }



    //Submit Handler
    const handleSubmit = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/Employee/UpdateBankDetails`, { ...TokenData, ...recipe }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        setLoading(false)
                    } else if (res.Status === "EXPIRED") {
                        toast.error(res.Message)
                        sessionExpired()
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        handleClear()
                    }
                }
            });
        } else {
            //  toast.error(myString[0])
            //   (inputrefs.current[myString[1]].focus())
        }
    }

    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            "EmpCode": "",
            "IFSCCode": "",
            "AccountNo": "",
            "PaymentMode": "",
            "FromBank": "",
            "EmployeeBank": "",
            "EffectiveDate": "",
            "AccountUpdateDate": "",
            "NameinBankAccount": ""
        }))
        setErrors({})
        inputrefs.current['txtEmployee'].focus();
    }

    let EmployeeData = []
    for (let i = 0; i < employeeAllData.length; i++) {
        let mydata = employeeAllData[i]?.values
        EmployeeData.push(mydata)
    };


    let EmployeeBankData = []
    for (let i = 0; i < employeeBankAllData.length; i++) {
        let mydata = employeeBankAllData[i]?.values
        EmployeeBankData.push(mydata)
    };

    useEffect(() => {
        inputrefs.current['txtEmployee'].focus();
        document.title = 'Pay Plus : Update Bank Details';
        GetEmployeeData();
        getPaymentModeData();
        getEmployeeBankData();
    }, [])


    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Update Bank Details</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtPaymentMode'].focus() };
                            }}
                            label='Employee'
                            placeholder="Employee"
                            //divclassname='col-lg-6  '
                            maxLength='10'
                            options={EmployeeData}
                            id='txtEmployee'
                            key='txtEmployee'
                            //disabled={ShowEdit}
                            required={true}
                            error={errors.EmpCode}
                            inputValue={recipe.EmpCode ? recipe.EmpCode : ''}
                            onInputChange={(event, newInputValue) => {
                                GetEmployeeData(newInputValue)
                                HandleChange('EmpCode', newInputValue)
                                // getFormData(newInputValue)

                            }}
                        />
                        <div className={`col-md-3 mb-1 cntrl-width`} key="">
                            <label className="form-label">Payment Mode <span style={{ color: "red" }}> *</span></label>
                            <select
                                ref={ref => (inputrefs.current['txtPaymentMode'] = ref)}
                                className="form-control"
                                id="txtPaymentMode"
                                value={recipe.PaymentMode}
                                //error={errors.PaymentMode}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtEmployeeBank'].focus() };
                                }}
                                onChange={(e) => HandleChange('PaymentMode', e.target.value)}>
                                <option value="">--Select Payment Mode--</option>
                                {paymentModeAllData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}

                            </select>
                            <div className="error-msg">{errors.PaymentMode}</div>
                        </div>

                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtAccountNo'].focus() };
                            }}
                            label='Employee Bank'
                            placeholder="Employee Bank"
                            maxLength='50'
                            options={EmployeeBankData}
                            id='txtEmployeeBank'
                            required={true}
                            error={errors.EmployeeBank}
                            inputValue={recipe.EmployeeBank ? recipe.EmployeeBank : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('EmployeeBank', newInputValue)
                                GetIFSCPrefix(newInputValue)
                                getEmployeeBankData(newInputValue)
                            }}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtNameinBankAccount'].focus() };
                            }}
                            label='Account No.'
                            id='txtAccountNo'
                            maxLength='16'
                            placeholder="Account No."
                            value={recipe.AccountNo}
                            error={errors.AccountNo}
                            onChange={(e) => HandleChange('AccountNo', number(e.target.value))}
                            required
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtIFSCCode'].focus() };
                            }}
                            label='Name in Bank Account'
                            id='txtNameinBankAccount'
                            maxLength='30'
                            placeholder='Name in Bank Account'
                            value={recipe.NameinBankAccount}
                            onChange={(e) => { HandleChange('NameinBankAccount', (e.target.value)) }}
                            required={true}
                            //disabled={!recipe.EmployeeBank}
                            error={errors.NameinBankAccount}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtAccountUpdateDate'].focus() };
                            }}
                            label='IFSC Code'
                            id='txtIFSCCode'
                            maxLength='11'
                            placeholder='IFSC Code'
                            value={recipe.IFSCCode}
                            onChange={(e) => { HandleChange('IFSCCode', e.target.value.toUpperCase()) }}
                            required={true}
                            //disabled={!recipe.EmployeeBank}
                            error={errors.IFSCCode}
                        />
                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtEffectiveDate'].focus() };
                            }}
                            label='Account Update Date'
                            id='txtAccountUpdateDate'
                            selected={recipe.AccountUpdateDate === '' ? '' : new Date(recipe.AccountUpdateDate)}
                            placeholder="Account Update Date"
                            //value={recipe.AccountUpdateDate}
                            onChange={(e) => HandleChange('AccountUpdateDate', dateFormate(e))}
                            error={errors.AccountUpdateDate}
                            required={true}
                        //disabled={!recipe.EmployeeBank}
                        />
                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtRemarks'].focus() };
                            }}
                            label='Effective Date'
                            id='txtEffectiveDate'
                            selected={recipe.EffectiveDate === '' ? '' : new Date(recipe.EffectiveDate)}
                            placeholder="Effective Date"
                            //value={recipe.EffectiveDate}
                            onChange={(e) => HandleChange('EffectiveDate', dateFormate(e))}
                            error={errors.EffectiveDate}
                            required={true}
                        //disabled={!recipe.EmployeeBank}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { handleSubmit() };
                            }}
                            label='Remarks'
                            id='txtRemarks'
                            maxLength='16'
                            placeholder="Remarks"
                            value={recipe.Remarks}
                            error={errors.Remarks}
                            onChange={(e) => HandleChange('Remarks', (e.target.value))}
                        //required
                        />


                        <FormButton
                            handleSubmit={handleSubmit}
                            Export={false}
                            // handleExport={() => handleDownloadExcel(API_SERVER + 'api/MASTER/ShowBankMaster', ExportRequest, 'Bank Master')}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>

            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}
        </>
    )
}

export default UpdateBankDetails