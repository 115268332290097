import React, { useEffect, useState, useRef, ReactDOM } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, DateBox, DynamicHTMLComponent, SmallDateBox, SelectInputBox } from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
import BlurLoader from "../Loder/BlurLoader"; //loder
import { toast } from 'react-toastify'; //react toast 
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils'; //common Data
import { post } from '../../apiservices/service'; //services
import { Token, dateFormate, numberWithComma } from '../Common/Common';
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';

function LeaveApplication() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();

    //token Data
    const TokenData = Token()
    //const TokenData = {
    //    AuthToken: process.env.REACT_APP_SECRET_KEY,
    //    LoginToken: user.loginDetailID,
    //    UserID: user.userID,
    //    CompanyID: user.companyID
    //}

    //initial state
    const [recipe, setRecipe] = useState({
        Employee: '',
        ApplicationType: '',
        LeaveType: '',
        LeaveFromDate: '',
        LeaveFromType: '',
        LeaveToDate: '',
        LeaveToType: '',
        Reason: ''
    })
    const [EmployeeData, setEmployeeData] = useState([])
    const [loading, setLoading] = useState(false)
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [isUpdate, setIsUpdate] = useState(false)
    const [errors, setErrors] = useState({});
    const [LeaveTypeData, setLeaveTypeData] = useState([]);
    const [tableData, setTableData] = useState({});


    //handle Get Leave Register Table Data 
    const GetLeaveRegisterTableData = (emp) => {
        post(`${API_SERVER}api/Leave/ShowLeaveRegister`, { ...TokenData, Employee: emp ? emp : '' }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    //   toast.error(res.Message)
                    setLoading(false)
                    setTableData({ ...res })
                } else if (res.Status === "EXPIRED") {
                    //  toast.error(res.Message)
                    sessionExpired()
                    setLoading(false)
                } else {
                    setLoading(false)
                    //  toast.success(res.Message)
                   // console.log(res)
                    setTableData({ ...res })
                }
            }
        });

    }



    const getEmployeeData = () => {
        let temp = {
            Type: "GetEmployee",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setEmployeeData(data)
                }
            }
        });
    }

    const getLeaveTypeData = (value) => {
        let temp = {
            Type: "GetLeaveType",
            Prefix: '',
            ContextKey: value,
            ContextKey2: ""
        }
        setLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                } else {
                    setLoading(false)
                    let data = res.dataList
                    setLeaveTypeData(data)
                }
            }
        });
    }

    useEffect(() => {
        inputrefs.current['txtEmployee'].focus();
        document.title = 'Pay Plus : Leave Application'
        getEmployeeData();
        //getLeaveTypeData();
    }, [sortConfig])

    useEffect(() => {
    }, [isUpdate])

    let EmpData = []
    for (let i = 0; i < EmployeeData.length; i++) {
        let mydata = EmployeeData[i]?.values
        EmpData.push(mydata)
    };

    //Change Handler
    const HandleChange = (prop, value) => {
        //if (prop === 'LeaveFromDate') {
        //    setLeaveFromDate(value)
        //} else if (prop === 'LeaveToDate') {
        //    setLeaveToDate(value)
        //}
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
        if (prop === "ApplicationType") {
            getLeaveTypeData(value);
        }
    }

    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "Employee", msg: 'Please Select Employee.!' },
            { field: "ApplicationType", msg: 'Please Select Application Type.!' },
            { field: "LeaveType", msg: 'Please Select Leave Type.!' },
            { field: "LeaveFromDate", msg: 'Please Select Leave From Date.' },
            { field: "LeaveFromType", msg: 'Please Select Leave From Type.!' },
            { field: "LeaveToDate", msg: 'Please Select Leave To Date.' },
            { field: "LeaveToType", msg: 'Please Select Leave To Type.!' },
            { field: "Reason", msg: 'Please Enter Reason for Leave.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '' || recipe[item.field] === '0') {

                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }

    //handleSubmit Handler
    const handleSubmit = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/Leave/LeaveApplication`, { ...TokenData, ...recipe }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        setLoading(false)
                    } else if (res.status === "EXPIRED") {
                        toast.error(res.message)
                        sessionExpired()
                    } else if (res.status === "UNAUTHORIZED") {
                        toast.error(res.message);
                        handleClear();
                        setLoading(false);
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        handleClear()
                    }
                }
            });
        }
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            Employee: '',
            ApplicationType: '',
            LeaveType: '',
            LeaveFromDate: '',
            LeaveFromType: '',
            LeaveToDate: '',
            LeaveToType: '',
            Reason: ''
        }));
        setErrors({})
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        setTableData({})
        inputrefs.current['txtEmployee'].focus();
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Leave Application</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-6'>

                            <div className='row'>
                                <AutoCompleteBox
                                    divclassname='col-md-4 mb-1'
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['ddlApplicationType'].focus() };
                                    }}
                                    label='Employee'
                                    placeholder="Employee"
                                    maxLength='100'
                                    options={EmpData}
                                    id='txtEmployee'
                                    required={true}
                                    error={errors.Employee}
                                    inputValue={recipe.Employee ? recipe.Employee : ''}
                                    onInputChange={(event, newInputValue) => {
                                        HandleChange('Employee', newInputValue)
                                        GetLeaveRegisterTableData(newInputValue)
                                        //setStatePre(newInputValue)
                                    }}
                                />

                                {/*<div className={`col-md-3 mb-1 cntrl-width`} key="dvApplicationType">*/}
                                {/*    <label className="form-label">Application Type<span style={{ color: "red" }}> *</span></label>*/}
                                {/*    <select*/}
                                {/*        className="form-control"*/}
                                {/*        ref={ref => (inputrefs.current['ddlApplicationType'] = ref)}*/}
                                {/*        placeholder="ApplicationType"*/}
                                {/*        inputrefs={inputrefs}*/}
                                {/*        required*/}
                                {/*        id="ddlApplicationType"*/}
                                {/*        value={recipe.ApplicationType}*/}
                                {/*        onKeyDown={(e) => {*/}
                                {/*            if (e.key === 'Enter') { inputrefs.current['ddlLeaveType'].focus() };*/}
                                {/*        }}*/}
                                {/*        onChange={(e) => {*/}
                                {/*            HandleChange('ApplicationType', e.target.value)*/}
                                {/*            if (e.target.value) {*/}
                                {/*                setRecipe({ ...recipe, ApplicationType: e.target.value, LeaveFromDate: '', LeaveToDate:'' })*/}
                                {/*            }*/}
                                {/*        }}>*/}
                                {/*        <option value="0">-- Select Application Type --</option>*/}
                                {/*        <option value="Absent">Against Absenteeism</option>*/}
                                {/*        <option value="Planned">Planned Leave</option>*/}
                                {/*    </select>*/}
                                {/*    <div className="error-msg">{errors.ApplicationType}</div>*/}
                                {/*</div>*/}

                                <SelectInputBox
                                    divclassname='col-md-4 mb-1'
                                    label='Application Type'
                                    inputrefs={inputrefs}
                                    required
                                    id="ddlApplicationType"
                                    value={recipe.ApplicationType}
                                    error={errors.ApplicationType}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['ddlLeaveType'].focus() };
                                    }}
                                    onChange={(e) => {
                                        HandleChange('ApplicationType', e.target.value)
                                        if (e.target.value) {
                                            setRecipe({ ...recipe, ApplicationType: e.target.value, LeaveFromDate: '', LeaveToDate: '' })
                                        }
                                    }}
                                    children={<>
                                        <option value="0">-- Select Application Type --</option>
                                        <option value="Absent">Against Absenteeism</option>
                                        <option value="Planned">Planned Leave</option>
                                    </>}
                                />

                                {/*<div className={`col-md-3 mb-1 cntrl-width`} key="dvLeaveType">*/}
                                {/*    <label className="form-label">Leave Type<span style={{ color: "red" }}> *</span></label>*/}
                                {/*    <select*/}
                                {/*        ref={ref => (inputrefs.current['ddlLeaveType'] = ref)}*/}
                                {/*        placeholder="LeaveType"*/}
                                {/*        inputrefs={inputrefs}*/}
                                {/*        className="form-control"*/}
                                {/*        required*/}
                                {/*        id="ddlLeaveType" value={recipe.LeaveType}*/}
                                {/*        onKeyDown={(e) => {*/}
                                {/*            if (e.key === 'Enter') { inputrefs.current['txtLeaveFromDate'].focus() };*/}
                                {/*        }}*/}
                                {/*        onChange={(e) => HandleChange('LeaveType', e.target.value)}>*/}
                                {/*        <option value="0">-- Select Leave Type --</option>*/}
                                {/*        {LeaveTypeData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}*/}
                                {/*    </select>*/}
                                {/*    <div className="error-msg">{errors.LeaveType}</div>*/}
                                {/*</div>*/}

                                <SelectInputBox
                                    divclassname='col-md-4 mb-1'
                                    label='Leave Type'
                                    inputrefs={inputrefs}
                                    required
                                    error={errors.LeaveType}
                                    id="ddlLeaveType" value={recipe.LeaveType}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtLeaveFromDate'].focus() };
                                    }}
                                    onChange={(e) => HandleChange('LeaveType', e.target.value)}
                                    children={<>
                                        <option value="0">-- Select Leave Type --</option>
                                        {LeaveTypeData.map((opt, index) => <option key={index} value={opt.id} >{opt.name}</option>)}
                                    </>}
                                />
                                <div className="col-md-4 mb-1">
                                    <div className="row">
                                        {/*<div className="col-md-6" style={{ paddingRight: "0px" }}>*/}

                                        <DateBox
                                            divclassname="col-7"
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['ddlLeaveFromType'].focus() };
                                            }}
                                            maxDate={recipe.ApplicationType === "Absent" && new Date()}
                                            minDate={recipe.ApplicationType === "Planned" && new Date()}
                                            label='Leave From'
                                            id='txtLeaveFromDate'
                                            selected={recipe.LeaveFromDate === '' ? '' : new Date(recipe.LeaveFromDate)}
                                            maxLength='12'
                                            placeholder="Leave From"
                                            value={recipe.LeaveFromDate}
                                            onChange={(e) => {
                                                HandleChange('LeaveFromDate', dateFormate(e));
                                                if (e) setRecipe({ ...recipe, LeaveFromDate: dateFormate(e), LeaveToDate: '' });
                                            }}
                                            required
                                            error={errors.LeaveFromDate}
                                        />
                                        {/*</div>*/}
                                        {/*<div className="col-md-5" style={{ paddingLeft: "5px", marginTop: "2px" }}>*/}
                                        {/*<div className={`col-4 p-0 ps-0`} style={{ marginTop: "2px" }} key="dvLeaveFromType">*/}
                                        {/*    <label className="form-label"></label>*/}
                                        {/*    <select*/}
                                        {/*        ref={ref => (inputrefs.current['ddlLeaveFromType'] = ref)}*/}
                                        {/*        // placeholder="Leave From Type"*/}
                                        {/*        inputrefs={inputrefs}*/}
                                        {/*        className="form-control"*/}
                                        {/*        required*/}
                                        {/*        id="ddlLeaveFromType" value={recipe.LeaveFromType}*/}
                                        {/*        onKeyDown={(e) => {*/}
                                        {/*            if (e.key === 'Enter') { inputrefs.current['txtLeaveToDate'].focus() };*/}
                                        {/*        }}*/}
                                        {/*        onChange={(e) => HandleChange('LeaveFromType', e.target.value)}>*/}
                                        {/*        <option value="0">--Select--</option>*/}
                                        {/*        <option value="1">Full Day</option>*/}
                                        {/*        <option value="3">Second Half</option>*/}
                                        {/*    </select>*/}
                                        {/*    {errors.LeaveFromType && <div className="error-msg">{errors.LeaveFromType}</div>}*/}
                                        {/*</div>*/}

                                        <SelectInputBox
                                            divclassname='col-5 mt-4 ps-0'
                                            inputStyle={{ marginTop: '0.1rem' }}
                                            // label='Leave From Type'
                                            inputrefs={inputrefs}
                                            required
                                            error={errors.LeaveFromType}
                                            id="ddlLeaveFromType" value={recipe.LeaveFromType}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtLeaveToDate'].focus() };
                                            }}
                                            onChange={(e) => HandleChange('LeaveFromType', e.target.value)}
                                            children={<>
                                                <option value="0">--Select--</option>
                                                <option value="1">Full Day</option>
                                                <option value="3">Second Half</option>
                                            </>}
                                        />

                                    </div>
                                    {/*</div>*/}
                                </div>

                                <div className="col-md-4 mb-1">
                                    <div className="row">
                                        {/*<div className="col-md-6" style={{ paddingRight: "0px" }}>*/}
                                        <DateBox
                                            divclassname="col-7"
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['ddlLeaveToType'].focus() };
                                            }}
                                            minDate={new Date(recipe.LeaveFromDate)}
                                            maxDate={recipe.ApplicationType === "Absent" && new Date()}
                                            label='Leave To'
                                            id='txtLeaveToDate'
                                            selected={recipe.LeaveToDate === '' ? '' : new Date(recipe.LeaveToDate)}
                                            maxLength='12'
                                            placeholder="Leave To"
                                            value={recipe.LeaveToDate}
                                            error={errors.LeaveToDate}
                                            onChange={(e) => HandleChange('LeaveToDate', dateFormate(e))}
                                            required
                                        />
                                        {/*</div>*/}
                                        {/*<div className="col-md-5" style={{ paddingLeft: "5px", marginTop: "2px" }}>*/}
                                        {/*<div className={`col-4 p-0 ps-0`} style={{ marginTop: "2px" }} key="dvLeaveToType">*/}
                                        {/*    <label className="form-label"></label>*/}
                                        {/*    <select*/}
                                        {/*        ref={ref => (inputrefs.current['ddlLeaveToType'] = ref)}*/}
                                        {/*        //placeholder="LeaveToType"*/}
                                        {/*        inputrefs={inputrefs}*/}
                                        {/*        className="form-control"*/}
                                        {/*        required*/}
                                        {/*        id="ddlLeaveToType" value={recipe.LeaveToType}*/}
                                        {/*        onKeyDown={(e) => {*/}
                                        {/*            if (e.key === 'Enter') { inputrefs.current['txtReason'].focus() };*/}
                                        {/*        }}*/}
                                        {/*        onChange={(e) => HandleChange('LeaveToType', e.target.value)}>*/}
                                        {/*        <option value="0">--Select--</option>*/}
                                        {/*        <option value="1">Full Day</option>*/}
                                        {/*        <option value="2">First Half</option>*/}
                                        {/*    </select>*/}
                                        {/*    <div className="error-msg">{errors.LeaveToType}</div>*/}
                                        {/*    */}{/*    </div>*/}
                                        {/*    */}{/*</div>*/}
                                        {/*</div>*/}
                                        <SelectInputBox
                                            divclassname='col-5 mt-4 ps-0'
                                            inputStyle={{ marginTop: '0.1rem' }}
                                            // label='Leave From Type'
                                            inputrefs={inputrefs}
                                            required
                                            error={errors.LeaveFromType}
                                            id="ddlLeaveToType" value={recipe.LeaveToType}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtReason'].focus() };
                                            }}
                                            onChange={(e) => HandleChange('LeaveToType', e.target.value)}
                                            children={<>
                                                <option value="0">--Select--</option>
                                                <option value="1">Full Day</option>
                                                <option value="2">First Half</option>
                                            </>}
                                        />
                                    </div>
                                </div>

                                <div className={`col-md-4 mb-1`} >
                                    <label> Reason <span style={{ color: "red" }}> *</span>

                                        <textarea
                                            ref={ref => (inputrefs.current['txtReason'] = ref)}
                                            className="form-control mt-1"
                                            id="txtReason"
                                            name="txtReason"
                                            rows={2} cols={100}
                                            placeholder="Enter Reason..."
                                            value={recipe.Reason}
                                            onChange={(e) => HandleChange('Reason', e.target.value)}
                                        /></label>
                                    <div className="error-msg">{errors.Reason}</div>
                                </div>

                                <FormButton
                                    Export={false}
                                    SaveText='Apply'
                                    saveButtonIcon='fa-check'
                                    handleSubmit={handleSubmit}
                                    handleClear={handleClear}
                                />

                                {/*<hr className="mt-4" />*/}
                                {/*<div className="col-lg-12 mt-4">*/}
                                {/*    <div className="lms-new-button text-center">*/}
                                {/*        <button type="button" name="txtSubmitbtn" id='txtSubmitbtn' onClick={handleSubmit} className="btn btn-rounded btn-primary">*/}
                                {/*            <i className="fa fa-check"></i> Apply*/}
                                {/*        </button>*/}

                                {/*        <button type="button" name="txtResetbtn" id='txtResetbtn' onClick={handleClear} className="btn btn-rounded btn-danger">*/}
                                {/*            <i className="fa-solid fa-arrow-rotate-left"></i> Reset*/}
                                {/*        </button>*/}

                                {/*    </div>*/}
                                {/*</div>*/}

                            </div>
                        </div>
                        <div className='col-6'>
                            <Table
                                headingText='Leave Register'
                                showTotalRecord={false}
                                Filter={false}
                                Action={false}
                                Token={TokenData}
                                PageName=''

                                tableData={tableData}
                                loading={loading}

                            />
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default LeaveApplication