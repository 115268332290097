import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import DOMPurify from 'dompurify';
import 'react-datepicker/dist/react-datepicker.css';
import { FileUploader } from "react-drag-drop-files";
import Select from 'react-select'
import DatePicker from 'react-datepicker';
import Calendar from 'react-datepicker';
import MaskedTextInput from 'react-text-mask'
import * as React from 'react';
import { toast } from 'react-toastify';


export const CustomButton = (props) => {
    const { id, inputrefs, onClick, ButtonIcon, ButtonText, ButtonClass = 'btn-primary', ButtonIconClass } = props;
    return <button
        type="button"
        key={`key${id}`}
        name={id}
        ref={ref => (inputrefs ? inputrefs.current[id] = ref : null)}
        onClick={onClick}
        className={`btn btn-rounded ${ButtonClass}`}>
        <span className={`text-white me-2 ${ButtonIconClass}`}>
            <i className={`fa ${ButtonIcon}`}></i>

        </span>{ButtonText}</button>
}


export const FormButton = (props) => {
    let xport = false;
    if (props.handleExport != null) {
        xport = true;
    } else {
        xport = false
    };

    let {
        handleClear,
        id = null,
        Next = false, nextButtonText = 'Next', nextButtonColor = 'btn-success', nextButtonIcon = 'fa-arrow-right', handleNext,
        /* Export = false, exportButtonIcon = 'fa-download', exportText = 'Export', handleExport,*/
        Export = xport, exportButtonIcon = 'fa-download', exportText = 'Export', handleExport,
        save = true, SaveText = 'Save', saveButtonIcon = 'fa-save', handleSubmit,
        add = false, handleAdd,
        reset = true,
        Report = false, handleReport, NameForReportModal,
        update = false,
        print = false, handlePrint,
        inputrefs, children,

        Reject = false, NameForModal, rejectColor = 'btn-warning', handleReject
    } = props
    return (

        <div className='d-block mt-3 d-md-flex' >
            <div className="">
                {save ?
                    <button type="button" name={id}
                        ref={ref => (inputrefs ? inputrefs.current[id] = ref : null)}
                        onClick={handleSubmit}
                        className="btn btn-rounded btn-primary">
                        <span className=" text-white me-2">
                            <i className={`fa ${saveButtonIcon}`}></i>

                        </span>{SaveText}</button>
                    : <></>}
                {add ?
                    <button type="button" name={id}
                        ref={ref => (inputrefs ? inputrefs.current[id] = ref : null)}
                        onClick={handleAdd}
                        className="btn btn-rounded btn-primary">
                        <span className=" text-white me-2">
                            <i className="fa-fa-plus">&#xf0c7;</i>

                        </span></button>
                    : <></>}
                {Next ?
                    <button type="button" className={`btn btn-rounded ${nextButtonColor}`} name={id}
                        onClick={handleNext}
                    ><span className=" text-warning me-2"><i className={`fa ${nextButtonIcon} text-white color-success`}></i>
                        </span>{nextButtonText}</button>
                    : <></>}
                {Reject ?
                    <button type="button" data-bs-toggle="modal" data-bs-target={`#${NameForModal}`} className={`btn btn-rounded ${rejectColor}`} name={id}
                        onClick={!NameForModal ? handleReject : ''}
                    ><span className=" text-warning me-2"><i className={`fa fa-close text-white color-success`}></i>
                        </span>Reject</button>
                    : <></>}
                {Export ?
                    <button type="button" className="btn btn-rounded btn-warning" name={id}
                        onClick={handleExport}
                    ><span className=" text-warning me-2"><i className={`fa ${exportButtonIcon} text-white color-success`}></i>
                        </span>{exportText}</button>
                    : <></>}

                {update ?
                    <button type="button" name={id}
                        onClick={handleSubmit}
                        className="btn btn-rounded btn-primary">
                        <span className=" text-white me-2">
                            <i className={`fa fa-upload color-warning`}>
                            </i>
                        </span>Update</button>
                    : <></>}
                {print ?
                    <button type="button" name={id}
                        onClick={handlePrint}
                        className="btn btn-rounded btn-secondary">
                        <span className=" text-white me-2">
                            <i className="fa fa-print color-warning">
                            </i>
                        </span>Print</button>
                    : <></>}
                {reset ? <button type="button" name={id} onClick={handleClear} className="btn btn-rounded btn-danger"><span className="text-white me-2"><i className="fa-solid fa-arrow-rotate-left"></i>
                </span>Reset</button> : <></>}
                {Report ?
                    <button type="button" name={id}
                        onClick={handleReport}
                        data-bs-toggle="modal"
                        data-bs-target={`#${NameForReportModal}`}
                        className="btn btn-rounded btn-primary">
                        <span className=" text-white me-2">
                            <i className={`fa fa-duotone fa-user color-warning`}>
                            </i>
                        </span>Report</button>
                    : <></>}
                {children}
            </div>

        </div>

    )
}

export const FormButtonWithPrint = (props) => {

    let { handleSubmit, handlePrint, handleExport, handleClear, id, inputrefs, children, save, print, reset, buttonName, Export } = props

    return (

        <div className='d-block mt-3 d-md-flex' >
            <div className="">
                {save ?
                    <button type="button" name={id}
                        id='txtSubmitbtn'
                        // ref={ref => (inputrefs ? inputrefs.current[id] = ref : null)}
                        onClick={handleSubmit}
                        className="btn btn-rounded btn-primary">
                        <span className=" text-white me-2">
                            {buttonName ? <i className="fa fa-search">
                            </i> : <i className="fa">&#xf0c7;</i>}

                        </span>{buttonName ? buttonName : 'Save'}</button>
                    : <></>}

                {print ?
                    <button type="button" name={id}
                        onClick={handlePrint}
                        className="btn btn-rounded btn-secondary">
                        <span className=" text-white me-2">
                            <i className="fa fa-print color-warning">
                            </i>
                        </span>Print</button>
                    : <></>}
                {Export ?
                    <button type="button" name={id}
                        onClick={handleExport}
                        className="btn btn-rounded btn-warning">
                        <span className=" text-warning me-2">
                            <i className="fa fa-download text-white color-success">
                            </i>
                        </span>Export</button>
                    : <></>}
                {reset ?
                    <button type="button"
                        name={id}
                        onClick={handleClear}
                        className="btn btn-rounded btn-danger">
                        <span className="text-white me-2">
                            <i className="fa-solid fa-arrow-rotate-left"></i>
                        </span>Reset</button>
                    : <></>}

                {children}
            </div>

        </div>

    )
}


export const ImportButton = ({ BulkSubmit, handleBulk, downloadFormat, children, Status = [], ErrorData }) => {
    return (
        <div className='border border-2 p-2 w-100' style={{ 'borderRadius': '8px' }}>
            <label className="form-label" htmlFor={'import'}>{'Bulk Upload'}
                <span style={{ color: "red" }}> *</span>
            </label>
            <FileUploader handleChange={BulkSubmit} name="file" types={["xlsx"]} />
            <div className='mt-2'>
                <button type="button" className="btn btn-rounded btn-primary me-3 mb-0" style={{ minWidth: '8px' }} onClick={handleBulk}>
                    <i className="fa fa-upload text-white color-success"></i> Upload
                </button>
                <span type="button" style={{ minWidth: '8px', textDecoration: 'underline', color: 'blue', fontSize: '13px' }} onClick={downloadFormat}>
                    Download Format <i className="fa fa-download color-success"></i>
                </span>
            </div>
            {/*<textarea className='form-control' readOnly>{HeaderFormat}</textarea>*/}
            <div className='d-flex mb-1'>
                {Status?.length !== 0 ?
                    <>
                        <div className='text-success p-0 pt-2 m-0 fw-bold' style={{ lineHeight: '14px' }}>Success : {Status?.SuccessCount} &nbsp; &nbsp;
                            <span className='text-danger'>Error : {Status?.ErrorCount}
                                {Status?.length !== 0 && Status?.ErrorData?.length !== 0 ?
                                    <>&nbsp;<i className="fa fa-download  color-success" style={{ cursor: 'pointer' }} onClick={ErrorData}></i></>
                                    : ""}
                            </span></div>
                    </>
                    : ""}
            </div>

            {children}
        </div>
    )
}



export const InputBox = (props) => {


    let { divclassname = 'col-md-3 mb-1 cntrl-width ', labelleft, eyeIcon, showPassword, handleShowPassword, disabled = false, customclass, onFocus, onKeyPress, pattern, onKeyDown = null, inputClassName = '', type = 'text', divstyle = null, inputStyle = null, inputrefs, id, label = '', value, onChange, placeholder, required, error, confirm_error } = props



    return (

        <div
            className={`${divclassname}`}
            style={divstyle}
            key={id}
        >
            {labelleft === true ?

                <div className='d-flex' style={{
                    height: 'fit-content',
                    width: 'max-content',
                    textAlign: 'end',
                    textWrap: 'nowrap',
                    display: 'flex'
                }}>

                    {label !== '' ? <label className="form-label" style={{ textWrap: 'nowrap', }} htmlFor={id}>{label}
                        {required ? <span style={{ color: "red", }}> *</span> : <></>}
                    </label> : <></>}
                    <input

                        id={id}
                        style={inputStyle}
                        onKeyDown={onKeyDown}
                        onFocus={onFocus}
                        onKeyPress={onKeyPress}
                        pattern={pattern}
                        ref={ref => (inputrefs.current[id] = ref)}
                        type={type}
                        className={`form-control ${inputClassName} ${customclass}`}
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        autoComplete="new-password"
                        disabled={disabled}

                        {...props}
                    />
                </div>

                :
                <>{label !== '' ? <label className="form-label" htmlFor={id}>{label}
                    {required ? <span style={{ color: "red" }}> *</span> : <></>}
                </label> : <></>}
                    <input

                        id={id}
                        style={inputStyle}
                        onKeyDown={onKeyDown}
                        onFocus={onFocus}
                        onKeyPress={onKeyPress}
                        pattern={pattern}
                        ref={ref => (inputrefs.current[id] = ref)}
                        type={type}
                        className={`form-control ${inputClassName + ' ' + customclass}`}
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        autoComplete="new-password"
                        disabled={disabled}

                        {...props}
                    />
                    {eyeIcon && <i style={{
                        position: 'relative',
                        top: '-1.7rem',
                        left: '21rem',

                        cursor: 'pointer',
                    }}
                        className={` ${showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'}`}
                        onclick={handleShowPassword}></i>}
                </>}
            {error && <div className="error-msg">{error}</div>}
            {confirm_error && <div className="error-msg">{confirm_error}</div>}
        </div>

    )
}


export const PasswordInputBox = (props) => {
    let { divclassname = 'col-md-3 mb-1 cntrl-width ', key,
        disabled = false, customclass, onFocus, onKeyPress, pattern, onKeyDown = null, inputClassName = '',
        divstyle = null, inputStyle = null, inputrefs, id, label = '', value, onChange,
        placeholder, required, error, confirm_error, disableOnEdit = false } = props

    const [showPassword, setShowPassword] = useState(false)
    const handleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    return (

        <div
            className={`${divclassname}`}
            style={divstyle}
            key={!key ? id : key}
        >

            {label !== '' ? <label className="form-label" htmlFor={id}>{label}
                {required ? <span style={{ color: "red" }}> *</span> : <></>}
            </label> : <></>}
            <div className='input-group'>
                <input
                    type={showPassword ? 'text' : 'password'}
                    id={id}
                    style={inputStyle}
                    onKeyDown={onKeyDown}
                    onFocus={onFocus}
                    onKeyPress={onKeyPress}
                    pattern={pattern}
                    ref={ref => (inputrefs.current[id] = ref)}
                    className={`form-control ${inputClassName + ' ' + customclass}`}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    autoComplete="new-password"
                    disabled={disabled}
                    aria-describedby="basic-addon1"

                    {...props}
                />
                <span className={`input-group-text`} style={disableOnEdit ? { pointerEvents: 'none', cursor: 'not-allowed' } : { cursor: 'pointer' }} id="basic-addon1" onClick={() => handleShowPassword()}><i className={` ${showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'}`}></i> </span>
            </div>

            {error && <div className="error-msg">{error}</div>}
            {confirm_error && <div className="error-msg">{confirm_error}</div>}
        </div>

    )
}
export const InputBoxWithLabel = (props) => {


    let { Labelclass = '', onKeyDown = null, inputClassName = '', type = 'text', divClass = 'col-md-4 col-sm-6 mb-3', divstyle = null, inputStyle = null, inputrefs, id, nextinputid, label, value, onChange, placeholder, required } = props
    return (

        <div
            className={divClass}
            style={divstyle}

        >
            <label className={`form-label ${Labelclass}`} htmlFor={id}>{label || 'Branch'}
                {required ? <span style={{ color: "red" }}> *</span> : <></>}
            </label>
            <input
                id={id}
                style={inputStyle}
                onKeyDown={onKeyDown}
                ref={ref => (inputrefs.current[id] = ref)}
                type={type}
                className={`form-control ${inputClassName}`}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                autoComplete="off"
                {...props}
            />
        </div>

    )
}

export const AutoCompleteBoxWithLabel = (props) => {

    const { className = "col-md-4 col-sm-6 mb-3", onKeyPress, onKeyDown, style, inputStyle, inputrefs, maxLength, id, label, options, inputValue, onInputChange, placeholder, required } = props
    return (
        <div
            className={className}
            style={style}
        >
            <label className="form-label" htmlFor={id}>{label || 'Branch'}
                {required ? <span style={{ color: "red" }}> *</span> : <></>}
            </label>
            <Autocomplete
                inputValue={inputValue}
                onInputChange={onInputChange}
                freeSolo
                autoComplete
                options={options}
                renderInput={(params) => <TextField
                    {...params}
                    onInput={(e) => {
                        const { value } = e.target;

                        if (value?.length > maxLength) {
                            e.target.value = value.slice(0, maxLength);
                        }
                    }}
                    sx={inputStyle}
                    placeholder={placeholder}
                    inputRef={ref => (inputrefs.current[id] = ref)}
                    onKeyPress={onKeyPress}
                    onKeyDown={onKeyDown}

                />}
                {...props}
            />
        </div>
    )
}

export const AutoCompleteBox = (props) => {

    const { divclassname = 'col-md-3 mb-1 cntrl-width', onKeyPress, defaultValue, onKeyDown, onChange, style,
        inputStyle, inputrefs, maxLength, id, label, options,
        inputValue, onInputChange, placeholder, required, error } = props
    return (
        <div
            className={`${divclassname}`}
            style={style}
            key={id}
        >
            {label !== '' ? <label className="form-label" htmlFor={id}>{label}
                {required ? <span style={{ color: "red" }}> *</span> : <></>}
            </label> : <div style={{ display: 'none' }}></div>}
            <Autocomplete
                inputValue={inputValue}
                onInputChange={onInputChange}
                freeSolo
                autoHighlight={true}

                //    autoSelect={true}
                //  autoComplete={true}
                selectOnFocus
                //clearOnBlur
                handleHomeEndKeys
                onChange={onChange}
                options={options ? options : []}
                renderInput={(params) => <TextField
                    {...params}
                    onInput={(e) => {
                        const { value } = e.target;
                        // console.log(e,'test')
                        if (value?.length > maxLength) {
                            e.target.value = value.slice(0, maxLength);
                        }
                    }}
                    defaultValue={defaultValue}
                    sx={inputStyle}
                    placeholder={placeholder}
                    inputRef={ref => (inputrefs.current[id] = ref)}
                // onKeyPress={onKeyPress}
                //onKeyDown={onKeyDown}

                />}
                {...props}
            />
            {error && <div className="error-msg">{error}</div>}
        </div>
    )
}

export const DateBox = (props) => {
    const { divclassname = 'col-md-3 mb-1 cntrl-width', style, minDate, error, label, onKeyDown, maxDate, selected, placeholder, id, inputrefs, onChange, inputStyle, inputClass = 'form-control input-fields', required } = props
    // const mask = createMask('DD-MMM-YYYY', { placeholder: 'DD-MMM-YYYY' });
    // const mask = [/d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

    return (
        <div
            className={` ${divclassname}`}
            style={style}
        >
            {label && < label className="form-label">{label}
                {required ? <span style={{ color: "red" }}> *</span> : <></>}

            </label>}
            {/*<DatePicker*/}
            <Calendar
                className={inputClass}
                //style={{ inputStyle }}
                dateFormat="dd-MMM-yyyy"
                placeholderText={placeholder}
                selected={selected}
                maxDate={maxDate}
                minDate={minDate}
                ref={ref => (inputrefs.current[id] = ref?.input?.inputElement)}
                preventOpenOnFocus={true}
                onKeyDown={onKeyDown}
                // onChange={onChange}
                //  customInput={(params) => <MaskedInput className='' {...params} mask={mask} />}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                customInput={
                    <MaskedTextInput
                        ref={ref => (inputrefs.current[id] = ref?.input)}
                        type="text"
                        //style={{ padding:"5px 2px !important" }}
                        mask={[/[0-3]/, /[0-9]/, "-", /['A-Za-z']/, /['a-z']/, /['a-z']/, "-", /\d/, /\d/, /\d/, /\d/]}
                    />
                }

                {...props}
            />
            {error && <div className="error-msg">{error}</div>}
        </div>
    )
}

export const MonthBox = (props) => {
    const { divclassname = 'col-md-3 mb-1 cntrl-width', style, customclass, error, label, onKeyDown, maxDate, selected, placeholder, id, inputrefs, onChange, required } = props
    return (
        <div
            className={` ${divclassname}`}
            style={style}
        >
            <label className="form-label">{label}
                {required ? <span style={{ color: "red" }}> *</span> : <></>}

            </label>
            <DatePicker
                className={`form-control input-fields ${customclass}`}
                dateFormat="MMMM"
                placeholderText={placeholder}
                selected={selected}
                maxDate={maxDate}
                ref={ref => (inputrefs.current[id] = ref)}
                onKeyDown={onKeyDown}
                onChange={onChange}
                showMonthYearPicker
                showFullMonthYearPicker
                {...props}
            />
            {error && <div className="error-msg">{error}</div>}
        </div>
    )
}

export const YearBox = (props) => {
    const { divclassname = 'col-md-3 mb-1 cntrl-width', style, error, label, onKeyDown, maxDate, selected, placeholder, id, inputrefs, onChange, required } = props
    return (
        <div
            className={` ${divclassname}`}
            style={style}
        >
            <label className="form-label">{label}
                {required ? <span style={{ color: "red" }}> *</span> : <></>}

            </label>
            <DatePicker
                className='form-control input-fields'
                dateFormat="yyyy"
                placeholderText={placeholder}
                selected={selected}
                maxDate={maxDate}
                ref={ref => (inputrefs.current[id] = ref)}
                onKeyDown={onKeyDown}
                onChange={onChange}
                showYearPicker
                {...props}
            />
            {error && <div className="error-msg">{error}</div>}
        </div>
    )
}

export const TimeBox = (props) => {
    const { placeholder, disabled, customclass, error, inputClassName, onFocus, pattern, value, onKeyDown, onChange, maxLength, inputrefs, id, label, required, labelClass, divclassname = 'col-md-3 mb-1 cntrl-width ', style } = props

    return (

        <div className={` ${divclassname}`}
            style={style} >
            {customclass === "RemoveLabel" ? <span></span>
                :
                <label className={`form-label ${labelClass}`}>{label}
                    {required ? <span style={{ color: "red" }}> *</span> : <></>}
                </label>}

            <input
                ref={ref => (inputrefs.current[id] = ref)}
                type="time"
                pattern={pattern}
                onFocus={onFocus}
                className={`form-control ${inputClassName + ' ' + customclass}`}
                format='HH:mm'
                placeholder={placeholder}
                maxLength={maxLength}
                value={value}
                onKeyDown={onKeyDown}
                onChange={onChange}
                disabled={disabled}
                {...props}

            />
            {error && <div className="error-msg">{error}</div>}
        </div>

    )
}


export const SelectInputBox = (props) => {

    const { divclassname = 'col-md-3 mb-1 cntrl-width', inputClassName, onFocus, onKeyDown, divstyle, error, inputStyle, inputrefs, id, label, value, onChange, children, required } = props
    return (
        <div
            className={` ${divclassname}`}
            style={divstyle}
        >
            {label && <label className="form-label" htmlFor={id}>{label || 'Branch'}
                {required ? <span style={{ color: "red" }}> *</span> : <></>}
            </label>}
            <select
                id={id}
                className={`form-select ${inputClassName}`}
                style={inputStyle}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                ref={ref => (inputrefs.current[id] = ref)}
                value={value}
                onChange={onChange}
                {...props}
            >
                {children}
            </select>
            {error && <div className="error-msg">{error}</div>}
        </div>
    )
}




export const MultiSelectInputBox = (props) => {
    const { divclassname = 'col-md-3 mb-1 cntrl-width',
        inputClassName, options,
        onFocus, onKeyDown, divstyle, error,
        inputStyle, inputrefs, id, label,
        value, onChange, required, closeMenuOnSelect = false } = props
    return (
        <div
            className={` ${divclassname}`}
            style={divstyle}
        >
            <label className="form-label" htmlFor={id}>{label || 'Branch'}
                {required ? <span style={{ color: "red" }}> *</span> : <></>}
            </label>

            <Select
                id={id}

                styles={{
                    //   indicatorContainer: (base) => ({
                    //      ...base,
                    //    padding: '6px',
                    //flexWrap: 'unset',
                    //  }),
                    //   multiValue: (base) => ({
                    //         ...base,
                    //         flex: '1 0 auto',
                    //     })
                }}
                classNames={{
                    valueContainer: (state) =>
                        state ? 'scroll_select' : '',
                    indicatorsContainer: (state) =>
                        state ? 'indicator_cross' : '',
                    control: (state) =>
                        state ? 'setHieght' : '',
                }}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                ref={ref => (inputrefs.current[id] = ref)}
                value={value}
                onChange={onChange}
                // defaultValue={value}
                isMulti
                closeMenuOnSelect={closeMenuOnSelect}
                name={id}
                options={options}
                {...props}
            />

            {error && <div className="error-msg">{error}</div>}
        </div>
    )
}


export const Switch = (props) => {

    const { id, checked = false, inputrefs, onKeyDown, label,
        // extraclassname = `form-switch ps-4 ps-md-5 form-check mt-4`,
        extraclassname = `form-switch mt-4`,
        onChange, divclassname = 'col-md-3 mb-1  cntrl-width' } = props

    return (
        <div className={` ${divclassname}`} key={`checkin${id}`}>
            <div className={`${extraclassname}`} >
                <input
                    className="form-check-input"
                    type="checkbox"
                    id={id}
                    checked={checked}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    ref={ref => (inputrefs.current[id] = ref)}
                    {...props}
                />
                <label className="form-check-label ms-2" htmlFor={id}>{label}</label>
            </div>
        </div>
    )
}

export const CheckBoxInput = (props) => {
    const { divclassname = 'col-md-4 col-lg-3 col-6 mb-1 cntrl-width', id, checked = false, onKeyDown, inputrefs, label, onChange, customclass } = props
    return (
        <div className={`${divclassname}`} key={`checkin${id}`}>
            <div className={customclass ? customclass : `form-check form-switch ps-4 ps-md-5 mt-4`}>
                <input
                    ref={ref => (inputrefs.current[id] = ref)}
                    className=""
                    type="checkbox"
                    id={id}
                    checked={checked}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    {...props}
                />
                <label className="form-check-label ms-2" htmlFor={id}>{label}</label>
            </div>
        </div>
    )
}
export const View = (props) => {
    const { id, checked = false, onKeyDown, label, onChange } = props
    return (
        <div className='col-md-4 col-lg-3 col-6 mb-1 cntrl-width' key={`checkin${id}`}>
            <div className="form-check form-switch ps-4 ps-md-5 mt-4">
                <input
                    className=""
                    type="checkbox"
                    id={id}
                    checked={checked}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    {...props}
                />
                <label className="form-check-label ms-2" htmlFor={id}>{label}</label>
            </div>
        </div>
    )
}

export const DynamicHTMLComponent = ({ htmlContent }) => {
    const sanitizedHTML = DOMPurify.sanitize(htmlContent);

    return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
};

export const SmallDateBox = (props) => {
    const { divclassname = 'col-md-12 mb-1', style, error, label, onKeyDown, maxDate, selected, placeholder, id, inputrefs, onChange, required } = props
    return (
        <div
            className={` ${divclassname}`}
            style={style}
        >
            <label className="form-label">{label}
                {required ? <span style={{ color: "red" }}> *</span> : <></>}

            </label>
            <DatePicker
                className='form-control input-fields'
                dateFormat="dd-MMM-yyyy"
                placeholderText={placeholder}
                selected={selected}
                maxDate={maxDate}
                ref={ref => (inputrefs.current[id] = ref)}
                onKeyDown={onKeyDown}
                onChange={onChange}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                {...props}
            />
            {error && <div className="error-msg">{error}</div>}
        </div>
    )
}



export const handleFileUploderInput = (selector, fromData, fileNameProp, fileExtProp, types) => {
    let elements = document.querySelectorAll(selector);

    if (elements.length > 0) {
        let ele = elements[0];
        if (fromData[fileNameProp] !== 'undefind' && fromData !== 'undefind' && fromData !== '' && fromData && fromData[fileNameProp] && fromData[fileNameProp] !== "") {
            //  console.log(ele.childNodes, '222222222222')
            //  ele.childNodes[2].innerHTML = `${fromData[fileNameProp]}.${fromData[fileExtProp]}`;
            ele.childNodes[2].innerHTML = `<div class="sc-kAyceB DimIH"><span class="sc-imWYAI jRuVVs">${fromData[fileNameProp]}.${fromData[fileExtProp]}</span></div>`;
        } else {
            //console.log(ele.childNodes,'11111')
            ele.childNodes[2].innerHTML = `<div class="sc-kAyceB DimIH"><span class="sc-imWYAI jRuVVs"><span>Upload</span> a file right here</span><span title="types: ${types}" class="file-types">${types}</span></div>`;
            // ele.childNodes[1].innerHTML = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns='http://www.w3.org/2000/svg'></svg> <span class="sc-fqkvVR kFhUBM"><span>Upload</span> or drop a file right here</span><span title="types: JPG,PNG" class="file-types">JPG,PNG</span>`;
        }
    }
}



export const handleFileUploader = (file, fileTypes, setFormData, FromData, Photo, PhotoExt, PhotoName, setImgRead, imgRead, imgPropName) => {
    let extArr = file.name.split(".");
    // console.log(file, extArr, fileTypes)
    if (fileTypes.includes(extArr[extArr.length - 1]) === true) {
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const img = reader.result;
                setImgRead && setImgRead({ ...imgRead, [imgPropName]: img });
                let logo = reader.result.split(',')[1];
                setFormData({
                    ...FromData,
                    [Photo]: logo,
                    [PhotoExt]: extArr[extArr.length - 1],
                    [PhotoName]: extArr[0]
                })
            };
        }
    } else {
        toast.error('Please Upload Valid File.!')
    }

};





export const FileUploadChild = (props) => {
    const { message = '', types = [], children } = props;
    return (
        <>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="
http://www.w3.org/2000/svg"><path d="M5.33317 6.66667H22.6665V16H25.3332V6.66667C25.3332 5.196 24.1372 4 22.6665 4H5.33317C3.8625 4 2.6665 5.196 2.6665 6.66667V22.6667C2.6665 24.1373 3.8625 25.3333 5.33317 25.3333H15.9998V22.6667H5.33317V6.66667Z" fill="#0658C2"></path><path d="M10.6665 14.6667L6.6665 20H21.3332L15.9998 12L11.9998 17.3333L10.6665 14.6667Z" fill="#0658C2"></path><path d="M25.3332 18.6667H22.6665V22.6667H18.6665V25.3333H22.6665V29.3333H25.3332V25.3333H29.3332V22.6667H25.3332V18.6667Z" fill="#0658C2"></path></svg>
            <div className=" sc-kAyceB DimIH ">
                {(message.trim() === '.' || message.trim() === '') ?
                    <><span> <span className='sc-fqkvVR text-decoration-underline'>{`Upload`}</span> a right file here</span><span title={`types: ${types.join()}`} className='file-types'>{types.join()}</span> </> :
                    <span className="sc-fqkvVR">{`${message}`}</span>
                }
                {/*{(message.trim() !== '.' || message.trim() !== '') &&*/}
                {/*    <span title={`types: ${types.join()}`} className=''>{types.join()}</span>*/}
                {/*}*/}
                {children}
            </div>
        </>
    );
}