
import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, AutoCompleteBoxWithLabel, DateBox, MonthBox, YearBox } from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
//loder
import BlurLoader from "../Loder/BlurLoader";
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { Token, currentMonth, currentYear, dateFormate, number, numberwithDot } from '../Common/Common';

function Payslip() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();

    //token Data
    const TokenData = Token()

    //initial state
    const [recipe, setRecipe] = useState({
        employee: '',
        branch: "",
        designation: '',
        department: '',
        month: new Date(),
        year: new Date(),
    })

    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    //  const [filterData, setFilterData] = useState(initializeFilterData);
    const [isUpdate, setIsUpdate] = useState(false);
    const [branchAllData, setBranchAllData] = useState([])
    const [employeeAllData, setEmployeeAllData] = useState([])
    const [departmentAllData, setDepartmentAllData] = useState([])
    const [designationAllData, setDesignationAllData] = useState([])
    const [companyAllData, setCompanyAllData] = useState([])
    const [errors, setErrors] = useState({});
    const [showTable, setShowTable] = useState(false);


    //Get branch Data for AutoComplete
    const GetBranchData = (value) => {
        setBranchAllData([])
        let temp = {
            Type: "GetBranch",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        //    setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setBranchAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setBranchAllData(data)
                }
            }
        });
    }

    //Get Department Data for AutoComplete
    const GetDepartmentData = (value) => {
        setDepartmentAllData([])
        let temp = {
            Type: "GetDepartment",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        //  setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setDepartmentAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setDepartmentAllData(data)
                }
            }
        });
    }

    //Get Designation Data for AutoComplete
    const GetDesignationData = (value) => {
        setDesignationAllData([])
        let temp = {
            Type: "GetDesignation",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        // setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setDesignationAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setDesignationAllData(data)
                }
            }
        });
    }

    //Get Employee Data for AutoComplete
    const GetEmployeeData = (value) => {
        setEmployeeAllData([])
        let temp = {
            Type: "GetEmployee",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setEmployeeAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setEmployeeAllData(data)
                }
            }
        });
    }

    //Submit Handler
    const handleSubmit = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/Print/PrintPayslip`, { ...TokenData, ...recipe, year: recipe.year ? currentYear(recipe.year) : '', month: recipe.month ? currentMonth(recipe.month, 'numeric') : '', }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        setLoading(false)
                        toast.error(res.message)
                    } else if (res.status === "EXPIRED") {
                        toast.error(res.message)
                        sessionExpired()
                    } else if (res.status === "UNAUTHORIZED") {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        setLoading(false)
                        let base64PDF = res && res.base64PDF
                        window.open(`/PdfView/#data:application/pdf;base64,${base64PDF}`, '_blank')
                    }
                }
            });
        }
    }

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    // Handle Validate
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "month", msg: 'Please Select Month.' },
            { field: "year", msg: 'Please Select Year.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {

                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            employee: '',
            branch: "",
            designation: '',
            department: '',
            month: '',
            year: '',
        }))
        setErrors({})
    }

    let BranchData = []
    for (let i = 0; i < branchAllData.length; i++) {
        let mydata = branchAllData[i]?.values
        BranchData.push(mydata)
    };


    let DepartmentData = []
    for (let i = 0; i < departmentAllData.length; i++) {
        let mydata = departmentAllData[i]?.values
        DepartmentData.push(mydata)
    };

    let DesignationData = []
    for (let i = 0; i < designationAllData.length; i++) {
        let mydata = designationAllData[i]?.values
        DesignationData.push(mydata)
    };


    let EmployeeData = []
    for (let i = 0; i < employeeAllData.length; i++) {
        let mydata = employeeAllData[i]?.values
        EmployeeData.push(mydata)
    };



    useEffect(() => {
        inputrefs.current['txtBranch'].focus();
        document.title = 'Pay Plus : Payslip';
        //   GetEmployeeData();
    }, [])


    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Payslip</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtDepartment'].focus() };
                            }}
                            label='Branch'
                            placeholder="Branch"
                            maxLength='50'
                            options={BranchData}
                            id='txtBranch'
                            key='txtBranch'
                            // required={true}
                            // error={errors.branch}
                            inputValue={recipe.branch ? recipe.branch : ''}
                            onInputChange={(event, newInputValue) => {
                                GetBranchData(newInputValue)
                                HandleChange('branch', newInputValue)

                            }}
                            onFocus={() => GetBranchData()}
                        />

                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtDesignation'].focus() };
                            }}
                            label='Department'
                            placeholder="Department"
                            maxLength='50'
                            options={DepartmentData}
                            id='txtDepartment'
                            key='txtDepartment'
                            // required={true}
                            // error={errors.department}
                            inputValue={recipe.department ? recipe.department : ''}
                            onInputChange={(event, newInputValue) => {
                                GetDepartmentData(newInputValue)
                                HandleChange('department', newInputValue)

                            }}
                            onFocus={() => GetDepartmentData()}
                        />

                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtEmployee'].focus() };
                            }}
                            label='Designation'
                            placeholder="Designation"
                            maxLength='50'
                            options={DesignationData}
                            id='txtDesignation'
                            key='txtDesignation'
                            // required={true}
                            // error={errors.designation}
                            inputValue={recipe.designation ? recipe.designation : ''}
                            onInputChange={(event, newInputValue) => {
                                GetDesignationData(newInputValue)
                                HandleChange('designation', newInputValue)

                            }}
                            onFocus={() => GetDesignationData()}
                        />


                        <AutoCompleteBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtMonth'].focus() };
                            }}
                            label='Employee'
                            placeholder="Employee"
                            maxLength='50'
                            options={EmployeeData}
                            id='txtEmployee'
                            key='txtEmployee'
                            // required={true}
                            // error={errors.employee}
                            inputValue={recipe.employee ? recipe.employee : ''}
                            onInputChange={(event, newInputValue) => {
                                GetEmployeeData(newInputValue)
                                HandleChange('employee', newInputValue)

                            }}
                            onFocus={() => GetEmployeeData()}
                        />


                        <MonthBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtYear'].focus() };
                            }}
                            label='Month'
                            id='txtMonth'
                            selected={recipe.month ? recipe.month : ""}
                            placeholder="Month"
                            onChange={(e) => { HandleChange('month', e) }}
                            required
                            error={errors.month}
                        />
                        <YearBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { handleSubmit() };
                            }}
                            label='Year'
                            id='txtYear'
                            selected={recipe.year ? recipe.year : ""}
                            placeholder="Year"
                            onChange={(e) => { HandleChange('year', e) }}
                            required
                            error={errors.year}
                        />
                        <FormButton
                            saveButtonIcon='fa-print'
                            SaveText='Print'
                            handleSubmit={handleSubmit}
                            Export={false}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>
            {loading && < BlurLoader />}
        </>
    )
}

export default Payslip
