import React, { useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditor = ({ value, setValue, className = '', style }) => {
 


    const modules = {
        toolbar: [
            [{ 'font': [] }, { 'header': [1, 2, 3, 4, 5, 6, false] },'bold', 'italic', 'underline', 'strike', 'blockquote', { 'color': [] }, { 'background': [] }, 'code', { 'script': 'sub' }, { 'script': 'super' }, { 'direction': 'rtl' }, { 'align': [] }, { 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }, 'link', 'image', 'clean'],
           // [{ 'color': [] }, { 'background': [] }],
          //  [{ 'align': [] },{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }, 'link', 'image'],
           // [{ 'script': 'sub' }, { 'script': 'super' }],
            //['link', 'image'],
         //   [{ 'font': [] }, { 'header': [1, 2, 3, 4, 5, 6, false] }],
           // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
           // [{ 'align': [] }],
            // [{'size' :[5,8,10,12]}],
           // [{ 'direction': 'rtl' }],
           // ['clean'],
        ],
    }

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]

    return (
        <div className={className} style={style}>
            <ReactQuill
                theme="snow"
                value={value}
                onChange={setValue}
                modules={modules}
            //  formats={formats}
            />
        </div>
    );
};

export default TextEditor;
