import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

//login Page
import Login from './components/Login/Login';

//context Auth Provider
import { AuthProvider } from './context/auth';

//protected Routes
import { ProtectedRoute } from './ProtectedRoutes';

//mainLayout
import Layout from './components/Layout/Layout';

// all components

//User Screens Start
import ChangePassword from './components/User/ChangePassword';
import ResetPassword from './components/User/ResetPassword';
import UserGroupMaster from './components/User/UserGroupMaster';
import UserGroupMenuAccess from './components/User/UserGroupMenuAccess';
import UserMaster from './components/User/UserMaster';

//User Screens Add

//Dashboard Screen start
import Dashboard from './components/Dashboard/Dashboard';
//Dashboard Screen End

//import ComingSoon from './components/commoncomponents/ComingSoon';


//Master Screen
import AllowanceMaster from './components/Master/AllowanceMaster';
import BankMaster from './components/Master/BankMaster';
import BranchMaster from './components/Master/BranchMaster';
import CompanyMaster from './components/Master/CompanyMaster';
import CountryMaster from './components/Master/CountryMaster';
import DeductionMaster from './components/Master/DeductionMaster';
import DepartmentMaster from './components/Master/DepartmentMaster';
import DesignationMaster from './components/Master/DesignationMaster';
import DocumentSubTypeMaster from './components/Master/DocumentSubTypeMaster';
import DocumentTypeMaster from './components/Master/DocumentTypeMaster';
import ExtraAllowance from './components/Master/ExtraAllowance';
import HolidayMaster from './components/Master/HolidayMaster';
import LeaveMaster from './components/Master/LeaveMaster';
import MailTemplate from './components/Master/MailTemplateMaster';
import RelationMaster from './components/Master/RelationMaster';
import StateMaster from './components/Master/StateMaster';
import UserMenuAccess from './components/User/UserMenuAccess';
import PageNotFound from './components/commoncomponents/PageNotFound';



//Candidate Master 
import CandidateMaster from './components/Candidate Master/CandidateMaster';
import CandidateRegistration from './components/Candidate Master/CandidateRegistration';
import ConvertToEmployee from './components/Candidate Master/ConvertToEmployee';
import SearchCandidate from './components/Candidate Master/SearchCandidate';
import VerifyCandidate from './components/Candidate Master/VerifyCandidate';
import LetterFormatForPrint from './components/Master/LetterFormatForPrint';
import PDFView, { ImageView } from './components/commoncomponents/PdfView';

//Employee Master
import EmployeeMaster from './components/EmployeeMaster/EmployeeMaster';
import EmployeeRejoining from './components/EmployeeMaster/EmployeeRejoining';
import SearchEmployee from './components/EmployeeMaster/SearchEmployess';
import UpdateBankDetails from './components/EmployeeMaster/UpdateBankDetails';
import UpdateEmployee from './components/EmployeeMaster/UpdateEmployee';
import VerifyBankDetails from './components/EmployeeMaster/VerifyBankDetails';
import VerifyEmployeeDetails from './components/EmployeeMaster/VerifyEmployeeDetails';
import VerifyFamilyDetails from './components/EmployeeMaster/VerifyFamilyDetails';


//Loan Management
import EMISuspension from './components/Loan Management/EMISuspension';
import LoanMaster from './components/Loan Management/LoanMaster';
import LoanRepay from './components/Loan Management/LoanRepay';
import LoanReport from './components/Loan Management/LoanReport';
import LoanStatement from './components/Loan Management/LoanStatement';
import LoanTypeMaster from './components/Loan Management/LoanTypeMaster';

//Reports
import AttendanceDetailReport from './components/Reports/AttendanceDetailReport';
import BirthdayReport from './components/Reports/BirthdayReport';
import BloodGroupReport from './components/Reports/BloodGroupReport';
import ESICReport from './components/Reports/ESICReport';
import EmployeeDocumentReport from './components/Reports/EmployeeDocumentReport';
import LeaveStatusReport from './components/Reports/LeaveStatusReport';
import MobileAttendanceReport from './components/Reports/MobileAttendanceReport';
import OTSheetInExcel from './components/Reports/OTSheetInExcel';
import PFReport from './components/Reports/PFReport';
import RoasterReport from './components/Reports/RoasterReport';
import WorkingAndLeftEmployee from './components/Reports/WorkingAndLeftEmployee';

//Leave Management
import LeaveApplication from './components/Leave Management/LeaveApplication';
import LeaveApproval from './components/Leave Management/LeaveApproval';
import LeaveStatus from './components/Leave Management/LeaveStatus';

//Transaction
import DeleteAttendanceAndSalary from './components/Transaction/DeleteAttendanceAndSalary';
import FreezeSalary from './components/Transaction/FreezeSalary';
import ProcessSalary from './components/Transaction/ProcessSalary';
import SalarySheetInExcel from './components/Transaction/SalarySheetInExcel';
import UnFreezeSalary from './components/Transaction/UnFreezeSalary';
import UnVerifySalary from './components/Transaction/UnVerifySalary';
import VerifySalary from './components/Transaction/VerifySalary';


//Roaster Management
import RoasterMaster from './components/RoasterManagement/RoasterMaster';
import ShiftMaster from './components/RoasterManagement/ShiftMaster';

//Import
import ImportAadhaarCardNo from './components/Import/ImportAadhaarCardNo';
import ImportAllowance from './components/Import/ImportAllowance';
import ImportAttendance from './components/Import/ImportAttendance';
import ImportBankAcNo from './components/Import/ImportBankAcNo';
import ImportESICNo from './components/Import/ImportESICNo';
import ImportEmployee from './components/Import/ImportEmployee';
import ImportFullEmployee from './components/Import/ImportFullEmployee';
import ImportLeaveRegister from './components/Import/ImportLeaveRegister';
import ImportLeftEmployee from './components/Import/ImportLeftEmployee';
import ImportPFNo from './components/Import/ImportPFNo';
import ImportRoaster from './components/Import/ImportRoaster';
import ImportSalaryDisbursment from './components/Import/ImportSalaryDisbursment';
import ImportSalaryStructure from './components/Import/ImportSalaryStructure';
import ImportShortAttendance from './components/Import/ImportShortAttendance';
import ImportUANNo from './components/Import/ImportUANNo';
import ImportLoan from './components/Loan Management/ImportLoan';
import ImportDeduction from './components/Transaction/ImportDeduction';

//Print
import AppointmentLetter from './components/Print/AppointmentLetter';
import CandidateOfferLetter from './components/Print/CandidateOfferLetter';
import PaySlip from './components/Print/PaySlip';
import TerminationLetter from './components/Print/TerminationLetter';
import WarningLetter from './components/Print/WarningLetter';

function App() {
    return (
        <>
            <ToastContainer position={'top-center'} />
            <BrowserRouter>
                <AuthProvider>
                    <Routes>
                        <Route element={<ProtectedRoute><Layout /></ProtectedRoute>}>
                            <Route exact path='Dashboard/Dashboard' element={<Dashboard />} />

                            {/* User Master*/}
                            <Route exact path='User/UserMaster' element={<UserMaster />} />
                            <Route exact path='User/UserGroupMaster' element={<UserGroupMaster />} />
                            <Route exact path='User/ChangePassword' element={<ChangePassword />} />
                            <Route exact path='User/ResetPassword' element={<ResetPassword />} />
                            <Route exact path="User/UserMenuAccess" element={<UserMenuAccess />} />
                            <Route exact path="User/GroupMenuAccess" element={<UserGroupMenuAccess />} />

                            {/*Master*/}
                            <Route exact path='Master/CountryMaster' element={<CountryMaster />} />
                            <Route exact path='Master/StateMaster' element={<StateMaster />} />
                            <Route exact path='Master/BranchMaster' element={<BranchMaster />} />
                            <Route exact path='Master/AllowanceMaster' element={<AllowanceMaster />} />
                            <Route exact path='Master/CompanyMaster' element={<CompanyMaster />} />
                            <Route exact path='Master/DeductionMaster' element={<DeductionMaster />} />
                            <Route exact path='Master/DepartmentMaster' element={<DepartmentMaster />} />
                            <Route exact path='Master/DesignationMaster' element={<DesignationMaster />} />
                            <Route exact path='Master/DocumentTypeMaster' element={<DocumentTypeMaster />} />
                            <Route exact path='Master/DocumentSubTypeMaster' element={<DocumentSubTypeMaster />} />
                            <Route exact path='Master/HolidayMaster' element={<HolidayMaster />} />
                            <Route exact path='Master/BankMaster' element={<BankMaster />} />
                            <Route exact path='Master/LeaveMaster' element={<LeaveMaster />} />
                            <Route exact path='Master/RelationMaster' element={<RelationMaster />} />
                            <Route exact path='Master/MailTemplate' element={<MailTemplate />} />
                            <Route exact path='Master/ExtraAllowance' element={<ExtraAllowance />} />

                            {/* Candidate Master*/}
                            <Route exact path='Candidate/CandidateMaster' element={<CandidateMaster />} />
                            <Route exact path='Candidate/CandidateRegistration' element={<CandidateRegistration />} />
                            <Route exact path='Candidate/SearchCandidate' element={<SearchCandidate />} />
                            <Route exact path='Candidate/VerifyCandidate' element={<VerifyCandidate  />} />
                            <Route exact path='Candidate/ConvertToEmployee' element={<ConvertToEmployee />} />
                            <Route exact path='Master/LetterFormatForPrint' element={<LetterFormatForPrint />} />

                            {/* Employee Master*/ }
                            <Route exact path='/Employee/EmployeeMaster' element={<EmployeeMaster />} />
                            <Route exact path='/Employee/SearchEmployee' element={<SearchEmployee />} />
                            <Route exact path='/Employee/VerifyBankDetails' element={<VerifyBankDetails />} />
                            <Route exact path='/Employee/VerifyFamilyDetail' element={<VerifyFamilyDetails />} />
                            <Route exact path='/Employee/VerifyEmployeeDetail' element={<VerifyEmployeeDetails />} />
                            <Route exact path='/Employee/UpdateBankDetails' element={<UpdateBankDetails />} />
                            <Route exact path='/Employee/EmployeeRejoining' element={<EmployeeRejoining />} />
                            <Route exact path='/Employee/UpdateEmployee' element={<UpdateEmployee />} />

                            {/*Loan Management*/}
                            <Route exact path='LoanManagement/LoanTypeMaster' element={<LoanTypeMaster />} />
                            <Route exact path='LoanManagement/LoanRepay' element={<LoanRepay />} />
                            <Route exact path='LoanManagement/EMISuspension' element={<EMISuspension />} />
                            <Route exact path='LoanManagement/LoanMaster' element={<LoanMaster />} />
                            <Route exact path='LoanManagement/LoanStatement' element={<LoanStatement />} />
                            <Route exact path='LoanManagement/LoanReport' element={<LoanReport />} />

                            {/*Reports*/}
                            <Route exact path='Report/BirthdayReport' element={<BirthdayReport />} />
                            <Route exact path='Report/WorkingAndLeftEmployee' element={<WorkingAndLeftEmployee />} />
                            <Route exact path='Report/LeaveStatusReport' element={<LeaveStatusReport />} />
                            <Route exact path='Report/BloodGroupReport' element={<BloodGroupReport />} />
                            <Route exact path='Report/AttendanceDetailReport' element={<AttendanceDetailReport />} />
                            <Route exact path='Report/MobileAttendanceReport' element={<MobileAttendanceReport />} />
                            <Route exact path='Report/PFReport' element={<PFReport />} />
                            <Route exact path='Report/ESICReport' element={<ESICReport />} />
                            <Route exact path='Report/EmployeeDocumentReport' element={<EmployeeDocumentReport />} />
                            <Route exact path='Report/OTSheetInExcel' element={<OTSheetInExcel />} />
                            <Route exact path='Report/RoasterReport' element={<RoasterReport />} />

                            {/*Leave Management*/}
                            <Route exact path='LeaveManagement/LeaveApplication' element={<LeaveApplication />} />
                            <Route exact path='LeaveManagement/LeaveApproval' element={<LeaveApproval />} />
                            <Route exact path='LeaveManagement/LeaveStatus' element={<LeaveStatus />} />

                            {/* Transaction */ }
                            <Route exact path='Transaction/ProcessSalary' element={<ProcessSalary />} />
                            <Route exact path='Transaction/SalarySheetInExcel' element={<SalarySheetInExcel />} />
                            <Route exact path='Transaction/VerifySalary' element={<VerifySalary />} />
                            <Route exact path='Transaction/UnVerifySalary' element={<UnVerifySalary />} />
                            <Route exact path='Transaction/FreezeSalary' element={<FreezeSalary />} />
                            <Route exact path='Transaction/UnFreezeSalary' element={<UnFreezeSalary />} />
                            <Route exact path='Transaction/DeleteAttendanceAndSalary' element={<DeleteAttendanceAndSalary />} />

                            {/* RoasterManagement */}
                            <Route exact path='RoasterManagement/ShiftMaster' element={<ShiftMaster />} />
                            <Route exact path='RoasterManagement/RoasterMaster' element={<RoasterMaster/>} />

                            {/* Import */}
                            <Route exact path='Import/ImportPFNo' element={<ImportPFNo />} />
                            <Route exact path='Import/ImportESICNo' element={<ImportESICNo />} />
                            <Route exact path='Import/ImportUANNo' element={<ImportUANNo />} />
                            <Route exact path='Import/ImportBankAcNo' element={<ImportBankAcNo />} />
                            <Route exact path='Import/ImportAadhaarCardNo' element={<ImportAadhaarCardNo />} />
                            <Route exact path='Import/ImportLeftEmployee' element={<ImportLeftEmployee />} />
                            <Route exact path='Import/ImportSalaryDisbursment' element={<ImportSalaryDisbursment />} />
                            <Route exact path='Import/ImportShortAttendance' element={<ImportShortAttendance />} />
                            <Route exact path='Import/ImportAttendance' element={<ImportAttendance />} />
                            <Route exact path='Import/ImportFullEmployee' element={<ImportFullEmployee />} />
                            <Route exact path='Import/ImportEmployee' element={<ImportEmployee />} />
                            <Route exact path='Import/ImportDeduction' element={<ImportDeduction />} />
                            <Route exact path='Import/ImportAllowance' element={<ImportAllowance />} />
                            <Route exact path='Import/ImportLoan' element={<ImportLoan />} />
                            <Route exact path='Import/ImportSalaryStructure' element={<ImportSalaryStructure />} />
                            <Route exact path='Import/ImportLeaveRegister' element={<ImportLeaveRegister />} />
                            <Route exact path='Import/ImportRoaster' element={<ImportRoaster />} />


                            {/* Print */}
                            <Route exact path='Print/AppointmentLetter' element={<AppointmentLetter />} />
                            <Route exact path='Print/TerminationLetter' element={<TerminationLetter />} />
                            <Route exact path='Print/WarningLetter' element={<WarningLetter />} />
                            <Route exact path='Print/PaySlip' element={<PaySlip />} />
                            <Route exact path='Print/CandidateOfferLetter' element={<CandidateOfferLetter />} />

                            <Route path='*' element={<PageNotFound />} />
                        </Route>
                        <Route exact path='/' element={<Login />} />
                        {/*<Route exact path='/forgotPassword' element={<ForgotPasswordSlide1 />} />*/}
                        {/*<Route exact path='/PdfView' element={<Viewer />} />*/}
                        <Route exact path='/PdfView' element={<PDFView />} />
                        <Route exact path='/ImageView' element={<ImageView />} />
                       {/*<Route path='*' element={<PageNotFound />} />*/}
                        {/*<Route exact path='/login/forgotPassword' element={<ForgotPasswordSlide2 />} />*/}
                    </Routes>
                </AuthProvider>
            </BrowserRouter>
        </>
    );
}

export default App;
