
import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, AutoCompleteBoxWithLabel, DateBox } from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';
//loder
import BlurLoader from "../Loder/BlurLoader";
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';
//services
import { post } from '../../apiservices/service';
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { Token, dateFormate, number, numberwithDot } from '../Common/Common';

function BirthdayReport() {
    const inputrefs = useRef([]);
    const { user, sessionExpired } = useAuth();
    //token Data
    //const TokenData = {
    //    AuthToken: process.env.REACT_APP_SECRET_KEY,
    //    LoginToken: user.loginsessionID,
    //    UserID: user.userID,
    //    CompanyID: user.companyID
    //}
    const TokenData = Token()
    //initial state
    const [recipe, setRecipe] = useState({
        loanTypeID: "0",
       // company: '',
        fromDate: '',
        toDate: '',
    })

    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData);
    const [isUpdate, setIsUpdate] = useState(false);
    const [companyAllData, setCompanyAllData] = useState([])
    const [errors, setErrors] = useState({});
    const [showTable, setShowTable] = useState(false)


    //Get company Data for AutoComplete
    const GetCompanyData = (value) => {
        setCompanyAllData([])
        let temp = {
            Type: "GetCompany",
            Prefix: value ? value : '',
            ContextKey: '',
            ContextKey2: ""
        }
        setLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setCompanyAllData([])
                } else {
                    setLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setCompanyAllData(data)
                }
            }
        });
    }

    //Submit Handler
    const handleSubmit = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        // setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        setLoading(true)
        post(`${API_SERVER}api/Report/ShowBirthdayReport`, { ...TokenData, ...temp, ...sortConfig, ...filterData, ...recipe }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    toast.error(res.Message)
                    setLoading(false)
                    setResult({ ...res })
                } else if (res.Status === "EXPIRED") {
                    toast.error(res.Message)
                    sessionExpired()
                } else if (res.Status === "UNAUTHORIZED") {
                    toast.error(res.Message);
                    setLoading(false);
                } else {
                    setLoading(false)
                    toast.success(res.message)
                    setResult({ ...res })
                    setShowTable(true)
                    //getFormData()
                    // handleClear()
                }
            }
        });

    }

    const handleChange1 = (event, value) => {
        //getFormData(value);
        handleSubmit(value);
    };

    useEffect(() => {
//  inputrefs.current['txtCompany'].focus();
        inputrefs.current['txtFromDate'].focus();
        document.title = 'Pay Plus : Birthday Report';
        GetCompanyData();
        //getFormData()
        if (sortConfig.SortOrder !== null) handleSubmit();

    }, [sortConfig, isUpdate])

    //Change Handler
    const HandleChange = (prop, value) => {       
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
         //   { field: "company", msg: 'Please Enter Company.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0 || recipe[item.field] === '') {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        return isValid;
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            loanTypeID: "0",
          //  company: '',
            fromDate: '',
            toDate: '',
        }))
        setErrors({})
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        setShowTable(false)
       // getFormData()
        inputrefs.current['txtCompany'].focus();
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    let CompanyData = []
    for (let i = 0; i < companyAllData.length; i++) {
        let mydata = companyAllData[i]?.values
        CompanyData.push(mydata)
    };

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        ...recipe,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Birthday Report</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>

                        {/*<AutoCompleteBox*/}
                        {/*    inputrefs={inputrefs}*/}
                        {/*    onKeyDown={(e) => {*/}
                        {/*        if (e.key === 'Enter') { inputrefs.current['txtFromDate'].focus() };*/}
                        {/*    }}*/}
                        {/*    id='txtCompany'*/}
                        {/*    label='Company'*/}
                        {/*    placeholder="Company"*/}
                        {/*    maxLength='50'*/}
                        {/*    options={CompanyData}                            */}
                        {/*    key='txtCompany'*/}
                        {/*    required={true}*/}
                        {/*    error={errors.company}*/}
                        {/*    inputValue={recipe.company ? recipe.company : ''}*/}
                        {/*    onInputChange={(event, newInputValue) => {*/}
                        {/*        GetCompanyData(newInputValue)*/}
                        {/*        HandleChange('company', newInputValue)*/}

                        {/*    }}*/}
                        {/*/>*/}

                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtToDate'].focus() };
                            }}
                            //labelClass='text-nowrap'
                            //divclassname='col-lg-6'
                            id='txtFromDate'
                            label='From Date'
                            placeholder="From Date"
                            selected={recipe.fromDate === '' ? '' : new Date(recipe.fromDate)}
                            onChange={(e) => HandleChange('fromDate', dateFormate(e))}
                           // error={errors.fromDate}
                           // required
                        />
                        <DateBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtApprovalBy'].focus() };
                            }}
                            //labelClass='text-nowrap'
                            //divclassname='col-lg-6'
                            id='txtToDate'
                            label='To Date'
                            placeholder="To Date"
                            selected={recipe.toDate === '' ? '' : new Date(recipe.toDate)}
                            onChange={(e) => HandleChange('toDate', dateFormate(e))}
                           // error={errors.toDate}
                            //required
                        />

                        <FormButton
                            saveButtonIcon='fa-search'
                            SaveText='Search'
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/Report/ShowBirthdayReport', ExportRequest, 'Birthday Reports')}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>
            {showTable &&
                <Table
                Filter={false }
                    Action={false}
                    Token={TokenData}
                    PageName='BirthdayReports'
                    // handleEdit={handleEdit}
                    // handleDelete={handleDelete}
                    handleFilter={() => {
                        //getFormData();
                        handleSubmit();
                        setFilterData({
                            ...filterData,
                        });
                    }}
                    tableData={result}
                    loading={loading}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    currentPageNo={currentPageNo}
                    handleChange1={handleChange1}
                    handleSorting={handleSorting}
                    sortConfig={sortConfig}
                />
            }
            {loading && < BlurLoader />}
        </>
    )
}

export default BirthdayReport
